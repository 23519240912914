import React from 'react';

interface FormTextAreaInputProps {
    readonly id: string;
    readonly rows: number;
    readonly labelText?: string;
    readonly explanation?: JSX.Element;
    readonly nextBestActionButton?: JSX.Element;
    readonly value: string;
    readonly placeholder?: string;
    readonly onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    readonly autoFocus?: boolean;
    readonly disabled?: boolean;
    readonly bold?: boolean;
    readonly required?: boolean;
    readonly righHandComponent?: JSX.Element;
}

export const FormTextAreaInput = React.forwardRef<HTMLTextAreaElement, FormTextAreaInputProps>(
    (
        {
            id,
            rows,
            labelText,
            explanation,
            nextBestActionButton,
            value,
            placeholder,
            onChange,
            autoFocus = false,
            disabled = false,
            bold = false,
            required = false,
            righHandComponent
        }: FormTextAreaInputProps,
        ref
    ) => {
        return (
            <div className="flex flex-col w-full">
                {labelText && (
                    <div className="mb-2 flex items-center justify-between">
                        <div className="grid grid-cols-1">
                            <label htmlFor={id} className="text-sm">
                                {labelText}
                            </label>
                            {explanation && explanation}
                        </div>
                        {nextBestActionButton && nextBestActionButton}
                    </div>
                )}
                <div className="flex items-center justify-between w-full">
                    <textarea
                        id={id}
                        rows={rows}
                        ref={ref as React.MutableRefObject<HTMLTextAreaElement>}
                        className={`mt-1 border-solid border rounded-md p-3 grow font-normal text-base sm:text-sm text-grey break-words w-full outline-none appearance-none ${
                            bold ? 'font-bold' : 'font-normal'
                        }`}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        required={required}
                    />
                    {righHandComponent && (
                        <span className="ml-2 flex justify-end min-w-max">{righHandComponent}</span>
                    )}
                </div>
            </div>
        );
    }
);

FormTextAreaInput.displayName = 'FormTextAreaInput';
