import React from 'react';
import LinkArrowIcon from './assets/icons/link-arrow.svg';
import { ButtonMain } from './components/ButtonMain';
import { Tab, Tabs } from './components/shared/Tabs';
import { SupportLearnTheBasics } from './SupportLearnTheBasics ';
import { SupportTipsAndStrategies } from './SupportTipsAndStrategies';
import { VerticalSpacer } from './components/VerticalSpacer';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { StartHeader } from './components/StartHeader';
import { RouteLink, useCurrentUrlPath } from './Routing';

export type SupportView = 'tips-and-strategies' | 'learn-the-basics';

function rendersSupportViewContent(supportView: SupportView): JSX.Element {
    switch (supportView) {
        case 'tips-and-strategies':
            return <SupportTipsAndStrategies />;
        case 'learn-the-basics':
            return <SupportLearnTheBasics />;
        default:
            return <SupportTipsAndStrategies />;
    }
}

export const Support: React.FC = () => {
    const tabView: SupportView = (useCurrentUrlPath()?.level2 as SupportView) ?? 'learn-the-basics';

    const isMobile = useIsMobileScreenSize();

    return (
        <div>
            <StartHeader
                primaryHeaderLabel="Support"
                secondaryHeaderText="Learn tips and strategies on how to use Sniply, or contact our Help Desk."
                button={
                    isMobile ? (
                        <ButtonMain size="extra-small">
                            <a
                                className="flex items-center text-sm"
                                href="https://support.snip.ly/"
                            >
                                Help Desk
                                <div className="ml-2">
                                    <img className="h-2 w-2" src={LinkArrowIcon} />
                                </div>
                            </a>
                        </ButtonMain>
                    ) : (
                        <ButtonMain width="30">
                            <a className="flex items-center" href="https://support.snip.ly/">
                                Help Desk
                                <div className="ml-2">
                                    <img className="h-2 w-2" src={LinkArrowIcon} />
                                </div>
                            </a>
                        </ButtonMain>
                    )
                }
            />
            {isMobile ? <VerticalSpacer heightValue={5} /> : <VerticalSpacer heightValue={10} />}
            <Tabs
                tabs={[
                    { id: 'learn-the-basics', displayText: 'Learn the basics' },
                    { id: 'tips-and-strategies', displayText: 'Tips & Strategies' }
                ]}
                currentTabView={tabView ?? 'team'}
                renderTabSelectionButton={(tab: Tab) => (
                    <button className="text-base h-6 px-2">
                        <RouteLink href={`/support/${tab.id}`}>{tab.displayText}</RouteLink>
                    </button>
                )}
            />
            <p className="border-b border-grey-lighter" />
            <VerticalSpacer heightValue={10} />
            {rendersSupportViewContent(tabView)}
        </div>
    );
};
