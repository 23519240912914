import React from 'react';

export interface Notification {
    readonly messageText: string;
    readonly type: 'warning' | 'success';
    readonly showTimeInSeconds?: number;
    readonly iconSrc?: string;
    readonly buttonText?: string;
    readonly onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly hasCloseButton?: boolean;
}

interface NotificationContextValue {
    readonly showNotification: boolean;
    readonly notification: Notification;
    readonly handleOpenNotification: (notification: Notification) => void;
    readonly handleCloseNotification: () => void;
}

export const NotificationContext = React.createContext<NotificationContextValue>(undefined);

interface NotificationContextProviderProps {
    readonly children: React.ReactNode;
}

export const NotificationContextProvider: React.FC<NotificationContextProviderProps> = ({
    children
}) => {
    const [showNotification, setShowNotification] = React.useState(false);
    const [notification, setNotification] = React.useState<Notification>();

    const handleOpenNotification = (currentNotification: Notification) => {
        setNotification(currentNotification);
        setShowNotification(showNotification ? false : true);
    };
    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    return (
        <NotificationContext.Provider
            value={{
                showNotification,
                notification,
                handleOpenNotification,
                handleCloseNotification
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
