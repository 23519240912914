import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid } from './components/StandardContentGrid';
import { RouteLink } from './Routing';
import { TeamService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { FormTextInput } from './components/FormTextInput';

export const CustomizationUtmTags: React.FC = () => {
    const [utmSource, setUtmSource] = React.useState<string>('');
    const [utmCampaign, setUtmCampaign] = React.useState<string>('');
    const [utmMedium, setUtmMedium] = React.useState<string>('');
    const [utmContent, setUtmContent] = React.useState<string>('');
    const [utmTerm, setUtmTerm] = React.useState<string>('');
    const [utmOverride, setUtmOverride] = React.useState<boolean>();
    const [utmPassThroughParams, setUtmPassThroughParams] = React.useState<boolean>(false);

    const [fetchingInitialData, setFetchingInitialData] = React.useState(false);
    const [errorFetchingInitialData, setErrorFetchingInitialData] = React.useState(false);

    const [updatingData, setUpdatingData] = React.useState(false);
    const [errorUpdatingData, setErrorUpdatingData] = React.useState(false);

    const [successUpdatingData, setSuccessUpdatingData] = React.useState(false);

    const handleChangeUtmSettings = React.useCallback(
        (event) => {
            event.preventDefault();
            setUpdatingData(true);

            TeamService.teamPartialUpdate({
                requestBody: {
                    utm_source_default: utmSource,
                    utm_campaign_default: utmCampaign,
                    utm_medium_default: utmMedium,
                    utm_content_default: utmContent,
                    utm_term_default: utmTerm,
                    utm_override_with_url: utmOverride,
                    transfer_all_parameters: utmPassThroughParams
                }
            })
                .then((result) => {
                    console.log('TeamService.teamPartialUpdate() of utm settings', result);
                    setUpdatingData(false);
                    setSuccessUpdatingData(true);

                    setTimeout(() => setSuccessUpdatingData(false), 5000);
                })
                .catch((error) => {
                    console.log('Error changing amp settings.', error);
                    setErrorUpdatingData(true);
                    setUpdatingData(false);
                });
        },
        [
            utmSource,
            utmCampaign,
            utmMedium,
            utmContent,
            utmTerm,
            utmOverride,
            utmPassThroughParams,
            setErrorUpdatingData
        ]
    );

    React.useEffect(() => {
        setFetchingInitialData(true);

        TeamService.teamRetrieve()
            .then((result) => {
                console.log('TeamService.teamRetrieve()', result);
                setFetchingInitialData(false);

                setUtmSource(result.utm_source_default);
                setUtmCampaign(result.utm_campaign_default);
                setUtmMedium(result.utm_medium_default);
                setUtmContent(result.utm_content_default);
                setUtmTerm(result.utm_term_default);
                setUtmOverride(result.utm_override_with_url);
                setUtmPassThroughParams(result.transfer_all_parameters);
            })
            .catch((error) => {
                console.log('Error fetching from TeamService.teamRetrieve()', error);
                setFetchingInitialData(false);
                setErrorFetchingInitialData(true);
            });
    }, []);

    return (
        <StandardContentGrid>
            <ContentElementContainer>
                <h3 className="font-semibold">UTM Tag Settings</h3>
                {fetchingInitialData ? (
                    <div className="flex items-center justify-center">
                        <LoadingSpinner size={5} />
                    </div>
                ) : errorFetchingInitialData ? (
                    <div>
                        Unexpected error retrieving your data. Please refresh your browser to try
                        again.
                    </div>
                ) : (
                    <div>
                        <form
                            className="mt-5 xs:mt-10 text-grey text-sm"
                            onSubmit={handleChangeUtmSettings}
                        >
                            <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
                                <FormTextInput
                                    id="utm-source-input"
                                    labelText="UTM Source"
                                    value={utmSource}
                                    onChange={(event) => {
                                        setUtmSource(event.target.value);
                                    }}
                                />
                                <FormTextInput
                                    id="utm-campaign-input"
                                    labelText="UTM Campaign"
                                    value={utmCampaign}
                                    onChange={(event) => {
                                        setUtmCampaign(event.target.value);
                                    }}
                                />
                                <FormTextInput
                                    id="utm-medium-input"
                                    labelText="UTM Medium"
                                    value={utmMedium}
                                    onChange={(event) => {
                                        setUtmMedium(event.target.value);
                                    }}
                                />
                                <FormTextInput
                                    id="utm-content-input"
                                    labelText="UTM Content"
                                    value={utmContent}
                                    onChange={(event) => {
                                        setUtmContent(event.target.value);
                                    }}
                                />
                                <FormTextInput
                                    id="utm-term-input"
                                    labelText="UTM Term"
                                    value={utmTerm}
                                    onChange={(event) => {
                                        setUtmTerm(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-2 mt-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="default-override"
                                        checked={utmOverride}
                                        onChange={() => setUtmOverride(utmOverride ? false : true)}
                                    />
                                    <label className="ml-4" htmlFor="default-override">
                                        Override these defaults with UTM tags in the URL
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="pass-all-params"
                                        checked={utmPassThroughParams}
                                        onChange={() =>
                                            setUtmPassThroughParams(
                                                utmPassThroughParams ? false : true
                                            )
                                        }
                                    />
                                    <label className="ml-4" htmlFor="pass-all-params">
                                        Pass-through all parameters (everything after the
                                        &apos;?&apos;)
                                    </label>
                                </div>
                            </div>
                            <div className="mt-4">
                                {updatingData ? (
                                    <div className="flex items-center justify-center">
                                        <LoadingSpinner size={5} />
                                    </div>
                                ) : (
                                    <ButtonMain className="w-full xs:w-40" type="submit">
                                        Save Settings
                                    </ButtonMain>
                                )}
                            </div>
                        </form>
                        {errorUpdatingData && (
                            <p className="mt-2 text-xs text-red">
                                Something went wrong when trying to save your changes. Please try
                                again.
                            </p>
                        )}
                        {successUpdatingData && (
                            <p className="mt-2 text-xs text-green">
                                Your settings were successfully updated.
                            </p>
                        )}
                    </div>
                )}
            </ContentElementContainer>
            <ContentElementContainer>
                <SimpleContentBox headerText="UTM Tags">
                    <div className="font-normal text-sm text-grey">
                        <p>
                            UTM tags allow you to track visitor sources and segment campaign traffic
                            in your analytics.
                        </p>
                        <br />
                        <p>
                            <span>
                                Here you can set the UTM tag values Sniply will use on your snips.
                                For more details, we&apos;ve got a{' '}
                            </span>
                            <RouteLink
                                href="https://support.snip.ly/hc/en-us/articles/20000965256084-How-does-Sniply-handle-UTM-Tags-"
                                className="text-primary underline"
                            >
                                guide
                            </RouteLink>
                            <span>.</span>
                        </p>
                    </div>
                </SimpleContentBox>
            </ContentElementContainer>
        </StandardContentGrid>
    );
};
