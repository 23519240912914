import React from 'react';
import { BASE_DOMAIN } from './App';

import DeleteIcon from './assets/icons/delete.svg';
import EditIcon from './assets/icons/edit.svg';
import MenuBulletsIcon from './assets/icons/menu-bullets-horizontal.svg';
import { SearchInput } from './components/SearchInput';
import { TableButton } from './components/TableButton';
import { Workspace } from './data-transformations/create-workspaces-list';
import { removeProtocolFromUrl } from './utils/remove-protocol-from-url';
import { TeamMembersByWorkspace } from './WorkspaceContextProvider';

interface WorkspacesEditRowButtonProps {
    readonly workspace: Workspace;
    readonly onDeActivateWorkspaceClick: (id: string) => void;
    readonly onReActivateWorkspaceClick: (id: string) => void;
    readonly onEditWorkspaceClick: (id: string) => void;
}

export const WorkspaceEditRowButtonDropdown: React.FC<WorkspacesEditRowButtonProps> = ({
    workspace,
    onDeActivateWorkspaceClick,
    onReActivateWorkspaceClick,
    onEditWorkspaceClick
}) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end" ref={containerRef}>
            <button onClick={() => setIsMenuOpen(isMenuOpen ? false : true)}>
                <img className="h-4 w-4" src={MenuBulletsIcon} />
            </button>
            {isMenuOpen && (
                <ul className="z-1 absolute -top-2 right-0 p-2 text-sm bg-white border border-grey-lightest rounded-md w-36 shadow-lg">
                    {workspace.inActive
                        ? onReActivateWorkspaceClick && (
                              <li className="text-grey">
                                  <button
                                      className="flex items-center"
                                      onClick={() => {
                                          onReActivateWorkspaceClick(workspace.id);

                                          setIsMenuOpen(false);
                                      }}
                                  >
                                      <img src={DeleteIcon} alt="delete icon" className="w-4 h-4" />
                                      <span className="ml-2">Re-Activate</span>
                                  </button>
                              </li>
                          )
                        : onDeActivateWorkspaceClick && (
                              <li className="text-grey">
                                  <button
                                      className="flex items-center"
                                      onClick={() => {
                                          onDeActivateWorkspaceClick(workspace.id);

                                          setIsMenuOpen(false);
                                      }}
                                  >
                                      <img src={DeleteIcon} alt="delete icon" className="w-4 h-4" />
                                      <span className="ml-2">De-Activate</span>
                                  </button>
                              </li>
                          )}
                    {onEditWorkspaceClick && (
                        <li className="mt-2 text-grey">
                            <button
                                className="flex items-center"
                                onClick={() => {
                                    onEditWorkspaceClick(workspace.id);
                                    setIsMenuOpen(false);
                                }}
                            >
                                <img src={EditIcon} alt="edit icon" className="w-4 h-4" />
                                <span className="ml-2">Edit</span>
                            </button>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

const WorkspacesTableHeader: React.FC = () => {
    return (
        <div className="p-2 grid grid-cols-4-4-4-2-1 gap-2 font-semibold border-b border-grey-lighter text-xs sm:text-sm">
            <span className="ml-8">Name</span>
            <span>Default Domain</span>
            <span>Team</span>
            <span>Snips</span>
            <span></span>
        </div>
    );
};

interface WorkspaceRowProps {
    readonly workspace: Workspace;
    readonly numberOfTeamMembers: number;
    readonly onDeActivateWorkspace: (id: string) => void;
    readonly onReActivateWorkspace: (id: string) => void;
    readonly onEditWorkspace: (id: string) => void;
}

const WorkspacesRow: React.FC<WorkspaceRowProps> = ({
    workspace,
    numberOfTeamMembers,
    onDeActivateWorkspace,
    onReActivateWorkspace,
    onEditWorkspace
}) => {
    return (
        <div
            className={`w-full p-2 grid gap-3 grid-cols-4-4-4-2-1 items-center ${
                workspace.inActive ? 'text-grey-light' : 'text-grey'
            }`}
        >
            <div className="flex items-center min-w-0">
                <div className="h-6 w-6 rounded-full border border-white bg-black text-sm text-white text-center shrink-0">
                    {!workspace?.imageUrl ||
                    workspace?.imageUrl ===
                        'https://ffb2efd5105ff0aedbc9-9cdacdeebf0faa19b665bf427f0c8092.ssl.cf1.rackcdn.com/img/profile-placeholder.png' ? (
                        workspace?.name.slice(0, 1)
                    ) : (
                        <img src={workspace?.imageUrl} className="w-6 rounded-full" />
                    )}
                </div>
                <span className={`ml-2 truncate text-sm sm:text-base`}>{workspace.name}</span>
            </div>
            <span className="text-sm">
                {removeProtocolFromUrl(workspace.defaultCustomDomainName || BASE_DOMAIN)}
            </span>
            <div className="text-sm">
                {numberOfTeamMembers
                    ? numberOfTeamMembers === 1
                        ? `${numberOfTeamMembers} Member`
                        : `${numberOfTeamMembers} Members`
                    : ''}
            </div>
            <div className="text-sm">{workspace.numberOfLinks || '-'}</div>
            <WorkspaceEditRowButtonDropdown
                workspace={workspace}
                onDeActivateWorkspaceClick={onDeActivateWorkspace}
                onReActivateWorkspaceClick={onReActivateWorkspace}
                onEditWorkspaceClick={onEditWorkspace}
            />
        </div>
    );
};

interface WorkspacesTableProps {
    readonly workspaces: Workspace[];
    readonly teamMembersByWorkspace: TeamMembersByWorkspace;
    readonly onDeActivateWorkspaceClick: (id: string) => void;
    readonly onReActivateWorkspaceClick: (id: string) => void;
    readonly onEditWorkspaceClick: (id: string) => void;
    readonly onAddWorkspaceClick: () => void;
}

export const WorkspacesTable: React.FC<WorkspacesTableProps> = ({
    workspaces,
    teamMembersByWorkspace,
    onEditWorkspaceClick,
    onDeActivateWorkspaceClick,
    onReActivateWorkspaceClick,
    onAddWorkspaceClick
}) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    return (
        <div>
            <div className="flex justify-end">
                <TableButton onClick={onAddWorkspaceClick}>+ Add Workspace</TableButton>
            </div>
            <div className="mt-6 grid grid-cols-1 xs:grid-cols-2">
                <div className="flex items-center">
                    <h3 className="font-semibold">Workspaces</h3>
                    {workspaces && (
                        <span className="ml-1 text-grey-light">{`(${workspaces.length})`}</span>
                    )}
                </div>
                <div className="flex items-center mt-2 xs:mt-0 xs:justify-end">
                    <SearchInput
                        searchTermState={searchTerm}
                        placeholderText="search..."
                        setSearchTermState={setSearchTerm}
                        onClearSearch={() => setSearchTerm('')}
                        isCollapsible={false}
                        isRounded
                    />
                </div>
            </div>
            <div className="mt-2 h-screen-60 overflow-y-scroll">
                <div className="mt-6 w-full">
                    <WorkspacesTableHeader />
                    {workspaces
                        ?.filter((workspace) =>
                            workspace.name?.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((workspace) => (
                            <WorkspacesRow
                                key={workspace.id}
                                workspace={workspace}
                                numberOfTeamMembers={
                                    teamMembersByWorkspace.find(
                                        (workspaceWithTeamMembers) =>
                                            workspaceWithTeamMembers?.workspaceId === workspace.id
                                    )?.teamMembers.length
                                }
                                onDeActivateWorkspace={
                                    workspaces.length > 1 && onDeActivateWorkspaceClick
                                }
                                onReActivateWorkspace={onReActivateWorkspaceClick}
                                onEditWorkspace={onEditWorkspaceClick}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};
