import React from 'react';
import { ButtonMain } from './ButtonMain';

import SettingsIconWhite from '../assets/icons/settings.svg';
import SettingsIcon from '../assets/icons/settings-grey.svg';

interface ButtonSettingsProps {
    readonly onClick: () => void;
}

const ButtonSettings: React.FC<ButtonSettingsProps> = ({ onClick }) => {
    const [hoveringButton, setHoveringButton] = React.useState(false);

    return (
        <ButtonMain
            size="extra-small"
            style="secondary"
            onMouseOver={() => setHoveringButton(true)}
            onMouseLeave={() => setHoveringButton(false)}
            onClick={(event) => {
                event.preventDefault();
                onClick();
            }}
        >
            <img
                src={hoveringButton ? SettingsIconWhite : SettingsIcon}
                alt="copy icon"
                className="w-4 h-4"
            />
        </ButtonMain>
    );
};

export default ButtonSettings;
