export function historyBackWithFallback(fallbackUrl?: string): void {
    fallbackUrl = fallbackUrl || '/';
    const previousRoute = window.location.href;

    window.history.back();

    setTimeout(() => {
        if (window.location.href == previousRoute) {
            window.location.href = fallbackUrl;
        }
    }, 500);
}
