import React from 'react';
import { useWindowSize } from '../../hooks/use-window-size';

interface ItemSpecificHeaderProps {
    readonly snipUrl: string;
    readonly breadcrumbRoot: JSX.Element;
    readonly currentItemName: string;
    readonly secondaryHeaderText?: string;
    readonly editButtonLabel?: string;
    readonly onEditItemClick?: (itemId: string) => void;
    readonly copyButton?: JSX.Element;
    readonly mainButton?: JSX.Element;
}

export const ItemSpecificHeader: React.FC<ItemSpecificHeaderProps> = ({
    snipUrl,
    breadcrumbRoot,
    currentItemName,
    secondaryHeaderText,
    copyButton,
    mainButton
}) => {
    const { width } = useWindowSize();
    const isTinyScreen = width < 375;

    return (
        <div className="flex justify-between">
            <div>
                <div className="flex items-center flex-wrap">
                    {breadcrumbRoot}
                    <div className="flex items-center">
                        {currentItemName && (
                            <svg
                                className="h-2 w-2 mx-2"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.25 8.5L4.75 5L1.25 1.5"
                                    stroke="#465C6F"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                        <a
                            href={snipUrl}
                            className="font-semibold text-base text-grey hover:underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {currentItemName}
                        </a>
                        <div className="flex ml-2 items-center justify-between">
                            <div className="flex items-center">
                                {copyButton && snipUrl && (
                                    <div className="flex items-center justify-center">
                                        {copyButton}
                                    </div>
                                )}
                            </div>
                            {isTinyScreen && mainButton && mainButton}
                        </div>
                    </div>
                </div>
                {secondaryHeaderText && (
                    <p className="mt-1 text-sm text-grey-light">{secondaryHeaderText}</p>
                )}
            </div>
            {!isTinyScreen && mainButton && mainButton}
        </div>
    );
};
