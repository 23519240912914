import React from 'react';

import { ContentService, LinkClipboardService } from './api';
import CalendarIcon from './assets/icons/calendar-grey.svg';
import HideIcon from './assets/icons/hide.svg';
import LinkArrowIcon from './assets/icons/link-arrow-grey.svg';
// import BranchIcon from './assets/icons/branch.svg';
// import SettingsIcon from './assets/icons/settings.svg';
import SourceIcon from './assets/icons/source-grey.svg';
import SuccessIcon from './assets/icons/tick-white.svg';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { LoadingSpinner } from './components/LoadingSpinner';
import { StartHeader } from './components/StartHeader';
import { VerticalSpacer } from './components/VerticalSpacer';
import { ContentListItem, createContentList } from './data-transformations/create-content-list';
import { NotificationContext } from './NotificationContextProvider';
import { navigateTo } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';

function createSuggesteKeywords(completeListOfKeywords: string[]): string[] {
    const shuffledListOfKeywords = completeListOfKeywords.sort(() => 0.5 - Math.random());

    return shuffledListOfKeywords.slice(0, 4);
}

export interface DiscoverContentCardProps {
    readonly contentItem: ContentListItem;
    readonly onHideItem: (itemUrl: string) => void;
    readonly snipButton?: JSX.Element;
    readonly saveButton?: JSX.Element;
}

export const DiscoverContentCard: React.FC<DiscoverContentCardProps> = ({
    contentItem: { linkUrl, imageUrl, titleText, additionalText, sourceName, sourceUrl, issueDate },
    onHideItem,
    snipButton,
    saveButton
}) => (
    <div className="p-4 border border-grey-lighter bg-white text-grey">
        <div className="px-2 pt-2 pb-4 grid grid-cols-1 sm:grid-cols-2-1 gap-4 border-b border-grey-lighter">
            <div className="flex items-start w-full">
                <img src={imageUrl} alt="image" className="w-15 sm:w-24" />
                <div className="-mt-1 ml-2">
                    <p className="font-semibold">{titleText}</p>
                    <p className="mt-1 text-sm">{additionalText}</p>
                </div>
            </div>
            <div className="flex text-grey-light items-center sm:items-start justify-center sm:justify-end">
                <button
                    className="flex items-center"
                    onClick={(event) => {
                        event.preventDefault();
                        onHideItem(linkUrl);
                    }}
                >
                    <img src={HideIcon} alt="hide icon" className="w-4 h-4" />
                    <span className="ml-2">Hide</span>
                </button>
                <a className="ml-10 flex items-center" href={linkUrl}>
                    <img src={LinkArrowIcon} alt="link icon" className="w-4 h-4" />
                    <span className="ml-2">View</span>
                </a>
            </div>
        </div>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2-1 gap-y-4 gap-x-2">
            <div className="flex">
                <a className="flex items-center" href={sourceUrl}>
                    <img src={SourceIcon} alt="source icon" className="w-4 h-4" />
                    <span className="ml-2 text-grey-light text-sm">{sourceName}</span>
                </a>
                <div className="ml-10 flex items-center">
                    <img src={CalendarIcon} alt="date icon" className="w-4 h-4" />
                    <span className="ml-2 text-grey-light text-sm">{issueDate}</span>
                </div>
            </div>
            <div className="flex">
                {saveButton && saveButton}
                <div className="ml-4 w-full">{snipButton}</div>
            </div>
        </div>
    </div>
);

export const DiscoverContent: React.FC = () => {
    const [keywords, setKeywords] = React.useState('');
    const [suggestedKeywords, setSuggestedKeywords] = React.useState<string[]>([]);
    const [contentData, setContentData] = React.useState<ContentListItem[]>([]);

    const [suggestedKeywordUsed, setSuggestedKeywordUsed] = React.useState('');

    const [isLoadingContentData, setIsLoadingContentData] = React.useState(false);
    const [hadErrorLoadingContentData, setHadErrorLoadingContentData] = React.useState(false);
    const [isSavingToClipboard, setIsSavingToClipboard] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleSubmitKeywordSearch = React.useCallback((searchTerm: string) => {
        setIsLoadingContentData(true);

        ContentService.contentCreate({ requestBody: { search_term: searchTerm } })
            .then((result) => {
                setContentData(createContentList(result));
                setIsLoadingContentData(false);
            })
            .catch((error) => {
                console.error(
                    'Error fetching content for search term from ContentService.contentCreate()',
                    error
                );
                setHadErrorLoadingContentData(true);
            });
    }, []);

    const handleHideItem = React.useCallback(
        (itemUrl: string) => {
            setContentData(contentData.filter((item) => item.linkUrl !== itemUrl));
        },
        [contentData]
    );

    const handleSnipItem = React.useCallback(
        (contentItem: ContentListItem) => {
            ContentService.contentSnippedCreate({
                requestBody: {
                    url: contentItem.linkUrl,
                    title: contentItem.titleText,
                    description: contentItem.additionalText,
                    image_url: contentItem.imageUrl,
                    content_search: suggestedKeywordUsed || keywords,
                    snipped: true
                }
            }).catch((error) => console.error(error));

            navigateTo(`/snip-create/content-select?discoverContentItemUrl=${contentItem.linkUrl}`);
        },
        [keywords, suggestedKeywordUsed]
    );

    const handleSaveItemToClipboard = React.useCallback(
        (itemUrl: string) => {
            setIsSavingToClipboard(true);

            LinkClipboardService.linkClipboardCreate({
                requestBody: { brand: currentWorkspace.id, url: itemUrl }
            })
                .then(() => {
                    setIsSavingToClipboard(false);
                    setContentData(contentData.filter((item) => item.linkUrl !== itemUrl));

                    handleOpenNotification({
                        messageText: 'Your content has been saved to the Clipboard.',
                        type: 'success',
                        iconSrc: SuccessIcon,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.error(error);

                    handleOpenNotification({
                        messageText:
                            'Your content could not be saved at this point in time. Please try again.',
                        type: 'warning',
                        showTimeInSeconds: 3
                    });
                });
        },
        [contentData, currentWorkspace, handleOpenNotification]
    );

    React.useEffect(() => {
        ContentService.contentTopicsList()
            .then((result) => {
                const listOfSuggestions = createSuggesteKeywords(result);
                setSuggestedKeywords(listOfSuggestions);
                setSuggestedKeywordUsed(listOfSuggestions[0]);
                handleSubmitKeywordSearch(listOfSuggestions[0]);
            })
            .catch((error) => console.error(error));
    }, [handleSubmitKeywordSearch]);

    return (
        <div className="grid grid-cols-1 gap-y-5 text-grey">
            <StartHeader
                primaryHeaderLabel="Discover Content"
                secondaryHeaderText="Find content based on keywords and use it for Snips or save to your Clipboard for later use."
            />
            <ContentElementContainer>
                <p className="text-sm">
                    Sniply is recommending content from many of the most popular sources.
                </p>
                <VerticalSpacer heightValue={4} />
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2-1 items-center">
                    <FormTextInput
                        id="keywords-input"
                        value={keywords}
                        onChange={(event) => setKeywords(event.target.value)}
                        placeholder="Enter keywords"
                        autoFocus
                    />
                    <div className="flex items-center">
                        <ButtonMain
                            className="w-full sm:w-24"
                            onClick={(event) => {
                                event.preventDefault();
                                setSuggestedKeywordUsed('');
                                keywords && handleSubmitKeywordSearch(keywords);
                            }}
                        >
                            Search
                        </ButtonMain>
                    </div>
                    {/* <div className="flex items-center justify-between w-full px-3 h-10 grow text-sm text-grey">
                        <div className="flex items-center">
                            <img src={BranchIcon} alt="sources icon" className="w-4 h-4" />
                            <span className="ml-4">{`${numberOfSources} Sources`}</span>
                        </div>
                        <ButtonMain size="small">
                            <img src={SettingsIcon} alt="settings icon" className="w-5 h-5" />
                        </ButtonMain>
                    </div> */}
                </div>
                {suggestedKeywords.length > 0 && (
                    <div className="mt-4">
                        <p className="text-sm text-grey-light">Perhaps try these topics:</p>
                        <ul className="flex flex-wrap">
                            {suggestedKeywords.map((keyword, index) => (
                                <li key={keyword} className={`mt-2 ${index > 0 ? 'ml-2' : 'ml-0'}`}>
                                    <ButtonMain
                                        className="w-auto"
                                        style={
                                            suggestedKeywordUsed && suggestedKeywordUsed === keyword
                                                ? 'secondary'
                                                : 'neutral'
                                        }
                                        size="extra-small"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setSuggestedKeywordUsed(keyword);
                                            handleSubmitKeywordSearch(keyword);
                                        }}
                                    >
                                        {keyword}
                                    </ButtonMain>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </ContentElementContainer>
            {isLoadingContentData ? (
                <div className="mt-12 flex w-full h-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : hadErrorLoadingContentData ? (
                <div className="mt-6">
                    <p className="text-sm text-red">
                        Something went wrong while fetching your content recommendations. Please try
                        again.
                    </p>
                </div>
            ) : contentData.length > 0 ? (
                <div>
                    <div className="ml-1 text-sm">
                        <span className="font-semibold">{contentData.length}</span>
                        <span> Results</span>
                    </div>
                    <div className="mt-2 grid grid-cols-1 gap-4">
                        {contentData.map((contentItem) => (
                            <DiscoverContentCard
                                key={contentItem.linkUrl}
                                contentItem={contentItem}
                                onHideItem={handleHideItem}
                                snipButton={
                                    <ButtonMain
                                        size="small"
                                        width="full"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleSnipItem(contentItem);
                                        }}
                                    >
                                        Snip
                                    </ButtonMain>
                                }
                                saveButton={
                                    isSavingToClipboard ? (
                                        <div className="w-full h-full flex items-center justify-center">
                                            <LoadingSpinner size={5} />
                                        </div>
                                    ) : (
                                        <ButtonMain
                                            style="neutral"
                                            size="small"
                                            width="full"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleSaveItemToClipboard(contentItem.linkUrl);
                                            }}
                                        >
                                            Save
                                        </ButtonMain>
                                    )
                                }
                            />
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};
