import React from 'react';

interface SideNavigationLayoutProps {
    readonly logo: JSX.Element;
    readonly collapseExpandButton?: JSX.Element;
    readonly navigationGroupListTop: JSX.Element;
    readonly createLinkButton: JSX.Element;
    readonly navigationGroupListBottom: JSX.Element;
    readonly beamerLink: JSX.Element;
    readonly slim?: boolean;
    readonly userSettings: JSX.Element;
}

export const SideNavigationLayout: React.FC<SideNavigationLayoutProps> = ({
    logo,
    collapseExpandButton = false,
    navigationGroupListTop,
    createLinkButton,
    navigationGroupListBottom,
    beamerLink,
    slim = false,
    userSettings
}) => {
    return (
        <div className={`h-full ${slim ? 'w-16' : 'w-64'}`}>
            <div className="flex justify-between">
                <div className="px-5">{logo}</div>
                {collapseExpandButton && (
                    <div className={`h-7 ${slim && 'absolute left-12'}`}>
                        {collapseExpandButton}
                    </div>
                )}
            </div>
            <div className="h-full grid grid-cols-1 content-between overflow-y-scroll">
                <div className=" mt-5.5 semiTall:mt-9 tall:mt-14">{navigationGroupListTop}</div>
                <div className="flex flex-col mb-2.5">
                    <div className="px-2">{createLinkButton}</div>
                    <div className="mt-4 semiTall:mt-6">
                        {navigationGroupListBottom}
                        {beamerLink}
                        <div className="flex items-center h-20 border-t border-grey-lighter mt-4 pt-2">
                            {userSettings}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
