/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmbeddedWebsite } from '../models/EmbeddedWebsite';
import type { EmbeddedWebsiteRequest } from '../models/EmbeddedWebsiteRequest';
import type { PaginatedEmbeddedWebsiteList } from '../models/PaginatedEmbeddedWebsiteList';
import type { PatchedEmbeddedWebsiteRequest } from '../models/PatchedEmbeddedWebsiteRequest';
import type { SiteInstallationVerification } from '../models/SiteInstallationVerification';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SitesService {

    /**
     * @returns PaginatedEmbeddedWebsiteList
     * @throws ApiError
     */
    public static sitesList({
        brand,
        ordering,
        page,
        pageSize,
        search,
    }: {
        brand?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEmbeddedWebsiteList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/sites/',
            query: {
                'brand': brand,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns EmbeddedWebsite
     * @throws ApiError
     */
    public static sitesCreate({
        requestBody,
    }: {
        requestBody: EmbeddedWebsiteRequest,
    }): CancelablePromise<EmbeddedWebsite> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/sites/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EmbeddedWebsite
     * @throws ApiError
     */
    public static sitesRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<EmbeddedWebsite> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/sites/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EmbeddedWebsite
     * @throws ApiError
     */
    public static sitesPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedEmbeddedWebsiteRequest,
    }): CancelablePromise<EmbeddedWebsite> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/sites/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static sitesDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/sites/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SiteInstallationVerification
     * @throws ApiError
     */
    public static sitesVerifyPartialUpdate({
        id,
    }: {
        id: string,
    }): CancelablePromise<SiteInstallationVerification> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/sites/{id}/verify',
            path: {
                'id': id,
            },
        });
    }

}
