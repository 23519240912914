import React from 'react';
import { CheckIfUrlSnippableService, TeamService } from '../../api';
import ButtonBack from '../../components/ButtonBack';
import { CtaType } from '../../components/CtaTypeDropdownSelect';
import { SnipCreateCtaSelect } from './SnipCreateCtaSelect';
import { navigateTo, RouteLink, useCurrentUrlPath } from '../../Routing';
import { ContentDisplayCardItem, SnipCreateSelectContent } from './SnipCreateSelectContent';
import { isValidUrlWithProtocol } from '../../utils/is-valid-url';
import CloseIcon from '../../assets/icons/close.svg';
import { useHasSpaceForCtaSidePreview } from '../../hooks/use-has-space-for-cta-side-preview';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import { LegacyCta } from '../../cta-components/LegacyCta';
import { CtaPosition, CtasListItem, CtaTheme } from '../../data-transformations/create-cta-data';
import {
    DemoContentIntegrator,
    DemoView,
    PreviewViewSelectionTab
} from '../../components/DemoContentIntegrator';
import { useElementWidth } from '../../hooks/use-element-width';
import { MOBILE_VIEW_THRESHOLD } from '../../hooks/use-is-mobile-screen-size';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_FORM_CTA_PLACEHOLDER_TEXT,
    DEFAULT_LINK_TEXT_COLOR,
    DEFAULT_MESSAGE_TEXT_COLOR
} from '../../utils/default-cta-configuration';
import { UserContext } from '../../UserContextProvider';
import { UpgradePlanMessageContext } from '../../UpgradePlanMessageContextProvider';
import { hasHitPlanLimitForCtaImpressions } from '../../utils/has-hit-plan-limit-for-cta-impressions';
import { historyBackWithFallback } from '../../utils/history-back-with-fallback';
import { SnipUrls, createSnipUrlsData } from '../../data-transformations/create-snip-urls-data';
import { SnipsListItem } from '../../data-transformations/create-snip-data';
import { SnipCreateInitialSnipCreated } from './SnipCreateInitialSnipCreated';
import { SnipCreatePrepareContent } from './SnipCreatePrepareContent';
import { getNextHigherPlan } from '../../utils/get-next-higher-plan';

export const SnipCreate = () => {
    const [showCtaSidePreview, setShowCtaSidePreview] = React.useState(false);
    // This is used to decide if on reload on later from pages to send the user back to the snip part.
    const [hasJourneyBeenStarted, setHasJourneyBeenStarted] = React.useState(false);

    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    const [clipboardItemId, setClipboardItemId] = React.useState('');
    const [contentItemInformation, setContentItemInformation] =
        React.useState<ContentDisplayCardItem>();

    const [snipUrls, setSnipUrls] = React.useState<SnipUrls>();
    const [initiallyCreatedSnip, setInitiallyCreatedSnip] = React.useState<SnipsListItem>();

    // Form Values --- Start
    const [callToActionUrl, setCallToActionUrl] = React.useState('');
    const [originalContentUrl, setOriginalContentUrl] = React.useState('');

    // This following is the Url used in the iFrame inside by the renderer application
    // and the demo / preview of this application.
    // It can be either a) the original URL, b) a Sniply Proxy Url or c) a Sniply Summary Url
    const [sniplyContentUrl, setSniplyContentUrl] = React.useState('');

    const [ctaId, setCtaId] = React.useState('');
    const [ctaName, setCtaName] = React.useState('');
    const [ctaType, setCtaType] = React.useState<CtaType>('button');

    const [headlineText, setHeadlineText] = React.useState('Your Headline');
    const [imageUrl, setImageUrl] = React.useState<string>();

    const [messageText, setMessageText] = React.useState('Your Message');
    const [messageTextColor, setMessageTextColor] = React.useState(DEFAULT_MESSAGE_TEXT_COLOR);

    const [buttonText, setButtonText] = React.useState('Click here');
    const [buttonTextColor, setButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);

    const [linkText, setLinkText] = React.useState('Click here');
    const [linkTextColor, setLinkTextColor] = React.useState(DEFAULT_LINK_TEXT_COLOR);

    const [backgroundColor, setBackgroundColor] = React.useState(DEFAULT_BACKGROUND_COLOR);
    const [buttonColor, setButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);

    const [formButtonTextColor, setFormButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);
    const [formButtonColor, setFormButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);
    const [formPlaceholderText, setFormPlaceholderText] = React.useState(
        DEFAULT_FORM_CTA_PLACEHOLDER_TEXT
    );

    const [imageAdUrl, setImageAdUrl] = React.useState('');

    const [theme, setTheme] = React.useState<CtaTheme>('social');
    const [position, setPosition] = React.useState<CtaPosition>('bottom-left');

    const [showSniplyLogo, setShowSniplyLogo] = React.useState(true);
    const [showWhiteSniplyLogo, setShowWhiteSniplyLogo] = React.useState(false);

    const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>(undefined);
    const [imageAdPreviewUrl, setImageAdPreviewUrl] = React.useState<string>(undefined);
    // Form Values --- End

    // Snippability States --- Start
    const [checkingSnippability, setCheckingSnippability] = React.useState(false);
    const [hasSnippabilityStatusBeenChecked, setHasSnippabilityStatusBeenChecked] =
        React.useState(false);

    const [showContentInfoCard, setShowContentInfoCard] = React.useState(false);
    // Snippability States --- End

    const { level2, level3 } = useCurrentUrlPath();

    const snippabilityRequestIndex = React.useRef(0);

    const handleSelectCta = React.useCallback((cta: CtasListItem) => {
        setCtaId(cta.id);
        setCallToActionUrl(cta.urlToBePromoted);
        setCtaName(cta.name);
        setCtaType(cta.type);
        setImageUrl(cta.imageUrl);
        setHeadlineText(cta.headlineText);
        setMessageText(cta.messageText);
        setMessageTextColor(cta.messageTextColor);
        setBackgroundColor(cta.backgroundColor);
        setButtonColor(cta.buttonColor);
        setButtonText(cta.buttonText);
        setButtonTextColor(cta.buttonTextColor);
        setLinkText(cta.linkText);
        setLinkTextColor(cta.linkTextColor);
        setFormButtonTextColor(cta.formButtonTextColor);
        setFormButtonColor(cta.formButtonColor);
        setFormPlaceholderText(cta.formPlaceholderText);
        setImageAdUrl(cta.adImageUrl);
        setPosition(cta.position);
        setTheme(cta.theme);
        setShowSniplyLogo(cta.showSniplyLogo);
        setShowWhiteSniplyLogo(cta.showWhiteSniplyLogo);
    }, []);

    const handleResetCtaConfiguration = React.useCallback(() => {
        setCallToActionUrl('');

        setCtaId('');
        setCtaName('');
        setCtaType('button');

        setImageUrl(undefined);

        setHeadlineText('Your Headline');

        setMessageText('Your Message');
        setMessageTextColor(DEFAULT_MESSAGE_TEXT_COLOR);

        setButtonText('Click here');
        setButtonColor(DEFAULT_BUTTON_COLOR);
        setButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);

        setLinkText('Click here');
        setLinkTextColor(DEFAULT_LINK_TEXT_COLOR);

        setBackgroundColor(DEFAULT_BACKGROUND_COLOR);

        setFormButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);
        setFormButtonColor(DEFAULT_BUTTON_COLOR);
        setFormPlaceholderText(DEFAULT_FORM_CTA_PLACEHOLDER_TEXT);

        setImageAdUrl('');
        setTheme('social');
        setPosition('bottom-left');

        setShowSniplyLogo(true);
        setShowWhiteSniplyLogo(false);
    }, []);

    // Check if Content Url is snippable
    React.useEffect(() => {
        setShowContentInfoCard(false);

        if (isValidUrlWithProtocol(ensureUrlHasProtocol(originalContentUrl))) {
            const currentSnippabilityRequestIndex = snippabilityRequestIndex.current + 1;

            snippabilityRequestIndex.current = currentSnippabilityRequestIndex;

            setShowContentInfoCard(true);
            setCheckingSnippability(true);

            setTimeout(
                () => {
                    if (currentSnippabilityRequestIndex === snippabilityRequestIndex.current) {
                        CheckIfUrlSnippableService.checkIfUrlSnippableCreate({
                            requestBody: { url: ensureUrlHasProtocol(originalContentUrl) }
                        })
                            .then((result) => {
                                console.log(
                                    'CheckIfUrlSnippableService.checkIfUrlSnippableCreate()',
                                    result
                                );

                                if (
                                    currentSnippabilityRequestIndex ===
                                    snippabilityRequestIndex.current
                                ) {
                                    setContentItemInformation({
                                        titleText: result.og_data.og_title,
                                        additionalText: result.og_data.og_description,
                                        imageUrl: result.og_data.og_image,
                                        faviconUrl: result.favicon,
                                        source: result.og_data.og_source,
                                        author: result.og_data.og_author
                                    });

                                    setHasSnippabilityStatusBeenChecked(true);
                                    // we are covering a bunch of scenarios here:
                                    // if the raw url is snippable use that one,
                                    // proxy and static might not work due to websites
                                    // blacklisting sniply, so we would fall all the
                                    // way back through to the raw url which
                                    // in that case would be really not snippable.
                                    if (result.is_snippable) {
                                        setSniplyContentUrl(result.url);
                                    } else {
                                        if (result.proxy_url) {
                                            setSniplyContentUrl(result.proxy_url);
                                        } else if (result.static_page_url) {
                                            setSniplyContentUrl(result.static_page_url);
                                        } else {
                                            // Only truly unsnippable scenario to be displayed to users
                                            setSniplyContentUrl(result.url);
                                        }
                                    }

                                    const urls = createSnipUrlsData(result);
                                    setSnipUrls(urls);

                                    setCheckingSnippability(false);
                                }
                            })
                            .catch((error) => {
                                if (
                                    currentSnippabilityRequestIndex ===
                                    snippabilityRequestIndex.current
                                ) {
                                    console.log(
                                        'Error fetching from CheckIfUrlSnippableService.checkIfUrlSnippableCreate(): ',
                                        error
                                    );

                                    setHasSnippabilityStatusBeenChecked(false);
                                    setCheckingSnippability(false);
                                }
                            });
                    }
                },
                currentSnippabilityRequestIndex === 1 ? 0 : 4000
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalContentUrl]);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    const isSideBarWidthEquivalentOfMobileScreenWidth =
        useElementWidth(sideBarRef) < MOBILE_VIEW_THRESHOLD;

    React.useEffect(() => {
        if (
            (level2 === 'cta-select' && level3 === 'new' && hasScreenSpaceForSideBar) ||
            (level2 === 'cta-select' && ctaName && hasScreenSpaceForSideBar)
        ) {
            setShowCtaSidePreview(true);
        } else {
            setShowCtaSidePreview(false);
        }
    }, [level2, level3, ctaName, hasScreenSpaceForSideBar]);

    React.useEffect(() => {
        if (!isValidUrlWithProtocol(ensureUrlHasProtocol(originalContentUrl))) {
            setCheckingSnippability(false);
        }
    }, [originalContentUrl]);

    // If the Creation Process starts with a Clipboard Item, the items url and id are passed as URL params.
    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;

        const discoverContentItemUrlParam = searchParams.get('discoverContentItemUrl');
        if (discoverContentItemUrlParam) {
            setOriginalContentUrl(discoverContentItemUrlParam);
        }

        const clipboardItemUrlParam = searchParams.get('clipboardItemUrl');
        if (clipboardItemUrlParam) {
            setOriginalContentUrl(clipboardItemUrlParam);
        }

        const clipboardItemIdParam = searchParams.get('clipboardItemId');
        if (clipboardItemIdParam) {
            setClipboardItemId(clipboardItemIdParam);
        }
    }, []);

    const {
        userPlan,
        hasUsedFirstFreeSnip,
        isAppSumoPlan,
        isAppSumoMonthlyClicksLimitReached,
        currentMonthlyCtaImpressions,
        maxMonthlyCtaImpressions
    } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    // Prevent AppSumo Deal Users to create new Snips as long as they reached their monthly clicks limit.
    React.useEffect(() => {
        if (isAppSumoPlan && isAppSumoMonthlyClicksLimitReached) {
            navigateTo('/');
            handleOpenUpgradePlanMessage({
                feature: 'Create Snip',
                title: 'Upgrade Your Sniply Plan',
                message:
                    'You have reached your monthly limit of Clicks per Snip. In order to keep creating new Snips for the rest of the month, you need to switch to a different plan.',
                requiredPlan: 'Enterprise'
            });
        }
    }, [handleOpenUpgradePlanMessage, isAppSumoMonthlyClicksLimitReached, isAppSumoPlan]);

    // Prevent Users to create new Snips as long as they reached their monthly limit of Cta Impressions.
    React.useEffect(() => {
        if (
            hasHitPlanLimitForCtaImpressions(currentMonthlyCtaImpressions, maxMonthlyCtaImpressions)
        ) {
            navigateTo('/');
            handleOpenUpgradePlanMessage({
                feature: 'Create Snip',
                title: 'Upgrade Your Sniply Plan',
                message:
                    'You have reached your monthly limit of CTA Impressions. In order to keep creating new Snips for the rest of the month, you need to switch to a different plan.',
                requiredPlan: getNextHigherPlan(userPlan)
            });
        }
    }, [
        handleOpenUpgradePlanMessage,
        currentMonthlyCtaImpressions,
        maxMonthlyCtaImpressions,
        userPlan
    ]);

    React.useEffect(() => {
        if (userPlan === 'Free' && hasUsedFirstFreeSnip) {
            navigateTo('/plans');

            handleOpenUpgradePlanMessage({
                feature: 'General App Usage',
                requiredPlan: 'Basic',
                title: 'Subscribe to a Sniply plan',
                message:
                    'You are not currently on a subscription, please select a plan of your choice to continue using our services.'
            });
        }
    }, [userPlan, handleOpenUpgradePlanMessage, hasUsedFirstFreeSnip]);

    // Prevent users who hit their Snips or Clicks limits to create new Snips
    React.useEffect(() => {
        if (!isAppSumoPlan && userPlan !== 'Free') {
            TeamService.teamLimitsAndBillingRetrieve().then((result) => {
                const reachedClicksLimit =
                    (result.clicks_used_this_month || 0) >= (result.click_limit || 0);

                const reachedSnipsLimit =
                    (result.links_used_this_month || 0) >= (result.link_limit || 0);

                if (reachedClicksLimit) {
                    navigateTo('/plans');

                    handleOpenUpgradePlanMessage({
                        feature: 'General App Usage',
                        requiredPlan: getNextHigherPlan(userPlan),
                        title: 'Upgrade Your Sniply plan',
                        message:
                            'You have reached your monthly limit of clicks. In order to keep creating new Snips for the rest of the month, you need to switch to a different plan.'
                    });
                } else if (reachedSnipsLimit) {
                    navigateTo('/plans');

                    handleOpenUpgradePlanMessage({
                        feature: 'General App Usage',
                        requiredPlan: getNextHigherPlan(userPlan),
                        title: 'Upgrade Your Sniply plan',
                        message:
                            'You have reached your monthly limit of Snips. In order to keep creating new Snips for the rest of the month, you need to switch to a different plan.'
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAppSumoPlan, userPlan]);

    return (
        <div className="h-full w-full flex text-grey">
            <div
                className={`h-full w-full ${
                    level2 === 'prepare-content'
                        ? 'sm:w-10/12'
                        : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                } bg-white border-r border-grey-lighter`}
            >
                <div
                    className={`absolute z-2 bg-white w-full ${
                        level2 === 'prepare-content'
                            ? 'sm:w-10/12'
                            : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                    } p-4 grid grid-cols-3 items-start`}
                >
                    <ButtonBack onClick={() => historyBackWithFallback('/')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar && level2 === 'cta-select' ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href="/">
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                    {level2 === undefined || level2 === 'content-select' ? (
                        <SnipCreateSelectContent
                            setHasJourneyBeenStarted={setHasJourneyBeenStarted}
                            originalContentUrl={originalContentUrl}
                            setOriginalContentUrl={setOriginalContentUrl}
                            sniplyContentUrl={sniplyContentUrl}
                            snipUrls={snipUrls}
                            hasSnippabilityStatusBeenChecked={hasSnippabilityStatusBeenChecked}
                            setHasSnippabilityStatusBeenChecked={
                                setHasSnippabilityStatusBeenChecked
                            }
                            checkingSnippability={checkingSnippability}
                            showContentInfoCard={showContentInfoCard}
                            setShowContentInfoCard={setShowContentInfoCard}
                            clipboardItemId={clipboardItemId}
                            contentItemInformation={contentItemInformation}
                            setInitiallyCreatedSnip={setInitiallyCreatedSnip}
                        />
                    ) : level2 === 'created' ? (
                        <SnipCreateInitialSnipCreated
                            hasJourneyBeenStarted={hasJourneyBeenStarted}
                            initiallyCreatedSnip={initiallyCreatedSnip}
                            setInitiallyCreatedSnip={setInitiallyCreatedSnip}
                        />
                    ) : level2 === 'prepare-content' ? (
                        <SnipCreatePrepareContent
                            hasJourneyBeenStarted={hasJourneyBeenStarted}
                            snipUrls={snipUrls}
                            sniplyContentUrl={sniplyContentUrl}
                            setSniplyContentUrl={setSniplyContentUrl}
                        />
                    ) : level2 === 'cta-select' ? (
                        <SnipCreateCtaSelect
                            initiallyCreatedSnip={initiallyCreatedSnip}
                            activeMobileViewTab={mobileConfigurePreviewActiveTab}
                            onSelectCta={handleSelectCta}
                            resetCtaConfiguration={handleResetCtaConfiguration}
                            hasJourneyBeenStarted={hasJourneyBeenStarted}
                            callToActionUrl={callToActionUrl}
                            setCallToActionUrl={setCallToActionUrl}
                            sniplyContentUrl={sniplyContentUrl}
                            ctaId={ctaId}
                            setCtaId={setCtaId}
                            ctaName={ctaName}
                            setCtaName={setCtaName}
                            ctaType={ctaType}
                            setCtaType={setCtaType}
                            headlineText={headlineText}
                            setHeadlineText={setHeadlineText}
                            messageText={messageText}
                            setMessageText={setMessageText}
                            buttonText={buttonText}
                            setButtonText={setButtonText}
                            linkText={linkText}
                            setLinkText={setLinkText}
                            linkTextColor={linkTextColor}
                            setLinkTextColor={setLinkTextColor}
                            formButtonTextColor={formButtonTextColor}
                            setFormButtonTextColor={setFormButtonTextColor}
                            formButtonColor={formButtonColor}
                            setFormButtonColor={setFormButtonColor}
                            formPlaceholderText={formPlaceholderText}
                            setFormPlaceholderText={setFormPlaceholderText}
                            imageAdUrl={imageAdUrl}
                            setImageAdUrl={setImageAdUrl}
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            imagePreviewUrl={imagePreviewUrl}
                            setImagePreviewUrl={setImagePreviewUrl}
                            imageAdPreviewUrl={imageAdPreviewUrl}
                            setImageAdPreviewUrl={setImageAdPreviewUrl}
                            theme={theme}
                            setTheme={setTheme}
                            backgroundColor={backgroundColor}
                            setBackgroundColor={setBackgroundColor}
                            messageTextColor={messageTextColor}
                            setMessageTextColor={setMessageTextColor}
                            buttonColor={buttonColor}
                            setButtonColor={setButtonColor}
                            buttonTextColor={buttonTextColor}
                            setButtonTextColor={setButtonTextColor}
                            position={position}
                            setPosition={setPosition}
                            showSniplyLogo={showSniplyLogo}
                            setShowSniplyLogo={setShowSniplyLogo}
                            showWhiteSniplyLogo={showWhiteSniplyLogo}
                            setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                            clipboardItemId={clipboardItemId}
                        />
                    ) : null}
                </div>
            </div>
            <div
                className={`h-full grow ${showCtaSidePreview ? 'bg-grey-lighter' : 'bg-primary'}`}
                ref={sideBarRef}
            >
                {showCtaSidePreview && (
                    <DemoContentIntegrator
                        contentUrl={sniplyContentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: ctaId,
                                    name: ctaName,
                                    type: ctaType,
                                    urlToBePromoted: ensureUrlHasProtocol(callToActionUrl),
                                    imageUrl: imagePreviewUrl || imageUrl,
                                    headlineText,
                                    messageText,
                                    messageTextColor,
                                    backgroundColor,
                                    buttonColor,
                                    buttonText,
                                    buttonTextColor,
                                    linkText,
                                    linkTextColor,
                                    formButtonColor,
                                    formButtonTextColor,
                                    formPlaceholderText,
                                    adImageUrl: imageAdPreviewUrl || imageAdUrl,
                                    position,
                                    theme,
                                    showSniplyLogo,
                                    showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={
                                    isSideBarWidthEquivalentOfMobileScreenWidth ||
                                    activePreviewView === 'mobile'
                                }
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                        previewViewSelectionTab={
                            hasScreenSpaceForSideBar && (
                                <PreviewViewSelectionTab
                                    activeTab={activePreviewView}
                                    setActiveTab={setActivePreviewView}
                                />
                            )
                        }
                        activePreviewView={activePreviewView}
                    />
                )}
            </div>
        </div>
    );
};
