import React from 'react';
import { SideNavigationVisibilityContext } from '../App';

export function useElementWidth(elementRef: React.MutableRefObject<HTMLElement>) {
    const [elementWidth, setElementWidth] = React.useState<number>();
    const isSideNavigationVisible = React.useContext(SideNavigationVisibilityContext);

    React.useEffect(() => {
        function handleResize() {
            if (elementRef?.current) {
                setElementWidth(elementRef.current.offsetWidth);
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [elementRef, isSideNavigationVisible]);

    return elementWidth;
}
