/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LinkClipboard } from '../models/LinkClipboard';
import type { LinkClipboardRequest } from '../models/LinkClipboardRequest';
import type { PatchedLinkClipboardRequest } from '../models/PatchedLinkClipboardRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LinkClipboardService {

    /**
     * LinkClipboard a.k.a. Snip Clipboard is a temporary storage for links that you want to save for later use.
     *
     * You can snip it at a later point.
     * @returns LinkClipboard
     * @throws ApiError
     */
    public static linkClipboardList({
        brand,
    }: {
        brand?: string,
    }): CancelablePromise<Array<LinkClipboard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/link-clipboard/',
            query: {
                'brand': brand,
            },
        });
    }

    /**
     * LinkClipboard a.k.a. Snip Clipboard is a temporary storage for links that you want to save for later use.
     *
     * You can snip it at a later point.
     * @returns LinkClipboard
     * @throws ApiError
     */
    public static linkClipboardCreate({
        requestBody,
    }: {
        requestBody: LinkClipboardRequest,
    }): CancelablePromise<LinkClipboard> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/link-clipboard/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LinkClipboard
     * @throws ApiError
     */
    public static linkClipboardRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<LinkClipboard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/link-clipboard/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns LinkClipboard
     * @throws ApiError
     */
    public static linkClipboardUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: LinkClipboardRequest,
    }): CancelablePromise<LinkClipboard> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/link-clipboard/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LinkClipboard
     * @throws ApiError
     */
    public static linkClipboardPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedLinkClipboardRequest,
    }): CancelablePromise<LinkClipboard> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/link-clipboard/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static linkClipboardDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/link-clipboard/{id}',
            path: {
                'id': id,
            },
        });
    }

}
