import React from 'react';
import { TeamService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { useElementWidth } from './hooks/use-element-width';
import { SearchInput } from './components/SearchInput';
import { TeamMemberData, TeamMemberRole } from './data-transformations/create-team-members-data';
import { UserContext } from './UserContextProvider';
import { DropdownSelect } from './components/DropdownSelect';
import { Toggle } from './components/Toggle';
import { TableButton } from './components/TableButton';
import { TeamEditRowButtonDropdown } from './TeamEditRowButtonDropdown';
import { navigateTo } from './Routing';

const TeamMembersTableHeader: React.FC = () => {
    return (
        <div className="mb-1 pb-1 grid grid-cols-8-4-4-1 gap-2 font-semibold border-b border-grey-lighter text-xs sm:text-sm">
            <span>Name</span>
            <span>Role</span>
            <span>Active</span>
            <span></span>
        </div>
    );
};

interface TeamMemberRowProps {
    readonly teamMember: TeamMemberData;
    readonly onChangeRole: (newRole: string) => void;
    readonly onChangeActiveState: (newState: boolean) => void;
    readonly onResendInvitation: (id: string) => void;
    readonly onDeleteInvitation: (id: string) => void;
    readonly onDeleteTeamMember: (id: string) => void;
    readonly onEditTeamMember: (id: string) => void;
}

const TeamMembersRow: React.FC<TeamMemberRowProps> = ({
    teamMember,
    onChangeRole,
    onChangeActiveState,
    onResendInvitation,
    onDeleteInvitation,
    onDeleteTeamMember,
    onEditTeamMember
}) => {
    const { userEmail } = React.useContext(UserContext);

    const { name, email, role, active, invitationPending } = teamMember;

    const isCurrentUser = email === userEmail;

    // The following is not a super clean solution, because essentially it describes the current user.
    // However only admins have access to the Team section so it is fair to assume that if the user is here, it means its an admin.
    const isAdmin = email === userEmail;

    return (
        <div className="w-full p-2 grid gap-3 grid-cols-8-4-4-1 items-center">
            <div className="flex items-center min-w-0">
                <span
                    className={`truncate text-sm sm:text-base ${
                        isCurrentUser && 'border-b-2 border-purple md:border-none'
                    } ${invitationPending ? 'text-grey-light' : 'text-grey'}`}
                >
                    {name}
                </span>
                {isCurrentUser && (
                    <span className="ml-2 px-1 border border-grey-lighter rounded-sm text-xs sm:text-sm text-white bg-purple hidden md:block">
                        You
                    </span>
                )}
            </div>
            {isAdmin ? (
                <div className="flex items-center border border-grey-lighter rounded-md h-10 w-24 p-3 text-sm text-justify bg-grey-lightest">
                    <span>Admin</span>
                </div>
            ) : (
                <div className={`${invitationPending ? 'text-grey-light' : 'text-grey'}`}>
                    <DropdownSelect
                        className="w-24"
                        selectedOption={role}
                        options={[
                            { value: 'admin', displayLabel: 'Admin' },
                            { value: 'client', displayLabel: 'Client' }
                        ]}
                        onSelectOption={onChangeRole}
                    />
                </div>
            )}
            {invitationPending ? (
                <span className="w-14 text-center h-fit font-normal text-xs px-2 py-1 rounded bg-yellow text-white">
                    invited
                </span>
            ) : (
                <div className="w-14 flex justify-center">
                    <Toggle
                        disabled={email === userEmail}
                        selectedOption={active}
                        onToggleChange={onChangeActiveState}
                    />
                </div>
            )}
            {!isAdmin && (
                <TeamEditRowButtonDropdown
                    teamMember={teamMember}
                    onResendInvitationClick={onResendInvitation}
                    onDeleteInvitation={onDeleteInvitation}
                    onDeleteTeamMemberClick={onDeleteTeamMember}
                    onEditClick={onEditTeamMember}
                />
            )}
        </div>
    );
};

interface TeamMembersTableProps {
    readonly teamMembersData: TeamMemberData[];
    readonly setTeamMembersData: (data: TeamMemberData[]) => void;
    readonly isLoadingInitialData: boolean;
    readonly hadErrorLoadingData: boolean;
    readonly onInviteTeamMember: () => void;
}

export const TeamMembersTable: React.FC<TeamMembersTableProps> = ({
    teamMembersData,
    setTeamMembersData,
    isLoadingInitialData,
    hadErrorLoadingData,
    onInviteTeamMember
}) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    const [planLimitError, setPlanLimitError] = React.useState('');

    const containerRef = React.useRef(undefined);
    const containerWidth = useElementWidth(containerRef);
    const isVerySmallScreen = containerWidth < 500;

    const createChangeRoleHandler = React.useCallback(
        (teamMember: TeamMemberData) => (newRoleValue: TeamMemberRole) => {
            if (teamMember.invitationPending) {
                TeamService.teamMembersInvitePartialUpdate({
                    id: teamMember.id,
                    requestBody: {
                        invitee: teamMember.email,
                        is_client_role: newRoleValue === 'client' ? true : false
                    }
                }).then(() => {
                    const newTeamMemberData = teamMembersData.map((entry) => {
                        if (entry.id === teamMember.id) {
                            return { ...entry, role: newRoleValue };
                        } else {
                            return entry;
                        }
                    });

                    setTeamMembersData(newTeamMemberData);
                });
            } else {
                TeamService.teamMemberPartialUpdate({
                    id: teamMember.id,
                    requestBody: {
                        is_client_role: newRoleValue === 'client' ? true : false,
                        exclude_brand_ids: teamMember.excludedWorkspaceIds
                    }
                });
            }
        },
        [teamMembersData, setTeamMembersData]
    );

    const handleResendInvitation = React.useCallback((teamMemberId) => {
        TeamService.teamMembersInviteResendPartialUpdate({ id: teamMemberId }).catch((error) => {
            console.error('Error resending the invitation.', error);

            if (error.body.non_field_errors?.[0].startsWith("You're at your plan's limit")) {
                setPlanLimitError(error.body.non_field_errors[0]);
            }
        });
    }, []);

    const handleDeleteTeamMemberInvite = React.useCallback(
        (teamMemberId) => {
            TeamService.teamMembersInviteDestroy({ id: teamMemberId })
                .then((result) => {
                    console.log(' TeamService.teamMembersInviteDestroy()', result);
                    setTeamMembersData(
                        teamMembersData.filter((teamMember) => teamMember.id !== teamMemberId)
                    );
                })
                .catch((error) => {
                    console.log('Error deleting invitation.', error);
                });
        },
        [teamMembersData, setTeamMembersData]
    );

    const handleDeleteTeamMember = React.useCallback(
        (teamMemberId) => {
            TeamService.teamMemberDestroy({ id: teamMemberId })
                .then((result) => {
                    console.log(' TeamService.teamMemberDestroy()', result);
                    setTeamMembersData(
                        teamMembersData.filter((teamMember) => teamMember.id !== teamMemberId)
                    );
                })
                .catch((error) => {
                    console.log('Error deleting team member.', error);
                });
        },
        [teamMembersData, setTeamMembersData]
    );

    const handleEditTeamMember = React.useCallback((teamMemberId) => {
        navigateTo(`/team/edit-member?teammember-id=${teamMemberId}`);
    }, []);

    return (
        <div ref={containerRef}>
            <div className="flex justify-end">
                <TableButton onClick={onInviteTeamMember}>
                    {isVerySmallScreen ? '+ Invite New' : '+ Invite new team member'}
                </TableButton>
            </div>
            <div className="mt-6 grid grid-cols-1 xs:grid-cols-2">
                <div className="flex items-center">
                    <h3 className="font-semibold">Team Members</h3>
                    {teamMembersData && (
                        <span className="ml-1 text-grey-light">{`(${teamMembersData.length})`}</span>
                    )}
                </div>
                <div className="flex items-center mt-2 xs:mt-0 xs:justify-end">
                    <SearchInput
                        searchTermState={searchTerm}
                        placeholderText="search..."
                        setSearchTermState={setSearchTerm}
                        onClearSearch={() => setSearchTerm('')}
                        isCollapsible={false}
                        isRounded
                    />
                </div>
            </div>
            <div className="mt-2 h-68 overflow-y-scroll">
                {isLoadingInitialData ? (
                    <div className="h-full w-full flex items-center justify-center">
                        <LoadingSpinner size={10} />
                    </div>
                ) : hadErrorLoadingData ? (
                    <div className="text-red">
                        Apologies your Team Members could not be retrieved. An unexpected network
                        error has occurred. Please try again later.
                    </div>
                ) : (
                    <div className="mt-6 w-full">
                        <TeamMembersTableHeader />
                        {teamMembersData
                            ?.filter((teamMember) =>
                                teamMember.name?.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((teamMember) => (
                                <TeamMembersRow
                                    key={teamMember.id}
                                    teamMember={teamMember}
                                    onChangeRole={createChangeRoleHandler(teamMember)}
                                    onChangeActiveState={(newState) => console.log(newState)}
                                    onResendInvitation={handleResendInvitation}
                                    onDeleteInvitation={handleDeleteTeamMemberInvite}
                                    onDeleteTeamMember={handleDeleteTeamMember}
                                    onEditTeamMember={handleEditTeamMember}
                                />
                            ))}
                    </div>
                )}
            </div>
            {planLimitError && <p className="mt-2 text-red text-sm">{planLimitError}</p>}
        </div>
    );
};
