import React from 'react';
import { ChurnkeyService, StripeService } from './api';

import { FocusViewLayout } from './components/FocusViewLayout';
import { UserPlanInformation } from './components/UserPlanInformation';
import { VerticalSpacer } from './components/VerticalSpacer';
import { UserContext } from './UserContextProvider';
import SettingIcon from './assets/icons/settings-grey-medium.svg';
import CancelIcon from './assets/icons/cancel-grey.svg';
import UpgradePlanImage from './assets/images/upgrade-plan.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { navigateTo } from './Routing';
import { ButtonMain } from './components/ButtonMain';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';

export const UserBilling: React.FC = () => {
    const {
        userPlan,
        stripeId,
        isV3Plan,
        isAppSumoPlan,
        appSumoTopSnipSlug,
        planRenewalDate,
        planCancelDate,
        isUserIsOnTrialPeriod,
        daysUntilTrialEnds,
        currentSnips,
        maxSnips,
        currentClicks,
        appSumoCurrentMonthlyClicksForTopSnip,
        maxClicks,
        currentCtas,
        maxCtas,
        currentMonthlyCtaImpressions,
        maxMonthlyCtaImpressions,
        currentTeammembers,
        maxTeammembers,
        currentWorkspaces,
        maxWorkspaces
    } = React.useContext(UserContext);

    const [errorFetchingStripeLink, setErrorFetchingStripeLink] = React.useState(false);
    const [churnkeyAuthHash, setChurnkeyAuthHash] = React.useState('');

    const handleManageSubscription = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            StripeService.stripePortalLinkCreate({
                requestBody: { return_url: window.location.href }
            })
                .then((result) => {
                    window.location.href = result.stripe_portal_link;
                })
                .catch((error) => {
                    console.error('Error generating link to Stripe Portal.', error);
                    setErrorFetchingStripeLink(true);
                });
        },
        []
    );

    const handleCancelSubscription = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (churnkeyAuthHash && stripeId) {
                (window as any).churnkey.init('show', {
                    customerId: stripeId,
                    authHash: churnkeyAuthHash,
                    appId: process.env.CHURNKEY_APP_ID,
                    mode: process.env.CHURNKEY_MODE,
                    provider: 'stripe'
                });
            }
        },
        [churnkeyAuthHash, stripeId]
    );

    const isMobileScreen = useIsMobileScreenSize();

    React.useEffect(() => {
        if (stripeId && !churnkeyAuthHash) {
            ChurnkeyService.churnkeyAuthHashRetrieve().then((result) => {
                setChurnkeyAuthHash(result.churnkey_auth_hash);
            });
        }
    }, [stripeId, churnkeyAuthHash]);

    return (
        <FocusViewLayout enableWideView onBackButtonClick={() => navigateTo('/user')}>
            <h2 className="font-semibold text-lg">Billing & Subscription</h2>
            <p className="mt-1 text-sm text-grey-light">
                Your current plan and usage across this billing cycle
            </p>
            <VerticalSpacer heightValue={6} />
            <div className="p-2 bg-grey-lightest">
                <UserPlanInformation
                    userPlan={userPlan}
                    isV3Plan={isV3Plan}
                    isAppSumoPlan={isAppSumoPlan}
                    topSnipSlugForAppSumo={appSumoTopSnipSlug}
                    planRenewalDate={planRenewalDate}
                    planCancelDate={planCancelDate}
                    isUserIsOnTrialPeriod={isUserIsOnTrialPeriod}
                    numberOfRemainingTrialDays={daysUntilTrialEnds}
                    numberOfSnipsUsed={currentSnips}
                    numberOfTotalSnipsIncluded={maxSnips}
                    numberOfClicksUsed={currentClicks}
                    numberOfClicksUsedOnTopSnipForAppSumo={appSumoCurrentMonthlyClicksForTopSnip}
                    numberOfTotalClicksIncluded={maxClicks}
                    numberOfCtasUsed={currentCtas}
                    numberOfTotalCtasIncluded={maxCtas}
                    numberOfMonthlyCtaImpressionsUsed={currentMonthlyCtaImpressions}
                    numberOfTotalMonthlyCtaImpressionsIncluded={maxMonthlyCtaImpressions}
                    numberOfTeammembersUsed={currentTeammembers}
                    numberOfTotalTeammembersIncluded={maxTeammembers}
                    numberOfWorkspacesUsed={currentWorkspaces}
                    numberOfTotalWorkspacesIncluded={maxWorkspaces}
                    manageSubscriptionButton={
                        <button className="flex items-center" onClick={handleManageSubscription}>
                            <img src={SettingIcon} alt="settings icon" className="w-4 h-4" />
                            <span className="ml-2 text-sm text-grey-light">
                                {isMobileScreen ? 'Manage' : 'Manage Subscription'}
                            </span>
                        </button>
                    }
                    cancelSubscriptionButton={
                        userPlan !== 'Free' && churnkeyAuthHash && stripeId ? (
                            <button
                                className="flex items-center"
                                onClick={handleCancelSubscription}
                            >
                                <img src={CancelIcon} alt="cancel icon" className="w-4 h-4" />
                                <span className="ml-2 text-sm text-grey-light">Cancel</span>
                            </button>
                        ) : null
                    }
                />
                <VerticalSpacer heightValue={10} />
                <ContentElementContainer>
                    <div className="flex flex-wrap sm:grid sm:grid-cols-1-6-2 gap-x-2 gap-y-4 items-center">
                        <div className="w-20 hidden sm:block">
                            <img
                                src={UpgradePlanImage}
                                alt="upgrade plan image"
                                className="w-20 h-20 block"
                            />
                        </div>
                        <div className="ml-4 flex-grow p-2">
                            <h4 className="font-semibold">{`${
                                userPlan === 'Enterprise' || userPlan === 'Business'
                                    ? 'Compare'
                                    : 'Upgrade'
                            } your plan`}</h4>
                            {userPlan === 'Enterprise' || userPlan === 'Business' ? (
                                <p className="mt-2 text-sm text-grey-medium">
                                    Need more Call-to-Actions, CTA impressions or some of our custom
                                    features? We got you covered!
                                </p>
                            ) : (
                                <p className="mt-2 text-sm text-grey-medium">
                                    Want to re-evaluate your usage in terms of Call-to-Actions, CTA
                                    impressions or some of our custom features?
                                </p>
                            )}
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <ButtonMain
                                size="small"
                                width="44"
                                onClick={() => navigateTo('/plans')}
                            >
                                View all plans
                            </ButtonMain>
                        </div>
                    </div>
                </ContentElementContainer>

                <VerticalSpacer heightValue={10} />
                {errorFetchingStripeLink && (
                    <p className="text-sm text-red">
                        Apologies, we could not provide access to the subscriptions portal at this
                        time. Please try again.
                    </p>
                )}
            </div>
        </FocusViewLayout>
    );
};
