import React from 'react';

import { LabelsService, LinkClipboardService } from './api';
import HideIcon from './assets/icons/hide.svg';
import LinkArrowIcon from './assets/icons/link-arrow-grey.svg';
import SniplyIcon from './assets/icons/sniply-logo.svg';
import { CampaignsDropdownSelect } from './CampaignsDropdownSelect';
import { CampaignsDropdownTagSelect } from './CampaignsDropdownTagSelect';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { DropDownOption } from './components/DropdownSelect';
import { DropDownTabOption } from './components/DropdownTabs';
import { EmptyTableView } from './components/EmptyTableView';
import { FormTextInput } from './components/FormTextInput';
import { ImageWithFallback } from './components/ImageWithFallback';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchInput } from './components/SearchInput';
import { StartHeader } from './components/StartHeader';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import {
    createClipboardItemData,
    CuratedContentItem
} from './data-transformations/create-clipboard-item-data';
import { navigateTo } from './Routing';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { WorkspaceContext } from './WorkspaceContextProvider';

export interface ClipboardItemCardProps {
    readonly contentItem: CuratedContentItem;
    readonly listIndex: number;
    readonly onClickDelete: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
    readonly snipButton?: JSX.Element;
    readonly campaignsData: DropDownOption[];
    readonly isLoadingCampaigns: boolean;
    readonly handleSelectCampaign: (
        clipboardItemId: string
    ) => (event: React.MouseEvent<HTMLButtonElement>, selectedCampaignId: string) => void;
    readonly handleResetCampaign: (
        clipboardItemId: string
    ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly handleCreateCampaign: (
        clipboardItemId: string
    ) => (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => void;
}

export const ClipboardItemCard: React.FC<ClipboardItemCardProps> = ({
    contentItem: {
        id,
        imageUrl,
        titleText,
        additionalText,
        linkUrl,
        faviconUrl,
        source,
        campaignId
    },
    listIndex,
    onClickDelete,
    snipButton,
    campaignsData,
    isLoadingCampaigns,
    handleSelectCampaign,
    handleResetCampaign,
    handleCreateCampaign
}) => {
    return (
        <>
            {listIndex > 0 && <hr className="mx-6 my-2 border-t border-grey-lighter" />}
            <div className="p-4 bg-white text-grey">
                <div className="pb-2 w-full min-w-0 grid grid-cols-1 md:grid-cols-3-1">
                    <div className="flex w-full items-start">
                        {imageUrl && (
                            <img src={imageUrl} alt="image" className="mr-2 w-24 md:w-28" />
                        )}
                        <div>
                            {titleText ? (
                                <p className={`font-semibold text-sm md:text-base line-clamp-1`}>
                                    {titleText ?? '(Title Not Available)'}
                                </p>
                            ) : (
                                <p className="w-full mb-3 text-xs md:text-sm font-semibold line-clamp-1">
                                    {linkUrl}
                                </p>
                            )}
                            {additionalText && (
                                <p className="mt-3 text-xs md:text-sm line-clamp-3 text-grey-medium">
                                    {additionalText}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 md:flex text-grey-medium items-center md:items-start justify-start md:justify-end text-sm mt-5 md:mt-0">
                        <a
                            className="flex items-center"
                            href={linkUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={LinkArrowIcon} alt="link icon" className="w-4 h-4" />
                            <span className="ml-2">View</span>
                        </a>
                        <button
                            className="md:ml-10  flex items-center justify-end md:justify-start"
                            onClick={(event) => onClickDelete(event, id)}
                        >
                            <img src={HideIcon} alt="hide icon" className="w-4 h-4" />
                            <span className="ml-2">Remove</span>
                        </button>
                    </div>
                </div>
                <div className="mt-3 grid grid-cols-1 gap-2 sm:grid-cols-2-2-1 items-center">
                    <div className="hidden sm:block text-grey-medium text-sm">
                        {source && (
                            <div className="flex items-center">
                                <ImageWithFallback
                                    src={faviconUrl}
                                    alt="favicon"
                                    fallbackSrc={SniplyIcon}
                                    className="h-4 w-4"
                                />
                                <span className="ml-2 line-clamp-1">{source}</span>
                            </div>
                        )}
                    </div>
                    <div className="flex w-full justify-start">
                        <div className="w-44">
                            <CampaignsDropdownTagSelect
                                label="+ Add To Campaign"
                                campaigns={campaignsData}
                                isLoadingCampaigns={isLoadingCampaigns}
                                selectedCampaignId={campaignId}
                                onSelectCampaignId={handleSelectCampaign(id)}
                                onResetCampaignId={handleResetCampaign(id)}
                                onCreateCampaign={handleCreateCampaign(id)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end w-full">{snipButton}</div>
                </div>
            </div>
        </>
    );
};

export const Clipboard: React.FC = () => {
    const [clipboardItemsData, setClipboardItemsData] = React.useState<CuratedContentItem[]>([]);

    const [newItemSelectedCampaignId, setNewItemSelectedCampaignId] = React.useState('');
    const [newContentUrl, setNewContentUrl] = React.useState('');
    const [showValidationError, setShowValidationError] = React.useState(false);

    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterByThisCampaignId, setFilterByThisCampaignId] = React.useState('');

    const [isLoadingContentList, setIsLoadingContentList] = React.useState(true);
    const [errorLoadingContentList, setErrorLoadingContentList] = React.useState(false);

    const [isSavingNewContentItem, setIsSavingNewContentItem] = React.useState(false);

    const [campaignsData, setCampaignsData] = React.useState<DropDownTabOption[]>([]);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = React.useState(true);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const handleContentItemRemoveSaved = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
            event.preventDefault();

            LinkClipboardService.linkClipboardDestroy({ id });
            setClipboardItemsData(
                clipboardItemsData.filter((contentDataItem) => contentDataItem.id !== id)
            );
        },
        [clipboardItemsData]
    );

    const handleContentItemCampaignCreateAndSelect = React.useCallback(
        (clipboardItemId: string) =>
            (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
                event.preventDefault();

                LabelsService.labelsCreate({
                    requestBody: { brand: currentWorkspace.id, name: campaignName }
                }).then((result) => {
                    setCampaignsData([
                        ...campaignsData,
                        { displayLabel: result.name, value: result.id }
                    ]);

                    LinkClipboardService.linkClipboardPartialUpdate({
                        id: clipboardItemId,
                        requestBody: { label: result.id }
                    }).then(() => {
                        const newContentData = clipboardItemsData.map((contentItem) =>
                            contentItem.id === clipboardItemId
                                ? { ...contentItem, campaignId: result.id }
                                : contentItem
                        );

                        setClipboardItemsData(newContentData);
                    });
                });
            },
        [currentWorkspace, campaignsData, clipboardItemsData]
    );

    const handleContentItemCampaignSelect = React.useCallback(
        (clipboardItemId: string) =>
            (event: React.MouseEvent<HTMLButtonElement>, selectedCampaignId: string) => {
                event.preventDefault();

                setClipboardItemsData(
                    clipboardItemsData.map((contentItem) =>
                        contentItem.id === clipboardItemId
                            ? { ...contentItem, campaignId: selectedCampaignId }
                            : contentItem
                    )
                );

                LinkClipboardService.linkClipboardPartialUpdate({
                    id: clipboardItemId,
                    requestBody: { label: selectedCampaignId }
                });
            },
        [clipboardItemsData]
    );

    const handleContentItemCampaignReset = React.useCallback(
        (clipboardItemId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            LinkClipboardService.linkClipboardPartialUpdate({
                id: clipboardItemId,
                requestBody: { label: '' }
            });

            setClipboardItemsData(
                clipboardItemsData.map((contentItem) =>
                    contentItem.id === clipboardItemId
                        ? { ...contentItem, campaignId: '' }
                        : contentItem
                )
            );
        },
        [clipboardItemsData]
    );

    const handleNewCampaignCreateForPotentialNewClipboardItem = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
            event.preventDefault();

            LabelsService.labelsCreate({
                requestBody: { brand: currentWorkspace.id, name: campaignName }
            }).then((result) => {
                console.log('LabelsService.labelsCreate()', result);

                setCampaignsData([
                    ...campaignsData,
                    { displayLabel: result.name, value: result.id }
                ]);

                setNewItemSelectedCampaignId(result.id);
            });
        },
        [currentWorkspace, campaignsData]
    );

    const handleSubmitNewContentUrl = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>, linkUrl: string, campaignId: string) => {
            event.preventDefault();
            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(linkUrl))) {
                setShowValidationError(true);
            } else {
                setIsSavingNewContentItem(true);
                LinkClipboardService.linkClipboardCreate({
                    requestBody: {
                        url: ensureUrlHasProtocol(linkUrl),
                        brand: currentWorkspace?.id,
                        label: campaignId
                    }
                })
                    .then((result) => {
                        setClipboardItemsData([
                            createClipboardItemData(result),
                            ...clipboardItemsData
                        ]);
                        setNewContentUrl('');
                        setIsSavingNewContentItem(false);
                        setNewItemSelectedCampaignId('');
                    })
                    .catch((error) => {
                        console.error(
                            'Error saving content item via LinkClipboardService.linkClipboardCreate()',
                            error
                        );
                        setIsSavingNewContentItem(false);
                    });
            }
        },
        [clipboardItemsData, currentWorkspace]
    );

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingContentList(true);
            LinkClipboardService.linkClipboardList({ brand: currentWorkspace.id })
                .then((result) => {
                    const clipboardItems = result.map((clipboardListItem) =>
                        createClipboardItemData(clipboardListItem)
                    );

                    setClipboardItemsData(clipboardItems);
                    setIsLoadingContentList(false);
                })
                .catch((error) => {
                    console.error(error);
                    setErrorLoadingContentList(true);
                    setIsLoadingContentList(false);
                });
        }
    }, [currentWorkspace]);

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingCampaigns(true);

            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                    setIsLoadingCampaigns(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                    setIsLoadingCampaigns(false);
                });
        }
    }, [currentWorkspace]);

    const filteredContentData = clipboardItemsData
        .filter(
            (clipboardItem) =>
                clipboardItem?.titleText?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                clipboardItem?.linkUrl?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                clipboardItem?.source?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
        .filter((clipboardItem) => {
            if (filterByThisCampaignId) {
                return clipboardItem.campaignId === filterByThisCampaignId;
            }
            return true;
        });

    return (
        <div>
            <StartHeader
                primaryHeaderLabel="Clipboard"
                secondaryHeaderText="Save the content you might want to snip later."
            />
            <VerticalSpacer heightValue={5} />
            <div>
                <ContentElementContainer>
                    <div className="h-20 mb-3 py-2">
                        {isSavingNewContentItem ? (
                            <div className="flex h-full w-full justify-center items-center">
                                <LoadingSpinner size={5} />
                            </div>
                        ) : (
                            <div>
                                <form
                                    onSubmit={(event) =>
                                        handleSubmitNewContentUrl(
                                            event,
                                            newContentUrl,
                                            newItemSelectedCampaignId
                                        )
                                    }
                                >
                                    <div className="flex items-center">
                                        <FormTextInput
                                            id="content-url-input"
                                            placeholder="example.com/some-article"
                                            value={newContentUrl}
                                            onChange={(event) =>
                                                setNewContentUrl(event.target.value)
                                            }
                                            righHandComponent={
                                                <ButtonMain
                                                    width="24"
                                                    type="submit"
                                                    style="primary"
                                                >
                                                    + Add
                                                </ButtonMain>
                                            }
                                        />
                                    </div>
                                    {showValidationError && (
                                        <p className="p-2 text-red text-sm">
                                            Please provide a valid Url.
                                        </p>
                                    )}
                                </form>
                                <div className="mt-3 w-44">
                                    <CampaignsDropdownTagSelect
                                        label="+ Add To Campaign"
                                        campaigns={campaignsData}
                                        isLoadingCampaigns={isLoadingCampaigns}
                                        selectedCampaignId={newItemSelectedCampaignId}
                                        onSelectCampaignId={(event, id) => {
                                            event.preventDefault();
                                            setNewItemSelectedCampaignId(id);
                                        }}
                                        onResetCampaignId={(event) => {
                                            event.preventDefault();
                                            setNewItemSelectedCampaignId('');
                                        }}
                                        onCreateCampaign={
                                            handleNewCampaignCreateForPotentialNewClipboardItem
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </ContentElementContainer>
                {isLoadingContentList ? (
                    <div className="mt-8 flex h-72 justify-center items-center">
                        <LoadingSpinner size={7} />
                    </div>
                ) : errorLoadingContentList ? (
                    <p className="text-sm text-red">
                        We were unable to retrieve your saved content items at this moment due to an
                        unexpected error. Please try again by refreshing your browser.
                    </p>
                ) : clipboardItemsData.length < 1 ? (
                    <div className="mt-8 h-72 w-full">
                        <EmptyTableView
                            headlineText="You do not have any saved content"
                            sublineText="Once you do, you will see the overview of all your saved content
                                    here."
                        />
                    </div>
                ) : (
                    <div>
                        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-2 items-center text-sm justify-between">
                            <div className="flex items-center">
                                <span className="hidden sm:block">Filter by:</span>
                                <span className="block sm:hidden">Filter:</span>
                                <div className="ml-2">
                                    <CampaignsDropdownSelect
                                        selectedCampaignId={filterByThisCampaignId}
                                        setSelectedCampaignId={setFilterByThisCampaignId}
                                    />
                                </div>
                                {filterByThisCampaignId && (
                                    <button
                                        className="ml-2 text-sm text-grey-medium"
                                        onClick={() => setFilterByThisCampaignId('')}
                                    >
                                        Reset
                                    </button>
                                )}
                            </div>
                            <div className="flex md:justify-end">
                                <SearchInput
                                    searchTermState={searchTerm}
                                    setSearchTermState={setSearchTerm}
                                    onClearSearch={() => setSearchTerm('')}
                                    placeholderText="Search"
                                    isCollapsible={false}
                                    isRounded
                                />
                            </div>
                        </div>
                        <VerticalSpacer heightValue={2} />
                        <hr className="border-t border-grey-lighter" />
                        <VerticalSpacer heightValue={2} />
                        <span className="flex text-xs text-grey">{`Clipped Links (${
                            filteredContentData?.length ?? 0
                        })`}</span>
                        <VerticalSpacer heightValue={2} />
                        <ContentElementContainer>
                            <div className="grid grid-cols-1">
                                {filteredContentData.length > 0 ? (
                                    filteredContentData.map((contentItem, index) => (
                                        <ClipboardItemCard
                                            key={contentItem.id}
                                            listIndex={index}
                                            contentItem={contentItem}
                                            onClickDelete={handleContentItemRemoveSaved}
                                            snipButton={
                                                <ButtonMain
                                                    size="small"
                                                    width="full"
                                                    onClick={() =>
                                                        navigateTo(
                                                            `/snip-create/content-select?clipboardItemUrl=${contentItem.linkUrl}&clipboardItemId=${contentItem.id}`
                                                        )
                                                    }
                                                >
                                                    Snip
                                                </ButtonMain>
                                            }
                                            campaignsData={campaignsData}
                                            isLoadingCampaigns={isLoadingCampaigns}
                                            handleSelectCampaign={handleContentItemCampaignSelect}
                                            handleResetCampaign={handleContentItemCampaignReset}
                                            handleCreateCampaign={
                                                handleContentItemCampaignCreateAndSelect
                                            }
                                        />
                                    ))
                                ) : searchTerm.length > 0 ? (
                                    <p className="text-grey-medium text-sm">No Results</p>
                                ) : null}
                            </div>
                        </ContentElementContainer>
                    </div>
                )}
            </div>
        </div>
    );
};
