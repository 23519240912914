import React from 'react';

import { LinksService } from './api';
import ArchiveIcon from './assets/icons/archive.svg';
import UnArchiveIcon from './assets/icons/unarchive.svg';
import { AuthContext } from './auth';
import { ButtonMain } from './components/ButtonMain';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ItemSpecificHeader } from './components/shared/ItemSpecificHeader';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createSnipData, SnipsListItem } from './data-transformations/create-snip-data';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { RouteLink } from './Routing';
import { SnipsSnipContent } from './SnipsSnipContent';

interface SnipsSnipProps {
    readonly currentSnipId: string;
}

export const SnipsSnip: React.FC<SnipsSnipProps> = ({ currentSnipId }) => {
    const [snipData, setSnipData] = React.useState<SnipsListItem>();

    const isMobile = useIsMobileScreenSize();

    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    const [isUpdatingArchivedState, setIsUpdatingArchivedState] = React.useState(false);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const handleSetArchived = React.useCallback(
        (value: boolean) => {
            setIsUpdatingArchivedState(true);
            LinksService.linksPartialUpdate({
                id: currentSnipId,
                requestBody: {
                    hide_on_dashboard: value
                }
            })
                .then(() => {
                    setSnipData({ ...snipData, archived: value });
                    setIsUpdatingArchivedState(false);
                })
                .catch((error) => {
                    console.error('Error updating snip archived state.', error);
                    setIsUpdatingArchivedState(false);
                });
        },
        [currentSnipId, snipData]
    );

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentSnipId) {
            LinksService.linksRetrieve({ id: currentSnipId })
                .then((result) => {
                    console.log('LinksService.linksRetrieve()', result);
                    setSnipData(createSnipData(result));
                    setHadErrorLoadingData(false);
                    setIsLoadingData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LinksService.linksList(): ', error);
                    setIsLoadingData(false);
                });
        }
    }, [isOpenAPITokenSet, currentSnipId]);

    return (
        <div className="h-full w-full">
            <ItemSpecificHeader
                snipUrl={snipData?.snipUrl}
                currentItemName={snipData?.snipName}
                secondaryHeaderText={
                    snipData && 'View and configure all relevant aspects of your snip here'
                }
                breadcrumbRoot={
                    <RouteLink
                        href="/snips"
                        className="font-semibold text-lg text-grey hover:underline"
                    >
                        Snips
                    </RouteLink>
                }
                mainButton={
                    isUpdatingArchivedState ? (
                        <div className="h-9 w-9 flex items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : snipData?.archived ? (
                        <ButtonMain
                            style="neutral"
                            size="small"
                            onClick={() => {
                                handleSetArchived(false);
                            }}
                        >
                            <img src={UnArchiveIcon} alt="archive icon" className="w-4 h-4" />
                        </ButtonMain>
                    ) : (
                        <ButtonMain
                            style="neutral"
                            size="small"
                            onClick={() => {
                                handleSetArchived(true);
                            }}
                        >
                            <img src={ArchiveIcon} alt="archive icon" className="w-4 h-4" />
                        </ButtonMain>
                    )
                }
            />
            {isMobile ? <VerticalSpacer heightValue={5} /> : <VerticalSpacer heightValue={10} />}
            {isLoadingData ? (
                <div className="h-full w-full flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : hadErrorLoadingData ? (
                <div className="text-red">
                    Apologies your Snip could not be retrieved. An unexpected network error has
                    occurred. Please try refreshing the browser or try again later.
                </div>
            ) : !snipData ? null : (
                <SnipsSnipContent snip={snipData} setSnip={setSnipData} />
            )}
        </div>
    );
};
