import React from 'react';
import { ContentElementContainer } from './ContentElementContainer';
import LinkArrowIcon from '../assets/icons/link-arrow.svg';

export interface SupportInfoLink {
    readonly displayText: string;
    readonly url: string;
}

export interface SupportInfoCardProps {
    readonly titleText: string;
    readonly additionalText: string;
    readonly infoLinks: SupportInfoLink[];
    readonly imageUrl?: string;
}

export const SupportInfoCard: React.FC<SupportInfoCardProps> = ({
    titleText,
    additionalText,
    infoLinks,
    imageUrl
}) => {
    return (
        <ContentElementContainer>
            {imageUrl ? (
                <img className="w-full" src={imageUrl} />
            ) : (
                <div className="bg-grey-lighter h-20" />
            )}
            <h3 className="mt-4 text-grey text-lg">{titleText}</h3>
            <p className="text-xs text-grey-light">{additionalText}</p>
            <ul className="text-sm text-grey mt-4">
                {infoLinks.map((infoLink) => (
                    <li className="flex items-center py-2" key={infoLink.displayText}>
                        <a className="underline" href={infoLink.url}>
                            {infoLink.displayText}
                        </a>
                        <div className="ml-2">
                            <img className="h-2 w-2" src={LinkArrowIcon} />
                        </div>
                    </li>
                ))}
            </ul>
        </ContentElementContainer>
    );
};
