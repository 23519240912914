import React from 'react';
import { DropDownOption, DropdownSelect } from './components/DropdownSelect';
import { FocusViewLayout } from './components/FocusViewLayout';
import { VerticalSpacer } from './components/VerticalSpacer';
import { navigateTo, RouteLink } from './Routing';
import { UserContext } from './UserContextProvider';
import { WorkspaceContext } from './WorkspaceContextProvider';
import PlusIcon from './assets/icons/plus-plain.svg';
import AffiliateIcon from './assets/icons/affiliate.svg';
import { AuthContext } from './auth';
import { TeamService, UserService } from './api';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { getNextHigherPlan } from './utils/get-next-higher-plan';

export const UserAccountDashboard = () => {
    const [showTeamSection, setShowTeamSection] = React.useState(false);

    const { currentWorkspace, setCurrentWorkspace, workspaces, activeWorkspaces } =
        React.useContext(WorkspaceContext);

    const { firstName, lastName, userEmail, maxWorkspaces, userProfilePictureUrl, userPlan } =
        React.useContext(UserContext);

    const { logout, logoutRedirect } = React.useContext(AuthContext);
    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    const findWorkspaceForDropdownSelection = React.useCallback(
        (option: string) => {
            return activeWorkspaces.find((workspace) => workspace.id === option);
        },
        [activeWorkspaces]
    );

    const createWorkspaceDropdownOptions = React.useCallback<() => DropDownOption[]>(() => {
        return activeWorkspaces.map((workspace) => ({
            value: workspace.id,
            displayLabel: workspace.name
        }));
    }, [activeWorkspaces]);

    React.useEffect(() => {
        if (userEmail) {
            TeamService.teamMembersRetrieve().then((result) => {
                const isAdmin = !result.team_members.find(
                    (teamMember) => teamMember.email === userEmail
                )?.is_client_role;

                setShowTeamSection(isAdmin);
            });
        }
    }, [userEmail]);

    return (
        <FocusViewLayout onBackButtonClick={() => navigateTo('/')}>
            <h2 className="font-semibold text-lg">Your Account</h2>
            <VerticalSpacer heightValue={10} />
            <div className="grid grid-cols-1 gap-18 text-grey">
                <div>
                    <h2 className="font-semibold">User</h2>
                    <div className="mt-3 flex">
                        <div className="h-6 w-6 rounded-full border border-white bg-black text-sm text-white text-center">
                            {!userProfilePictureUrl ? (
                                firstName?.slice(0, 1)
                            ) : (
                                <img src={userProfilePictureUrl} className="rounded-full" />
                            )}
                        </div>
                        <span className="ml-2 text-sm">{`${firstName} ${lastName}`}</span>
                    </div>
                    <VerticalSpacer heightValue={6} />
                    <div className="grid grid-cols-1 gap-3 font-light">
                        <RouteLink href="/settings">Settings</RouteLink>
                        {showTeamSection ? (
                            <RouteLink href="/team">Team</RouteLink>
                        ) : (
                            <p className="text-grey-light">Team</p>
                        )}
                        <RouteLink href="/billing">Billing</RouteLink>
                        <a
                            className="flex items-center"
                            href="https://friends.sniply.io/signup"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>Become Affiliate</span>
                            <div className="flex items-center ml-3">
                                <img src={AffiliateIcon} alt="affiliate icon" className="h-4 w-4" />
                                <span className="text-green ml-1">Earn 25% commission</span>
                            </div>
                        </a>
                    </div>
                    <hr className="my-4 w-2/3" />
                    <a className="font-light" onClick={() => logout()} href={logoutRedirect}>
                        Logout
                    </a>
                </div>
                <div>
                    <div className="flex items-center justify-between">
                        <h2 className="font-semibold ">Workspace</h2>
                        <button
                            onClick={() => {
                                if (workspaces.length >= maxWorkspaces) {
                                    handleOpenUpgradePlanMessage({
                                        feature: 'Workspace',
                                        requiredPlan: getNextHigherPlan(userPlan),
                                        message:
                                            'You have reached the maximum amount of workspaces for your current plan. To add another workspace you will have to upgrade your plan.'
                                    });
                                } else {
                                    navigateTo('/manage-workspaces/add');
                                }
                            }}
                            disabled={!workspaces.length}
                        >
                            <img src={PlusIcon} className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="mt-3 grid grid-cols-1 gap-3 font-light">
                        {createWorkspaceDropdownOptions().length > 1 ? (
                            <DropdownSelect
                                placeholderText="select workspace"
                                options={createWorkspaceDropdownOptions()}
                                selectedOption={currentWorkspace?.id}
                                onSelectOption={(option) => {
                                    setCurrentWorkspace(findWorkspaceForDropdownSelection(option));

                                    navigateTo('/');
                                    UserService.userProfilePartialUpdate({
                                        requestBody: { last_used_brand: option }
                                    });
                                }}
                                enableSelectedOptionBold
                            />
                        ) : (
                            <div className="flex items-center border border-grey-lighter bg-white rounded-md w-full  h-10 p-3 text-sm font-semibold py-1 px-3">
                                {activeWorkspaces[0]?.name}
                            </div>
                        )}
                        <RouteLink href="/manage-workspaces">Manage Workspaces</RouteLink>
                    </div>
                </div>
            </div>
        </FocusViewLayout>
    );
};
