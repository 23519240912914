import React from 'react';
import { ensureUrlHasProtocol } from '../utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from '../utils/is-valid-url';
import { ButtonMain } from './ButtonMain';
import { FormTextInput } from './FormTextInput';

interface UrlListInputProps {
    readonly urlList: string[];
    readonly setUrlList: (urlList: string[]) => void;
}

export const UrlListInput: React.FC<UrlListInputProps> = ({ urlList, setUrlList }) => {
    const [newListItem, setNewListItem] = React.useState('');
    const [newlistItemInvalid, setNewListItemInvalid] = React.useState(false);

    return (
        <div>
            {urlList.length > 0 && (
                <ul className="p-2 rounded-m bg-grey-lightest text-xs">
                    {urlList.map((listItem) => (
                        <li key={listItem}>{listItem}</li>
                    ))}
                </ul>
            )}
            <div className="mt-2 flex items-center justify-between">
                <div className="w-full">
                    <FormTextInput
                        id="outbound-snips-url-blacklist-input"
                        placeholder="http://example.com/pricing/*"
                        value={newListItem}
                        onChange={(event) => setNewListItem(event.target.value)}
                    />
                    <p className="mt-1 text-sm text-red">
                        {newlistItemInvalid ? 'Please use a valid url.' : ''}
                    </p>
                </div>
                <ButtonMain
                    className="ml-4"
                    size="small"
                    onClick={() => {
                        if (isValidUrlWithProtocol(ensureUrlHasProtocol(newListItem))) {
                            setUrlList([...urlList, ensureUrlHasProtocol(newListItem)]);
                        } else {
                            setNewListItemInvalid(true);
                        }
                    }}
                >
                    Add
                </ButtonMain>
            </div>
        </div>
    );
};
