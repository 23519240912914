import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { LinkButtonMain } from './components/ButtonMain';
import FacebookIcon from './assets/icons/facebook-minimal.svg';
import InstagramIcon from './assets/icons/instagram-minimal.svg';
import TwitterIcon from './assets/icons/twitter-minimal.svg';
import LinkedInIcon from './assets/icons/linkedIn-minimal.svg';
import { NotificationContext } from './NotificationContextProvider';
import CopyIconWhite from './assets/icons/copy-white.svg';
import CopyIcon from './assets/icons/copy.svg';
import QRCodeIconWhite from './assets/icons/qr-code-white.svg';
import QRCodeIcon from './assets/icons/qr-code.svg';
import { QRCodeCanvas } from 'qrcode.react';

interface SnipsSnipContentShareProps {
    readonly snip: SnipsListItem;
}

export const SnipsSnipContentShare: React.FC<SnipsSnipContentShareProps> = ({ snip }) => {
    const { handleOpenNotification } = React.useContext(NotificationContext);

    return (
        <ContentElementContainer>
            <h3 className="font-semibold">Share Your Snip</h3>
            <div className="mt-4 flex flex-col xl:flex-row xl:items-center">
                <div className="flex items-center">
                    <button
                        className="py-1 px-2 border border-grey-light rounded-md flex items-center"
                        onClick={(event) => {
                            event.preventDefault();

                            navigator.clipboard.writeText(snip.snipUrl);

                            handleOpenNotification({
                                messageText: 'Snip URL successfully copied.',
                                type: 'success',
                                iconSrc: CopyIconWhite,
                                showTimeInSeconds: 3
                            });
                        }}
                    >
                        <img className="h-5 w-5" src={CopyIcon} />
                        <span className="ml-2 text-sm">Copy Link</span>
                    </button>
                    <button
                        className="ml-4 py-1 px-2 border border-grey-light rounded-md flex items-center"
                        onClick={(event) => {
                            event.preventDefault();

                            const canvas = document.getElementById('qr-code');
                            const pngUrl = (canvas as HTMLCanvasElement)
                                .toDataURL('image/png')
                                .replace('image/png', 'image/octet-stream');

                            const downloadLink = document.createElement('a');
                            downloadLink.href = pngUrl;
                            downloadLink.download = `sniply-qr-code-${snip.slug}.png`;
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);

                            handleOpenNotification({
                                messageText: 'QR code successfully downloaded.',
                                type: 'success',
                                iconSrc: QRCodeIconWhite,
                                showTimeInSeconds: 3
                            });
                        }}
                    >
                        <div>
                            <QRCodeCanvas
                                id="qr-code"
                                className="hidden"
                                value={snip.snipUrl}
                                includeMargin
                                fgColor="#2681DB"
                                size={1000}
                            />
                            <img className="h-5 w-5" src={QRCodeIcon} />
                        </div>
                        <span className="ml-2 text-sm">Download QR Code</span>
                    </button>
                </div>
                <div className="mt-6 xl:mt-0 xl:ml-6 xl:flex xl:items-center">
                    <p className="text-sm">or use your favourite platform</p>
                    <div className="mt-4 xl:mt-0 xl:ml-6 flex items-center">
                        <LinkButtonMain
                            href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${snip.snipUrl}&p[title]=${snip.contentTitle}`}
                            size="small"
                            style="neutral"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={FacebookIcon} alt="facebook icon" className="w-5 h-5" />
                        </LinkButtonMain>
                        <LinkButtonMain
                            href={`http://twitter.com/share?text=${snip.contentTitle}&url=${snip.snipUrl}`}
                            size="small"
                            style="neutral"
                            className="ml-4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={TwitterIcon} alt="twitter icon" className="w-5 h-5" />
                        </LinkButtonMain>
                        <LinkButtonMain
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${snip.snipUrl}&title=${snip.contentTitle}`}
                            size="small"
                            style="neutral"
                            className="ml-4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={LinkedInIcon} alt="linkedin icon" className="w-5 h-5" />
                        </LinkButtonMain>
                        <LinkButtonMain
                            href={`https://www.instagram.com`}
                            size="small"
                            style="neutral"
                            className="ml-4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={InstagramIcon} alt="instagram icon" className="w-5 h-5" />
                        </LinkButtonMain>
                    </div>
                </div>
            </div>
        </ContentElementContainer>
    );
};
