import React from 'react';

import LockIcon from './assets/icons/lock.svg';
import { CtaColorPicker } from './components/CtaColorPicker';
import { CtaCustomizingStyleContainer } from './components/CtaCustomizingStyleContainer';
import { DropdownSelect } from './components/DropdownSelect';
import { DropdownSelectSimple } from './components/DropdownSelectSimple';
import { FormTextInput } from './components/FormTextInput';
import { ImageUploadWithCrop } from './components/ImageUploadWithCrop';
import { VerticalSpacer } from './components/VerticalSpacer';
import { CtaPosition, CtaTheme } from './data-transformations/create-cta-data';
import { UpgradePlanMessage } from './UpgradePlanMessageContextProvider';
import { UserPlan } from './UserContextProvider';

interface CtaFormCreateProps {
    readonly customizingStyle: boolean;
    readonly setImagePreviewUrl: (imageUrl: string) => void;
    // Form CTA Configuration
    readonly imageUrl: string;
    readonly setImageUrl: (imageUrl: string) => void;
    readonly headlineText: string;
    readonly setHeadlineText: (headlineText: string) => void;
    readonly messageText: string;
    readonly setMessageText: (messageText: string) => void;
    readonly messageTextColor: string;
    readonly onSetMessageTextColor: (messageTextColor: string) => void;
    readonly formPlaceholderText: string;
    readonly setFormPlaceholderText: (formPlaceholderText: string) => void;
    // Form CTA Styling
    readonly theme: CtaTheme;
    readonly setTheme: (theme: CtaTheme) => void;
    readonly backgroundColor: string;
    readonly onSetBackgroundColor: (backgroundColor: string) => void;
    readonly buttonColor: string;
    readonly onSetButtonColor: (buttonColor: string) => void;
    readonly buttonTextColor: string;
    readonly onSetButtonTextColor: (buttonTextColor: string) => void;
    readonly position: CtaPosition;
    readonly onSetPosition: (position: CtaPosition) => void;
    readonly showSniplyLogo: boolean;
    readonly onSetShowSniplyLogo: (state: boolean) => void;
    readonly showWhiteSniplyLogo: boolean;
    readonly setShowWhiteSniplyLogo: (state: boolean) => void;
    readonly canHideSniplyLogo: boolean;
    readonly minimumHideSniplyLogoPlan: UserPlan;
    readonly canUseCtaColors: boolean;
    readonly minimumUseCtaColorsPlan: UserPlan;
    readonly canUseCtaPositioning: boolean;
    readonly minimumUseCtaPositioningPlan: UserPlan;
    readonly handleOpenUpgradePlanMessage: (message: UpgradePlanMessage) => void;
}

export const CtaFormCreate: React.FC<CtaFormCreateProps> = ({
    customizingStyle,
    setImagePreviewUrl,
    imageUrl,
    setImageUrl,
    headlineText,
    setHeadlineText,
    messageText,
    setMessageText,
    formPlaceholderText,
    setFormPlaceholderText,
    theme,
    setTheme,
    backgroundColor,
    onSetBackgroundColor,
    messageTextColor,
    onSetMessageTextColor,
    buttonColor,
    onSetButtonColor,
    buttonTextColor,
    onSetButtonTextColor,
    position,
    onSetPosition,
    showSniplyLogo,
    onSetShowSniplyLogo,
    showWhiteSniplyLogo,
    setShowWhiteSniplyLogo,
    canHideSniplyLogo,
    minimumHideSniplyLogoPlan,
    canUseCtaColors,
    minimumUseCtaColorsPlan,
    canUseCtaPositioning,
    minimumUseCtaPositioningPlan,
    handleOpenUpgradePlanMessage
}) => {
    return !customizingStyle ? (
        <>
            <p className="mt-2 text-xs">
                <span>
                    Form CTAs allow you to collect email addresses from visitors. You are
                    responsible for ensuring you have obtained any necessary consent to collect the
                    email addresses.{' '}
                </span>
                <a
                    className="underline"
                    href="https://support.snip.ly/hc/en-us/articles/20000869883924-Data-Collection-for-Form-Call-to-Actions"
                >
                    Learn more
                </a>
            </p>
            <VerticalSpacer heightValue={2} />
            <FormTextInput
                id="headline-input"
                labelText="Headline"
                placeholder="Enter a headline here"
                value={headlineText}
                onChange={(event) => setHeadlineText(event.target.value)}
            />
            <FormTextInput
                id="message-input"
                labelText="Message"
                explanation={
                    <div className="ml-2 text-xs text-grey-light">(Personalise your message)</div>
                }
                placeholder="Enter your message here"
                value={messageText}
                onChange={(event) => setMessageText(event.target.value)}
            />
            <FormTextInput
                id="placeholder-text-input"
                labelText="Placeholder Text"
                placeholder="Enter your placeholder text here"
                value={formPlaceholderText}
                onChange={(event) => setFormPlaceholderText(event.target.value)}
            />
            <ImageUploadWithCrop
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                setImagePreviewUrl={setImagePreviewUrl}
                aspect={1}
            />
        </>
    ) : (
        <CtaCustomizingStyleContainer>
            <DropdownSelect
                titleText="Theme"
                options={[
                    { value: 'social', displayLabel: 'Social' },
                    { value: 'fullwidth', displayLabel: 'Full Width' },
                    { value: 'candy', displayLabel: 'Candy' },
                    { value: 'bean', displayLabel: 'Bean' }
                ]}
                selectedOption={theme}
                onSelectOption={setTheme as (themeValue: CtaTheme) => void}
            />
            <DropdownSelect
                titleText="Position"
                options={[
                    { value: 'bottom-left', displayLabel: 'Bottom Left' },
                    { value: 'bottom-right', displayLabel: 'Bottom Right' },
                    { value: 'top-left', displayLabel: 'Top Left' },
                    { value: 'top-right', displayLabel: 'Top Right' }
                ]}
                selectedOption={position}
                onSelectOption={onSetPosition}
                titleIcon={
                    !canUseCtaPositioning && (
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                handleOpenUpgradePlanMessage({
                                    feature: 'Call-To-Action Position',
                                    requiredPlan: minimumUseCtaPositioningPlan
                                });
                            }}
                        >
                            <img src={LockIcon} className="h-4 w-4" />
                        </button>
                    )
                }
            />
            <div>
                <div className="flex items-center">
                    <h4 className="text-sm">Colors</h4>
                    {!canUseCtaColors && (
                        <span className="ml-2">
                            <button
                                onClick={(event) => {
                                    event.preventDefault();

                                    handleOpenUpgradePlanMessage({
                                        feature: 'Call-To-Action Colors',
                                        requiredPlan: minimumUseCtaColorsPlan
                                    });
                                }}
                            >
                                <img src={LockIcon} className="h-4 w-4" />
                            </button>
                        </span>
                    )}
                </div>
                <div className="mt-2 grid grid-cols-2 gap-2">
                    <CtaColorPicker
                        titleText="Background"
                        colorHexValue={backgroundColor}
                        onChangeColor={onSetBackgroundColor}
                    />
                    <CtaColorPicker
                        titleText="Text"
                        colorHexValue={messageTextColor}
                        onChangeColor={onSetMessageTextColor}
                    />
                    <CtaColorPicker
                        titleText="Button"
                        colorHexValue={buttonColor}
                        onChangeColor={onSetButtonColor}
                    />
                    <CtaColorPicker
                        titleText="Button Text"
                        colorHexValue={buttonTextColor}
                        onChangeColor={onSetButtonTextColor}
                    />
                </div>
            </div>
            <DropdownSelectSimple
                titleText="Sniply Logo"
                options={[
                    { value: false, displayLabel: 'Hide Logo' },
                    { value: true, displayLabel: 'Show Logo' }
                ]}
                selectedOption={showSniplyLogo}
                onSelectOption={onSetShowSniplyLogo}
                titleIcon={
                    !canHideSniplyLogo && (
                        <button
                            onClick={(event) => {
                                event.preventDefault();

                                handleOpenUpgradePlanMessage({
                                    feature: 'Hide Sniply Logo',
                                    requiredPlan: minimumHideSniplyLogoPlan
                                });
                            }}
                        >
                            <img src={LockIcon} className="h-4 w-4" />
                        </button>
                    )
                }
            />
            <DropdownSelectSimple
                titleText="Sniply Logo Color"
                options={[
                    { value: false, displayLabel: 'Default' },
                    { value: true, displayLabel: 'White' }
                ]}
                selectedOption={showWhiteSniplyLogo}
                onSelectOption={setShowWhiteSniplyLogo}
            />
        </CtaCustomizingStyleContainer>
    );
};
