/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneratePresignedUploadURL } from '../models/GeneratePresignedUploadURL';
import type { GeneratePresignedUploadURLRequest } from '../models/GeneratePresignedUploadURLRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UploadService {

    /**
     * Generate a presigned URL for uploading an image.
     *
     * The URL is valid for 5 minutes.
     *
     * The link must be then sent to CTA creation endpoint.
     * @returns GeneratePresignedUploadURL
     * @throws ApiError
     */
    public static uploadCreate({
        requestBody,
    }: {
        requestBody: GeneratePresignedUploadURLRequest,
    }): CancelablePromise<GeneratePresignedUploadURL> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/upload/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
