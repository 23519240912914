import React from 'react';

interface SideNavigationLogoProps {
    readonly slim?: boolean;
}

export const SideNavigationLogo: React.FC<SideNavigationLogoProps> = ({ slim = false }) =>
    slim ? (
        <svg className="h-7" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.468 7.07041L15.0782 7.0625L12.9438 13.5449H16.4184C18.1557 13.5449 19.5952 15.0196 19.5952 16.7917C19.5952 18.5639 18.1557 20.0322 16.4184 20.0322H10.859L8.72461 26.5131H16.468C21.7296 26.5131 26.048 22.1587 26.048 16.7411C26.048 11.3235 21.7792 7.07041 16.468 7.07041Z"
                fill="#2681DB"
            />
            <path
                d="M10.843 19.9453L12.9623 13.5452H9.51225C7.78723 13.5452 6.35793 12.0523 6.35793 10.2927C6.35793 8.53312 7.78723 7.06276 9.51225 7.06276H15.0837L17.2009 0.589844H9.51225C4.23862 0.589844 0 4.9134 0 10.2927C0 15.672 4.23862 20.0276 9.51225 20.0276H10.843V19.9453Z"
                fill="#2681DB"
            />
        </svg>
    ) : (
        // <svg
        //     // className="h-7" viewBox="0 0 113 28" fill="none" xmlns="http://www.w3.org/2000/svg"
        //     xmlns="http://www.w3.org/2000/svg"
        //     // width="500"
        //     zoomAndPan="magnify"
        //     viewBox="0 0 375 187.499995"
        //     height="1.75 rem"
        //     preserveAspectRatio="xMidYMid meet"
        //     version="1.0"
        // >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="450"
            zoomAndPan="magnify"
            viewBox="0 0 337.5 112.499997"
            height="2.5rem"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
        >
            <defs>
                <g />
            </defs>
            <path
                fill="#2581da"
                d="M 47.453125 21.753906 L 43.542969 21.730469 L 37.539062 39.964844 L 47.3125 39.964844 C 52.199219 39.964844 56.25 44.113281 56.25 49.097656 C 56.25 54.085938 52.199219 58.214844 47.3125 58.214844 L 31.675781 58.214844 L 25.671875 76.445312 L 47.453125 76.445312 C 62.253906 76.445312 74.402344 64.195312 74.402344 48.957031 C 74.402344 33.71875 62.394531 21.753906 47.453125 21.753906 Z M 47.453125 21.753906 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 31.628906 57.96875 L 37.589844 39.964844 L 27.886719 39.964844 C 23.035156 39.964844 19.011719 35.765625 19.011719 30.816406 C 19.011719 25.867188 23.035156 21.730469 27.886719 21.730469 L 43.558594 21.730469 L 49.515625 3.523438 L 27.886719 3.523438 C 13.050781 3.523438 1.128906 15.683594 1.128906 30.816406 C 1.128906 45.949219 13.050781 58.203125 27.886719 58.203125 L 31.628906 58.203125 Z M 31.628906 57.96875 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 200.769531 20.515625 L 211.777344 20.515625 L 211.777344 65.175781 L 200.769531 65.175781 Z M 200.769531 20.515625 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 199.777344 8.15625 C 199.777344 16.726562 212.769531 16.726562 212.769531 8.15625 C 212.769531 -0.417969 199.777344 -0.417969 199.777344 8.15625 Z M 199.777344 8.15625 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 139.753906 23.066406 C 135.507812 20.324219 131 19.433594 125.367188 19.433594 C 117.246094 19.433594 106.601562 23.042969 106.601562 33.328125 C 106.601562 43.429688 116.613281 46.5 125.003906 47.128906 C 131.320312 47.492188 133.847656 48.753906 133.847656 51.820312 C 133.847656 55.070312 129.96875 57.234375 125.816406 57.144531 C 120.855469 57.054688 113.636719 54.4375 110.480469 50.917969 L 110.417969 51.011719 L 107.367188 60.921875 C 113.207031 65.792969 119.421875 66.707031 125.636719 66.707031 C 138.359375 66.707031 144.765625 59.941406 144.765625 52.09375 C 144.765625 40.273438 134.117188 38.378906 125.726562 37.835938 C 120.042969 37.476562 117.425781 35.851562 117.425781 32.964844 C 117.425781 30.167969 120.3125 28.542969 125.546875 28.542969 C 129.78125 28.542969 133.382812 29.535156 136.539062 32.5 Z M 139.753906 23.066406 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 193.445312 41.808594 L 193.445312 65.175781 L 182.4375 65.175781 L 182.4375 41.898438 C 182.4375 35.128906 178.738281 29.988281 171.699219 29.988281 C 164.933594 29.988281 160.332031 35.671875 160.332031 42.4375 L 160.332031 65.175781 L 149.414062 65.175781 L 149.414062 20.605469 L 159.25 20.605469 L 159.972656 26.648438 C 164.484375 22.230469 168.992188 19.972656 174.585938 19.972656 C 185.054688 19.972656 193.445312 27.824219 193.445312 41.808594 Z M 193.445312 41.808594 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 230.257812 59.941406 L 230.257812 83.761719 L 219.25 83.761719 L 219.25 20.695312 L 229.535156 20.695312 L 230.257812 26.832031 C 233.683594 21.867188 239.640625 19.703125 244.691406 19.703125 C 258.40625 19.703125 267.519531 29.898438 267.519531 42.980469 C 267.519531 55.972656 259.308594 66.257812 245.054688 66.257812 C 240.363281 66.257812 233.414062 64.8125 230.257812 59.941406 Z M 231.25 42.980469 C 231.25 49.925781 236.390625 55.609375 243.878906 55.609375 C 251.367188 55.609375 256.511719 49.925781 256.511719 42.980469 C 256.511719 36.03125 251.820312 30.347656 243.878906 30.347656 C 235.941406 30.347656 231.25 36.03125 231.25 42.980469 Z M 231.25 42.980469 "
                fillOpacity="1"
                fillRule="evenodd"
            />
            <path
                fill="#2581da"
                d="M 273.503906 2.109375 L 273.503906 65.175781 L 284.421875 65.175781 L 284.421875 2.109375 Z M 273.503906 2.109375 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#2581da"
                d="M 315.574219 40.183594 L 323.695312 20.695312 L 335.875 20.695312 L 310.851562 83.761719 L 298.761719 83.761719 L 304.925781 64.453125 L 287.15625 20.695312 L 299.964844 20.695312 L 306.730469 39.914062 L 310.972656 52.453125 Z M 315.574219 40.183594 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(116.966165, 104.212743)">
                    <g>
                        <path d="M 4.21875 -11.21875 C 4.695312 -12.039062 5.382812 -12.710938 6.28125 -13.234375 C 7.1875 -13.753906 8.222656 -14.015625 9.390625 -14.015625 C 10.628906 -14.015625 11.742188 -13.71875 12.734375 -13.125 C 13.722656 -12.539062 14.5 -11.71875 15.0625 -10.65625 C 15.632812 -9.59375 15.921875 -8.351562 15.921875 -6.9375 C 15.921875 -5.550781 15.632812 -4.3125 15.0625 -3.21875 C 14.5 -2.125 13.71875 -1.273438 12.71875 -0.671875 C 11.71875 -0.078125 10.609375 0.21875 9.390625 0.21875 C 8.191406 0.21875 7.144531 -0.0351562 6.25 -0.546875 C 5.351562 -1.066406 4.675781 -1.734375 4.21875 -2.546875 L 4.21875 0 L 1.9375 0 L 1.9375 -18.609375 L 4.21875 -18.609375 Z M 13.578125 -6.9375 C 13.578125 -7.976562 13.367188 -8.882812 12.953125 -9.65625 C 12.535156 -10.425781 11.96875 -11.015625 11.25 -11.421875 C 10.539062 -11.828125 9.757812 -12.03125 8.90625 -12.03125 C 8.0625 -12.03125 7.285156 -11.820312 6.578125 -11.40625 C 5.867188 -11 5.296875 -10.40625 4.859375 -9.625 C 4.429688 -8.84375 4.21875 -7.941406 4.21875 -6.921875 C 4.21875 -5.878906 4.429688 -4.96875 4.859375 -4.1875 C 5.296875 -3.40625 5.867188 -2.804688 6.578125 -2.390625 C 7.285156 -1.984375 8.0625 -1.78125 8.90625 -1.78125 C 9.757812 -1.78125 10.539062 -1.984375 11.25 -2.390625 C 11.96875 -2.804688 12.535156 -3.40625 12.953125 -4.1875 C 13.367188 -4.96875 13.578125 -5.882812 13.578125 -6.9375 Z M 13.578125 -6.9375 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(136.691, 104.212743)">
                    <g>
                        <path d="M 13.8125 -13.78125 L 5.515625 6.484375 L 3.140625 6.484375 L 5.859375 -0.15625 L 0.296875 -13.78125 L 2.84375 -13.78125 L 7.171875 -2.609375 L 11.453125 -13.78125 Z M 13.8125 -13.78125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(153.572935, 104.212743)">
                    <g />
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(163.007966, 104.212743)">
                    <g>
                        <path d="M 4.171875 -17.53125 L 4.171875 -6.4375 C 4.171875 -4.875 4.550781 -3.71875 5.3125 -2.96875 C 6.082031 -2.21875 7.144531 -1.84375 8.5 -1.84375 C 9.84375 -1.84375 10.894531 -2.21875 11.65625 -2.96875 C 12.414062 -3.71875 12.796875 -4.875 12.796875 -6.4375 L 12.796875 -17.53125 L 15.09375 -17.53125 L 15.09375 -6.46875 C 15.09375 -5.007812 14.796875 -3.78125 14.203125 -2.78125 C 13.617188 -1.78125 12.828125 -1.035156 11.828125 -0.546875 C 10.835938 -0.0664062 9.722656 0.171875 8.484375 0.171875 C 7.234375 0.171875 6.109375 -0.0664062 5.109375 -0.546875 C 4.117188 -1.035156 3.332031 -1.78125 2.75 -2.78125 C 2.175781 -3.78125 1.890625 -5.007812 1.890625 -6.46875 L 1.890625 -17.53125 Z M 4.171875 -17.53125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(182.70765, 104.212743)">
                    <g>
                        <path d="M 4.21875 -11.25 C 4.675781 -12.03125 5.351562 -12.6875 6.25 -13.21875 C 7.144531 -13.75 8.191406 -14.015625 9.390625 -14.015625 C 10.609375 -14.015625 11.71875 -13.71875 12.71875 -13.125 C 13.71875 -12.539062 14.5 -11.71875 15.0625 -10.65625 C 15.632812 -9.59375 15.921875 -8.351562 15.921875 -6.9375 C 15.921875 -5.550781 15.632812 -4.3125 15.0625 -3.21875 C 14.5 -2.125 13.71875 -1.273438 12.71875 -0.671875 C 11.71875 -0.078125 10.609375 0.21875 9.390625 0.21875 C 8.210938 0.21875 7.175781 -0.0390625 6.28125 -0.5625 C 5.382812 -1.09375 4.695312 -1.753906 4.21875 -2.546875 L 4.21875 6.546875 L 1.9375 6.546875 L 1.9375 -13.78125 L 4.21875 -13.78125 Z M 13.578125 -6.9375 C 13.578125 -7.976562 13.367188 -8.882812 12.953125 -9.65625 C 12.535156 -10.425781 11.96875 -11.015625 11.25 -11.421875 C 10.539062 -11.828125 9.757812 -12.03125 8.90625 -12.03125 C 8.0625 -12.03125 7.285156 -11.820312 6.578125 -11.40625 C 5.867188 -11 5.296875 -10.40625 4.859375 -9.625 C 4.429688 -8.84375 4.21875 -7.941406 4.21875 -6.921875 C 4.21875 -5.878906 4.429688 -4.96875 4.859375 -4.1875 C 5.296875 -3.40625 5.867188 -2.804688 6.578125 -2.390625 C 7.285156 -1.984375 8.0625 -1.78125 8.90625 -1.78125 C 9.757812 -1.78125 10.539062 -1.984375 11.25 -2.390625 C 11.96875 -2.804688 12.535156 -3.40625 12.953125 -4.1875 C 13.367188 -4.96875 13.578125 -5.882812 13.578125 -6.9375 Z M 13.578125 -6.9375 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(202.432483, 104.212743)">
                    <g>
                        <path d="M 1.078125 -8.78125 C 1.078125 -10.488281 1.460938 -12.023438 2.234375 -13.390625 C 3.003906 -14.765625 4.054688 -15.835938 5.390625 -16.609375 C 6.722656 -17.378906 8.207031 -17.765625 9.84375 -17.765625 C 11.75 -17.765625 13.414062 -17.300781 14.84375 -16.375 C 16.269531 -15.457031 17.3125 -14.148438 17.96875 -12.453125 L 15.21875 -12.453125 C 14.726562 -13.503906 14.023438 -14.316406 13.109375 -14.890625 C 12.203125 -15.460938 11.113281 -15.75 9.84375 -15.75 C 8.613281 -15.75 7.507812 -15.460938 6.53125 -14.890625 C 5.5625 -14.316406 4.800781 -13.503906 4.25 -12.453125 C 3.695312 -11.410156 3.421875 -10.1875 3.421875 -8.78125 C 3.421875 -7.382812 3.695312 -6.160156 4.25 -5.109375 C 4.800781 -4.066406 5.5625 -3.257812 6.53125 -2.6875 C 7.507812 -2.125 8.613281 -1.84375 9.84375 -1.84375 C 11.113281 -1.84375 12.203125 -2.117188 13.109375 -2.671875 C 14.023438 -3.234375 14.726562 -4.046875 15.21875 -5.109375 L 17.96875 -5.109375 C 17.3125 -3.429688 16.269531 -2.132812 14.84375 -1.21875 C 13.414062 -0.300781 11.75 0.15625 9.84375 0.15625 C 8.207031 0.15625 6.722656 -0.222656 5.390625 -0.984375 C 4.054688 -1.753906 3.003906 -2.816406 2.234375 -4.171875 C 1.460938 -5.535156 1.078125 -7.070312 1.078125 -8.78125 Z M 1.078125 -8.78125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(224.572528, 104.212743)">
                    <g>
                        <path d="M 7.953125 0.21875 C 6.660156 0.21875 5.488281 -0.0703125 4.4375 -0.65625 C 3.394531 -1.238281 2.570312 -2.070312 1.96875 -3.15625 C 1.375 -4.238281 1.078125 -5.492188 1.078125 -6.921875 C 1.078125 -8.328125 1.382812 -9.570312 2 -10.65625 C 2.613281 -11.738281 3.445312 -12.566406 4.5 -13.140625 C 5.5625 -13.722656 6.742188 -14.015625 8.046875 -14.015625 C 9.359375 -14.015625 10.539062 -13.722656 11.59375 -13.140625 C 12.65625 -12.566406 13.488281 -11.742188 14.09375 -10.671875 C 14.707031 -9.597656 15.015625 -8.347656 15.015625 -6.921875 C 15.015625 -5.492188 14.695312 -4.238281 14.0625 -3.15625 C 13.4375 -2.070312 12.585938 -1.238281 11.515625 -0.65625 C 10.441406 -0.0703125 9.253906 0.21875 7.953125 0.21875 Z M 7.953125 -1.78125 C 8.773438 -1.78125 9.546875 -1.972656 10.265625 -2.359375 C 10.984375 -2.742188 11.5625 -3.320312 12 -4.09375 C 12.445312 -4.863281 12.671875 -5.804688 12.671875 -6.921875 C 12.671875 -8.023438 12.453125 -8.960938 12.015625 -9.734375 C 11.585938 -10.503906 11.019531 -11.078125 10.3125 -11.453125 C 9.601562 -11.835938 8.84375 -12.03125 8.03125 -12.03125 C 7.1875 -12.03125 6.414062 -11.835938 5.71875 -11.453125 C 5.019531 -11.078125 4.460938 -10.503906 4.046875 -9.734375 C 3.628906 -8.960938 3.421875 -8.023438 3.421875 -6.921875 C 3.421875 -5.796875 3.625 -4.847656 4.03125 -4.078125 C 4.445312 -3.304688 5 -2.726562 5.6875 -2.34375 C 6.375 -1.96875 7.128906 -1.78125 7.953125 -1.78125 Z M 7.953125 -1.78125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(243.391657, 104.212743)">
                    <g>
                        <path d="M 8.65625 -14.03125 C 10.332031 -14.03125 11.6875 -13.523438 12.71875 -12.515625 C 13.757812 -11.503906 14.28125 -10.039062 14.28125 -8.125 L 14.28125 0 L 12.03125 0 L 12.03125 -7.796875 C 12.03125 -9.171875 11.6875 -10.222656 11 -10.953125 C 10.3125 -11.679688 9.367188 -12.046875 8.171875 -12.046875 C 6.960938 -12.046875 6 -11.664062 5.28125 -10.90625 C 4.570312 -10.15625 4.21875 -9.0625 4.21875 -7.625 L 4.21875 0 L 1.9375 0 L 1.9375 -13.78125 L 4.21875 -13.78125 L 4.21875 -11.828125 C 4.675781 -12.523438 5.296875 -13.066406 6.078125 -13.453125 C 6.859375 -13.835938 7.71875 -14.03125 8.65625 -14.03125 Z M 8.65625 -14.03125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(262.210786, 104.212743)">
                    <g>
                        <path d="M 4.734375 -11.90625 L 4.734375 -3.78125 C 4.734375 -3.101562 4.875 -2.625 5.15625 -2.34375 C 5.4375 -2.070312 5.929688 -1.9375 6.640625 -1.9375 L 8.328125 -1.9375 L 8.328125 0 L 6.265625 0 C 4.992188 0 4.035156 -0.289062 3.390625 -0.875 C 2.753906 -1.46875 2.4375 -2.4375 2.4375 -3.78125 L 2.4375 -11.90625 L 0.65625 -11.90625 L 0.65625 -13.78125 L 2.4375 -13.78125 L 2.4375 -17.25 L 4.734375 -17.25 L 4.734375 -13.78125 L 8.328125 -13.78125 L 8.328125 -11.90625 Z M 4.734375 -11.90625 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(274.086181, 104.212743)">
                    <g>
                        <path d="M 14.515625 -7.421875 C 14.515625 -6.984375 14.488281 -6.519531 14.4375 -6.03125 L 3.421875 -6.03125 C 3.503906 -4.675781 3.96875 -3.613281 4.8125 -2.84375 C 5.664062 -2.082031 6.695312 -1.703125 7.90625 -1.703125 C 8.894531 -1.703125 9.71875 -1.929688 10.375 -2.390625 C 11.039062 -2.859375 11.503906 -3.476562 11.765625 -4.25 L 14.234375 -4.25 C 13.867188 -2.925781 13.128906 -1.847656 12.015625 -1.015625 C 10.910156 -0.191406 9.539062 0.21875 7.90625 0.21875 C 6.59375 0.21875 5.421875 -0.0703125 4.390625 -0.65625 C 3.359375 -1.238281 2.546875 -2.070312 1.953125 -3.15625 C 1.367188 -4.238281 1.078125 -5.492188 1.078125 -6.921875 C 1.078125 -8.347656 1.363281 -9.597656 1.9375 -10.671875 C 2.507812 -11.742188 3.3125 -12.566406 4.34375 -13.140625 C 5.375 -13.722656 6.5625 -14.015625 7.90625 -14.015625 C 9.207031 -14.015625 10.359375 -13.726562 11.359375 -13.15625 C 12.367188 -12.582031 13.144531 -11.796875 13.6875 -10.796875 C 14.238281 -9.804688 14.515625 -8.679688 14.515625 -7.421875 Z M 12.15625 -7.90625 C 12.15625 -8.769531 11.960938 -9.515625 11.578125 -10.140625 C 11.191406 -10.773438 10.664062 -11.253906 10 -11.578125 C 9.332031 -11.910156 8.597656 -12.078125 7.796875 -12.078125 C 6.640625 -12.078125 5.65625 -11.707031 4.84375 -10.96875 C 4.03125 -10.226562 3.566406 -9.207031 3.453125 -7.90625 Z M 12.15625 -7.90625 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(292.402141, 104.212743)">
                    <g>
                        <path d="M 8.65625 -14.03125 C 10.332031 -14.03125 11.6875 -13.523438 12.71875 -12.515625 C 13.757812 -11.503906 14.28125 -10.039062 14.28125 -8.125 L 14.28125 0 L 12.03125 0 L 12.03125 -7.796875 C 12.03125 -9.171875 11.6875 -10.222656 11 -10.953125 C 10.3125 -11.679688 9.367188 -12.046875 8.171875 -12.046875 C 6.960938 -12.046875 6 -11.664062 5.28125 -10.90625 C 4.570312 -10.15625 4.21875 -9.0625 4.21875 -7.625 L 4.21875 0 L 1.9375 0 L 1.9375 -13.78125 L 4.21875 -13.78125 L 4.21875 -11.828125 C 4.675781 -12.523438 5.296875 -13.066406 6.078125 -13.453125 C 6.859375 -13.835938 7.71875 -14.03125 8.65625 -14.03125 Z M 8.65625 -14.03125 " />
                    </g>
                </g>
            </g>
            <g fill="#080b2a" fillOpacity="1">
                <g transform="translate(311.22128, 104.212743)">
                    <g>
                        <path d="M 4.734375 -11.90625 L 4.734375 -3.78125 C 4.734375 -3.101562 4.875 -2.625 5.15625 -2.34375 C 5.4375 -2.070312 5.929688 -1.9375 6.640625 -1.9375 L 8.328125 -1.9375 L 8.328125 0 L 6.265625 0 C 4.992188 0 4.035156 -0.289062 3.390625 -0.875 C 2.753906 -1.46875 2.4375 -2.4375 2.4375 -3.78125 L 2.4375 -11.90625 L 0.65625 -11.90625 L 0.65625 -13.78125 L 2.4375 -13.78125 L 2.4375 -17.25 L 4.734375 -17.25 L 4.734375 -13.78125 L 8.328125 -13.78125 L 8.328125 -11.90625 Z M 4.734375 -11.90625 " />
                    </g>
                </g>
            </g>
            <path
                fill="#080b2a"
                d="M 200.769531 20.515625 L 211.777344 20.515625 L 211.777344 65.175781 L 200.769531 65.175781 Z M 200.769531 20.515625 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#080b2a"
                d="M 199.777344 8.15625 C 199.777344 16.726562 212.769531 16.726562 212.769531 8.15625 C 212.769531 -0.417969 199.777344 -0.417969 199.777344 8.15625 Z M 199.777344 8.15625 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#080b2a"
                d="M 139.753906 23.066406 C 135.507812 20.324219 131 19.433594 125.367188 19.433594 C 117.246094 19.433594 106.601562 23.042969 106.601562 33.328125 C 106.601562 43.429688 116.613281 46.5 125.003906 47.128906 C 131.320312 47.492188 133.847656 48.753906 133.847656 51.820312 C 133.847656 55.070312 129.96875 57.234375 125.816406 57.144531 C 120.855469 57.054688 113.636719 54.4375 110.480469 50.917969 L 110.417969 51.011719 L 107.367188 60.921875 C 113.207031 65.792969 119.421875 66.707031 125.636719 66.707031 C 138.359375 66.707031 144.765625 59.941406 144.765625 52.09375 C 144.765625 40.273438 134.117188 38.378906 125.726562 37.835938 C 120.042969 37.476562 117.425781 35.851562 117.425781 32.964844 C 117.425781 30.167969 120.3125 28.542969 125.546875 28.542969 C 129.78125 28.542969 133.382812 29.535156 136.539062 32.5 Z M 139.753906 23.066406 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#080b2a"
                d="M 193.445312 41.808594 L 193.445312 65.175781 L 182.4375 65.175781 L 182.4375 41.898438 C 182.4375 35.128906 178.738281 29.988281 171.699219 29.988281 C 164.933594 29.988281 160.332031 35.671875 160.332031 42.4375 L 160.332031 65.175781 L 149.414062 65.175781 L 149.414062 20.605469 L 159.25 20.605469 L 159.972656 26.648438 C 164.484375 22.230469 168.992188 19.972656 174.585938 19.972656 C 185.054688 19.972656 193.445312 27.824219 193.445312 41.808594 Z M 193.445312 41.808594 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#080b2a"
                d="M 230.257812 59.941406 L 230.257812 83.761719 L 219.25 83.761719 L 219.25 20.695312 L 229.535156 20.695312 L 230.257812 26.832031 C 233.683594 21.867188 239.640625 19.703125 244.691406 19.703125 C 258.40625 19.703125 267.519531 29.898438 267.519531 42.980469 C 267.519531 55.972656 259.308594 66.257812 245.054688 66.257812 C 240.363281 66.257812 233.414062 64.8125 230.257812 59.941406 Z M 231.25 42.980469 C 231.25 49.925781 236.390625 55.609375 243.878906 55.609375 C 251.367188 55.609375 256.511719 49.925781 256.511719 42.980469 C 256.511719 36.03125 251.820312 30.347656 243.878906 30.347656 C 235.941406 30.347656 231.25 36.03125 231.25 42.980469 Z M 231.25 42.980469 "
                fillOpacity="1"
                fillRule="evenodd"
            />
            <path
                fill="#080b2a"
                d="M 273.503906 2.109375 L 273.503906 65.175781 L 284.421875 65.175781 L 284.421875 2.109375 Z M 273.503906 2.109375 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <path
                fill="#080b2a"
                d="M 315.574219 40.183594 L 323.695312 20.695312 L 335.875 20.695312 L 310.851562 83.761719 L 298.761719 83.761719 L 304.925781 64.453125 L 287.15625 20.695312 L 299.964844 20.695312 L 306.730469 39.914062 L 310.972656 52.453125 Z M 315.574219 40.183594 "
                fillOpacity="1"
                fillRule="nonzero"
            />
        </svg>
    );
