import React from 'react';
import { RouteLink } from '../Routing';
import DropdownIcon from '../assets/icons/dropdown-down.svg';
import PlusIcon from '../assets/icons/plus.svg';

export interface DropDownOption {
    readonly displayLabel: string;
    readonly value: string;
    readonly displayValue?: string;
    readonly inActive?: boolean;
}

interface DropdownSelectLinkCreateNewProps {
    readonly href: string;
    readonly displayText: string;
}

export const DropdownSelectLinkCreateNew: React.FC<DropdownSelectLinkCreateNewProps> = ({
    href,
    displayText
}) => (
    <RouteLink href={href} className="flex items-center">
        <img className="h-4 w-4" src={PlusIcon} />
        <span className="p-2">{displayText}</span>
    </RouteLink>
);

interface DropdownSelectProps {
    readonly className?: string;
    readonly titleText?: string;
    readonly titleIcon?: JSX.Element;
    readonly placeholderText?: string;
    readonly options: DropDownOption[];
    readonly selectedOption: string;
    readonly onSelectOption: (selectedOption: string) => void;
    readonly selectedOptionLabelText?: string;
    readonly enableSearch?: boolean;
    readonly enableSelectedOptionBold?: boolean;
    readonly enableTextBase?: boolean;
    readonly searchInputPlaceholder?: string;
    readonly linkCreateNew?: JSX.Element;
    readonly nextBestActionButton?: JSX.Element;
    readonly rounded?: boolean;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
    className,
    titleText,
    titleIcon,
    placeholderText = '',
    options,
    selectedOption,
    onSelectOption,
    selectedOptionLabelText,
    enableSearch = false,
    enableSelectedOptionBold = false,
    enableTextBase = false,
    searchInputPlaceholder = '',
    linkCreateNew,
    nextBestActionButton,
    rounded = false
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filteredOptions, setFilteredOptions] = React.useState(options);

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        setFilteredOptions(
            options.filter((option) =>
                option.value.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, options]);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={className}>
            {titleText && (
                <div className="mb-1 flex items-center justify-between">
                    <div className="flex items-center">
                        <span className="flex text-sm"> {titleText}</span>
                        {titleIcon && <span className="ml-2">{titleIcon}</span>}
                    </div>
                    {nextBestActionButton ?? null}
                </div>
            )}
            <div
                className={`relative border border-grey-lighter bg-white w-full ${
                    rounded ? 'rounded-full' : 'rounded-md'
                }`}
                ref={dropdownContentRef}
            >
                <button
                    className={`flex items-center justify-between w-full h-10 p-3 ${
                        enableTextBase ? 'text-base' : 'text-sm'
                    }`}
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                >
                    {selectedOption ? (
                        <div className="flex items-center justify-between w-full pr-2 min-w-0">
                            <span
                                className={`truncate ${
                                    enableSelectedOptionBold ? 'font-semibold' : 'font-normal'
                                }`}
                            >
                                {
                                    options.find((option) => option.value === selectedOption)
                                        ?.displayLabel
                                }
                            </span>
                            {selectedOptionLabelText && (
                                <span className="truncate rounded-md bg-grey-tint py-1 px-3">
                                    {selectedOptionLabelText}
                                </span>
                            )}
                        </div>
                    ) : (
                        <span className="text-grey-light">{placeholderText}</span>
                    )}
                    {open ? (
                        <div className="ml-2 rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="ml-2 h-2 w-2" src={DropdownIcon} />
                    )}
                </button>
                {open && filteredOptions.length > 0 && (
                    <div className="absolute top-11 text-sm rounded-md shadow-md px-3 pb-2 w-full bg-white border border-grey-lighter z-50  max-h-40 overflow-y-scroll">
                        {enableSearch && (
                            <input
                                className="mt-1 w-full border-b p-2"
                                type="text"
                                placeholder={searchInputPlaceholder}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                            />
                        )}
                        {linkCreateNew && linkCreateNew}
                        <ul>
                            {filteredOptions
                                .filter((option) => option.value !== selectedOption)
                                .map((option) => (
                                    <li
                                        className="mt-2 rounded-md w-full min-w-0"
                                        key={option.value}
                                    >
                                        <button
                                            className={`flex items-center justify-between w-full text-left min-w-0 ${
                                                option.inActive ? 'text-grey-medium' : 'text-grey'
                                            }`}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectOption(option.value);
                                                setOpen(false);
                                            }}
                                            disabled={option.inActive}
                                        >
                                            <span className="truncate">{option.displayLabel}</span>
                                            {option.displayValue && (
                                                <span className=" text-sm p-1 bg-grey-lighter rounded-full">
                                                    {option.displayValue}
                                                </span>
                                            )}
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
