import React from 'react';
import { UserSettingsChangeEmail } from './UserSettingsChangeEmail';
import { UserSettingsChangePassword } from './UserSettingsChangePassword';
import { AccountSettingsContentAndPlatform } from './AccountSettingsContentAndPlatform';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FocusViewLayout } from './components/FocusViewLayout';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid, StandardContentChildGrid } from './components/StandardContentGrid';
import { VerticalSpacer } from './components/VerticalSpacer';
import { ModalDeactivateAccount } from './ModalDeactivateAccount';
import { ModalEmailSubscription } from './ModalEmailSubscription';
import { UserSettingsChangeName } from './UserSettingsChangeName';

export const UserSettings: React.FC = () => {
    const [showModalEmailSubscriptionSettings, setShowModalEmailSubscriptionSettings] =
        React.useState(false);

    const [showModalDeactivateAccount, setShowModalDeactivateAccount] = React.useState(false);

    return (
        <FocusViewLayout enableWideView>
            <h2 className="font-semibold text-lg">User Settings</h2>
            <VerticalSpacer heightValue={10} />
            {showModalEmailSubscriptionSettings ? (
                <StandardContentGrid>
                    <ModalEmailSubscription
                        closeModal={() => setShowModalEmailSubscriptionSettings(false)}
                    />
                </StandardContentGrid>
            ) : showModalDeactivateAccount ? (
                <StandardContentGrid>
                    <ModalDeactivateAccount
                        closeModal={() => setShowModalDeactivateAccount(false)}
                    />
                </StandardContentGrid>
            ) : (
                <StandardContentGrid>
                    <StandardContentChildGrid>
                        <UserSettingsChangeName />
                        <UserSettingsChangePassword />
                        <UserSettingsChangeEmail />
                    </StandardContentChildGrid>
                    <StandardContentChildGrid>
                        <ContentElementContainer>
                            <SimpleContentBox
                                headerText="Email Subscriptions"
                                infoText="Change your email subscription settings here"
                            >
                                <ButtonMain
                                    className="w-full xs:w-56"
                                    size="small"
                                    onClick={() => setShowModalEmailSubscriptionSettings(true)}
                                >
                                    Manage subscriptions
                                </ButtonMain>
                            </SimpleContentBox>
                        </ContentElementContainer>
                        <AccountSettingsContentAndPlatform />
                        <ContentElementContainer>
                            <SimpleContentBox
                                headerText="Deactivate Account"
                                infoText="Disable your login and set all your snips to redirect to their destination URL (no call-to-actions will be shown)."
                            >
                                <ButtonMain
                                    className="w-full xs:w-56"
                                    style="warning"
                                    size="small"
                                    onClick={() => {
                                        setShowModalDeactivateAccount(true);
                                    }}
                                >
                                    Deactivate account
                                </ButtonMain>
                            </SimpleContentBox>
                        </ContentElementContainer>
                    </StandardContentChildGrid>
                </StandardContentGrid>
            )}
        </FocusViewLayout>
    );
};
