import React from 'react';
import { IntegrationAppCard } from './components/IntegrationAppCard';
import BufferImage from './assets/images/Integrations/Buffer.png';
import HootsuiteImage from './assets/images/Integrations/Hootsuite.png';
import SocialPilotImage from './assets/images/Integrations/SocialPilot.png';
import EdgarImage from './assets/images/Integrations/Edgar.png';
import FacebookImage from './assets/images/Integrations/Facebook.png';
import TwitterImage from './assets/images/Integrations/Twitter.png';
import LinkedinImage from './assets/images/Integrations/Linkedin.png';
import SproutImage from './assets/images/Integrations/SproutSocial.png';
import PostPlannerImage from './assets/images/Integrations/PostPlanner.png';
import HubSpotImage from './assets/images/Integrations/HubSpot.png';
import MailChimpImage from './assets/images/Integrations/MailChimp.png';
import AweberImage from './assets/images/Integrations/Aweber.png';
import ActiveCampaignImage from './assets/images/Integrations/ActiveCampaign.png';
import GetResponseImage from './assets/images/Integrations/GetResponse.png';
import CampaignMonitorImage from './assets/images/Integrations/CampaignMonitor.png';
import ZapierImage from './assets/images/Integrations/Zapier.png';
import SalesForceImage from './assets/images/Integrations/Salesforce.png';
import SugarCrmImage from './assets/images/Integrations/SugarCrm.png';
import InfusionsoftImage from './assets/images/Integrations/Infusionsoft.png';
import BundlePostImage from './assets/images/Integrations/BundlePost.png';
import FeedlyImage from './assets/images/Integrations/Feedly.png';
import SendibleImage from './assets/images/Integrations/Sendible.png';
import IftttImage from './assets/images/Integrations/Ifttt.png';
import DlvrImage from './assets/images/Integrations/Dvlr.png';
import ChromeImage from './assets/images/Integrations/Chrome.png';
import FirefoxImage from './assets/images/Integrations/Firefox.png';
import SafariImage from './assets/images/Integrations/Safari.png';

const DIRECTORY_BASE_URL = process.env.PRODUCTION ? 'https://snip.ly' : 'https://sniply.site';

interface IntegrationApp {
    readonly name: string;
    readonly descriptionText: string;
    readonly iconUrl: string;
    readonly targetUrl: string;
}

export const IntegrationsAppDirectory: React.FC = () => {
    const [integrationApps, setIntegrationApps] = React.useState<IntegrationApp[]>([]);

    React.useEffect(() => {
        setIntegrationApps([
            {
                name: 'Buffer',
                descriptionText: 'Quickly schedule content from anywhere on the web.',
                iconUrl: BufferImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Buffer/'
            },
            {
                name: 'Hootsuite',
                descriptionText: 'A social media management system for brand management.',
                iconUrl: HootsuiteImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Hootsuite/'
            },
            {
                name: 'SocialPilot',
                descriptionText: 'Social media platform for agencies and professionals.',
                iconUrl: SocialPilotImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/SocialPilot/'
            },
            {
                name: 'Edgar',
                descriptionText: 'The social media queue that fills itself.',
                iconUrl: EdgarImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Edgar/'
            },
            {
                name: 'Facebook',
                descriptionText: 'A social utility that connects people with friends.',
                iconUrl: FacebookImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Facebook/'
            },
            {
                name: 'Twitter',
                descriptionText: 'An online social networking and microblogging service.',
                iconUrl: TwitterImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Twitter/'
            },
            {
                name: 'LinkedIn',
                descriptionText: 'A business-oriented social networking service.',
                iconUrl: LinkedinImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/LinkedIn/'
            },
            {
                name: 'Sprout Social',
                descriptionText: 'A social media tool for growing your social media presence.',
                iconUrl: SproutImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Sprout/'
            },
            {
                name: 'PostPlanner',
                descriptionText: 'Smarter content. Better posts. Mind boggling results.',
                iconUrl: PostPlannerImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/PostPlanner/'
            },
            {
                name: 'HubSpot',
                descriptionText: 'An inbound marketing software platform for attracting visitors.',
                iconUrl: HubSpotImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/HubSpot/'
            },
            {
                name: 'MailChimp',
                descriptionText: 'A large email marketing service provider.',
                iconUrl: MailChimpImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/MailChimp/'
            },
            {
                name: 'Aweber',
                descriptionText: 'A professional email marketing service.',
                iconUrl: AweberImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Aweber/'
            },
            {
                name: 'Active Campaign',
                descriptionText: 'Email marketing, CRM, and marketing automation.',
                iconUrl: ActiveCampaignImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/ActiveCampaign/'
            },
            {
                name: 'GetResponse',
                descriptionText: 'Email marketing software made simple.',
                iconUrl: GetResponseImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/GetResponse/'
            },
            {
                name: 'Campaign Monitor',
                descriptionText: 'Create, send, and optimize your email campaigns.',
                iconUrl: CampaignMonitorImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/CampaignMonitor/'
            },
            {
                name: 'Zapier',
                descriptionText: 'An integrations platform to connect hundreds of web apps.',
                iconUrl: ZapierImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Zapier/'
            },
            {
                name: 'Salesforce',
                descriptionText: 'An enterprise Customer Relationship Manager (CRM) application.',
                iconUrl: SalesForceImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Salesforce/'
            },
            {
                name: 'SugarCRM',
                descriptionText: 'A popular Customer Relationship Managment platform.',
                iconUrl: SugarCrmImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/SugarCRM/'
            },
            {
                name: 'Infusionsoft',
                descriptionText: 'A CRM designed for small business owners.',
                iconUrl: InfusionsoftImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Infusionsoft/'
            },
            {
                name: 'Bundle Post',
                descriptionText: 'A social media content management tool.',
                iconUrl: BundlePostImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/BundlePost/'
            },
            {
                name: 'Sendible',
                descriptionText: 'A social media management platform.',
                iconUrl: SendibleImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Sendible/'
            },
            {
                name: 'Feedly',
                descriptionText: 'An online content aggregator.',
                iconUrl: FeedlyImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Feedly/'
            },
            {
                name: 'IFTTT',
                descriptionText:
                    'An integration platform designed to connect apps and automate tasks.',
                iconUrl: IftttImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/IFTTT/'
            },
            {
                name: 'Dlvr.it',
                descriptionText: 'An online content distribution tool.',
                iconUrl: DlvrImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Dlvr.it/'
            },
            {
                name: 'Chrome',
                descriptionText: 'A popular web browser made by Google.',
                iconUrl: ChromeImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Chrome/'
            },
            {
                name: 'Firefox',
                descriptionText: 'A popular web browser made by Mozilla.',
                iconUrl: FirefoxImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Firefox/'
            },
            {
                name: 'Safari',
                descriptionText: 'The default Mac OS X web browser.',
                iconUrl: SafariImage,
                targetUrl: DIRECTORY_BASE_URL + '/integrations/Safari/'
            }
        ]);
    }, []);

    return (
        <div className="grid gap-4 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {integrationApps?.map((integrationApp) => (
                <IntegrationAppCard
                    key={integrationApp.name}
                    appName={integrationApp.name}
                    appDescriptionText={integrationApp.descriptionText}
                    iconUrl={integrationApp.iconUrl}
                    targetUrl={integrationApp.targetUrl}
                />
            ))}
        </div>
    );
};
