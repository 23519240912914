import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import { UserService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { AuthContext } from './auth';
import { UserContext } from './UserContextProvider';

export const UserSettingsChangeName = () => {
    const [currentFirstName, setCurrentFirstName] = React.useState('');
    const [currentLastName, setCurrentLastName] = React.useState('');

    const [isUpdatingName, setIsUpdatingName] = React.useState(false);
    const [backendError, setBackendError] = React.useState(false);

    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    const handleChangeName = React.useCallback(
        (event) => {
            event.preventDefault();

            setIsUpdatingName(true);

            UserService.userPartialUpdate({
                requestBody: {
                    first_name: currentFirstName,
                    last_name: currentLastName
                }
            })
                .then((result) => {
                    console.log('UserService.userChangePasswordUpdate()', result);
                    setIsUpdatingName(false);

                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                })
                .catch((error) => {
                    console.error(
                        'Error changing user names via UserService.userPartialUpdate()',
                        error
                    );
                    setBackendError(true);
                    setIsUpdatingName(false);
                });
        },
        [currentFirstName, currentLastName]
    );

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const userContext = React.useContext(UserContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && userContext) {
            setCurrentFirstName(userContext.firstName);
            setCurrentLastName(userContext.lastName);
        }
    }, [isOpenAPITokenSet, userContext]);

    React.useEffect(() => {
        backendError && setBackendError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFirstName, currentLastName]);

    return (
        <ContentElementContainer>
            <SimpleContentBox headerText="Change Name">
                <form className="mt-1 grid gap-y-4" onSubmit={handleChangeName}>
                    <div className="flex flex-col xs:w-1/2 xs:pr-1">
                        <FormTextInput
                            id="first-name"
                            type="text"
                            labelText="First Name"
                            value={currentFirstName}
                            onChange={(event) => {
                                setCurrentFirstName(event.target.value);
                            }}
                            bold
                        />
                    </div>
                    <div className="grid grid-cols-1 xs:grid-cols-2 gap-2">
                        <FormTextInput
                            id="last-name"
                            type="text"
                            labelText="Last Name"
                            value={currentLastName}
                            onChange={(event) => {
                                setCurrentLastName(event.target.value);
                            }}
                            bold
                        />
                    </div>
                    {backendError && (
                        <div className="text-red">
                            Apologies something went wrong. An unexpected network error has
                            occurred. Please try refreshing the browser or try again later.
                        </div>
                    )}
                    {isUpdatingName ? (
                        <div>
                            <LoadingSpinner size={5} />
                        </div>
                    ) : showSuccessMessage ? (
                        <div className="text-green">Name successfully changed.</div>
                    ) : (
                        <ButtonMain className="xs:w-40" size="small" type="submit">
                            Save
                        </ButtonMain>
                    )}
                </form>
            </SimpleContentBox>
        </ContentElementContainer>
    );
};
