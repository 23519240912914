import React from 'react';
import { RouteLink } from '../Routing';

interface TableButtonProps {
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly children?: React.ReactNode;
}

export const TableButton: React.FC<TableButtonProps> = ({ onClick, children }) => {
    return (
        <button
            onClick={(event) => onClick && onClick(event)}
            className="text-grey border border-grey-light hover:bg-grey-lightest rounded-md text-sm px-2 py-1"
        >
            {children}
        </button>
    );
};

interface TableLinkButtonProps {
    readonly href: string;
    readonly children?: React.ReactNode;
}

export const TableLinkButton: React.FC<TableLinkButtonProps> = ({ href, children }) => {
    return (
        <RouteLink
            href={href}
            className="text-grey border border-grey-light hover:bg-grey-lightest rounded-md text-sm px-2 py-1"
        >
            {children}
        </RouteLink>
    );
};
