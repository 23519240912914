import React from 'react';

import { LinkClipboardService, LinksService } from '../../api';
import ConfirmedIcon from '../../assets/icons/confirmed.svg';
import RejectedIcon from '../../assets/icons/rejected.svg';
import SniplyIcon from '../../assets/icons/sniply-logo.svg';
import { FormStepLayout } from '../../components/FormStepLayout';
import { FormTextInput } from '../../components/FormTextInput';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { VerticalSpacer } from '../../components/VerticalSpacer';
import { navigateTo } from '../../Routing';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from '../../utils/is-valid-url';
import { WorkspaceContext } from '../../WorkspaceContextProvider';
import { ImageWithFallback } from '../../components/ImageWithFallback';
import { UserContext } from '../../UserContextProvider';
import { SnipUrls } from '../../data-transformations/create-snip-urls-data';
import { ButtonMain } from '../../components/ButtonMain';
import { SnipsListItem, createSnipData } from '../../data-transformations/create-snip-data';

export interface ContentDisplayCardItem {
    readonly titleText: string;
    readonly imageUrl: string;
    readonly additionalText: string;
    readonly faviconUrl: string;
    readonly source: string;
    readonly author: string;
}

interface ContentDisplayCardProps {
    readonly contentItem: ContentDisplayCardItem;
}

export const ContentDisplayCard: React.FC<ContentDisplayCardProps> = ({
    contentItem: { titleText, imageUrl, additionalText, faviconUrl, source, author }
}) =>
    titleText && (
        <div className="p-3 border border-grey-lighter bg-white text-grey">
            <div className="w-full min-w-0">
                <p className="font-semibold text-sm truncate">{titleText}</p>
                <div className="mt-2 flex">
                    {imageUrl && <img src={imageUrl} alt="image" className="mr-2 w-16" />}
                    {additionalText && <p className="text-xs line-clamp-3">{additionalText}</p>}
                </div>
                {source && (
                    <div className="mt-3 flex items-center truncate">
                        <ImageWithFallback
                            src={faviconUrl}
                            alt="favicon"
                            fallbackSrc={SniplyIcon}
                            className="h-4 w-4"
                        />{' '}
                        <p className="ml-2 text-xs">{source}</p>
                        {author && source && (
                            <>
                                <span className="mx-2 text-grey-light">|</span>
                                <p className="text-xs text-grey-light">{author}</p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

interface SnipCreateSelectContentProps {
    readonly originalContentUrl: string;
    readonly setOriginalContentUrl: (externalUrl: string) => void;
    readonly sniplyContentUrl: string;
    readonly snipUrls: SnipUrls;
    readonly setHasJourneyBeenStarted: (value: boolean) => void;
    readonly hasSnippabilityStatusBeenChecked: boolean;
    readonly setHasSnippabilityStatusBeenChecked: (state: boolean) => void;
    readonly checkingSnippability: boolean;
    readonly showContentInfoCard: boolean;
    readonly setShowContentInfoCard: (state: boolean) => void;
    readonly clipboardItemId: string | undefined;
    readonly contentItemInformation: ContentDisplayCardItem;
    readonly setInitiallyCreatedSnip: (snip: SnipsListItem) => void;
}

export const SnipCreateSelectContent: React.FC<SnipCreateSelectContentProps> = ({
    originalContentUrl,
    setOriginalContentUrl,
    sniplyContentUrl,
    snipUrls,
    setHasJourneyBeenStarted,
    hasSnippabilityStatusBeenChecked,
    setHasSnippabilityStatusBeenChecked,
    checkingSnippability,
    showContentInfoCard,
    setShowContentInfoCard,
    clipboardItemId,
    contentItemInformation,
    setInitiallyCreatedSnip
}) => {
    const [showNoValidUrlValidationMessage, setShowNoValidUrlValidationMessage] =
        React.useState(false);

    const [showUrlNotSnippableValidationMessage, setShowUrlNotSnippableValidationMessage] =
        React.useState(false);

    const [isCreatingSnip, setIsCreatingSnip] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { userPlan, hasUsedFirstFreeSnip, setHasUsedFirstFreeSnip } =
        React.useContext(UserContext);

    const handleCreateSnipWithoutCta = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (checkingSnippability) {
                // Prevent keyboard "enter" until snippability is loaded.
                return;
            }

            if (currentWorkspace) {
                if (!isValidUrlWithProtocol(ensureUrlHasProtocol(originalContentUrl))) {
                    setShowNoValidUrlValidationMessage(true);
                } else {
                    setIsCreatingSnip(true);
                    LinksService.linksCreate({
                        requestBody: {
                            brand: currentWorkspace.id,
                            url: snipUrls.raw,
                            cta_ids: []
                        }
                    })
                        .then((snipResult) => {
                            console.log('LinksService.linksCreate()', snipResult);

                            setIsCreatingSnip(false);
                            setHasJourneyBeenStarted(true);
                            setInitiallyCreatedSnip(createSnipData(snipResult));
                            setTimeout(() => navigateTo(`/snip-create/created`), 1);

                            userPlan === 'Free' &&
                                !hasUsedFirstFreeSnip &&
                                setHasUsedFirstFreeSnip(true);

                            if (clipboardItemId) {
                                LinkClipboardService.linkClipboardDestroy({
                                    id: clipboardItemId
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(
                                'Error creating a new Snip via LinksService.linksCreate()',
                                error
                            );
                            setIsCreatingSnip(false);
                        });
                }
            }
        },
        [
            currentWorkspace,
            originalContentUrl,
            setHasJourneyBeenStarted,
            clipboardItemId,
            hasUsedFirstFreeSnip,
            setHasUsedFirstFreeSnip,
            userPlan,
            snipUrls,
            setInitiallyCreatedSnip,
            checkingSnippability
        ]
    );

    React.useEffect(() => {
        // Remove validation errors when touching url input field
        if (showNoValidUrlValidationMessage) {
            setShowNoValidUrlValidationMessage(false);
        }

        if (showUrlNotSnippableValidationMessage) {
            setShowUrlNotSnippableValidationMessage(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalContentUrl, sniplyContentUrl]);

    return (
        <FormStepLayout
            title={isCreatingSnip ? '' : 'Select Content'}
            step="1-of-4"
            onSubmit={(event) => handleCreateSnipWithoutCta(event)}
            buttons={
                sniplyContentUrl && (
                    <div className="flex">
                        <ButtonMain type="submit" width="full" disabled={checkingSnippability}>
                            Continue
                        </ButtonMain>
                    </div>
                )
            }
        >
            {isCreatingSnip ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <div className="mt-2">
                    <div className="flex items-center">
                        <FormTextInput
                            id="content-url-input"
                            labelText="Which Content / URL do I want to share?"
                            placeholder="www.example.com/some-article"
                            value={originalContentUrl}
                            onChange={(event) => {
                                setOriginalContentUrl(event.target.value);
                                setHasSnippabilityStatusBeenChecked(false);
                                showContentInfoCard && setShowContentInfoCard(false);
                            }}
                            righHandComponent={
                                checkingSnippability ? (
                                    <div className="flex items-center justify-center">
                                        <LoadingSpinner size={5} />
                                    </div>
                                ) : isValidUrlWithProtocol(
                                      ensureUrlHasProtocol(originalContentUrl)
                                  ) && hasSnippabilityStatusBeenChecked ? (
                                    <img src={ConfirmedIcon} className="h-4 w-4" />
                                ) : isValidUrlWithProtocol(
                                      ensureUrlHasProtocol(originalContentUrl)
                                  ) && !hasSnippabilityStatusBeenChecked ? (
                                    <img src={RejectedIcon} className="h-4 w-4" />
                                ) : null
                            }
                            autoFocus
                        />
                    </div>
                    <div className="mt-4">
                        {showContentInfoCard && contentItemInformation && !checkingSnippability && (
                            <div className="">
                                <ContentDisplayCard contentItem={contentItemInformation} />
                            </div>
                        )}
                        {showContentInfoCard &&
                            !checkingSnippability &&
                            !snipUrls.dynamic &&
                            !snipUrls.static && (
                                <div className="mt-2 p-4 bg-orange/10">
                                    <p className="text-sm">
                                        <span className="text-orange-dark">
                                            {`It will not be possible to add a Call-To-Action to this
                                        particular URL unless you use the 'Summary' feature. You can of course continue and create a re-direct.`}
                                        </span>
                                    </p>
                                </div>
                            )}
                        <VerticalSpacer heightValue={2} />
                        {showNoValidUrlValidationMessage && (
                            <>
                                <VerticalSpacer heightValue={2} />
                                <p className="h-3 p-1 text-xs text-red">
                                    {`Please provide a valid URL.`}
                                </p>
                            </>
                        )}
                        {showUrlNotSnippableValidationMessage && (
                            <>
                                <VerticalSpacer heightValue={2} />
                                <p className="h-3 p-1 text-xs text-red">
                                    {`Please provide a snippable content URL or use Summary Mode to continue.`}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </FormStepLayout>
    );
};
