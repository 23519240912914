import { getNumberOfDays } from '../utils/get-number-of-days';
import { Link } from '../api';
import { createCtaData, CtasListItem } from './create-cta-data';
import { createOriginalUrl } from '../utils/create-original-url';

export type SnipType = 'cta' | 'summary' | 'redirect';
export interface SnipsListItem
    extends Record<string, string | number | boolean | string[] | CtasListItem[]> {
    readonly id: string;
    readonly type: SnipType;
    readonly snipName: string;
    readonly snipUrl: string;
    readonly slug: string;
    readonly customDomainId?: string;
    readonly snipDomain: string;
    readonly faviconUrl: string;
    readonly contentTitle: string;
    readonly originalContentUrl: string;
    readonly sniplyContentUrl: string;
    readonly source?: string;
    readonly campaignId: string;
    readonly ctaIds: string[];
    readonly ctas: CtasListItem[];
    readonly numberOfClicks: number;
    readonly numberOfConversions: number;
    readonly conversionRate: number;
    readonly createdSince: number;
    readonly archived: boolean;
}

export function getBaseDomainFromUrl(url: string): string {
    return url.split('//')[1].split('/')[0];
}

export function getSnipType(result: Link): SnipType {
    if (result.url.startsWith('https://summary.snip.ly/summary?url=')) {
        return 'summary';
    }
    if (result.cta_ids.length > 0) {
        return 'cta';
    }
    return 'redirect';
}

export function createSnipData(result: Link): SnipsListItem {
    return {
        id: result.id,
        type: getSnipType(result),
        snipName: result.href.startsWith('https://')
            ? result.href.slice(8, result.href.length)
            : result.href.startsWith('http://')
            ? result.href.slice(7, result.href.length)
            : result.href,
        snipUrl: result.href,
        snipDomain: getBaseDomainFromUrl(result.href),
        slug: result.slug,
        customDomainId: result.custom_domain,
        faviconUrl: result.favicon,
        contentTitle: result.page_title,
        originalContentUrl: createOriginalUrl(result.url),
        sniplyContentUrl: result.url,
        source: result.og_data?.og_source,
        campaignId: result.label ?? '',
        ctaIds: result.cta_ids ?? [],
        ctas: result.cta_objects.map((cta) => createCtaData(cta)) ?? [],
        numberOfClicks: result.cache_view_count,
        numberOfConversions: result.cache_click_count,
        conversionRate: Math.round(
            result.cache_view_count === 0 && result.cache_click_count === 0
                ? 0
                : (result.cache_click_count / result.cache_view_count) * 100
        ),
        createdSince: getNumberOfDays(new Date(result.created_date), new Date(Date.now())),
        archived: result.hide_on_dashboard ?? false
    };
}
