/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Label } from '../models/Label';
import type { LabelRequest } from '../models/LabelRequest';
import type { PaginatedLabelList } from '../models/PaginatedLabelList';
import type { PatchedLabelRequest } from '../models/PatchedLabelRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LabelsService {

    /**
     * Labels are used to categorize your objects(CTA, Link, etc) within a workspace.
     *
     * Only one label can be assigned to an object.
     * @returns PaginatedLabelList
     * @throws ApiError
     */
    public static labelsList({
        brand,
        ordering,
        page,
        pageSize,
        search,
    }: {
        brand?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLabelList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/labels/',
            query: {
                'brand': brand,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * Labels are used to categorize your objects(CTA, Link, etc) within a workspace.
     *
     * Only one label can be assigned to an object.
     * @returns Label
     * @throws ApiError
     */
    public static labelsCreate({
        requestBody,
    }: {
        requestBody: LabelRequest,
    }): CancelablePromise<Label> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/labels/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Label
     * @throws ApiError
     */
    public static labelsRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Label> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/labels/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Label
     * @throws ApiError
     */
    public static labelsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: LabelRequest,
    }): CancelablePromise<Label> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/labels/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Label
     * @throws ApiError
     */
    public static labelsPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedLabelRequest,
    }): CancelablePromise<Label> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/labels/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static labelsDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/labels/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
