import React from 'react';
import BellIcon from './assets/icons/bell.svg';
import { NavigableViews, SideNavigationVisibilityContext } from './App';
import { AppLayout } from './components/AppLayout';
import { MainNavigationGroupList } from './components/MainNavigationGroup';
import { SideNavigationLayout } from './components/Navigation/SideNavigationLayout';
import { SideNavigationLogo } from './components/Navigation/SideNavigationLogo';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { User } from './User';
import { RouteLink } from './Routing';
import { CollapseExpandButton } from './components/CollapseExpandButton';
import { FeatureFlagsContext } from './FeatureFlagsContextProvider';
import { ButtonMainActions } from './components/ButtonMainActions';
import { ButtonMain } from './components/ButtonMain';

const VIEWS_TOP: Partial<NavigableViews> = {
    'dashboard': 'Dashboard',
    'snips': 'Snips',
    'discover-content': 'Discover Content',
    'clipboard': 'Clipboard',
    'reporting': 'Reporting'
};

const VIEWS_BOTTOM: Partial<NavigableViews> = {
    customization: 'Customization',
    integrations: 'Integrations',
    support: 'Support'
};

interface WithNavigationProps {
    readonly children: React.ReactNode;
    readonly isVerticallyScrollable?: boolean;
}

export const WithNavigation: React.FC<WithNavigationProps> = ({
    children,
    isVerticallyScrollable = true
}) => {
    const { isSideNavigationExpanded, setIsSideNavigationExpanded } = React.useContext(
        SideNavigationVisibilityContext
    );

    const { enablePagesFeature } = React.useContext(FeatureFlagsContext);

    const isMobile = useIsMobileScreenSize();
    const [isMobileNavigationExpanded, setIsMobileNavigationExpanded] = React.useState(false);
    const isSlimView = isMobile ? false : !isSideNavigationExpanded;

    return (
        <AppLayout
            isMobile={isMobile}
            navigation={
                <SideNavigationLayout
                    slim={isSlimView}
                    logo={
                        <RouteLink href="/">
                            <SideNavigationLogo slim={isSlimView} />
                        </RouteLink>
                    }
                    collapseExpandButton={
                        !isMobile && (
                            <CollapseExpandButton
                                arrowDirection={!isSideNavigationExpanded ? 'right' : 'left'}
                                onClick={() =>
                                    setIsSideNavigationExpanded(
                                        isSideNavigationExpanded ? false : true
                                    )
                                }
                            />
                        )
                    }
                    navigationGroupListTop={
                        <MainNavigationGroupList
                            navigableViews={
                                !enablePagesFeature ? VIEWS_TOP : { ...VIEWS_TOP, page: 'Page' }
                            }
                            slim={isSlimView}
                            setIsMobileNavigationExpanded={setIsMobileNavigationExpanded}
                        />
                    }
                    createLinkButton={
                        !isSideNavigationExpanded && !isMobileNavigationExpanded ? (
                            <RouteLink href="/snip-create/content-select">
                                <div className="w-full">
                                    <ButtonMain width="full">+</ButtonMain>
                                </div>
                            </RouteLink>
                        ) : (
                            <ButtonMainActions />
                        )
                    }
                    navigationGroupListBottom={
                        <MainNavigationGroupList
                            navigableViews={VIEWS_BOTTOM}
                            slim={isSlimView}
                            setIsMobileNavigationExpanded={setIsMobileNavigationExpanded}
                        />
                    }
                    beamerLink={
                        <div
                            id="beamer-widget-button"
                            className="h-8 my-3 semiTall:my-4 px-5 flex items-center text-grey-light"
                        >
                            <div className="w-1 h-full rounded-r-lg bg-transparent" />
                            <img src={BellIcon} alt="bell icon" className="w-6 h-6" />
                            {!isSlimView && <span className="ml-2">{`Product Updates`}</span>}
                        </div>
                    }
                    userSettings={<User />}
                />
            }
            content={children}
            isMobileNavigationExpanded={isMobileNavigationExpanded}
            setIsMobileNavigationExpanded={setIsMobileNavigationExpanded}
            isVerticallyScrollable={isVerticallyScrollable}
        />
    );
};
