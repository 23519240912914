import React from 'react';
import { PlansService, TeamService, UserService } from './api';
import { AuthContext } from './auth';
import { LoadingSpinner } from './components/LoadingSpinner';
import { createUser } from './data-transformations/create-user';

export type UserPlan = 'Basic' | 'Pro' | 'Business' | 'Enterprise' | 'Free';
export type UserPlanCycle = 'annual' | 'monthly';

interface UserContextValue {
    readonly userId: string;
    readonly userName: string;
    readonly userEmail: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly userPlan: UserPlan;
    readonly setUserPlan: (plan: UserPlan) => void;
    readonly stripeId: string;
    readonly userPlanCycle: UserPlanCycle;
    readonly isV3Plan: boolean;
    readonly isAppSumoPlan: boolean;
    readonly appSumoTopSnipSlug: string;
    readonly hasUsedFirstFreeSnip: boolean;
    readonly setHasUsedFirstFreeSnip: (value: boolean) => void;
    readonly isAppSumoMonthlyClicksLimitReached: boolean;
    readonly planRenewalDate?: string;
    readonly planCancelDate: string | null;
    readonly isUserIsOnTrialPeriod: boolean;
    readonly daysUntilTrialEnds?: number;
    readonly userNeedsOnboarding: boolean;
    readonly setUserNeedsOnboarding: (value: boolean) => void;
    readonly currentSnips: number;
    readonly maxSnips: number;
    readonly currentClicks: number;
    readonly appSumoCurrentMonthlyClicksForTopSnip: number;
    readonly maxClicks: number;
    readonly currentCtas: number;
    readonly maxCtas: number;
    readonly currentMonthlyCtaImpressions: number;
    readonly maxMonthlyCtaImpressions: number;
    readonly currentTeammembers: number;
    readonly maxTeammembers: number;
    readonly currentWorkspaces: number;
    readonly maxWorkspaces: number;
    readonly userProfilePictureUrl: string;
    readonly canUseImageAdCta: boolean;
    readonly canUseABTesting: boolean;
    readonly canHideSniplyLogo: boolean;
    readonly canUseCtaColors: boolean;
    readonly canUseCtaPositioning: boolean;
    readonly minimumImageAdPlan: UserPlan;
    readonly minimumABTestingPlan: UserPlan;
    readonly minimumHideSniplyLogoPlan: UserPlan;
    readonly minimumUseCtaColorsPlan: UserPlan;
    readonly minimumUseCtaPositioningPlan: UserPlan;
}

export interface User {
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
}

export const UserContext = React.createContext<UserContextValue>(undefined);

interface UserContextProviderProps {
    readonly children: React.ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
    const [user, setUser] = React.useState<User>();
    const [userNeedsOnboarding, setUserNeedsOnboarding] = React.useState<boolean>(false);

    const [userPlan, setUserPlan] = React.useState<UserPlan>();
    const [stripeId, setStripeId] = React.useState('');
    const [userPlanCycle, setUserPlanCycle] = React.useState<UserPlanCycle>();

    const [isV3Plan, setIsV3Plan] = React.useState<boolean>();

    const [isAppSumoPlan, setIsAppSumoPlan] = React.useState<boolean>();
    const [appSumoTopSnipSlug, setAppSumoTopSnipSlug] = React.useState('');
    const [isAppSumoMonthlyClicksLimitReached, setIsAppSumoMonthlyClicksLimitReached] =
        React.useState<boolean>();

    const [hasUsedFirstFreeSnip, setHasUsedFirstFreeSnip] = React.useState(false);

    const [planRenewalDate, setPlanRenewalDate] = React.useState<string>();
    const [planCancelDate, setPlanCancelDate] = React.useState<string | null>(null);

    const [isUserIsOnTrialPeriod, setIsUserIsOnTrialPeriod] = React.useState(false);
    const [daysUntilTrialEnds, setDaysUntilTrialEnds] = React.useState<number>();

    const [currentSnips, setCurrentSnips] = React.useState<number>();
    const [maxSnips, setMaxSnips] = React.useState<number>();

    const [currentClicks, setCurrentClicks] = React.useState<number>();
    const [appSumoCurrentMonthlyClicksForTopSnip, setAppSumoCurrentMonthlyClicksForTopSnip] =
        React.useState<number>();

    const [maxClicks, setMaxClicks] = React.useState<number>();

    const [currentCtas, setCurrentCtas] = React.useState<number>();
    const [maxCtas, setMaxCtas] = React.useState<number>();

    const [currentMonthlyCtaImpressions, setCurrentMonthlyCtaImpressions] =
        React.useState<number>();
    const [maxMonthlyCtaImpressions, setMaxMonthlyCtaImpressions] = React.useState<number>();

    const [currentTeammembers, setCurrentTeammembers] = React.useState<number>();
    const [maxTeammembers, setMaxTeammembers] = React.useState<number>();

    const [currentWorkspaces, setCurrentWorkspaces] = React.useState<number>();
    const [maxWorkspaces, setMaxWorkspaces] = React.useState<number>();

    const [userProfilePictureUrl, setUserProfilePictureUrl] = React.useState('');

    const [canUseImageAdCta, setCanUseImageAdCta] = React.useState<boolean>();
    const [minimumImageAdPlan, setMinimumImageAdPlan] = React.useState<UserPlan>();

    const [canUseABTesting, setCanUseABTesting] = React.useState<boolean>();
    const [minimumABTestingPlan, setMinimumABTestingPlan] = React.useState<UserPlan>();

    const [canHideSniplyLogo, setCanHideSniplyLogo] = React.useState<boolean>();
    const [minimumHideSniplyLogoPlan, setMinimumHideSniplyLogoPlan] = React.useState<UserPlan>();

    const [canUseCtaColors, setCanUseCtaColors] = React.useState<boolean>();
    const [minimumUseCtaColorsPlan, setMinimumUseCtaColorsPlan] = React.useState<UserPlan>();

    const [canUseCtaPositioning, setCanUseCtaPositioning] = React.useState<boolean>();
    const [minimumUseCtaPositioningPlan, setMinimumUseCtaPositioningPlan] =
        React.useState<UserPlan>();

    const { isOpenAPITokenSet, logout, logoutRedirect } = React.useContext(AuthContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet) {
            UserService.userRetrieve()
                .then((result) => {
                    console.log(' UserService.userRetrieve()', result);
                    setUser(createUser(result));

                    setUserNeedsOnboarding(!result.profile.onboarding_tips_presented);
                    setUserProfilePictureUrl(result.profile.photo_url);

                    if (!result.profile.onboarding_tips_presented) {
                        UserService.userProfilePartialUpdate({
                            requestBody: { onboarding_tips_presented: true }
                        }).catch((error) =>
                            console.error('Error updating User Profile onboarding status.', error)
                        );
                    }
                })
                .catch((error) => {
                    console.log('Error fetching User Data: ', error);

                    // In case of errors fetching users assume that logging in again
                    // is the best course of action
                    logout();
                    window.location.replace(logoutRedirect);
                });

            TeamService.teamRetrieve()
                .then((result) => {
                    console.log('TeamService.teamRetrieve()', result);

                    setStripeId(result.stripe_id);

                    setCanUseImageAdCta(result.can_use_image_cta);
                    setCanUseABTesting(result.can_use_abtesting);

                    setCanHideSniplyLogo(result.can_use_hide_sniply_logo);

                    setCanUseCtaColors(result.can_use_colors);
                    setCanUseCtaPositioning(result.can_use_positioning);
                })
                .catch((error) => {
                    console.log('Error fetching Team Data: ', error);
                });

            TeamService.teamLimitsAndBillingRetrieve()
                .then((result) => {
                    console.log('TeamService.teamLimitsAndBillingRetrieve()', result);

                    const isAppSumo = result.plan_name.includes('Tier');

                    setUserPlan(result.plan_name as UserPlan);
                    setUserPlanCycle(result.billing_cycle as UserPlanCycle);
                    setIsV3Plan(result.is_v3_plan);
                    setIsAppSumoPlan(isAppSumo);
                    setHasUsedFirstFreeSnip(result.first_link_created);

                    setPlanRenewalDate(
                        !result.upcoming_invoice_date || result.plan_name === 'Free'
                            ? null
                            : new Date(result.upcoming_invoice_date)?.toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric'
                              })
                    );

                    result.subscription_cancel_at &&
                        setPlanCancelDate(
                            new Date(result.subscription_cancel_at)?.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })
                        );

                    setIsUserIsOnTrialPeriod(result.is_on_trial);
                    setDaysUntilTrialEnds(result.days_before_next_charge);

                    // V2 backwards compatible quotas -- start
                    setCurrentSnips(result.links_used_this_month || 0);
                    setMaxSnips(result.link_limit || 0);

                    setCurrentClicks(result.clicks_used_this_month || 0);
                    setMaxClicks(result.click_limit || 0);

                    setAppSumoTopSnipSlug(result.appsumo_limits?.top_link_this_month);

                    setAppSumoCurrentMonthlyClicksForTopSnip(
                        result.appsumo_limits?.top_link_click_count_this_month
                    );

                    setIsAppSumoMonthlyClicksLimitReached(
                        result.appsumo_limits?.appsumo_limit_reached
                    );

                    // V2 backwards compatible quotas -- end

                    // V3 quotas -- start
                    setCurrentCtas(result.number_of_used_ctas || 0);
                    setMaxCtas(isAppSumoPlan ? 999999999 : result.cta_limit || 0);

                    setCurrentMonthlyCtaImpressions(
                        result.non_hidden_cta_impressions_this_month || 0
                    );
                    setMaxMonthlyCtaImpressions(result.max_monthly_non_hidden_cta_impressions || 0);
                    // V3 quotas -- end

                    setCurrentTeammembers(result.number_of_team_members || 0);
                    setMaxTeammembers(result.team_member_limit || 0);

                    setCurrentWorkspaces(result.current_number_of_profiles || 0);
                    setMaxWorkspaces(result.profile_limit || 0);
                })
                .catch((error) => {
                    console.log('Error fetching Team Limits and Billing Data: ', error);
                });

            PlansService.plansRetrieve().then((result) => {
                console.log('PlansService.plansRetrieve()', result);

                setMinimumImageAdPlan(
                    result.plans.month.find((plan) => plan.features.image_cta).nice_name as UserPlan
                );

                setMinimumABTestingPlan(
                    result.plans.month.find((plan) => plan.features.abtesting).nice_name as UserPlan
                );

                setMinimumHideSniplyLogoPlan(
                    result.plans.month.find((plan) => plan.features.hide_sniply_logo)
                        .nice_name as UserPlan
                );

                setMinimumUseCtaColorsPlan(
                    result.plans.month.find((plan) => plan.features.colors).nice_name as UserPlan
                );

                setMinimumUseCtaPositioningPlan(
                    result.plans.month.find((plan) => plan.features.positioning)
                        .nice_name as UserPlan
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenAPITokenSet, isAppSumoPlan]);

    if (!user) {
        return (
            <div className="flex w-full h-full items-center justify-center">
                <LoadingSpinner size={10} />
            </div>
        );
    }

    return (
        <UserContext.Provider
            value={{
                userId: user?.id,
                userName: user?.name,
                userEmail: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                userPlan,
                setUserPlan,
                stripeId,
                userPlanCycle,
                isV3Plan,
                isAppSumoPlan,
                appSumoTopSnipSlug,
                hasUsedFirstFreeSnip,
                setHasUsedFirstFreeSnip,
                isAppSumoMonthlyClicksLimitReached,
                planRenewalDate,
                planCancelDate,
                isUserIsOnTrialPeriod,
                daysUntilTrialEnds,
                userNeedsOnboarding,
                setUserNeedsOnboarding,
                currentSnips,
                maxSnips,
                currentClicks,
                appSumoCurrentMonthlyClicksForTopSnip,
                maxClicks,
                currentCtas,
                maxCtas,
                currentMonthlyCtaImpressions,
                maxMonthlyCtaImpressions,
                currentTeammembers,
                maxTeammembers,
                currentWorkspaces,
                maxWorkspaces,
                userProfilePictureUrl,
                canUseImageAdCta,
                minimumImageAdPlan,
                canUseABTesting,
                minimumABTestingPlan,
                canHideSniplyLogo,
                minimumHideSniplyLogoPlan,
                canUseCtaColors,
                minimumUseCtaColorsPlan,
                canUseCtaPositioning,
                minimumUseCtaPositioningPlan
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
