/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { OpenAPI } from '../api';
import {
    errorMessageForExpiredRefreshToken,
    fetchTokens,
    fetchWithRefreshToken,
    login,
    tokenExpired
} from './authentication';
import { useLocalStorage } from './Hooks';
import { IAuthContext, IAuthProvider } from './Types';
import { validateAuthConfig } from './validateAuthConfig';

type TokenType = 'Bearer';

interface AuthResponse {
    readonly access_token: string;
    readonly expires_in: number;
    readonly token_type: TokenType;
    readonly scope: string;
    readonly refresh_token: string;
}

export const AuthContext = React.createContext<IAuthContext>({
    token: '',
    logout: undefined,
    logoutRedirect: '',
    error: null,
    isOpenAPITokenSet: false
});

export const AuthProvider = ({ authConfig, children }: IAuthProvider) => {
    const [refreshToken, setRefreshToken] = useLocalStorage<string | null>(
        'ROCP_refreshToken',
        null
    );

    const [token, setToken] = useLocalStorage<string | null>('ROCP_token', null);
    const [tokenExpirationTime, setTokenExpirationTime] = React.useState<number>();

    const [loginInProgress, setLoginInProgress] = useLocalStorage<boolean>(
        'ROCP_loginInProgress',
        false
    );

    const [error, setError] = React.useState<string | null>(null);
    const [isOpenAPITokenSet, setIsOpenAPITokenSet] = React.useState(false);

    let interval: any;

    validateAuthConfig(authConfig);

    function logout() {
        setRefreshToken(null);
        setToken(null);
        setLoginInProgress(false);
        setTokenExpirationTime(undefined);
    }

    function handleTokenResponse(response: AuthResponse) {
        setRefreshToken(response.refresh_token);
        setToken(response.access_token);
        setLoginInProgress(false);
        setTokenExpirationTime(response.expires_in);
    }

    function refreshAccessToken() {
        if (refreshToken) {
            if (!token) {
                // No token found to refresh
                console.error('Tried to refresh token, but no original token was found.');
                setError('Bad authorization state. Refreshing the page might solve the issue.');

                // Logout to let users log back in cleanly
                logout();
            } else if (tokenExpired(tokenExpirationTime)) {
                // The client has an expired token. Will try to get a new one with the refreshToken
                fetchWithRefreshToken(authConfig, refreshToken)
                    .then((result: any) => handleTokenResponse(result))
                    .catch((fetchWithRefreshTokenError: string) => {
                        setError(fetchWithRefreshTokenError);
                        if (errorMessageForExpiredRefreshToken(fetchWithRefreshTokenError)) {
                            logout();
                            login(authConfig);
                        }
                    });
            }
        } else {
            // No refresh_token
            console.error('Tried to refresh token without a refresh token.');
            setError('Bad authorization state. Refreshing the page might solve the issue.');
        }
    }

    // Register the 'check for soon expiring access token' interval (Every minute)
    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        interval = setInterval(() => refreshAccessToken(), 60000);
        return () => clearInterval(interval);
    }, [token]); // This token dependency removes the old, and registers a new Interval when a new token is fetched.

    // Runs once on page load
    React.useEffect(() => {
        if (loginInProgress) {
            // The client has been redirected back from the Auth endpoint with an auth code
            const urlParams = new URLSearchParams(window.location.search);
            if (!urlParams.get('code')) {
                // This should not happen. There should be a 'code' parameter in the url by now..."
                const error_description =
                    urlParams.get('error_description') ||
                    'Bad authorization state. Refreshing the page might solve the issue.';
                console.error(error_description);
                setError(error_description);
                logout();
            } else {
                // Request token from auth server with the auth code
                fetchTokens(authConfig)
                    .then((tokens: any) => {
                        handleTokenResponse(tokens);
                        history.replaceState(null, '', location.pathname); // Clear ugly url params
                    })
                    .catch((fetchTokensError: string) => {
                        setError(fetchTokensError);
                    });
            }
        } else if (!token) {
            // First page visit
            setLoginInProgress(true);
            login(authConfig);
        } else {
            refreshAccessToken(); // Check if token should be updated
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set the current token as the OpenApi.TOKEN to be used for all BE calls.
    React.useEffect(() => {
        if (token) {
            OpenAPI.TOKEN = token;
            setIsOpenAPITokenSet(true);
        }
    }, [token]);

    return (
        <AuthContext.Provider
            value={{
                token,
                logout,
                logoutRedirect: authConfig.logoutRedirect,
                error,
                isOpenAPITokenSet
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
