export function createOriginalUrl(url: string) {
    if (url.startsWith('https://summary.snip.ly/summary?url=')) {
        return url.slice(36);
    }
    if (url.startsWith('https://summary.sniply.site/summary?url=')) {
        return url.slice(40);
    }
    if (url.startsWith('https://go.sniply.app/?url=')) {
        return url.slice(27);
    }
    if (url.startsWith('https://go.sniply.page/?url=')) {
        return url.slice(28);
    }
    if (url.startsWith('https://go.sniply.site/?url=')) {
        return url.slice(28);
    }
    if (url.startsWith('https://go.sniply.app/web/?url=')) {
        return url.slice(33);
    }
    if (url.startsWith('https://go.sniply.page/web/?url=')) {
        return url.slice(34);
    }
    if (url.startsWith('https://go.sniply.site/web/?url=')) {
        return url.slice(34);
    }
    if (url.startsWith('https://go.sniply.app/static/?url=')) {
        return url.slice(34);
    }
    if (url.startsWith('https://go.sniply.page/static/?url=')) {
        return url.slice(35);
    }
    if (url.startsWith('https://go.sniply.site/static/?url=')) {
        return url.slice(35);
    }
    if (url.startsWith('https://stagingproxy.sniply.site/?url=')) {
        return url.slice(38);
    }
    if (url.startsWith('https://stagingproxy.sniply.site/static/?url=')) {
        return url.slice(45);
    }
    if (url.startsWith('https://cdn.ampproject.org/c/s/')) {
        return 'https://' + url.slice(31);
    }
    return url;
}
