/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollaborationInvitation } from '../models/CollaborationInvitation';
import type { CollaborationInvitationRequest } from '../models/CollaborationInvitationRequest';
import type { PatchedCollaborationInvitationRequest } from '../models/PatchedCollaborationInvitationRequest';
import type { PatchedTeamMemberProfileRequest } from '../models/PatchedTeamMemberProfileRequest';
import type { PatchedTeamRequest } from '../models/PatchedTeamRequest';
import type { Team } from '../models/Team';
import type { TeamLimitsAndBilling } from '../models/TeamLimitsAndBilling';
import type { TeamMemberList } from '../models/TeamMemberList';
import type { TeamMemberProfile } from '../models/TeamMemberProfile';
import type { TeamRequest } from '../models/TeamRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamService {

    /**
     * @returns Team
     * @throws ApiError
     */
    public static teamRetrieve(): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/team/',
        });
    }

    /**
     * @returns Team
     * @throws ApiError
     */
    public static teamUpdate({
        requestBody,
    }: {
        requestBody?: TeamRequest,
    }): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/team/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Team
     * @throws ApiError
     */
    public static teamPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedTeamRequest,
    }): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/team/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TeamLimitsAndBilling
     * @throws ApiError
     */
    public static teamLimitsAndBillingRetrieve(): CancelablePromise<TeamLimitsAndBilling> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/team/limits-and-billing',
        });
    }

    /**
     * Update a user within a team
     * @returns TeamMemberProfile
     * @throws ApiError
     */
    public static teamMemberPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedTeamMemberProfileRequest,
    }): CancelablePromise<TeamMemberProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/team/member/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a user within a team
     * @returns void
     * @throws ApiError
     */
    public static teamMemberDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/team/member/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TeamMemberList
     * @throws ApiError
     */
    public static teamMembersRetrieve(): CancelablePromise<TeamMemberList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/team/members/',
        });
    }

    /**
     * @returns CollaborationInvitation
     * @throws ApiError
     */
    public static teamMembersInviteCreate({
        requestBody,
    }: {
        requestBody: CollaborationInvitationRequest,
    }): CancelablePromise<CollaborationInvitation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/team/members/invite/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CollaborationInvitation
     * @throws ApiError
     */
    public static teamMembersInvitePartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedCollaborationInvitationRequest,
    }): CancelablePromise<CollaborationInvitation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/team/members/invite/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static teamMembersInviteDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/team/members/invite/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CollaborationInvitation
     * @throws ApiError
     */
    public static teamMembersInviteResendPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedCollaborationInvitationRequest,
    }): CancelablePromise<CollaborationInvitation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/team/members/invite/{id}/resend/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
