import React from 'react';
import { CtasService } from './api';
import { EndlessScrollContainer } from './components/EndlessScrollContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchInput } from './components/SearchInput';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createCtaData, CtasListItem } from './data-transformations/create-cta-data';
import { createCtasList } from './data-transformations/create-ctas-list';
import { useWindowSize } from './hooks/use-window-size';
import { navigateTo } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';

const NUMBER_OF_CTAS_PER_LOAD = 10;

interface CtaListItemProps {
    readonly cta: CtasListItem;
    readonly onSelectCta: (cta: CtasListItem) => void;
    readonly active?: boolean;
}

export const CtaListItem: React.FC<CtaListItemProps> = ({ cta, onSelectCta, active = false }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`flex h-14 w-full items-center justify-between ${
                active ? 'border border-grey-light' : 'border border-grey-lighter'
            } rounded-md p-2`}
            onClick={(event) => {
                event.preventDefault();
                onSelectCta(cta);
            }}
        >
            <div className="flex items-center min-w-0">
                <div className="w-12">
                    <div
                        style={{ background: cta.backgroundColor }}
                        className="flex items-center justify-center h-12 w-12 border border-grey-lighter"
                    >
                        <div style={{ background: cta.buttonColor }} className="h-6 w-6" />
                    </div>
                </div>
                <div className="mx-4 min-w-0">
                    <p className="flex text-left text-sm line-clamp-1">{cta.name}</p>
                    <div className="flex items-center mt-1 min-w-0">
                        <div className="w-18">
                            <div className="w-18 bg-grey-lighter rounded-md text-xs">
                                {cta.type?.toUpperCase()}
                            </div>
                        </div>
                        {cta.urlToBePromoted && (
                            <span className="ml-4 text-xs truncate">{cta.urlToBePromoted}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-6 pr-3">
                <span
                    style={{
                        boxShadow: `0px 0px 0px 3px ${
                            active ? '#2681DB' : isHovered ? '#E2E8EE' : 'white'
                        } inset`
                    }}
                    className={`w-4 h-4 inline-block mr-1 rounded-full border bg-white ${
                        active ? 'border-primary' : ' border-grey-lighter'
                    }`}
                ></span>
            </div>
        </button>
    );
};

interface CtaSelectionProps {
    readonly initiallySelectedCta?: CtasListItem;
    readonly selectedCtaId: string;
    readonly onSelectCta: (selectedCta: CtasListItem) => void;
    readonly useAsTemplateButton?: JSX.Element;
    readonly createNewButton?: JSX.Element;
    readonly hideThisCtaId?: string;
}

export const CtaSelection: React.FC<CtaSelectionProps> = ({
    initiallySelectedCta,
    selectedCtaId,
    onSelectCta,
    useAsTemplateButton,
    createNewButton,
    hideThisCtaId
}) => {
    const [ctasData, setCtasData] = React.useState<CtasListItem[]>([]);
    const [searchResultsData, setSearchResultsData] = React.useState<CtasListItem[]>([]);

    const [isLoadingInitialData, setIsLoadingInitialData] = React.useState(true);
    const [errorLoadingInitialData, setErrorLoadingInitialData] = React.useState(false);
    const [initialDataRetryCounter, setInitialDataRetryCounter] = React.useState(0);

    const [isLoadingSearchData, setIsLoadingSearchData] = React.useState(false);

    const [isLoadingMoreData, setIsLoadingMoreData] = React.useState(false);
    const [numberOfPagesLoaded, setNumberOfPagesLoaded] = React.useState(0);
    const [hasMorePages, setHasMorePages] = React.useState(true);

    const [searchTerm, setSearchTerm] = React.useState('');

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const { height } = useWindowSize();
    const isLowHeightScreen = height < 750;

    const handleLoadMoreCtas = React.useCallback(() => {
        if (!isLoadingMoreData && hasMorePages) {
            setIsLoadingMoreData(true);

            CtasService.ctasList({
                brand: currentWorkspace.id,
                search: searchTerm,
                page: numberOfPagesLoaded + 1,
                pageSize: NUMBER_OF_CTAS_PER_LOAD
            })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);

                    setNumberOfPagesLoaded(numberOfPagesLoaded + 1);
                    setCtasData([...ctasData, ...createCtasList(result)]);
                    setIsLoadingMoreData(false);
                })

                .catch((error) => {
                    console.error('Error fetching from CtasService.ctasList(): ', error);

                    if (error.body?.detail?.startsWith('Invalid page')) {
                        setHasMorePages(false);
                    }

                    setIsLoadingMoreData(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ctasData,
        setCtasData,
        isLoadingMoreData,
        numberOfPagesLoaded,
        setNumberOfPagesLoaded,
        currentWorkspace
    ]);

    // Intial fetch
    React.useEffect(() => {
        if (currentWorkspace && !ctasData?.length) {
            setIsLoadingInitialData(true);

            CtasService.ctasList({
                brand: currentWorkspace.id,
                search: searchTerm,
                page: 1,
                pageSize: NUMBER_OF_CTAS_PER_LOAD
            })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);

                    if (result.results.length === 0) {
                        navigateTo('/snip-create/cta-select/new');
                        return;
                    }

                    !selectedCtaId &&
                        result.results.length > 0 &&
                        onSelectCta(createCtaData(result.results[0]));

                    setCtasData(createCtasList(result));
                    setNumberOfPagesLoaded(1);
                    setIsLoadingInitialData(false);
                })
                .catch((error) => {
                    console.error('Error fetching from CtasService.ctasList(): ', error);

                    setErrorLoadingInitialData(true);
                    setIsLoadingInitialData(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWorkspace, initialDataRetryCounter]);

    const searchRequestIndex = React.useRef(0);

    // Re-fetch based on search term
    React.useEffect(() => {
        setHasMorePages(true);

        if (searchTerm && searchTerm.length > 2) {
            const currentSearchRequestIndex = searchRequestIndex.current + 1;

            searchRequestIndex.current = currentSearchRequestIndex;

            setIsLoadingSearchData(true);

            setTimeout(() => {
                if (currentSearchRequestIndex === searchRequestIndex.current) {
                    CtasService.ctasList({
                        brand: currentWorkspace.id,
                        search: searchTerm,
                        page: 1,
                        pageSize: NUMBER_OF_CTAS_PER_LOAD
                    })
                        .then((result) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log('CtasService.ctasList()', result);

                                setSearchResultsData(createCtasList(result));
                                setNumberOfPagesLoaded(1);
                                setIsLoadingSearchData(false);
                            }
                        })
                        .catch((error) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.error(
                                    'Error fetching from CtasService.ctasList(): ',
                                    error
                                );
                                setIsLoadingSearchData(false);
                            }
                        });
                }
            }, 1000);
        } else {
            setSearchResultsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const ctasDataWithInitiallySelectedFirst = initiallySelectedCta
        ? [initiallySelectedCta, ...ctasData.filter((cta) => cta.id !== initiallySelectedCta.id)]
        : ctasData;

    const ctasDataForDisplay = hideThisCtaId
        ? ctasDataWithInitiallySelectedFirst.filter((cta) => cta.id !== hideThisCtaId)
        : ctasDataWithInitiallySelectedFirst;

    const ctasDataWithSearchApplied =
        searchResultsData && searchTerm.length > 2 ? searchResultsData : ctasDataForDisplay;

    return isLoadingInitialData ? (
        <div
            className={`${
                isLowHeightScreen ? 'h-60' : 'h-76'
            } w-full flex items-center justify-center`}
        >
            <LoadingSpinner size={10} />
        </div>
    ) : errorLoadingInitialData ? (
        <div className={`${isLowHeightScreen ? 'h-60' : 'h-76'} w-full text-red text-sm`}>
            <p>
                We could not retrieve your Call-To-Actions at this time due to an unexpected error.
                Please try again, by refreshing your browser window.
            </p>
            <button
                className="underline font-semibold"
                onClick={(event) => {
                    event.preventDefault();
                    setInitialDataRetryCounter(initialDataRetryCounter + 1);
                }}
            >
                Retry Now
            </button>
        </div>
    ) : (
        <div>
            <div className="grid grid-cols-1 gap-2 xs:flex xs:items-start xs:justify-between">
                <h4>Select Call-To-Action</h4>
                <SearchInput
                    searchTermState={searchTerm}
                    setSearchTermState={setSearchTerm}
                    onClearSearch={() => setSearchTerm('')}
                    isCollapsible={false}
                    placeholderText="Search"
                    isRounded
                />
            </div>
            <VerticalSpacer heightValue={4} />
            <EndlessScrollContainer
                showLoadMoreAtAll={
                    !isLoadingInitialData &&
                    !isLoadingSearchData &&
                    ctasDataForDisplay.length >= NUMBER_OF_CTAS_PER_LOAD
                }
                onReachedScrollBottom={handleLoadMoreCtas}
                onLoadMoreClick={handleLoadMoreCtas}
                isLoadingMoreData={isLoadingMoreData}
                hasMoreResults={hasMorePages}
                height={isLowHeightScreen ? 'max-h-52' : 'max-h-68'}
            >
                {isLoadingSearchData ? (
                    <div className="flex w-full h-full items-center justify-center">
                        <LoadingSpinner size={10} />
                    </div>
                ) : (
                    ctasDataWithSearchApplied.map((displayedCta) => (
                        <CtaListItem
                            key={displayedCta.id}
                            cta={displayedCta}
                            onSelectCta={onSelectCta}
                            active={selectedCtaId === displayedCta.id}
                        />
                    ))
                )}
            </EndlessScrollContainer>

            <div className="mt-6 xs:mt-10 flex w-full justify-center">
                {createNewButton && createNewButton}
                {ctasData.length > 0 && useAsTemplateButton && useAsTemplateButton}
            </div>
        </div>
    );
};
