import React from 'react';

import DeleteIcon from './assets/icons/delete.svg';
import VerifyIcon from './assets/icons/repeat.svg';
import EditIcon from './assets/icons/edit.svg';

import MenuBulletsIcon from './assets/icons/menu-bullets-horizontal.svg';
import { WebsitesListItem } from './data-transformations/create-websites-list';

interface EditRowButtonDropdownOptionProps {
    readonly labelText: string;
    readonly iconSrc: string;
    readonly onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly marginTop?: string;
}

export const EditRowButtonDropdownOption: React.FC<EditRowButtonDropdownOptionProps> = ({
    labelText,
    iconSrc,
    onClick,
    marginTop
}) => (
    <li className={`mt-${marginTop}`}>
        <button className="flex items-center" onClick={onClick}>
            <img src={iconSrc} alt="icon" className="w-4 h-4" />
            <span className="ml-2">{labelText}</span>
        </button>
    </li>
);

interface EmbedOnWebsiteEditRowButtonDropdownProps {
    readonly website: WebsitesListItem;
    readonly onDeleteWebsiteClick: (id: string) => void;
    readonly onVerifyWebsiteEmbeddedClick: (id: string) => void;
    readonly onOpenEditWebsite: (website: WebsitesListItem) => void;
}

export const EmbedOnWebsiteEditRowButtonDropdown: React.FC<
    EmbedOnWebsiteEditRowButtonDropdownProps
> = ({ website, onDeleteWebsiteClick, onVerifyWebsiteEmbeddedClick, onOpenEditWebsite }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end my-2" ref={containerRef}>
            <button onClick={() => setIsMenuOpen(isMenuOpen ? false : true)}>
                <img className="h-4 w-4" src={MenuBulletsIcon} />
            </button>
            {isMenuOpen && (
                <ul className="z-1 absolute top-4 -right-2 p-2 text-sm bg-white border border-grey-lightest rounded-md w-32 shadow-lg">
                    <EditRowButtonDropdownOption
                        labelText="Edit"
                        iconSrc={EditIcon}
                        onClick={(event) => {
                            event.preventDefault();
                            onOpenEditWebsite(website);
                            setIsMenuOpen(false);
                        }}
                    />
                    {!website.verified && (
                        <EditRowButtonDropdownOption
                            labelText="Verify"
                            iconSrc={VerifyIcon}
                            onClick={() => {
                                onVerifyWebsiteEmbeddedClick(website.id);
                                setIsMenuOpen(false);
                            }}
                            marginTop="2"
                        />
                    )}
                    <EditRowButtonDropdownOption
                        labelText="Delete"
                        iconSrc={DeleteIcon}
                        onClick={(event) => {
                            event.preventDefault();
                            onDeleteWebsiteClick(website.id);
                            setIsMenuOpen(false);
                        }}
                        marginTop={website.verified ? '0' : '2'}
                    />
                </ul>
            )}
        </div>
    );
};
