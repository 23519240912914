import React from 'react';
import { InfoCardGrid } from './components/InfoCardGrid';
import { SupportInfoCard, SupportInfoCardProps } from './components/SupportInfoCard';
import ManagingTeamImage from './assets/images/LearnTheBasics/managing-team.jpeg';
import WhatIsSniplyImage from './assets/images/LearnTheBasics/what-is-sniply.jpeg';
import CreatingSnipsImage from './assets/images/LearnTheBasics/creating-snips.jpeg';
import UsingCtasImage from './assets/images/LearnTheBasics/using-ctas.jpeg';
import AnalyticsImage from './assets/images/LearnTheBasics/analytics.jpeg';

const LEARN_THE_BASICS: SupportInfoCardProps[] = [
    {
        titleText: 'What is Sniply?',
        additionalText: 'Understanding the concepts behind sniply',
        imageUrl: WhatIsSniplyImage,
        infoLinks: [
            { displayText: 'What is Sniply', url: 'https://sniply.io/blog/what-sniply/' },
            {
                displayText: 'Why do people use Sniply?',
                url: 'https://sniply.io/blog/why-sniply/'
            },
            {
                displayText: 'How do I drive clicks to my Snips?',
                url: 'https://sniply.io/blog/getting-more-clicks-with-sniply/'
            }
        ]
    },
    {
        titleText: 'Creating Snips',
        additionalText: 'Learn how to attach Call-To-Actions (CTAs) to links',
        imageUrl: CreatingSnipsImage,
        infoLinks: [
            {
                displayText: 'What is a snip?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000835975060-What-is-a-Sniply-Link-'
            },
            {
                displayText: 'How do I create a snip?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000886011668-How-do-I-create-a-new-Sniply-Link-'
            },
            {
                displayText: 'How do I make changes to a snip?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000969794836-How-do-I-make-changes-to-a-Sniply-Link-'
            }
        ]
    },
    {
        titleText: 'Using and Styling Call-to-Actions (CTAs)',
        additionalText:
            'Learn how to use and style your Call-To-Actions unleashing the full potential of Sniply.',
        imageUrl: UsingCtasImage,
        infoLinks: [
            {
                displayText: 'What is a Call-to-Action?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000831802772-What-is-a-Call-to-Action-'
            },
            {
                displayText: 'How do I create a new Call-to-Action?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000945571476-How-do-I-create-a-new-Call-to-Action-'
            },
            {
                displayText: 'How do I make changes to a Call-to-Action?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000988707092-How-do-I-change-and-edit-my-Call-to-Actions-'
            }
        ]
    },
    {
        titleText: 'Analytics and Tracking',
        additionalText: 'Measure the results and impact of your Snips',
        imageUrl: AnalyticsImage,
        infoLinks: [
            {
                displayText: 'What is Sniply Analytics?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000836021652-What-is-Sniply-Analytics-'
            },
            {
                displayText: 'How do I check Analytics of a Call-To-Action?',
                url: 'https://support.snip.ly/hc/en-us/articles/20001010412052-How-do-I-check-Analytics-for-Call-to-Actions-'
            },
            {
                displayText: 'How do I check Analytics for Call-To-Action A/B testing?',
                url: 'https://support.snip.ly/hc/en-us/articles/20001062147476-How-do-I-check-Analytics-for-A-B-Testing-Variations-'
            }
        ]
    },
    {
        titleText: 'Managing a team',
        additionalText: 'How to invite colleagues and collaborate on Sniply',
        imageUrl: ManagingTeamImage,
        infoLinks: [
            {
                displayText: 'What is a team member?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000851247124-What-is-a-Team-Member-'
            },
            {
                displayText: 'How do I add a Team Member?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000873772692-How-do-I-add-a-Team-Member-'
            },
            {
                displayText: 'How do I manage my Team Members?',
                url: 'https://support.snip.ly/hc/en-us/articles/20000971517076-How-do-I-manage-my-Team-Members-'
            }
        ]
    }
];

export const SupportLearnTheBasics = () => {
    return (
        <InfoCardGrid>
            {LEARN_THE_BASICS.map((infoCardData) => (
                <SupportInfoCard
                    key={infoCardData.titleText}
                    titleText={infoCardData.titleText}
                    additionalText={infoCardData.additionalText}
                    infoLinks={infoCardData.infoLinks}
                    imageUrl={infoCardData.imageUrl}
                />
            ))}
        </InfoCardGrid>
    );
};
