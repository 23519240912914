import { User } from '../UserContextProvider';
import { FullUser } from '../api';

export function createUser(userResponse: FullUser): User {
    return {
        id: userResponse?.profile?.id ?? '',
        name: userResponse?.username ?? '',
        email: userResponse?.email ?? '',
        firstName: userResponse?.first_name ?? '',
        lastName: userResponse?.last_name ?? ''
    };
}
