import React from 'react';

interface PlainToggleProps {
    readonly optionOne: string;
    readonly optionTwo: string;
    readonly selectedOption: string;
    readonly onSelectOption: (option: string) => void;
}

export const PlainToggle: React.FC<PlainToggleProps> = ({
    optionOne,
    optionTwo,
    selectedOption,
    onSelectOption
}) => (
    <div className="flex items-center text-sm">
        <button
            className={`w-24 sm:w-32 p-2 bg-white ${
                optionOne === selectedOption
                    ? 'text-grey border border-grey rounded-l-md'
                    : 'text-grey-light border-y border-l border-grey-light rounded-l-md'
            }`}
            onClick={() => onSelectOption(optionOne)}
        >
            {optionOne}
        </button>
        <div className="border-l left-1/2 h-2/3 self-center"></div>
        <button
            className={`w-24 sm:w-32 p-2 bg-white ${
                optionTwo === selectedOption
                    ? 'text-grey border border-grey rounded-r-md'
                    : 'text-grey-light border-y border-r border-grey-light rounded-r-md'
            }`}
            onClick={() => onSelectOption(optionTwo)}
        >
            {optionTwo}
        </button>
    </div>
);
