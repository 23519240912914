import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import { TeamService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';

export const AccountSettingsContentAndPlatform = () => {
    const [enableAmpForDesktop, setEnableAmpForDesktop] = React.useState(false);

    const [fetchingInitialData, setFetchingInitialData] = React.useState(false);
    const [errorFetchingInitialData, setErrorFetchingInitialData] = React.useState(false);

    const [updatingData, setUpdatingData] = React.useState(false);
    const [errorUpdatingData, setErrorUpdatingData] = React.useState(false);
    const [successUpdatingData, setSuccessUpdatingData] = React.useState(false);

    const handleSubmitAmpSettings = React.useCallback(
        (event) => {
            event.preventDefault();
            setUpdatingData(true);

            const newValue = enableAmpForDesktop ? false : true;

            TeamService.teamPartialUpdate({
                requestBody: { amp_enabled_on_desktop: newValue }
            })
                .then(() => {
                    setUpdatingData(false);
                    setSuccessUpdatingData(true);
                    setTimeout(() => setSuccessUpdatingData(false), 5000);
                })
                .catch((error) => {
                    console.error('Error changing amp settings.', error);
                    setErrorUpdatingData(true);
                    setUpdatingData(false);
                });
        },
        [enableAmpForDesktop, setUpdatingData]
    );

    React.useEffect(() => {
        setFetchingInitialData(true);

        TeamService.teamRetrieve()
            .then((result) => {
                setFetchingInitialData(false);
                setEnableAmpForDesktop(result.amp_enabled_on_desktop);
            })
            .catch((error) => {
                console.error('Error fetching from TeamService.teamRetrieve()', error);
                setFetchingInitialData(false);
                setErrorFetchingInitialData(true);
            });
    }, []);

    return (
        <ContentElementContainer>
            <SimpleContentBox
                headerText="Content & Platform Settings
"
            >
                {fetchingInitialData ? (
                    <div className="flex h-full w-full items-center justify-center">
                        <LoadingSpinner size={5} />
                    </div>
                ) : errorFetchingInitialData ? (
                    <p className="mt-2 text-xs text-red">
                        Unable to retrieve your current AMP settings. Please try again by reloading
                        the browser window.
                    </p>
                ) : (
                    <form className="mt-1" onSubmit={handleSubmitAmpSettings}>
                        <div className="flex items-center">
                            <input
                                id="enable-amp"
                                type="checkbox"
                                checked={enableAmpForDesktop}
                                onChange={() => {
                                    setEnableAmpForDesktop(enableAmpForDesktop ? false : true);
                                }}
                            />
                            <label htmlFor="enable-amp" className="ml-4 text-sm">
                                Enable AMP version for desktop visitors
                            </label>
                        </div>
                        <div className="mt-4">
                            {updatingData ? (
                                <div className="h-9 flex w-full items-center justify-center">
                                    <LoadingSpinner size={5} />
                                </div>
                            ) : (
                                <ButtonMain className="w-full xs:w-40" size="small" type="submit">
                                    Save
                                </ButtonMain>
                            )}
                        </div>
                    </form>
                )}
                {errorUpdatingData && (
                    <p className="mt-2 text-xs text-red">
                        Something went wrong when trying to save your changes. Please try again.
                    </p>
                )}
                {successUpdatingData && (
                    <p className="mt-2 text-xs text-green">
                        Your settings were successfully updated.
                    </p>
                )}
            </SimpleContentBox>
        </ContentElementContainer>
    );
};
