import React from 'react';
import { ButtonMain } from './components/ButtonMain';
import { CtaColorPicker } from './components/CtaColorPicker';
import { CtaCustomizingStyleContainer } from './components/CtaCustomizingStyleContainer';
import { FormTextInput } from './components/FormTextInput';
import { ImageUploadWithCrop } from './components/ImageUploadWithCrop';

const CustomizingButton: React.FC<{
    readonly customizingStyle: boolean;
    readonly setCustomizingStyle: (value: boolean) => void;
}> = ({ customizingStyle, setCustomizingStyle }) => (
    <div className="flex justify-center">
        <ButtonMain
            className="w-48"
            size="small"
            style="neutral"
            onClick={(event) => {
                event.preventDefault();
                setCustomizingStyle(customizingStyle ? false : true);
            }}
        >
            {customizingStyle ? 'Back To Configuration' : 'Customize Style'}
        </ButtonMain>
    </div>
);

export interface PageCreatorProps {
    readonly name: string;
    readonly setName: (name: string) => void;
    readonly titleText: string;
    readonly setTitleText: (titleText: string) => void;
    readonly imageUrl: string;
    readonly setImageUrl: (url: string) => void;
    readonly setImagePreviewUrl: (url: string) => void;
    readonly facebookProfileUrl: string;
    readonly setFacebookProfileUrl: (url: string) => void;
    readonly instagramProfileUrl: string;
    readonly setInstagramProfileUrl: (url: string) => void;
    readonly twitterProfileUrl: string;
    readonly setTwitterProfileUrl: (url: string) => void;
    readonly linkedinProfileUrl: string;
    readonly setLinkedinProfileUrl: (url: string) => void;
    readonly backgroundColor: string;
    readonly setBackgroundColor: (color: string) => void;
    readonly textColor: string;
    readonly setTextColor: (color: string) => void;
    readonly linksTextColor: string;
    readonly setLinksTextColor: (color: string) => void;
    readonly linksButtonColor: string;
    readonly setLinksButtonColor: (color: string) => void;
    readonly link1Text: string;
    readonly setLink1Text: (text: string) => void;
    readonly link1Url: string;
    readonly setLink1Url: (url: string) => void;
    readonly link2Text: string;
    readonly setLink2Text: (text: string) => void;
    readonly link2Url: string;
    readonly setLink2Url: (url: string) => void;
    readonly link3Text: string;
    readonly setLink3Text: (text: string) => void;
    readonly link3Url: string;
    readonly setLink3Url: (url: string) => void;
}

export const PageCreator: React.FC<PageCreatorProps> = ({
    name,
    setName,
    titleText,
    setTitleText,
    imageUrl,
    setImageUrl,
    setImagePreviewUrl,
    backgroundColor,
    setBackgroundColor,
    textColor,
    setTextColor,
    linksTextColor,
    setLinksTextColor,
    linksButtonColor,
    setLinksButtonColor,
    link1Text,
    setLink1Text,
    // link1Url,
    // setLink1Url,
    link2Text,
    setLink2Text,
    // link2Url,
    // setLink2Url,
    link3Text,
    setLink3Text
    // link3Url,
    // setLink3Url
}) => {
    const [customizingStyle, setCustomizingStyle] = React.useState(false);

    return (
        <div>
            {!customizingStyle ? (
                <div className="mt-10 grid grid-cols-1 gap-4">
                    <FormTextInput
                        id="page-name-input"
                        labelText="Page Name (optional)"
                        placeholder="Enter a page name here"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <ImageUploadWithCrop
                        setImageUrl={setImageUrl}
                        imageUrl={imageUrl}
                        setImagePreviewUrl={setImagePreviewUrl}
                        aspect={1}
                    />
                    <FormTextInput
                        id="title-input"
                        labelText="Title"
                        placeholder="Enter a title here"
                        value={titleText}
                        onChange={(event) => setTitleText(event.target.value)}
                    />
                    {/* <FormTextInput
                    id="facebook-input"
                    labelText="Facebook"
                    explanation={
                        <div className="ml-2 text-xs text-grey-light">(Personalise your message)</div>
                    }
                    placeholder="Link your Facebook profile here"
                    value={facebookUrl}
                    onChange={(event) => setFacebookUrl(event.target.value)}
                /> */}
                    <FormTextInput
                        id="link-1-text-input"
                        labelText="Link 1 Text"
                        placeholder="Enter first links text here"
                        value={link1Text}
                        onChange={(event) => setLink1Text(event.target.value)}
                    />
                    <FormTextInput
                        id="link-2-text-input"
                        labelText="Link 2 Text"
                        placeholder="Enter first links text here"
                        value={link2Text}
                        onChange={(event) => setLink2Text(event.target.value)}
                    />
                    <FormTextInput
                        id="link-3-text-input"
                        labelText="Link 3 Text"
                        placeholder="Enter first links text here"
                        value={link3Text}
                        onChange={(event) => setLink3Text(event.target.value)}
                    />
                </div>
            ) : (
                <div className="mt-10">
                    <CtaCustomizingStyleContainer>
                        <div>
                            <div className="flex items-center">
                                <h4 className="text-sm">Colors</h4>
                            </div>
                            <div className="mt-2 grid grid-cols-2 gap-2">
                                <CtaColorPicker
                                    titleText="Background"
                                    colorHexValue={backgroundColor}
                                    onChangeColor={setBackgroundColor}
                                />
                                <CtaColorPicker
                                    titleText="Text"
                                    colorHexValue={textColor}
                                    onChangeColor={setTextColor}
                                />
                                <CtaColorPicker
                                    titleText="Links Text"
                                    colorHexValue={linksTextColor}
                                    onChangeColor={setLinksTextColor}
                                />
                                <CtaColorPicker
                                    titleText="Button"
                                    colorHexValue={linksButtonColor}
                                    onChangeColor={setLinksButtonColor}
                                />
                            </div>
                        </div>
                    </CtaCustomizingStyleContainer>
                </div>
            )}
            <div className="mt-8">
                <CustomizingButton
                    customizingStyle={customizingStyle}
                    setCustomizingStyle={setCustomizingStyle}
                />
            </div>
        </div>
    );
};
