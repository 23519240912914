import React from 'react';
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { ReportingTimePeriodInDays } from './Reporting';

export interface MainGraphEntry {
    readonly 'Date': string;
    readonly 'Clicks': number;
    readonly 'Conversions': number;
    readonly 'Rate (%)': number;
}
interface GraphMainProps {
    readonly data: MainGraphEntry[];
    readonly timeFrame: ReportingTimePeriodInDays;
}

export const GraphMain: React.FC<GraphMainProps> = ({ data, timeFrame }) => {
    const xAchsisTickInterval = timeFrame === '7' ? undefined : 7;
    const isMobile = useIsMobileScreenSize();

    return !data ? null : (
        <div className="h-84">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart style={{ fontFamily: 'Poppins', fontSize: '12px' }} data={data}>
                    <defs>
                        <linearGradient id="clicks-fill" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#2681DB" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="conversions-fill" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        style={{ fontSize: '8px' }}
                        dataKey="Date"
                        fontFamily="Poppins"
                        fontSize={12}
                        tickLine={false}
                        interval={xAchsisTickInterval}
                        stroke="#A1B3C1"
                    />
                    <YAxis yAxisId="primary" axisLine={false} tick={false} width={0} />
                    <YAxis
                        yAxisId="secondary"
                        axisLine={false}
                        tick={false}
                        width={0}
                        type="number"
                        domain={[0, 100]}
                    />
                    <Tooltip />
                    <Area
                        yAxisId="primary"
                        type="monotone"
                        dataKey="Clicks"
                        stroke="#2681DB"
                        fill="url(#clicks-fill)"
                    />
                    <Area
                        yAxisId="primary"
                        type="monotone"
                        dataKey="Conversions"
                        stroke="#82ca9d"
                        fill="url(#conversions-fill)"
                    />
                    <Area
                        yAxisId="secondary"
                        type="monotone"
                        dataKey="Rate (%)"
                        stroke="#ff8080"
                        strokeDasharray={10}
                        strokeWidth={1}
                        fill="#ff8080"
                        fillOpacity={0}
                    />
                    <Legend
                        align={isMobile ? 'center' : 'right'}
                        verticalAlign={isMobile ? 'bottom' : 'top'}
                        iconSize={12}
                        iconType="plainline"
                        formatter={(value) => (
                            <span className="ml-1 text-grey text-xs">{value}</span>
                        )}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};
