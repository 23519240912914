/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionData } from '../models/SubscriptionData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlansService {

    /**
     * Shows plans list with limits and prices as well as stripe publishable key
     * @returns SubscriptionData
     * @throws ApiError
     */
    public static plansRetrieve(): CancelablePromise<SubscriptionData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/plans',
        });
    }

}
