export function ensureUrlHasProtocol(potentiallyRawUrl: string) {
    if (
        potentiallyRawUrl.trim()?.startsWith('http://') ||
        potentiallyRawUrl.trim()?.startsWith('https://') ||
        potentiallyRawUrl.trim()?.startsWith('ftp://') ||
        potentiallyRawUrl.trim()?.startsWith('ftps://') ||
        potentiallyRawUrl.trim()?.startsWith('mailto:') ||
        potentiallyRawUrl.trim()?.startsWith('tel:') ||
        potentiallyRawUrl.trim()?.startsWith('sms:')
    ) {
        return potentiallyRawUrl;
    }

    return 'https://' + potentiallyRawUrl.trim();
}
