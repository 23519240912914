import React from 'react';
import ChevronIcon from '../assets/icons/chevron-down-white.svg';

interface ButtonMainActionsProps {
    readonly size?: 'normal' | 'small';
    readonly width?: string;
}

export const ButtonMainActions: React.FC<ButtonMainActionsProps> = ({ size = 'normal', width }) => {
    const [isClickedOpen, setIsClickedOpen] = React.useState(false);
    const [isHoveredOpen, setIsHoveredOpen] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleDropdownClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (isClickedOpen) {
                setIsClickedOpen(false);
            } else {
                setIsClickedOpen(true);
            }
        },
        [isClickedOpen]
    );

    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsClickedOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div
                ref={containerRef}
                className={`relative flex z-100 ${width ? width : 'w-full'}`}
                onMouseLeave={() => isHoveredOpen && setIsHoveredOpen(false)}
            >
                <a
                    className={`flex grow ${
                        size === 'small' ? 'text-sm font-normal h-9' : 'text-base font-normal h-10'
                    } flex items-center rounded-l-md px-2 py-1 text-white transition duration-300 transform hover:shadow-sm bg-primary hover:bg-primary-dark`}
                    href="/snip-create/content-select"
                >
                    + Create Snip
                </a>
                <button
                    onMouseEnter={() => setIsHoveredOpen(true)}
                    onClick={handleDropdownClick}
                    className={`bg-primary hover:bg-primary-dark rounded-r-md border-l border-white flex items-center justify-center px-2 py-1 transition duration-300 transform hover:shadow-sm 
          `}
                >
                    <img
                        src={ChevronIcon}
                        alt="chevron icon"
                        className={`w-5 h-5 transition-all ease-in duration-100 ${
                            isClickedOpen || isHoveredOpen ? 'rotate-180' : ' rotate-0'
                        }`}
                    />
                </button>
                <div
                    className={`absolute flex w-full z-50 left-0 transition-all ease-in duration-300 ${
                        isClickedOpen || isHoveredOpen ? 'opacity-1' : 'opacity-0'
                    } ${size === 'small' ? 'top-9' : 'top-10'}`}
                >
                    <a
                        className={`flex grow ${
                            size === 'small'
                                ? 'text-sm font-normal h-9'
                                : 'text-base font-normal h-10'
                        } border-t border-white flex items-center rounded-l-md px-2 py-1 text-white transition duration-300 transform hover:shadow-sm
     bg-primary hover:bg-primary-dark`}
                        href="/cta-create"
                    >
                        + Create CTA
                    </a>
                    {
                        // Invisible elements in order to use flexing of the left hand side elements of the button
                        // while maintaining the same width.
                        // There is probably a much more elegant way.
                    }
                    <div className={`bg-transparent flex px-2 py-1 border-l border-transparent`}>
                        <span className="h-5 w-5"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};
