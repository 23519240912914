import React from 'react';

const cleanPercentage = (percentage) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct = 100, r = 70 }) => {
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    return (
        <circle
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
            strokeWidth={'6px'}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};

const Pie = ({ percentage1, percentage2, percentage3, colour }) => {
    const a = -90;
    const b = a + percentage3 * 3.6;
    const c = b + percentage2 * 3.6;

    return (
        <>
            <svg style={{ transform: 'rotateY(180deg)' }} width={200} height={200}>
                <g transform={`rotate(${c} ${'100 100'})`}>
                    <Circle colour="#E2E8EE" r={75} />
                    <Circle colour={colour} pct={cleanPercentage(percentage1)} r={75} />
                </g>
                <g transform={`rotate(${b} ${'100 100'})`}>
                    <Circle colour="#E2E8EE" r={55} />
                    <Circle colour={colour} pct={cleanPercentage(percentage2)} r={55} />
                </g>
                <g transform={`rotate(${a} ${'100 100'})`}>
                    <Circle colour="#E2E8EE" r={35} />
                    <Circle colour={colour} pct={cleanPercentage(percentage3)} r={35} />
                </g>
            </svg>
        </>
    );
};

export default Pie;
