import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { SnipsListItem } from './data-transformations/create-snip-data';
import SuccessImage from './assets/images/success.svg';
import { LinkButtonMain } from './components/ButtonMain';
import FacebookIcon from './assets/icons/facebook-minimal.svg';
import InstagramIcon from './assets/icons/instagram-minimal.svg';
import TwitterIcon from './assets/icons/twitter-minimal.svg';
import LinkedInIcon from './assets/icons/linkedIn-minimal.svg';
import LightBulbImage from './assets/images/lightbulb.svg';
import { navigateTo } from './Routing';
import { SnipUrlCustomizer } from './components/SnipUrlCustomizer';
import { NotificationContext } from './NotificationContextProvider';
import CopyIconWhite from './assets/icons/copy-white.svg';
import CopyIcon from './assets/icons/copy.svg';
import { QRCodeCanvas } from 'qrcode.react';
import QRCodeIcon from './assets/icons/qr-code.svg';
import QRCodeIconWhite from './assets/icons/qr-code-white.svg';

interface SnipsSnipCreationSuccessfulProps {
    readonly snip: SnipsListItem;
    readonly setSnip: (snip: SnipsListItem) => void;
    readonly setShowCreationSuccessInfo: (state: boolean) => void;
    readonly domainId: string;
    readonly setDomainId: (id: string) => void;
    readonly shortLink: string;
    readonly setShortLink: (shortLink: string) => void;
}

export const SnipsSnipCreationSuccessful: React.FC<SnipsSnipCreationSuccessfulProps> = ({
    snip,
    setSnip,
    setShowCreationSuccessInfo,
    domainId,
    setDomainId,
    shortLink,
    setShortLink
}) => {
    const { handleOpenNotification } = React.useContext(NotificationContext);

    return (
        <ContentElementContainer>
            <div className="flex items-center justify-end">
                <button
                    className="text-grey-light text-sm"
                    onClick={(event) => {
                        event.preventDefault();

                        setShowCreationSuccessInfo(false);
                        // Remove URL Query Param which triggers the Creation Success Element
                        history.replaceState({}, document.title, '/snips' + '/' + snip.id);
                    }}
                >
                    Dismiss
                </button>
            </div>
            <div className="mt-2 grid grid-cols-1 md:grid-cols-3-2 gap-6">
                <div className="">
                    <div className="flex items-center">
                        <span className="font-semibold text-lg">Snip successfully created!</span>
                        <img src={SuccessImage} alt="success image" className="ml-2 h-8 w-8" />
                    </div>
                    <p className="mt-4">
                        Now that you have successfully created a Snip, you can further customize and
                        share the link with the world. Copy your URL, download a QR code or share on
                        any channel you want.
                    </p>
                    <div className="mt-4">
                        <h4 className="font-semibold mb-4">Customize your Snip URL</h4>
                        <div className="mt-1 flex">
                            <SnipUrlCustomizer
                                snip={snip}
                                setSnip={setSnip}
                                domainId={domainId}
                                setDomainId={setDomainId}
                                shortlink={shortLink}
                                setShortlink={setShortLink}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <h4 className="font-semibold mb-4">Share your Snip</h4>
                        <div className="flex flex-col sm:flex-row items-start">
                            <button
                                className="py-1 px-2 border border-grey-light rounded-md flex items-center"
                                onClick={(event) => {
                                    event.preventDefault();

                                    navigator.clipboard.writeText(snip.snipUrl);

                                    handleOpenNotification({
                                        messageText: 'Snip URL successfully copied.',
                                        type: 'success',
                                        iconSrc: CopyIconWhite,
                                        showTimeInSeconds: 3
                                    });
                                }}
                            >
                                <img className="h-5 w-5" src={CopyIcon} />
                                <span className="ml-2 text-sm">Copy Link</span>
                            </button>
                            <button
                                className="mt-2 sm:mt-0 sm:ml-2 py-1 px-2 border border-grey-light rounded-md flex items-center"
                                onClick={(event) => {
                                    event.preventDefault();

                                    const canvas = document.getElementById('qr-code');
                                    const pngUrl = (canvas as HTMLCanvasElement)
                                        .toDataURL('image/png')
                                        .replace('image/png', 'image/octet-stream');

                                    const downloadLink = document.createElement('a');
                                    downloadLink.href = pngUrl;
                                    downloadLink.download = `sniply-qr-code-${snip.slug}.png`;
                                    document.body.appendChild(downloadLink);
                                    downloadLink.click();
                                    document.body.removeChild(downloadLink);

                                    handleOpenNotification({
                                        messageText: 'QR code successfully downloaded.',
                                        type: 'success',
                                        iconSrc: QRCodeIconWhite,
                                        showTimeInSeconds: 3
                                    });
                                }}
                            >
                                <div>
                                    <QRCodeCanvas
                                        id="qr-code"
                                        className="hidden"
                                        value={snip.snipUrl}
                                        includeMargin
                                        fgColor="#2681DB"
                                        size={1000}
                                    />
                                    <img className="h-5 w-5" src={QRCodeIcon} />
                                </div>
                                <span className="ml-2 text-sm">Download QR Code</span>
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 text-sm">
                        <p>Share on your favorite platforms</p>
                        <div className="mt-2 flex items-center">
                            <LinkButtonMain
                                href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${snip.snipUrl}&p[title]=${snip.contentTitle}`}
                                size="small"
                                style="neutral"
                                className="ml-4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={FacebookIcon} alt="facebook icon" className="w-5 h-5" />
                            </LinkButtonMain>
                            <LinkButtonMain
                                href={`http://twitter.com/share?text=${snip.contentTitle}&url=${snip.snipUrl}`}
                                size="small"
                                style="neutral"
                                className="ml-4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={TwitterIcon} alt="twitter icon" className="w-5 h-5" />
                            </LinkButtonMain>
                            <LinkButtonMain
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${snip.snipUrl}&title=${snip.contentTitle}`}
                                size="small"
                                style="neutral"
                                className="ml-4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={LinkedInIcon} alt="linkedin icon" className="w-5 h-5" />
                            </LinkButtonMain>
                            <LinkButtonMain
                                href={`https://www.instagram.com`}
                                size="small"
                                style="neutral"
                                className="ml-4"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={InstagramIcon} alt="instagram icon" className="w-5 h-5" />
                            </LinkButtonMain>
                        </div>
                    </div>
                </div>
                <div className="bg-primary bg-opacity-10 py-6 pl-3 pr-6">
                    <div className="flex">
                        <img src={LightBulbImage} alt="lightbulb image" className="w-10 h-10" />
                        <div className="ml-2">
                            <p className="font-semibold text-sm">
                                Automatic sharing via e.g. Zapier integration
                            </p>
                            <p className="mt-4 text-sm">
                                Did you know that you can automatically share you Snips on your
                                favorite mediums through our integrations?
                            </p>
                            <button
                                className="mt-4 underline text-sm"
                                onClick={() => navigateTo('/integrations')}
                            >
                                View Integrations
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ContentElementContainer>
    );
};
