import React from 'react';

export function useInitializeChurnkeyIntegration(): void {
    React.useEffect(() => {
        if (!(window as any).churnkey || !(window as any).churnkey.created) {
            (window as any).churnkey = { created: true };
            const a = document.createElement('script');
            a.src = `https://assets.churnkey.co/js/app.js?appId=${process.env.CHURNKEY_APP_ID}`;
            a.async = true;
            const b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b);
        }
    }, []);
}
