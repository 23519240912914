import React from 'react';
import { CheckIfUrlSnippableService, LinksService } from '../../api';
import ButtonBack from '../../components/ButtonBack';
import { CtaType } from '../../components/CtaTypeDropdownSelect';
import { navigateTo, RouteLink, useCurrentUrlPath } from '../../Routing';
import CloseIcon from '../../assets/icons/close.svg';
import { useHasSpaceForCtaSidePreview } from '../../hooks/use-has-space-for-cta-side-preview';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import { LegacyCta } from '../../cta-components/LegacyCta';
import { CtaPosition, CtasListItem, CtaTheme } from '../../data-transformations/create-cta-data';
import {
    DemoContentIntegrator,
    DemoView,
    PreviewViewSelectionTab
} from '../../components/DemoContentIntegrator';
import { useElementWidth } from '../../hooks/use-element-width';
import { MOBILE_VIEW_THRESHOLD } from '../../hooks/use-is-mobile-screen-size';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_FORM_CTA_PLACEHOLDER_TEXT,
    DEFAULT_LINK_TEXT_COLOR,
    DEFAULT_MESSAGE_TEXT_COLOR
} from '../../utils/default-cta-configuration';
import { historyBackWithFallback } from '../../utils/history-back-with-fallback';
import { SnipUrls, createSnipUrlsData } from '../../data-transformations/create-snip-urls-data';
import { PrepareContentStep } from '../../components/PrepareContentStep';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { SnipCtaAddToRedirectCtaSelect } from './SnipCtaAddToRedirectCtaSelect';

export const SnipCtaAddToRedirect = () => {
    const [snipId, setSnipId] = React.useState('');
    const [isLoadingSnip, setIsLoadingSnip] = React.useState(true);

    const [showCtaSidePreview, setShowCtaSidePreview] = React.useState(false);
    // const [hasJourneyBeenStarted, setHasJourneyBeenStarted] = React.useState(false);

    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    const [snipUrls, setSnipUrls] = React.useState<SnipUrls>();

    // Form Values --- Start
    const [callToActionUrl, setCallToActionUrl] = React.useState('');

    // This following is the Url used in the iFrame inside by the renderer application
    // and the demo / preview of this application.
    // It can be either a) the original URL, b) a Sniply Proxy Url or c) a Sniply Summary Url
    const [sniplyContentUrl, setSniplyContentUrl] = React.useState('');

    const [ctaId, setCtaId] = React.useState('');
    const [ctaName, setCtaName] = React.useState('');
    const [ctaType, setCtaType] = React.useState<CtaType>('button');

    const [headlineText, setHeadlineText] = React.useState('Your Headline');
    const [imageUrl, setImageUrl] = React.useState<string>();

    const [messageText, setMessageText] = React.useState('Your Message');
    const [messageTextColor, setMessageTextColor] = React.useState(DEFAULT_MESSAGE_TEXT_COLOR);

    const [buttonText, setButtonText] = React.useState('Click here');
    const [buttonTextColor, setButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);

    const [linkText, setLinkText] = React.useState('Click here');
    const [linkTextColor, setLinkTextColor] = React.useState(DEFAULT_LINK_TEXT_COLOR);

    const [backgroundColor, setBackgroundColor] = React.useState(DEFAULT_BACKGROUND_COLOR);
    const [buttonColor, setButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);

    const [formButtonTextColor, setFormButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);
    const [formButtonColor, setFormButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);
    const [formPlaceholderText, setFormPlaceholderText] = React.useState(
        DEFAULT_FORM_CTA_PLACEHOLDER_TEXT
    );

    const [imageAdUrl, setImageAdUrl] = React.useState('');

    const [theme, setTheme] = React.useState<CtaTheme>('social');
    const [position, setPosition] = React.useState<CtaPosition>('bottom-left');

    const [showSniplyLogo, setShowSniplyLogo] = React.useState(true);
    const [showWhiteSniplyLogo, setShowWhiteSniplyLogo] = React.useState(false);

    const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>(undefined);
    const [imageAdPreviewUrl, setImageAdPreviewUrl] = React.useState<string>(undefined);

    const [imageBlob, setImageBlob] = React.useState<Blob>();
    const [fileName, setFileName] = React.useState<string>();
    // Form Values --- End

    const { level2, level3 } = useCurrentUrlPath();

    const handleSelectCta = React.useCallback((cta: CtasListItem) => {
        setCtaId(cta.id);
        setCallToActionUrl(cta.urlToBePromoted);
        setCtaName(cta.name);
        setCtaType(cta.type);
        setImageUrl(cta.imageUrl);
        setHeadlineText(cta.headlineText);
        setMessageText(cta.messageText);
        setMessageTextColor(cta.messageTextColor);
        setBackgroundColor(cta.backgroundColor);
        setButtonColor(cta.buttonColor);
        setButtonText(cta.buttonText);
        setButtonTextColor(cta.buttonTextColor);
        setLinkText(cta.linkText);
        setLinkTextColor(cta.linkTextColor);
        setFormButtonTextColor(cta.formButtonTextColor);
        setFormButtonColor(cta.formButtonColor);
        setFormPlaceholderText(cta.formPlaceholderText);
        setImageAdUrl(cta.adImageUrl);
        setPosition(cta.position);
        setTheme(cta.theme);
        setShowSniplyLogo(cta.showSniplyLogo);
        setShowWhiteSniplyLogo(cta.showWhiteSniplyLogo);
    }, []);

    const handleResetCtaConfiguration = React.useCallback(() => {
        setCallToActionUrl('');

        setCtaId('');
        setCtaName('');
        setCtaType('button');

        setImageUrl(undefined);

        setHeadlineText('Your Headline');

        setMessageText('Your Message');
        setMessageTextColor(DEFAULT_MESSAGE_TEXT_COLOR);

        setButtonText('Click here');
        setButtonColor(DEFAULT_BUTTON_COLOR);
        setButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);

        setLinkText('Click here');
        setLinkTextColor(DEFAULT_LINK_TEXT_COLOR);

        setBackgroundColor(DEFAULT_BACKGROUND_COLOR);

        setFormButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);
        setFormButtonColor(DEFAULT_BUTTON_COLOR);
        setFormPlaceholderText(DEFAULT_FORM_CTA_PLACEHOLDER_TEXT);

        setImageAdUrl('');
        setTheme('social');
        setPosition('bottom-left');

        setShowSniplyLogo(true);
        setShowWhiteSniplyLogo(false);
    }, []);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const snipIdFromQueryParams = searchParams.get('snipId');
        setSnipId(snipIdFromQueryParams);

        LinksService.linksRetrieve({ id: snipIdFromQueryParams })
            .then((snipResult) => {
                setSniplyContentUrl(snipResult.url);

                CheckIfUrlSnippableService.checkIfUrlSnippableCreate({
                    requestBody: { url: snipResult.url }
                })
                    .then((result) => {
                        console.log(
                            'CheckIfUrlSnippableService.checkIfUrlSnippableCreate()',
                            result
                        );

                        if (result.is_snippable) {
                            setSniplyContentUrl(result.url);
                        } else {
                            if (result.proxy_url) {
                                setSniplyContentUrl(result.proxy_url);
                            } else if (result.static_page_url) {
                                setSniplyContentUrl(result.static_page_url);
                            } else {
                                // Only truly unsnippable scenario to be displayed to users
                                setSniplyContentUrl(result.summary_url);
                            }
                        }

                        const urls = createSnipUrlsData(result);
                        setSnipUrls(urls);

                        setIsLoadingSnip(false);

                        if (!urls.dynamic) {
                            // If the dynamic option is not available prompt the content type selection
                            // i.e. offering static and summary. Otherwise go directly to CTA selection.
                            navigateTo(
                                `/snip-cta-add/select-content-type?snipId=${snipIdFromQueryParams}`
                            );
                        } else {
                            navigateTo(`/snip-cta-add/cta-select?snipId=${snipIdFromQueryParams}`);
                        }
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from CheckIfUrlSnippableService.checkIfUrlSnippableCreate(): ',
                            error
                        );
                    });
            })
            .catch((error) => {
                console.error(error);
                setIsLoadingSnip(false);
            });
    }, []);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    const isSideBarWidthEquivalentOfMobileScreenWidth =
        useElementWidth(sideBarRef) < MOBILE_VIEW_THRESHOLD;

    React.useEffect(() => {
        if (
            (level2 === 'cta-select' && level3 === 'new' && hasScreenSpaceForSideBar) ||
            (level2 === 'cta-select' && ctaName && hasScreenSpaceForSideBar)
        ) {
            setShowCtaSidePreview(true);
        } else {
            setShowCtaSidePreview(false);
        }
    }, [level2, level3, ctaName, hasScreenSpaceForSideBar]);

    return (
        <div className="h-full w-full flex text-grey">
            <div
                className={`h-full w-full ${
                    level2 === 'select-content-type'
                        ? 'sm:w-10/12'
                        : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                } bg-white border-r border-grey-lighter`}
            >
                <div
                    className={`absolute z-2 bg-white w-full ${
                        level2 === 'select-content-type'
                            ? 'sm:w-10/12'
                            : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                    } p-4 grid grid-cols-3 items-start`}
                >
                    <ButtonBack onClick={() => historyBackWithFallback('/')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar && level2 === 'cta-select' ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href="/">
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                    {isLoadingSnip ? (
                        <div className="h-full w-full flex items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : level2 === 'select-content-type' ? (
                        <PrepareContentStep
                            onContinueClick={() => navigateTo('/snip-cta-add/cta-select')}
                            snipUrls={snipUrls}
                            sniplyContentUrl={sniplyContentUrl}
                            setSniplyContentUrl={setSniplyContentUrl}
                        />
                    ) : level2 === 'cta-select' ? (
                        <SnipCtaAddToRedirectCtaSelect
                            snipId={snipId}
                            activeMobileViewTab={mobileConfigurePreviewActiveTab}
                            onSelectCta={handleSelectCta}
                            resetCtaConfiguration={handleResetCtaConfiguration}
                            callToActionUrl={callToActionUrl}
                            setCallToActionUrl={setCallToActionUrl}
                            sniplyContentUrl={sniplyContentUrl}
                            ctaId={ctaId}
                            setCtaId={setCtaId}
                            ctaName={ctaName}
                            setCtaName={setCtaName}
                            ctaType={ctaType}
                            setCtaType={setCtaType}
                            headlineText={headlineText}
                            setHeadlineText={setHeadlineText}
                            messageText={messageText}
                            setMessageText={setMessageText}
                            buttonText={buttonText}
                            setButtonText={setButtonText}
                            linkText={linkText}
                            setLinkText={setLinkText}
                            linkTextColor={linkTextColor}
                            setLinkTextColor={setLinkTextColor}
                            formButtonTextColor={formButtonTextColor}
                            setFormButtonTextColor={setFormButtonTextColor}
                            formButtonColor={formButtonColor}
                            setFormButtonColor={setFormButtonColor}
                            formPlaceholderText={formPlaceholderText}
                            setFormPlaceholderText={setFormPlaceholderText}
                            imageAdUrl={imageAdUrl}
                            setImageAdUrl={setImageAdUrl}
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            imagePreviewUrl={imagePreviewUrl}
                            setImagePreviewUrl={setImagePreviewUrl}
                            imageAdPreviewUrl={imageAdPreviewUrl}
                            setImageAdPreviewUrl={setImageAdPreviewUrl}
                            imageBlob={imageBlob}
                            setImageBlob={setImageBlob}
                            fileName={fileName}
                            setFileName={setFileName}
                            theme={theme}
                            setTheme={setTheme}
                            backgroundColor={backgroundColor}
                            setBackgroundColor={setBackgroundColor}
                            messageTextColor={messageTextColor}
                            setMessageTextColor={setMessageTextColor}
                            buttonColor={buttonColor}
                            setButtonColor={setButtonColor}
                            buttonTextColor={buttonTextColor}
                            setButtonTextColor={setButtonTextColor}
                            position={position}
                            setPosition={setPosition}
                            showSniplyLogo={showSniplyLogo}
                            setShowSniplyLogo={setShowSniplyLogo}
                            showWhiteSniplyLogo={showWhiteSniplyLogo}
                            setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                        />
                    ) : null}
                </div>
            </div>
            <div
                className={`h-full grow ${showCtaSidePreview ? 'bg-grey-lighter' : 'bg-primary'}`}
                ref={sideBarRef}
            >
                {showCtaSidePreview && (
                    <DemoContentIntegrator
                        contentUrl={sniplyContentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: ctaId,
                                    name: ctaName,
                                    type: ctaType,
                                    urlToBePromoted: ensureUrlHasProtocol(callToActionUrl),
                                    imageUrl: imagePreviewUrl || imageUrl,
                                    headlineText,
                                    messageText,
                                    messageTextColor,
                                    backgroundColor,
                                    buttonColor,
                                    buttonText,
                                    buttonTextColor,
                                    linkText,
                                    linkTextColor,
                                    formButtonColor,
                                    formButtonTextColor,
                                    formPlaceholderText,
                                    adImageUrl: imageAdPreviewUrl || imageAdUrl,
                                    position,
                                    theme,
                                    showSniplyLogo,
                                    showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={
                                    isSideBarWidthEquivalentOfMobileScreenWidth ||
                                    activePreviewView === 'mobile'
                                }
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                        previewViewSelectionTab={
                            hasScreenSpaceForSideBar && (
                                <PreviewViewSelectionTab
                                    activeTab={activePreviewView}
                                    setActiveTab={setActivePreviewView}
                                />
                            )
                        }
                        activePreviewView={activePreviewView}
                    />
                )}
            </div>
        </div>
    );
};
