/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Link } from '../models/Link';
import type { LinkRequest } from '../models/LinkRequest';
import type { PaginatedLinkList } from '../models/PaginatedLinkList';
import type { PatchedLinkRequest } from '../models/PatchedLinkRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LinksService {

    /**
     * Links are the most important part of Sniply, they are what users interact with the most.
     *
     * A Link is created by passing in a URL, Brand ID and CTA list, which can be empty for a simple redirect link.
     *
     * The API will return Sniply Link, which will show viewers the URL with the Call-to-Action associated with the CTA
     * overlay.
     * @returns PaginatedLinkList
     * @throws ApiError
     */
    public static linksList({
        archived,
        brand,
        label,
        ordering,
        page,
        pageSize,
        search,
    }: {
        archived?: boolean,
        brand?: string,
        label?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLinkList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/links/',
            query: {
                'archived': archived,
                'brand': brand,
                'label': label,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * Links are the most important part of Sniply, they are what users interact with the most.
     *
     * A Link is created by passing in a URL, Brand ID and CTA list, which can be empty for a simple redirect link.
     *
     * The API will return Sniply Link, which will show viewers the URL with the Call-to-Action associated with the CTA
     * overlay.
     * @returns Link
     * @throws ApiError
     */
    public static linksCreate({
        requestBody,
    }: {
        requestBody: LinkRequest,
    }): CancelablePromise<Link> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/links/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Link
     * @throws ApiError
     */
    public static linksRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Link> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/links/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Link
     * @throws ApiError
     */
    public static linksUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: LinkRequest,
    }): CancelablePromise<Link> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/links/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Link
     * @throws ApiError
     */
    public static linksPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedLinkRequest,
    }): CancelablePromise<Link> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/links/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
