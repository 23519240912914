import React from 'react';

import DropdownIcon from './assets/icons/dropdown-down.svg';
import { LoadingSpinner } from './components/LoadingSpinner';
import { CustomDomainsListItem } from './data-transformations/create-custom-domains-list';
import { NotificationContext } from './NotificationContextProvider';
import { navigateTo } from './Routing';

export function createDomainDropdownOptions(
    customDomainsData: CustomDomainsListItem[]
): CustomDomainsDropdownOption[] {
    return customDomainsData
        .filter((customDomain) => customDomain.isVerified)
        .map((customDomain) => ({
            id: customDomain.id,
            name: customDomain.domainName
        }));
}

interface DomainDropdownSelectProps {
    readonly options: CustomDomainsDropdownOption[];
    readonly isLoadingOptions: boolean;
    readonly defaultOption: CustomDomainsDropdownOption;
    readonly selectedOptionId: string;
    readonly onSelectOption: (id: string) => void;
    readonly showAddDomainButton?: boolean;
}

export interface CustomDomainsDropdownOption {
    readonly id: string;
    readonly name: string;
}

export const DomainDropdownSelect: React.FC<DomainDropdownSelectProps> = ({
    options,
    isLoadingOptions,
    defaultOption,
    selectedOptionId,
    onSelectOption,
    showAddDomainButton = true
}) => {
    const [open, setOpen] = React.useState(false);
    const dropdownContentRef = React.useRef(null);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const optionsForDisplay: CustomDomainsDropdownOption[] = [defaultOption, ...options];

    return (
        <div>
            <div
                className="relative py-2 px-4 h-full border w-full rounded-l-md border-grey-lighter bg-white"
                ref={dropdownContentRef}
            >
                <button
                    className={`flex w-full items-center justify-between text-sm h-6`}
                    onClick={(event) => {
                        event.preventDefault();

                        if (optionsForDisplay.length < 2) {
                            handleOpenNotification({
                                messageText:
                                    'You currently do not have any verified custom domains to select from.',
                                type: 'warning',
                                showTimeInSeconds: 3
                            });
                            return;
                        }

                        setOpen(open ? false : true);
                    }}
                >
                    <div className="flex items-center justify-between w-full pr-2 min-w-0">
                        {isLoadingOptions ? (
                            <div className="flex h-full w-full items-center justify-center">
                                <LoadingSpinner size={5} />
                            </div>
                        ) : (
                            <span className={`truncate font-normal`}>
                                {
                                    optionsForDisplay.find(
                                        (option) => option.id === selectedOptionId
                                    )?.name
                                }
                            </span>
                        )}
                    </div>
                    {open ? (
                        <div className="ml-2 rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="ml-2 h-2 w-2" src={DropdownIcon} />
                    )}
                </button>
                {open && (
                    <div className="absolute top-10 left-0 text-sm rounded-md shadow-md px-3 pb-2 w-48 bg-white border border-grey-lighter z-50  max-h-40 overflow-y-scroll">
                        <ul>
                            {optionsForDisplay
                                .filter((option) => option.id !== selectedOptionId)
                                .map((option) => (
                                    <li className="mt-2 rounded-md w-full min-w-0" key={option.id}>
                                        <button
                                            className="flex items-center justify-between w-full text-left min-w-0"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectOption(option.id);
                                                setOpen(false);
                                            }}
                                        >
                                            <span className="truncate">{option.name}</span>
                                        </button>
                                    </li>
                                ))}
                            {showAddDomainButton && (
                                <li className=" mt-2 text-grey-medium">
                                    <button
                                        onClick={() =>
                                            navigateTo('/customization/custom-domains/add-domain')
                                        }
                                    >
                                        + Add Custom Domain
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
