import React from 'react';

import EnterprisePlanIcon from '../assets/icons/enterprise-plan.svg';
import BasicPlanIcon from '../assets/icons/basic-plan.svg';
import BusinessPlanIcon from '../assets/icons/business-plan.svg';
import CalendarIcon from '../assets/icons/calendar.svg';
import ProPlanIcon from '../assets/icons/pro-plan.svg';
import { ContentElementContainer } from './ContentElementContainer';
import { LoadingSpinner } from './LoadingSpinner';
import { ProgressBar } from './ProgressBar';

function getProgressInPercentString(usedNumber: number, limitNumber: number): string {
    if (usedNumber >= limitNumber) {
        return '100%';
    }

    return `${Math.round((usedNumber / limitNumber) * 100)}%`;
}

interface UserPlanInformationProps {
    readonly userPlan: string;
    readonly isV3Plan: boolean;
    readonly isAppSumoPlan: boolean;
    readonly topSnipSlugForAppSumo: string | undefined;
    readonly isUserIsOnTrialPeriod: boolean;
    readonly planRenewalDate: string;
    readonly planCancelDate: string | null;
    readonly numberOfRemainingTrialDays?: number;
    readonly numberOfSnipsUsed: number;
    readonly numberOfTotalSnipsIncluded: number;
    readonly numberOfClicksUsed: number;
    readonly numberOfClicksUsedOnTopSnipForAppSumo: number | undefined;
    readonly numberOfTotalClicksIncluded: number;
    readonly numberOfCtasUsed: number;
    readonly numberOfTotalCtasIncluded: number;
    readonly numberOfMonthlyCtaImpressionsUsed: number;
    readonly numberOfTotalMonthlyCtaImpressionsIncluded: number;
    readonly numberOfTeammembersUsed: number;
    readonly numberOfTotalTeammembersIncluded: number;
    readonly numberOfWorkspacesUsed: number;
    readonly numberOfTotalWorkspacesIncluded: number;
    readonly manageSubscriptionButton: JSX.Element;
    readonly cancelSubscriptionButton?: JSX.Element;
}

export const UserPlanInformation: React.FC<UserPlanInformationProps> = ({
    userPlan,
    isV3Plan,
    isAppSumoPlan,
    topSnipSlugForAppSumo,
    planRenewalDate,
    planCancelDate,
    isUserIsOnTrialPeriod,
    numberOfRemainingTrialDays,
    numberOfSnipsUsed,
    numberOfTotalSnipsIncluded,
    numberOfClicksUsed,
    numberOfClicksUsedOnTopSnipForAppSumo,
    numberOfTotalClicksIncluded,
    numberOfCtasUsed,
    numberOfTotalCtasIncluded,
    numberOfMonthlyCtaImpressionsUsed,
    numberOfTotalMonthlyCtaImpressionsIncluded,
    numberOfTeammembersUsed,
    numberOfTotalTeammembersIncluded,
    numberOfWorkspacesUsed,
    numberOfTotalWorkspacesIncluded,
    manageSubscriptionButton,
    cancelSubscriptionButton
}) => {
    console.log(planCancelDate);
    return (
        <ContentElementContainer>
            {!userPlan ? (
                <div className="w-full h-full flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <div className="grid grid-cols-1">
                    <div className="flex flex-col">
                        <div className="flex w-full items-start justify-between">
                            <h4 className="text-base text-grey font-semibold">Usage</h4>
                            <div className="flex items-center">
                                {manageSubscriptionButton}
                                {cancelSubscriptionButton && (
                                    <span className="ml-3">{cancelSubscriptionButton}</span>
                                )}
                            </div>
                        </div>
                        <div className="mt-2 flex items-center flex-wrap">
                            <div className="mt-4 flex items-center mr-8">
                                <div className="flex items-center justify-center bg-grey-lighter h-8 w-8 sm:h-10 sm:w-10 rounded-full">
                                    <img
                                        src={
                                            userPlan === 'Basic'
                                                ? BasicPlanIcon
                                                : userPlan === 'Pro'
                                                ? ProPlanIcon
                                                : userPlan === 'Business'
                                                ? BusinessPlanIcon
                                                : EnterprisePlanIcon
                                        }
                                        alt="plan icon"
                                        className="w-4 h-4 sm:w-5 sm:h-5"
                                    />
                                </div>
                                <div className="ml-4 flex flex-col">
                                    <span className="text-base sm:text-lg text-grey font-semibold">
                                        {isAppSumoPlan ? 'Special Offer' : userPlan}
                                    </span>
                                    <span className="text-xs sm:text-sm text-grey-light">Plan</span>
                                </div>
                            </div>
                            <div className="mt-4 flex items-center">
                                <div className="flex items-center justify-center bg-grey-lighter h-8 w-8 sm:h-10 sm:w-10 rounded-full">
                                    <img
                                        src={CalendarIcon}
                                        alt="plan icon"
                                        className="w-4 h-4 sm:w-5 sm:h-5"
                                    />
                                </div>
                                {isUserIsOnTrialPeriod ? (
                                    <div className="ml-4 flex flex-col">
                                        <span className="text-base sm:text-lg text-grey font-semibold">{`${numberOfRemainingTrialDays} ${
                                            numberOfRemainingTrialDays === 1 ? 'day' : 'days'
                                        } left`}</span>
                                        <span className="text-xs sm:text-sm text-red">
                                            FREE TRIAL
                                        </span>
                                    </div>
                                ) : isAppSumoPlan ? (
                                    <div className="ml-4 flex flex-col">
                                        <span className="text-base sm:text-lg text-grey font-semibold">
                                            Lifetime
                                        </span>
                                        <span className="text-xs sm:text-sm text-green">
                                            APPSUMO DEAL
                                        </span>
                                    </div>
                                ) : planCancelDate ? (
                                    <div className="ml-4 flex flex-col">
                                        <span className="text-base sm:text-lg text-red font-semibold">
                                            {planCancelDate}
                                        </span>
                                        <span className="text-xs sm:text-sm text-red">
                                            Plan Ending
                                        </span>
                                    </div>
                                ) : (
                                    planRenewalDate && (
                                        <div className="ml-4 flex flex-col">
                                            <span className="text-base sm:text-lg text-grey font-semibold">
                                                {planRenewalDate}
                                            </span>
                                            <span className="text-xs sm:text-sm text-grey-light">
                                                Renewal Date
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 w-full lg:w-2/3">
                        {isV3Plan ? (
                            <>
                                <div>
                                    <p className="text-sm mb-1">{`${numberOfCtasUsed} out of ${numberOfTotalCtasIncluded} Call-To-Actions (${getProgressInPercentString(
                                        numberOfCtasUsed,
                                        numberOfTotalCtasIncluded
                                    )} used)`}</p>
                                    <ProgressBar
                                        backgroundColor="#2681DB"
                                        progressInPercent={getProgressInPercentString(
                                            numberOfCtasUsed,
                                            numberOfTotalCtasIncluded
                                        )}
                                        heightInPx={6}
                                    />
                                </div>
                                <div className="mt-6">
                                    <p className="text-sm mb-1">{`${numberOfMonthlyCtaImpressionsUsed} out of ${numberOfTotalMonthlyCtaImpressionsIncluded} Monthly Call-To-Action Impressions (${getProgressInPercentString(
                                        numberOfMonthlyCtaImpressionsUsed,
                                        numberOfTotalMonthlyCtaImpressionsIncluded
                                    )} used)`}</p>
                                    <ProgressBar
                                        backgroundColor="#2681DB"
                                        progressInPercent={getProgressInPercentString(
                                            numberOfMonthlyCtaImpressionsUsed,
                                            numberOfTotalMonthlyCtaImpressionsIncluded
                                        )}
                                        heightInPx={6}
                                    />
                                </div>
                                {numberOfTotalTeammembersIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Team Members
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfTeammembersUsed} out of ${numberOfTotalTeammembersIncluded} Team Members (${getProgressInPercentString(
                                            numberOfTeammembersUsed,
                                            numberOfTotalTeammembersIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfTeammembersUsed,
                                                numberOfTotalTeammembersIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalWorkspacesIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Workspaces
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfWorkspacesUsed} out of ${numberOfTotalWorkspacesIncluded} Workspaces (${getProgressInPercentString(
                                            numberOfWorkspacesUsed,
                                            numberOfTotalWorkspacesIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfWorkspacesUsed,
                                                numberOfTotalWorkspacesIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                            </>
                        ) : isAppSumoPlan ? (
                            <>
                                <div className="text-sm text-grey-light">Unlimited Snips</div>
                                {numberOfTotalClicksIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Clicks
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfClicksUsedOnTopSnipForAppSumo} out of ${numberOfTotalClicksIncluded} Monthly Clicks (${getProgressInPercentString(
                                            numberOfClicksUsed,
                                            numberOfTotalClicksIncluded
                                        )} used on snip .../${topSnipSlugForAppSumo})`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfClicksUsed,
                                                numberOfTotalClicksIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalTeammembersIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Team Members
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfTeammembersUsed} out of ${numberOfTotalTeammembersIncluded} Team Members (${getProgressInPercentString(
                                            numberOfTeammembersUsed,
                                            numberOfTotalTeammembersIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfTeammembersUsed,
                                                numberOfTotalTeammembersIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalWorkspacesIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Workspaces
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfWorkspacesUsed} out of ${numberOfTotalWorkspacesIncluded} Workspaces (${getProgressInPercentString(
                                            numberOfWorkspacesUsed,
                                            numberOfTotalWorkspacesIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfWorkspacesUsed,
                                                numberOfTotalWorkspacesIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {numberOfTotalSnipsIncluded === 999999999 ? (
                                    <div className="text-sm text-grey-light">Unlimited Snips</div>
                                ) : (
                                    <div>
                                        <p className="text-sm mb-1">{`${numberOfSnipsUsed} out of ${numberOfTotalSnipsIncluded} Snips (${getProgressInPercentString(
                                            numberOfSnipsUsed,
                                            numberOfTotalSnipsIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfSnipsUsed,
                                                numberOfTotalSnipsIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalClicksIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Clicks
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfClicksUsed} out of ${numberOfTotalClicksIncluded} Clicks (${getProgressInPercentString(
                                            numberOfClicksUsed,
                                            numberOfTotalClicksIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfClicksUsed,
                                                numberOfTotalClicksIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalTeammembersIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Team Members
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfTeammembersUsed} out of ${numberOfTotalTeammembersIncluded} Team Members (${getProgressInPercentString(
                                            numberOfTeammembersUsed,
                                            numberOfTotalTeammembersIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfTeammembersUsed,
                                                numberOfTotalTeammembersIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                                {numberOfTotalWorkspacesIncluded === 999999999 ? (
                                    <div className="mt-6 text-sm text-grey-light">
                                        Unlimited Workspaces
                                    </div>
                                ) : (
                                    <div className="mt-6">
                                        <p className="text-sm mb-1">{`${numberOfWorkspacesUsed} out of ${numberOfTotalWorkspacesIncluded} Workspaces (${getProgressInPercentString(
                                            numberOfWorkspacesUsed,
                                            numberOfTotalWorkspacesIncluded
                                        )} used)`}</p>
                                        <ProgressBar
                                            backgroundColor="#2681DB"
                                            progressInPercent={getProgressInPercentString(
                                                numberOfWorkspacesUsed,
                                                numberOfTotalWorkspacesIncluded
                                            )}
                                            heightInPx={6}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </ContentElementContainer>
    );
};
