import React from 'react';

interface BadgeProps {
    readonly displayText?: string;
}

export const ActiveBadge: React.FC<BadgeProps> = ({ displayText = 'Active' }) => (
    <span className="h-fit font-normal text-xs text-white px-2 py-1 rounded bg-green">
        {displayText}
    </span>
);

export const InActiveBadge: React.FC<BadgeProps> = ({ displayText = 'Inactive' }) => (
    <span className="h-fit font-normal text-xs text-grey px-2 py-1 rounded bg-grey-tint">
        {displayText}
    </span>
);

export const WarnInActiveBadge: React.FC<BadgeProps> = ({ displayText = 'Inactive' }) => (
    <span className="h-fit font-normal text-xs text-white px-2 py-1 rounded bg-red">
        {displayText}
    </span>
);
