import React from 'react';

import EnterprisePlanIcon from './assets/icons/enterprise-plan.svg';
import ProPlanIcon from './assets/icons/pro-plan.svg';
import BusinessPlanIcon from './assets/icons/business-plan.svg';
import { ButtonMain } from './components/ButtonMain';
import { navigateTo } from './Routing';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { UserPlan } from './UserContextProvider';
interface UpgradePlanMessageProps {
    readonly featureName: string;
    readonly titleText?: string;
    readonly messageText?: string;
    readonly minimumRequiredPlan: UserPlan;
    readonly handleCloseMessage: () => void;
}

const UpgradePlanMessage: React.FC<UpgradePlanMessageProps> = ({
    featureName,
    titleText,
    messageText,
    minimumRequiredPlan,
    handleCloseMessage
}) => {
    const [startFadeIn, setStartFadeIn] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleCloseMessage();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setStartFadeIn(true);
    }, []);

    const standardMessageText = `In order to use the ${featureName} feature, you need to change your plan. Wherever possible we will enable you to try out the feature, but be aware that we will reset your selection to default values unless you upgrade to the required plan.`;

    return (
        <div
            ref={containerRef}
            className={`absolute w-full top-0 left-0 text-sm rounded-md shadow-md p-4 bg-white border border-grey-lighter z-50 transition ease-in duration-150 ${
                startFadeIn ? 'translate-y-0' : '-translate-y-full'
            }`}
        >
            <div className="flex flex-wrap justify-center sm:grid sm:grid-cols-1-12-2 gap-x-2 gap-y-4 items-center">
                <div className="w-full h-full flex justify-center">
                    <img
                        src={
                            minimumRequiredPlan === 'Pro'
                                ? ProPlanIcon
                                : minimumRequiredPlan === 'Business'
                                ? BusinessPlanIcon
                                : EnterprisePlanIcon
                        }
                        alt="plan icon"
                        className="w-10 h-10 block"
                    />
                </div>
                <div className="ml-4 flex-grow p-2">
                    <h4 className="font-semibold">
                        {titleText ? (
                            <p>{titleText}</p>
                        ) : (
                            <>
                                <span>Upgrade to </span>
                                <span className="italic">{minimumRequiredPlan}</span>
                                <span> Plan</span>
                            </>
                        )}
                    </h4>
                    <p className="mt-2 text-sm text-grey-medium">
                        {messageText ? messageText : standardMessageText}
                    </p>
                </div>
                <div className="flex items-center justify-center w-full">
                    <ButtonMain
                        size="small"
                        width="44"
                        onClick={() => {
                            navigateTo('/plans');
                            handleCloseMessage();
                        }}
                    >
                        View all plans
                    </ButtonMain>
                </div>
            </div>
        </div>
    );
};

export const UpgradePlanMessageIntegration = () => {
    const {
        showMessage,
        featureName,
        titleText,
        messageText,
        minimumRequiredPlan,
        handleCloseUpgradePlanMessage
    } = React.useContext(UpgradePlanMessageContext);

    return !showMessage ? null : (
        <UpgradePlanMessage
            featureName={featureName}
            titleText={titleText}
            messageText={messageText}
            minimumRequiredPlan={minimumRequiredPlan}
            handleCloseMessage={handleCloseUpgradePlanMessage}
        />
    );
};
