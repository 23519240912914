import React from 'react';
import { CtaType } from '../CtaTypeDropdownSelect';

interface CtaIconByTypeProps {
    readonly ctaType: CtaType;
}

export const CtaIconByType: React.FC<CtaIconByTypeProps> = ({ ctaType }) => {
    switch (ctaType) {
        case 'text-link':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m17.657 14.828-1.414-1.414L17.657 12A4.001 4.001 0 0 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414Zm-2.83 2.83-1.413 1.413a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414Zm0-9.9 1.416 1.414-7.071 7.07-1.415-1.414 7.07-7.07ZM5.776 2.292l1.932-.518L8.742 5.64l-1.931.518-1.036-3.864Zm9.483 16.068 1.93-.518 1.037 3.864-1.932.518-1.035-3.864ZM2.293 5.775l3.864 1.036-.518 1.931-3.864-1.035.518-1.932Zm16.068 9.483 3.864 1.035-.518 1.932-3.864-1.036.518-1.93Z"
                        fill="#2681DB"
                    />
                </svg>
            );
        case 'form':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007ZM4.434 5 12 11.81 19.566 5H4.434ZM0 15h8v2H0v-2Zm0-5h5v2H0v-2Z"
                        fill="#2681DB"
                    />
                </svg>
            );
        case 'image-ad':
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2Zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016ZM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
                        fill="#2681DB"
                    />
                </svg>
            );
        default:
            return (
                <svg
                    width="22"
                    height="13"
                    viewBox="0 0 22 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="1" y="1" width="20" height="11" rx="1" stroke="#2681DB" />
                </svg>
            );
    }
};
