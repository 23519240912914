import React from 'react';
import Pie from './Pie';

import DesktopIcon from '../assets/icons/desktop.svg';
import TabletIcon from '../assets/icons/tablet.svg';
import MobileIcon from '../assets/icons/mobile.svg';

interface TrafficByDevice {
    readonly displayName: string;
    readonly displayPercentageValue: number;
}

export interface TrafficByDevicesData {
    readonly desktop: TrafficByDevice;
    readonly tablet: TrafficByDevice;
    readonly mobile: TrafficByDevice;
}
interface TrafficByDeviceProps {
    readonly data: TrafficByDevicesData;
}

export const TrafficByDevice: React.FC<TrafficByDeviceProps> = ({ data }) => {
    if (!data) {
        return null;
    }

    return (
        <div>
            <div className="flex justify-center">
                <Pie
                    percentage1={data.desktop.displayPercentageValue}
                    colour="#2681DB"
                    percentage2={data.tablet.displayPercentageValue}
                    percentage3={data.mobile.displayPercentageValue}
                />
            </div>

            <div className="flex flex-row justify-between pt-5 px-4">
                <div className="flex flex-col w-1/4 items-center">
                    <img className="h-6 w-6" src={DesktopIcon} />
                    <span className="h-fit text-grey text-sm font-normal py-2">
                        {data.desktop.displayName}
                    </span>
                    <span className="h-fit text-grey text-xl font-semibold">
                        {data.desktop.displayPercentageValue}%
                    </span>
                </div>
                <div className="flex flex-col w-1/4 items-center">
                    <img className="h-6 w-6" src={TabletIcon} />
                    <span className="h-fit text-grey text-sm font-normal py-2">
                        {data.tablet.displayName}
                    </span>
                    <span className="h-fit text-grey text-xl font-semibold">
                        {data.tablet.displayPercentageValue}%
                    </span>
                </div>
                <div className="flex flex-col w-1/4 items-center">
                    <img className="h-6 w-6" src={MobileIcon} />
                    <span className="h-fit text-grey text-sm font-normal py-2">
                        {data.mobile.displayName}
                    </span>
                    <span className="h-fit text-grey text-xl font-semibold">
                        {data.mobile.displayPercentageValue}%
                    </span>
                </div>
            </div>
        </div>
    );
};
