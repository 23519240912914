import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import ArrowIcon from '../assets/icons/arrow.svg';

interface EndlessScrollContainerProps {
    readonly showLoadMoreAtAll: boolean;
    readonly onReachedScrollBottom: () => void;
    readonly onLoadMoreClick: () => void;
    readonly isLoadingMoreData: boolean;
    readonly hasMoreResults: boolean;
    readonly height?: string;
    readonly children: React.ReactNode;
}

export const EndlessScrollContainer: React.FC<EndlessScrollContainerProps> = ({
    showLoadMoreAtAll,
    onReachedScrollBottom,
    onLoadMoreClick,
    height = 'h-screen-50 sm:h-screen-70',
    isLoadingMoreData,
    hasMoreResults,
    children
}) => {
    const containerRef = React.useRef<HTMLDivElement>(undefined);

    const handleScroll = React.useCallback(() => {
        if (containerRef && containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

            if (scrollTop + clientHeight === scrollHeight) {
                onReachedScrollBottom();
            }
        }
    }, [containerRef, onReachedScrollBottom]);

    return (
        <div
            ref={containerRef}
            onScroll={handleScroll}
            className={`w-full ${height ? height : ''} overflow-y-scroll`}
        >
            {children}
            {showLoadMoreAtAll && (
                <div className="flex w-full h-10 items-center justify-center">
                    {isLoadingMoreData && hasMoreResults && <LoadingSpinner size={5} />}
                    {!isLoadingMoreData && hasMoreResults && (
                        <button
                            className="flex items-center border border-grey-light rounded-full py-1 px-2"
                            onClick={onLoadMoreClick}
                        >
                            <img
                                src={ArrowIcon}
                                alt="arrow down icon"
                                className="h-4 w-4 -rotate-90"
                            />
                            <span className="ml-2 text-sm">Load More</span>
                        </button>
                    )}
                    {!hasMoreResults && (
                        <p className="text-sm text-grey-light">No more results to load.</p>
                    )}
                </div>
            )}
        </div>
    );
};
