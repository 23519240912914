import React from 'react';

import CloseIcon from '../assets/icons/close.svg';
import LockIcon from '../assets/icons/lock.svg';
import { CTARequest, CtasService, LinksService, TeamService } from '../api';
import ButtonBack from '../components/ButtonBack';
import { ButtonMain } from '../components/ButtonMain';
import { CtaType } from '../components/CtaTypeDropdownSelect';
import { FormStepLayout } from '../components/FormStepLayout';
import { FormTextInput } from '../components/FormTextInput';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { VerticalSpacer } from '../components/VerticalSpacer';
import { ContentIntegrator } from '../cta-components/ContentIntegrator';
import { CtaCreator } from '../CtaCreator';
import { CtaSelection } from '../CtaSelection';
import {
    createCtaData,
    CtaPosition,
    CtasListItem,
    CtaTheme
} from '../data-transformations/create-cta-data';
import { createCtaLinkList } from '../data-transformations/create-cta-link-list';
import { useHasSpaceForCtaSidePreview } from '../hooks/use-has-space-for-cta-side-preview';
import { MOBILE_VIEW_THRESHOLD, useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';
import { navigateTo, RouteLink, useCurrentUrlPath } from '../Routing';
import { createUpdatedCtaIds } from '../utils/create-updated-cta-ids';
import { getPositionForApiCall } from '../utils/get-position-for-api-call';
import { WorkspaceContext } from '../WorkspaceContextProvider';
import { CtaVariant } from '../SnipsSnipContent';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../components/ConfigPreviewTab';
import { LegacyCta } from '../cta-components/LegacyCta';
import { useElementWidth } from '../hooks/use-element-width';
import {
    DemoContentIntegrator,
    DemoView,
    PreviewViewSelectionTab
} from '../components/DemoContentIntegrator';
import { isValidUrlWithProtocol } from '../utils/is-valid-url';
import { ensureUrlHasProtocol } from '../utils/ensure-url-has-protocol';
import { UserContext } from '../UserContextProvider';
import { UpgradePlanMessageContext } from '../UpgradePlanMessageContextProvider';
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_CTA_POSITION,
    DEFAULT_FORM_CTA_PLACEHOLDER_TEXT,
    DEFAULT_LINK_TEXT_COLOR,
    DEFAULT_MESSAGE_TEXT_COLOR
} from '../utils/default-cta-configuration';
import { hasHitPlanLimitForCtas } from '../utils/has-hit-plan-limit-for-ctas';
import { NotificationContext } from '../NotificationContextProvider';
import { historyBackWithFallback } from '../utils/history-back-with-fallback';
import { getNextHigherPlan } from '../utils/get-next-higher-plan';

export const SnipCtaChange = () => {
    const [snipId, setSnipId] = React.useState('');
    const [variant, setVariant] = React.useState<CtaVariant>('A');
    const [initiallySelectedCta, setInitiallySelectedCta] = React.useState<CtasListItem>();

    const [ctaIds, setCtaIds] = React.useState<string[]>([]);

    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    const [showNoCtaLinkUrlError, setShowNoCtaLinkUrlError] = React.useState(false);
    const noCtaLinkUrlErrorRef = React.useRef(null);

    const errorMessageRef = React.useRef(null);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    // Form Values --- Start
    const [callToActionUrl, setCallToActionUrl] = React.useState('');
    const [contentUrl, setContentUrl] = React.useState('');

    const [ctaId, setCtaId] = React.useState('');
    const [ctaName, setCtaName] = React.useState('');
    const [ctaType, setCtaType] = React.useState<CtaType>('button');

    const [headlineText, setHeadlineText] = React.useState('Your Message');
    const [imageUrl, setImageUrl] = React.useState<string>('');

    const [messageText, setMessageText] = React.useState('Your Message');
    const [messageTextColor, setMessageTextColor] = React.useState(DEFAULT_MESSAGE_TEXT_COLOR);

    const [buttonText, setButtonText] = React.useState('Click here');
    const [buttonTextColor, setButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);

    const [linkText, setLinkText] = React.useState('Click here');
    const [linkTextColor, setLinkTextColor] = React.useState(DEFAULT_LINK_TEXT_COLOR);

    const [backgroundColor, setBackgroundColor] = React.useState(DEFAULT_BACKGROUND_COLOR);
    const [buttonColor, setButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);

    const [formButtonTextColor, setFormButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);
    const [formButtonColor, setFormButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);
    const [formPlaceholderText, setFormPlaceholderText] = React.useState(
        DEFAULT_FORM_CTA_PLACEHOLDER_TEXT
    );

    const [imageAdUrl, setImageAdUrl] = React.useState('');

    const [theme, setTheme] = React.useState<CtaTheme>('social');
    const [position, setPosition] = React.useState<CtaPosition>('bottom-left');

    const [showSniplyLogo, setShowSniplyLogo] = React.useState(true);
    const [showWhiteSniplyLogo, setShowWhiteSniplyLogo] = React.useState(false);

    const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>(undefined);
    const [imageAdPreviewUrl, setImageAdPreviewUrl] = React.useState<string>(undefined);

    // Form Values --- End
    //---

    const [isLoadingCta, setIsLoadingCta] = React.useState(false);
    const [isUpdatingSnip, setIsUpdatingSnip] = React.useState(false);

    const [reachedPlanLimitForCtas, setReachedPlanLimitForCtas] = React.useState(false);
    const [isCheckingPlanCtaLimit, setIsCheckingPlanCtaLimit] = React.useState(true);

    const { userPlan } = React.useContext(UserContext);
    const { level2 } = useCurrentUrlPath();
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const {
        canUseImageAdCta,
        minimumImageAdPlan,
        canUseCtaColors,
        canUseCtaPositioning,
        canHideSniplyLogo
    } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleSelectCta = React.useCallback((cta: CtasListItem) => {
        setCtaId(cta.id);
        setCallToActionUrl(cta.urlToBePromoted);
        setCtaName(cta.name);
        setCtaType(cta.type);
        setImageUrl(cta.imageUrl);
        setHeadlineText(cta.headlineText);
        setMessageText(cta.messageText);
        setMessageTextColor(cta.messageTextColor);
        setBackgroundColor(cta.backgroundColor);
        setButtonColor(cta.buttonColor);
        setButtonText(cta.buttonText);
        setButtonTextColor(cta.buttonTextColor);
        setLinkText(cta.linkText);
        setLinkTextColor(cta.linkTextColor);
        setFormButtonTextColor(cta.formButtonTextColor);
        setFormButtonColor(cta.formButtonColor);
        setFormPlaceholderText(cta.formPlaceholderText);
        setImageAdUrl(cta.adImageUrl);
        setPosition(cta.position);
        setTheme(cta.theme);
        setShowSniplyLogo(cta.showSniplyLogo);
        setShowWhiteSniplyLogo(cta.showWhiteSniplyLogo);
    }, []);

    const handleCreateCtaThenSnip = React.useCallback(() => {
        const requestObject: CTARequest =
            ctaType === 'button'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: buttonText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          background_color: canUseCtaColors ? buttonColor : DEFAULT_BUTTON_COLOR,
                          is_text: false,
                          text_color: canUseCtaColors ? buttonTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'text-link'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: linkText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          is_text: true,
                          text_color: canUseCtaColors ? linkTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'form'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      form_action: {
                          placeholder_text: canUseCtaColors
                              ? formPlaceholderText
                              : DEFAULT_MESSAGE_TEXT_COLOR,
                          button_background_color: canUseCtaColors
                              ? buttonColor
                              : DEFAULT_BUTTON_COLOR,
                          button_text_color: canUseCtaColors
                              ? buttonTextColor
                              : DEFAULT_BUTTON_TEXT_COLOR,
                          submissions: [] // Does nothing at creation but required prop
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : // ctaType === 'image-ad'
                  {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      image_action: {
                          image_url: imageAdUrl,
                          url: ensureUrlHasProtocol(callToActionUrl)
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true
                  };

        setIsUpdatingSnip(true);

        CtasService.ctasCreate({ requestBody: requestObject })
            .then((newCtaResult) => {
                console.log('CtasService.ctasCreate()', newCtaResult);

                LinksService.linksCreate({
                    requestBody: {
                        brand: currentWorkspace.id,
                        url: contentUrl,
                        cta_ids: createUpdatedCtaIds(newCtaResult.id, variant, ctaIds)
                    }
                })
                    .then((snipResult) => {
                        console.log('LinksService.linksCreate()', snipResult);

                        navigateTo(`/snips/${snipResult.id}`);
                        setIsUpdatingSnip(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error creating a new Snip via LinksService.linksCreate()',
                            error
                        );

                        handleOpenNotification({
                            type: 'warning',
                            messageText:
                                'Something unexpected went wrong when trying to save your Snip. Please try again.',
                            hasCloseButton: true
                        });

                        setIsUpdatingSnip(false);
                    });
            })
            .catch((error) => {
                console.error('Error posting to CtasService.ctasCreate()', error);

                handleOpenNotification({
                    type: 'warning',
                    messageText:
                        'Something unexpected went wrong when trying to save your Snip. Please try again.',
                    hasCloseButton: true
                });

                setIsUpdatingSnip(false);
            });
    }, [
        ctaIds,
        variant,
        currentWorkspace,
        callToActionUrl,
        contentUrl,
        ctaType,
        ctaName,
        imageUrl,
        imageAdUrl,
        headlineText,
        theme,
        messageText,
        messageTextColor,
        backgroundColor,
        buttonText,
        buttonTextColor,
        linkText,
        linkTextColor,
        buttonColor,
        formPlaceholderText,
        position,
        showSniplyLogo,
        canHideSniplyLogo,
        canUseCtaColors,
        canUseCtaPositioning,
        handleOpenNotification
    ]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (ctaType === 'image-ad' && !canUseImageAdCta) {
                handleOpenUpgradePlanMessage({
                    feature: 'Image Call-To-Action',
                    requiredPlan: minimumImageAdPlan
                });

                return;
            }

            if (level2 === 'new') {
                if (
                    (!callToActionUrl && ctaType !== 'form') ||
                    (!isValidUrlWithProtocol(ensureUrlHasProtocol(callToActionUrl)) &&
                        ctaType !== 'form')
                ) {
                    setShowNoCtaLinkUrlError(true);
                    noCtaLinkUrlErrorRef?.current?.scrollIntoView();
                } else if (!messageText && ctaType !== 'image-ad') {
                    setErrorMessage('Please provide a message text.');
                    errorMessageRef?.current?.scrollIntoView();
                } else if (ctaType === 'button' && !buttonText) {
                    setErrorMessage('Please provide a button text.');
                    errorMessageRef?.current?.scrollIntoView();
                } else if (ctaType === 'text-link' && !linkText) {
                    setErrorMessage('Please provide a link text.');
                    errorMessageRef?.current?.scrollIntoView();
                } else if (ctaType === 'form' && !formPlaceholderText) {
                    setErrorMessage('Please provide a placeholder text.');
                    errorMessageRef?.current?.scrollIntoView();
                } else {
                    handleCreateCtaThenSnip();
                }
            } else {
                LinksService.linksPartialUpdate({
                    id: snipId,
                    requestBody: {
                        cta_ids: createUpdatedCtaIds(ctaId, variant, ctaIds)
                    }
                })
                    .then(() => {
                        navigateTo(`/snips/${snipId}`);
                    })
                    .catch((error) => {
                        console.error('Error posting to LinksService.linksPartialUpdate()', error);
                        handleOpenNotification({
                            type: 'warning',
                            messageText:
                                'Something unexpected went wrong when trying to save your Snip. Please try again.',
                            hasCloseButton: true
                        });
                    });
            }
        },
        [
            snipId,
            level2,
            ctaId,
            variant,
            ctaIds,
            handleCreateCtaThenSnip,
            callToActionUrl,
            ctaType,
            canUseImageAdCta,
            minimumImageAdPlan,
            handleOpenUpgradePlanMessage,
            handleOpenNotification,
            messageText,
            buttonText,
            linkText,
            formPlaceholderText
        ]
    );

    const handleResetCtaConfiguration = React.useCallback(() => {
        setCallToActionUrl('');

        setCtaId('');
        setCtaName('');
        setCtaType('button');

        setImageUrl(undefined);
        setHeadlineText('Your Headline');

        setMessageText('Your Message');
        setMessageTextColor(DEFAULT_MESSAGE_TEXT_COLOR);

        setButtonText('Click here');
        setButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);

        setLinkText('Click here');
        setLinkTextColor(DEFAULT_LINK_TEXT_COLOR);

        setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
        setButtonColor(DEFAULT_BUTTON_COLOR);

        setFormButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);
        setFormButtonColor(DEFAULT_BUTTON_COLOR);
        setFormPlaceholderText(DEFAULT_FORM_CTA_PLACEHOLDER_TEXT);

        setImageAdUrl('');
        setTheme('social');
        setPosition('bottom-left');

        setShowSniplyLogo(true);
        setShowWhiteSniplyLogo(false);
    }, []);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const variationFromQueryParams = searchParams.get('variant');

        if (variationFromQueryParams) {
            setVariant(variationFromQueryParams as CtaVariant);
        }

        const snipIdFromQueryParams = searchParams.get('snipId');

        if (snipIdFromQueryParams) {
            setSnipId(snipIdFromQueryParams);

            setIsLoadingCta(true);

            LinksService.linksRetrieve({ id: snipIdFromQueryParams }).then((snipResult) => {
                setContentUrl(snipResult.url);
                setCtaIds(snipResult.cta_ids);

                const cta =
                    snipResult.cta_objects?.[variant] &&
                    createCtaData(snipResult.cta_objects[variant]);

                if (cta) {
                    setInitiallySelectedCta(cta);
                    setCtaId(cta.id);
                    setCtaName(cta.name);
                    setCallToActionUrl(cta.urlToBePromoted);
                    setCtaType(cta.type);
                    setMessageText(cta.messageText);
                    setMessageTextColor(cta.messageTextColor);
                    setBackgroundColor(cta.backgroundColor);
                    setButtonColor(cta.buttonColor);
                    setButtonText(cta.buttonText);
                    setButtonTextColor(cta.buttonTextColor);
                    setLinkText(cta.linkText);
                    setLinkTextColor(cta.linkTextColor);
                    setFormButtonTextColor(cta.formButtonTextColor);
                    setFormButtonColor(cta.formButtonColor);
                    setFormPlaceholderText(cta.formPlaceholderText);
                    setImageAdUrl(cta.adImageUrl);
                    setPosition(cta.position);
                    setTheme(cta.theme);
                    setShowSniplyLogo(cta.showSniplyLogo);
                }

                setIsLoadingCta(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    const isSideBarWidthEquivalentOfMobileScreenWidth =
        useElementWidth(sideBarRef) < MOBILE_VIEW_THRESHOLD;

    const [ctaLinkSuggestions, setCtaLinkSuggestions] = React.useState<string[]>([]);
    const [filteredCtaLinkSuggestions, setFilteredCtaLinkSuggestions] = React.useState<string[]>(
        []
    );

    React.useEffect(() => {
        CtasService.ctasList({ brand: currentWorkspace?.id, page: 1, pageSize: 10 }).then(
            (result) => {
                console.log(result);
                setCtaLinkSuggestions(createCtaLinkList(result));
                setFilteredCtaLinkSuggestions(createCtaLinkList(result));
            }
        );
    }, [currentWorkspace]);

    React.useEffect(() => {
        setShowNoCtaLinkUrlError(false);
    }, [callToActionUrl]);

    React.useEffect(() => {
        TeamService.teamLimitsAndBillingRetrieve()
            .then((result) => {
                setReachedPlanLimitForCtas(
                    hasHitPlanLimitForCtas(result.number_of_used_ctas, result.cta_limit)
                );

                setIsCheckingPlanCtaLimit(false);
            })
            .catch((error) => {
                console.error(
                    'Error fetching from TeamService.teamLimitsAndBillingRetrieve()',
                    error
                );

                setIsCheckingPlanCtaLimit(false);
            });
    }, []);

    // Reset error states
    React.useEffect(() => {
        setShowNoCtaLinkUrlError(false);
    }, [callToActionUrl]);

    React.useEffect(() => {
        setErrorMessage(undefined);
    }, [messageText, buttonColor, linkText, formPlaceholderText, ctaType]);

    const isMobileScreen = useIsMobileScreenSize();

    const hideThisCtaIdInCtaSelection =
        variant === 'B' ? ctaIds[0] : variant === 'A' && ctaIds.length > 1 ? ctaIds[1] : '';

    return (
        <div className="h-full w-full flex text-grey">
            <div className="h-full w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 bg-white border-r border-grey-lighter">
                <div className="absolute z-2 bg-white w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 p-4  grid grid-cols-3 items-start">
                    <ButtonBack onClick={() => historyBackWithFallback('/snips')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href={`/snips/${snipId}`}>
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                    <FormStepLayout
                        title={
                            isLoadingCta
                                ? null
                                : ctaIds.length === 0 || (ctaIds.length === 1 && variant === 'B')
                                ? 'Add Call-To-Action'
                                : 'Edit Call-To-Action'
                        }
                        step="1-of-1"
                        buttons={
                            <ButtonMain type="submit" width="full">
                                Save
                            </ButtonMain>
                        }
                        onSubmit={handleSubmit}
                    >
                        {isLoadingCta ? (
                            <div className="h-full w-full flex items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : isUpdatingSnip ? (
                            <div className="flex w-full h-full items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : level2 === 'new' ? (
                            <>
                                <FormTextInput
                                    labelText="Name Your Call-To-Action"
                                    id="cta-input"
                                    placeholder="Enter a name for your call-to-action"
                                    value={ctaName}
                                    onChange={(event) => setCtaName(event.target.value)}
                                    autoFocus
                                />
                                <VerticalSpacer heightValue={10} />
                                {ctaType !== 'form' ? (
                                    <>
                                        <div className="relative z-1">
                                            <FormTextInput
                                                id="cta-link-input"
                                                labelText="Call-To-Action Link"
                                                explanation={
                                                    <p className="mt-1 text-xs text-grey-light">
                                                        (This is where users will be sent when they
                                                        click your Call-To-Action.)
                                                    </p>
                                                }
                                                placeholder="mywebsite.com"
                                                value={callToActionUrl}
                                                onChange={(event) => {
                                                    setCallToActionUrl(event.target.value);
                                                    setFilteredCtaLinkSuggestions(
                                                        ctaLinkSuggestions.filter((suggestion) =>
                                                            suggestion.includes(callToActionUrl)
                                                        )
                                                    );
                                                }}
                                                disableAutocomplete
                                            />
                                        </div>
                                        {filteredCtaLinkSuggestions.length > 0 &&
                                            !filteredCtaLinkSuggestions.includes(
                                                callToActionUrl
                                            ) && (
                                                <div className="mx-2 p-4 grid gap-3 bg-grey-tint border border-grey-lighter rounded-md -translate-y-1">
                                                    <h4 className="text-xs font-semibold">
                                                        {!callToActionUrl
                                                            ? 'LAST USED:'
                                                            : 'SUGGESTIONS'}
                                                    </h4>

                                                    <ul className="mt-2 text-xs grid grid-cols-1 gap-1">
                                                        {filteredCtaLinkSuggestions
                                                            .slice(0, 3)
                                                            .map((suggestion) => (
                                                                <li
                                                                    className="p-1 rounded-md bg-white hover:underline"
                                                                    key={suggestion}
                                                                >
                                                                    <button
                                                                        className="w-full text-left"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            setCallToActionUrl(
                                                                                suggestion
                                                                            );
                                                                        }}
                                                                    >
                                                                        {suggestion}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            )}
                                        <div ref={noCtaLinkUrlErrorRef}>
                                            {showNoCtaLinkUrlError && (
                                                <p className="text-xs mt-2 text-red">
                                                    Please provide a valid Call-To-Action Link Url
                                                    before proceeding.
                                                </p>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                <VerticalSpacer heightValue={10} />
                                <CtaCreator
                                    ctaName={ctaName}
                                    setCtaName={setCtaName}
                                    ctaType={ctaType}
                                    setCtaType={setCtaType}
                                    headlineText={headlineText}
                                    setHeadlineText={setHeadlineText}
                                    messageText={messageText}
                                    setMessageText={setMessageText}
                                    buttonText={buttonText}
                                    setButtonText={setButtonText}
                                    linkText={linkText}
                                    setLinkText={setLinkText}
                                    linkTextColor={linkTextColor}
                                    setLinkTextColor={setLinkTextColor}
                                    formButtonTextColor={formButtonTextColor}
                                    setFormButtonTextColor={setFormButtonTextColor}
                                    formButtonColor={formButtonColor}
                                    setFormButtonColor={setFormButtonColor}
                                    formPlaceholderText={formPlaceholderText}
                                    setFormPlaceholderText={setFormPlaceholderText}
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    imageAdUrl={imageAdUrl}
                                    setImageAdUrl={setImageAdUrl}
                                    setImagePreviewUrl={setImagePreviewUrl}
                                    setImageAdPreviewUrl={setImageAdPreviewUrl}
                                    theme={theme}
                                    setTheme={setTheme}
                                    backgroundColor={backgroundColor}
                                    setBackgroundColor={setBackgroundColor}
                                    messageTextColor={messageTextColor}
                                    setMessageTextColor={setMessageTextColor}
                                    buttonColor={buttonColor}
                                    setButtonColor={setButtonColor}
                                    buttonTextColor={buttonTextColor}
                                    setButtonTextColor={setButtonTextColor}
                                    position={position}
                                    setPosition={setPosition}
                                    showSniplyLogo={showSniplyLogo}
                                    setShowSniplyLogo={setShowSniplyLogo}
                                    showWhiteSniplyLogo={showWhiteSniplyLogo}
                                    setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                                />
                                <div className="h-5" ref={errorMessageRef}>
                                    {errorMessage && (
                                        <p className="text-xs text-red">{errorMessage} </p>
                                    )}
                                </div>
                            </>
                        ) : mobileConfigurePreviewActiveTab === 'preview' ? (
                            <div className="w-full h-full border border-grey-light rounded-sm">
                                <ContentIntegrator
                                    contentUrl={contentUrl}
                                    cta={
                                        <LegacyCta
                                            configuration={{
                                                id: ctaId,
                                                name: ctaName,
                                                type: ctaType,
                                                urlToBePromoted:
                                                    ensureUrlHasProtocol(callToActionUrl),
                                                imageUrl,
                                                headlineText,
                                                messageText,
                                                messageTextColor,
                                                backgroundColor,
                                                buttonColor,
                                                buttonText,
                                                buttonTextColor,
                                                linkText,
                                                linkTextColor,
                                                formButtonColor,
                                                formButtonTextColor,
                                                formPlaceholderText,
                                                adImageUrl: imageAdUrl,
                                                position,
                                                theme,
                                                showSniplyLogo,
                                                showWhiteSniplyLogo
                                            }}
                                            disclaimerHref="https://snip.ly/whats-this2"
                                            isDemoMobileScreen={true}
                                            onConvertClick={() =>
                                                console.log('handle CTA Conversion')
                                            }
                                            onSubmitFormInputValue={() =>
                                                console.log('handle Form Submit')
                                            }
                                        />
                                    }
                                />
                            </div>
                        ) : (
                            <div>
                                <p className="text-sm">
                                    Using a Call-to-Action you can drive traffic from the Content
                                    you are sharing to another Destination like your own product or
                                    service.
                                </p>
                                <VerticalSpacer heightValue={10} />
                                <CtaSelection
                                    initiallySelectedCta={initiallySelectedCta}
                                    selectedCtaId={ctaId}
                                    onSelectCta={handleSelectCta}
                                    hideThisCtaId={hideThisCtaIdInCtaSelection}
                                    createNewButton={
                                        isCheckingPlanCtaLimit ? null : (
                                            <ButtonMain
                                                style="neutral"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    if (reachedPlanLimitForCtas) {
                                                        handleOpenUpgradePlanMessage({
                                                            feature: 'Add Call-To-Action',
                                                            requiredPlan:
                                                                getNextHigherPlan(userPlan),
                                                            message:
                                                                'You have reached the maximum amount of Call-To-Actions for your current plan. To add another CTA you will have to upgrade your plan.'
                                                        });
                                                    } else {
                                                        navigateTo(
                                                            `/snip-cta-change/new?snipId=${snipId}&variant=${variant}`
                                                        );
                                                        handleResetCtaConfiguration();
                                                    }
                                                }}
                                                disabled={isCheckingPlanCtaLimit}
                                            >
                                                Create New
                                                {reachedPlanLimitForCtas ? (
                                                    <img
                                                        src={LockIcon}
                                                        alt="lock icon"
                                                        className="ml-2 h-5 w-5"
                                                    />
                                                ) : null}
                                            </ButtonMain>
                                        )
                                    }
                                    useAsTemplateButton={
                                        isCheckingPlanCtaLimit ? null : (
                                            <ButtonMain
                                                className="ml-2 flex items-center"
                                                style="neutral"
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    if (reachedPlanLimitForCtas) {
                                                        handleOpenUpgradePlanMessage({
                                                            feature: 'Add Call-To-Action',
                                                            requiredPlan:
                                                                getNextHigherPlan(userPlan),
                                                            message:
                                                                'You have reached the maximum amount of Call-To-Actions for your current plan. To add another CTA you will have to upgrade your plan.'
                                                        });
                                                    } else {
                                                        navigateTo(
                                                            `/snip-cta-change/new?snipId=${snipId}&variant=${variant}`
                                                        );
                                                        setCtaId('');
                                                    }
                                                }}
                                                disabled={isCheckingPlanCtaLimit}
                                            >
                                                {isMobileScreen
                                                    ? 'Use As Template'
                                                    : 'Use Selected As Template'}
                                                {reachedPlanLimitForCtas ? (
                                                    <img
                                                        src={LockIcon}
                                                        alt="lock icon"
                                                        className="ml-2 h-5 w-5"
                                                    />
                                                ) : null}
                                            </ButtonMain>
                                        )
                                    }
                                />
                            </div>
                        )}
                    </FormStepLayout>
                </div>
            </div>
            <div
                className={`h-full grow ${
                    hasScreenSpaceForSideBar ? 'bg-grey-lighter' : 'bg-primary'
                }`}
                ref={sideBarRef}
            >
                {hasScreenSpaceForSideBar && (
                    <DemoContentIntegrator
                        contentUrl={contentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: ctaId,
                                    name: ctaName,
                                    type: ctaType,
                                    urlToBePromoted: ensureUrlHasProtocol(callToActionUrl),
                                    imageUrl: imagePreviewUrl || imageUrl,
                                    headlineText,
                                    messageText,
                                    messageTextColor,
                                    backgroundColor,
                                    buttonColor,
                                    buttonText,
                                    buttonTextColor,
                                    linkText,
                                    linkTextColor,
                                    formButtonColor,
                                    formButtonTextColor,
                                    formPlaceholderText,
                                    adImageUrl: imageAdPreviewUrl || imageAdUrl,
                                    position,
                                    theme,
                                    showSniplyLogo,
                                    showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={
                                    isSideBarWidthEquivalentOfMobileScreenWidth ||
                                    activePreviewView === 'mobile'
                                }
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                        previewViewSelectionTab={
                            hasScreenSpaceForSideBar && (
                                <PreviewViewSelectionTab
                                    activeTab={activePreviewView}
                                    setActiveTab={setActivePreviewView}
                                />
                            )
                        }
                        activePreviewView={activePreviewView}
                    />
                )}
            </div>
        </div>
    );
};
