import React from 'react';
import { UserService } from './api';
import WarningIcon from './assets/icons/warning.svg';
import { AuthContext } from './auth';
import { ButtonMain } from './components/ButtonMain';
import { LegacyModal } from './components/Modal';

interface DeactivationReason {
    readonly id: string;
    readonly displayName: string;
}

const DEACTIVATION_REASONS: DeactivationReason[] = [
    { id: 'campain-over', displayName: 'Campaign over' },
    { id: 'duplicate-account', displayName: 'Duplicated campaign' },
    { id: 'insufficient-results', displayName: 'Insufficient results (clicks, conversions)' },
    { id: 'not-working', displayName: 'Sniply does not work' },
    { id: 'other', displayName: 'Other' }
];

interface ModalDeactivateAccountProps {
    readonly closeModal: () => void;
}

export const ModalDeactivateAccount: React.FC<ModalDeactivateAccountProps> = ({ closeModal }) => {
    const [deactivationReason, setDeactivationReason] = React.useState(undefined);

    const { logoutRedirect } = React.useContext(AuthContext);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            console.log(`TODO POST deactivation reason ${deactivationReason} to API.`);

            UserService.userDeactivateUpdate()
                .then(() => {
                    closeModal();
                    window.location.replace(logoutRedirect);
                })
                .catch((error) => {
                    console.error(
                        'Error deactivation account via UserService.userDeactivateUpdate().',
                        error
                    );
                });
        },
        [deactivationReason, closeModal, logoutRedirect]
    );
    return (
        <LegacyModal titleText="Deactivate account" onCloseClick={closeModal}>
            <div className="flex items-center p-4">
                <img className="h-12 w-12" src={WarningIcon} />
                <p className="ml-4 mr-1/6 text-sm text-grey-light">
                    {`
                    Warning this will affect all of your snips! Your account and links will be
                    disabled. All of your current links will be redirected to a "404 - not found"
                    page.`}
                </p>
            </div>
            <form className="mt-4 p-4" onSubmit={(event) => handleSubmit(event)}>
                <h4>Why do you want to deactivate your account?</h4>
                <div className="mt-2 grid gap-2 text-sm">
                    {DEACTIVATION_REASONS.map((reason) => (
                        <div key={reason.id} className="flex items-center">
                            <input
                                id="cam"
                                type="radio"
                                name="deactivation-reason"
                                checked={deactivationReason === reason.id ? true : false}
                                onClick={() => setDeactivationReason(reason.id)}
                            />
                            <label className="ml-3" htmlFor={reason.id}>
                                {reason.displayName}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="mt-6">
                    <ButtonMain size="small" style="warning" type="submit">
                        Deactivate account
                    </ButtonMain>
                    <ButtonMain
                        size="small"
                        className="ml-2"
                        style="secondary"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </ButtonMain>
                </div>
            </form>
        </LegacyModal>
    );
};
