import { CTA } from '../api';
import { getCtaTypeFromResponse } from '../utils/get-cta-type-from-response';

export type CtaTheme = 'social' | 'fullwidth' | 'candy' | 'bean';
export type CtaPosition = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
export type CtaType = 'button' | 'text-link' | 'form' | 'image-ad';
export interface CtasListItem {
    readonly id: string;
    readonly name: string;
    readonly type: CtaType;
    readonly imageUrl: string;
    readonly headlineText: string;
    readonly messageText: string;
    readonly messageTextColor: string;
    readonly urlToBePromoted: string;
    readonly backgroundColor: string;
    readonly buttonColor: string;
    readonly buttonText: string;
    readonly buttonTextColor: string;
    readonly linkText: string;
    readonly linkTextColor: string;
    readonly formButtonTextColor: string;
    readonly formButtonColor: string;
    readonly formPlaceholderText: string;
    readonly adImageUrl: string;
    readonly position: CtaPosition;
    readonly theme: CtaTheme;
    readonly showSniplyLogo: boolean;
    readonly showWhiteSniplyLogo?: boolean;
    readonly createdDate: string;
    readonly numberOfClicks: number;
    readonly numberOfConversions: number;
    readonly conversionRate: number;
    readonly archived: boolean;
}

export function createCtaPosition(position: string): CtaPosition {
    switch (position) {
        case 'bottom':
            return 'bottom-left';
        case 'top':
            return 'top-left';
        default:
            return position as CtaPosition;
    }
}

export function createCtaData(cta: CTA): CtasListItem {
    const conversionRate =
        cta?.cache_view_count === 0
            ? 0
            : Math.round((cta.cache_click_count / cta.cache_view_count) * 100);

    return {
        id: cta.id,
        name: cta.name || cta.message || '',
        type: getCtaTypeFromResponse(cta),
        imageUrl: cta.image_url || undefined,
        headlineText: cta.title || '',
        messageText: cta.message || '',
        messageTextColor: cta.message_color,
        urlToBePromoted: cta.button_action?.url || cta.image_action?.url || '',
        backgroundColor: cta.background_color,
        buttonColor:
            cta.button_action?.background_color || cta.form_action?.button_background_color,
        buttonText: !cta.button_action?.is_text ? cta.button_action?.text : '',
        buttonTextColor: !cta.button_action?.is_text
            ? cta.button_action?.text_color
            : '' || cta.form_action?.button_text_color,
        linkText: cta.button_action?.is_text ? cta.button_action?.text : '',
        linkTextColor: cta.button_action?.is_text ? cta.button_action?.text_color : '',
        formButtonTextColor: cta.form_action ? cta.form_action?.button_text_color : '',
        formButtonColor: cta.form_action ? cta.form_action?.button_background_color : '',
        formPlaceholderText: cta.form_action?.placeholder_text || '',
        adImageUrl: cta.image_action?.image_url,
        position: createCtaPosition(cta.position),
        theme: cta.theme as CtaTheme,
        showSniplyLogo: cta.show_sniply_logo,
        showWhiteSniplyLogo: cta.show_white_sniply_logo,
        createdDate: cta.date_created,
        numberOfClicks: cta.cache_view_count,
        numberOfConversions: cta.cache_click_count,
        conversionRate: conversionRate,
        archived: cta.archived
    };
}
