import React from 'react';

import { ConversionPixelsService } from './api';
import { AuthContext } from './auth';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid } from './components/StandardContentGrid';
import { RouteLink } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';
import CopyIcon from './assets/icons/copy.svg';
import CopyIconWhite from './assets/icons/copy-white.svg';

import { ActiveBadge, InActiveBadge } from './components/Badge';
import { NotificationContext } from './NotificationContextProvider';

interface ConversionPixel {
    readonly id: string;
    readonly verified: boolean;
}

export const CustomizationConversionPixel = () => {
    const [conversionPixelData, setConversionPixelData] = React.useState<ConversionPixel>();

    const [isLoadingConversionPixel, setisLoadingConversionPixel] = React.useState(true);
    const [errorLoadingConversionPixel, setErrorLoadingConversionPixel] = React.useState(false);

    const [isUpdatingConversionPixel, setIsUpdatingConversionPixel] = React.useState(false);
    const [errorUpdatingConversionPixel, setErrorUpdatingConversionPixel] = React.useState(false);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleActivateConversionPixel = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setIsUpdatingConversionPixel(true);

            ConversionPixelsService.conversionPixelsCreate({
                requestBody: {
                    brand: currentWorkspace.id,
                    name: currentWorkspace.name,
                    click_attribution_window_days: 30, //Currently not configurable.
                    view_attribution_window_days: 7 //Currently not configurable.
                }
            })
                .then((result) => {
                    setConversionPixelData({ id: result.id, verified: result.verified });
                    setIsUpdatingConversionPixel(false);
                    setErrorUpdatingConversionPixel(false);
                })
                .catch((error) => {
                    console.error(
                        'Error creating a Conversion Pixel via ConversionPixelsService.conversionPixelsCreate().',
                        error
                    );
                    setIsUpdatingConversionPixel(false);
                    setErrorUpdatingConversionPixel(true);
                });
        },
        [currentWorkspace]
    );

    const handleRemoveConversionPixel = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, pixelId: string) => {
            event.preventDefault();

            setIsUpdatingConversionPixel(true);

            ConversionPixelsService.conversionPixelsDestroy({
                id: pixelId
            })
                .then(() => {
                    setConversionPixelData(undefined);
                    setIsUpdatingConversionPixel(false);
                    setErrorUpdatingConversionPixel(false);
                })
                .catch((error) => {
                    console.error(
                        'Error deleting a Conversion Pixel via ConversionPixelsService.conversionPixelsDestroy().',
                        error
                    );
                    setErrorUpdatingConversionPixel(true);
                    setIsUpdatingConversionPixel(false);
                });
        },
        []
    );

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setisLoadingConversionPixel(true);

            ConversionPixelsService.conversionPixelsList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('ConversionPixelsService.conversionPixelsList()', result);

                    const conversionPixel = result?.results[0];

                    if (conversionPixel) {
                        setConversionPixelData({
                            id: conversionPixel.id,
                            verified: conversionPixel.verified
                        });
                    }

                    setisLoadingConversionPixel(false);
                })
                .catch((error) => {
                    console.error(
                        'Error fetching from ConversionPixelsService.conversionPixelsList()',
                        error
                    );

                    setisLoadingConversionPixel(false);
                    setErrorLoadingConversionPixel(true);
                });
        }
    }, [currentWorkspace, isOpenAPITokenSet]);

    const conversionPixelHtmlString = conversionPixelData
        ? `<img src="https://snip.ly/services/pixel/${conversionPixelData.id}/" height=1 width=1 border=0 />`
        : '';

    return (
        <StandardContentGrid>
            <ContentElementContainer>
                <div className="mb-4 flex items-center justify-between">
                    <div className="flex w-full items-center justify-between">
                        <h3 className="font-semibold text-grey">Conversion Pixel</h3>
                        {isLoadingConversionPixel ||
                        isUpdatingConversionPixel ? null : conversionPixelData ? (
                            <ActiveBadge />
                        ) : (
                            <InActiveBadge />
                        )}
                    </div>
                </div>
                <div className="text-grey min-h-96">
                    {isLoadingConversionPixel ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : errorLoadingConversionPixel ? (
                        <p className="mt-2 text-xs text-red">
                            Unable to retrieve your Conversion Pixel. Please try again by reloading
                            the browser window.
                        </p>
                    ) : (
                        <div>
                            <div>
                                <div className="h-44 border-solid border rounded-md p-3 grow font-normal text-sm text-grey">
                                    {isUpdatingConversionPixel ? (
                                        <div className="flex w-full h-full items-center justify-center">
                                            <LoadingSpinner size={10} />{' '}
                                        </div>
                                    ) : errorUpdatingConversionPixel ? (
                                        <p className="mt-2 text-xs text-red">
                                            Unable to update your Conversion Pixel. Please try
                                            again.
                                        </p>
                                    ) : conversionPixelData ? (
                                        <div className="relative">
                                            <button
                                                className="absolute top-1 right-1"
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    navigator.clipboard.writeText(
                                                        conversionPixelHtmlString
                                                    );

                                                    handleOpenNotification({
                                                        messageText:
                                                            'HTML string successfully copied.',
                                                        type: 'success',
                                                        iconSrc: CopyIconWhite,
                                                        showTimeInSeconds: 3
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={CopyIcon}
                                                    alt="copy icon"
                                                    className="h-5 w-5"
                                                />
                                            </button>
                                            <div className="pt-10 break-words">
                                                {conversionPixelHtmlString}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-sm text-grey-medium">
                                            Activate Conversion Pixel in order to retrieve the code
                                            to integrate on your site.
                                        </p>
                                    )}
                                </div>
                                <div className="mt-6 mb-2 flex justify-center">
                                    {conversionPixelData ? (
                                        <ButtonMain
                                            onClick={(event) =>
                                                handleRemoveConversionPixel(
                                                    event,
                                                    conversionPixelData.id
                                                )
                                            }
                                            style="secondary"
                                        >
                                            Remove Conversion Pixel
                                        </ButtonMain>
                                    ) : (
                                        <ButtonMain
                                            onClick={(event) =>
                                                handleActivateConversionPixel(event)
                                            }
                                        >
                                            Activate Conversion Pixel
                                        </ButtonMain>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ContentElementContainer>
            <ContentElementContainer>
                <SimpleContentBox headerText="Conversion Pixels">
                    <div className="font-normal text-sm text-grey">
                        <p>
                            Conversion Pixels enable you to track purchases, downloads and
                            conversions on your site to help you see which of your call-to-actions
                            are working. The number of specific actions taken on your website will
                            be reported as Verified Conversions.
                        </p>
                        <br />
                        <p>
                            <span>
                                Here you can find more information about how to set up Conversion
                                Pixels:{' '}
                            </span>
                            <RouteLink
                                href="https://support.snip.ly/hc/en-us/articles/20000976015380-How-do-I-set-up-a-Conversion-Pixel-"
                                className="text-primary underline"
                            >
                                see guide
                            </RouteLink>
                            <span>.</span>
                        </p>
                    </div>
                </SimpleContentBox>
            </ContentElementContainer>
        </StandardContentGrid>
    );
};
