import React from 'react';

import { LinksService } from './api';
import ArrowIconWhite from './assets/icons/arrow-white.svg';
import SniplyIcon from './assets/icons/sniply-logo.svg';
import { AuthContext } from './auth';
import { ButtonCopy } from './components/ButtonCopy';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { EmptyTableView } from './components/EmptyTableView';
import { ImageWithFallback } from './components/ImageWithFallback';
import { LoadingSpinner } from './components/LoadingSpinner';
import { Tab, Tabs } from './components/shared/Tabs';
import { TableEmptyFallback } from './components/TableEmptyFallback';
import { VerticalSpacer } from './components/VerticalSpacer';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { createSnipsList } from './data-transformations/create-snips-list';
import { useWindowSize } from './hooks/use-window-size';
import { navigateTo, RouteLink } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';

export type SortingDirection = 'ascending' | 'descending';

interface DashboardSnipsTableHeaderProps {
    readonly isSmallView: boolean;
}

const DashboardSnipsTableHeader: React.FC<DashboardSnipsTableHeaderProps> = ({ isSmallView }) => {
    return (
        <div className="flex items-center justify-between">
            <div className={`w-full mb-1 pb-1 grid grid-cols-4-1-1-1 gap-2 text-xs sm:text-sm`}>
                <span className="pl-2">Snip</span>
                <span className="text-center">Clicks</span>
                <span className="text-center">Conv.</span>
                <span className="text-center">Rate</span>
            </div>
            {/* Replicating the layout of the DashboardSnipsTableRow here so the column headers match  */}
            {!isSmallView && <div className="w-28"></div>}
        </div>
    );
};

interface DashboardSnipTableRowProps {
    readonly id: string;
    readonly snipName: string;
    readonly snipUrl: string;
    readonly faviconUrl: string;
    readonly contentUrl: string;
    readonly numberOfClicks: number;
    readonly numberOfConversions: number;
    readonly conversionRate: number;
    readonly isSmallView: boolean;
}

const DashboardSnipTableRow: React.FC<DashboardSnipTableRowProps> = ({
    id,
    snipName,
    snipUrl,
    faviconUrl,
    contentUrl,
    numberOfClicks,
    numberOfConversions,
    conversionRate,
    isSmallView
}) => {
    const [isHoveringSnip, setIsHoveringSnip] = React.useState(false);

    return (
        <div
            onMouseEnter={() => setIsHoveringSnip(true)}
            onMouseLeave={() => setIsHoveringSnip(false)}
            className={`h-12 py-6 px-2 flex items-center justify-between  ${
                isHoveringSnip ? 'border border-grey-lighter rounded-md ' : 'border border-white'
            }`}
        >
            <button
                className={`w-full grid items-center grid-cols-4-1-1-1`}
                onClick={() => navigateTo(`/snips/${id}`)}
            >
                <div className="flex items-center min-w-0">
                    <ImageWithFallback
                        src={faviconUrl}
                        alt="favicon"
                        fallbackSrc={SniplyIcon}
                        className="h-4 w-4"
                    />
                    <div className="ml-4 min-w-0">
                        <p className="text-left truncate text-sm md:text-base">{snipName}</p>
                        {contentUrl && (
                            <p className="text-xs truncate text-left text-grey-medium">
                                {contentUrl}
                            </p>
                        )}
                    </div>
                </div>
                <span className="text-xs">{numberOfClicks}</span>
                <span className="text-xs">{numberOfConversions}</span>
                <span className="text-xs">{`${conversionRate}%`}</span>
            </button>

            {!isSmallView && (
                <div className="flex items-center w-28">
                    {isHoveringSnip && (
                        <>
                            <ButtonCopy copyTargetLabel="Snip URL" textForCopyAction={snipUrl} />
                            <ButtonMain size="extra-small" className="ml-2">
                                <a href={snipUrl} target="_blank" rel="noreferrer">
                                    <img
                                        className="h-4 w-4 rotate-180"
                                        src={ArrowIconWhite}
                                        alt="arrow icon"
                                    />
                                </a>
                            </ButtonMain>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

type DashboardSnipsTabView = 'top' | 'recent';

interface DashboardSnipsProps {
    readonly setHasZeroSnips: (value: boolean) => void;
}

export const DashboardSnips: React.FC<DashboardSnipsProps> = ({ setHasZeroSnips }) => {
    const [topSnipsData, setTopSnipsData] = React.useState<SnipsListItem[]>([]);
    const [recentSnipsData, setRecentSnipsData] = React.useState<SnipsListItem[]>([]);
    const [tabView, setTabView] = React.useState<DashboardSnipsTabView>('recent');

    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    const { width } = useWindowSize();
    const isSmallScreen = width < 900;

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const snipDataForDisplay = tabView === 'top' ? topSnipsData : recentSnipsData;

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            if (tabView === 'top' && !topSnipsData.length) {
                setIsLoadingData(true);

                LinksService.linksList({
                    archived: false,
                    brand: currentWorkspace.id,
                    ordering: '-cache_view_count',
                    page: 1,
                    pageSize: 10
                })
                    .then((result) => {
                        console.log('LinksService.linksList() Top View Count', result);

                        result.results.length === 0 && setHasZeroSnips(true);

                        setTopSnipsData(createSnipsList(result));

                        setHadErrorLoadingData(false);
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from LinksService.linksList()Top View Count: ',
                            error
                        );
                        setHadErrorLoadingData(true);
                        setIsLoadingData(false);
                    });
            }

            if (tabView === 'recent' && !recentSnipsData.length) {
                setIsLoadingData(true);

                LinksService.linksList({
                    archived: false,
                    brand: currentWorkspace.id,
                    ordering: '-created_date',
                    page: 1,
                    pageSize: 10
                })
                    .then((result) => {
                        console.log('LinksService.linksList() Most Recent', result);

                        setRecentSnipsData(createSnipsList(result));

                        setHadErrorLoadingData(false);
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from LinksService.linksList() Most Recent: ',
                            error
                        );
                        setHadErrorLoadingData(true);
                        setIsLoadingData(false);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenAPITokenSet, currentWorkspace, tabView]);

    return (
        <ContentElementContainer>
            {!isLoadingData && !recentSnipsData.length ? (
                <EmptyTableView
                    headlineText="You do not have any snips yet"
                    sublineText="Once you do, you will see the overview of all your top performing and most recent snips
                        here."
                />
            ) : (
                <div>
                    <Tabs
                        tabs={[
                            { id: 'recent', displayText: 'Most Recent' },
                            { id: 'top', displayText: 'Top Performing' }
                        ]}
                        currentTabView={tabView}
                        renderTabSelectionButton={(tab: Tab) => (
                            <button
                                className="text-base h-6 px-2"
                                onClick={() => setTabView(tab.id as DashboardSnipsTabView)}
                            >
                                {tab.displayText}
                            </button>
                        )}
                    />
                    <VerticalSpacer heightValue={4} />
                    <DashboardSnipsTableHeader isSmallView={isSmallScreen} />
                    <div
                        className={`border-t border-grey-lighter ${
                            isSmallScreen ? 'h-68' : 'h-76'
                        }`}
                    >
                        {isLoadingData ? (
                            <div className="h-full w-full flex items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : hadErrorLoadingData ? (
                            <div className="text-red text-sm">
                                Apologies your Snips could not be retrieved. An unexpected network
                                error has occurred. Please try again later.
                            </div>
                        ) : snipDataForDisplay.length ? (
                            <div className="w-full">
                                <div
                                    className={`pt-2 w-full ${
                                        isSmallScreen ? 'h-60' : 'h-68'
                                    } overflow-y-scroll`}
                                >
                                    {snipDataForDisplay.map((snip) => (
                                        <DashboardSnipTableRow
                                            id={snip.id}
                                            key={snip.snipName}
                                            snipName={snip.snipName}
                                            snipUrl={snip.snipUrl}
                                            faviconUrl={snip.faviconUrl}
                                            contentUrl={snip.originalContentUrl}
                                            numberOfClicks={snip.numberOfClicks}
                                            numberOfConversions={snip.numberOfConversions}
                                            conversionRate={snip.conversionRate}
                                            isSmallView={isSmallScreen}
                                        />
                                    ))}
                                </div>
                                <button
                                    className="text-primary w-full text-center mt-2 hover:underline"
                                    onClick={() => navigateTo('/snips')}
                                >
                                    View All Snips
                                </button>
                            </div>
                        ) : (
                            <TableEmptyFallback
                                titleText="No Snips"
                                whatToDoText="Get started with Sniply by creating your first Snip."
                                primaryActionButton={
                                    <RouteLink href="/snip-create/content-select">
                                        <ButtonMain>+ Create New Snip</ButtonMain>
                                    </RouteLink>
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </ContentElementContainer>
    );
};
