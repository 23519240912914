import { PaginatedCTAList } from '../api';
import { DropDownOption } from '../components/DropdownSelect';
import { createCtaData, CtasListItem } from './create-cta-data';

export function createCtasList(response: PaginatedCTAList): CtasListItem[] {
    if (!response || !response.results?.length) {
        return [];
    }

    // Filter out CTAs which according to the old logic were considered "hidden" CTAs.
    // They are are not considered as a CTA going forward. Rather, there are Snips without CTA.
    return response.results?.map((result) => createCtaData(result)).filter((cta) => cta.type);
}

export function createCtasListForDropdown(response: PaginatedCTAList): DropDownOption[] {
    if (!response || !response.results?.length) {
        return [];
    }

    return response.results.map((ctaResult) => ({
        value: ctaResult.id,
        displayLabel: ctaResult.name || ctaResult.message || ''
    }));
}
