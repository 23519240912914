import React from 'react';
import { LabelsService } from './api';
import { AuthContext } from './auth';
import {
    FilterDropdownSelect,
    FilterDropdownSelectOption
} from './components/FilterDropdownSelect';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface CampaignsFilterDropdownSelectProps {
    readonly selectedCampaignId: string;
    readonly onSelectOption: (option: string) => void;
}

export const CampaignsFilterDropdownSelect: React.FC<CampaignsFilterDropdownSelectProps> = ({
    selectedCampaignId,
    onSelectOption
}) => {
    const [campaignsData, setCampaignsData] = React.useState<FilterDropdownSelectOption[]>([]);
    const [isLoadingCampaignsData, setIsLoadingCampaignsData] = React.useState(true);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                    setIsLoadingCampaignsData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                    setIsLoadingCampaignsData(false);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return selectedCampaignId && isLoadingCampaignsData ? null : (
        <FilterDropdownSelect
            label="Campaign"
            options={campaignsData}
            selectedOptionId={selectedCampaignId}
            onSelectOption={onSelectOption}
        />
    );
};
