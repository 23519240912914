import React from 'react';

import { CtasService, FeedsService } from './api';
import CopyIcon from './assets/icons/copy.svg';
import CopyIconWhite from './assets/icons/copy-white.svg';
import DeleteIcon from './assets/icons/delete.svg';
import EditIcon from './assets/icons/edit.svg';
import MenuBulletsIcon from './assets/icons/menu-bullets.svg';
import PlusIcon from './assets/icons/plus-plain.svg';
import { AuthContext } from './auth';
import { SearchInput } from './components/SearchInput';
import { TableButton } from './components/TableButton';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createCtasListForDropdown } from './data-transformations/create-ctas-list';
import { FeedsListItem } from './data-transformations/create-feeds-list';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { NotificationContext } from './NotificationContextProvider';
import { navigateTo } from './Routing';
import { SnipsTableRowCtaSelect } from './SnipsOverviewTable';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { DropDownOption } from './components/DropdownSelect';

interface FeedsEditRowButtonProps {
    readonly feedId: string;
    readonly onEditFeed: (feedId: string) => void;
    readonly onDeleteFeed: (feedId: string) => void;
    readonly onCopyOutputFeedUrl: (feedOutpulUrl: string) => void;
}

export const FeedsEditRowButtonDropdown: React.FC<FeedsEditRowButtonProps> = ({
    feedId,
    onEditFeed,
    onDeleteFeed,
    onCopyOutputFeedUrl
}) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end" ref={containerRef}>
            <button onClick={() => setIsMenuOpen(isMenuOpen ? false : true)}>
                <img className="h-4 w-4" src={MenuBulletsIcon} />
            </button>
            {isMenuOpen && (
                <ul className="z-1 absolute -top-2 right-0 p-2 text-sm bg-white border border-grey-lightest rounded-md w-36 shadow-lg">
                    <li className="text-grey">
                        <button
                            className="flex items-center"
                            onClick={(event) => {
                                event.preventDefault();
                                onEditFeed(feedId);
                                setIsMenuOpen(false);
                            }}
                        >
                            <img src={EditIcon} alt="edit icon" className="w-4 h-4" />
                            <span className="ml-2">Edit</span>
                        </button>
                    </li>
                    <li className="mt-2 text-grey">
                        <button
                            className="flex items-center"
                            onClick={(event) => {
                                event.preventDefault();
                                onDeleteFeed(feedId);
                                setIsMenuOpen(false);
                            }}
                        >
                            <img src={DeleteIcon} alt="delete icon" className="w-4 h-4" />
                            <span className="ml-2">Delete</span>
                        </button>
                    </li>
                    <li className="mt-2 text-grey">
                        <button
                            className="flex items-center"
                            onClick={(event) => {
                                event.preventDefault();
                                onCopyOutputFeedUrl(feedId);
                                setIsMenuOpen(false);
                            }}
                        >
                            <img src={CopyIcon} alt="copy icon" className="w-4 h-4" />
                            <span className="ml-2">Copy Output</span>
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

interface FeedsTableHeaderProps {
    readonly numberOfDisplayedFeeds: number;
    readonly numberOfTotalFeeds: number;
}

const FeedsTableHeader: React.FC<FeedsTableHeaderProps> = ({
    numberOfDisplayedFeeds,
    numberOfTotalFeeds
}) => {
    return (
        <div className="flex px-3 items-center justify-between">
            <div className={`w-full h-full grid gap-2 grid-cols-8-4-4-1 mb-1 pb-1 text-xs`}>
                <div>
                    <span>Feeds</span>
                    {numberOfTotalFeeds && (
                        <span className="ml-1">{`(${numberOfDisplayedFeeds} out of ${numberOfTotalFeeds})`}</span>
                    )}
                </div>
                <span className="text-center">Call-To-Action</span>
                <span className="text-center">Created</span>
                {/* Empty span for action button column */}
                <span></span>
            </div>
        </div>
    );
};

const FeedsTableHeaderSmall: React.FC<FeedsTableHeaderProps> = ({
    numberOfDisplayedFeeds,
    numberOfTotalFeeds
}) => {
    return (
        <div className="flex px-3 items-center justify-between">
            <div className={`w-full h-full grid gap-2 grid-cols-8-4-1 mb-1 pb-1 text-xs`}>
                <div>
                    <span>Feeds</span>
                    {numberOfTotalFeeds && (
                        <span className="ml-1">{`(${numberOfDisplayedFeeds} out of ${numberOfTotalFeeds})`}</span>
                    )}
                </div>
                <span>Call-To-Action</span>
                {/* Empty span for action button column */}
                <span></span>
            </div>
        </div>
    );
};

interface FeedsTableRowProps {
    readonly listIndex: number;
    readonly feedId: string;
    readonly feedName: string;
    readonly feedUrl: string;
    readonly feedCtaId: string;
    readonly createdDaysAgo: string;
    readonly ctasOptions: DropDownOption[];
    readonly onEditFeed: (feedId: string) => void;
    readonly onDeleteFeed: (feedId: string) => void;
    readonly onCopyOutputFeedUrl: (feedId: string) => void;
}

const FeedsTableRow: React.FC<FeedsTableRowProps> = ({
    listIndex,
    feedId,
    feedName,
    feedUrl,
    feedCtaId,
    ctasOptions,
    createdDaysAgo,
    onEditFeed,
    onDeleteFeed,
    onCopyOutputFeedUrl
}) => {
    const [selectedCtaId, setSelectedCtaId] = React.useState(feedCtaId);
    const [isHoveringRow, setIsHoveringRow] = React.useState(false);

    const handleSelectCta = React.useCallback(
        (ctaId: string) => {
            const requestBody = ctaId ? { cta_ids: [ctaId] } : { cta_ids: [] };

            FeedsService.feedsPartialUpdate({
                id: feedId,
                requestBody
            }).then(() => setSelectedCtaId(ctaId));
        },
        [feedId]
    );

    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <div
                className="flex px-3 py-3 items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter"
                onMouseEnter={() => setIsHoveringRow(true)}
                onMouseLeave={() => setIsHoveringRow(false)}
            >
                <div className="w-full h-full grid gap-2 grid-cols-8-4-4-1 items-center text-sm">
                    <div>
                        <p className="font-medium">{feedName}</p>
                        <p className="mt-1 line-clamp-1 text-xs">{feedUrl}</p>
                    </div>
                    <SnipsTableRowCtaSelect
                        ctaOptions={ctasOptions}
                        selectedCtaId={selectedCtaId}
                        onSelectCtaId={handleSelectCta}
                        onResetCtaId={() => handleSelectCta(null)}
                        isHoveringRow={isHoveringRow}
                        isRightAligned
                    />
                    <span className="text-xs text-center">{createdDaysAgo}</span>
                    <FeedsEditRowButtonDropdown
                        feedId={feedId}
                        onEditFeed={onEditFeed}
                        onDeleteFeed={onDeleteFeed}
                        onCopyOutputFeedUrl={onCopyOutputFeedUrl}
                    />
                </div>
            </div>
        </>
    );
};
interface FeedsTableRowSmallProps {
    readonly listIndex: number;
    readonly feedId: string;
    readonly feedName: string;
    readonly feedUrl: string;
    readonly feedCtaName: string;
}

const FeedsTableRowSmall: React.FC<Partial<FeedsTableRowSmallProps>> = ({
    listIndex,
    feedId,
    feedName,
    feedCtaName,
    feedUrl
}) => {
    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <button
                className="w-full flex px-3 py-3 items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter"
                onClick={() => navigateTo(`/feed-edit/${feedId}`)}
            >
                <div className="w-full h-full grid gap-2 grid-cols-8-4-1 items-center text-sm">
                    <div>
                        <p className="font-medium text-left">{feedName}</p>
                        <p className="mt-1 line-clamp-1 text-xs text-left">{feedUrl}</p>
                    </div>
                    {feedCtaName ? (
                        <span className="text-xs text-center line-clamp-1">{feedCtaName}</span>
                    ) : (
                        <img src={PlusIcon} alt="plus icon" className="h-4 w-4" />
                    )}
                    <div className="flex justify-end">
                        <img src={MenuBulletsIcon} alt="menu icon" className="h-4 w-4" />
                    </div>
                </div>
            </button>
        </>
    );
};

interface IntegrationsRssFeedTableProps {
    readonly feeds: FeedsListItem[];
    readonly numberOfTotalFeeds: number;
    readonly setFeeds: (feeds: FeedsListItem[]) => void;
}

export const IntegrationsRssFeedTable: React.FC<IntegrationsRssFeedTableProps> = ({
    feeds,
    numberOfTotalFeeds,
    setFeeds
}) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [ctasData, setCtasData] = React.useState<DropDownOption[]>([]);

    const isMobileScreenSize = useIsMobileScreenSize();

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleEditFeed = React.useCallback((id: string) => {
        navigateTo(`/feed-edit/${id}`);
    }, []);

    const handleDeleteFeed = React.useCallback(
        (id: string) => {
            FeedsService.feedsPartialUpdate({
                id: id,
                requestBody: {
                    deleted: true
                }
            })
                .then(() => {
                    setFeeds(feeds.filter((feed) => feed.id !== id));
                })
                .catch((error) => {
                    console.log('Error calling api for FeedsService.feedsUpdate()', error);
                });
        },
        [feeds, setFeeds]
    );

    const handleCopyOutputFeedUrl = React.useCallback(
        (id: string) => {
            const outputUrl = feeds?.find((feed) => feed.id === id).outputUrl;

            navigator.clipboard.writeText(outputUrl);

            handleOpenNotification({
                messageText: `Successfully copied ${outputUrl}`,
                type: 'success',
                iconSrc: CopyIconWhite,
                showTimeInSeconds: 3
            });
        },
        [feeds, handleOpenNotification]
    );

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            CtasService.ctasList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);
                    setCtasData(createCtasListForDropdown(result));
                })
                .catch((error) => {
                    console.error('Error fetching from FeedsService.feedsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    const feedsToDisplay = feeds.filter(
        (feed) =>
            feed.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            feed.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div className="flex items-center justify-between">
                <h3 className="font-semibold">Feeds</h3>
                <TableButton onClick={() => navigateTo('/feed-create')}>+ New Feed</TableButton>
            </div>
            <VerticalSpacer heightValue={6} />
            <div className="flex justify-end">
                <SearchInput
                    searchTermState={searchTerm}
                    setSearchTermState={setSearchTerm}
                    onClearSearch={() => setSearchTerm('')}
                    placeholderText="Search"
                    isCollapsible={false}
                    isRounded
                />
            </div>
            <VerticalSpacer heightValue={2} />
            <hr className="border-t border-grey-lighter" />
            <VerticalSpacer heightValue={4} />
            {isMobileScreenSize ? (
                <FeedsTableHeaderSmall
                    numberOfDisplayedFeeds={feedsToDisplay?.length ?? 0}
                    numberOfTotalFeeds={numberOfTotalFeeds}
                />
            ) : (
                <FeedsTableHeader
                    numberOfDisplayedFeeds={feedsToDisplay?.length ?? 0}
                    numberOfTotalFeeds={numberOfTotalFeeds}
                />
            )}

            <div className="h-88 overflow-scroll">
                {feeds?.length ? (
                    <div className="w-full">
                        {!feedsToDisplay?.length ? (
                            <p className="mt-4 text-grey-medium text-sm">
                                No results for this search.
                            </p>
                        ) : (
                            feedsToDisplay.map((feed, listIndex) =>
                                isMobileScreenSize ? (
                                    <FeedsTableRowSmall
                                        key={feed.id}
                                        listIndex={listIndex}
                                        feedId={feed.id}
                                        feedName={feed.name}
                                        feedUrl={feed.url}
                                        feedCtaName={
                                            ctasData?.find((cta) => cta.value === feed.ctaId)
                                                ?.displayLabel
                                        }
                                    />
                                ) : (
                                    <FeedsTableRow
                                        key={feed.id}
                                        listIndex={listIndex}
                                        feedId={feed.id}
                                        feedName={feed.name}
                                        feedUrl={feed.url}
                                        feedCtaId={feed.ctaId}
                                        ctasOptions={ctasData}
                                        createdDaysAgo={feed.daysAgoSaved}
                                        onEditFeed={handleEditFeed}
                                        onDeleteFeed={handleDeleteFeed}
                                        onCopyOutputFeedUrl={handleCopyOutputFeedUrl}
                                    />
                                )
                            )
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
