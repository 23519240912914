import React from 'react';
import { ReportingOverallStats } from './data-transformations/create-reporting-stats';
import TriangleUpGreenIcon from './assets/icons/triangle-green-up.svg';
import TriangleUpRedIcon from './assets/icons/triangle-red-up.svg';
import { AuthContext } from './auth';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { ConversionPixelsService } from './api';
import { navigateTo } from './Routing';

export const DeltaPercentageIndicator: React.FC<{ readonly percentageValue: number }> = ({
    percentageValue
}) =>
    percentageValue < 0 ? (
        <div className="flex items-center">
            <img src={TriangleUpRedIcon} alt="icon down" className="rotate-180" />
            <span className="text-xs text-red">{`${-percentageValue}%`}</span>
        </div>
    ) : (
        <div className="flex items-center">
            <img src={TriangleUpGreenIcon} alt="icon up" />
            <span className="text-xs text-green">{`${percentageValue}%`}</span>
        </div>
    );

interface ReportingOverallOverviewProps {
    readonly data: ReportingOverallStats;
    readonly selectedSnipId: string;
}

export const ReportingOverallOverview: React.FC<ReportingOverallOverviewProps> = ({
    data,
    selectedSnipId
}) => {
    const [hasConversionPixelActivated, setHasConversionPixelActivated] = React.useState(false);
    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            ConversionPixelsService.conversionPixelsList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('ConversionPixelsService.conversionPixelsList()', result);

                    const conversionPixel = result?.results[0];

                    if (conversionPixel) {
                        setHasConversionPixelActivated(true);
                    }
                })
                .catch((error) => {
                    console.error(
                        'Error fetching from ConversionPixelsService.conversionPixelsList()',
                        error
                    );
                });
        }
    }, [currentWorkspace, isOpenAPITokenSet]);

    return (
        data && (
            <div className="w-full">
                <div className="mt-4 ml-6 lg:ml-0 grid grid-cols-2 lg:grid-cols-4 grow">
                    <div className="ml-2 mb-2 lg:mb-0 lg:border-r lg:border-grey-lighter">
                        <div>
                            <div className="flex items-center">
                                <span className="font-semibold">
                                    {selectedSnipId ? '1' : data.numberOfSnips}
                                </span>
                                <div className="ml-2">
                                    <DeltaPercentageIndicator
                                        percentageValue={data.numberOfSnipsDelta}
                                    />
                                </div>
                            </div>
                            <p className="text-xs text-grey-light">
                                {selectedSnipId ? 'Snip' : 'New Snips'}
                            </p>
                        </div>
                    </div>
                    <div className="ml-2 mb-2 lg:mb-0 lg:border-r lg:border-grey-lighter">
                        <div className="flex items-center">
                            <span className="font-semibold">{data.numberOfClicks}</span>
                            <div className="ml-2">
                                <DeltaPercentageIndicator
                                    percentageValue={data.numberOfClicksDelta}
                                />
                            </div>
                        </div>
                        <p className="text-xs text-grey-light">Clicks</p>
                    </div>
                    <div className="ml-2 lg:border-r lg:border-grey-lighter">
                        <div className="flex items-center">
                            <span className="font-semibold">{data.numberOfConversions}</span>
                            <div className="ml-2">
                                <DeltaPercentageIndicator
                                    percentageValue={data.numberOfConversionsDelta}
                                />
                            </div>
                        </div>
                        <p className="text-xs text-grey-light">Conversions</p>
                    </div>
                    <div className="ml-2">
                        <div className="flex items-center">
                            <span className="font-semibold">{`${data.conversionRate}%`}</span>
                            <div className="ml-2">
                                <DeltaPercentageIndicator
                                    percentageValue={data.conversionRateDelta}
                                />
                            </div>
                        </div>
                        <p className="text-xs text-grey-light">Rate</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="hidden lg:block" />
                    <div className="mt-6 py-2 relative border border-green rounded-md">
                        <p className="absolute flex justify-center w-36 -top-2 left-1/2 -translate-x-1/2 text-xs text-green bg-white px-2">
                            Verified Conversions
                        </p>
                        {hasConversionPixelActivated ? (
                            <div className="ml-6 lg:ml-0 py-1 grid grid-cols-2">
                                <div className="ml-2 lg:border-r lg:border-green">
                                    <div className="flex items-center">
                                        <span className="font-semibold">
                                            {data.numberOfVerifiedConversions}
                                        </span>
                                    </div>
                                    <p className="text-xs text-grey-light">Conversions</p>
                                </div>
                                <div className="ml-2">
                                    <div className="flex items-center">
                                        <span className="font-semibold">{`${data.verifiedConversionRate}%`}</span>
                                    </div>
                                    <p className="text-xs text-grey-light">Rate</p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center p-2">
                                <span className="text-xs text-grey-medium">
                                    Currently not set up.
                                </span>
                                <button
                                    className="ml-3 text-xs underline transition-all duration-200"
                                    onClick={() => navigateTo('/customization/conversion-pixel')}
                                >
                                    Check Out Conversion Pixels
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};
