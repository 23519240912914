import React from 'react';

interface OverviewTabFirstLevelGridProps {
    readonly children: React.ReactNode;
}

export const OverviewTabFirstLevelGrid: React.FC<OverviewTabFirstLevelGridProps> = ({
    children
}) => {
    return <div className="grid grid-cols-1 gap-y-5">{children}</div>;
};

interface TwoOneToOneGridProps {
    readonly children: React.ReactNode;
}

/**
 * This Grid is has 2 to 1 column size on xl screens otherwhise one column
 */
export const TwoOneToOneGrid: React.FC<TwoOneToOneGridProps> = ({ children }) => {
    return (
        <div className="grid grid-cols-1 xl:grid-cols-2-1 gap-x-10 gap-y-3 sm:gap-y-5">
            {children}
        </div>
    );
};

interface OneOneToOneGridProps {
    readonly children: React.ReactNode;
}

/**
 * This Grid is has 2 same size columns screens starting md, otherwhise one column
 */
export const OneOneToOneGrid: React.FC<OneOneToOneGridProps> = ({ children }) => {
    return <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-5">{children}</div>;
};
