/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { BrandRequest } from '../models/BrandRequest';
import type { PaginatedBrandList } from '../models/PaginatedBrandList';
import type { PatchedBrandRequest } from '../models/PatchedBrandRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BrandsService {

    /**
     * Brands represent Workspaces, that are the main way of grouping resources together.
     *
     * - Custom Code: Retargeting code
     * @returns PaginatedBrandList
     * @throws ApiError
     */
    public static brandsList({
        archived,
        ordering,
        page,
        pageSize,
        search,
    }: {
        archived?: boolean,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedBrandList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/brands/',
            query: {
                'archived': archived,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * Brands represent Workspaces, that are the main way of grouping resources together.
     *
     * - Custom Code: Retargeting code
     * @returns Brand
     * @throws ApiError
     */
    public static brandsCreate({
        requestBody,
    }: {
        requestBody: BrandRequest,
    }): CancelablePromise<Brand> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/brands/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public static brandsRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<Brand> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/brands/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public static brandsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: BrandRequest,
    }): CancelablePromise<Brand> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/brands/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Brand
     * @throws ApiError
     */
    public static brandsPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedBrandRequest,
    }): CancelablePromise<Brand> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/brands/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
