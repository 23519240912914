import React from 'react';
import { UserPlan } from './UserContextProvider';

export interface UpgradePlanMessage {
    feature: string;
    requiredPlan: UserPlan;
    title?: string;
    message?: string;
}

interface UpgradePlanMessageContextValue {
    readonly showMessage: boolean;
    readonly featureName: string;
    readonly titleText: string;
    readonly messageText: string;
    readonly minimumRequiredPlan: UserPlan;
    readonly handleOpenUpgradePlanMessage: (message: UpgradePlanMessage) => void;
    readonly handleCloseUpgradePlanMessage: () => void;
}

export const UpgradePlanMessageContext =
    React.createContext<UpgradePlanMessageContextValue>(undefined);

interface UpgradePlanMessageContextProviderProps {
    readonly children: React.ReactNode;
}

export const UpgradePlanMessageContextProvider: React.FC<
    UpgradePlanMessageContextProviderProps
> = ({ children }) => {
    const [showMessage, setShowMessage] = React.useState(false);
    const [featureName, setFeatureName] = React.useState<string>();
    const [minimumRequiredPlan, setMinimumRequiredPlan] = React.useState<UserPlan>();
    const [titleText, setTitleText] = React.useState<string>();
    const [messageText, setMessageText] = React.useState<string>();

    const handleOpenUpgradePlanMessage = ({
        feature,
        requiredPlan,
        title,
        message
    }: UpgradePlanMessage) => {
        setFeatureName(feature);
        setTitleText(title);
        setMessageText(message);
        setMinimumRequiredPlan(requiredPlan);
        setShowMessage(showMessage ? false : true);
    };
    const handleCloseUpgradePlanMessage = () => {
        setShowMessage(false);
    };

    return (
        <UpgradePlanMessageContext.Provider
            value={{
                showMessage,
                featureName,
                titleText,
                messageText,
                minimumRequiredPlan,
                handleOpenUpgradePlanMessage,
                handleCloseUpgradePlanMessage
            }}
        >
            {children}
        </UpgradePlanMessageContext.Provider>
    );
};
