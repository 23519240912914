import React from 'react';
import { FormTextInput } from './components/FormTextInput';
import { ButtonMain } from './components/ButtonMain';
import { DropdownSelect } from './components/DropdownSelect';
import { TeamMemberData, TeamMemberRole } from './data-transformations/create-team-members-data';
import { TeamService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { navigateTo } from './Routing';
import { WorkspaceDropdownMultiSelect } from './WorkspaceDropdownMultiSelect';
import { FilterDropdownSelectOption } from './components/FilterDropdownSelect';
import { WorkspaceContext } from './WorkspaceContextProvider';

export function getWorkspaceIdsToExclude(
    workspacesData: FilterDropdownSelectOption[],
    selectedWorkspaceIds: string[]
): string[] {
    return workspacesData
        .map((workspace) => workspace.value)
        .filter((workspaceId) => !selectedWorkspaceIds.includes(workspaceId));
}

interface TeamModalInviteTeamMemberProps {
    readonly teamMembersData: TeamMemberData[];
    readonly setTeamMembersData: (teamData: TeamMemberData[]) => void;
    readonly closeModal: () => void;
}

export const TeamModalInviteTeamMember: React.FC<TeamModalInviteTeamMemberProps> = ({
    teamMembersData,
    setTeamMembersData,
    closeModal
}) => {
    const [workspacesData, setWorkspacesData] = React.useState<FilterDropdownSelectOption[]>([]);

    const [newUserEmail, setNewUserEmail] = React.useState('');
    const [newUsersRole, setNewUsersRole] = React.useState<TeamMemberRole>('admin');
    const [newUsersWorkspaceIds, setNewUsersWorkspaceIds] = React.useState([]);

    const [sendingInvite, setSendingInvite] = React.useState(false);

    const [errorNetworkSendingInvite, setErrorNetworkSendingInvite] = React.useState(false);
    const [errorInviteAlreadyExists, setErrorInviteAlreadyExists] = React.useState(false);
    const [errorTooManyTeamMembers, setErrorTooManyTeamMembers] = React.useState(false);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setSendingInvite(true);

            TeamService.teamMembersInviteCreate({
                requestBody: {
                    invitee: newUserEmail,
                    exclude_brand_ids: getWorkspaceIdsToExclude(
                        workspacesData,
                        newUsersWorkspaceIds
                    ),
                    is_client_role: newUsersRole === 'client' ? true : false
                }
            })
                .then((result) => {
                    console.log('TeamService.teamMembersInviteCreate()', result);

                    setSendingInvite(false);

                    setTeamMembersData([
                        ...teamMembersData,
                        {
                            id: result.id,
                            name: newUserEmail,
                            email: newUserEmail,
                            role: newUsersRole,
                            active: false,
                            invitationPending: true,
                            excludedWorkspaceIds: []
                        }
                    ]);

                    closeModal();
                })
                .catch((error) => {
                    console.error(
                        'Error posting new invite to TeamService.teamMembersInviteCreate()',
                        error
                    );

                    if (error.body?.invitee?.[0]?.startsWith('You already invited')) {
                        setErrorInviteAlreadyExists(true);
                    } else if (
                        error.body?.non_field_errors?.[0]?.startsWith("You're at your plan's limit")
                    ) {
                        setErrorTooManyTeamMembers(true);
                    } else {
                        setErrorNetworkSendingInvite(true);
                    }

                    setSendingInvite(false);
                });
        },
        [
            newUserEmail,
            newUsersRole,
            closeModal,
            teamMembersData,
            setTeamMembersData,
            workspacesData,
            newUsersWorkspaceIds
        ]
    );

    const handleSelectWorkspace = React.useCallback(
        (workspaceId: string) => {
            if (newUsersWorkspaceIds.includes(workspaceId)) {
                setNewUsersWorkspaceIds(newUsersWorkspaceIds.filter((id) => id !== workspaceId));
            } else {
                setNewUsersWorkspaceIds([...newUsersWorkspaceIds, workspaceId]);
            }
        },
        [newUsersWorkspaceIds]
    );

    const handleSelectAllWorkspaces = React.useCallback(() => {
        setNewUsersWorkspaceIds(workspacesData.map((workspaceData) => workspaceData.value));
    }, [workspacesData]);

    const { activeWorkspaces } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (activeWorkspaces) {
            setWorkspacesData(
                activeWorkspaces.map((workspace) => ({
                    displayLabel: workspace.name,
                    value: workspace.id
                }))
            );
        }
    }, [activeWorkspaces]);

    return (
        <div className="p-2">
            <h3 className="font-semibold">Invite Team Member</h3>{' '}
            <form className="grid gap-y-4 p-4" onSubmit={handleSubmit}>
                <FormTextInput
                    id="user-email"
                    labelText="User Email"
                    placeholder="new account email"
                    value={newUserEmail}
                    onChange={(event) => {
                        setNewUserEmail(event.target.value);
                    }}
                />
                <DropdownSelect
                    titleText="User Role"
                    options={[
                        { value: 'admin', displayLabel: 'Admin' },
                        { value: 'client', displayLabel: 'Client' }
                    ]}
                    selectedOption={newUsersRole}
                    onSelectOption={setNewUsersRole as (userRole: TeamMemberRole) => void}
                />
                <WorkspaceDropdownMultiSelect
                    workspacesData={workspacesData}
                    selectedWorkspaceIds={newUsersWorkspaceIds}
                    onSelectWorkspace={handleSelectWorkspace}
                    onSelectAllWorkspaces={handleSelectAllWorkspaces}
                />
                <div className="h-12">
                    {sendingInvite ? (
                        <LoadingSpinner size={5} />
                    ) : (
                        <div className="flex">
                            <ButtonMain type="submit">Send Invite</ButtonMain>
                            <ButtonMain className="ml-2" style="neutral" onClick={closeModal}>
                                Cancel
                            </ButtonMain>
                        </div>
                    )}
                </div>
                {errorNetworkSendingInvite && (
                    <p className="mt-4 text-sm text-red">
                        Something went wrong. Please try sending the invite again.
                    </p>
                )}
                {errorInviteAlreadyExists && (
                    <p className="mt-4 text-sm text-red">
                        Sorry. This person has already been invited.
                    </p>
                )}
                {errorTooManyTeamMembers && (
                    <div className="mt-4 flex items-center">
                        <p className="text-sm text-red">
                            Sorry. You have reached your limit of team members.
                        </p>
                        <button
                            className="ml-2 text-sm underline"
                            onClick={() => navigateTo('/billing')}
                        >
                            Update your plan
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};
