import React from 'react';
import { VerticalSpacer } from './VerticalSpacer';

interface FormStepLayoutProps {
    readonly title: string;
    readonly step: '1-of-1' | '1-of-2' | '2-of-2' | '1-of-4' | '2-of-4' | '3-of-4' | '4-of-4';
    readonly description?: JSX.Element;
    readonly children: React.ReactNode;
    readonly buttons?: JSX.Element;
    readonly onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    readonly enableWideView?: boolean;
}

export const FormStepLayout: React.FC<FormStepLayoutProps> = ({
    title,
    step,
    description,
    children,
    buttons,
    onSubmit
}) => {
    return (
        <form
            className="h-full w-full flex flex-col justify-between"
            onSubmit={onSubmit && onSubmit}
        >
            <div className="h-full w-full overflow-y-scroll flex flex-col">
                <h2 className="mt-6 xs:mt-10 font-semibold text-lg">{title}</h2>
                {description && <div className="mt-2">{description}</div>}
                <VerticalSpacer heightValue={6} />
                <div className="text-grey h-full w-full">{children}</div>
            </div>
            {buttons && <div className="py-2">{buttons}</div>}
            <div className="p-2 flex items-center justify-center">
                {step === '1-of-4' ? (
                    <>
                        <span className="h-1  w-12 bg-grey-light shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                    </>
                ) : step === '2-of-4' ? (
                    <>
                        <span className="h-1  w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1 ml-2  w-12 bg-grey-light shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                    </>
                ) : step === '3-of-4' ? (
                    <>
                        <span className="h-1  w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1 ml-2  w-12 bg-grey-light shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                    </>
                ) : step === '4-of-4' ? (
                    <>
                        <span className="h-1  w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-6 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1 ml-2  w-12 bg-grey-light shadow-md rounded-md" />
                    </>
                ) : step === '1-of-2' ? (
                    <>
                        <span className="h-1  w-12 bg-grey-light shadow-md rounded-md" />
                        <span className="h-1  ml-2 w-12 bg-grey-lighter shadow-md rounded-md" />
                    </>
                ) : step === '2-of-2' ? (
                    <>
                        <span className="h-1 w-12 bg-grey-lighter shadow-md rounded-md" />
                        <span className="h-1 ml-2 w-12 bg-grey-light shadow-md rounded-md" />
                    </>
                ) : (
                    // 1-of-1
                    <span className="h-1 bg-transparent" />
                )}
            </div>
        </form>
    );
};
