/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SnipReportRequest } from '../models/SnipReportRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static reportsSnipsCreate({
        requestBody,
    }: {
        requestBody?: SnipReportRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/reports/snips/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static reportsSnipsFormSubmissionsCreate({
        linkId,
    }: {
        linkId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/reports/snips/{link_id}/form_submissions',
            path: {
                'link_id': linkId,
            },
        });
    }

}
