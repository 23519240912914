import React from 'react';
import { AnalyticsEmailFrequencyEnum, UserService } from './api';
import { ButtonMain } from './components/ButtonMain';
import { LoadingSpinner } from './components/LoadingSpinner';
import { LegacyModal } from './components/Modal';

interface ModalEmailSubscriptionProps {
    readonly closeModal: () => void;
}

export const ModalEmailSubscription: React.FC<ModalEmailSubscriptionProps> = ({ closeModal }) => {
    const [enableEducationalEmails, setEnableEducationalEmails] =
        React.useState<boolean>(undefined);

    const [enableAnnouncementsEmails, setEnableAnnouncementsEmails] =
        React.useState<boolean>(undefined);

    const [enableSupportEmails, setEnableSupportEmails] = React.useState<boolean>(undefined);
    const [analyticsReportInterval, setAnalyticsReportInterval] =
        React.useState<AnalyticsEmailFrequencyEnum>(undefined);

    const [fetchingInitialData, setFetchingInitialData] = React.useState(false);
    const [errorFetchingInitialData, setErrorFetchingInitialData] = React.useState(false);

    const [updatingData, setUpdatingData] = React.useState(false);
    const [errorUpdatingData, setErrorUpdatingData] = React.useState(false);
    const [successUpdatingData, setSuccessUpdatingData] = React.useState(false);

    const handleSubmitEmailSubscriptionSettings = React.useCallback(
        (event) => {
            event.preventDefault();
            setUpdatingData(true);

            UserService.userProfilePartialUpdate({
                requestBody: {
                    educational_email_unsubscribed: !enableEducationalEmails,
                    announcement_email_unsubscribed: !enableAnnouncementsEmails,
                    support_email_unsubscribed: !enableSupportEmails,
                    analytics_email_frequency: analyticsReportInterval
                }
            })
                .then(() => {
                    setUpdatingData(false);
                    setSuccessUpdatingData(true);
                    setTimeout(() => setSuccessUpdatingData(false), 5000);
                })
                .catch((error) => {
                    console.error('Error changing amp settings.', error);
                    setErrorUpdatingData(true);
                    setUpdatingData(false);
                });
        },
        [
            enableEducationalEmails,
            enableAnnouncementsEmails,
            enableSupportEmails,
            analyticsReportInterval,
            setUpdatingData
        ]
    );

    React.useEffect(() => {
        setFetchingInitialData(true);

        UserService.userRetrieve()
            .then((result) => {
                setFetchingInitialData(false);

                console.log(result);

                setEnableEducationalEmails(!result.profile.educational_email_unsubscribed);
                setEnableAnnouncementsEmails(!result.profile.announcement_email_unsubscribed);
                setEnableSupportEmails(!result.profile.support_email_unsubscribed);
                setAnalyticsReportInterval(result.profile.analytics_email_frequency);
            })
            .catch((error) => {
                console.error('Error fetching from TeamService.teamRetrieve()', error);
                setFetchingInitialData(false);
                setErrorFetchingInitialData(true);
            });
    }, []);

    return (
        <LegacyModal titleText="Email subscription settings" onCloseClick={closeModal}>
            {fetchingInitialData ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={5} />
                </div>
            ) : errorFetchingInitialData ? (
                <p className="mt-2 text-xs text-red">
                    Unable to retrieve your current subscription settings. Please try again by
                    reloading he browser window.
                </p>
            ) : (
                <form onSubmit={handleSubmitEmailSubscriptionSettings}>
                    <div className="grid grid-cols-2 gap-6 p-4">
                        <div>
                            <h4>Email Lists</h4>
                            <p className="text-xs text-grey-light">Subscriptions to lists</p>
                            <div className="mt-8 grid gap-y-4">
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="educational"
                                        type="checkbox"
                                        checked={enableEducationalEmails}
                                        onChange={() =>
                                            setEnableEducationalEmails(
                                                enableEducationalEmails ? false : true
                                            )
                                        }
                                    />
                                    <label className="ml-3" htmlFor="educational">
                                        <p className="text-sm">Educational</p>
                                        <p className="text-xs text-grey-light">
                                            Tips and tricks for improving performance
                                        </p>
                                    </label>
                                </div>
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="announcements"
                                        type="checkbox"
                                        checked={enableAnnouncementsEmails}
                                        onChange={() =>
                                            setEnableAnnouncementsEmails(
                                                enableAnnouncementsEmails ? false : true
                                            )
                                        }
                                    />
                                    <label className="ml-3" htmlFor="announcements">
                                        <p className="text-sm">Announcements</p>
                                        <p className="text-xs text-grey-light">
                                            Important announcements and updates
                                        </p>
                                    </label>
                                </div>
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="support"
                                        type="checkbox"
                                        checked={enableSupportEmails}
                                        onChange={() =>
                                            setEnableSupportEmails(
                                                enableSupportEmails ? false : true
                                            )
                                        }
                                    />
                                    <label className="ml-3" htmlFor="support">
                                        <p className="text-sm">Support</p>
                                        <p className="text-xs text-grey-light">
                                            Help and support communications
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4>Analytics Reports</h4>
                            <p className="text-xs text-grey-light">
                                Summary of clicks and conversions
                            </p>
                            <div className="mt-8 grid gap-2 text-sm">
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="daily"
                                        type="radio"
                                        name="analytics-report-settings"
                                        checked={analyticsReportInterval === 'daily' ? true : false}
                                        onChange={() => setAnalyticsReportInterval('daily')}
                                    />
                                    <label className="ml-3" htmlFor="daily">
                                        Daily
                                    </label>
                                </div>
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="weekly"
                                        type="radio"
                                        name="analytics-report-settings"
                                        checked={
                                            analyticsReportInterval === 'weekly' ? true : false
                                        }
                                        onChange={() => setAnalyticsReportInterval('weekly')}
                                    />
                                    <label className="ml-3" htmlFor="weekly">
                                        Weekly
                                    </label>
                                </div>
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="monthly"
                                        type="radio"
                                        name="analytics-report-settings"
                                        checked={
                                            analyticsReportInterval === 'monthly' ? true : false
                                        }
                                        onChange={() => setAnalyticsReportInterval('monthly')}
                                    />
                                    <label className="ml-3" htmlFor="monthly">
                                        Monthly
                                    </label>
                                </div>
                                <div className="flex">
                                    <input
                                        className="mt-1.5"
                                        id="never"
                                        type="radio"
                                        name="analytics-report-settings"
                                        checked={analyticsReportInterval === 'never' ? true : false}
                                        onChange={() => setAnalyticsReportInterval('never')}
                                    />
                                    <label className="ml-3" htmlFor="never">
                                        Never
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        {updatingData ? (
                            <div className="h-9 flex w-full items-center justify-center">
                                <LoadingSpinner size={5} />
                            </div>
                        ) : (
                            <ButtonMain className="w-full xs:w-40" size="small" type="submit">
                                Save
                            </ButtonMain>
                        )}
                    </div>
                </form>
            )}
            {errorUpdatingData && (
                <p className="mt-2 text-xs text-red">
                    Something went wrong when trying to save your changes. Please try again.
                </p>
            )}
            {successUpdatingData && (
                <p className="mt-2 text-xs text-green">Your settings were successfully updated.</p>
            )}
        </LegacyModal>
    );
};
