import React from 'react';
import { BrandsService, TeamMember, TeamService, UserService } from './api';
import { AuthContext } from './auth';
import { createWorkspacesList, Workspace } from './data-transformations/create-workspaces-list';

export type TeamMembersByWorkspace = {
    readonly workspaceId: string;
    readonly teamMembers: { readonly id: string; readonly name: string }[];
}[];

function getTeamMembersByWorkspace(
    workspaces: Workspace[],
    teamMembers: TeamMember[]
): TeamMembersByWorkspace {
    return workspaces.map((workspace) => {
        const workspaceTeamMembers = [];

        for (const teamMember of teamMembers) {
            if (!teamMember.exclude_brand_ids.includes(workspace.id)) {
                workspaceTeamMembers.push({ id: teamMember.id, name: teamMember.name });
            }
        }

        return { workspaceId: workspace.id, teamMembers: workspaceTeamMembers };
    });
}

interface WorkspaceContextValue {
    readonly currentWorkspace: Workspace | undefined;
    readonly setCurrentWorkspace: (workspace: Workspace) => void;
    readonly workspaces: Workspace[];
    readonly activeWorkspaces: Workspace[];
    readonly setWorkspaces: (workspaces: Workspace[]) => void;
    readonly teamMembersByWorkspace: TeamMembersByWorkspace;
    readonly setTeamMembersByWorkspace: (teamMembersByWorkspace: TeamMembersByWorkspace) => void;
}

export const WorkspaceContext = React.createContext<WorkspaceContextValue | undefined>(undefined);

interface WorkspaceContextProviderProps {
    readonly children: React.ReactNode;
}

export const WorkspaceContextProvider: React.FC<WorkspaceContextProviderProps> = ({ children }) => {
    const [currentWorkspace, setCurrentWorkspace] = React.useState<Workspace | undefined>(
        undefined
    );
    const [workspaces, setWorkspaces] = React.useState<Workspace[]>([]);
    const [teamMembersByWorkspace, setTeamMembersByWorkspace] =
        React.useState<TeamMembersByWorkspace>([]);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && workspaces?.length < 1) {
            BrandsService.brandsList({ ordering: 'name', page: 1, pageSize: 500 })
                .then((brandsResult) => {
                    console.log('BrandsService.brandsList():', brandsResult);

                    const workspacesList = createWorkspacesList(brandsResult);

                    setWorkspaces(workspacesList);

                    UserService.userRetrieve().then((userResult) => {
                        const lastUsedWorkspace = workspacesList?.find(
                            (workspace) => workspace.id === userResult.profile.last_used_brand
                        );

                        // This takes into account the scenario where a user downgrades the billing plan
                        // which may lead to certain workspaces / brands being set to "archived". In that case,
                        // the current workspace needs to change to one which is actually active
                        // to prevent users entering a broken application.
                        const initialCurrentWorkspace = !lastUsedWorkspace
                            ? workspacesList.reverse().find((workspace) => !workspace.inActive)
                            : !lastUsedWorkspace.inActive
                            ? lastUsedWorkspace
                            : workspacesList.reverse().find((workspace) => !workspace.inActive);

                        setCurrentWorkspace(initialCurrentWorkspace);

                        TeamService.teamMembersRetrieve()
                            .then((result) => {
                                console.log('TeamService.teamMembersRetrieve()', result);
                                setTeamMembersByWorkspace(
                                    getTeamMembersByWorkspace(workspacesList, result.team_members)
                                );
                            })
                            .catch((error) => {
                                console.log(
                                    'Error fetching from  TeamService.teamMembersRetrieve(): ',
                                    error
                                );
                            });
                    });
                })
                .catch((error) => {
                    console.log('Error fetching^ from BrandsService.brandsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, workspaces]);

    return (
        <WorkspaceContext.Provider
            value={{
                currentWorkspace,
                setCurrentWorkspace,
                workspaces,
                activeWorkspaces: workspaces.filter((workspace) => !workspace.inActive),
                setWorkspaces,
                teamMembersByWorkspace,
                setTeamMembersByWorkspace
            }}
        >
            {children}
        </WorkspaceContext.Provider>
    );
};
