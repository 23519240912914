import { IsSnippable } from '../api';

export interface SnipUrls {
    readonly raw: string;
    readonly dynamic: string;
    readonly static: string | null;
    readonly summary: string;
}

export function createSnipUrlsData(isSnippableResponse: IsSnippable): SnipUrls {
    return {
        raw: isSnippableResponse?.url,
        dynamic: isSnippableResponse?.is_snippable
            ? isSnippableResponse?.url
            : isSnippableResponse?.proxy_url,
        static: isSnippableResponse?.static_page_url,
        summary: isSnippableResponse?.summary_url
    };
}
