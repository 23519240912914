import { CtaType } from '../components/CtaTypeDropdownSelect';
import { CTA } from '../api';

export function getCtaTypeFromResponse(ctaResult: CTA): CtaType {
    if (ctaResult.button_action && ctaResult.button_action.is_text === false) {
        return 'button';
    } else if (ctaResult.button_action && ctaResult.button_action.is_text === true) {
        return 'text-link';
    } else if (ctaResult.form_action) {
        return 'form';
    } else if (ctaResult.image_action) {
        return 'image-ad';
    }
}
