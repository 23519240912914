import React from 'react';

import EditIcon from './assets/icons/edit.svg';
import { ButtonMain } from './components/ButtonMain';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchInput } from './components/SearchInput';
import { TableEmptyFallback } from './components/TableEmptyFallback';
import { VerticalSpacer } from './components/VerticalSpacer';
import { useElementWidth } from './hooks/use-element-width';
import { navigateTo } from './Routing';
import { CustomDomainsListItem } from './data-transformations/create-custom-domains-list';
import { ContentElementContainer } from './components/ContentElementContainer';
import { SimpleContentBox } from './components/SimpleContentBox';
import { CustomDomainsEditRowButtonDropdown } from './CustomDomainsEditRowButtonDropdown';
import { getFirstLetterOfBaseDomain } from './utils/get-first-letter-of-base-domain';
import { BASE_DOMAIN } from './App';
import { createDomainDropdownOptions, DomainDropdownSelect } from './DomainDropdownSelect';
import { EndlessScrollContainer } from './components/EndlessScrollContainer';
import { NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD } from './CustomizationCustomDomains';

interface CustomDomainsTableHeaderProps {
    readonly numberOfDisplayedCustomDomains: number;
    readonly numberOfTotalCustomDomains: number;
}

const CustomDomainsTableHeader: React.FC<CustomDomainsTableHeaderProps> = ({
    numberOfDisplayedCustomDomains,
    numberOfTotalCustomDomains
}) => {
    return (
        <div className={`w-full grid gap-2 grid-cols-8-8-8-1 mb-1 pb-1 text-xs`}>
            <div>
                <span>Domains</span>
                {numberOfTotalCustomDomains && (
                    <span className="ml-1">{`(${numberOfDisplayedCustomDomains} out of ${numberOfTotalCustomDomains})`}</span>
                )}
            </div>
            <span>Redirect Url</span>
            <span>Status</span>
            {/* Empty span for action button column */}
            <span></span>
        </div>
    );
};

const CustomDomainsTableHeaderSmall: React.FC<CustomDomainsTableHeaderProps> = ({
    numberOfDisplayedCustomDomains,
    numberOfTotalCustomDomains
}) => {
    return (
        <div className="flex px-3 items-center justify-between">
            <div className={`w-full grid gap-2 grid-cols-8-2-1 mb-1 pb-1 text-xs`}>
                <div>
                    <span>Domains</span>
                    {numberOfTotalCustomDomains && (
                        <span className="ml-1">{`(${numberOfDisplayedCustomDomains} out of ${numberOfTotalCustomDomains})`}</span>
                    )}
                </div>
                <span>Verified</span>
                {/* Empty span for action button column */}
                <span></span>
            </div>
        </div>
    );
};

interface CustomDomainTableRowProps {
    readonly listIndex: number;
    readonly customDomain: CustomDomainsListItem;
    readonly handleEditCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        customDomain: CustomDomainsListItem
    ) => void;
    readonly handleDeleteCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        id: string
    ) => void;
    readonly handleVerifyCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        id: string
    ) => void;
}

const CustomDomainTableRow: React.FC<CustomDomainTableRowProps> = ({
    listIndex,
    customDomain,
    handleEditCustomDomain,
    handleDeleteCustomDomain,
    handleVerifyCustomDomain
}) => {
    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <div className="flex px-3 py-3 items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter">
                <div className="w-full h-full grid gap-2 grid-cols-8-8-8-1 items-center">
                    <div className="min-w-0 flex items-center">
                        <div
                            className={`min-w-0 flex items-center justify-center rounded-full h-5 w-5 text-xs ${
                                customDomain.isSubDomain
                                    ? 'bg-grey-lighter'
                                    : 'bg-primary text-white'
                            }`}
                        >
                            {getFirstLetterOfBaseDomain(customDomain.domainName)}
                        </div>
                        <span className="ml-3 text-sm truncate">{customDomain.domainName}</span>
                    </div>
                    <div className="min-w-0 flex items-center">
                        <span className="text-xs truncate">{customDomain.redirectUrl}</span>
                        <button
                            className="ml-2 shrink-0"
                            onClick={(event) => handleEditCustomDomain(event, customDomain)}
                        >
                            <img src={EditIcon} alt="edit icon" className="h-4 w-4" />
                        </button>
                    </div>
                    <div className="flex items-center">
                        <span
                            className={`w-3 h-3 rounded-full ${
                                customDomain.isVerified ? 'bg-green' : 'bg-yellow'
                            }`}
                        />
                        <span className="ml-2 text-xs">
                            {customDomain.isVerified ? 'Verified' : 'Unverified'}
                        </span>
                    </div>
                    <div className="flex justify-end items-center">
                        <CustomDomainsEditRowButtonDropdown
                            customDomain={customDomain}
                            onEditCustomDomainClick={handleEditCustomDomain}
                            onDeleteDomainClick={handleDeleteCustomDomain}
                            onVerifyDomainClick={handleVerifyCustomDomain}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const CustomDomainTableRowSmall: React.FC<CustomDomainTableRowProps> = ({
    listIndex,
    customDomain,
    handleEditCustomDomain,
    handleDeleteCustomDomain,
    handleVerifyCustomDomain
}) => {
    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <div className="flex px-3 py-3 w-full items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter">
                <div className="w-full h-full grid gap-2 grid-cols-8-2-1 items-center">
                    <div className="flex items-center min-w-0">
                        <span
                            className={`flex shrink-0 items-center justify-center rounded-full h-5 w-5 text-xs text-white ${
                                customDomain.isSubDomain ? 'bg-grey-light' : 'bg-primary'
                            }`}
                        >
                            {getFirstLetterOfBaseDomain(customDomain.domainName)}
                        </span>
                        <div className="ml-3 min-w-0">
                            <p className="truncate text-sm">{customDomain.domainName}</p>
                            {customDomain.redirectUrl && (
                                <div className="flex items-center min-w-0">
                                    <span className="mt-1 truncate text-xs text-grey-medium">
                                        {customDomain.redirectUrl}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <span
                            className={`w-3 h-3 rounded-full ${
                                customDomain.isVerified ? 'bg-green' : 'bg-yellow'
                            }`}
                        />
                    </div>
                    <div className="flex justify-end items-center">
                        <CustomDomainsEditRowButtonDropdown
                            customDomain={customDomain}
                            onEditCustomDomainClick={handleEditCustomDomain}
                            onDeleteDomainClick={handleDeleteCustomDomain}
                            onVerifyDomainClick={handleVerifyCustomDomain}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

interface CustomDomainsOverviewTableProps {
    readonly customDomainsData: CustomDomainsListItem[];
    readonly numberOfTotalCustomDomains: number;
    readonly defaultCustomDomainId: string;
    readonly isSettingDefaultDomain: boolean;
    readonly errorSettingDefaultDomain: boolean;
    readonly handleSelectDefaultCustomDomainId: (domainId: string) => void;
    readonly isLoadingInitialData: boolean;
    readonly isLoadingSearchResultsData: boolean;
    readonly hadErrorLoadingData: boolean;
    readonly hasZeroActiveCustomDomains: boolean;
    readonly handleLoadMoreCustomDomains: () => void;
    readonly isLoadingMoreData: boolean;
    readonly hasMoreResults: boolean;
    readonly searchTerm: string;
    readonly setSearchTerm: (searchTerm: string) => void;
    readonly handleEditCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        customDomain: CustomDomainsListItem
    ) => void;
    readonly handleDeleteCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        customDomainId: string
    ) => void;
    readonly handleVerifyCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        customDomainId: string
    ) => void;
}

export const CustomDomainsOverviewTable: React.FC<CustomDomainsOverviewTableProps> = ({
    customDomainsData,
    numberOfTotalCustomDomains,
    defaultCustomDomainId,
    handleSelectDefaultCustomDomainId,
    isSettingDefaultDomain,
    errorSettingDefaultDomain,
    isLoadingInitialData,
    isLoadingSearchResultsData,
    hadErrorLoadingData,
    hasZeroActiveCustomDomains,
    handleLoadMoreCustomDomains,
    isLoadingMoreData,
    hasMoreResults,
    searchTerm,
    setSearchTerm,
    handleEditCustomDomain,
    handleDeleteCustomDomain,
    handleVerifyCustomDomain
}) => {
    const containerRef = React.useRef(undefined);
    const containerWidth = useElementWidth(containerRef);
    const isSmallScreen = containerWidth < 700;
    const isLargeScreen = containerWidth >= 1024;

    return (
        <div ref={containerRef}>
            <div className={`grid gap-5 ${isLargeScreen ? 'grid-cols-3-1' : 'grid-cols-1'}`}>
                <ContentElementContainer>
                    {isLoadingInitialData || isSettingDefaultDomain ? (
                        <div className="h-screen-30 sm:h-screen-50 w-full flex items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : hadErrorLoadingData ? (
                        <div className="h-screen-30 sm:h-screen-50 w-full flex items-center justify-center">
                            <div className="text-red">
                                Apologies your Custom Domains could not be retrieved. An unexpected
                                network error has occurred. Please try again later.
                            </div>
                        </div>
                    ) : hasZeroActiveCustomDomains ? (
                        <div className="h-screen-30 sm:h-screen-50 w-full flex items-center justify-center">
                            <TableEmptyFallback
                                titleText="No Custom Domains"
                                whatToDoText="Create your first Custom Domain."
                                primaryActionButton={
                                    <ButtonMain
                                        onClick={() =>
                                            navigateTo('/customization/custom-domains/add-domain')
                                        }
                                    >
                                        + Create New Custom Domain
                                    </ButtonMain>
                                }
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="grid grid-cols-1 gap-2 sm:flex items-center w-full">
                                <div className="flex items-center sm:block sm:mr-3 sm:w-48">
                                    <p className="text-sm">Default Domain:</p>
                                    <p className="ml-2 sm:ml-0 text-xs text-grey-light">
                                        (only verified domains)
                                    </p>
                                </div>
                                <div className="w-full">
                                    <DomainDropdownSelect
                                        options={createDomainDropdownOptions(customDomainsData)}
                                        isLoadingOptions={isLoadingInitialData}
                                        defaultOption={{ id: 'default', name: BASE_DOMAIN }}
                                        selectedOptionId={defaultCustomDomainId}
                                        onSelectOption={handleSelectDefaultCustomDomainId}
                                        showAddDomainButton={false}
                                    />
                                </div>
                            </div>
                            <div className="h-4">
                                {errorSettingDefaultDomain && (
                                    <p className="text-sm text-red ">
                                        We could not set your default domain due to an unexpected
                                        error. Please try again.
                                    </p>
                                )}
                            </div>
                            <VerticalSpacer heightValue={4} />
                            <div className="flex items-center justify-end">
                                <SearchInput
                                    searchTermState={searchTerm}
                                    setSearchTermState={setSearchTerm}
                                    onClearSearch={() => setSearchTerm('')}
                                    placeholderText="Search"
                                    isCollapsible={false}
                                    isRounded
                                />
                                <button
                                    className="ml-3 border border-grey-light rounded-full py-1 px-2 text-sm text-grey"
                                    onClick={() =>
                                        navigateTo('/customization/custom-domains/add-domain')
                                    }
                                >
                                    + Add Domain
                                </button>
                            </div>
                            <VerticalSpacer heightValue={3} />
                            <hr className="border-t border-grey-lighter" />
                            <VerticalSpacer heightValue={4} />
                            {isSmallScreen ? (
                                <CustomDomainsTableHeaderSmall
                                    numberOfDisplayedCustomDomains={customDomainsData?.length ?? 0}
                                    numberOfTotalCustomDomains={numberOfTotalCustomDomains}
                                />
                            ) : (
                                <CustomDomainsTableHeader
                                    numberOfDisplayedCustomDomains={customDomainsData?.length ?? 0}
                                    numberOfTotalCustomDomains={numberOfTotalCustomDomains}
                                />
                            )}

                            <div className="mt-1 bg-white rounded-sm">
                                <div className="h-screen-30 sm:h-screen-50">
                                    {isLoadingSearchResultsData ? (
                                        <div className="w-full h-full flex items-center justify-center">
                                            <LoadingSpinner size={10} />
                                        </div>
                                    ) : (
                                        <EndlessScrollContainer
                                            height="h-screen-30 sm:h-screen-50"
                                            showLoadMoreAtAll={
                                                customDomainsData.length >=
                                                NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD
                                            }
                                            onReachedScrollBottom={handleLoadMoreCustomDomains}
                                            onLoadMoreClick={handleLoadMoreCustomDomains}
                                            isLoadingMoreData={isLoadingMoreData}
                                            hasMoreResults={hasMoreResults}
                                        >
                                            {customDomainsData?.length > 0 && (
                                                <div className="w-full">
                                                    {customDomainsData?.map(
                                                        (customDomain, listIndex) =>
                                                            isSmallScreen ? (
                                                                <CustomDomainTableRowSmall
                                                                    key={customDomain.id}
                                                                    listIndex={listIndex}
                                                                    customDomain={customDomain}
                                                                    handleEditCustomDomain={
                                                                        handleEditCustomDomain
                                                                    }
                                                                    handleDeleteCustomDomain={
                                                                        handleDeleteCustomDomain
                                                                    }
                                                                    handleVerifyCustomDomain={
                                                                        handleVerifyCustomDomain
                                                                    }
                                                                />
                                                            ) : (
                                                                <CustomDomainTableRow
                                                                    key={customDomain.id}
                                                                    listIndex={listIndex}
                                                                    customDomain={customDomain}
                                                                    handleEditCustomDomain={
                                                                        handleEditCustomDomain
                                                                    }
                                                                    handleDeleteCustomDomain={
                                                                        handleDeleteCustomDomain
                                                                    }
                                                                    handleVerifyCustomDomain={
                                                                        handleVerifyCustomDomain
                                                                    }
                                                                />
                                                            )
                                                    )}
                                                </div>
                                            )}
                                        </EndlessScrollContainer>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </ContentElementContainer>
                <ContentElementContainer>
                    <SimpleContentBox headerText="Custom Domains">
                        <div className="font-normal text-sm text-grey">
                            <p>
                                Connect your own custom domain to create links using a personal,
                                branded domain. Instead of using snip.ly links such as snip.ly/abc,
                                you can create links like mysite.co/abc.
                            </p>
                            <br />
                            <p>
                                <span>
                                    Here you can find more information about how to set up Custom
                                    Domains:{'  '}
                                </span>
                                <a
                                    href="https://support.snip.ly/hc/en-us/articles/20000907424532-How-do-I-create-snips-using-my-own-custom-domain-"
                                    className="text-primary underline"
                                >
                                    see guide
                                </a>
                                <span>.</span>
                            </p>
                        </div>
                    </SimpleContentBox>
                </ContentElementContainer>
            </div>
        </div>
    );
};
