import React from 'react';
import { Traffic } from '../api';
import TrafficDirectIcon from '../assets/icons/traffic-direct.svg';
import TrafficReferralIcon from '../assets/icons/traffic-referral.svg';
interface TrafficTableProps {
    readonly dataEntries: Traffic[];
}

export const TrafficTable: React.FC<TrafficTableProps> = ({ dataEntries }) =>
    dataEntries && (
        <div>
            <div className="py-1 grid grid-cols-2-1-1 min-w-0 text-xs text-grey-light">
                <span className="truncate">Source</span>
                <span className="truncate">Clicks</span>
                <span className="truncate">Conversions</span>
            </div>
            <div className="h-40 overflow-y-scroll">
                {dataEntries?.map((entry) => (
                    <div
                        key={entry.source}
                        className="py-3 grid grid-cols-2-1-1 min-w-0 border-t border-grey-lighter text-sm"
                    >
                        <div className="flex items-center">
                            <img
                                className="h-5 w-5"
                                src={
                                    entry.source === '(direct)'
                                        ? TrafficDirectIcon
                                        : TrafficReferralIcon
                                }
                            />
                            <span className="ml-2">{entry.source}</span>
                        </div>
                        <span>{entry.clicks}</span>
                        <span>{entry.conversions}</span>
                    </div>
                ))}
            </div>
        </div>
    );
