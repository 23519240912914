import React from 'react';
import { CtasService } from './api';
import { AuthContext } from './auth';
import { DropdownSelect } from './components/DropdownSelect';
import { createCtasListForDropdown } from './data-transformations/create-ctas-list';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface CtaDropdownSelectProps {
    readonly titleText?: string;
    readonly selectedCtaId: string;
    readonly setSelectedCtaId: (selectedCtaId: string) => void;
}

export const CtaDropdownSelect: React.FC<CtaDropdownSelectProps> = ({
    titleText,
    selectedCtaId,
    setSelectedCtaId
}) => {
    const [ctasData, setCtasData] = React.useState([]);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            CtasService.ctasList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);
                    setCtasData(createCtasListForDropdown(result));
                })
                .catch((error) => {
                    console.error('Error fetching from FeedsService.feedsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return (
        <DropdownSelect
            titleText={titleText}
            placeholderText="Select Call-To-Action"
            options={ctasData}
            selectedOption={selectedCtaId}
            onSelectOption={(option) => setSelectedCtaId(option)}
        />
    );
};
