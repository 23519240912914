import React from 'react';
import DeleteIconWhite from './assets/icons/delete-white.svg';
import VerifiedIconWhite from './assets/icons/award-white.svg';
import PendingVerificationIconWhite from './assets/icons/coffee-white.svg';

import { CustomDomainsAddDomain } from './CustomizationCustomDomainsAddDomain';
import { CustomDomainsOverviewTable } from './CustomDomainsOverviewTable';
import { navigateTo, useCurrentUrlPath } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { BrandsService, CustomDomainsService, CustomDomainUpdate } from './api';
import {
    createCustomDomain,
    createCustomDomainsList,
    CustomDomainsListItem
} from './data-transformations/create-custom-domains-list';
import { CustomDomainsDomainDetails } from './CustomizationCustomDomainsConnectDomain';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { CustomDomainsWhoSetsUp } from './CustomizationCustomDomainsWhoSetsUp';
import { NotificationContext } from './NotificationContextProvider';

export const SNIPLY_LOAD_BALANCER_LINK = 'lb-production.sniply.site';

export const NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD = 10;

export function includesSubdomain(domain: string): boolean {
    return domain.split('.').length > 2;
}

function reduceEditedDomain(
    customDomains: CustomDomainsListItem[],
    editingRedirectUrlResponse: CustomDomainUpdate,
    existingDomainId: string
): CustomDomainsListItem[] {
    return customDomains.map((domain) =>
        domain.id === existingDomainId
            ? { ...domain, redirectUrl: editingRedirectUrlResponse.fallback_url }
            : domain
    );
}

export const CustomDomainsContentGrid = ({ children }) => {
    return <div className="grid grid-cols-1 xl:grid-cols-2-1 gap-10">{children}</div>;
};

export type CustomDomainsFlowView = 'overview' | 'create-new';

export const CustomizationCustomDomains = () => {
    // Form Values - start
    const [domainId, setDomainId] = React.useState('');
    const [domainName, setDomainName] = React.useState('');
    const [subDomainName, setSubDomainName] = React.useState('');

    const [redirectUrl, setRedirectUrl] = React.useState('');

    const [showSelfManagedInfo, setShowSelfManagedInfo] = React.useState(true);
    // const [isVerified, setIsVerified] = React.useState(false);

    const [nameServers, setNameservers] = React.useState<string[]>([]);
    // Form Values - end

    const [defaultCustomDomainId, setDefaultCustomDomainId] = React.useState('');
    const [isSettingDefaultDomain, setIsSettingDefaultDomain] = React.useState(false);
    const [errorSettingDefaultDomain, setErrorSettingDefaultDomain] = React.useState(false);

    // Domain Check States --- Start
    const [isDomainAvailable, setIsDomainAvailable] = React.useState(false);
    const [checkingDomain, setCheckingDomain] = React.useState(false);

    const [showDomainNotAvailableWarning, setShowDomainNotAvailableWarning] = React.useState(false);
    const [showSubDomainNotAvailableWarning, setShowSubDomainNotAvailableWarning] =
        React.useState(false);

    const [errorCheckingDomainAvailability, setErrorCheckingDomainAvailability] =
        React.useState(false);

    const [errorCheckingSubDomainAvailability, setErrorCheckingSubDomainAvailability] =
        React.useState(false);
    // Domain Check States --- End

    const [errorInvalidDomainName, setErrorInvalidDomainName] = React.useState(false);
    const [errorCreatingDomain, setErrorCreatingDomain] = React.useState(false);

    const [errorDomainAlreadyInUse, setErrorDomainAlreadyInUse] = React.useState(false);
    const [errorInvalidRedirectUrl, setErrorInvalidRedirectUrl] = React.useState(false);

    const [customDomainsData, setCustomDomainsData] = React.useState<CustomDomainsListItem[]>();
    const [numberOfTotalCustomDomains, setNumberOfTotalCustomDomains] = React.useState<number>();
    const [searchResultsData, setSearchResultsData] = React.useState<CustomDomainsListItem[]>();

    const [searchTerm, setSearchTerm] = React.useState('');

    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [isLoadingSearchResultData, setIsLoadingSearchResultData] = React.useState(false);
    const [hasLoadedDataWithZeroResults, setHasLoadedDataWithZeroResults] = React.useState(false);

    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    const [isLoadingMoreData, setIsLoadingMoreData] = React.useState(false);
    const [numberOfPagesLoaded, setNumberOfPagesLoaded] = React.useState(0);
    const [hasMorePages, setHasMorePages] = React.useState(true);

    const [isCreatingCustomDomain, setIsCreatingCustomDomain] = React.useState(false);
    const [isSavingRedirectUrl, setIsSavingRedirectUrl] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);
    const { level3, level4 } = useCurrentUrlPath();

    const handleLoadCustomDomainsData = React.useCallback(() => {
        setIsLoadingData(true);
        setHadErrorLoadingData(false);

        CustomDomainsService.customDomainsList({
            brand: currentWorkspace?.id,
            page: 1,
            pageSize: NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD
        })
            .then((result) => {
                console.log('CustomDomainsService.customDomainsList()', result);

                setCustomDomainsData(createCustomDomainsList(result));
                setNumberOfTotalCustomDomains(result.count);
                setNumberOfPagesLoaded(1);

                setIsLoadingData(false);
                result.results.length === 0 && setHasLoadedDataWithZeroResults(true);
            })
            .catch((error) => {
                console.log(
                    'Error fetching from CustomDomainsService.customDomainsList(): ',
                    error
                );
                setHadErrorLoadingData(true);
                setIsLoadingData(false);
            });
    }, [currentWorkspace]);

    const handleLoadMoreCustomDomainsData = React.useCallback(() => {
        if (!isLoadingMoreData && hasMorePages) {
            setIsLoadingMoreData(true);

            CustomDomainsService.customDomainsList({
                brand: currentWorkspace?.id,
                search: searchTerm,
                page: numberOfPagesLoaded + 1,
                pageSize: NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD
            })
                .then((result) => {
                    console.log('CustomDomainsService.customDomainsList()', result);

                    setNumberOfPagesLoaded(numberOfPagesLoaded + 1);

                    searchTerm
                        ? setSearchResultsData([
                              ...searchResultsData,
                              ...createCustomDomainsList(result)
                          ])
                        : setCustomDomainsData([
                              ...customDomainsData,
                              ...createCustomDomainsList(result)
                          ]);

                    setIsLoadingMoreData(false);
                })
                .catch((error) => {
                    console.log(
                        'Error fetching more domains from CustomDomainsService.customDomainsList(): ',
                        error
                    );

                    if (error.body?.detail?.startsWith('Invalid page')) {
                        setHasMorePages(false);
                    }

                    setIsLoadingMoreData(false);
                });
        }
    }, [
        currentWorkspace,
        hasMorePages,
        isLoadingMoreData,
        numberOfPagesLoaded,
        customDomainsData,
        searchResultsData,
        searchTerm
    ]);

    const handleCreateCustomDomain = React.useCallback(
        (domain: string, isSelfManaged: boolean) => {
            setIsCreatingCustomDomain(true);

            CustomDomainsService.customDomainsCreate({
                requestBody: {
                    brand: currentWorkspace.id,
                    custom_domain: domain,
                    is_self_managed: isSelfManaged
                }
            })
                .then((result) => {
                    console.log('CustomDomainsService.customDomainsCreate()', result);

                    setShowSelfManagedInfo(result.is_self_managed);
                    setNameservers(result.custom_domain_nameservers);
                    setCustomDomainsData([createCustomDomain(result), ...customDomainsData]);

                    setDomainId(result.id);
                    navigateTo('/customization/custom-domains/domain-details/add');
                    setIsCreatingCustomDomain(false);
                })
                .catch((error) => {
                    console.error('Error creating custom domain.', error);

                    if (
                        error.body?.non_field_errors?.[0].startsWith(
                            'The fields brand, custom_domain must make a unique set.'
                        )
                    ) {
                        setErrorDomainAlreadyInUse(true);
                    } else {
                        setErrorCreatingDomain(true);
                    }
                    setIsCreatingCustomDomain(false);
                });
        },
        [currentWorkspace, customDomainsData]
    );

    const handleAddDomainWithSubdomain = React.useCallback(
        (domainWithSubdomain: string) => {
            if (showSubDomainNotAvailableWarning) {
                // Continue anyways
                handleCreateCustomDomain(domainWithSubdomain, true);
            }

            !subDomainName && setCheckingDomain(true);

            CustomDomainsService.customDomainsCheckDomainIsInUseCreate({
                requestBody: { domain: domainWithSubdomain }
            })
                .then((result) => {
                    console.log(
                        'CustomDomainsService.customDomainsCheckDomainIsInUseCreate()',
                        result
                    );

                    setIsDomainAvailable(!result.is_used);

                    if (!result.is_used) {
                        handleCreateCustomDomain(domainWithSubdomain, true);
                    } else {
                        setShowSubDomainNotAvailableWarning(true);
                    }

                    !subDomainName && setCheckingDomain(false);
                })
                .catch((error) => {
                    console.log(
                        'CustomDomainsService.customDomainsCheckDomainIsInUseCreate(): ',
                        error
                    );

                    if (error.body.domain?.[0].startsWith('Invalid domain name')) {
                        setErrorInvalidDomainName(true);
                    } else {
                        setErrorCheckingSubDomainAvailability(true);
                    }

                    !subDomainName && setCheckingDomain(false);
                });
        },
        [handleCreateCustomDomain, showSubDomainNotAvailableWarning, subDomainName]
    );

    const handleAddStandardDomain = React.useCallback(() => {
        if (!showDomainNotAvailableWarning) {
            setCheckingDomain(true);

            CustomDomainsService.customDomainsCheckDomainIsInUseCreate({
                requestBody: { domain: domainName }
            })
                .then((result) => {
                    console.log(
                        'CustomDomainsService.customDomainsCheckDomainIsInUseCreate()',
                        result
                    );

                    setIsDomainAvailable(!result.is_used);

                    if (!result.is_used) {
                        navigateTo('/customization/custom-domains/who-sets-up');
                    } else {
                        setShowDomainNotAvailableWarning(true);
                    }

                    setCheckingDomain(false);
                })
                .catch((error) => {
                    console.log(
                        'CustomDomainsService.customDomainsCheckDomainIsInUseCreate(): ',
                        error
                    );

                    if (error.body.domain?.[0].startsWith('Invalid domain name')) {
                        setErrorInvalidDomainName(true);
                    } else {
                        setErrorCheckingDomainAvailability(true);
                    }

                    setCheckingDomain(false);
                });
        } else if (!subDomainName && showDomainNotAvailableWarning) {
            // if continue anyways with unavailable domain (no subdomain selected)
            navigateTo('/customization/custom-domains/who-sets-up');
        } else if (subDomainName && !showSubDomainNotAvailableWarning) {
            handleAddDomainWithSubdomain(`${subDomainName}.${domainName}`);
        } else if (subDomainName && showSubDomainNotAvailableWarning) {
            // if continue anyways with unavailable subdomain
            // from dedicated subdomain input field
            handleCreateCustomDomain(`${subDomainName}.${domainName}`, true);
        }
    }, [
        domainName,
        subDomainName,
        handleCreateCustomDomain,
        handleAddDomainWithSubdomain,
        showDomainNotAvailableWarning,
        showSubDomainNotAvailableWarning
    ]);

    const handleSubmitCustomDomain = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (domainName && includesSubdomain(domainName)) {
                handleAddDomainWithSubdomain(domainName);
            } else if (domainName && !includesSubdomain(domainName)) {
                handleAddStandardDomain();
            }
        },
        [domainName, handleAddDomainWithSubdomain, handleAddStandardDomain]
    );

    const handleSaveRedirectUrl = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (level4 === 'add' && !redirectUrl) {
                navigateTo('/customization/custom-domains');
            }

            if (redirectUrl && !isValidUrlWithProtocol(ensureUrlHasProtocol(redirectUrl))) {
                setErrorInvalidRedirectUrl(true);
            } else {
                setIsSavingRedirectUrl(true);

                CustomDomainsService.customDomainsEditPartialUpdate({
                    id: domainId,
                    requestBody: {
                        fallback_url: !redirectUrl ? '' : ensureUrlHasProtocol(redirectUrl)
                    }
                })
                    .then((result) => {
                        console.log(
                            'CustomDomainsService.customDomainsEditPartialUpdate()',
                            result
                        );

                        setCustomDomainsData(
                            reduceEditedDomain(customDomainsData, result, domainId)
                        );

                        navigateTo('/customization/custom-domains');
                        setIsSavingRedirectUrl(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsSavingRedirectUrl(false);
                    });
            }
        },
        [domainId, redirectUrl, customDomainsData, level4]
    );

    const handleEditCustomDomain = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, customDomain: CustomDomainsListItem) => {
            event.preventDefault();

            setDomainId(customDomain.id);
            setDomainName(customDomain.domainName);
            setRedirectUrl(customDomain.redirectUrl);

            setShowSelfManagedInfo(customDomain.isSelfManaged);
            console.log('setting setShowSelfManagedInfo to:', customDomain.isSelfManaged);
            setNameservers(customDomain.nameServers);

            navigateTo('/customization/custom-domains/domain-details/edit');
        },
        []
    );

    const handleSelectDefaultCustomDomainId = React.useCallback(
        (customDomainId: string) => {
            setErrorSettingDefaultDomain(false);

            setIsSettingDefaultDomain(true);
            BrandsService.brandsPartialUpdate({
                id: currentWorkspace.id,
                requestBody: {
                    default_custom_domain_id: customDomainId === 'default' ? null : customDomainId
                }
            })
                .then(() => {
                    setDefaultCustomDomainId(customDomainId);
                    setIsSettingDefaultDomain(false);
                })
                .catch((error) => {
                    setErrorSettingDefaultDomain(true);
                    setIsSettingDefaultDomain(false);
                    console.error('Error setting default custom domain.', error);
                });
        },
        [currentWorkspace]
    );

    const handleCancel = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setDomainId('');
        setDomainName('');
        setSubDomainName('');
        setRedirectUrl('');
        setNameservers([]);

        navigateTo('/customization/custom-domains');
    }, []);

    const handleVerifyCustomDomain = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, customDomainId: string) => {
            event.preventDefault();

            CustomDomainsService.customDomainsVerifyPartialUpdate({ id: customDomainId })
                .then((result) => {
                    console.log('CustomDomainsService.customDomainsVerifyPartialUpdate()', result);
                    if (result.verified) {
                        // if the domain is now verified

                        setCustomDomainsData(
                            customDomainsData.map((domain) => {
                                if (domain.id === customDomainId) {
                                    return { ...domain, isVerified: true };
                                } else {
                                    return domain;
                                }
                            })
                        );

                        handleOpenNotification({
                            iconSrc: VerifiedIconWhite,
                            type: 'success',
                            messageText: 'Your domain is verified.',
                            showTimeInSeconds: 3
                        });
                    } else {
                        handleOpenNotification({
                            iconSrc: PendingVerificationIconWhite,
                            type: 'warning',
                            messageText: 'Your domain could not yet be verified.',
                            showTimeInSeconds: 3
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error verfying custom domain', error);
                });
        },
        [customDomainsData, handleOpenNotification]
    );

    const handleDeleteCustomDomain = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, customDomainId: string) => {
            event.preventDefault();

            CustomDomainsService.customDomainsDeleteDestroy({ id: customDomainId })
                .then(() => {
                    setCustomDomainsData(
                        customDomainsData.filter(
                            (customDomain) => customDomain.id !== customDomainId
                        )
                    );
                    handleOpenNotification({
                        iconSrc: DeleteIconWhite,
                        type: 'success',
                        messageText: 'Successfully deleted custom domain.',
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => console.error(error));
        },
        [customDomainsData, handleOpenNotification]
    );

    React.useEffect(() => {
        if (currentWorkspace) {
            handleLoadCustomDomainsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWorkspace]);

    const searchRequestIndex = React.useRef(0);

    React.useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            const currentSearchRequestIndex = searchRequestIndex.current + 1;

            searchRequestIndex.current = currentSearchRequestIndex;

            setIsLoadingSearchResultData(true);

            setTimeout(() => {
                if (currentSearchRequestIndex === searchRequestIndex.current) {
                    CustomDomainsService.customDomainsList({
                        brand: currentWorkspace?.id,
                        search: searchTerm,
                        page: 1,
                        pageSize: NUMBER_OF_CUSTOM_DOMAINS_PER_LOAD
                    })
                        .then((result) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'Loading search-based Snips via CustomDomainsService.customDomainsList()',
                                    result
                                );

                                setSearchResultsData(createCustomDomainsList(result));
                                setIsLoadingSearchResultData(false);
                                setNumberOfPagesLoaded(1);

                                result.results.length === 0 &&
                                    setHasLoadedDataWithZeroResults(true);
                            }
                        })
                        .catch((error) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'Error fetching from CustomDomainsService.customDomainsList(): ',
                                    error
                                );
                                setIsLoadingSearchResultData(false);
                            }
                        });
                }
            }, 1000);
        } else {
            setSearchResultsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    React.useEffect(() => {
        if (errorInvalidDomainName) {
            setErrorInvalidDomainName(false);
        }

        if (errorDomainAlreadyInUse) {
            setErrorDomainAlreadyInUse(false);
        }

        if (showDomainNotAvailableWarning) {
            setShowDomainNotAvailableWarning(false);
        }

        if (showSubDomainNotAvailableWarning) {
            setShowSubDomainNotAvailableWarning(false);
        }

        if (subDomainName) {
            setSubDomainName('');
        }

        if (errorCheckingDomainAvailability) {
            setErrorCheckingDomainAvailability(false);
        }

        if (errorCheckingSubDomainAvailability) {
            setErrorCheckingSubDomainAvailability(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainName]);

    React.useEffect(() => {
        if (errorDomainAlreadyInUse) {
            setErrorDomainAlreadyInUse(false);
        }

        if (showSubDomainNotAvailableWarning) {
            setShowSubDomainNotAvailableWarning(false);
        }

        if (errorCheckingSubDomainAvailability) {
            setErrorCheckingSubDomainAvailability(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subDomainName]);

    React.useEffect(() => {
        if (errorInvalidRedirectUrl) {
            setErrorInvalidRedirectUrl(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUrl]);

    const customDomainsDataToDisplay =
        searchResultsData && searchTerm.length > 2 ? searchResultsData : customDomainsData;

    React.useEffect(() => {
        if (currentWorkspace) {
            setDefaultCustomDomainId(currentWorkspace.defaultCustomDomainId || 'default');
        }
    }, [currentWorkspace]);

    React.useEffect(() => {
        if (!domainName) {
            navigateTo('/customization/custom-domains');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setErrorSettingDefaultDomain(false);
    }, [defaultCustomDomainId]);

    return level3 === 'add-domain' ? (
        <CustomDomainsAddDomain
            domainName={domainName}
            setDomainName={setDomainName}
            subDomainName={subDomainName}
            setSubDomainName={setSubDomainName}
            onSubmitCustomDomain={handleSubmitCustomDomain}
            onCancel={handleCancel}
            errorDomainAlreadyInUse={errorDomainAlreadyInUse}
            checkingDomain={checkingDomain}
            isDomainAvailable={isDomainAvailable}
            showDomainNotAvailableWarning={showDomainNotAvailableWarning}
            showSubDomainNotAvailableWarning={showSubDomainNotAvailableWarning}
            isCreatingCustomDomain={isCreatingCustomDomain}
            errorInvalidDomainName={errorInvalidDomainName}
            errorCreatingDomain={errorCheckingDomainAvailability || errorCreatingDomain}
            errorCreatingSubDomain={errorCheckingSubDomainAvailability}
        />
    ) : level3 === 'who-sets-up' ? (
        <CustomDomainsWhoSetsUp
            onCreateCustomDomain={(event, isSelfManaged) => {
                event.preventDefault();
                handleCreateCustomDomain(domainName, isSelfManaged);
            }}
            errorCreatingDomain={errorCreatingDomain}
            errorDomainAlreadyInUse={errorDomainAlreadyInUse}
        />
    ) : level3 === 'domain-details' ? (
        <CustomDomainsDomainDetails
            domainName={subDomainName ? `${subDomainName}.${domainName}` : domainName}
            redirectUrl={redirectUrl}
            setRedirectUrl={setRedirectUrl}
            showSelfManagedInfo={showSelfManagedInfo}
            nameServers={nameServers}
            onSaveRedirectUrl={handleSaveRedirectUrl}
            onCancel={handleCancel}
            isSavingDomain={isSavingRedirectUrl}
            errorInvalidRedirectUrl={errorInvalidRedirectUrl}
        />
    ) : (
        <CustomDomainsOverviewTable
            customDomainsData={customDomainsDataToDisplay}
            numberOfTotalCustomDomains={numberOfTotalCustomDomains}
            defaultCustomDomainId={defaultCustomDomainId}
            handleSelectDefaultCustomDomainId={handleSelectDefaultCustomDomainId}
            isSettingDefaultDomain={isSettingDefaultDomain}
            errorSettingDefaultDomain={errorSettingDefaultDomain}
            isLoadingInitialData={isLoadingData}
            isLoadingSearchResultsData={isLoadingSearchResultData}
            hadErrorLoadingData={hadErrorLoadingData}
            hasZeroActiveCustomDomains={
                hasLoadedDataWithZeroResults && customDomainsData.length < 1 && !searchTerm
            }
            handleLoadMoreCustomDomains={handleLoadMoreCustomDomainsData}
            isLoadingMoreData={isLoadingMoreData}
            hasMoreResults={hasMorePages}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleEditCustomDomain={handleEditCustomDomain}
            handleDeleteCustomDomain={handleDeleteCustomDomain}
            handleVerifyCustomDomain={handleVerifyCustomDomain}
        />
    );
};
