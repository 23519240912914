import React from 'react';

import EnterprisePlanIcon from './assets/icons/enterprise-plan.svg';
import BasicPlanIcon from './assets/icons/basic-plan.svg';
import BusinessPlanIcon from './assets/icons/business-plan.svg';
import HyperlinkIcon from './assets/icons/hyperlink.svg';
import ProPlanIcon from './assets/icons/pro-plan.svg';
import TickIcon from './assets/icons/tick.svg';
import XIcon from './assets/icons/x.svg';
import { PlanEnum, PlansService, StripeNameEnum, StripeService } from './api';
import { ButtonMain } from './components/ButtonMain';
import { FocusViewLayout } from './components/FocusViewLayout';
import { LoadingSpinner } from './components/LoadingSpinner';
import { PlainToggle } from './components/PlainToggle';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createPlansData, PlanItem } from './data-transformations/create-plans-data';
import { UserContext, UserPlanCycle } from './UserContextProvider';
import { EnterprisePlanContactForm } from './EnterprisePlanContactForm';
import { navigateTo } from './Routing';

interface PlanCardProps {
    readonly plan: PlanItem;
    readonly isMonthlyView: boolean;
    readonly isPopularPlan: boolean;
    readonly onSelectNewPlan: (
        event: React.MouseEvent<HTMLButtonElement>,
        plan: StripeNameEnum
    ) => void;
    readonly active?: boolean;
    readonly showStartTrial?: boolean;
    readonly setShowContactForm: (value: boolean) => void;
}

const PlanCard: React.FC<PlanCardProps> = ({
    plan,
    isMonthlyView,
    isPopularPlan,
    onSelectNewPlan,
    active = false,
    showStartTrial = false,
    setShowContactForm
}) => (
    <div
        className={`flex flex-col justify-between bg-white p-4 sm:p-4 ${
            active ? 'border border-primary' : 'border border-grey-lighter'
        } rounded-lg text-grey`}
    >
        <div>
            <div className="h-6 flex justify-end">
                {isPopularPlan && (
                    <div className="p-2 flex items-center bg-primary rounded-sm text-white text-sm">
                        Popular
                    </div>
                )}
            </div>
            <div className="w-5">
                <img
                    src={
                        plan.displayName === 'Basic'
                            ? BasicPlanIcon
                            : plan.displayName === 'Pro'
                            ? ProPlanIcon
                            : plan.displayName === 'Business'
                            ? BusinessPlanIcon
                            : EnterprisePlanIcon
                    }
                    alt="plan icon"
                    className="h-5 w-5"
                />
            </div>
            <h3 className="mt-1">{plan.displayName}</h3>
            {plan.displayName === 'Enterprise' ? (
                <div className="mt-1 flex h-8">
                    <p className="text-3xl font-bold">Custom</p>
                    <div className="ml-1 flex items-end">
                        <span className="text-xs">{`/${isMonthlyView ? 'month' : 'year'}`}</span>
                    </div>
                </div>
            ) : (
                <div className="mt-1 flex h-8">
                    <div className="flex items-start font-semibold">
                        <span>$</span>
                    </div>
                    <p className="ml-1 text-3xl font-bold">{plan.price}</p>
                    <div className="ml-1 flex items-end">
                        <span className="text-xs">{`/${isMonthlyView ? 'month' : 'year'}`}</span>
                    </div>
                </div>
            )}
            <div className="my-4 sm:my-6 border-b border-grey-lighter" />
            {plan.displayName === 'Enterprise' ? (
                <div>
                    <p className="text-sm">
                        Need more than the standard offering per month with all premium features and
                        dedicated support?
                    </p>
                </div>
            ) : (
                <div>
                    <div className="flex items-center text-sm">
                        <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                        <div className="ml-2">
                            <span className="font-bold">Unlimited</span>
                            <span className="ml-1">Snips</span>
                        </div>
                    </div>
                    <div className="mt-2 flex items-center text-sm">
                        <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                        <div className="ml-2 line-clamp-1">
                            <span className="font-bold">{plan.maxCtas}</span>
                            <span className="ml-1">active Call-To-Actions (CTAs)</span>
                        </div>
                    </div>
                    <div className="mt-2 flex items-center text-sm">
                        <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                        <div className="ml-2">
                            <span className="font-bold">
                                {plan.maxCtaImpressions.toLocaleString()}
                            </span>
                            <span className="ml-1">CTA Impressions</span>
                        </div>
                    </div>
                    <div className="mt-2 flex items-center text-sm">
                        <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                        <div className="ml-2">
                            <span className="font-bold">{plan.maxWorkspaces}</span>
                            <span className="ml-1">Workspaces</span>
                        </div>
                    </div>
                    {plan.hasCustomDomain ? (
                        <div className="mt-2 flex items-center text-sm">
                            <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                            <div className="ml-2">
                                <span>Custom Snip URLs</span>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-2 flex items-center text-sm">
                            <img src={XIcon} alt="x icon" className="w-4 h-4" />
                            <div className="ml-2">
                                <span className="ml-1 text-grey-light">No Custom Snip URLs</span>
                            </div>
                        </div>
                    )}
                    {plan.hasABTesting ? (
                        <div className="mt-2 flex items-center text-sm">
                            <img src={TickIcon} alt="tick icon" className="w-4 h-4" />
                            <div className="ml-2">
                                <span>A/B Testing</span>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-2 flex items-center text-sm">
                            <img src={XIcon} alt="x icon" className="w-4 h-4" />
                            <div className="ml-2">
                                <span className="ml-1 text-grey-light">No A/B Testing</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
        <div className="mt-6">
            {!active ? (
                plan.displayName === 'Enterprise' ? (
                    <ButtonMain width="full" onClick={() => setShowContactForm(true)}>
                        Contact us
                    </ButtonMain>
                ) : (
                    <ButtonMain
                        width="full"
                        onClick={(event) =>
                            onSelectNewPlan(event, plan.stripeName as StripeNameEnum)
                        }
                    >
                        {showStartTrial ? 'Start Trial' : 'Select Plan'}
                    </ButtonMain>
                )
            ) : (
                <ButtonMain width="full" style="neutral" disabled>
                    Current Plan
                </ButtonMain>
            )}
        </div>
    </div>
);

export const SubscriptionPlans: React.FC = () => {
    const [showContactForm, setShowContactForm] = React.useState(false);

    const [monthlyPlans, setMonthlyPlans] = React.useState<PlanItem[]>([]);
    const [annualPlans, setAnnualPlans] = React.useState<PlanItem[]>([]);
    const [tabView, setTabView] = React.useState<UserPlanCycle>('monthly');

    const [fetchingIsTrialAvailable, setFetchingIsTrialAvailable] = React.useState(false);
    const [isTrialAvailable, setIsTrialAvailable] = React.useState(false);

    const [fetchingPlans, setFetchingPlans] = React.useState(false);
    const [errorFetchingPlans, setErrorFetchingPlans] = React.useState(false);
    const [errorFetchingStripeLink, setErrorFetchingStripeLink] = React.useState(false);

    const currentlyDisplayedPlans = tabView === 'monthly' ? monthlyPlans : annualPlans;

    const { userPlan, userPlanCycle } = React.useContext(UserContext);

    const handleSelectFirstPlan = React.useCallback((plan: PlanEnum) => {
        StripeService.stripeGetSessionCreate({
            requestBody: {
                plan,
                return_url: window.location.protocol + '//' + window.location.hostname
            }
        })
            .then((result) => {
                window.location.href = result.url;
            })
            .catch((getSessionError) => {
                console.error('Error generating link to Stripe Portal.', getSessionError);
                setErrorFetchingStripeLink(true);
            });
    }, []);

    const handleChangePlan = React.useCallback(() => {
        StripeService.stripePortalLinkCreate({
            requestBody: {
                return_url: window.location.protocol + '//' + window.location.hostname
            }
        })
            .then((result) => {
                window.location.href = result.stripe_portal_link;
            })
            .catch((error) => {
                console.error('Error generating link to Stripe Portal.', error);
                setErrorFetchingStripeLink(true);
            });
    }, []);

    const handleSelectPlan = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, plan: PlanEnum) => {
            event.preventDefault();
            if (
                // new user or returning user w/o active plan
                userPlan === 'Free'
            ) {
                handleSelectFirstPlan(plan); // via Stripe Get New Session
            } else {
                handleChangePlan(); // via Stripe Portal Link
            }
        },

        [handleChangePlan, handleSelectFirstPlan, userPlan]
    );

    React.useEffect(() => {
        setFetchingIsTrialAvailable(true);

        StripeService.stripeTrialAvailableRetrieve()
            .then((result) => {
                console.log('Fetching from StripeService.stripeTrialAvailableRetrieve()', result);
                setIsTrialAvailable(result.trial_available);
                setFetchingIsTrialAvailable(false);
            })
            .catch((error) => {
                console.error(
                    'Error fetching from  StripeService.stripeTrialAvailableRetrieve():',
                    error
                );
                setFetchingIsTrialAvailable(false);
            });
    }, []);

    React.useEffect(() => {
        setFetchingPlans(true);

        PlansService.plansRetrieve()
            .then((result) => {
                console.log('Fetching from PlansService.plansRetrieve()', result);
                setMonthlyPlans(createPlansData(result?.plans?.month));
                setAnnualPlans(createPlansData(result?.plans?.annual));
                setFetchingPlans(false);
            })
            .catch((error) => {
                console.error('Error fetching from  PlansService.plansRetrieve():', error);
                setFetchingPlans(false);
                setErrorFetchingPlans(true);
            });
    }, []);

    return (
        <FocusViewLayout enableWideView onBackButtonClick={() => navigateTo('/user')}>
            {fetchingPlans || fetchingIsTrialAvailable ? (
                <div className="h-full w-full flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : showContactForm ? (
                <EnterprisePlanContactForm onClose={() => setShowContactForm(false)} />
            ) : (
                <div className="bg-grey-lightest p-4">
                    <p className="mt-2 w-full text-center font-bold text-2xl sm:text-3xl">
                        Make your content work for you
                    </p>
                    <p className="mt-4 w-full text-center text-sm sm:text-base">
                        No hidden fees. Upgrade or cancel anytime. No obligation.
                    </p>
                    <div className="mt-6 w-full flex flex-col items-center">
                        <PlainToggle
                            optionOne="monthly"
                            optionTwo="annual"
                            selectedOption={tabView}
                            onSelectOption={(option: UserPlanCycle) => setTabView(option)}
                        />
                        <div className="mt-3 flex items-center justify-center rounded-sm text-purple text-xs">
                            <span className="font-semibold">2 Months free</span>
                            <span className="ml-1">for any annual plan</span>
                        </div>
                    </div>
                    <VerticalSpacer heightValue={4} />
                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-4">
                        {currentlyDisplayedPlans.map((plan) => (
                            <PlanCard
                                key={plan.displayName}
                                plan={plan}
                                isMonthlyView={tabView === 'monthly'}
                                isPopularPlan={plan.displayName === 'Pro'}
                                onSelectNewPlan={handleSelectPlan}
                                active={plan.displayName === userPlan && tabView === userPlanCycle}
                                showStartTrial={isTrialAvailable && plan.id !== userPlan}
                                setShowContactForm={setShowContactForm}
                            />
                        ))}
                    </div>
                    <a
                        className="mt-6 flex items-center w-full justify-center"
                        href="https://sniply.io/pricing"
                    >
                        <span className="text-grey-light">View all features</span>
                        <img className="ml-2 h-5 w-5" src={HyperlinkIcon} alt="hyperlink icon" />
                    </a>
                </div>
            )}
            {errorFetchingPlans && (
                <p className="mt-2 text-sm text-red">
                    Apologies, we there was an error fetching the plans information. Please try
                    again by reloading the browser window.
                </p>
            )}
            {errorFetchingStripeLink && (
                <p className="mt-2 text-sm text-red">
                    Apologies, we there was an error when we tried to re-direct you to the
                    subscriptions. Please try again by selecting the desired plan.
                </p>
            )}
        </FocusViewLayout>
    );
};
