import React from 'react';

interface ImageWithFallbackProps {
    readonly src?: string;
    readonly alt?: string;
    readonly fallbackSrc?: string;
    readonly className?: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
    src,
    alt = 'image',
    fallbackSrc,
    className = ''
}) => {
    const [imageSrc, setImageSrc] = React.useState(src || fallbackSrc);
    const [hasTriedFallback, setHasTriedFallback] = React.useState(false);

    const handleErrorLoadingInitialImage = React.useCallback(() => {
        if (!hasTriedFallback) {
            setHasTriedFallback(true);
            setImageSrc(fallbackSrc);
        }
    }, [fallbackSrc, hasTriedFallback]);

    return (
        <img
            src={imageSrc}
            alt={alt}
            onError={handleErrorLoadingInitialImage}
            className={className}
        />
    );
};
