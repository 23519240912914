import React from 'react';

import CalendarIcon from './assets/icons/calendar.svg';
import EditIcon from './assets/icons/edit.svg';
import LinkIcon from './assets/icons/link-blue.svg';
import ScreenIcon from './assets/icons/screen.svg';
import SnipIcon from './assets/icons/snip-blue.svg';
import WebsiteIcon from './assets/icons/website.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { navigateTo } from './Routing';

interface SnipsSnipContentGeneralInfoProps {
    readonly snip: SnipsListItem;
}

export const SnipsSnipContentGeneralInfo: React.FC<SnipsSnipContentGeneralInfoProps> = ({
    snip
}) => {
    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="font-semibold">General Info</h3>
                <button
                    className="flex items-center"
                    onClick={() => navigateTo(`/snip-edit?snipId=${snip.id}`)}
                >
                    <img src={EditIcon} alt="edit icon" className="h-4 w-4" />
                    <span className="ml-2 text-sm text-grey-light">Edit</span>
                </button>
            </div>
            <div className="p-1 mt-4 text-sm">
                <div className="flex items-center">
                    <img src={LinkIcon} alt="link icon" className="h-4 w-4" />
                    <a
                        href={snip.snipUrl}
                        className="ml-2 line-clamp-1 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {snip.snipUrl}
                    </a>
                </div>
                {snip.source && (
                    <div className="mt-2 flex items-center">
                        <img src={WebsiteIcon} alt="link icon" className="h-4 w-4" />
                        <span className="ml-2 line-clamp-1">{snip.source}</span>
                    </div>
                )}
                <div className="mt-2 flex items-center">
                    <img src={ScreenIcon} alt="link icon" className="h-4 w-4" />
                    <a
                        href={snip.originalContentUrl}
                        className="ml-2 hover:underline line-clamp-1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {snip.originalContentUrl}
                    </a>
                </div>
                <div className="mt-2 flex items-center">
                    <img src={CalendarIcon} alt="link icon" className="h-4 w-4" />
                    <span className="ml-2 ">{`${snip.createdSince} day ago`}</span>
                </div>
                <div className="mt-2 flex items-center">
                    <img src={SnipIcon} alt="snip icon" className="h-4 w-4" />
                    <span className="ml-2 px-2 rounded-full bg-primary text-white">{`${snip.type.toUpperCase()}`}</span>
                </div>
            </div>
        </ContentElementContainer>
    );
};
