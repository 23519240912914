import React from 'react';

import CloseIconWhite from './assets/icons/close-white.svg';

import { Notification, NotificationContext } from './NotificationContextProvider';

interface NotificationComponentProps {
    readonly notification: Notification;
    readonly handleCloseNotification: () => void;
}

export const NotificationComponent: React.FC<NotificationComponentProps> = ({
    notification,
    handleCloseNotification
}) => {
    const [startFadeIn, setStartFadeIn] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleCloseNotification();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (notification?.showTimeInSeconds && handleCloseNotification) {
            setTimeout(() => handleCloseNotification(), notification.showTimeInSeconds * 1000);
        }
    }, [notification, handleCloseNotification]);

    React.useEffect(() => {
        setStartFadeIn(true);
    }, []);

    const { messageText, type, iconSrc, buttonText, onButtonClick } = notification;

    return (
        <div
            ref={containerRef}
            className={`absolute mx-4 sm:mx-0 right-5 bottom-5 p-2 flex rounded-md ${
                type === 'warning' ? 'bg-red' : 'bg-green'
            } z-max transition ease-in duration-700 ${startFadeIn ? 'opacity-1' : 'opacity-0'}`}
        >
            <div className="flex items-center">
                {iconSrc && <img src={iconSrc} className="h-4 w-4 mr-2" />}
                <span className="text-sm text-white">{messageText}</span>
                {buttonText && onButtonClick && (
                    <button
                        className="ml-2 underline text-white text-sm"
                        onClick={(event) => {
                            onButtonClick(event);
                            handleCloseNotification();
                        }}
                    >
                        {buttonText}
                    </button>
                )}
            </div>
            {notification.hasCloseButton && (
                <button onClick={() => handleCloseNotification()} className="ml-2 flex items-start">
                    <img src={CloseIconWhite} alt="close icon" className="h-4 w-4" />
                </button>
            )}
        </div>
    );
};

export const NotificationIntegration = () => {
    const { showNotification, notification, handleCloseNotification } =
        React.useContext(NotificationContext);

    return !showNotification ? null : (
        <NotificationComponent
            notification={notification}
            handleCloseNotification={handleCloseNotification}
        />
    );
};
