import React from 'react';

import { CheckIfUrlSnippableService, LinksService } from '../../api';
import CloseIcon from '../../assets/icons/close.svg';
import { AuthContext } from '../../auth';
import ButtonBack from '../../components/ButtonBack';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import {
    DemoContentIntegrator,
    DemoView,
    PreviewViewSelectionTab
} from '../../components/DemoContentIntegrator';
import { LegacyCta } from '../../cta-components/LegacyCta';
import { createCtaData, CtasListItem } from '../../data-transformations/create-cta-data';
import { createSnipData, SnipsListItem } from '../../data-transformations/create-snip-data';
import { useElementWidth } from '../../hooks/use-element-width';
import { useHasSpaceForCtaSidePreview } from '../../hooks/use-has-space-for-cta-side-preview';
import { MOBILE_VIEW_THRESHOLD } from '../../hooks/use-is-mobile-screen-size';
import { RouteLink } from '../../Routing';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import { historyBackWithFallback } from '../../utils/history-back-with-fallback';
import { isValidUrlWithProtocol } from '../../utils/is-valid-url';
import { SnipEditContent } from './SnipEditContent';
import { createSnipUrlsData, SnipUrls } from '../../data-transformations/create-snip-urls-data';
import { createOriginalUrl } from '../../utils/create-original-url';

export const SnipEdit = () => {
    const [snipId, setSnipId] = React.useState('');
    const [currentSnip, setCurrentSnip] = React.useState<SnipsListItem>();
    const [cta, setCta] = React.useState<CtasListItem>();

    const [originalContentUrl, setOriginalContentUrl] = React.useState('');
    const [previousContentUrl, setPreviousContentUrl] = React.useState('');

    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    const [isLoadingSnip, setIsLoadingSnip] = React.useState(true);

    const [snipUrls, setSnipUrls] = React.useState<SnipUrls>();

    // Form Values --- Start
    const [domainId, setDomainId] = React.useState('');
    const [shortLink, setShortLink] = React.useState('');
    const [sniplyContentUrl, setSniplyContentUrl] = React.useState('');
    // Form Values --- End

    // Snippability States --- Start
    const [isContentUrlSnippable, setIsContentUrlSnippable] = React.useState(true);
    const [checkingSnippability, setCheckingSnippability] = React.useState(false);
    const [showSnippability, setShowSnippability] = React.useState(false);

    // Snippability States --- End
    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const snippabilityRequestIndex = React.useRef(0);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    const isSideBarWidthEquivalentOfMobileScreenWidth =
        useElementWidth(sideBarRef) < MOBILE_VIEW_THRESHOLD;

    const hasContentUrlInputValueBeenChanged =
        originalContentUrl !== createOriginalUrl(previousContentUrl);

    // Check if Content Url is snippable
    React.useEffect(() => {
        // Only run the Snippability check and snip url setting logic
        // in case the input value has been changed during
        // the edit snip process
        if (!hasContentUrlInputValueBeenChanged) {
            return;
        }

        if (!isOpenAPITokenSet) {
            return;
        }

        if (isValidUrlWithProtocol(ensureUrlHasProtocol(originalContentUrl))) {
            const currentSnippabilityRequestIndex = snippabilityRequestIndex.current + 1;

            snippabilityRequestIndex.current = currentSnippabilityRequestIndex;

            setShowSnippability(true);
            setCheckingSnippability(true);

            setTimeout(
                () => {
                    if (currentSnippabilityRequestIndex === snippabilityRequestIndex.current) {
                        CheckIfUrlSnippableService.checkIfUrlSnippableCreate({
                            requestBody: { url: ensureUrlHasProtocol(originalContentUrl) }
                        })
                            .then((result) => {
                                console.log(
                                    'CheckIfUrlSnippableService.checkIfUrlSnippableCreate()',
                                    result
                                );

                                if (
                                    currentSnippabilityRequestIndex ===
                                    snippabilityRequestIndex.current
                                ) {
                                    // we are covering a bunch of scenarios here:
                                    // if the raw url is snippable use that one,
                                    // proxy and static might not work due to websites
                                    // blacklisting sniply, so we would fall all the
                                    // way back through to the raw url which
                                    // in that case would be really not snippable.
                                    if (result.is_snippable) {
                                        setSniplyContentUrl(result.url);
                                        setIsContentUrlSnippable(true);
                                    } else {
                                        if (result.proxy_url) {
                                            setSniplyContentUrl(result.proxy_url);
                                            setIsContentUrlSnippable(true);
                                        } else if (result.static_page_url) {
                                            setSniplyContentUrl(result.static_page_url);
                                            setIsContentUrlSnippable(true);
                                        } else {
                                            // Only truly unsnippable scenario to be displayed to users
                                            setSniplyContentUrl(result.url);
                                            setIsContentUrlSnippable(false);
                                        }
                                    }

                                    const urls = createSnipUrlsData(result);

                                    setSnipUrls(urls);

                                    setCheckingSnippability(false);
                                }
                            })
                            .catch((error) => {
                                if (
                                    currentSnippabilityRequestIndex ===
                                    snippabilityRequestIndex.current
                                ) {
                                    console.log(
                                        'Error fetching from CheckIfUrlSnippableService.checkIfUrlSnippableCreate(): ',
                                        error
                                    );
                                    setIsContentUrlSnippable(false);
                                    setCheckingSnippability(false);
                                }
                            });
                    }
                },
                currentSnippabilityRequestIndex === 1 ? 0 : 4000
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenAPITokenSet, originalContentUrl]);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const snipIdFromQueryParams = searchParams.get('snipId');

        if (snipIdFromQueryParams) {
            setSnipId(snipIdFromQueryParams);

            setIsLoadingSnip(true);

            LinksService.linksRetrieve({ id: snipIdFromQueryParams })
                .then((snipResult) => {
                    const snip = createSnipData(snipResult);

                    setCurrentSnip(snip);

                    setDomainId(snip.customDomainId ?? 'default');
                    setShortLink(snip.slug);

                    setOriginalContentUrl(snip.originalContentUrl);
                    setPreviousContentUrl(snip.sniplyContentUrl);
                    setSniplyContentUrl(snip.sniplyContentUrl);

                    snipResult.cta_objects?.[0] &&
                        setCta(createCtaData(snipResult.cta_objects?.[0]));

                    setIsLoadingSnip(false);

                    CheckIfUrlSnippableService.checkIfUrlSnippableCreate({
                        requestBody: {
                            url: snip.originalContentUrl
                        }
                    })
                        .then((snippabilityResult) => {
                            setSnipUrls(createSnipUrlsData(snippabilityResult));
                        })
                        .catch((error) =>
                            console.log(
                                'Error fetching from CheckIfUrlSnippableService.checkIfUrlSnippableCreate(): ',
                                error
                            )
                        );
                })
                .catch((error) => {
                    console.error('Error fetching snip for editing: ', error);
                    setIsLoadingSnip(false);

                    //TODO error handling
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="h-full w-full flex text-grey">
            <div className="h-full w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 bg-white border-r border-grey-lighter">
                <div className="absolute z-2 bg-white w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 p-4 grid grid-cols-3 items-start">
                    <ButtonBack onClick={() => historyBackWithFallback('/snips')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar && currentSnip?.type !== 'redirect' ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href={`/snips/${snipId}`}>
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                {currentSnip && (
                    <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                        <SnipEditContent
                            mobileConfigurePreviewActiveTab={mobileConfigurePreviewActiveTab}
                            snipId={snipId}
                            currentSnip={currentSnip}
                            setCurrentSnip={setCurrentSnip}
                            isLoadingSnip={isLoadingSnip}
                            originalContentUrl={originalContentUrl}
                            setOriginalContentUrl={setOriginalContentUrl}
                            sniplyContentUrl={sniplyContentUrl}
                            setSniplyContentUrl={setSniplyContentUrl}
                            domainId={domainId}
                            setDomainId={setDomainId}
                            shortLink={shortLink}
                            setShortLink={setShortLink}
                            cta={cta}
                            isContentUrlSnippable={isContentUrlSnippable}
                            setIsContentUrlSnippable={setIsContentUrlSnippable}
                            checkingSnippability={checkingSnippability}
                            showSnippability={showSnippability}
                            setShowSnippability={setShowSnippability}
                            hasContentUrlBeenEdited={originalContentUrl !== previousContentUrl}
                            previousContentUrl={previousContentUrl}
                            snipUrls={snipUrls}
                        />
                    </div>
                )}
            </div>
            <div
                className={`h-full grow ${
                    hasScreenSpaceForSideBar && currentSnip?.type !== 'redirect'
                        ? 'bg-grey-lighter'
                        : 'bg-primary'
                }`}
                ref={sideBarRef}
            >
                {hasScreenSpaceForSideBar && currentSnip?.type !== 'redirect' && (
                    <DemoContentIntegrator
                        contentUrl={sniplyContentUrl}
                        snipUrls={snipUrls}
                        cta={
                            cta && (
                                <LegacyCta
                                    configuration={cta}
                                    disclaimerHref="https://snip.ly/whats-this2"
                                    isDemoMobileScreen={
                                        isSideBarWidthEquivalentOfMobileScreenWidth ||
                                        activePreviewView === 'mobile'
                                    }
                                    onConvertClick={() => console.log('handle CTA Conversion')}
                                    onSubmitFormInputValue={() => console.log('handle Form Submit')}
                                />
                            )
                        }
                        previewViewSelectionTab={
                            hasScreenSpaceForSideBar && (
                                <PreviewViewSelectionTab
                                    activeTab={activePreviewView}
                                    setActiveTab={setActivePreviewView}
                                />
                            )
                        }
                        activePreviewView={activePreviewView}
                    />
                )}
            </div>
        </div>
    );
};
