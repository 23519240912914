import React from 'react';

import ButtonTypePurpleIcon from './assets/icons/button-type-purple.svg';
import CTAClicksIcon from './assets/icons/cta-clicks.svg';
import CTAConversionRateIcon from './assets/icons/cta-conversion-rate.svg';
import CTAPurpleIcon from './assets/icons/cta-purple.svg';
import DeleteIcon from './assets/icons/delete-key.svg';
import EditIcon from './assets/icons/edit.svg';
import FormPurpleIcon from './assets/icons/form-purple.svg';
import PlusIcon from './assets/icons/plus-plain.svg';
import SettingsIcon from './assets/icons/settings-grey-medium.svg';
import SnipPurpleIcon from './assets/icons/snip-purple.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { navigateTo } from './Routing';
import { CtaVariant } from './SnipsSnipContent';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { UserContext } from './UserContextProvider';
import { ButtonMain } from './components/ButtonMain';

interface SnipsSnipContentCtaProps {
    readonly snip: SnipsListItem;
    readonly snipHasCta: boolean;
    readonly selectedCtaId: string;
    readonly ctaTabView: CtaVariant;
    readonly setCtaTabView: (ctaTabView: CtaVariant) => void;
    readonly handleRemoveCta: (ctaId: string) => void;
    readonly isRemovingCta: boolean;
}

export const SnipsSnipContentCta: React.FC<SnipsSnipContentCtaProps> = ({
    snip,
    snipHasCta,
    selectedCtaId,
    ctaTabView,
    setCtaTabView,
    handleRemoveCta,
    isRemovingCta
}) => {
    const { canUseABTesting, minimumABTestingPlan } = React.useContext(UserContext);
    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="hidden sm:inline font-semibold">Call To Action</h3>
                <h3 className="sm:hidden font-semibold">CTA</h3>
                {snip.type !== 'redirect' ? (
                    <div className="flex items-center">
                        {snipHasCta && (
                            <button
                                className="flex items-center"
                                onClick={() => handleRemoveCta(selectedCtaId)}
                            >
                                <img src={DeleteIcon} alt="delete icon" className="h-4 w-4" />
                                <span className="ml-2 text-grey-light text-sm">Remove</span>
                            </button>
                        )}
                        <button
                            className="flex items-center ml-4"
                            onClick={() =>
                                navigateTo(
                                    `/snip-cta-change?snipId=${snip.id}&variant=${ctaTabView}`
                                )
                            }
                        >
                            <img
                                src={snipHasCta ? SettingsIcon : PlusIcon}
                                alt="delete icon"
                                className="h-4 w-4"
                            />
                            <span className="ml-2 text-grey-light text-sm">
                                {!snipHasCta ? 'Add Call-To-Action' : 'Change'}
                            </span>
                        </button>
                        <button
                            className="flex items-center ml-4"
                            onClick={() =>
                                navigateTo(
                                    `/snip-cta-edit?snipId=${snip.id}&ctaId=${selectedCtaId}`
                                )
                            }
                        >
                            <img src={EditIcon} alt="delete icon" className="h-4 w-4" />
                            <span className="ml-2 text-grey-light text-sm">Edit</span>
                        </button>
                    </div>
                ) : (
                    <ButtonMain onClick={() => navigateTo(`/snip-cta-add?snipId=${snip.id}`)}>
                        + Add CTA
                    </ButtonMain>
                )}
            </div>
            {snipHasCta && (
                <div className="mt-4 flex items-end text-grey h-10 text-sm">
                    <div>
                        <button
                            className="h-6 px-2 line-clamp-1"
                            onClick={() => setCtaTabView('A')}
                        >
                            {snip.ctas[0]?.name ?? snip.ctas[0]?.messageText}
                        </button>
                        <div
                            className={`h-0.5 w-full rounded-t-md mt-2 ${
                                ctaTabView === 'A' ? 'bg-primary' : 'bg-grey-lightest'
                            }`}
                        />
                    </div>

                    <div className="ml-3">
                        {snip.ctas[1] ? (
                            <button
                                className="h-6 px-2 line-clamp-1"
                                onClick={() => setCtaTabView('B')}
                            >
                                {snip.ctas[1]?.name ?? snip.ctas[1]?.messageText}
                            </button>
                        ) : (
                            <button
                                className="h-6 px-2 text-grey-light line-clamp-1"
                                onClick={() => {
                                    if (canUseABTesting) {
                                        navigateTo(`/snip-cta-change?snipId=${snip.id}&variant=B`);
                                    } else {
                                        handleOpenUpgradePlanMessage({
                                            feature: 'A/B Testing',
                                            requiredPlan: minimumABTestingPlan
                                        });
                                    }
                                }}
                            >
                                + Add B Variation
                            </button>
                        )}
                        <div
                            className={`h-0.5 w-full rounded-t-md mt-2 ${
                                ctaTabView === 'B' ? 'bg-primary' : 'bg-grey-lighter'
                            }`}
                        />
                    </div>
                </div>
            )}
            {snipHasCta && (
                <div className="mt-4">
                    {isRemovingCta ? (
                        <div className="flex w-full h-full items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex items-center">
                                <img src={CTAPurpleIcon} alt="cta icon" className="h-4 w-4" />
                                <span className="text-sm ml-2 line-clamp-1">
                                    {snip.ctas[ctaTabView === 'A' ? 0 : 1]?.name ??
                                        snip.ctas[ctaTabView === 'A' ? 0 : 1]?.messageText}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <img
                                    src={CTAClicksIcon}
                                    alt="cta clicks icon"
                                    className="h-4 w-4"
                                />
                                {snip.ctas[ctaTabView === 'A' ? 0 : 1]?.numberOfClicks ? (
                                    <span className="text-sm ml-2">
                                        {snip.ctas[ctaTabView === 'A' ? 0 : 1]?.numberOfClicks}
                                    </span>
                                ) : (
                                    <span className="text-sm ml-2 text-grey-light">
                                        No stats yet
                                    </span>
                                )}
                            </div>
                            <div className="flex items-center">
                                <img
                                    src={
                                        snip.ctas[ctaTabView === 'A' ? 0 : 1].type === 'form'
                                            ? FormPurpleIcon
                                            : SnipPurpleIcon
                                    }
                                    alt="snip icon"
                                    className="h-4 w-4"
                                />
                                <span className="ml-2 text-sm line-clamp-1">
                                    {snip.ctas[ctaTabView === 'A' ? 0 : 1].type === 'form'
                                        ? snip.ctas[ctaTabView === 'A' ? 0 : 1].messageText
                                        : snip.ctas[ctaTabView === 'A' ? 0 : 1]?.urlToBePromoted}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <img
                                    src={CTAConversionRateIcon}
                                    alt="cta clicks icon"
                                    className="h-4 w-4"
                                />
                                {snip.ctas[ctaTabView === 'A' ? 0 : 1]?.conversionRate ? (
                                    <span className="text-sm ml-2">
                                        {`${
                                            snip.ctas[ctaTabView === 'A' ? 0 : 1]?.conversionRate
                                        }%`}
                                    </span>
                                ) : (
                                    <span className="text-sm ml-2 text-grey-light">
                                        No stats yet
                                    </span>
                                )}
                            </div>
                            <div className="flex items-center">
                                <img
                                    src={ButtonTypePurpleIcon}
                                    alt="button type icon"
                                    className="h-4 w-4"
                                />
                                <span className="text-sm ml-2">
                                    {snip.ctas[ctaTabView === 'A' ? 0 : 1]?.type === 'image-ad'
                                        ? 'IMAGE'
                                        : snip.ctas[
                                              ctaTabView === 'A' ? 0 : 1
                                          ]?.type?.toUpperCase()}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </ContentElementContainer>
    );
};
