import { CtaPosition } from '../data-transformations/create-cta-data';

type PositionTypeBackend = 'bottom' | 'bottom-right' | 'top' | 'top-right';

export function getPositionForApiCall(position: CtaPosition): PositionTypeBackend {
    switch (position) {
        case 'bottom-left':
            return 'bottom';
        case 'top-left':
            return 'top';
        default:
            return position;
    }
}
