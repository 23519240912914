import React from 'react';
import { useElementWidth } from '../hooks/use-element-width';

export const InfoCardGrid = ({ children }) => {
    const containerRef = React.useRef(undefined);
    const containerWidth = useElementWidth(containerRef);

    return (
        <div
            ref={containerRef}
            className={`grid gap-4 ${
                containerWidth <= 420
                    ? 'grid-cols-1'
                    : containerWidth <= 640
                    ? 'grid-cols-2'
                    : containerWidth <= 1024
                    ? ' grid-cols-3'
                    : ' grid-cols-4'
            }`}
        >
            {children}
        </div>
    );
};
