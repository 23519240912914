import React from 'react';
import { useElementWidth } from '../hooks/use-element-width';

interface TReportingContentLayoutProps {
    readonly overallOverview: JSX.Element;
    readonly snipsList: JSX.Element;
    readonly graph: JSX.Element;
    // readonly trafficByCountry: JSX.Element;
    readonly trafficByDevice: JSX.Element;
    readonly trafficBySource: JSX.Element;
    readonly pageViewStatistics: JSX.Element;
}

export const ReportingContentLayout: React.FC<TReportingContentLayoutProps> = ({
    overallOverview,
    snipsList,
    graph,
    // trafficByCountry,
    trafficByDevice,
    trafficBySource,
    pageViewStatistics
}) => {
    const containerRef = React.useRef();
    const containerWidth = useElementWidth(containerRef);
    const shouldSnipListAndGraphBeSideBySide = containerWidth > 800;

    return (
        <div className="grid grid-cols-1 gap-y-5" ref={containerRef}>
            {overallOverview}
            <div
                className={`grid ${
                    shouldSnipListAndGraphBeSideBySide ? 'grid-cols-1-2' : 'grid-cols-1'
                } gap-5`}
            >
                {snipsList}
                {graph}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                {/* {trafficByCountry} */}
                {trafficByDevice}
                <div className="grid grid-cols-1 gap-5">
                    {trafficBySource}
                    {pageViewStatistics}
                </div>
            </div>
        </div>
    );
};
