import React from 'react';
import { DashboardOverviewStats } from './data-transformations/create-dashboard-overview-stats';
import GirlWithStarsImage from './assets/images/girl-with-stars.svg';
import Arrow from './assets/icons/arrow-grey-lighter.svg';
import { useElementWidth } from './hooks/use-element-width';
import { DeltaPercentageIndicator } from './ReportingOverallOverview';

interface DashboardTopBarProps {
    readonly stats: DashboardOverviewStats;
}

export const DashboardTopBar: React.FC<DashboardTopBarProps> = ({ stats }) => {
    const containerRef = React.useRef(undefined);
    const containerWidth = useElementWidth(containerRef);
    const isSmallView = containerWidth < 700 && containerWidth >= 400;
    const isTinyView = containerWidth < 400;

    return (
        <div ref={containerRef}>
            {isTinyView ? (
                <div className="relative">
                    <div>
                        <img
                            src={GirlWithStarsImage}
                            alt="image"
                            className={`h-20 w-20 absolute -top-5 left-2`}
                        />
                        <div className="ml-24">
                            <p className="font-semibold">
                                {stats.numberOfSnips < 1 ? 'Time to get started!' : 'Well done!'}
                            </p>
                            <p className="text-xs">{`You have created ${stats.numberOfSnips} Snips in the past 30 days.`}</p>
                        </div>
                    </div>
                    <div className="mt-4 ml-10 grid grid-cols-1-1">
                        <div className="flex items-center">
                            <div>
                                <div className="flex items-center text-base">
                                    <span className="font-semibold">{stats.numberOfSnips}</span>
                                    <div className="ml-2">
                                        <DeltaPercentageIndicator
                                            percentageValue={stats.numberOfSnipsDelta}
                                        />
                                    </div>
                                </div>
                                <p className="text-xs">Snips</p>
                            </div>
                            <img src={Arrow} alt="arrow icon" className="ml-4 h-5 w-5 rotate-180" />
                        </div>
                        <div className="flex items-center">
                            <div>
                                <div className="flex items-center text-base">
                                    <span className="font-semibold">{stats.numberOfClicks}</span>
                                    <div className="ml-2">
                                        <DeltaPercentageIndicator
                                            percentageValue={stats.numberOfClicksDelta}
                                        />
                                    </div>
                                </div>
                                <p className="text-xs">Clicks</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div>
                                <div className="flex items-center text-base">
                                    <span className="font-semibold">
                                        {stats.numberOfConversions}
                                    </span>
                                    <div className="ml-2">
                                        <DeltaPercentageIndicator
                                            percentageValue={stats.numberOfConversionsDelta}
                                        />
                                    </div>
                                </div>
                                <p className="text-xs">Conversions</p>
                            </div>
                            <img src={Arrow} alt="arrow icon" className="ml-4 h-5 w-5 rotate-180" />
                        </div>
                        <div>
                            <div className="flex items-center text-base">
                                <span className="font-semibold">{`${stats.conversionRateInPercent}%`}</span>
                                <div className="ml-2">
                                    <DeltaPercentageIndicator
                                        percentageValue={stats.conversionRateDelta}
                                    />
                                </div>
                            </div>
                            <p className="text-xs">Rate</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="relative">
                    <img
                        src={GirlWithStarsImage}
                        alt="image"
                        className={`${
                            isSmallView
                                ? 'h-30 w-30 absolute -top-10 left-5'
                                : 'h-40 w-40 absolute -top-10 left-10'
                        }`}
                    />
                    <div className={`mt-2 ${isSmallView ? 'ml-40' : 'ml-60'}`}>
                        <p className="font-semibold text-base md:text-lg">
                            {' '}
                            {stats.numberOfSnips < 1 ? 'Time to get started!' : 'Well done!'}
                        </p>
                        <p className="text-sm md:text-base">{`You have created ${stats.numberOfSnips} Snips in the past 30 days.`}</p>
                        <div
                            className={`mt-4 grid ${
                                isSmallView ? 'grid-cols-1-1' : 'grid-cols-1-1-1-1'
                            }`}
                        >
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-base lg:text-lg xl:text-xl font-semibold">
                                            {stats.numberOfSnips}
                                        </span>
                                        <div className="ml-2">
                                            <DeltaPercentageIndicator
                                                percentageValue={stats.numberOfSnipsDelta}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-xs lg:text-base">Snips</p>
                                </div>
                                <img
                                    src={Arrow}
                                    alt="arrow icon"
                                    className="ml-2 xl:ml-[20%] h-5 w-5 rotate-180"
                                />
                            </div>
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-base lg:text-lg xl:text-xl font-semibold">
                                            {stats.numberOfClicks}
                                        </span>
                                        <div className="ml-2">
                                            <DeltaPercentageIndicator
                                                percentageValue={stats.numberOfClicksDelta}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-xs lg:text-base">Clicks</p>
                                </div>
                                {!isSmallView && (
                                    <img
                                        src={Arrow}
                                        alt="arrow icon"
                                        className="ml-2 xl:ml-[20%] h-5 w-5 rotate-180"
                                    />
                                )}
                            </div>
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-base lg:text-lg xl:text-xl font-semibold">
                                            {stats.numberOfConversions}
                                        </span>
                                        <div className="ml-2">
                                            <DeltaPercentageIndicator
                                                percentageValue={stats.numberOfConversionsDelta}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-xs lg:text-base">Conversions</p>
                                </div>
                                <img
                                    src={Arrow}
                                    alt="arrow icon"
                                    className="ml-2 xl:ml-[20%] h-5 w-5 rotate-180"
                                />
                            </div>
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-base lg:text-lg xl:text-xl font-semibold">
                                            {`${stats.conversionRateInPercent}%`}
                                        </span>
                                        <div className="ml-2">
                                            <DeltaPercentageIndicator
                                                percentageValue={stats.conversionRateDelta}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-xs lg:text-base">Rate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
