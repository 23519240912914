/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentRecommendationRetrieval } from '../models/ContentRecommendationRetrieval';
import type { ContentRecommendationRetrievalRequest } from '../models/ContentRecommendationRetrievalRequest';
import type { RecommendedContentSnipped } from '../models/RecommendedContentSnipped';
import type { RecommendedContentSnippedRequest } from '../models/RecommendedContentSnippedRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentService {

    /**
     * @returns ContentRecommendationRetrieval
     * @throws ApiError
     */
    public static contentCreate({
        requestBody,
    }: {
        requestBody: ContentRecommendationRetrievalRequest,
    }): CancelablePromise<ContentRecommendationRetrieval> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/content/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RecommendedContentSnipped
     * @throws ApiError
     */
    public static contentSnippedCreate({
        requestBody,
    }: {
        requestBody: RecommendedContentSnippedRequest,
    }): CancelablePromise<RecommendedContentSnipped> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/content/snipped/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public static contentTopicsList(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/content/topics',
        });
    }

}
