import { ContentRecommendationRetrieval } from '../api';

export interface ContentListItem extends Record<string, string | number> {
    readonly linkUrl: string;
    readonly titleText: string;
    readonly additionalText: string;
    readonly sourceName: string;
    readonly sourceUrl: string;
    readonly issueDate: string;
    readonly imageUrl: string;
}

export function createContentList(response: ContentRecommendationRetrieval): ContentListItem[] {
    if (!response) {
        return [];
    }

    return response.results.map((result) => {
        return {
            linkUrl: result.url,
            titleText: result.title,
            additionalText: result.description,
            sourceName: result.source.name,
            sourceUrl: result.source.url,
            issueDate: new Date(result.publishedAt).toDateString(),
            imageUrl: result.image
        };
    });
}
