import React from 'react';
import CopyIconWhite from './assets/icons/copy-white.svg';
import LockIcon from './assets/icons/lock-blue.svg';
import ArrowIcon from './assets/icons/arrow.svg';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { VerticalSpacer } from './components/VerticalSpacer';
import CopyIcon from './assets/icons/copy.svg';
import { LoadingSpinner } from './components/LoadingSpinner';
import {
    CustomDomainsContentGrid,
    includesSubdomain,
    SNIPLY_LOAD_BALANCER_LINK
} from './CustomizationCustomDomains';
import { NotificationContext } from './NotificationContextProvider';
import { useCurrentUrlPath } from './Routing';

interface SelfManagedInfoProps {
    readonly domainName: string;
    readonly onCopyClick: (copiedItem: string) => void;
}

export const SelfManagedInfo: React.FC<SelfManagedInfoProps> = ({ domainName, onCopyClick }) => (
    <div>
        <p className="text-sm">
            <span>To connect your domain, you will need to create a</span>
            <span className="font-semibold"> CNAME</span>
            <span> with following values. After you have added the records, press Save.</span>
            <a
                className="underline ml-2"
                href="https://support.snip.ly/hc/en-us/articles/20000907424532-How-do-I-create-snips-using-my-own-custom-domain-"
            >
                View tutorial
            </a>
        </p>
        <VerticalSpacer heightValue={6} />
        <div>
            <div
                className={`flex items-center justify-between p-2 w-full border border-primary rounded-md bg-blue-50`}
            >
                <div className="grid grid-cols-1 gap-1 sm:flex sm:flex-wrap items-center text-sm font-medium">
                    <span className="mr-2">CNAME:</span>
                    <div className="flex mr-3 items-center">
                        <span className="mr-2">(Name:)</span>
                        <span className="mr-2 break-words line-clamp-1">{domainName}</span>
                        <div className="w-6">
                            <button
                                className="flex w-6"
                                onClick={(event) => {
                                    event.preventDefault();
                                    navigator.clipboard.writeText(domainName);
                                    onCopyClick('CNAME domain name');
                                }}
                            >
                                <img src={CopyIcon} alt="copy icon" className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                    <img
                        src={ArrowIcon}
                        alt="arrow icon"
                        className="mr-3 rotate-180 w-4 h-4 hidden sm:block"
                    />
                    <div className="flex items-center">
                        <span className="mr-2">(Target:)</span>
                        <span className="mr-2">{SNIPLY_LOAD_BALANCER_LINK}</span>
                        <div className="w-6">
                            <button
                                className="flex w-6"
                                onClick={(event) => {
                                    event.preventDefault();
                                    navigator.clipboard.writeText(SNIPLY_LOAD_BALANCER_LINK);
                                    onCopyClick('CNAME target');
                                }}
                            >
                                <img src={CopyIcon} alt="copy icon" className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <VerticalSpacer heightValue={6} />
        <div className="flex items-start">
            <img src={LockIcon} alt="lock icon" className="h-4 w-4" />
            <div className="ml-2">
                <p className="text-sm">
                    HTTPS will be activated as soon as the CNAME record is set.{' '}
                </p>
                <p className="text-grey-light text-xs">*it may take up to 15 minutes</p>
            </div>
        </div>
    </div>
);

interface ManagedBySniplyInfoProps {
    readonly nameServers: string[];
    readonly onCopyClick: (copiedItem: string) => void;
}

export const ManagedBySniplyInfo: React.FC<ManagedBySniplyInfoProps> = ({
    nameServers,
    onCopyClick
}) => (
    <div>
        <p className="text-sm">
            <span>
                {`To connect your domain, you will need to update your domain's
             DNS settings. This is managed in your domain provider, not in
        Sniply. Locate your domain provider DNS settings and change
nameservers to the following ones.`}
            </span>
            <a
                className="underline ml-2"
                href="https://support.snip.ly/hc/en-us/articles/20000907424532-How-do-I-create-snips-using-my-own-custom-domain-"
            >
                View tutorial
            </a>
        </p>
        <VerticalSpacer heightValue={6} />
        <div>
            {nameServers?.map((nameServer, index) => (
                <div
                    key={nameServer}
                    className={`flex items-center justify-between p-2 w-full border border-primary rounded-md bg-blue-50 ${
                        index >= 0 ? 'mt-2' : 'mt-0'
                    }`}
                >
                    <span className="text-sm font-medium">{`DNS ${index + 1}: ${nameServer}`}</span>
                    <button
                        className="flex justify-end w-10"
                        onClick={(event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(nameServer);
                            onCopyClick('Name Server');
                        }}
                    >
                        <img src={CopyIcon} alt="copy icon" className="w-5 h-5" />
                    </button>
                </div>
            ))}
        </div>
        <VerticalSpacer heightValue={6} />
        <p className="text-sm">
            After you have changed your domain Nameservers, press Save. Registers can take up to 24h
            to process nameserver updates. We will notify you by email when your domain is active.
        </p>
    </div>
);

interface CustomDomainsDomainDetailsProps {
    readonly domainName: string;
    readonly redirectUrl: string;
    readonly setRedirectUrl: (redirectUrl: string) => void;
    readonly showSelfManagedInfo: boolean;
    readonly nameServers: string[];
    readonly onSaveRedirectUrl: (event: React.FormEvent<HTMLFormElement>) => void;
    readonly onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly isSavingDomain: boolean;
    readonly errorInvalidRedirectUrl: boolean;
}

export const CustomDomainsDomainDetails: React.FC<CustomDomainsDomainDetailsProps> = ({
    domainName,
    redirectUrl,
    setRedirectUrl,
    showSelfManagedInfo,
    nameServers,
    onSaveRedirectUrl,
    onCancel,
    isSavingDomain,
    errorInvalidRedirectUrl
}) => {
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleCopyAction = React.useCallback(
        (copiedItem: string) => {
            handleOpenNotification({
                messageText: `Successfully copied ${copiedItem}.`,
                type: 'success',
                iconSrc: CopyIconWhite,
                showTimeInSeconds: 3
            });
        },
        [handleOpenNotification]
    );

    const { level4 } = useCurrentUrlPath();

    return (
        <div>
            <h3 className="mt-6 mb-3 sm:mb-6 sm:pl-2 font-semibold text-lg text-grey">{`${
                level4 === 'edit' ? 'Edit' : 'Add'
            } Domain`}</h3>
            <CustomDomainsContentGrid>
                <ContentElementContainer>
                    <div>
                        <form onSubmit={onSaveRedirectUrl}>
                            <div className="flex flex-col">
                                <h3 className="font-semibold">{`${
                                    level4 === 'edit' ? '' : 'Connect'
                                } ${
                                    includesSubdomain(domainName) ? 'Subdomain' : 'Domain'
                                } "${domainName}"`}</h3>
                                <VerticalSpacer heightValue={6} />
                                {showSelfManagedInfo ? (
                                    <SelfManagedInfo
                                        domainName={domainName}
                                        onCopyClick={handleCopyAction}
                                    />
                                ) : (
                                    <ManagedBySniplyInfo
                                        nameServers={nameServers}
                                        onCopyClick={handleCopyAction}
                                    />
                                )}
                                <VerticalSpacer heightValue={8} />
                                <FormTextInput
                                    id="redirect-url-input"
                                    labelText="Redirect Url"
                                    placeholder="mysite.com"
                                    explanation={
                                        <p className="text-xs text-grey-medium">
                                            Website to redirect to for Homepage and 404 requests
                                        </p>
                                    }
                                    value={redirectUrl}
                                    onChange={(event) => {
                                        setRedirectUrl(event.target.value);
                                    }}
                                    autoFocus
                                />
                                <div className="h-6 mt-1">
                                    {errorInvalidRedirectUrl && (
                                        <p className="text-red text-sm">
                                            Please provide a valid redirect url.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="mt-5 flex justify-end">
                                {isSavingDomain ? (
                                    <LoadingSpinner size={5} />
                                ) : (
                                    <>
                                        {level4 === 'edit' && (
                                            <ButtonMain style="neutral" onClick={onCancel}>
                                                Back
                                            </ButtonMain>
                                        )}
                                        <ButtonMain className="ml-2" type="submit">
                                            Save
                                        </ButtonMain>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </ContentElementContainer>
            </CustomDomainsContentGrid>
        </div>
    );
};
