import React from 'react';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { CustomDomainsContentGrid } from './CustomizationCustomDomains';
import { useElementWidth } from './hooks/use-element-width';
import { navigateTo } from './Routing';

interface CustomDomainsWhoSetsUpProps {
    readonly onCreateCustomDomain: (
        event: React.MouseEvent<HTMLButtonElement>,
        isSelfManaged: boolean
    ) => void;
    readonly errorCreatingDomain: boolean;
    readonly errorDomainAlreadyInUse: boolean;
}

export const CustomDomainsWhoSetsUp: React.FC<CustomDomainsWhoSetsUpProps> = ({
    onCreateCustomDomain,
    errorCreatingDomain,
    errorDomainAlreadyInUse
}) => {
    const containerRef = React.useRef(null);
    const containerWidth = useElementWidth(containerRef);
    const isSmallView = containerWidth < 760;

    return (
        <div ref={containerRef}>
            <h3 className="mt-6 mb-3 sm:mb-6 sm:pl-2 font-semibold text-lg text-grey">
                Who should connect the Domain to Sniply?
            </h3>
            <CustomDomainsContentGrid>
                <ContentElementContainer>
                    <h3 className="font-semibold">Make a choice</h3>
                    <p className="mt-2 text-sm text-grey-medium">
                        We can set your domain up to point at our Sniply server, or if you would
                        rather like to do it yourself - please go ahead. Instructions will follow in
                        the next step.
                    </p>
                    {isSmallView ? (
                        <div className="mt-6 grid grid-cols-1 gap-3">
                            <div className="flex w-full">
                                <ButtonMain
                                    size="small"
                                    width="full"
                                    style="secondary"
                                    onClick={(event) => onCreateCustomDomain(event, true)}
                                >
                                    Self-Managed
                                </ButtonMain>
                                <ButtonMain
                                    size="small"
                                    width="full"
                                    className="ml-2"
                                    onClick={(event) => onCreateCustomDomain(event, false)}
                                >
                                    Managed by Sniply
                                </ButtonMain>
                            </div>
                            <ButtonMain
                                size="small"
                                style="neutral"
                                onClick={() =>
                                    navigateTo('/customization/custom-domains/add-domain')
                                }
                            >
                                Back
                            </ButtonMain>
                        </div>
                    ) : (
                        <div className="mt-6 flex items-center justify-end">
                            <ButtonMain
                                style="neutral"
                                onClick={() =>
                                    navigateTo('/customization/custom-domains/add-domain')
                                }
                            >
                                Back
                            </ButtonMain>
                            <ButtonMain
                                style="secondary"
                                className="ml-2"
                                onClick={(event) => onCreateCustomDomain(event, true)}
                            >
                                Self-Managed
                            </ButtonMain>
                            <ButtonMain
                                className="ml-2"
                                onClick={(event) => onCreateCustomDomain(event, false)}
                            >
                                Managed by Sniply
                            </ButtonMain>
                        </div>
                    )}
                    {errorCreatingDomain && (
                        <p className="mt-2 text-sm text-red">
                            There was an unexpected error while creating this domain. Please try
                            again.
                        </p>
                    )}
                    {errorDomainAlreadyInUse && (
                        <p className="mt-2 text-sm text-red">
                            The given domain is already part of your custom domains.
                        </p>
                    )}
                </ContentElementContainer>
            </CustomDomainsContentGrid>
        </div>
    );
};
