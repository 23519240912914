import React from 'react';
import { RouteLink, useCurrentUrlPath } from '../Routing';
import { CurrentContentView, NavigableViews } from '../App';
import { NavigationIcon } from './icons/NavigationIcon';

interface MainNavigationGroupListProps {
    readonly navigableViews: Partial<NavigableViews>;
    readonly slim?: boolean;
    readonly setIsMobileNavigationExpanded?: (value: boolean) => void;
}

export const MainNavigationGroupList: React.FC<MainNavigationGroupListProps> = ({
    navigableViews,
    slim = false,
    setIsMobileNavigationExpanded
}) => {
    const currentUrlPath = useCurrentUrlPath();

    return (
        <ul>
            {(Object.keys(navigableViews) as CurrentContentView[]).map((view) => (
                <li key={view} className={'flex items-center h-8 my-3 semiTall:my-4'}>
                    <div
                        className={`w-1 h-full rounded-r-lg ${
                            view === currentUrlPath.level1 ? 'bg-primary' : 'bg-transparent'
                        }`}
                    />
                    <button
                        onClick={() => {
                            if (setIsMobileNavigationExpanded) {
                                setIsMobileNavigationExpanded(false);
                            }
                        }}
                    >
                        <RouteLink href={`/${view === 'dashboard' ? '' : view}`}>
                            <div className="h-7 px-5 flex items-center">
                                <NavigationIcon
                                    innerClassName={`h-6 w-6 ${
                                        view === currentUrlPath.level1
                                            ? 'fill-primary'
                                            : 'fill-grey-light'
                                    }`}
                                    view={view}
                                />
                                {!slim && (
                                    <span
                                        className={`ml-2 text-base ${
                                            view === currentUrlPath.level1
                                                ? 'text-primary'
                                                : 'text-grey-light'
                                        }`}
                                    >
                                        {navigableViews[view]}
                                    </span>
                                )}
                            </div>
                        </RouteLink>
                    </button>
                </li>
            ))}
        </ul>
    );
};
