import React from 'react';
import { FocusViewLayout } from './components/FocusViewLayout';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid } from './components/StandardContentGrid';
import { VerticalSpacer } from './components/VerticalSpacer';
import { TeamModalInviteTeamMember } from './TeamModalInviteTeamMember';
import {
    createInviteeData,
    createTeamMembersData,
    TeamMemberData
} from './data-transformations/create-team-members-data';
import { TeamService } from './api';
import { TeamMembersTable } from './TeamMembersTable';
import { navigateTo, useCurrentUrlPath } from './Routing';
import { TeamModalEditTeamMember } from './TeamModalEditTeamMember';

export const ElementContainer: React.FC<{ readonly children: React.ReactNode }> = ({
    children
}) => (
    <div className="bg-white p-2 sm:p-4 rounded-sm shadow-sm border border-grey-lighter text-grey min-h-0">
        {children}
    </div>
);

export const UserTeam: React.FC = () => {
    const [teamMembersData, setTeamMembersData] = React.useState<TeamMemberData[]>();

    const [isLoadingInitialData, setIsLoadingInitialData] = React.useState(false);
    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    React.useEffect(() => {
        if (!teamMembersData) {
            setIsLoadingInitialData(true);

            TeamService.teamMembersRetrieve()
                .then((result) => {
                    console.log('TeamService.teamMembersRetrieve()', result);
                    setTeamMembersData([
                        ...createTeamMembersData(result.team_members),
                        ...createInviteeData(result.invitations)
                    ]);
                    setHadErrorLoadingData(false);
                    setIsLoadingInitialData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from TeamService.teamMembersRetrieve(): ', error);
                    setHadErrorLoadingData(true);
                    setIsLoadingInitialData(false);
                });
        }
    }, [teamMembersData]);

    const { level2: teamView } = useCurrentUrlPath();

    return (
        <FocusViewLayout enableWideView>
            <h2 className="font-semibold text-lg">User Settings</h2>
            <VerticalSpacer heightValue={10} />
            {teamView === 'invite-member' ? (
                <StandardContentGrid>
                    <TeamModalInviteTeamMember
                        closeModal={() => navigateTo('/team')}
                        teamMembersData={teamMembersData}
                        setTeamMembersData={setTeamMembersData}
                    />
                </StandardContentGrid>
            ) : teamView === 'edit-member' ? (
                <StandardContentGrid>
                    <TeamModalEditTeamMember
                        closeModal={() => navigateTo('/team')}
                        teamMembersData={teamMembersData}
                        setTeamMembersData={setTeamMembersData}
                    />
                </StandardContentGrid>
            ) : (
                <StandardContentGrid>
                    <ElementContainer>
                        <TeamMembersTable
                            teamMembersData={teamMembersData}
                            setTeamMembersData={setTeamMembersData}
                            isLoadingInitialData={isLoadingInitialData}
                            hadErrorLoadingData={hadErrorLoadingData}
                            onInviteTeamMember={() => navigateTo('/team/invite-member')}
                        />
                    </ElementContainer>
                    <ElementContainer>
                        <SimpleContentBox headerText="Team Members">
                            <div className="font-normal text-sm text-grey">
                                <p>Sniply lets your work with team members</p>
                                <br />
                                <p>
                                    You can invite your team members, granting them access to your
                                    brands and links. After inviting collaborators, they will
                                    receive an email from Sniply informing them about Sniply and
                                    explaining how to join the team.
                                </p>
                                <br />
                                <p>
                                    Collaboration allows you to create Sniply links using different
                                    user accounts for the same set of brands, as well as sharing
                                    analytics and the ability to work together on Sniply campaigns.
                                </p>
                            </div>
                        </SimpleContentBox>
                    </ElementContainer>
                </StandardContentGrid>
            )}
        </FocusViewLayout>
    );
};
