import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

import { SnipsListItem } from '../../data-transformations/create-snip-data';
import { navigateTo } from '../../Routing';
import { FormStepLayout } from '../../components/FormStepLayout';
import { ButtonMain, LinkButtonMain } from '../../components/ButtonMain';
import { SnipUrlCustomizer } from '../../components/SnipUrlCustomizer';
import FacebookIcon from '../../assets/icons/facebook-minimal.svg';
import InstagramIcon from '../../assets/icons/instagram-minimal.svg';
import TwitterIcon from '../../assets/icons/twitter-minimal.svg';
import LinkedInIcon from '../../assets/icons/linkedIn-minimal.svg';
import { useIsMobileScreenSize } from '../../hooks/use-is-mobile-screen-size';
import CopyIconWhite from '../../assets/icons/copy-white.svg';
import CopyIcon from '../../assets/icons/copy.svg';
import QRCodeIconWhite from '../../assets/icons/qr-code-white.svg';
import QRCodeIcon from '../../assets/icons/qr-code.svg';
import { NotificationContext } from '../../NotificationContextProvider';

interface SnipCreateInitialSnipCreatedProps {
    readonly hasJourneyBeenStarted: boolean;
    readonly initiallyCreatedSnip: SnipsListItem;
    readonly setInitiallyCreatedSnip: (snip: SnipsListItem) => void;
}

export const SnipCreateInitialSnipCreated: React.FC<SnipCreateInitialSnipCreatedProps> = ({
    hasJourneyBeenStarted,
    initiallyCreatedSnip,
    setInitiallyCreatedSnip
}) => {
    const [domainId, setDomainId] = React.useState(
        initiallyCreatedSnip?.customDomainId || 'default'
    );
    const [shortLink, setShortLink] = React.useState(initiallyCreatedSnip?.slug || '');

    const handleFinish = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            navigateTo(`/snips/${initiallyCreatedSnip.id}?firstTime=true`);
        },
        [initiallyCreatedSnip]
    );

    React.useEffect(() => {
        if (!hasJourneyBeenStarted) {
            setTimeout(() => {
                navigateTo('/snip-create/content-select');
            }, 1);
        }
    }, [hasJourneyBeenStarted]);

    const isMobileScreen = useIsMobileScreenSize();
    const { handleOpenNotification } = React.useContext(NotificationContext);

    return (
        <FormStepLayout
            title={'Your Snip has been created!!'}
            step="2-of-4"
            buttons={
                <div className="grid grid-cols-2 gap-4">
                    <ButtonMain style="neutral" width="full" onClick={handleFinish}>
                        {`I'm Done`}
                    </ButtonMain>
                    <ButtonMain
                        width="full"
                        onClick={() => navigateTo('/snip-create/prepare-content')}
                    >
                        {isMobileScreen ? '+ Add CTA' : '+ Add Call-To-Action'}
                    </ButtonMain>
                </div>
            }
        >
            <div className="mt-2">
                {!initiallyCreatedSnip ? null : (
                    <div>
                        <div className="">
                            <p className="mt-4">
                                Now that you have successfully created a Snip, you can add a
                                Call-To-Action or further customize and share the link with the
                                world. Copy your URL, download a QR code or share on any channel you
                                want.
                            </p>
                            <div className="mt-8">
                                <h4 className="font-semibold mb-4">Customize your Snip URL</h4>
                                <div className="mt-1 flex">
                                    <SnipUrlCustomizer
                                        snip={initiallyCreatedSnip}
                                        setSnip={setInitiallyCreatedSnip}
                                        domainId={domainId}
                                        setDomainId={setDomainId}
                                        shortlink={shortLink}
                                        setShortlink={setShortLink}
                                    />
                                </div>
                            </div>
                            <div className="mt-8">
                                <h4 className="font-semibold mb-4">Share your Snip</h4>
                                <button
                                    className="py-1 px-2 border border-grey-light rounded-md flex items-center"
                                    onClick={(event) => {
                                        event.preventDefault();

                                        navigator.clipboard.writeText(initiallyCreatedSnip.snipUrl);

                                        handleOpenNotification({
                                            messageText: 'Snip URL successfully copied.',
                                            type: 'success',
                                            iconSrc: CopyIconWhite,
                                            showTimeInSeconds: 3
                                        });
                                    }}
                                >
                                    <img className="h-5 w-5" src={CopyIcon} />
                                    <span className="ml-2 text-sm">Copy Link</span>
                                </button>
                                <button
                                    className="mt-2 py-1 px-2 border border-grey-light rounded-md flex items-center"
                                    onClick={(event) => {
                                        event.preventDefault();

                                        const canvas = document.getElementById('qr-code');
                                        const pngUrl = (canvas as HTMLCanvasElement)
                                            .toDataURL('image/png')
                                            .replace('image/png', 'image/octet-stream');

                                        const downloadLink = document.createElement('a');
                                        downloadLink.href = pngUrl;
                                        downloadLink.download = `sniply-qr-code-${initiallyCreatedSnip.slug}.png`;
                                        document.body.appendChild(downloadLink);
                                        downloadLink.click();
                                        document.body.removeChild(downloadLink);

                                        handleOpenNotification({
                                            messageText: 'QR code successfully downloaded.',
                                            type: 'success',
                                            iconSrc: QRCodeIconWhite,
                                            showTimeInSeconds: 3
                                        });
                                    }}
                                >
                                    <div>
                                        <QRCodeCanvas
                                            id="qr-code"
                                            className="hidden"
                                            value={initiallyCreatedSnip.snipUrl}
                                            includeMargin
                                            fgColor="#2681DB"
                                            size={1000}
                                        />
                                        <img className="h-5 w-5" src={QRCodeIcon} />
                                    </div>
                                    <span className="ml-2 text-sm">Download QR Code</span>
                                </button>
                            </div>
                            <div className="mt-4 text-sm">
                                <p>Or use your favorite platforms directly</p>
                                <div className="mt-3 flex items-center">
                                    <LinkButtonMain
                                        href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${initiallyCreatedSnip.snipUrl}&p[title]=${initiallyCreatedSnip.contentTitle}`}
                                        size="small"
                                        style="neutral"
                                        className="ml-4"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={FacebookIcon}
                                            alt="facebook icon"
                                            className="w-5 h-5"
                                        />
                                    </LinkButtonMain>
                                    <LinkButtonMain
                                        href={`http://twitter.com/share?text=${initiallyCreatedSnip.contentTitle}&url=${initiallyCreatedSnip.snipUrl}`}
                                        size="small"
                                        style="neutral"
                                        className="ml-4"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={TwitterIcon}
                                            alt="twitter icon"
                                            className="w-5 h-5"
                                        />
                                    </LinkButtonMain>
                                    <LinkButtonMain
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${initiallyCreatedSnip.snipUrl}&title=${initiallyCreatedSnip.contentTitle}`}
                                        size="small"
                                        style="neutral"
                                        className="ml-4"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={LinkedInIcon}
                                            alt="linkedin icon"
                                            className="w-5 h-5"
                                        />
                                    </LinkButtonMain>
                                    <LinkButtonMain
                                        href={`https://www.instagram.com`}
                                        size="small"
                                        style="neutral"
                                        className="ml-4"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={InstagramIcon}
                                            alt="instagram icon"
                                            className="w-5 h-5"
                                        />
                                    </LinkButtonMain>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </FormStepLayout>
    );
};
