import { Plan, StripeNameEnum } from '../api';

export interface PlanItem {
    readonly id: string;
    readonly displayName: string;
    readonly stripeName: StripeNameEnum | '';
    readonly price: number | string;
    readonly maxCtas: number | string;
    readonly maxCtaImpressions: number | string;
    readonly maxWorkspaces: number | string;
    readonly hasCustomDomain: boolean | undefined;
    readonly hasABTesting: boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createPlanData(plan: Plan): PlanItem {
    return {
        id: plan.stripe_name,
        displayName: plan.nice_name,
        stripeName: plan.stripe_name,
        price: plan.price,
        maxCtas: plan.limits.number_of_ctas,
        maxCtaImpressions: plan.limits.cta_impressions_per_month,
        maxWorkspaces: plan.limits.profiles,
        hasCustomDomain: plan.features.custom_url,
        hasABTesting: plan.features.abtesting
    };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createPlansData(plans: Plan[]) {
    if (!plans) {
        return [];
    }

    const plansFromBackend = plans.map((plan) => createPlanData(plan));
    const plansIncludingEnterprisePlan = [
        ...plansFromBackend,
        {
            id: 'Enterprise',
            displayName: 'Enterprise',
            stripeName: null,
            price: 'custom',
            maxCtas: 'custom',
            maxCtaImpressions: 'custom',
            maxWorkspaces: 'custom',
            hasCustomDomain: true,
            hasABTesting: true
        }
    ];

    return plansIncludingEnterprisePlan;
}
