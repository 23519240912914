import React from 'react';
import DrodownIcon from '../assets/icons/dropdown-down.svg';

interface DropDownOption {
    readonly displayLabel: string;
    readonly displayValue?: string;
    readonly value: string | boolean;
}

interface DropdownSelectSimpleProps {
    readonly className?: string;
    readonly titleText?: string;
    readonly titleIcon?: JSX.Element;
    readonly placeholderText?: string;
    readonly options: DropDownOption[];
    readonly selectedOption: string | boolean;
    readonly onSelectOption: (selectedOption: string | boolean) => void;
    readonly selectedOptionLabelText?: string;
    readonly enableSearch?: boolean;
    readonly enableSelectedOptionBold?: boolean;
    readonly enableTextBase?: boolean;
    readonly searchInputPlaceholder?: string;
    readonly linkCreateNew?: JSX.Element;
    readonly nextBestActionButton?: JSX.Element;
}

export const DropdownSelectSimple: React.FC<DropdownSelectSimpleProps> = ({
    className,
    titleText,
    titleIcon,
    options,
    selectedOption,
    onSelectOption,
    nextBestActionButton
}) => {
    const [open, setOpen] = React.useState(false);

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={className}>
            {titleText && (
                <div className="mb-1 flex items-center justify-between">
                    <div className="flex items-center">
                        <span className="text-sm"> {titleText}</span>
                        {titleIcon && <span className="ml-2">{titleIcon}</span>}
                    </div>
                    {nextBestActionButton ?? null}
                </div>
            )}
            <div
                className="relative border border-grey-lighter bg-white rounded-md w-full"
                ref={dropdownContentRef}
            >
                <button
                    className={`flex items-center justify-between w-full h-10 p-3 text-sm`}
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                >
                    <div className="flex items-center justify-between w-full pr-2 min-w-0">
                        <span className="truncate">
                            {
                                options.find((option) => option.value === selectedOption)
                                    ?.displayLabel
                            }
                        </span>
                    </div>
                    {open ? (
                        <div className="ml-2 rotate-180">
                            <img className="h-2 w-2" src={DrodownIcon} />
                        </div>
                    ) : (
                        <img className="ml-2 h-2 w-2" src={DrodownIcon} />
                    )}
                </button>
                {open && (
                    <div className="absolute top-11 text-sm rounded-md shadow-md px-3 pb-2 w-full bg-white border border-grey-lighter z-50  max-h-40 overflow-y-scroll">
                        <ul>
                            {options
                                .filter((option) => option.value !== selectedOption)
                                .map((option) => (
                                    <li
                                        className="mt-2 rounded-md w-full min-w-0"
                                        key={option.value.toString()}
                                    >
                                        <button
                                            className="flex items-center justify-between w-full text-left min-w-0"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectOption(option.value);
                                                setOpen(false);
                                            }}
                                        >
                                            <span className="truncate">{option.displayLabel}</span>
                                            {option.displayValue && (
                                                <span className=" text-sm p-1 bg-grey-lighter rounded-full">
                                                    {option.displayValue}
                                                </span>
                                            )}
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
