import React from 'react';

import LockIcon from './assets/icons/lock.svg';
import { CtaCustomizingStyleContainer } from './components/CtaCustomizingStyleContainer';
import { DropdownSelect } from './components/DropdownSelect';
import { DropdownSelectSimple } from './components/DropdownSelectSimple';
import { ImageUploadWithCrop } from './components/ImageUploadWithCrop';
import { CtaPosition } from './data-transformations/create-cta-data';
import { UpgradePlanMessage } from './UpgradePlanMessageContextProvider';
import { UserPlan } from './UserContextProvider';

interface CtaImageAdCreateProps {
    readonly customizingStyle: boolean;
    readonly setImagePreviewUrl: (imageUrl: string) => void;
    // Image Ad CTA style
    readonly imageAdUrl: string;
    readonly setImageAdUrl: (imageAdUrl: string) => void;
    readonly position: CtaPosition;
    readonly onSetPosition: (position: CtaPosition) => void;
    readonly showSniplyLogo: boolean;
    readonly onSetShowSniplyLogo: (state: boolean) => void;
    readonly showWhiteSniplyLogo: boolean;
    readonly setShowWhiteSniplyLogo: (state: boolean) => void;
    readonly canHideSniplyLogo: boolean;
    readonly minimumHideSniplyLogoPlan: UserPlan;
    readonly canUseCtaPositioning: boolean;
    readonly minimumUseCtaPositioningPlan: UserPlan;
    readonly handleOpenUpgradePlanMessage: (message: UpgradePlanMessage) => void;
}

export const CtaImageAdCreate: React.FC<CtaImageAdCreateProps> = ({
    customizingStyle,
    imageAdUrl,
    setImageAdUrl,
    setImagePreviewUrl,
    position,
    onSetPosition,
    showSniplyLogo,
    onSetShowSniplyLogo,
    showWhiteSniplyLogo,
    setShowWhiteSniplyLogo,
    canHideSniplyLogo,
    minimumHideSniplyLogoPlan,
    canUseCtaPositioning,
    minimumUseCtaPositioningPlan,
    handleOpenUpgradePlanMessage
}) => {
    return !customizingStyle ? (
        <ImageUploadWithCrop
            imageUrl={imageAdUrl}
            setImageUrl={setImageAdUrl}
            setImagePreviewUrl={setImagePreviewUrl}
        />
    ) : (
        <CtaCustomizingStyleContainer>
            <DropdownSelect
                titleText="Position"
                options={[
                    { value: 'bottom-left', displayLabel: 'Bottom Left' },
                    { value: 'bottom-right', displayLabel: 'Bottom Right' },
                    { value: 'top-left', displayLabel: 'Top Left' },
                    { value: 'top-right', displayLabel: 'Top Right' }
                ]}
                selectedOption={position}
                onSelectOption={onSetPosition}
                titleIcon={
                    !canUseCtaPositioning && (
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                handleOpenUpgradePlanMessage({
                                    feature: 'Call-To-Action Position',
                                    requiredPlan: minimumUseCtaPositioningPlan
                                });
                            }}
                        >
                            <img src={LockIcon} className="h-4 w-4" />
                        </button>
                    )
                }
            />
            <DropdownSelectSimple
                titleText="Sniply Logo"
                options={[
                    { value: false, displayLabel: 'Hide Logo' },
                    { value: true, displayLabel: 'Show Logo' }
                ]}
                selectedOption={showSniplyLogo}
                onSelectOption={onSetShowSniplyLogo}
                titleIcon={
                    !canHideSniplyLogo && (
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                handleOpenUpgradePlanMessage({
                                    feature: 'Hide Sniply Logo',
                                    requiredPlan: minimumHideSniplyLogoPlan
                                });
                            }}
                        >
                            <img src={LockIcon} className="h-4 w-4" />
                        </button>
                    )
                }
            />
            <DropdownSelectSimple
                titleText="Sniply Logo Color"
                options={[
                    { value: false, displayLabel: 'Default' },
                    { value: true, displayLabel: 'White' }
                ]}
                selectedOption={showWhiteSniplyLogo}
                onSelectOption={setShowWhiteSniplyLogo}
            />
        </CtaCustomizingStyleContainer>
    );
};
