import React from 'react';
import { ButtonMain } from './ButtonMain';
import { ContentViewTabs } from './ContentViewTab';
import { FormStepLayout } from './FormStepLayout';
import { VerticalSpacer } from './VerticalSpacer';
import { SnipUrls } from '../data-transformations/create-snip-urls-data';

interface PrepareContentStepProps {
    readonly titleText?: string;
    readonly onContinueClick: () => void;
    readonly sniplyContentUrl: string;
    readonly setSniplyContentUrl: (url: string) => void;
    readonly snipUrls: SnipUrls;
}

export const PrepareContentStep: React.FC<PrepareContentStepProps> = ({
    titleText = 'Prepare Content',
    onContinueClick,
    sniplyContentUrl,
    setSniplyContentUrl,
    snipUrls
}) => {
    return (
        <FormStepLayout
            title={titleText}
            description={
                <p className="text-sm text-grey-light">
                    See which option below works best for you...
                </p>
            }
            step="1-of-2"
            buttons={
                <div className="flex justify-center">
                    <ButtonMain type="button" width="1/2" onClick={onContinueClick}>
                        Continue
                    </ButtonMain>
                </div>
            }
        >
            <div className="mt-2 h-full">
                <div className="flex justify-center">
                    <ContentViewTabs
                        currentContentView={
                            sniplyContentUrl === snipUrls.dynamic
                                ? 'dynamic'
                                : sniplyContentUrl === snipUrls.static
                                ? 'static'
                                : 'summary'
                        }
                        setCurrentContentUrl={setSniplyContentUrl}
                        snipUrls={snipUrls}
                    />
                </div>
                <VerticalSpacer heightValue={4} />
                <div className="h-full w-full p-2 mb-4 border border-grey-lighter overflow-y-scroll rounded-sm">
                    <iframe
                        src={snipUrls?.dynamic}
                        className={`${
                            sniplyContentUrl === snipUrls.dynamic ? 'flex' : 'hidden'
                        } w-full h-full`}
                    ></iframe>
                    <iframe
                        src={snipUrls?.static}
                        className={`${
                            sniplyContentUrl === snipUrls.static ? 'flex' : 'hidden'
                        } w-full h-full`}
                    ></iframe>
                    <iframe
                        src={snipUrls?.summary}
                        className={`${
                            sniplyContentUrl === snipUrls.summary ? 'flex' : 'hidden'
                        } w-full h-full`}
                    ></iframe>
                </div>
            </div>
        </FormStepLayout>
    );
};
