import React from 'react';

import CalendarIcon from './assets/icons/calendar-grey.svg';
import { CampaignsFilterDropdownSelect } from './CampaignsFilterDropdownSelect';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FilterDropdownSelectSimple } from './components/FilterDropdownSelectSimple';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ReportingContentLayout } from './components/ReportingContentLayout';
import { PageVisitStatistics } from './components/shared/PageVisitStatistics';
// import TrafficByCountry from './components/TrafficByCountry';
import { TrafficByDevice } from './components/TrafficByDevice';
import { TrafficTable } from './components/TrafficTable';
import { CtasFilterDropdownSelect } from './CtasFilterDropdownSelect';
import { ReportingGraphStats } from './data-transformations/create-reporting-graph-data';
import { ReportingStats } from './data-transformations/create-reporting-stats';
import { GraphMain } from './GraphMain';
import { ReportingTimePeriodInDays } from './Reporting';
import { ReportingOverallOverview } from './ReportingOverallOverview';
import { ReportingSnipsTable } from './ReportingSnipsTable';

interface ReportingContentProps {
    readonly stats: ReportingStats;
    readonly isLoadingStats: boolean;
    readonly hadErrorLoadingStats: boolean;
    readonly graphData: ReportingGraphStats;
    readonly isLoadingGraph: boolean;
    readonly hadErrorLoadingGraph: boolean;
    readonly selectedSnipId: string;
    readonly onSelectSnip: (id: string) => void;
    readonly selectedCampaignId: string;
    readonly onSelectCampaign: (id: string) => void;
    readonly selectedCtaId: string;
    readonly onSelectCta: (id: string) => void;
    readonly selectedTimePeriod: ReportingTimePeriodInDays;
    readonly onSelectTimePeriod: (timePeriod: ReportingTimePeriodInDays) => void;
    readonly onResetFilters: () => void;
    readonly isMobileScreen: boolean;
}

export const ReportingContent: React.FC<ReportingContentProps> = ({
    stats,
    isLoadingStats,
    hadErrorLoadingStats,
    graphData,
    isLoadingGraph,
    hadErrorLoadingGraph,
    selectedSnipId,
    onSelectSnip,
    selectedCampaignId,
    onSelectCampaign,
    selectedCtaId,
    onSelectCta,
    selectedTimePeriod,
    onSelectTimePeriod,
    onResetFilters,
    isMobileScreen
}) => {
    return (
        <div className="h-full w-full grid grid-cols-1 gap-y-5 text-grey">
            {isMobileScreen ? (
                <div className="flex flex-wrap items-center">
                    <span className="mt-2 text-sm">Filter:</span>
                    <div className="mt-2 ml-3">
                        <CampaignsFilterDropdownSelect
                            selectedCampaignId={selectedCampaignId}
                            onSelectOption={onSelectCampaign}
                        />
                    </div>
                    <div className="mt-2 ml-3">
                        <CtasFilterDropdownSelect
                            selectedCtaId={selectedCtaId}
                            onSelectOption={onSelectCta}
                        />
                    </div>
                    <div className="mt-2 ml-3">
                        <FilterDropdownSelectSimple
                            label="Time Period"
                            options={[
                                { displayLabel: 'Last 7 days', value: '7' },
                                { displayLabel: 'Last 30 days', value: '30' },
                                { displayLabel: 'All Time', value: 'all-time' }
                            ]}
                            selectedOptionId={selectedTimePeriod}
                            onSelectOption={onSelectTimePeriod}
                            iconSrc={CalendarIcon}
                            defaultOptionId="7"
                        />
                    </div>
                    {(selectedSnipId ||
                        selectedCampaignId ||
                        selectedCtaId ||
                        selectedTimePeriod !== '7') && (
                        <div className="mt-2 ml-3">
                            <button
                                className="mt-1 text-grey-light text-sm"
                                onClick={onResetFilters}
                            >
                                Reset Filters
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-wrap">
                    <div className="mt-2 flex items-center">
                        <span className="text-sm">Filter by:</span>
                        <div className="ml-3">
                            <CampaignsFilterDropdownSelect
                                selectedCampaignId={selectedCampaignId}
                                onSelectOption={onSelectCampaign}
                            />
                        </div>
                        <div className="ml-3">
                            <CtasFilterDropdownSelect
                                selectedCtaId={selectedCtaId}
                                onSelectOption={onSelectCta}
                            />
                        </div>
                        {(selectedSnipId ||
                            selectedCampaignId ||
                            selectedCtaId ||
                            selectedTimePeriod !== '7') && (
                            <button
                                className="ml-4 text-grey-light text-sm"
                                onClick={onResetFilters}
                            >
                                Reset Filters
                            </button>
                        )}
                    </div>
                    <div className="mt-2 flex grow justify-end">
                        <FilterDropdownSelectSimple
                            label="Time Period"
                            options={[
                                { displayLabel: 'Last 7 days', value: '7' },
                                { displayLabel: 'Last 30 days', value: '30' },
                                { displayLabel: 'All Time', value: 'all-time' }
                            ]}
                            selectedOptionId={selectedTimePeriod}
                            onSelectOption={onSelectTimePeriod}
                            defaultOptionId="7"
                            iconSrc={CalendarIcon}
                            openReverse
                        />
                    </div>
                </div>
            )}

            <ReportingContentLayout
                overallOverview={
                    <ContentElementContainer>
                        <div className="relative h-48 flex justify-between lg:block">
                            <h3 className="font-semibold">Overall</h3>
                            {isLoadingStats ? (
                                <div className="absolute left-1/2 -translate-x-1/2 h-full w-full flex items-center justify-center">
                                    <LoadingSpinner size={10} />
                                </div>
                            ) : hadErrorLoadingStats ? (
                                <p className="h-full w-full flex items-center justify center text-red text-sm">
                                    Apologies your data could not be retrieved. An unexpected
                                    network error has occurred. Please try refreshing the browser or
                                    try again later.
                                </p>
                            ) : (
                                <ReportingOverallOverview
                                    data={stats?.overall}
                                    selectedSnipId={selectedSnipId}
                                />
                            )}
                        </div>
                    </ContentElementContainer>
                }
                snipsList={
                    <ContentElementContainer>
                        <ReportingSnipsTable
                            selectedSnipId={selectedSnipId}
                            onSelectSnip={onSelectSnip}
                            selectedCampaignId={selectedCampaignId}
                            selectedCtaId={selectedCtaId}
                        />
                    </ContentElementContainer>
                }
                graph={
                    <ContentElementContainer>
                        <div className="relative">
                            <h3
                                className={`font-semibold top-0 left-0 ${
                                    isMobileScreen ? 'static' : 'absolute'
                                }`}
                            >
                                Performance Over Time
                            </h3>
                            <div className="h-84">
                                {isLoadingGraph ? (
                                    <div className="h-full w-full flex items-center justify-center">
                                        <LoadingSpinner size={10} />
                                    </div>
                                ) : hadErrorLoadingGraph ? (
                                    <p className="h-full w-full flex items-center justify center text-red text-sm">
                                        Apologies your data could not be retrieved. An unexpected
                                        network error has occurred. Please try refreshing the
                                        browser or try again later.
                                    </p>
                                ) : (
                                    <GraphMain data={graphData} timeFrame={selectedTimePeriod} />
                                )}
                            </div>
                        </div>
                    </ContentElementContainer>
                }
                // trafficByCountry={<TrafficByCountry data={dummyTrafficByCountryData} />}
                trafficByDevice={
                    <ContentElementContainer>
                        <div className="h-88">
                            <h3 className="text-base text-grey font-semibold">Traffic By Device</h3>
                            {isLoadingStats ? (
                                <div className="h-full w-full flex items-center justify-center">
                                    <LoadingSpinner size={10} />
                                </div>
                            ) : (
                                <TrafficByDevice data={stats?.trafficByDevice} />
                            )}
                        </div>
                    </ContentElementContainer>
                }
                trafficBySource={
                    <ContentElementContainer>
                        <div>
                            <h3 className="mb-2 text-base text-grey font-semibold">
                                Traffic By Source
                            </h3>
                            {isLoadingStats ? (
                                <div className="h-full w-full flex items-center justify-center">
                                    <LoadingSpinner size={10} />
                                </div>
                            ) : (
                                <TrafficTable dataEntries={stats?.trafficBySource} />
                            )}
                        </div>
                    </ContentElementContainer>
                }
                pageViewStatistics={
                    <ContentElementContainer>
                        <div className="h-44">
                            <h3 className="mb-2 text-base text-grey font-semibold">
                                Page View Statistics
                            </h3>
                            {isLoadingStats ? (
                                <div className="h-full w-full flex items-center justify-center">
                                    <LoadingSpinner size={10} />
                                </div>
                            ) : (
                                <PageVisitStatistics data={stats?.pageVisitInfo} />
                            )}
                        </div>
                    </ContentElementContainer>
                }
            />
        </div>
    );
};
