/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChurnkeyAuthenticationHash } from '../models/ChurnkeyAuthenticationHash';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChurnkeyService {

    /**
     * @returns ChurnkeyAuthenticationHash
     * @throws ApiError
     */
    public static churnkeyAuthHashRetrieve(): CancelablePromise<ChurnkeyAuthenticationHash> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/churnkey/auth_hash/',
        });
    }

}
