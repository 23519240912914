import React from 'react';
import ButtonBack from '../../components/ButtonBack';
import { RouteLink, useCurrentUrlPath } from '../../Routing';
import CloseIcon from '../../assets/icons/close.svg';
import { useHasSpaceForCtaSidePreview } from '../../hooks/use-has-space-for-cta-side-preview';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import { DemoView, PreviewViewSelectionTab } from '../../components/DemoContentIntegrator';
import { historyBackWithFallback } from '../../utils/history-back-with-fallback';
import { PagePreviewIntegrator } from '../../components/PagePreviewIntegrator';
import { Page } from '../../components/Page';
import { PageCreator } from '../../PageCreator';

export const PageCreate = () => {
    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    // Form Values --- Start
    const [pageName, setPageName] = React.useState('');

    const [titleText, setTitleText] = React.useState('Your Title Text');
    const [imageUrl, setImageUrl] = React.useState<string>();

    const [facebookProfileUrl, setFacebookProfileUrl] = React.useState('www.facebook.com');
    const [instagramProfileUrl, setInstagramProfileUrl] = React.useState('www.instagram.com');
    const [twitterProfileUrl, setTwitterProfileUrl] = React.useState('www.twitter.com');
    const [linkedinProfileUrl, setLinkedinProfileUrl] = React.useState('www.instagram.com');

    const [backgroundColor, setBackgroundColor] = React.useState('#FFFF');
    const [textColor, setTextColor] = React.useState('#465C6F');

    const [linksTextColor, setLinksTextColor] = React.useState('#FFFF');
    const [linksButtonColor, setLinksButtonColor] = React.useState('#4AB890');

    const [link1Text, setLink1Text] = React.useState('');
    const [link1Url, setLink1Url] = React.useState('');

    const [link2Text, setLink2Text] = React.useState('');
    const [link2Url, setLink2Url] = React.useState('');

    const [link3Text, setLink3Text] = React.useState('');
    const [link3Url, setLink3Url] = React.useState('');
    // Form Values --- End

    const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>(undefined);

    const { level2 } = useCurrentUrlPath();

    // const handleResetConfiguration = React.useCallback(() => {
    //     setPageId('');
    //     setPageName('');

    //     setTitleText('YourTitleText');
    //     setImageUrl('');

    //     setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
    //     setLinksTextColor(DEFAULT_LINKS_TEXT_COLOR);
    //     setLinksButtonColor(DEFAULT_BUTTON_COLOR);

    //     setLink1Text('');
    //     setLink1Url('');

    //     setLink2Text('');
    //     setLink2Url('');

    //     setLink3Text('');
    //     setLink3Url('');
    // }, []);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    return (
        <div className="h-full w-full flex text-grey">
            <div className="h-full w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 bg-white border-r border-grey-lighter">
                <div className="absolute z-2 bg-white w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 p-4 grid grid-cols-3 items-start">
                    <ButtonBack onClick={() => historyBackWithFallback('/')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar && level2 === 'cta-select' ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href="/">
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                    <PageCreator
                        name={pageName}
                        setName={setPageName}
                        titleText={titleText}
                        setTitleText={setTitleText}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        setImagePreviewUrl={setImagePreviewUrl}
                        facebookProfileUrl={facebookProfileUrl}
                        setFacebookProfileUrl={setFacebookProfileUrl}
                        instagramProfileUrl={instagramProfileUrl}
                        setInstagramProfileUrl={setInstagramProfileUrl}
                        twitterProfileUrl={twitterProfileUrl}
                        setTwitterProfileUrl={setTwitterProfileUrl}
                        linkedinProfileUrl={linkedinProfileUrl}
                        setLinkedinProfileUrl={setLinkedinProfileUrl}
                        backgroundColor={backgroundColor}
                        setBackgroundColor={setBackgroundColor}
                        textColor={textColor}
                        setTextColor={setTextColor}
                        linksTextColor={linksTextColor}
                        setLinksTextColor={setLinksTextColor}
                        linksButtonColor={linksButtonColor}
                        setLinksButtonColor={setLinksButtonColor}
                        link1Text={link1Text}
                        setLink1Text={setLink1Text}
                        link1Url={link1Url}
                        setLink1Url={setLink1Url}
                        link2Text={link2Text}
                        setLink2Text={setLink2Text}
                        link2Url={link2Url}
                        setLink2Url={setLink2Url}
                        link3Text={link3Text}
                        setLink3Text={setLink3Text}
                        link3Url={link3Url}
                        setLink3Url={setLink3Url}
                    />
                </div>
            </div>
            <div className={`h-full grow bg-grey-lighter`} ref={sideBarRef}>
                <PagePreviewIntegrator
                    page={
                        <Page
                            name={pageName}
                            titleText={titleText}
                            imageUrl={imagePreviewUrl || imageUrl}
                            facebookProfileUrl={facebookProfileUrl}
                            instagramProfileUrl={instagramProfileUrl}
                            twitterProfileUrl={twitterProfileUrl}
                            linkedinProfileUrl={linkedinProfileUrl}
                            backgroundColor={backgroundColor}
                            textColor={textColor}
                            linksTextColor={linksTextColor}
                            linksButtonColor={linksButtonColor}
                            link1Text={link1Text}
                            link1Url={link1Url}
                            link2Text={link2Text}
                            link2Url={link2Url}
                            link3Text={link3Text}
                            link3Url={link3Url}
                        />
                    }
                    previewViewSelectionTab={
                        hasScreenSpaceForSideBar && (
                            <PreviewViewSelectionTab
                                activeTab={activePreviewView}
                                setActiveTab={setActivePreviewView}
                            />
                        )
                    }
                    activePreviewView={activePreviewView}
                />
            </div>
        </div>
    );
};
