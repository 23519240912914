import React from 'react';
import EmptyStateImage from '../assets/images/empty-state.svg';

interface EmptyTableViewProps {
    readonly headlineText: string;
    readonly sublineText: string;
    readonly mainActionButton?: JSX.Element;
}

export const EmptyTableView: React.FC<EmptyTableViewProps> = ({
    headlineText,
    sublineText,
    mainActionButton
}) => (
    <div className="p-6 flex justify-center items-center w-full h-full text-grey border border-dashed border-grey-light rounded-md ">
        <div className="flex flex-col items-center justify-center">
            <img src={EmptyStateImage} alt="empty state image" />
            <p className="font-semibold text-center text-sm sm:text-base">{headlineText}</p>
            <p className="mt-2 text-xs sm:text-sm text-center">{sublineText}</p>
            {mainActionButton && <div className="mt-4 ">{mainActionButton}</div>}
        </div>
    </div>
);
