import React from 'react';
import { ButtonMain } from './ButtonMain';

import CopyIcon from '../assets/icons/copy.svg';
import CopyIconWhite from '../assets/icons/copy-white.svg';
import { NotificationContext } from '../NotificationContextProvider';

interface ButtonCopyProps {
    readonly copyTargetLabel: string;
    readonly textForCopyAction: string;
}

export const ButtonCopy: React.FC<ButtonCopyProps> = ({ copyTargetLabel, textForCopyAction }) => {
    const [hoveringButton, setHoveringButton] = React.useState(false);

    const { handleOpenNotification } = React.useContext(NotificationContext);

    return (
        <ButtonMain
            size="extra-small"
            style="secondary"
            onMouseOver={() => setHoveringButton(true)}
            onMouseLeave={() => setHoveringButton(false)}
            onClick={(event) => {
                event.preventDefault();
                navigator.clipboard.writeText(textForCopyAction);

                handleOpenNotification({
                    messageText: `${copyTargetLabel} successfully copied.`,
                    type: 'success',
                    iconSrc: CopyIconWhite,
                    showTimeInSeconds: 3
                });
            }}
        >
            <img
                src={hoveringButton ? CopyIconWhite : CopyIcon}
                alt="copy icon"
                className="w-4 h-4"
            />
        </ButtonMain>
    );
};
