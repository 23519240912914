import React from 'react';
import CloseIcon from '../assets/icons/close.svg';

interface LegacyModalProps {
    readonly titleText: string;
    readonly onCloseClick: () => void;
    readonly children?: React.ReactNode;
}

export const LegacyModal: React.FC<LegacyModalProps> = ({ titleText, onCloseClick, children }) => {
    return (
        <div className="bg-white border-solid border rounded-lg text-grey">
            <div className="flex justify-between items-center p-4">
                <h3 className="text-base font-semibold">{titleText}</h3>
                <button onClick={() => onCloseClick()} className="px-2">
                    <img className="h-6 w-6" src={CloseIcon} />
                </button>
            </div>
            <hr />
            <div className="md:mt-4 p-2 md:p-4">{children}</div>
        </div>
    );
};
