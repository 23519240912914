import { CtaVariant } from '../SnipsSnipContent';

export function createUpdatedCtaIds(
    newCtaId: string,
    variant: CtaVariant,
    ctaIds: string[]
): string[] {
    if (ctaIds.length === 2 && variant === 'A') {
        // Replace the A Variation
        return [newCtaId, ctaIds[1]];
    }

    if (ctaIds.length === 2 && variant === 'B') {
        // Replace the A Variation
        return [ctaIds[0], newCtaId];
    }

    if (ctaIds.length === 1 && variant === 'B') {
        // Add a B Variation
        return [ctaIds[0], newCtaId];
    }

    // Replace current single Cta
    return [newCtaId];
}
