import React from 'react';

interface TableEmptyFallbackProps {
    readonly titleText: string;
    readonly whatToDoText?: string;
    readonly primaryActionButton?: JSX.Element;
}

export const TableEmptyFallback: React.FC<TableEmptyFallbackProps> = ({
    titleText,
    whatToDoText,
    primaryActionButton
}) => {
    return (
        <div className="h-full flex p-4 items-center justify-center">
            <div className="flex flex-col items-center">
                <svg
                    width="100"
                    height="100"
                    viewBox="0 0 180 180"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M92.3759 163.224C132.618 163.224 165.24 130.602 165.24 90.36C165.24 50.1183 132.618 17.496 92.3759 17.496C52.1343 17.496 19.5119 50.1183 19.5119 90.36C19.5119 130.602 52.1343 163.224 92.3759 163.224Z"
                        fill="#EAEEF9"
                    />
                    <g filter="url(#filter0_d_230_415)">
                        <path
                            d="M77.112 46.008V44.496C77.112 43.128 75.96 41.976 74.592 41.976H32.544C31.176 41.976 30.024 43.128 30.024 44.496V48.528V139.536V143.568C30.024 144.936 31.176 146.088 32.544 146.088H159.264C160.632 146.088 161.784 144.936 161.784 143.568V50.976C161.784 49.608 160.632 48.456 159.264 48.456H79.56C78.192 48.456 77.112 47.376 77.112 46.008Z"
                            fill="white"
                        />
                    </g>
                    <g filter="url(#filter1_d_230_415)">
                        <path
                            d="M156.384 146.088H29.952C28.944 146.088 28.08 145.44 27.936 144.504L16.344 66.7439C16.2 65.6639 17.136 64.6559 18.36 64.6559H144.792C145.8 64.6559 146.664 65.3039 146.808 66.2399L158.4 144C158.544 145.08 157.608 146.088 156.384 146.088Z"
                            fill="white"
                        />
                    </g>
                    <path
                        d="M142.344 128.952H107.712L106.992 126.072H141.624L142.344 128.952Z"
                        fill="#2681DB"
                    />
                    <path
                        d="M144.144 135.144H109.512L108.792 132.264H143.424L144.144 135.144Z"
                        fill="#2681DB"
                    />
                    <path
                        d="M22.1039 38.664C24.45 38.664 26.3519 36.7621 26.3519 34.416C26.3519 32.0699 24.45 30.168 22.1039 30.168C19.7578 30.168 17.8559 32.0699 17.8559 34.416C17.8559 36.7621 19.7578 38.664 22.1039 38.664Z"
                        fill="#EAEEF9"
                    />
                    <path
                        d="M144.144 24.48C148.041 24.48 151.2 21.321 151.2 17.424C151.2 13.5271 148.041 10.368 144.144 10.368C140.247 10.368 137.088 13.5271 137.088 17.424C137.088 21.321 140.247 24.48 144.144 24.48Z"
                        fill="#EAEEF9"
                    />
                    <path
                        d="M160.776 25.4881C162.208 25.4881 163.368 24.3276 163.368 22.8961C163.368 21.4646 162.208 20.3041 160.776 20.3041C159.344 20.3041 158.184 21.4646 158.184 22.8961C158.184 24.3276 159.344 25.4881 160.776 25.4881Z"
                        fill="#EAEEF9"
                    />
                    <defs>
                        <filter
                            id="filter0_d_230_415"
                            x="20.024"
                            y="31.976"
                            width="151.76"
                            height="124.112"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.760784 0 0 0 0 0.8 0 0 0 0 0.85098 0 0 0 0.4 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_230_415"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_230_415"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter1_d_230_415"
                            x="1.32932"
                            y="49.6559"
                            width="172.085"
                            height="111.432"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="7.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.760784 0 0 0 0 0.8 0 0 0 0 0.85098 0 0 0 0.4 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_230_415"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_230_415"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>

                <h3 className="text-base text-grey font-semibold">{titleText}</h3>
                {whatToDoText && <p className="text-sm text-grey-light">{whatToDoText}</p>}
                {primaryActionButton && <div className="mt-4">{primaryActionButton}</div>}
            </div>
        </div>
    );
};
