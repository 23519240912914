/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedCancelSubscriptionRequest } from '../models/PatchedCancelSubscriptionRequest';
import type { PatchedTeamStripePortalLinkRequest } from '../models/PatchedTeamStripePortalLinkRequest';
import type { Subscribe } from '../models/Subscribe';
import type { SubscribeRequest } from '../models/SubscribeRequest';
import type { TeamStripePortalLink } from '../models/TeamStripePortalLink';
import type { TeamStripePortalLinkRequest } from '../models/TeamStripePortalLinkRequest';
import type { TrialAvailable } from '../models/TrialAvailable';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StripeService {

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static stripeCancelSubscriptionPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedCancelSubscriptionRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/stripe/cancel_subscription/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Function to get a stripe session for a subscription purchase
     * @returns Subscribe
     * @throws ApiError
     */
    public static stripeGetSessionCreate({
        requestBody,
    }: {
        requestBody: SubscribeRequest,
    }): CancelablePromise<Subscribe> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/stripe/get_session/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns a link to the Stripe customer portal
     * @returns TeamStripePortalLink
     * @throws ApiError
     */
    public static stripePortalLinkCreate({
        requestBody,
    }: {
        requestBody?: TeamStripePortalLinkRequest,
    }): CancelablePromise<TeamStripePortalLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/stripe/portal_link/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns a link to the Stripe customer portal
     * @returns TeamStripePortalLink
     * @throws ApiError
     */
    public static stripePortalLinkUpdate({
        requestBody,
    }: {
        requestBody?: TeamStripePortalLinkRequest,
    }): CancelablePromise<TeamStripePortalLink> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/stripe/portal_link/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns a link to the Stripe customer portal
     * @returns TeamStripePortalLink
     * @throws ApiError
     */
    public static stripePortalLinkPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedTeamStripePortalLinkRequest,
    }): CancelablePromise<TeamStripePortalLink> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/stripe/portal_link/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TrialAvailable
     * @throws ApiError
     */
    public static stripeTrialAvailableRetrieve(): CancelablePromise<TrialAvailable> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stripe/trial_available/',
        });
    }

}
