import React from 'react';

import { ReportsService, StatsService } from './api';
import DownloadIcon from './assets/icons/download.svg';
import SuccessIcon from './assets/icons/tick-white.svg';
import { AuthContext } from './auth';
import { ButtonMain } from './components/ButtonMain';
import { StartHeader } from './components/StartHeader';
import {
    createReportingGraphData,
    ReportingGraphStats
} from './data-transformations/create-reporting-graph-data';
import {
    createReportingStats,
    ReportingStats
} from './data-transformations/create-reporting-stats';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { NotificationContext } from './NotificationContextProvider';
// import TrafficByCountry from './components/TrafficByCountry';
// import { dummyTrafficByCountryData } from './mocks/traffic-by-country-data';
import { ReportingContent } from './ReportingContent';
import { getDateNumberForXDaysAgo } from './utils/getDateNumberForXDaysAgo';
import { WorkspaceContext } from './WorkspaceContextProvider';

export type ReportingTimePeriodInDays = '7' | '30' | 'all-time';

export const Reporting: React.FC = () => {
    const [stats, setStats] = React.useState<ReportingStats>();
    const [isLoadingStats, setIsLoadingStats] = React.useState(false);
    const [hadErrorLoadingStats, setHadErrorLoadingStats] = React.useState(false);

    const [graphData, setGraphData] = React.useState<ReportingGraphStats>();
    const [isLoadingGraph, setIsLoadingGraph] = React.useState(false);
    const [hadErrorLoadingGraph, setHadErrorLoadingGraph] = React.useState(false);

    const [selectedSnipId, setSelectedSnipId] = React.useState<string>('');
    const [selectedCampaignId, setSelectedCampaignId] = React.useState<string>('');
    const [selectedCtaId, setSelectedCtaId] = React.useState<string>('');

    const currentDate = Date.now();
    const [selectedTimePeriod, setSelectedTimePeriod] =
        React.useState<ReportingTimePeriodInDays>('7');

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const isMobileScreen = useIsMobileScreenSize();

    const handleRequestReport = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            ReportsService.reportsSnipsCreate({
                requestBody: {
                    brand: currentWorkspace.id,
                    label: selectedCampaignId || undefined
                }
            })
                .then(() => {
                    handleOpenNotification({
                        messageText:
                            'Your report request went through successfully. You will receive a download link in your email inbox very soon.',
                        type: 'success',
                        iconSrc: SuccessIcon,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.error(
                        'Error requesting report via ReportsService.reportsSnipsCreate()',
                        error
                    );

                    handleOpenNotification({
                        type: 'warning',
                        messageText:
                            'Something unexpected went wrong when requesting your report. Please try again later.',
                        showTimeInSeconds: 5
                    });
                });
        },
        [currentWorkspace, selectedCampaignId, handleOpenNotification]
    );

    const handleSelectCampaign = React.useCallback(
        (id: string) => {
            setSelectedCtaId('');

            if (id === selectedCampaignId) {
                setSelectedCampaignId('');
            } else {
                setSelectedCampaignId(id);
            }
        },
        [selectedCampaignId]
    );

    const handleSelectCta = React.useCallback((id: string) => {
        setSelectedCampaignId('');
        setSelectedCtaId(id);
    }, []);

    const handleSelectTimePeriod = React.useCallback((timePeriod: ReportingTimePeriodInDays) => {
        setSelectedTimePeriod(timePeriod);
    }, []);

    const handleSelectSnip = React.useCallback((id: string) => {
        setSelectedSnipId(id);
    }, []);

    const resetFilters = React.useCallback(() => {
        setSelectedSnipId('');
        setSelectedCampaignId('');
        setSelectedCtaId('');
        setSelectedTimePeriod('7');
        setHadErrorLoadingStats(false);
    }, []);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setIsLoadingStats(true);

            if (selectedSnipId) {
                StatsService.statsOverallLinkRetrieve({
                    linkId: selectedSnipId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsOverallLinkRetrieve()', result);
                        setStats(createReportingStats(result));
                        setIsLoadingStats(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsOverallLinkRetrieve(): ',
                            error
                        );
                        setIsLoadingStats(false);
                        setHadErrorLoadingStats(true);
                    });
            } else if (selectedCampaignId) {
                StatsService.statsOverallLabelRetrieve({
                    labelId: selectedCampaignId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsOverallCampaignRetrieve()', result);
                        setStats(createReportingStats(result));
                        setIsLoadingStats(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsOverallCampaignRetrieve(): ',
                            error
                        );
                        setIsLoadingStats(false);
                        setHadErrorLoadingStats(true);
                    });
            } else if (selectedCtaId) {
                StatsService.statsOverallCtaRetrieve({
                    ctaId: selectedCtaId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsOverallCtaRetrieve()', result);
                        setStats(createReportingStats(result));

                        setIsLoadingStats(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsOverallCtaRetrieve(): ',
                            error
                        );
                        setIsLoadingStats(false);
                        setHadErrorLoadingStats(true);
                    });
            } else {
                StatsService.statsOverallBrandRetrieve({
                    brandId: currentWorkspace.id,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsOverallBrandRetrieve()', result);
                        setStats(createReportingStats(result));

                        setIsLoadingStats(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsOverallBrandRetrieve(): ',
                            error
                        );
                        setIsLoadingStats(false);
                        setHadErrorLoadingStats(true);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isOpenAPITokenSet,
        currentWorkspace,
        selectedSnipId,
        selectedCampaignId,
        selectedCtaId,
        selectedTimePeriod
    ]);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setIsLoadingGraph(true);

            if (selectedSnipId) {
                StatsService.statsGraphLinkList({
                    linkId: selectedSnipId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsGraphLinkList()', result);
                        setGraphData(createReportingGraphData(result));
                        setIsLoadingGraph(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsGraphLinkList(): ',
                            error
                        );
                        setIsLoadingGraph(false);
                        setHadErrorLoadingGraph(true);
                    });
            } else if (selectedCampaignId) {
                StatsService.statsGraphLabelList({
                    labelId: selectedCampaignId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsGraphCampaignList()', result);
                        setGraphData(createReportingGraphData(result));
                        setIsLoadingGraph(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsGraphCampaignList(): ',
                            error
                        );
                        setIsLoadingGraph(false);
                        setHadErrorLoadingGraph(true);
                    });
            } else if (selectedCtaId) {
                StatsService.statsGraphCtaList({
                    ctaId: selectedCtaId,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsGraphCtaList()', result);
                        setGraphData(createReportingGraphData(result));

                        setIsLoadingGraph(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsGraphCtaList(): ',
                            error
                        );
                        setIsLoadingGraph(false);
                        setHadErrorLoadingGraph(true);
                    });
            } else {
                StatsService.statsGraphBrandList({
                    brandId: currentWorkspace.id,
                    startDate:
                        selectedTimePeriod === 'all-time'
                            ? undefined
                            : getDateNumberForXDaysAgo(currentDate, Number(selectedTimePeriod)),
                    endDate: selectedTimePeriod === 'all-time' ? undefined : currentDate
                })
                    .then((result) => {
                        console.log('StatsService.statsGraphBrandList()', result);
                        setGraphData(createReportingGraphData(result));

                        setIsLoadingGraph(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error fetching from StatsService.statsGraphBrandList: ',
                            error
                        );
                        setIsLoadingGraph(false);
                        setHadErrorLoadingGraph(true);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isOpenAPITokenSet,
        currentWorkspace,
        selectedSnipId,
        selectedCampaignId,
        selectedCtaId,
        selectedTimePeriod
    ]);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;

        const campaignIdFromUrl = searchParams.get('campaignId');

        if (campaignIdFromUrl) {
            setSelectedCampaignId(campaignIdFromUrl);
        }
    }, []);

    return (
        <div className="grid grid-cols-1 gap-y-3 text-grey">
            <StartHeader
                primaryHeaderLabel="Reporting"
                secondaryHeaderText="View how your Snips perform individually or by campaign."
                button={
                    <ButtonMain
                        size={isMobileScreen ? 'small' : 'normal'}
                        style="neutral"
                        className="flex items-center"
                        onClick={handleRequestReport}
                    >
                        <img src={DownloadIcon} alt="download icon" className="w-5 h-5" />
                        {!isMobileScreen && <span className="ml-2 text-sm">Request report</span>}
                    </ButtonMain>
                }
            />
            <ReportingContent
                stats={stats}
                isLoadingStats={isLoadingStats}
                hadErrorLoadingStats={hadErrorLoadingStats}
                graphData={graphData}
                isLoadingGraph={isLoadingGraph}
                hadErrorLoadingGraph={hadErrorLoadingGraph}
                selectedSnipId={selectedSnipId}
                onSelectSnip={handleSelectSnip}
                selectedCampaignId={selectedCampaignId}
                onSelectCampaign={handleSelectCampaign}
                selectedCtaId={selectedCtaId}
                onSelectCta={handleSelectCta}
                selectedTimePeriod={selectedTimePeriod}
                onSelectTimePeriod={handleSelectTimePeriod}
                onResetFilters={resetFilters}
                isMobileScreen={isMobileScreen}
            />
        </div>
    );
};
