import React from 'react';
export interface Tab {
    readonly id: string;
    readonly displayText: string;
    readonly disabled?: boolean;
}
interface TabsProps {
    readonly tabs: Tab[];
    readonly currentTabView: string;
    readonly renderTabSelectionButton: (tabId: Tab) => JSX.Element;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, currentTabView, renderTabSelectionButton }) => (
    <div className={`flex items-end text-grey h-10`}>
        {tabs.map((tab) => (
            <div key={tab.id} className="mr-3">
                {renderTabSelectionButton(tab)}
                <div
                    className={`h-0.5 w-full rounded-t-md mt-2 ${
                        currentTabView === tab.id ? 'bg-primary' : 'bg-grey-lightest'
                    }`}
                />
            </div>
        ))}
    </div>
);
