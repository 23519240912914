import React from 'react';
import { BrandsService, CustomDomainsService, UploadService } from './api';
import { BASE_DOMAIN } from './App';
import { ButtonMain } from './components/ButtonMain';
import { FocusViewLayout } from './components/FocusViewLayout';
import { FormTextInput } from './components/FormTextInput';
import { ImageUploadWithCropSimplified } from './components/ImageUploadWithCrop';
import { LoadingSpinner } from './components/LoadingSpinner';
import {
    createCustomDomainsList,
    CustomDomainsListItem
} from './data-transformations/create-custom-domains-list';
import { createWorkspace } from './data-transformations/create-workspaces-list';
import { createDomainDropdownOptions, DomainDropdownSelect } from './DomainDropdownSelect';
import { navigateTo } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { WorkspaceTeamMembersDropdown } from './components/WorkspaceTeamMembersDropdown';

export const EditWorkspace: React.FC = () => {
    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [hadErrorLoadingCustomDomains, setHadErrorLoadingCustomDomains] = React.useState(false);

    const [savingWorkspace, setSavingWorkspace] = React.useState(false);
    const [hadErrorSavingWorkspace, setHadErrorSavingWorkspace] = React.useState(false);

    const [workspaceId, setWorkspaceId] = React.useState('');
    const [workspaceName, setWorkspaceName] = React.useState('');
    const [workspaceDefaultDomainId, setWorkspaceDefaultDomainId] = React.useState('');

    const [workspaceCustomDomains, setWorkspaceCustomDomains] = React.useState<
        CustomDomainsListItem[]
    >([]);

    const [initialWorkspaceImageUrl, setInitialWorkspaceImageUrl] = React.useState('');
    const [workspacePreviewImageUrl, setWorkspacePreviewImageUrl] = React.useState('');
    const [workspaceImageUrl, setWorkspaceImageUrl] = React.useState('');

    const [fileName, setFileName] = React.useState('initialState');
    const [imageBlob, setImageBlob] = React.useState<Blob>();

    const {
        workspaces,
        setWorkspaces,
        currentWorkspace,
        setCurrentWorkspace,
        teamMembersByWorkspace
    } = React.useContext(WorkspaceContext);

    const handleSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setSavingWorkspace(true);

            if (workspacePreviewImageUrl !== initialWorkspaceImageUrl) {
                // If a new image has been selected - upload it, then proceed to update workspace.
                UploadService.uploadCreate({
                    requestBody: {
                        filename: fileName
                    }
                }).then((uploadCreateResult) => {
                    console.log(' UploadService.uploadCreate()', uploadCreateResult);
                    const newImageUrl = uploadCreateResult.upload_url.split('?')[0];

                    fetch(uploadCreateResult.upload_url, {
                        method: 'PUT',
                        headers: { 'x-amz-acl': 'public-read' },
                        body: imageBlob
                    })
                        .then(() => {
                            BrandsService.brandsPartialUpdate({
                                id: workspaceId,
                                requestBody: {
                                    name: workspaceName,
                                    photo_url: newImageUrl,
                                    default_custom_domain_id:
                                        workspaceDefaultDomainId === 'default'
                                            ? null
                                            : workspaceDefaultDomainId
                                }
                            })
                                .then((result) => {
                                    if (currentWorkspace?.id === workspaceId) {
                                        setCurrentWorkspace(createWorkspace(result));
                                    }

                                    setWorkspaces(
                                        workspaces.map((workspace) => {
                                            if (workspace.id === workspaceId) {
                                                return createWorkspace(result);
                                            } else {
                                                return workspace;
                                            }
                                        })
                                    );

                                    setSavingWorkspace(false);
                                    navigateTo('/');
                                })
                                .catch((error) =>
                                    console.log(
                                        'Error saving workspace via BrandsService.brandsCreate()',
                                        error
                                    )
                                );
                        })
                        .catch((error) => {
                            console.error('Error uploading image.', error);
                            setSavingWorkspace(false);
                            setHadErrorSavingWorkspace(true);
                        });
                });
            } else {
                BrandsService.brandsPartialUpdate({
                    id: workspaceId,
                    requestBody: {
                        name: workspaceName,
                        default_custom_domain_id:
                            workspaceDefaultDomainId === 'default' ? null : workspaceDefaultDomainId
                    }
                })
                    .then((result) => {
                        setWorkspaces(
                            workspaces.map((workspace) => {
                                if (workspace.id === workspaceId) {
                                    return createWorkspace(result);
                                } else {
                                    return workspace;
                                }
                            })
                        );

                        if (currentWorkspace?.id === workspaceId) {
                            setCurrentWorkspace(createWorkspace(result));
                        }

                        setSavingWorkspace(false);
                        navigateTo('/manage-workspaces');
                    })
                    .catch((error) =>
                        console.log(
                            'Error saving workspace via BrandsService.brandsCreate()',
                            error
                        )
                    );
            }
        },
        [
            workspaceId,
            workspaceName,
            currentWorkspace,
            setCurrentWorkspace,
            workspaces,
            setWorkspaces,
            fileName,
            imageBlob,
            initialWorkspaceImageUrl,
            workspacePreviewImageUrl,
            workspaceDefaultDomainId
        ]
    );

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const workspaceIdUrlParam = searchParams.get('workspace-id');

        if (workspaceIdUrlParam && workspaces) {
            const workspace = workspaces?.find((entry) => entry.id === workspaceIdUrlParam);

            if (!workspace) {
                return;
            }

            setWorkspaceId(workspace.id);
            setWorkspaceName(workspace.name);
            setWorkspaceDefaultDomainId(workspace.defaultCustomDomainId || 'default');

            if (
                workspace.imageUrl !==
                'https://ffb2efd5105ff0aedbc9-9cdacdeebf0faa19b665bf427f0c8092.ssl.cf1.rackcdn.com/img/profile-placeholder.png'
            ) {
                setInitialWorkspaceImageUrl(workspace.imageUrl);
                setWorkspaceImageUrl(workspace.imageUrl);
                setWorkspacePreviewImageUrl(workspace.imageUrl);
            }
        }

        CustomDomainsService.customDomainsList({
            brand: workspaceIdUrlParam
        })
            .then((result) => {
                console.log('CustomDomainsService.customDomainsList()', result);

                setWorkspaceCustomDomains(createCustomDomainsList(result));
                setHadErrorLoadingCustomDomains(false);

                setIsLoadingData(false);
            })
            .catch((error) => {
                console.log(
                    'Error fetching from CustomDomainsService.customDomainsList(): ',
                    error
                );
                setHadErrorLoadingCustomDomains(true);
                setIsLoadingData(false);
            });
    }, [workspaces]);

    const workspaceTeamMembers = teamMembersByWorkspace.find(
        (workspaceWithTeamMembers) => workspaceWithTeamMembers?.workspaceId === workspaceId
    )?.teamMembers;

    const numberOfTeamMembers = workspaceTeamMembers?.length;

    return (
        <FocusViewLayout>
            <h2 className="font-semibold text-lg">Edit Workspace</h2>
            {!workspaceId || savingWorkspace ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <div className="mt-10 grid grid-cols-1 gap-3">
                    <div className="flex items-center">
                        <div className="h-6 w-6 rounded-full border border-white bg-black text-sm text-white flex items-center justify-center">
                            {!workspacePreviewImageUrl ? (
                                workspaceName?.slice(0, 1)
                            ) : (
                                <img src={workspacePreviewImageUrl} className="rounded-full" />
                            )}
                        </div>
                        <span className="ml-3 font-medium text-grey-light">{workspaceName}</span>
                    </div>
                    <div className="mt-2">
                        <FormTextInput
                            id="workspace-input"
                            labelText="Name"
                            placeholder="Name your new workspace"
                            value={workspaceName}
                            onChange={(event) => setWorkspaceName(event.target.value)}
                            autoFocus
                        />
                    </div>
                    <div>
                        <p className="text-sm mb-2">Default Domain</p>
                        {hadErrorLoadingCustomDomains ? (
                            <p className="text-red text-sm">
                                We could not retrieve the custom domains at this moment. Please try
                                again by reloading your browser window.
                            </p>
                        ) : (
                            <DomainDropdownSelect
                                options={createDomainDropdownOptions(workspaceCustomDomains)}
                                isLoadingOptions={isLoadingData}
                                defaultOption={{ id: 'default', name: BASE_DOMAIN }}
                                selectedOptionId={workspaceDefaultDomainId}
                                onSelectOption={setWorkspaceDefaultDomainId}
                                showAddDomainButton={false}
                            />
                        )}
                    </div>
                    <div>
                        <p className="mb-1 text-sm">Image</p>
                        <ImageUploadWithCropSimplified
                            setFileName={setFileName}
                            setImageBlob={setImageBlob}
                            setImageUrl={setWorkspaceImageUrl}
                            imageUrl={workspaceImageUrl}
                            setImagePreviewUrl={setWorkspacePreviewImageUrl}
                            aspect={1}
                        />
                    </div>
                    <div>
                        <p className="mb-1 text-sm">Team Members</p>
                        {!workspaceTeamMembers ? (
                            <div className="w-full h-6 py-2 flex justify-center">
                                <LoadingSpinner size={6} />
                            </div>
                        ) : (
                            <WorkspaceTeamMembersDropdown
                                numberOfTeamMembersString={`${numberOfTeamMembers} ${
                                    numberOfTeamMembers > 1 ? 'team members' : 'team member'
                                }`}
                                options={workspaceTeamMembers?.map((teamMember) => teamMember)}
                                onSelectOption={(id: string) =>
                                    navigateTo(`/team/edit-member?teammember-id=${id}`)
                                }
                            />
                        )}
                    </div>
                    <div className="mt-4 grid grid-cols-1-2 gap-2">
                        <ButtonMain
                            size="small"
                            style="neutral"
                            onClick={() => navigateTo('/manage-workspaces')}
                        >
                            Cancel
                        </ButtonMain>
                        <ButtonMain size="small" onClick={handleSubmit}>
                            Save
                        </ButtonMain>
                    </div>
                    {hadErrorSavingWorkspace && (
                        <p className="mt-2 text-sm text-red">
                            We could not save your changes due to an unexpected error. Please try
                            again.
                        </p>
                    )}
                </div>
            )}
        </FocusViewLayout>
    );
};
