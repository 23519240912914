import React from 'react';

interface ToggleProps {
    readonly selectedOption: boolean;
    readonly onToggleChange: (newState: boolean) => void;
    readonly disabled: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({ selectedOption, onToggleChange, disabled }) => {
    return (
        <label className="flex items-center cursor-pointer relative">
            <input
                type="checkbox"
                id="toggle"
                className="sr-only"
                checked={selectedOption}
                onChange={() => {
                    onToggleChange(selectedOption ? false : true);
                }}
                disabled={disabled}
            />
            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-5 w-9 rounded-full"></div>
            <span></span>
        </label>
    );
};
