import { Brand, PaginatedBrandList } from '../api';

export interface Workspace {
    readonly id: string;
    readonly name: string;
    readonly imageUrl: string;
    readonly inActive: boolean;
    readonly numberOfLinks: number;
    readonly defaultCustomDomainId?: string;
    readonly defaultCustomDomainName?: string;
}

export function createWorkspace(brand: Brand) {
    return {
        id: brand.id,
        name: brand.name,
        imageUrl: brand.photo_url,
        inActive: brand.archived || false,
        defaultCustomDomainId: brand.default_custom_domain?.id,
        defaultCustomDomainName: brand.default_custom_domain?.custom_domain,
        numberOfLinks: brand.cache_link_count
    };
}

export function createWorkspacesList(response: PaginatedBrandList): Workspace[] {
    if (!response) {
        return [];
    }
    return response.results?.map((brand) => createWorkspace(brand));
}
