import React from 'react';

interface FeatureFlagsContextValue {
    readonly enablePagesFeature: boolean;
}

export const FeatureFlagsContext = React.createContext<FeatureFlagsContextValue>(undefined);

interface FeatureFlagsContextProviderProps {
    readonly children: React.ReactNode;
}

export const FeatureFlagsContextProvider: React.FC<FeatureFlagsContextProviderProps> = ({
    children
}) => {
    const [enablePagesFeature, setEnablePagesFeature] = React.useState(false);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const enablePagesFeatureFromQueryParams = searchParams.get('enablePagesFeature');

        if (enablePagesFeatureFromQueryParams) {
            setEnablePagesFeature(true);
        }
    }, []);

    return (
        <FeatureFlagsContext.Provider
            value={{
                enablePagesFeature
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};
