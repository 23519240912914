import React from 'react';
import { useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';

interface StartHeaderProps {
    readonly primaryHeaderLabel: string;
    readonly secondaryHeaderText: string;
    readonly button?: JSX.Element;
}

export const StartHeader: React.FC<StartHeaderProps> = ({
    primaryHeaderLabel,
    secondaryHeaderText,
    button
}) => {
    const isMobile = useIsMobileScreenSize();

    return isMobile ? (
        <div>
            <div className="flex items-center justify-between">
                <h2 className="font-semibold text-lg text-grey">{primaryHeaderLabel}</h2>
                <div className="">{button}</div>
            </div>
            <p className="mt-1 text-sm text-grey-light">{secondaryHeaderText}</p>
        </div>
    ) : (
        <div className="flex justify-between">
            <div className="w-2/3">
                <h2 className="font-semibold text-lg text-grey">{primaryHeaderLabel}</h2>
                <p className="text-sm text-grey-light">{secondaryHeaderText}</p>
            </div>
            <div>{button}</div>
        </div>
    );
};
