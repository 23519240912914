import React from 'react';

import { BrandsService } from './api';
import { FocusViewLayout } from './components/FocusViewLayout';
import { VerticalSpacer } from './components/VerticalSpacer';
import { navigateTo, useCurrentUrlPath } from './Routing';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { UserContext } from './UserContextProvider';
import { CreateNewWorkspace } from './UserSettingsCreateNewWorkspace';
import { EditWorkspace } from './UserSettingsEditWorkspace';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { WorkspacesTable } from './WorkspacesTable';
import { PromptContext } from './PromptContextProvider';
import { getNextHigherPlan } from './utils/get-next-higher-plan';

export const ManageWorkspaces: React.FC = () => {
    const { userPlan, maxWorkspaces } = React.useContext(UserContext);
    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);
    const { workspaces, setWorkspaces, teamMembersByWorkspace } =
        React.useContext(WorkspaceContext);

    const { handleOpenPrompt } = React.useContext(PromptContext);

    const handleDeActivateWorkspace = React.useCallback(
        (workspaceId) => {
            handleOpenPrompt({
                promptText: 'Do your really want to archive this workpace?',
                additionalText: 'All snips created under this workspace will be deactivated.',
                acceptButtonText: 'Yes',
                declineButtonText: 'No',
                onAcceptButtonClick: () => {
                    BrandsService.brandsPartialUpdate({
                        id: workspaceId,
                        requestBody: { archived: true }
                    })
                        .then((result) => {
                            console.log(' BrandsService.brandsPartialUpdate()', result);

                            setWorkspaces(
                                workspaces.map((workspace) => {
                                    if (workspace.id === workspaceId) {
                                        return { ...workspace, inActive: true };
                                    }
                                    return workspace;
                                })
                            );
                        })
                        .catch((error) => {
                            console.log('Error deleting workspace (i.e. brand).', error);
                        });
                }
            });
        },
        [workspaces, setWorkspaces, handleOpenPrompt]
    );

    const handleReActivateWorkspace = React.useCallback(
        (workspaceId) => {
            if (workspaces?.filter((workspace) => !workspace.inActive).length >= maxWorkspaces) {
                handleOpenUpgradePlanMessage({
                    feature: 'Workspace',
                    requiredPlan: getNextHigherPlan(userPlan),
                    message:
                        'You have reached the maximum amount of active workspaces for your current plan. To add activate workspace you will have to upgrade your plan.'
                });
            } else {
                BrandsService.brandsPartialUpdate({
                    id: workspaceId,
                    requestBody: { archived: false }
                })
                    .then((result) => {
                        console.log(' BrandsService.brandsPartialUpdate()', result);

                        setWorkspaces(
                            workspaces.map((workspace) => {
                                if (workspace.id === workspaceId) {
                                    return { ...workspace, inActive: false };
                                }
                                return workspace;
                            })
                        );
                    })
                    .catch((error) => {
                        console.log('Error deleting workspace (i.e. brand).', error);
                    });
            }
        },
        [workspaces, setWorkspaces, maxWorkspaces, userPlan, handleOpenUpgradePlanMessage]
    );

    const handleEditWorkspace = React.useCallback((workspaceId) => {
        navigateTo(`/manage-workspaces/edit?workspace-id=${workspaceId}`);
    }, []);

    const handleAddWorkspace = React.useCallback(() => {
        if (workspaces?.filter((workspace) => !workspace.inActive).length >= maxWorkspaces) {
            handleOpenUpgradePlanMessage({
                feature: 'Workspace',
                requiredPlan: getNextHigherPlan(userPlan),
                message:
                    'You have reached the maximum amount of workspaces for your current plan. To add another workspace you will have to upgrade your plan.'
            });
        } else {
            navigateTo('/manage-workspaces/add');
        }
    }, [workspaces, handleOpenUpgradePlanMessage, maxWorkspaces, userPlan]);

    const { level2: manageWorkspacesView } = useCurrentUrlPath();

    return manageWorkspacesView === 'add' ? (
        <CreateNewWorkspace />
    ) : manageWorkspacesView === 'edit' ? (
        <EditWorkspace />
    ) : (
        <FocusViewLayout enableWideView>
            <h2 className="mt-18 font-semibold text-lg">Your Workspaces</h2>
            <p className="mt-1 text-xs text-grey-medium">
                {workspaces.length > 0 && maxWorkspaces !== 0 && (
                    <>
                        <span>You are using </span>
                        <span className={'font-semibold'}>
                            {workspaces?.filter((workspace) => !workspace.inActive).length}
                        </span>
                        <span> out of </span>
                        <span className="font-semibold">{maxWorkspaces}</span>
                        <span> active workspaces included in your plan.</span>
                    </>
                )}
            </p>
            <VerticalSpacer heightValue={12} />
            <WorkspacesTable
                workspaces={workspaces}
                teamMembersByWorkspace={teamMembersByWorkspace}
                onDeActivateWorkspaceClick={handleDeActivateWorkspace}
                onReActivateWorkspaceClick={handleReActivateWorkspace}
                onEditWorkspaceClick={handleEditWorkspace}
                onAddWorkspaceClick={handleAddWorkspace}
            />
        </FocusViewLayout>
    );
};
