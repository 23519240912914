import React, { FC } from 'react';
import arrow from '../assets/images/Common/arrow45deg.svg';
import { ContentElementContainer } from './ContentElementContainer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IntegrationAppCardProps {
    readonly appName: string;
    readonly appDescriptionText: string;
    readonly iconUrl: string;
    readonly targetUrl: string;
}

export const IntegrationAppCard: FC<IntegrationAppCardProps> = ({
    appName,
    appDescriptionText,
    iconUrl,
    targetUrl
}) => {
    return (
        <ContentElementContainer>
            <div className="h-full flex flex-col justify-between">
                <div className="flex flex-col">
                    <img src={iconUrl} className="h-12.5 w-12.5 mt-3" />
                    <span className="font-semibold text-base text-grey mt-4">{appName}</span>
                    <span className="text-sm text-grey-light font-normal mt-2">
                        {appDescriptionText}{' '}
                    </span>
                </div>
                <a
                    href={targetUrl}
                    className="flex flex-row border-solid border border-grey-lighter text-grey bg-grey-lightest items-center justify-center mt-4 text-center rounded py-2 hover:bg-blue hover:border-blue hover:text-white"
                >
                    <span className="font-normal text-sm">View integration details</span>
                    <img className="ml-2" src={arrow} />
                </a>
            </div>
        </ContentElementContainer>
    );
};
