import React from 'react';
import CoffeeCupIcon from '../../assets/icons/coffee-primary.svg';
import BounceRateIcon from '../../assets/icons/bounce-rate.svg';

interface PageVisitStatisticsProps {
    readonly data: PageVisitsData;
}

export interface PageVisitsData {
    readonly averagePagesPerVisit?: string;
    readonly bounceRate: string;
    readonly averageTimeOnSite: string;
    readonly averageTimeOnFirstPage?: string;
}

interface PageVisitsListItemProps {
    readonly name: string;
    readonly displayValue: string;
    readonly icon: JSX.Element;
}

const PageVisitsListItem: React.FC<PageVisitsListItemProps> = ({ name, displayValue, icon }) => (
    <div className="flex items-center justify-between h-10">
        <div className="w-4/5 y flex items-center h-fit text-grey text-sm font-normal">
            <div className="h-4 w-4 mr-2">{icon}</div>
            {name}
        </div>
        <span className="h-fit text-grey text-sm font-normal">{displayValue}</span>
    </div>
);

export const PageVisitStatistics: React.FC<PageVisitStatisticsProps> = ({ data }) => {
    return (
        data && (
            <div>
                <ul className="">
                    {data.averageTimeOnSite && (
                        <PageVisitsListItem
                            name="Average Time on Site"
                            icon={<img src={CoffeeCupIcon} />}
                            displayValue={data.averageTimeOnSite}
                        />
                    )}
                    <hr className="outline-t outline-grey-lighter" />
                    {data.bounceRate && (
                        <PageVisitsListItem
                            name="Bounce Rate"
                            icon={<img src={BounceRateIcon} />}
                            displayValue={data.bounceRate}
                        />
                    )}
                </ul>
            </div>
        )
    );
};
