import React from 'react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { StatsService } from './api';

import ArrowIcon from './assets/icons/arrow-grey-light.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import {
    createReportingGraphData,
    ReportingGraphStats
} from './data-transformations/create-reporting-graph-data';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { navigateTo } from './Routing';

interface SnipsSnipContentPerformanceProps {
    readonly snip: SnipsListItem;
}

export const SnipsSnipContentPerformance: React.FC<SnipsSnipContentPerformanceProps> = ({
    snip
}) => {
    const [graphData, setGraphData] = React.useState<ReportingGraphStats>([]);

    const [isLoadingGraph, setIsLoadingGraph] = React.useState(false);
    const [hadErrorLoadingGraph, setHadErrorLoadingGraph] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingGraph(true);
        StatsService.statsGraphLinkList({
            linkId: snip.id,
            granularity: 'days'
        })
            .then((result) => {
                console.log('StatsService.statsGraphLinkList()', result);
                setGraphData(createReportingGraphData(result));
                setIsLoadingGraph(false);
            })
            .catch((error) => {
                console.log('Error fetching from StatsService.statsGraphLinkList(): ', error);
                setIsLoadingGraph(false);
                setHadErrorLoadingGraph(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="  font-semibold">Performance</h3>
                <button
                    className="flex items-center"
                    onClick={() => navigateTo(`/reporting/snip/${snip.id}`)}
                >
                    <img src={ArrowIcon} alt="arrow icon" className="rotate-180 w-4 h-4" />
                    <span className="ml-2 text-sm text-grey-light">View Analytics</span>
                </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1-2">
                <div className="p-1 mt-4">
                    <div className="flex items-center">
                        <span className="font-bold text-2xl">{snip.numberOfClicks}</span>
                        <span className="mt-1 ml-2 text-sm">Clicks</span>
                    </div>
                    <div className="mt-3 flex items-center">
                        <span className="font-bold text-2xl">{snip.numberOfConversions}</span>
                        <span className="mt-1 ml-2 text-sm">Conversions</span>
                    </div>
                    <div className="mt-3 flex items-center">
                        <span className="font-bold text-2xl">{snip.conversionRate}</span>
                        <span className="mt-1 ml-2 text-sm">Rate (%)</span>
                    </div>
                </div>
                <div>
                    {isLoadingGraph ? (
                        <div className="flex w-full h-full items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : hadErrorLoadingGraph ? (
                        <p className="text-sm text-red">
                            Error loading graph data, please try again by reloading the browser
                            window.
                        </p>
                    ) : graphData ? (
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={200}
                                height={60}
                                data={graphData}
                                margin={{
                                    top: 5,
                                    right: 0,
                                    left: 0,
                                    bottom: 5
                                }}
                            >
                                <defs>
                                    <linearGradient id="fill" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#2681DB" stopOpacity={0.3} />
                                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                                    </linearGradient>
                                </defs>
                                <Area
                                    type="monotone"
                                    dataKey="Clicks"
                                    stroke="#2681DB"
                                    fill="url(#fill)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : null}
                </div>
            </div>
        </ContentElementContainer>
    );
};
