import React from 'react';

import EditIcon from './assets/icons/edit.svg';
import LinkIcon from './assets/icons/link-blue.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { navigateTo } from './Routing';
import { Page } from './data-transformations/create-page-data';

interface PageOverviewGeneralInfoProps {
    readonly page: Page;
}

export const PageOverviewGeneralInfo: React.FC<PageOverviewGeneralInfoProps> = ({ page }) => {
    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="font-semibold">General Info</h3>
                <button className="flex items-center" onClick={() => navigateTo(`/page-edit`)}>
                    <img src={EditIcon} alt="edit icon" className="h-4 w-4" />
                    <span className="ml-2 text-sm text-grey-light">Edit</span>
                </button>
            </div>
            <div className="p-1 mt-4 text-sm">
                <div className="flex items-center">
                    <img src={LinkIcon} alt="link icon" className="h-4 w-4" />
                    <a
                        href={page.url}
                        className="ml-2 line-clamp-1 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {page.url}
                    </a>
                </div>
            </div>
        </ContentElementContainer>
    );
};
