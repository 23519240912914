export function sortTableRowDataBasedOnColumnId<TableRowDataType>(
    rowDataArray: TableRowDataType[],
    columnId: string,
    direction: 'ascending' | 'descending'
) {
    return direction === 'descending'
        ? rowDataArray.sort((a, b) => {
              if (a[columnId] > b[columnId]) {
                  return -1;
              } else if (a[columnId] < b[columnId]) {
                  {
                      return 1;
                  }
              } else {
                  return 0;
              }
          })
        : rowDataArray.sort((a, b) => {
              if (a[columnId] > b[columnId]) {
                  return 1;
              } else if (a[columnId] < b[columnId]) {
                  {
                      return -1;
                  }
              } else {
                  return 0;
              }
          });
}
