import React from 'react';

import { BrandsService } from './api';
import { AuthContext } from './auth';
import { ActiveBadge, InActiveBadge } from './components/Badge';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid } from './components/StandardContentGrid';
import { RouteLink } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';

export const CustomizationCustomCode = () => {
    const [customCode, setCustomCode] = React.useState('');
    const [savedCustomCode, setSavedCustomCode] = React.useState('');

    const [isLoadingCustomCode, setIsLoadingCustomCode] = React.useState(true);
    const [errorLoadingCustomCode, setErrorLoadingCustomCode] = React.useState(false);

    const [isUpdatingCustomCode, setIsUpdatingCustomCode] = React.useState(false);
    const [errorUpdatingCustomCode, setErrorUpdatingCustomCode] = React.useState(false);

    const [isCustomCodeInEditMode, setIsCustomCodeInEditMode] = React.useState(false);

    const [hasSavedCustomCode, setHasSavedCustomCode] = React.useState(false);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const handleUpdateCustomCode = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setIsUpdatingCustomCode(true);

            BrandsService.brandsPartialUpdate({
                id: currentWorkspace.id,
                requestBody: {
                    custom_code: customCode
                }
            })
                .then((result) => {
                    setIsUpdatingCustomCode(false);
                    setErrorUpdatingCustomCode(false);
                    setIsCustomCodeInEditMode(false);

                    setSavedCustomCode(result.custom_code || '');
                    setHasSavedCustomCode(true);
                })
                .catch((error) => {
                    console.error('Error updating Custom Code.', error);
                    setIsUpdatingCustomCode(false);
                    setErrorUpdatingCustomCode(true);
                });
        },
        [currentWorkspace, customCode]
    );

    const handleRemoveCustomCode = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setIsUpdatingCustomCode(true);

            BrandsService.brandsPartialUpdate({
                id: currentWorkspace.id,
                requestBody: {
                    custom_code: ''
                }
            })
                .then(() => {
                    setIsUpdatingCustomCode(false);
                    setErrorUpdatingCustomCode(false);

                    setCustomCode('');
                    setSavedCustomCode('');

                    setIsCustomCodeInEditMode(true);
                    setHasSavedCustomCode(false);
                })
                .catch((error) => {
                    console.error('Error removing Custom Code.', error);
                    setIsUpdatingCustomCode(false);
                    setErrorUpdatingCustomCode(true);
                });
        },
        [currentWorkspace]
    );

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setIsLoadingCustomCode(true);

            BrandsService.brandsRetrieve({ id: currentWorkspace.id })
                .then((result) => {
                    console.log('BrandsService.brandsRetrieve() ', result);

                    if (result.custom_code) {
                        setCustomCode(result.custom_code);
                        setSavedCustomCode(result.custom_code);
                        setHasSavedCustomCode(true);
                    } else {
                        setIsCustomCodeInEditMode(true);
                    }

                    setIsLoadingCustomCode(false);
                })
                .catch((error) => {
                    console.error(
                        'Error fetching from CustomCodesService.conversionPixelsList()',
                        error
                    );

                    setIsLoadingCustomCode(false);
                    setErrorLoadingCustomCode(true);
                });
        }
    }, [currentWorkspace, isOpenAPITokenSet]);
    return (
        <StandardContentGrid>
            <ContentElementContainer>
                <div className="flex w-full items-center justify-between">
                    <h3 className="font-semibold text-grey">Add Custom Code</h3>
                    {isLoadingCustomCode || isUpdatingCustomCode ? null : customCode &&
                      hasSavedCustomCode ? (
                        <ActiveBadge />
                    ) : (
                        <InActiveBadge />
                    )}
                </div>
                <div className="mt-5 xs:mt-5 text-grey">
                    <form onSubmit={handleUpdateCustomCode}>
                        <div className="flex flex-col mt-4 h-88">
                            <label htmlFor="custom-code-input" className="text-xs text-grey-light">
                                (Paste any analytics, tracking or re-marketing code here - it will
                                be loaded inside every snip.)
                            </label>
                            {isLoadingCustomCode || isUpdatingCustomCode ? (
                                <div className="w-full h-full flex items-center justify-center">
                                    <LoadingSpinner size={10} />
                                </div>
                            ) : errorLoadingCustomCode ? (
                                <p className="mt-2 text-xs text-red">
                                    Unable to retrieving your custom script. Please try again by
                                    reloading the browser window.
                                </p>
                            ) : !isCustomCodeInEditMode ? (
                                <div className="mt-1 border-solid border rounded-md p-3 grow font-normal text-sm text-grey break-words w-full overflow-y-scroll">
                                    {customCode}
                                </div>
                            ) : (
                                <>
                                    <textarea
                                        id="custom-code-input"
                                        rows={8}
                                        className="mt-1 border-solid border rounded-md p-3 grow font-normal text-base sm:text-sm text-grey break-words w-full outline-none appearance-none"
                                        placeholder="eg. <script>...</script>"
                                        value={customCode}
                                        onChange={(event) => {
                                            setCustomCode(event.target.value);
                                        }}
                                        autoFocus
                                    />
                                    {errorUpdatingCustomCode && (
                                        <p className="mt-2 text-xs text-red">
                                            Unable to save your custom script. Please make sure you
                                            add valid code and try saving again.
                                        </p>
                                    )}
                                </>
                            )}
                            {!isLoadingCustomCode &&
                            !isUpdatingCustomCode &&
                            isCustomCodeInEditMode ? (
                                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-4">
                                    {customCode && (
                                        <div className="grid grid-cols-2 gap-4 mt-6">
                                            <ButtonMain
                                                size="small"
                                                style="neutral"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setCustomCode(savedCustomCode);
                                                    setIsCustomCodeInEditMode(
                                                        !savedCustomCode ? true : false
                                                    );
                                                }}
                                            >
                                                Cancel
                                            </ButtonMain>
                                            <ButtonMain
                                                size="small"
                                                className="w-full"
                                                type="submit"
                                            >
                                                Save
                                            </ButtonMain>
                                        </div>
                                    )}
                                </div>
                            ) : isLoadingCustomCode || isUpdatingCustomCode ? null : (
                                <div className="grid grid-cols-2 gap-4 mt-6">
                                    <ButtonMain
                                        size="small"
                                        style="neutral"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setIsCustomCodeInEditMode(true);
                                        }}
                                    >
                                        Edit
                                    </ButtonMain>
                                    <ButtonMain
                                        size="small"
                                        style="secondary"
                                        onClick={handleRemoveCustomCode}
                                    >
                                        Remove
                                    </ButtonMain>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </ContentElementContainer>
            <ContentElementContainer>
                <SimpleContentBox headerText="Custom Code">
                    <div className="font-normal text-sm text-grey">
                        <p>
                            You can attach custom scripts to your links, allowing you to customize
                            their functionality and behavior.{' '}
                        </p>
                        <br />
                        <p>
                            For instance, attach retargeting pixels from ad platforms such as Google
                            Adwords, Facebook and Twitter to retarget your Sniply traffic.{' '}
                        </p>
                        <br />
                        <p>Simply copy and paste your code here.</p>
                        <p>
                            You are responsible for ensuring you&apos;ve obtained any necessary
                            consent pertaining to any scripts you add.
                        </p>
                        <br />
                        <p>
                            <span>
                                Here you can find more information about how to set up Custom Code:{' '}
                            </span>
                            <RouteLink
                                href="https://support.snip.ly/hc/en-us/articles/20001007386388-How-do-I-integrate-Custom-Scripts-"
                                className="text-primary underline"
                            >
                                see guide
                            </RouteLink>
                            <span>.</span>
                        </p>
                    </div>
                </SimpleContentBox>
            </ContentElementContainer>
        </StandardContentGrid>
    );
};
