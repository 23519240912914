import { CustomDomain, PaginatedCustomDomainList } from '../api';

export interface CustomDomainsListItem extends Record<string, string | boolean | string[]> {
    readonly id: string;
    readonly domainName: string;
    readonly redirectUrl: string;
    readonly isVerified: boolean;
    readonly isSubDomain: boolean;
    readonly isSelfManaged: boolean;
    readonly nameServers: string[];
    readonly txtRecord: string;
}

export function createCustomDomainsList(
    response: PaginatedCustomDomainList
): CustomDomainsListItem[] {
    if (!response) {
        return [];
    }

    return response.results.map((result) => createCustomDomain(result));
}

export function createCustomDomain(result: CustomDomain): CustomDomainsListItem {
    return {
        id: result.id,
        domainName: result.custom_domain.replace(/(^\w+:|^)\/\//, ''),
        redirectUrl: result.fallback_url,
        isVerified: result.is_verified,
        isSubDomain: result.is_subdomain,
        isSelfManaged: result.is_self_managed,
        nameServers: result.custom_domain_nameservers,
        txtRecord: `sniply-site-verification=${result.domain_verification_hash}`
    };
}
