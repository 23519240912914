import React from 'react';
import ConfigureIcon from '../assets/icons/settings-grey.svg';
import ConfigureIconLight from '../assets/icons/settings-grey-medium.svg';
import PreviewIcon from '../assets/icons/preview.svg';
import PreviewIconLight from '../assets/icons/preview-medium.svg';

export type ConfigPreviewTabOption = 'configure' | 'preview';

interface ConfigPreviewTabProps {
    readonly activeTab: ConfigPreviewTabOption;
    readonly setActiveTab: (activeTab: ConfigPreviewTabOption) => void;
}

export const ConfigPreviewTab: React.FC<ConfigPreviewTabProps> = ({ activeTab, setActiveTab }) => (
    <div className="grid grid-cols-2">
        <button
            className={`p-2 flex justify-center ${
                activeTab === 'configure'
                    ? 'border border-grey rounded-l-md'
                    : 'border-y border-l border-grey-light rounded-l-md'
            }`}
            onClick={() => setActiveTab('configure')}
        >
            <img
                src={activeTab === 'configure' ? ConfigureIcon : ConfigureIconLight}
                alt="configure icon"
                className="h-4 w-4"
            />
            {/* <span className="ml-2 text-xs">Configure</span> */}
        </button>
        <button
            className={`p-2 flex justify-center ${
                activeTab === 'preview'
                    ? 'border border-grey rounded-r-md'
                    : 'border-y border-r border-grey-light rounded-r-md'
            }`}
            onClick={() => setActiveTab('preview')}
        >
            <img
                src={activeTab === 'preview' ? PreviewIcon : PreviewIconLight}
                alt="preview icon"
                className="h-4 w-4"
            />
            {/* <span className="ml-2 text-xs">Preview</span> */}
        </button>
    </div>
);
