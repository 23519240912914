/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GraphStats } from '../models/GraphStats';
import type { OverallStats } from '../models/OverallStats';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatsService {

    /**
     * @returns GraphStats
     * @throws ApiError
     */
    public static statsGraphBrandList({
        brandId,
        endDate,
        granularity,
        startDate,
    }: {
        brandId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<Array<GraphStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/graph/brand/{brand_id}/',
            path: {
                'brand_id': brandId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns GraphStats
     * @throws ApiError
     */
    public static statsGraphCampaignList({
        campaignId,
        endDate,
        granularity,
        startDate,
    }: {
        campaignId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<Array<GraphStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/graph/campaign/{campaign_id}/',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns GraphStats
     * @throws ApiError
     */
    public static statsGraphCtaList({
        ctaId,
        endDate,
        granularity,
        startDate,
    }: {
        ctaId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<Array<GraphStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/graph/cta/{cta_id}/',
            path: {
                'cta_id': ctaId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns GraphStats
     * @throws ApiError
     */
    public static statsGraphLabelList({
        labelId,
        endDate,
        granularity,
        startDate,
    }: {
        labelId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<Array<GraphStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/graph/label/{label_id}/',
            path: {
                'label_id': labelId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns GraphStats
     * @throws ApiError
     */
    public static statsGraphLinkList({
        linkId,
        endDate,
        granularity,
        startDate,
    }: {
        linkId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<Array<GraphStats>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/graph/link/{link_id}/',
            path: {
                'link_id': linkId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns OverallStats
     * @throws ApiError
     */
    public static statsOverallBrandRetrieve({
        brandId,
        endDate,
        granularity,
        startDate,
    }: {
        brandId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<OverallStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/overall/brand/{brand_id}/',
            path: {
                'brand_id': brandId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns OverallStats
     * @throws ApiError
     */
    public static statsOverallCampaignRetrieve({
        campaignId,
        endDate,
        granularity,
        startDate,
    }: {
        campaignId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<OverallStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/overall/campaign/{campaign_id}/',
            path: {
                'campaign_id': campaignId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns OverallStats
     * @throws ApiError
     */
    public static statsOverallCtaRetrieve({
        ctaId,
        endDate,
        granularity,
        startDate,
    }: {
        ctaId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<OverallStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/overall/cta/{cta_id}/',
            path: {
                'cta_id': ctaId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns OverallStats
     * @throws ApiError
     */
    public static statsOverallLabelRetrieve({
        labelId,
        endDate,
        granularity,
        startDate,
    }: {
        labelId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<OverallStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/overall/label/{label_id}/',
            path: {
                'label_id': labelId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

    /**
     * @returns OverallStats
     * @throws ApiError
     */
    public static statsOverallLinkRetrieve({
        linkId,
        endDate,
        granularity,
        startDate,
    }: {
        linkId: string,
        endDate?: number,
        granularity?: string,
        startDate?: number,
    }): CancelablePromise<OverallStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/stats/overall/link/{link_id}/',
            path: {
                'link_id': linkId,
            },
            query: {
                'end_date': endDate,
                'granularity': granularity,
                'start_date': startDate,
            },
        });
    }

}
