import React from 'react';

import { SitesService } from './api';
import DeleteIconWhite from './assets/icons/delete-white.svg';

import { ActiveBadge, InActiveBadge } from './components/Badge';
import { SearchInput } from './components/SearchInput';
import { VerticalSpacer } from './components/VerticalSpacer';
import { WebsitesListItem } from './data-transformations/create-websites-list';
import { NotificationContext } from './NotificationContextProvider';
import { EmbedOnWebsiteEditRowButtonDropdown } from './EmbedOnWebsiteEditRowButtonDropdown';

interface EmbeddedWebsitesTableHeaderProps {
    readonly numberOfEmbeddedWebsites: number;
}

const EmbeddedWebsitesTableHeader: React.FC<EmbeddedWebsitesTableHeaderProps> = ({
    numberOfEmbeddedWebsites
}) => {
    return (
        <div className="flex px-3 items-center justify-between">
            <div className={`w-full h-full grid gap-2 grid-cols-8-4-1 mb-1 pb-1 text-xs`}>
                <span>{`Websites (${numberOfEmbeddedWebsites})`}</span>
                <span className="text-center">Verified</span>
                {/* Empty span for action button column */}
                <span></span>
            </div>
        </div>
    );
};

interface EmbeddedWebsitesTableRowProps {
    readonly listIndex: number;
    readonly website: WebsitesListItem;
    readonly onDeleteWebsite: (id: string) => void;
    readonly onVerifyWebsiteEmbeddedClick: (id: string) => void;
    readonly onOpenEditWebsite: (website: WebsitesListItem) => void;
}

const EmbeddedWebsitesTableRow: React.FC<EmbeddedWebsitesTableRowProps> = ({
    listIndex,
    website,
    onDeleteWebsite,
    onVerifyWebsiteEmbeddedClick,
    onOpenEditWebsite
}) => {
    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <div className="flex px-3 py-3 items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter">
                <div className="w-full h-full grid gap-2 grid-cols-8-4-1 items-center text-sm">
                    <span className="line-clamp-1">{website.domain}</span>
                    <span className="text-xs text-center">
                        {website.verified ? (
                            <ActiveBadge displayText="Verified" />
                        ) : (
                            <InActiveBadge displayText="Un-Verified" />
                        )}
                    </span>
                    <EmbedOnWebsiteEditRowButtonDropdown
                        website={website}
                        onDeleteWebsiteClick={onDeleteWebsite}
                        onVerifyWebsiteEmbeddedClick={onVerifyWebsiteEmbeddedClick}
                        onOpenEditWebsite={onOpenEditWebsite}
                    />
                </div>
            </div>
        </>
    );
};

interface IntegrationsEmbedOnWebsiteTableProps {
    readonly websites: WebsitesListItem[];
    readonly setWebsites: (websites: WebsitesListItem[]) => void;
    readonly handleVerifyEmbeddedOnWebsiteClick: (websiteId: string) => void;
    readonly handleOpenEditWebsite: (website: WebsitesListItem) => void;
}

export const IntegrationsEmbedOnWebsiteTable: React.FC<IntegrationsEmbedOnWebsiteTableProps> = ({
    websites,
    setWebsites,
    handleVerifyEmbeddedOnWebsiteClick,
    handleOpenEditWebsite
}) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleDeleteWebsite = React.useCallback(
        (id: string) => {
            SitesService.sitesDestroy({
                id: id
            })
                .then(() => {
                    setWebsites(websites.filter((website) => website.id !== id));
                    handleOpenNotification({
                        iconSrc: DeleteIconWhite,
                        type: 'success',
                        messageText: 'Successfully deleted website.',
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.log(
                        'Error deleting website embedding Sniply via SitesService.sitesDestroy()',
                        error
                    );
                });
        },
        [websites, setWebsites, handleOpenNotification]
    );

    const websitesForDisplay = websites.filter(
        (website) =>
            website?.domain.toLowerCase().includes(searchTerm.toLowerCase()) ||
            website?.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div className="flex items-center justify-between">
                <h3 className="font-semibold">Websites embedding Sniply</h3>
            </div>
            <VerticalSpacer heightValue={6} />
            <div className="flex justify-end">
                <SearchInput
                    searchTermState={searchTerm}
                    setSearchTermState={setSearchTerm}
                    onClearSearch={() => setSearchTerm('')}
                    placeholderText="Search"
                    isCollapsible={false}
                    isRounded
                />
            </div>
            <VerticalSpacer heightValue={2} />
            <hr className="border-t border-grey-lighter" />
            <VerticalSpacer heightValue={4} />
            <EmbeddedWebsitesTableHeader
                numberOfEmbeddedWebsites={websitesForDisplay?.length ?? 0}
            />

            <div className="h-88 overflow-scroll">
                {websites?.length ? (
                    <div className="w-full">
                        {!websitesForDisplay?.length ? (
                            <p className="mt-4 text-grey-medium text-sm">
                                No results for this search.
                            </p>
                        ) : (
                            websitesForDisplay.map((website, listIndex) => (
                                <EmbeddedWebsitesTableRow
                                    key={website.id}
                                    listIndex={listIndex}
                                    website={website}
                                    onDeleteWebsite={handleDeleteWebsite}
                                    onVerifyWebsiteEmbeddedClick={
                                        handleVerifyEmbeddedOnWebsiteClick
                                    }
                                    onOpenEditWebsite={handleOpenEditWebsite}
                                />
                            ))
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
