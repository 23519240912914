import React from 'react';

export interface Prompt {
    readonly promptText: string;
    readonly additionalText?: string;
    readonly acceptButtonText: string;
    readonly declineButtonText: string;
    readonly onAcceptButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly onDeclineButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface PromptContextValue {
    readonly showPrompt: boolean;
    readonly prompt: Prompt;
    readonly handleOpenPrompt: (prompt: Prompt) => void;
    readonly handleClosePrompt: () => void;
}

export const PromptContext = React.createContext<PromptContextValue>(undefined);

interface PromptContextProviderProps {
    readonly children: React.ReactNode;
}

export const PromptContextProvider: React.FC<PromptContextProviderProps> = ({ children }) => {
    const [showPrompt, setShowPrompt] = React.useState(false);
    const [prompt, setPrompt] = React.useState<Prompt>();

    const handleOpenPrompt = (currentPrompt: Prompt) => {
        setPrompt(currentPrompt);
        setShowPrompt(showPrompt ? false : true);
    };
    const handleClosePrompt = () => {
        setShowPrompt(false);
    };

    return (
        <PromptContext.Provider
            value={{
                showPrompt,
                prompt,
                handleOpenPrompt,
                handleClosePrompt
            }}
        >
            {children}
        </PromptContext.Provider>
    );
};
