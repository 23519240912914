import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import BufferImage from './assets/images/buffer.svg';
import { BufferService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ActiveBadge, InActiveBadge } from './components/Badge';

const TableHeader: React.FC = () => {
    return (
        <div className="flex items-center justify-between">
            <div className={`w-full mb-1 pb-1 grid grid-cols-3 gap-2 text-xs sm:text-sm`}>
                <span className="pl-2">Buffer Profile</span>
                <span className="text-center">Auto Snipping</span>
                <span className="text-center">Call-To-Action</span>
            </div>
        </div>
    );
};

interface TableRowProps {
    readonly id: string;
    readonly name: string;
    readonly enabled: boolean;
    readonly ctaId: string;
}

const TableRow: React.FC<TableRowProps> = ({ name, enabled, ctaId }) => {
    return (
        <div className={`h-12 py-6 px-2 flex items-center justify-between`}>
            <div className={`w-full grid items-center grid-cols-3`}>
                <span className="">{name}</span>
                <span>
                    {enabled ? (
                        <ActiveBadge displayText="enabled" />
                    ) : (
                        <InActiveBadge displayText="disabled" />
                    )}
                </span>
                <span className="text-xs">{ctaId}</span>
            </div>
        </div>
    );
};

export const IntegrationsBuffer: React.FC = () => {
    const [bufferAccountId, setBufferAccountId] = React.useState('');
    const [bufferProfiles, setBufferProfiles] = React.useState([]);

    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingData(true);

        BufferService.bufferCredentialsRetrieve()
            .then((result) => {
                console.log('BufferService.bufferCredentialsRetrieve()', result);
                setBufferAccountId(result.accounts[0]);
                setIsLoadingData(false);
                setHadErrorLoadingData(false);
            })
            .catch((error) => {
                console.error('Error fetching Buffer related information.', error);
                setHadErrorLoadingData(true);
                setIsLoadingData(false);
            });
    }, []);

    React.useEffect(() => {
        BufferService.bufferProfilesRetrieve().then((result) => {
            console.log('BufferService.bufferProfilesRetrieve()', result);
            setBufferProfiles([{ id: '1', name: 'profile1', snippingEnabled: true, ctaId: '123' }]);
        });
    }, []);

    const left = screen.width / 2 - 830 / 2;
    const top = screen.height / 2 - 800 / 2;

    const handleOpenBufferConnect = () => {
        window.open(
            `${process.env.API_HOST}/login/buffer/`,
            '_blank',
            'width=800,height=830,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=' +
                left +
                ',top=' +
                top
        );
    };

    return (
        <div className="h-full w-full">
            <ContentElementContainer>
                <SimpleContentBox headerText="Connect with Buffer">
                    <div className="flex flex-col p-4 items-center">
                        {bufferAccountId ? (
                            <div>
                                <TableHeader />
                                <div className={`border-t border-grey-lighter h-76 `}>
                                    {isLoadingData ? (
                                        <div className="h-full w-full flex items-center justify-center">
                                            <LoadingSpinner size={10} />
                                        </div>
                                    ) : hadErrorLoadingData ? (
                                        <div className="text-red text-sm">
                                            Apologies your Buffer information could not be
                                            retrieved. An unexpected network error has occurred.
                                            Please try again later.
                                        </div>
                                    ) : (
                                        bufferProfiles.length && (
                                            <div className="w-full">
                                                <div
                                                    className={`pt-2 w-full h-68
                                                overflow-y-scroll`}
                                                >
                                                    {bufferProfiles.map((profile) => (
                                                        <TableRow
                                                            key={profile.id}
                                                            id={profile.id}
                                                            name={profile.snipName}
                                                            enabled={profile.snippingEnabled}
                                                            ctaId={profile.ctaId}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ) : (
                            <img className="h-40 w-40" src={BufferImage} />
                        )}
                        <h3 className="text-base text-grey font-semibold">Connect with Buffer</h3>
                        <p className="text-sm text-grey-light">
                            Connect Sniply with Buffer to automatically convert any links into
                            Sniply Links
                        </p>
                        <div className="mt-3">
                            <ButtonMain size="small" onClick={() => handleOpenBufferConnect()}>
                                + Connect Buffer
                            </ButtonMain>
                        </div>
                        <a
                            className="mt-4 underline text-primary text-sm"
                            href="https://support.snip.ly/hc/en-us/articles/20000948759572-How-can-I-integrate-with-Buffer-Direct-Integration-"
                        >
                            Any Questions? Check out our Buffer guide.
                        </a>
                    </div>
                </SimpleContentBox>
            </ContentElementContainer>
        </div>
    );
};
