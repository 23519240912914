import React from 'react';
import DropdownIcon from '../assets/icons/dropdown-down.svg';

interface SimpleDropdownProps {
    readonly selectedOption: string;
    readonly options: string[];
    readonly onSelectOption: (option: string) => void;
}

export const SimpleDropdown: React.FC<SimpleDropdownProps> = ({
    selectedOption,
    options,
    onSelectOption
}) => {
    const [open, setOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="w-24 relative" ref={containerRef}>
            <button
                className="flex items-center justify-between w-full py-1 px-2 border border-grey-lighter rounded-full"
                onClick={() => setOpen(open ? false : true)}
            >
                <span>{selectedOption}</span>
                <div className="ml-2">
                    {open ? (
                        <div className="rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="h-2 w-2" src={DropdownIcon} />
                    )}
                </div>
            </button>
            {open && (
                <ul className="z-1 p-3 absolute top-10 left-0 shadow-lg rounded-lg bg-white ">
                    {options.map((option) => (
                        <li className=" bg-white rounded-md" key={option}>
                            <button
                                className={`w-full p-1 text-left ${
                                    option === selectedOption ? 'bg-grey-lightest' : 'bg-white'
                                }`}
                                onClick={() => {
                                    onSelectOption(option);
                                    setOpen(false);
                                }}
                                disabled={option === selectedOption}
                            >
                                {option}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
