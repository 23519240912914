import React from 'react';
import { RouteLink } from './Routing';
import { UserContext } from './UserContextProvider';
import { WorkspaceContext } from './WorkspaceContextProvider';
import ChevronIcon from './assets/icons/chevron-right.svg';

export const User: React.FC = () => {
    const { firstName, lastName, userProfilePictureUrl } = React.useContext(UserContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    return (
        <RouteLink href="/user" className="w-full">
            <div className="px-4 py-2 flex items-center justify-between">
                <div className="flex items-center min-w-0">
                    <div className="relative">
                        <div className="h-6 w-6 rounded-full  border border-white bg-primary text-sm text-white text-center">
                            {!userProfilePictureUrl ||
                            userProfilePictureUrl ===
                                'https://ffb2efd5105ff0aedbc9-9cdacdeebf0faa19b665bf427f0c8092.ssl.cf1.rackcdn.com/img/profile-placeholder.png' ? (
                                firstName?.slice(0, 1)
                            ) : (
                                <img src={userProfilePictureUrl} className="rounded-full" />
                            )}
                        </div>
                        <div className="absolute top-0 left-4 h-6 w-6 rounded-full border border-white bg-black text-sm text-white text-center">
                            {!currentWorkspace?.imageUrl ||
                            currentWorkspace?.imageUrl ===
                                'https://ffb2efd5105ff0aedbc9-9cdacdeebf0faa19b665bf427f0c8092.ssl.cf1.rackcdn.com/img/profile-placeholder.png' ? (
                                currentWorkspace?.name.slice(0, 1)
                            ) : (
                                <img src={currentWorkspace?.imageUrl} className="rounded-full" />
                            )}
                        </div>
                    </div>
                    <div className="ml-6 min-w-0">
                        <p className="text-xs">{`${firstName} ${lastName}`}</p>
                        <p className="mt-1 text-sm text-grey-light font-medium truncate">
                            {currentWorkspace?.name}
                        </p>
                    </div>
                </div>
                <div>
                    <img src={ChevronIcon} className="h-4 w-4" />
                </div>
            </div>
        </RouteLink>
    );
};
