import React from 'react';

interface SmallContentBoxProps {
    readonly headerText: string;
    readonly infoText?: string;
    readonly children?: React.ReactNode;
}

export const SimpleContentBox: React.FC<SmallContentBoxProps> = ({
    headerText,
    infoText,
    children
}) => {
    return (
        <div className="flex flex-col text-grey">
            <h3 className=" text-base font-semibold">{headerText}</h3>
            {infoText && <span className="mt-2 font-normal text-sm">{infoText}</span>}
            {children && <div className="mt-4">{children}</div>}
        </div>
    );
};
