import React from 'react';

import { LinksService } from '../../api';
import ConfirmedIcon from '../../assets/icons/confirmed.svg';
import RejectedIcon from '../../assets/icons/rejected.svg';
import { ButtonMain } from '../../components/ButtonMain';
import { ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import { FormStepLayout } from '../../components/FormStepLayout';
import { FormTextInput } from '../../components/FormTextInput';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { VerticalSpacer } from '../../components/VerticalSpacer';
import { ContentIntegrator } from '../../cta-components/ContentIntegrator';
import { LegacyCta } from '../../cta-components/LegacyCta';
import { CtasListItem } from '../../data-transformations/create-cta-data';
import { SnipsListItem } from '../../data-transformations/create-snip-data';
import { navigateTo } from '../../Routing';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from '../../utils/is-valid-url';
import { SnipUrls } from '../../data-transformations/create-snip-urls-data';
import { SnipUrlCustomizer } from '../../components/SnipUrlCustomizer';
import { ContentViewTabs } from '../../components/ContentViewTab';

interface SnipEditContentProps {
    readonly snipId: string;
    readonly currentSnip: SnipsListItem;
    readonly setCurrentSnip: (snip: SnipsListItem) => void;
    readonly isLoadingSnip: boolean;
    readonly originalContentUrl: string;
    readonly setOriginalContentUrl: (contentUrl: string) => void;
    readonly sniplyContentUrl: string;
    readonly setSniplyContentUrl: (contentUrl: string) => void;
    readonly domainId: string;
    readonly setDomainId: (id: string) => void;
    readonly shortLink: string;
    readonly setShortLink: (shortLink: string) => void;
    readonly cta: CtasListItem;
    readonly isContentUrlSnippable: boolean;
    readonly setIsContentUrlSnippable: (state: boolean) => void;
    readonly checkingSnippability: boolean;
    readonly showSnippability: boolean;
    readonly setShowSnippability: (state: boolean) => void;
    readonly hasContentUrlBeenEdited: boolean;
    readonly previousContentUrl: string;
    readonly mobileConfigurePreviewActiveTab: ConfigPreviewTabOption;
    readonly snipUrls: SnipUrls;
}

export const SnipEditContent: React.FC<SnipEditContentProps> = ({
    snipId,
    currentSnip,
    setCurrentSnip,
    isLoadingSnip,
    originalContentUrl,
    setOriginalContentUrl,
    sniplyContentUrl,
    setSniplyContentUrl,
    domainId,
    setDomainId,
    shortLink,
    setShortLink,
    cta,
    isContentUrlSnippable,
    setIsContentUrlSnippable,
    checkingSnippability,
    showSnippability,
    setShowSnippability,
    hasContentUrlBeenEdited,
    previousContentUrl,
    mobileConfigurePreviewActiveTab,
    snipUrls
}) => {
    const [showNoValidUrlValidationMessage, setShowNoValidUrlValidationMessage] =
        React.useState(false);

    const [showUrlNotSnippableValidationMessage, setShowUrlNotSnippableValidationMessage] =
        React.useState(false);

    const [isUpdatingSnip, setIsUpdatingSnip] = React.useState(false);
    const [errorUpdatingSnip, setErrorUpdatingSnip] = React.useState(false);
    const [errorSlugAlreadyInUse, setErrorSlugAlreadyInUse] = React.useState(false);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (checkingSnippability) {
                // Prevent keyboard "enter" until snippability is loaded.
                return;
            }

            if (previousContentUrl === sniplyContentUrl) {
                navigateTo(`/snips/${snipId}`);
                return;
            }

            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(sniplyContentUrl))) {
                setShowNoValidUrlValidationMessage(true);
            } else if (
                isValidUrlWithProtocol(ensureUrlHasProtocol(sniplyContentUrl)) &&
                !checkingSnippability &&
                !isContentUrlSnippable &&
                sniplyContentUrl !== snipUrls.summary
            ) {
                setShowUrlNotSnippableValidationMessage(true);
            } else {
                setIsUpdatingSnip(true);
                LinksService.linksPartialUpdate({
                    id: snipId,
                    requestBody: {
                        url: sniplyContentUrl
                    }
                })
                    .then((result) => {
                        console.log('Edited snip with following response:', result);
                        setIsUpdatingSnip(false);
                        navigateTo(`/snips/${snipId}`);
                    })
                    .catch((error) => {
                        console.error(
                            'Error updating the Snip via LinksService.linksPartialUpdate()',
                            error
                        );

                        if (error.body.slug?.[0].startsWith('That custom shortlink')) {
                            setErrorSlugAlreadyInUse(true);
                        } else {
                            setErrorUpdatingSnip(true);
                        }

                        setIsUpdatingSnip(false);
                    });
            }
        },
        [
            snipId,
            sniplyContentUrl,
            isContentUrlSnippable,
            checkingSnippability,
            previousContentUrl,
            snipUrls
        ]
    );

    React.useEffect(() => {
        // Remove validation errors when touching input fields or switching to Summary Mode
        if (showNoValidUrlValidationMessage) {
            setShowNoValidUrlValidationMessage(false);
        }

        if (showUrlNotSnippableValidationMessage) {
            setShowUrlNotSnippableValidationMessage(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalContentUrl, sniplyContentUrl]);

    return (
        <FormStepLayout
            title="Edit Snip"
            step="1-of-1"
            onSubmit={(event) => handleSubmit(event)}
            buttons={
                <ButtonMain
                    className="mt-4 w-full"
                    type="submit"
                    disabled={
                        isUpdatingSnip ||
                        checkingSnippability ||
                        !(
                            isValidUrlWithProtocol(ensureUrlHasProtocol(originalContentUrl)) &&
                            originalContentUrl.length > 2
                        )
                    }
                >
                    Save
                </ButtonMain>
            }
        >
            {isUpdatingSnip ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : mobileConfigurePreviewActiveTab === 'preview' ? (
                <div className="w-full h-full shadow-md rounded-sm pb-4">
                    <ContentIntegrator
                        contentUrl={sniplyContentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: cta.id,
                                    name: cta.name,
                                    type: cta.type,
                                    urlToBePromoted: cta.urlToBePromoted,
                                    headlineText: cta.headlineText,
                                    messageText: cta.messageText,
                                    messageTextColor: cta.messageTextColor,
                                    backgroundColor: cta.backgroundColor,
                                    buttonColor: cta.buttonColor,
                                    buttonText: cta.buttonText,
                                    buttonTextColor: cta.buttonTextColor,
                                    linkText: cta.linkText,
                                    linkTextColor: cta.linkTextColor,
                                    formButtonColor: cta.formButtonColor,
                                    formButtonTextColor: cta.formButtonTextColor,
                                    formPlaceholderText: cta.formPlaceholderText,
                                    imageUrl: cta.imageUrl,
                                    adImageUrl: cta.adImageUrl,
                                    position: cta.position,
                                    theme: cta.theme,
                                    showSniplyLogo: cta.showSniplyLogo,
                                    showWhiteSniplyLogo: cta.showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={true}
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                    />
                </div>
            ) : (
                <div className="mt-4">
                    <p className="text-sm">Snip Url</p>
                    <SnipUrlCustomizer
                        snip={currentSnip}
                        setSnip={setCurrentSnip}
                        domainId={domainId}
                        setDomainId={setDomainId}
                        shortlink={shortLink}
                        setShortlink={setShortLink}
                    />
                    <div className="mt-8 flex items-center">
                        <FormTextInput
                            id="content-url-input"
                            labelText="Which Content / URL do I want to share?"
                            placeholder="www.example.com/some-article"
                            value={originalContentUrl}
                            onChange={(event) => {
                                setOriginalContentUrl(event.target.value);
                                isContentUrlSnippable && setIsContentUrlSnippable(false);
                                showSnippability && setShowSnippability(false);
                            }}
                            disabled={isLoadingSnip}
                            righHandComponent={
                                hasContentUrlBeenEdited && (
                                    <>
                                        {checkingSnippability ? (
                                            <div className="flex items-center justify-center">
                                                <LoadingSpinner size={5} />
                                            </div>
                                        ) : isValidUrlWithProtocol(
                                              ensureUrlHasProtocol(originalContentUrl)
                                          ) && isContentUrlSnippable ? (
                                            <img src={ConfirmedIcon} className="h-4 w-4" />
                                        ) : isValidUrlWithProtocol(
                                              ensureUrlHasProtocol(originalContentUrl)
                                          ) && !isContentUrlSnippable ? (
                                            <img src={RejectedIcon} className="h-4 w-4" />
                                        ) : null}
                                    </>
                                )
                            }
                            autoFocus
                        />
                    </div>
                    {currentSnip?.type !== 'redirect' && snipUrls && (
                        <div className="mt-8">
                            <p className="text-sm mb-2">How to display content?</p>

                            <ContentViewTabs
                                currentContentView={
                                    sniplyContentUrl === snipUrls.dynamic
                                        ? 'dynamic'
                                        : sniplyContentUrl === snipUrls.static
                                        ? 'static'
                                        : 'summary'
                                }
                                setCurrentContentUrl={setSniplyContentUrl}
                                snipUrls={snipUrls}
                            />
                            {(!snipUrls.dynamic || !snipUrls.static || !snipUrls.summary) && (
                                <div className="mt-2 p-4 bg-orange/10">
                                    <p className="text-sm">
                                        <span className="text-orange-dark">
                                            {`*As it turns out, not all content types are supported for this url.`}
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="mt-4">
                        <VerticalSpacer heightValue={4} />
                        {showNoValidUrlValidationMessage && (
                            <p className="h-3 p-1 text-xs text-red">
                                {`Please provide a valid URL.`}
                            </p>
                        )}
                        {showUrlNotSnippableValidationMessage && (
                            <p className="h-3 p-1 text-xs text-red">
                                {`Please provide a snippable content url or use Summary Mode to continue.`}
                            </p>
                        )}
                        {errorUpdatingSnip && (
                            <p className="h-3 p-1 text-xs text-red">
                                {`Something went wrong when updating your Snip. Please try again.`}
                            </p>
                        )}
                        {errorSlugAlreadyInUse && (
                            <p className="h-3 p-1 text-xs text-red">
                                {`The custom shortlink you have selected is already being used. Please try a different one.`}
                            </p>
                        )}
                    </div>
                </div>
            )}
        </FormStepLayout>
    );
};
