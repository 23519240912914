import React from 'react';

import CloseIcon from '../assets/icons/close.svg';
import { CTARequest, CtasService, LinksService } from '../api';
import ButtonBack from '../components/ButtonBack';
import { ButtonMain } from '../components/ButtonMain';
import { CtaType } from '../components/CtaTypeDropdownSelect';
import { FormStepLayout } from '../components/FormStepLayout';
import { FormTextInput } from '../components/FormTextInput';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { VerticalSpacer } from '../components/VerticalSpacer';
import { ContentIntegrator } from '../cta-components/ContentIntegrator';
import { CtaCreator } from '../CtaCreator';
import { createCtaData, CtaPosition, CtaTheme } from '../data-transformations/create-cta-data';
import { createCtaLinkList } from '../data-transformations/create-cta-link-list';
import { useHasSpaceForCtaSidePreview } from '../hooks/use-has-space-for-cta-side-preview';
import { navigateTo, RouteLink } from '../Routing';
import { getPositionForApiCall } from '../utils/get-position-for-api-call';
import { WorkspaceContext } from '../WorkspaceContextProvider';
import { ConfigPreviewTab, ConfigPreviewTabOption } from '../components/ConfigPreviewTab';
import { LegacyCta } from '../cta-components/LegacyCta';
import { useElementWidth } from '../hooks/use-element-width';
import { MOBILE_VIEW_THRESHOLD } from '../hooks/use-is-mobile-screen-size';
import {
    DemoContentIntegrator,
    DemoView,
    PreviewViewSelectionTab
} from '../components/DemoContentIntegrator';
import { isValidUrlWithProtocol } from '../utils/is-valid-url';
import { UserContext } from '../UserContextProvider';
import { UpgradePlanMessageContext } from '../UpgradePlanMessageContextProvider';
import {
    DEFAULT_MESSAGE_TEXT_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_LINK_TEXT_COLOR,
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_CTA_POSITION,
    DEFAULT_FORM_CTA_PLACEHOLDER_TEXT
} from '../utils/default-cta-configuration';
import { ensureUrlHasProtocol } from '../utils/ensure-url-has-protocol';
import { NotificationContext } from '../NotificationContextProvider';
import { historyBackWithFallback } from '../utils/history-back-with-fallback';

export const SnipCtaEdit = () => {
    const [snipId, setSnipId] = React.useState('');
    const [mobileConfigurePreviewActiveTab, setmobileConfigurePreviewActiveTab] =
        React.useState<ConfigPreviewTabOption>('configure');

    const [activePreviewView, setActivePreviewView] = React.useState<DemoView>('mobile');

    const [showNoCtaLinkUrlError, setShowNoCtaLinkUrlError] = React.useState(false);
    const noCtaLinkUrlErrorRef = React.useRef(null);

    const errorMessageRef = React.useRef(null);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    // Form Values --- Start
    const [callToActionUrl, setCallToActionUrl] = React.useState('');
    const [contentUrl, setContentUrl] = React.useState('');

    const [ctaId, setCtaId] = React.useState('');
    const [ctaName, setCtaName] = React.useState('');
    const [ctaType, setCtaType] = React.useState<CtaType>('button');

    const [imageUrl, setImageUrl] = React.useState<string>();
    const [headlineText, setHeadlineText] = React.useState('Your Headline');

    const [messageText, setMessageText] = React.useState('Your Message');
    const [messageTextColor, setMessageTextColor] = React.useState(DEFAULT_MESSAGE_TEXT_COLOR);

    const [buttonText, setButtonText] = React.useState('Click here');
    const [buttonTextColor, setButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);

    const [linkText, setLinkText] = React.useState('Click here');
    const [linkTextColor, setLinkTextColor] = React.useState(DEFAULT_LINK_TEXT_COLOR);

    const [backgroundColor, setBackgroundColor] = React.useState(DEFAULT_BACKGROUND_COLOR);
    const [buttonColor, setButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);

    const [formButtonTextColor, setFormButtonTextColor] = React.useState(DEFAULT_BUTTON_TEXT_COLOR);
    const [formButtonColor, setFormButtonColor] = React.useState(DEFAULT_BUTTON_COLOR);
    const [formPlaceholderText, setFormPlaceholderText] = React.useState(
        DEFAULT_FORM_CTA_PLACEHOLDER_TEXT
    );

    const [imageAdUrl, setImageAdUrl] = React.useState('');

    const [theme, setTheme] = React.useState<CtaTheme>('social');
    const [position, setPosition] = React.useState<CtaPosition>('bottom-left');

    const [showSniplyLogo, setShowSniplyLogo] = React.useState(true);
    const [showWhiteSniplyLogo, setShowWhiteSniplyLogo] = React.useState(false);

    const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string>(undefined);
    const [imageAdPreviewUrl, setImageAdPreviewUrl] = React.useState<string>(undefined);
    // Form Values --- End
    //---

    const [isLoadingSnip, setIsLoadingSnip] = React.useState(false);
    const [isLoadingCta, setIsLoadingCta] = React.useState(false);
    const [isSavingCta, setIsSavingCta] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const {
        canUseImageAdCta,
        minimumImageAdPlan,
        canUseCtaColors,
        canUseCtaPositioning,
        canHideSniplyLogo
    } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleSaveEditedCta = React.useCallback(() => {
        const requestObject: CTARequest =
            ctaType === 'button'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: buttonText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          background_color: canUseCtaColors ? buttonColor : DEFAULT_BUTTON_COLOR,
                          is_text: false,
                          text_color: canUseCtaColors ? buttonTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'text-link'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: linkText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          is_text: true,
                          text_color: canUseCtaColors ? linkTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'form'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      form_action: {
                          placeholder_text: formPlaceholderText
                              ? formPlaceholderText
                              : DEFAULT_FORM_CTA_PLACEHOLDER_TEXT,
                          button_background_color: canUseCtaColors
                              ? buttonColor
                              : DEFAULT_BUTTON_COLOR,
                          button_text_color: canUseCtaColors
                              ? buttonTextColor
                              : DEFAULT_BUTTON_TEXT_COLOR,
                          submissions: [] // Does nothing at creation but required prop
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : // ctaType === 'image-ad'
                  {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      image_action: {
                          image_url: imageAdUrl,
                          url: ensureUrlHasProtocol(callToActionUrl)
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true
                  };

        setIsSavingCta(true);

        CtasService.ctasUpdate({ id: ctaId, requestBody: requestObject })
            .then((ctaResult) => {
                console.log('CtasService.ctasUpdate', ctaResult);
                setIsSavingCta(false);
                navigateTo(`/snips/${snipId}`);
            })
            .catch((error) => {
                console.error('Error posting to CtasService.ctasUpdate', error);
                handleOpenNotification({
                    type: 'warning',
                    messageText:
                        'Something unexpected went wrong when trying to save your Snip. Please try again.',
                    hasCloseButton: true
                });

                setIsSavingCta(false);
            });
    }, [
        currentWorkspace,
        snipId,
        callToActionUrl,
        ctaName,
        ctaId,
        ctaType,
        headlineText,
        imageUrl,
        imageAdUrl,
        theme,
        messageText,
        messageTextColor,
        backgroundColor,
        buttonText,
        buttonTextColor,
        linkText,
        linkTextColor,
        buttonColor,
        position,
        showSniplyLogo,
        canHideSniplyLogo,
        canUseCtaColors,
        canUseCtaPositioning,
        formPlaceholderText,
        handleOpenNotification
    ]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (
                (!callToActionUrl && ctaType !== 'form') ||
                (!isValidUrlWithProtocol(ensureUrlHasProtocol(callToActionUrl)) &&
                    ctaType !== 'form')
            ) {
                setShowNoCtaLinkUrlError(true);
                noCtaLinkUrlErrorRef?.current?.scrollIntoView();
            } else if (!messageText && ctaType !== 'image-ad') {
                setErrorMessage('Please provide a message text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'button' && !buttonText) {
                setErrorMessage('Please provide a button text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'text-link' && !linkText) {
                setErrorMessage('Please provide a link text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'form' && !formPlaceholderText) {
                setErrorMessage('Please provide a placeholder text.');
                errorMessageRef?.current?.scrollIntoView();
            } else {
                if (ctaType === 'image-ad' && !canUseImageAdCta) {
                    handleOpenUpgradePlanMessage({
                        feature: 'Image Call-To-Action',
                        requiredPlan: minimumImageAdPlan
                    });
                } else {
                    handleSaveEditedCta();
                }
            }
        },
        [
            buttonText,
            linkText,
            messageText,
            formPlaceholderText,
            handleSaveEditedCta,
            callToActionUrl,
            ctaType,
            canUseImageAdCta,
            minimumImageAdPlan,
            handleOpenUpgradePlanMessage
        ]
    );

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;
        const snipIdFromQueryParams = searchParams.get('snipId');
        const ctaIdFromQueryParams = searchParams.get('ctaId');

        if (snipIdFromQueryParams) {
            setSnipId(snipIdFromQueryParams);

            setIsLoadingSnip(true);

            LinksService.linksRetrieve({ id: snipIdFromQueryParams })
                .then((snipResult) => {
                    setContentUrl(snipResult.url);

                    setIsLoadingSnip(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoadingSnip(false);
                });
        }

        if (ctaIdFromQueryParams) {
            setCtaId(ctaIdFromQueryParams);
            setIsLoadingCta(true);

            CtasService.ctasRetrieve({ id: ctaIdFromQueryParams })
                .then((ctaResult) => {
                    const cta = createCtaData(ctaResult);

                    if (cta) {
                        setCtaName(cta.name ?? '');
                        setCallToActionUrl(cta.urlToBePromoted);
                        setCtaType(cta.type);
                        setImageUrl(cta.imageUrl);
                        setHeadlineText(cta.headlineText);
                        setMessageText(cta.messageText);
                        setMessageTextColor(cta.messageTextColor);
                        setBackgroundColor(cta.backgroundColor);
                        setButtonColor(cta.buttonColor);
                        setButtonText(cta.buttonText);
                        setButtonTextColor(cta.buttonTextColor);
                        setLinkText(cta.linkText);
                        setLinkTextColor(cta.linkTextColor);
                        setFormButtonTextColor(cta.formButtonTextColor);
                        setFormButtonColor(cta.formButtonColor);
                        setFormPlaceholderText(cta.formPlaceholderText);
                        setImageAdUrl(cta.adImageUrl);
                        setPosition(cta.position);
                        setTheme(cta.theme);
                        setShowSniplyLogo(cta.showSniplyLogo);
                        setShowWhiteSniplyLogo(cta.showWhiteSniplyLogo);
                    }

                    setIsLoadingCta(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsLoadingCta(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sideBarRef = React.useRef(null);
    const hasScreenSpaceForSideBar = useHasSpaceForCtaSidePreview(sideBarRef);

    const isSideBarWidthEquivalentOfMobileScreenWidth =
        useElementWidth(sideBarRef) < MOBILE_VIEW_THRESHOLD;

    const [ctaLinkSuggestions, setCtaLinkSuggestions] = React.useState<string[]>([]);
    const [filteredCtaLinkSuggestions, setFilteredCtaLinkSuggestions] = React.useState<string[]>(
        []
    );

    React.useEffect(() => {
        CtasService.ctasList({ brand: currentWorkspace?.id, page: 1, pageSize: 10 }).then(
            (result) => {
                console.log(result);
                setCtaLinkSuggestions(createCtaLinkList(result));
                setFilteredCtaLinkSuggestions(createCtaLinkList(result));
            }
        );
    }, [currentWorkspace]);

    // Reset error states
    React.useEffect(() => {
        setShowNoCtaLinkUrlError(false);
    }, [callToActionUrl]);

    React.useEffect(() => {
        setErrorMessage(undefined);
    }, [messageText, buttonColor, linkText, formPlaceholderText, ctaType]);

    return (
        <div className="h-full w-full flex text-grey">
            <div className="h-full w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 bg-white border-r border-grey-lighter">
                <div className="absolute z-2 bg-white w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 p-4 grid grid-cols-3 items-start">
                    <ButtonBack onClick={() => historyBackWithFallback('/')}>Back</ButtonBack>
                    {!hasScreenSpaceForSideBar ? (
                        <ConfigPreviewTab
                            activeTab={mobileConfigurePreviewActiveTab}
                            setActiveTab={setmobileConfigurePreviewActiveTab}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="flex justify-end">
                        <RouteLink href={`/snips/${snipId}`}>
                            <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                        </RouteLink>
                    </div>
                </div>
                <div className="pt-10 h-full px-6 sm:px-9 md:px-13 lg-px-17">
                    <FormStepLayout
                        title="Edit Call-To-Action"
                        step="1-of-1"
                        buttons={
                            <ButtonMain type="submit" width="full">
                                Save
                            </ButtonMain>
                        }
                        onSubmit={handleSubmit}
                    >
                        {isLoadingCta || isLoadingSnip || isSavingCta ? (
                            <div className="h-full w-full flex items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : mobileConfigurePreviewActiveTab === 'preview' ? (
                            <div className="w-full h-full border border-grey-light rounded-sm">
                                <ContentIntegrator
                                    contentUrl={contentUrl}
                                    cta={
                                        <LegacyCta
                                            configuration={{
                                                id: ctaId,
                                                name: ctaName,
                                                type: ctaType,
                                                urlToBePromoted:
                                                    ensureUrlHasProtocol(callToActionUrl),
                                                imageUrl,
                                                headlineText,
                                                messageText,
                                                messageTextColor,
                                                backgroundColor,
                                                buttonColor,
                                                buttonText,
                                                buttonTextColor,
                                                linkText,
                                                linkTextColor,
                                                formButtonColor,
                                                formButtonTextColor,
                                                formPlaceholderText,
                                                adImageUrl: imageAdUrl,
                                                position,
                                                theme,
                                                showSniplyLogo,
                                                showWhiteSniplyLogo
                                            }}
                                            disclaimerHref="https://snip.ly/whats-this2"
                                            isDemoMobileScreen={true}
                                            onConvertClick={() =>
                                                console.log('handle CTA Conversion')
                                            }
                                            onSubmitFormInputValue={() =>
                                                console.log('handle Form Submit')
                                            }
                                        />
                                    }
                                />
                            </div>
                        ) : (
                            <>
                                <FormTextInput
                                    labelText="Name Your Call-To-Action"
                                    id="cta-input"
                                    placeholder="Enter a name for your call-to-action"
                                    value={ctaName}
                                    onChange={(event) => setCtaName(event.target.value)}
                                    autoFocus
                                />
                                <VerticalSpacer heightValue={10} />
                                {ctaType !== 'form' ? (
                                    <>
                                        <div className="relative z-1">
                                            <FormTextInput
                                                id="cta-link-input"
                                                labelText="Call-To-Action Link"
                                                explanation={
                                                    <p className="mt-1 text-xs text-grey-light">
                                                        (This is where users will be sent when they
                                                        click your Call-To-Action.)
                                                    </p>
                                                }
                                                placeholder="mywebsite.com"
                                                value={callToActionUrl}
                                                onChange={(event) => {
                                                    setCallToActionUrl(event.target.value);
                                                    setFilteredCtaLinkSuggestions(
                                                        ctaLinkSuggestions.filter((suggestion) =>
                                                            suggestion.includes(callToActionUrl)
                                                        )
                                                    );
                                                }}
                                                disableAutocomplete
                                            />
                                        </div>
                                        {filteredCtaLinkSuggestions.length > 0 &&
                                            !filteredCtaLinkSuggestions.includes(
                                                callToActionUrl
                                            ) && (
                                                <div className="mx-2 p-4 grid gap-3 bg-grey-tint border border-grey-lighter rounded-md -translate-y-1">
                                                    <h4 className="text-xs font-semibold">
                                                        {!callToActionUrl
                                                            ? 'LAST USED:'
                                                            : 'SUGGESTIONS'}
                                                    </h4>

                                                    <ul className="mt-2 text-xs grid grid-cols-1 gap-1">
                                                        {filteredCtaLinkSuggestions
                                                            .slice(0, 3)
                                                            .map((suggestion) => (
                                                                <li
                                                                    className="p-1 rounded-md bg-white hover:underline"
                                                                    key={suggestion}
                                                                >
                                                                    <button
                                                                        className="w-full text-left"
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            setCallToActionUrl(
                                                                                suggestion
                                                                            );
                                                                        }}
                                                                    >
                                                                        {suggestion}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            )}
                                        <div ref={noCtaLinkUrlErrorRef}>
                                            {showNoCtaLinkUrlError && (
                                                <p className="text-xs mt-2 text-red">
                                                    Please provide a valid Call-To-Action Link Url
                                                    before proceeding.
                                                </p>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                <VerticalSpacer heightValue={10} />{' '}
                                <CtaCreator
                                    ctaName={ctaName}
                                    setCtaName={setCtaName}
                                    ctaType={ctaType}
                                    setCtaType={setCtaType}
                                    headlineText={headlineText}
                                    setHeadlineText={setHeadlineText}
                                    messageText={messageText}
                                    setMessageText={setMessageText}
                                    buttonText={buttonText}
                                    setButtonText={setButtonText}
                                    linkText={linkText}
                                    setLinkText={setLinkText}
                                    linkTextColor={linkTextColor}
                                    setLinkTextColor={setLinkTextColor}
                                    formButtonTextColor={formButtonTextColor}
                                    setFormButtonTextColor={setFormButtonTextColor}
                                    formButtonColor={formButtonColor}
                                    setFormButtonColor={setFormButtonColor}
                                    formPlaceholderText={formPlaceholderText}
                                    setFormPlaceholderText={setFormPlaceholderText}
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    imageAdUrl={imageAdUrl}
                                    setImageAdUrl={setImageAdUrl}
                                    setImagePreviewUrl={setImagePreviewUrl}
                                    setImageAdPreviewUrl={setImageAdPreviewUrl}
                                    theme={theme}
                                    setTheme={setTheme}
                                    backgroundColor={backgroundColor}
                                    setBackgroundColor={setBackgroundColor}
                                    messageTextColor={messageTextColor}
                                    setMessageTextColor={setMessageTextColor}
                                    buttonColor={buttonColor}
                                    setButtonColor={setButtonColor}
                                    buttonTextColor={buttonTextColor}
                                    setButtonTextColor={setButtonTextColor}
                                    position={position}
                                    setPosition={setPosition}
                                    showSniplyLogo={showSniplyLogo}
                                    setShowSniplyLogo={setShowSniplyLogo}
                                    showWhiteSniplyLogo={showWhiteSniplyLogo}
                                    setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                                />
                                <div className="h-5" ref={errorMessageRef}>
                                    {errorMessage && (
                                        <p className="text-xs text-red">{errorMessage} </p>
                                    )}
                                </div>
                            </>
                        )}
                    </FormStepLayout>
                </div>
            </div>
            <div
                className={`h-full grow ${
                    hasScreenSpaceForSideBar ? 'bg-grey-lighter' : 'bg-primary'
                }`}
                ref={sideBarRef}
            >
                {hasScreenSpaceForSideBar && (
                    <DemoContentIntegrator
                        contentUrl={contentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: ctaId,
                                    name: ctaName,
                                    type: ctaType,
                                    urlToBePromoted: ensureUrlHasProtocol(callToActionUrl),
                                    imageUrl: imagePreviewUrl || imageUrl,
                                    headlineText,
                                    messageText,
                                    messageTextColor,
                                    backgroundColor,
                                    buttonColor,
                                    buttonText,
                                    buttonTextColor,
                                    linkText,
                                    linkTextColor,
                                    formButtonColor,
                                    formButtonTextColor,
                                    formPlaceholderText,
                                    adImageUrl: imageAdPreviewUrl || imageAdUrl,
                                    position,
                                    theme,
                                    showSniplyLogo,
                                    showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={
                                    isSideBarWidthEquivalentOfMobileScreenWidth ||
                                    activePreviewView === 'mobile'
                                }
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                        previewViewSelectionTab={
                            hasScreenSpaceForSideBar && (
                                <PreviewViewSelectionTab
                                    activeTab={activePreviewView}
                                    setActiveTab={setActivePreviewView}
                                />
                            )
                        }
                        activePreviewView={activePreviewView}
                    />
                )}
            </div>
        </div>
    );
};
