import { OverallStats, Traffic } from '../api';
import { PageVisitsData } from '../components/shared/PageVisitStatistics';
import { TrafficByDevicesData } from '../components/TrafficByDevice';

export interface ReportingOverallStats {
    readonly numberOfSnips: number;
    readonly numberOfSnipsDelta: number;
    readonly numberOfClicks: number;
    readonly numberOfClicksDelta: number;
    readonly numberOfConversions: number;
    readonly numberOfConversionsDelta: number;
    readonly conversionRate: number;
    readonly conversionRateDelta: number;
    readonly numberOfVerifiedConversions?: number;
    readonly verifiedConversionRate?: number;
}

export interface ReportingStats {
    readonly overall: ReportingOverallStats;
    readonly trafficByDevice: TrafficByDevicesData;
    readonly pageVisitInfo: PageVisitsData;
    readonly trafficBySource: Traffic[];
}

export function createReportingStats(result: OverallStats): ReportingStats {
    const allDevicesClicks =
        result.overall.device_stats.desktop.clicks +
        result.overall.device_stats.mobile.clicks +
        result.overall.device_stats.tablet.clicks;

    return {
        overall: {
            numberOfSnips: result.overall.snips,
            numberOfSnipsDelta: Math.round(result.time_range_percentage_diff.snips),
            numberOfClicks: result.overall.clicks,
            numberOfClicksDelta: Math.round(result.time_range_percentage_diff.clicks),
            numberOfConversions: result.overall.conversions,
            numberOfConversionsDelta: Math.round(result.time_range_percentage_diff.conversions),
            conversionRate: Math.round(result.overall.conversion_rate),
            conversionRateDelta: Math.round(result.time_range_percentage_diff.conversion_rate),
            numberOfVerifiedConversions: result.overall.verified_conversions,
            verifiedConversionRate: result.overall.verified_conversion_rate
        },
        trafficByDevice: {
            desktop: {
                displayName: 'Desktop',
                displayPercentageValue:
                    allDevicesClicks === 0
                        ? 0
                        : Math.round(
                              (result.overall.device_stats.desktop.clicks / allDevicesClicks) * 100
                          )
            },
            mobile: {
                displayName: 'Mobile',
                displayPercentageValue:
                    allDevicesClicks === 0
                        ? 0
                        : Math.round(
                              (result.overall.device_stats.mobile.clicks / allDevicesClicks) * 100
                          )
            },
            tablet: {
                displayName: 'Tablet',
                displayPercentageValue:
                    allDevicesClicks === 0
                        ? 0
                        : Math.round(
                              (result.overall.device_stats.tablet.clicks / allDevicesClicks) * 100
                          )
            }
        },
        pageVisitInfo: {
            averageTimeOnSite: `${result.overall.average_time}s`,
            bounceRate: `${result.overall.bounces.bounce_rate}%`
        },
        trafficBySource: result.traffic_sources
    };
}
