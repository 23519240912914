import React from 'react';

interface CtaFormContainerProps {
    readonly children?: React.ReactNode;
}

export const CtaFormContainer: React.FC<CtaFormContainerProps> = ({ children }) => (
    <div className="p-4 pt-8 grid grid-cols-1 gap-3 bg-grey-tint border border-grey-lighter rounded-md -translate-y-5">
        {children}
    </div>
);
