import React from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { ButtonMain } from './ButtonMain';
import DropdownIcon from '../assets/icons/dropdown-down.svg';

interface CtaColorPickerProps {
    readonly titleText: string;
    readonly colorHexValue: string;
    readonly onChangeColor: (colorHexValue: string) => void;
}

export const CtaColorPicker: React.FC<CtaColorPickerProps> = ({
    titleText,
    colorHexValue,
    onChangeColor
}) => {
    const [showColorPicker, setShowColorPicker] = React.useState(false);

    const colorPickerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setShowColorPicker(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <span className="text-sm text-grey font-light">{titleText}</span>
            {showColorPicker ? (
                <div
                    className="p-2 absolute z-1 border border-grey-light rounded-md bg-white"
                    ref={colorPickerRef}
                >
                    <HexColorPicker color={colorHexValue} onChange={onChangeColor} />
                    <div className="mt-1 p-2 border border-grey-light rounded-md w-full">
                        <HexColorInput color={colorHexValue} onChange={onChangeColor} />
                    </div>
                    <div className="flex justify-center">
                        <ButtonMain
                            className="mt-2"
                            size="small"
                            style="secondary"
                            onClick={(event) => {
                                event.preventDefault();
                                setShowColorPicker(false);
                            }}
                        >
                            Select this color
                        </ButtonMain>
                    </div>
                </div>
            ) : (
                <button
                    className="p-3 h-10 flex items-center justify-between w-full bg-white text-sm border border-grey-lighter rounded-md"
                    onClick={(event) => {
                        event.preventDefault();
                        setShowColorPicker(true);
                    }}
                >
                    <div
                        style={{ background: colorHexValue }}
                        className={`h-6 w-3/4 border border-grey-light`}
                    />
                    <img className="h-2 w-2" src={DropdownIcon} />
                </button>
            )}
        </div>
    );
};
