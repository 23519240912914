import React from 'react';
import { SnipUrls } from '../../data-transformations/create-snip-urls-data';
import { navigateTo } from '../../Routing';
import { PrepareContentStep } from '../../components/PrepareContentStep';

interface SnipCreatePrepareContentProps {
    readonly hasJourneyBeenStarted: boolean;
    readonly snipUrls: SnipUrls;
    readonly sniplyContentUrl: string;
    readonly setSniplyContentUrl: (url: string) => void;
}

export const SnipCreatePrepareContent: React.FC<SnipCreatePrepareContentProps> = ({
    hasJourneyBeenStarted,
    snipUrls,
    sniplyContentUrl,
    setSniplyContentUrl
}) => {
    React.useEffect(() => {
        if (!hasJourneyBeenStarted) {
            setTimeout(() => {
                navigateTo('/snip-create/content-select');
            }, 1);
        }
    }, [hasJourneyBeenStarted]);

    return (
        <PrepareContentStep
            titleText="Prepare Content"
            onContinueClick={() => navigateTo('/snip-create/cta-select')}
            sniplyContentUrl={sniplyContentUrl}
            setSniplyContentUrl={setSniplyContentUrl}
            snipUrls={snipUrls}
        />
    );
};
