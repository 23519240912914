import { PaginatedRSSFeedList } from '../api';
import { getNumberOfDays } from '../utils/get-number-of-days';

export interface FeedsListItem extends Record<string, string | number | boolean> {
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly outputUrl: string;
    readonly ctaId: string;
    readonly campaignId?: string;
    readonly daysAgoSaved: string;
}

export function createFeedsList(response: PaginatedRSSFeedList): FeedsListItem[] {
    if (!response) {
        return [];
    }

    return response.results
        ?.filter((feedResult) => !feedResult.deleted)
        .map((feedResult) => {
            const numberOfDaysAgoSaved = getNumberOfDays(
                new Date(feedResult.created_date),
                new Date(Date.now())
            );

            return {
                id: feedResult.id,
                name: feedResult.feed_title,
                url: feedResult.feed_url,
                outputUrl: feedResult.link,
                ctaId: feedResult.cta_ids[0],
                campaignId: feedResult.label,
                daysAgoSaved: `${numberOfDaysAgoSaved} ${
                    numberOfDaysAgoSaved === 1 ? 'day' : 'days'
                } ago`
            };
        });
}
