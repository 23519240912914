import React from 'react';

export function useLocalStorage<T>(
    key: string,
    initialValue: T
): [T, (value: T | ((val: T) => T)) => void] {
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export function useChromeLocalStorage(key: string, initialValue: string | boolean) {
    const [storedValue, setStoredValue] = React.useState<string | boolean>(initialValue);

    const setValue = (value: string | boolean) => {
        try {
            setStoredValue(value);
            chrome.storage.local.set({ [key]: value });
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        if (!key || !setStoredValue) {
            return;
        }

        try {
            chrome.storage.local.get(key, (result) => {
                if (result[key]) {
                    setStoredValue(result[key]);
                } else {
                    console.log('No value found for chrome storage key: ', key);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [key, setStoredValue]);

    return [storedValue, setValue];
}
