import 'react-image-crop/dist/ReactCrop.css';

import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import UploadIcon from '../../assets/icons/upload.svg';

import { useDebounceEffect } from './useDebounceEffect';
import { createImage } from './create-image';
import { UploadService } from '../../api';
import { LoadingSpinner } from '../LoadingSpinner';
import { ButtonMain } from '../ButtonMain';

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier, so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

interface ImageUploadWithCropProps {
    readonly imageUrl: string;
    readonly setImageUrl: (imageUrl: string) => void;
    readonly setImagePreviewUrl: (url: string) => void;
    readonly showScale?: boolean;
    readonly aspect?: number;
}

export const ImageUploadWithCrop: React.FC<ImageUploadWithCropProps> = ({
    imageUrl,
    setImageUrl,
    setImagePreviewUrl,
    aspect
}) => {
    const [imgSrc, setImgSrc] = useState('');
    const imgRef = useRef<HTMLImageElement>(null);

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const cropSectionContainer = React.useRef<HTMLDivElement | null>(null);

    const [fileName, setFileName] = useState<string>();
    const [fileType, setFileType] = useState<string>();
    const [imageBlob, setImageBlob] = useState<Blob>();

    const [isSavingImage, setIsSavingImage] = useState(false);
    const [hadErrorSavingImage, setHadErrorSavingImage] = useState(false);
    const [hasImageBeenUploaded, setHasImageBeenUploaded] = useState(false);
    const [, setIsImageGif] = useState(false);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        setHadErrorSavingImage(false);

        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '');
                setHasImageBeenUploaded(false);
                setFileName(e.target.files[0].name);
                setFileType(e.target.files[0].type);

                setImagePreviewUrl(reader.result?.toString() || '');
                setImageBlob(e.target.files[0]);
                // Only required if we bring back ReactCrop
                // setTimeout(
                //     () => cropSectionContainer?.current?.scrollIntoView({ behavior: 'smooth' }),
                //     100
                // );
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect || 16 / 9));
    }

    function onImageLoadGif() {
        setCrop(undefined);
    }

    function onImageSave() {
        setIsSavingImage(true);
        setHadErrorSavingImage(false);

        UploadService.uploadCreate({
            requestBody: {
                filename: fileName
            }
        }).then((uploadCreateResult) => {
            const newImageUrl = uploadCreateResult.upload_url.split('?')[0];

            fetch(uploadCreateResult.upload_url, {
                method: 'PUT',
                headers: { 'x-amz-acl': 'public-read' },
                body: imageBlob
            })
                .then(() => {
                    setImageUrl(newImageUrl);
                    setIsSavingImage(false);
                    setHasImageBeenUploaded(true);
                    setHadErrorSavingImage(false);
                })
                .catch((error) => {
                    console.error('Error uploading image.', error);
                    setIsSavingImage(false);
                    setHadErrorSavingImage(true);
                });
        });
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current) {
                const { previewUrl, blob } = await createImage(imgRef.current, completedCrop);

                if (previewUrl) {
                    setImagePreviewUrl(previewUrl);
                }

                if (blob) {
                    setImageBlob(blob);
                }
            }
        },
        100,
        [completedCrop]
    );

    useEffect(() => {
        if (fileType) {
            fileType === 'image/gif' ? setIsImageGif(true) : setIsImageGif(false);
        }
    }, [fileType]);

    return (
        <div className="w-full">
            {isSavingImage ? (
                <div className="w-full h-full flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <div>
                    <p className="text-sm">Image</p>
                    {hasImageBeenUploaded && fileName && (
                        <p className="my-2 line-clamp-1 text-sm font-semibold">{fileName}</p>
                    )}
                    {hadErrorSavingImage && (
                        <p className="my-2 text-sm text-red">
                            The image could not be uploaded at this time for unexpected reasons.
                            Please try again.
                        </p>
                    )}
                    <div className="px-3 h-10 flex items-center justify-center border-solid border bg-white rounded-md">
                        <input
                            id="image-upload-input"
                            type="file"
                            accept="image/*"
                            onChange={onSelectFile}
                            hidden
                        />
                        <label
                            htmlFor="image-upload-input"
                            className={`w-full flex items-center ${
                                !imgSrc ? 'justify-start' : 'justify-center'
                            } cursor-pointer text-sm`}
                        >
                            <img className="h-5 w-5" src={UploadIcon} />
                            <span className="ml-3">
                                {imageUrl ? 'Change Image' : 'Choose Image'}
                            </span>
                        </label>
                    </div>
                    {imgSrc && (
                        <div>
                            <div className="flex items-center justify-center">
                                <img
                                    ref={imgRef}
                                    alt="image no crop"
                                    src={imgSrc}
                                    onLoad={onImageLoadGif}
                                />
                            </div>
                            <div className={`mt-2 grid grid-cols-2 items-center`}>
                                <button
                                    className="h-10 cursor-pointer text-grey hover:underline text-sm"
                                    onClick={() => {
                                        setImgSrc('');
                                        setImagePreviewUrl(undefined);
                                        setImageBlob(undefined);
                                        setHadErrorSavingImage(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <ButtonMain size="extra-small" onClick={() => onImageSave()}>
                                    Upload
                                </ButtonMain>
                            </div>
                        </div>
                    )}

                    {imgSrc && !hasImageBeenUploaded && false && (
                        <div>
                            <div className="mt-2 ">
                                <div className="flex items-center justify-center">
                                    <ReactCrop
                                        style={{ backgroundColor: 'white' }}
                                        crop={crop}
                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={imgSrc}
                                            onLoad={onImageLoad}
                                        />
                                    </ReactCrop>
                                </div>
                            </div>
                            <div
                                ref={cropSectionContainer}
                                className={`mt-2 grid grid-cols-2 items-center`}
                            >
                                <button
                                    className="h-10 cursor-pointer text-grey hover:underline text-sm"
                                    onClick={() => {
                                        setImgSrc('');
                                        setImagePreviewUrl(undefined);
                                        setImageBlob(undefined);
                                        setHadErrorSavingImage(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <ButtonMain size="extra-small" onClick={() => onImageSave()}>
                                    Upload
                                </ButtonMain>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

interface ImageUploadWithCropSimplifiedProps {
    readonly setFileName: (fileName: string) => void;
    readonly setImageBlob: (blob: Blob) => void;
    readonly imageUrl: string;
    readonly setImageUrl: (imageUrl: string) => void;
    readonly setImagePreviewUrl: (url: string) => void;
    readonly showScale?: boolean;
    readonly aspect?: number;
}

export const ImageUploadWithCropSimplified: React.FC<ImageUploadWithCropSimplifiedProps> = ({
    setFileName,
    setImageBlob,
    imageUrl,
    setImagePreviewUrl,
    aspect
}) => {
    const [imgSrc, setImgSrc] = useState('');
    const imgRef = useRef<HTMLImageElement>(null);
    const [hideCrop, setHideCrop] = useState(true);

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const cropSectionContainer = React.useRef<HTMLDivElement | null>(null);

    const [hadErrorSavingImage, setHadErrorSavingImage] = useState(false);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        setHadErrorSavingImage(false);

        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '');
                setHideCrop(false);

                setFileName(e.target.files[0].name);

                setTimeout(
                    () => cropSectionContainer?.current?.scrollIntoView({ behavior: 'smooth' }),
                    100
                );
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect || 16 / 9));
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current) {
                const { previewUrl, blob } = await createImage(imgRef.current, completedCrop);

                if (previewUrl) {
                    setImagePreviewUrl(previewUrl);
                }

                if (blob) {
                    setImageBlob(blob);
                }
            }
        },
        100,
        [completedCrop]
    );

    return (
        <div className="w-full">
            <div>
                {hadErrorSavingImage && (
                    <p className="my-2 text-sm text-red">
                        The image could not be uploaded at this time for unexpected reasons. Please
                        try again.
                    </p>
                )}
                <div className="px-3 h-10 flex items-center justify-center border-solid border bg-white rounded-md">
                    <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*"
                        onChange={onSelectFile}
                        hidden
                    />
                    <label
                        htmlFor="image-upload-input"
                        className={`w-full flex items-center ${
                            !imgSrc ? 'justify-start' : 'justify-center'
                        } cursor-pointer text-sm`}
                    >
                        <img className="h-5 w-5" src={UploadIcon} />
                        <span className="ml-3">{imageUrl ? 'Change Image' : 'Choose Image'}</span>
                    </label>
                </div>
                {imgSrc && !hideCrop && (
                    <div className="max-h-48">
                        <div className="mt-2">
                            <div className="flex items-center justify-center">
                                <ReactCrop
                                    style={{ backgroundColor: 'white' }}
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={aspect}
                                    maxHeight={300}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            </div>
                        </div>

                        <div
                            ref={cropSectionContainer}
                            className={`mt-2 grid grid-cols-2 items-center`}
                        >
                            <button
                                className="h-10 cursor-pointer text-grey hover:underline text-sm"
                                onClick={() => {
                                    setImagePreviewUrl(imageUrl);
                                    setHideCrop(true);
                                }}
                            >
                                Cancel
                            </button>
                            <ButtonMain size="extra-small" onClick={() => setHideCrop(true)}>
                                Use this crop
                            </ButtonMain>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
