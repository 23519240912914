import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';

import { CtasService, LabelsService, LinksService } from './api';
import ArrowIconWhite from './assets/icons/arrow-white.svg';
import ChevronDown from './assets/icons/chevron-down.svg';
import CloseIcon from './assets/icons/close.svg';
import CopyIconWhite from './assets/icons/copy-white.svg';
import CopyIcon from './assets/icons/copy.svg';
import MenuBulletsIcon from './assets/icons/menu-bullets.svg';
import PlusIcon from './assets/icons/plus-plain.svg';
import QRCodeIconWhite from './assets/icons/qr-code-white.svg';
import QRCodeIcon from './assets/icons/qr-code.svg';
import SniplyIcon from './assets/icons/sniply-logo.svg';
import { AuthContext } from './auth';
import { CampaignsDropdownSelect } from './CampaignsDropdownSelect';
import { ButtonMain } from './components/ButtonMain';
import ButtonSettings from './components/ButtonSettings';
import { DropDownOption } from './components/DropdownSelect';
import { EndlessScrollContainer } from './components/EndlessScrollContainer';
import { ImageWithFallback } from './components/ImageWithFallback';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchInput } from './components/SearchInput';
import { SimpleDropdown } from './components/SimpleDropdown';
import { VerticalSpacer } from './components/VerticalSpacer';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { createCtasListForDropdown } from './data-transformations/create-ctas-list';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { createSnipsList } from './data-transformations/create-snips-list';
import { useElementWidth } from './hooks/use-element-width';
import { NotificationContext } from './NotificationContextProvider';
import { navigateTo, RouteLink } from './Routing';
import { filterForActiveOrArchivedSnips } from './utils/filter-for-active-or-archived-snips';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { EmptyTableView } from './components/EmptyTableView';

export type ActiveOrArchivedView = 'Active' | 'Archived';

const NUMBER_OF_SNIPS_PER_LOAD = 15;

export type SortingDirection =
    | 'clicks-ascending'
    | 'clicks-descending'
    | 'conversions-ascending'
    | 'conversions-descending';

interface SnipsTableRowCtaSelect {
    readonly selectedCtaId: string;
    readonly ctaOptions: DropDownOption[];
    readonly onSelectCtaId: (selectedCtaId: string) => void;
    readonly onResetCtaId: () => void;
    readonly isHoveringRow: boolean;
    readonly isRightAligned?: boolean;
}

export const SnipsTableRowCtaSelect: React.FC<SnipsTableRowCtaSelect> = ({
    selectedCtaId,
    ctaOptions,
    onSelectCtaId,
    onResetCtaId,
    isHoveringRow,
    isRightAligned = false
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`h-8 relative flex items-center py-1 px-2 text-sm transition duration-200 border min-w-0 ${
                isHoveringRow && selectedCtaId ? 'border-grey-lighter' : 'border-white'
            } rounded-full`}
            ref={dropdownContentRef}
        >
            {selectedCtaId ? (
                <div className="flex items-center w-full min-w-0">
                    <button
                        className="flex items-center w-full min-w-0"
                        onClick={(event) => {
                            event.preventDefault();
                            setOpen(open ? false : true);
                        }}
                        disabled={!ctaOptions || ctaOptions.length === 0}
                    >
                        <span className="ml-2 truncate text-left">
                            {
                                ctaOptions.find((option) => option.value === selectedCtaId)
                                    ?.displayLabel
                            }
                        </span>
                    </button>
                    {isHoveringRow && (
                        <button onClick={() => onResetCtaId()} className={`ml-2 p-1}`}>
                            <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                        </button>
                    )}
                </div>
            ) : (
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                    disabled={!ctaOptions || ctaOptions.length === 0}
                    className={`text-left w-full relative ${
                        ctaOptions.length > 0 ? 'text-grey-medium' : 'text-grey-lighter'
                    }`}
                >
                    <img src={PlusIcon} alt="plus icon" className="h-4 w-4" />
                </button>
            )}

            {open && (
                <div
                    className={`absolute top-9 ${
                        isRightAligned ? 'right-0' : 'left-0'
                    } text-sm rounded-md  shadow-md px-3 pb-2 w-72 bg-white border border-grey-lighter z-50`}
                >
                    <div className="mt-2">
                        <SearchInput
                            placeholderText="search..."
                            searchTermState={searchTerm}
                            onClearSearch={() => setSearchTerm('')}
                            setSearchTermState={setSearchTerm}
                            width="full"
                            isRounded
                        />
                    </div>
                    <div className="mt-4 max-h-40 w-full overflow-y-scroll overflow-x-hidden">
                        <ul>
                            {ctaOptions
                                .filter((cta) =>
                                    cta.displayLabel
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                )
                                .filter((cta) => cta.value !== selectedCtaId)
                                .map((cta) => (
                                    <li
                                        className="text-left mt-2 rounded-md text-grey hover:text-grey-light w-full min-w-0"
                                        key={cta.value}
                                    >
                                        <button
                                            className="w-full"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectCtaId(cta.value);
                                                searchTerm && setSearchTerm('');
                                                setOpen(false);
                                            }}
                                        >
                                            <div className="text-left ml-3 min-w-0">
                                                <p className="truncate">{cta.displayLabel}</p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

interface SnipsTableRowCampaignsSelect {
    readonly selectedCampaignId: string;
    readonly campaignOptions: DropDownOption[];
    readonly onSelectCampaignId: (selectedCampaignId: string) => void;
    readonly onCreateCampaign: (
        event: React.MouseEvent<HTMLButtonElement>,
        campaignName: string
    ) => void;
    readonly onResetCampaignId: () => void;
    readonly isHoveringRow: boolean;
}

const SnipsTableRowCampaignsSelect: React.FC<SnipsTableRowCampaignsSelect> = ({
    selectedCampaignId,
    campaignOptions,
    onSelectCampaignId,
    onCreateCampaign,
    onResetCampaignId,
    isHoveringRow
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const campaignOptionsWithSearchApplied = campaignOptions.filter((campaign) =>
        campaign.displayLabel.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div
            className={`h-8 relative flex items-center py-1 px-2 text-sm transition duration-200 border min-w-0 ${
                isHoveringRow && selectedCampaignId ? 'border-grey-lighter' : 'border-white'
            } rounded-full`}
            ref={dropdownContentRef}
        >
            {selectedCampaignId ? (
                <div className="flex items-center w-full min-w-0">
                    <button
                        className="flex items-center w-full min-w-0"
                        onClick={(event) => {
                            event.preventDefault();
                            setOpen(open ? false : true);
                        }}
                    >
                        <span className="ml-2 truncate break-words text-left">
                            {
                                campaignOptions.find(
                                    (option) => option.value === selectedCampaignId
                                )?.displayLabel
                            }
                        </span>
                    </button>
                    {isHoveringRow && (
                        <button onClick={() => onResetCampaignId()} className={`ml-2 p-1}`}>
                            <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                        </button>
                    )}
                </div>
            ) : (
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                    className={`text-left w-full relative ${
                        campaignOptions.length > 0 ? 'text-grey-medium' : 'text-grey-lighter'
                    }`}
                >
                    <img src={PlusIcon} alt="plus icon" className="h-4 w-4" />
                </button>
            )}

            {open && (
                <div className="absolute top-9 left-0 text-sm rounded-md  shadow-md px-3 pb-2 w-72 bg-white border border-grey-lighter z-50">
                    <div className="mt-2">
                        <SearchInput
                            placeholderText="Search or create new"
                            searchTermState={searchTerm}
                            onClearSearch={() => setSearchTerm('')}
                            setSearchTermState={setSearchTerm}
                            isCollapsible={false}
                            width="full"
                            isRounded
                            autofocus
                        />
                    </div>
                    <div className="mt-4 max-h-40 w-full overflow-y-scroll overflow-x-hidden">
                        {searchTerm && campaignOptionsWithSearchApplied.length < 1 && (
                            <ButtonMain
                                width="full"
                                size="extra-small"
                                style="neutral"
                                onClick={(event) => {
                                    onCreateCampaign(event, searchTerm);
                                    searchTerm && setSearchTerm('');
                                    setOpen(false);
                                }}
                            >
                                {`Create '${searchTerm}'`}
                            </ButtonMain>
                        )}
                        <ul>
                            {campaignOptionsWithSearchApplied
                                .filter((campaign) => campaign.value !== selectedCampaignId)
                                .map((campaign) => (
                                    <li
                                        className="text-left mt-2 rounded-md text-grey hover:text-grey-light w-full min-w-0"
                                        key={campaign.value}
                                    >
                                        <button
                                            className="w-full"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectCampaignId(campaign.value);
                                                searchTerm && setSearchTerm('');
                                                setOpen(false);
                                            }}
                                        >
                                            <div className="text-left ml-3 min-w-0">
                                                <p className="truncate">{campaign.displayLabel}</p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

interface SnipsTableHeaderProps {
    readonly handleSortClick: (sortingStateValue: SnipsSortingState) => void;
    readonly sortClicksDirection: SortingDirection;
    readonly setSortClicksDirection: (sortingDirection: SortingDirection) => void;
    readonly sortConversionsDirection: SortingDirection;
    readonly setSortConversionsDirection: (sortingDirection: SortingDirection) => void;
    readonly numberOfDisplayedSnips: number;
    readonly numberOfTotalSnips: number;
}

const SnipsTableHeader: React.FC<SnipsTableHeaderProps> = ({
    handleSortClick,
    sortClicksDirection,
    setSortClicksDirection,
    numberOfDisplayedSnips,
    numberOfTotalSnips
}) => {
    return (
        <div className="flex px-3 items-center justify-between">
            <div className={`w-full h-full grid gap-2 grid-cols-3-2-2-1-1-1 mb-1 pb-1 text-xs`}>
                <div>
                    <span>Snips</span>
                    {numberOfTotalSnips && (
                        <span className="ml-1">{`(${numberOfDisplayedSnips} out of ${numberOfTotalSnips})`}</span>
                    )}
                </div>
                <span>Campaign</span>
                <span>Call-To-Action</span>
                <button
                    className="flex items-center"
                    onClick={(event) => {
                        event.preventDefault();

                        setSortClicksDirection(
                            sortClicksDirection === 'clicks-descending'
                                ? 'clicks-ascending'
                                : 'clicks-descending'
                        );

                        handleSortClick(
                            sortClicksDirection === 'clicks-descending'
                                ? 'cache_view_count'
                                : '-cache_view_count'
                        );
                    }}
                >
                    <span>Clicks</span>
                    <img
                        src={ChevronDown}
                        alt="chevron"
                        className={`ml-1 w-4 h-4 ${
                            sortClicksDirection === 'clicks-ascending' ? 'rotate-180' : 'rotate-0'
                        } ${!sortClicksDirection ? 'opacity-20' : ''}`}
                    />
                </button>
                <button
                    className="flex items-center"
                    onClick={(event) => {
                        event.preventDefault();

                        setSortClicksDirection(
                            sortClicksDirection === 'conversions-descending'
                                ? 'conversions-ascending'
                                : 'conversions-descending'
                        );

                        handleSortClick(
                            sortClicksDirection === 'conversions-descending'
                                ? 'cache_click_count'
                                : '-cache_click_count'
                        );
                    }}
                >
                    <span>Conv.</span>
                    <img
                        src={ChevronDown}
                        alt="chevron"
                        className={`ml-1 w-4 h-4 ${
                            sortClicksDirection === 'conversions-ascending'
                                ? 'rotate-180'
                                : 'rotate-0'
                        } ${!sortClicksDirection ? 'opacity-20' : ''}`}
                    />
                </button>
                <span>Conv.rate</span>
                {/* Empty span for action button column */}
                <span></span>
            </div>
            {/* Empty element for space taken up by onHover on the table row */}
            <div className="w-24" />
        </div>
    );
};

const SnipsTableHeaderSmall: React.FC<SnipsTableHeaderProps> = ({
    handleSortClick,
    sortClicksDirection,
    setSortClicksDirection,
    numberOfDisplayedSnips,
    numberOfTotalSnips
}) => {
    return (
        <div className={`mb-1 pb-1 px-3 flex items-center justify-between text-xs`}>
            <div>
                <span>Snips</span>
                {numberOfTotalSnips && (
                    <span className="ml-1">{`(${numberOfDisplayedSnips} out of ${numberOfTotalSnips})`}</span>
                )}
            </div>
            <div className="flex">
                <button
                    className="flex items-center"
                    onClick={(event) => {
                        event.preventDefault();

                        setSortClicksDirection(
                            sortClicksDirection === 'clicks-descending'
                                ? 'clicks-ascending'
                                : 'clicks-descending'
                        );

                        handleSortClick(
                            sortClicksDirection === 'clicks-descending'
                                ? 'cache_view_count'
                                : '-cache_view_count'
                        );
                    }}
                >
                    <span>Clicks</span>
                    <img
                        src={ChevronDown}
                        alt="chevron"
                        className={`ml-1 w-4 h-4 ${
                            sortClicksDirection === 'clicks-ascending' ? 'rotate-180' : 'rotate-0'
                        } ${!sortClicksDirection ? 'opacity-20' : ''}`}
                    />
                </button>
                <button
                    className=" ml-2 flex items-center"
                    onClick={(event) => {
                        event.preventDefault();

                        setSortClicksDirection(
                            sortClicksDirection === 'conversions-descending'
                                ? 'conversions-ascending'
                                : 'conversions-descending'
                        );

                        handleSortClick(
                            sortClicksDirection === 'conversions-descending'
                                ? 'cache_click_count'
                                : '-cache_click_count'
                        );
                    }}
                >
                    <span>Conv.</span>
                    <img
                        src={ChevronDown}
                        alt="chevron"
                        className={`ml-1 w-4 h-4 ${
                            sortClicksDirection === 'conversions-ascending'
                                ? 'rotate-180'
                                : 'rotate-0'
                        } ${!sortClicksDirection ? 'opacity-20' : ''}`}
                    />
                </button>
            </div>
        </div>
    );
};

interface SnipTableRowProps {
    readonly listIndex: number;
    readonly snipId: string;
    readonly snipSlug: string;
    readonly snipName: string;
    readonly snipUrl: string;
    readonly faviconUrl: string;
    readonly contentUrl: string;
    readonly campaign: string;
    readonly campaignsOptions: DropDownOption[];
    readonly setCampaignsOptions: (options: DropDownOption[]) => void;
    readonly cta: string;
    readonly ctasOptions: DropDownOption[];
    readonly numberOfClicks: number;
    readonly numberOfConversions: number;
    readonly conversionRate: number;
}

const SnipTableRow: React.FC<SnipTableRowProps> = ({
    listIndex,
    snipId,
    snipSlug,
    snipName,
    snipUrl,
    faviconUrl,
    contentUrl,
    campaign,
    campaignsOptions,
    setCampaignsOptions,
    cta,
    ctasOptions,
    numberOfClicks,
    numberOfConversions,
    conversionRate
}) => {
    const [selectedCampaignId, setSelectedCampaignId] = React.useState(campaign);
    const [selectedCtaId, setSelectedCtaId] = React.useState(cta);
    const [isHoveringRow, setIsHoveringRow] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleSelectCampaign = React.useCallback(
        (campaignId: string) => {
            LinksService.linksPartialUpdate({
                id: snipId,
                requestBody: { label: campaignId }
            }).then(() => setSelectedCampaignId(campaignId));
        },
        [snipId]
    );

    const handleCreateCampaignAndUpdateSnip = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
            event.preventDefault();

            LabelsService.labelsCreate({
                requestBody: { brand: currentWorkspace.id, name: campaignName }
            }).then((result) => {
                setCampaignsOptions([
                    ...campaignsOptions,
                    { displayLabel: result.name, value: result.id }
                ]);

                setSelectedCampaignId(result.id);

                LinksService.linksPartialUpdate({
                    id: snipId,
                    requestBody: { label: result.id }
                });
            });
        },
        [snipId, currentWorkspace, campaignsOptions, setCampaignsOptions]
    );

    const handleSelectCta = React.useCallback(
        (ctaId: string) => {
            const requestBody = ctaId ? { cta_ids: [ctaId] } : { cta_ids: [] };

            LinksService.linksPartialUpdate({
                id: snipId,
                requestBody
            }).then(() => setSelectedCtaId(ctaId));
        },
        [snipId]
    );

    return (
        <>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <div
                className="flex px-3 py-3 items-center justify-between hover:bg-grey-lightest border-b border-white hover:border-grey-lighter"
                onMouseEnter={() => setIsHoveringRow(true)}
                onMouseLeave={() => setIsHoveringRow(false)}
            >
                <div className="w-full h-full grid gap-2 grid-cols-3-2-2-1-1-1 items-center">
                    <div className="flex items-center min-w-0">
                        <div className="shrink-0">
                            <ImageWithFallback
                                src={faviconUrl}
                                alt="favicon"
                                fallbackSrc={SniplyIcon}
                                className="h-4 w-4"
                            />
                        </div>
                        <div className="ml-2 min-w-0">
                            <div className="flex items-center min-w-0">
                                <p
                                    className="text-left truncate cursor-pointer"
                                    onClick={() => navigateTo(`/snips/${snipId}`)}
                                >
                                    {snipName}
                                </p>
                                {isHoveringRow && (
                                    <>
                                        <button
                                            className="ml-1"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                navigator.clipboard.writeText(snipUrl);

                                                handleOpenNotification({
                                                    messageText: 'Snip Url successfully copied.',
                                                    type: 'success',
                                                    iconSrc: CopyIconWhite,
                                                    showTimeInSeconds: 3
                                                });
                                            }}
                                        >
                                            <img
                                                src={CopyIcon}
                                                alt="copy icon"
                                                className="h-4 w-4"
                                            />
                                        </button>
                                        <button
                                            className="ml-2"
                                            onClick={(event) => {
                                                event.preventDefault();

                                                const canvas = document.getElementById('qr-code');
                                                const pngUrl = (canvas as HTMLCanvasElement)
                                                    .toDataURL('image/png')
                                                    .replace('image/png', 'image/octet-stream');

                                                const downloadLink = document.createElement('a');
                                                downloadLink.href = pngUrl;
                                                downloadLink.download = `sniply-qr-code-${snipSlug}.png`;
                                                document.body.appendChild(downloadLink);
                                                downloadLink.click();
                                                document.body.removeChild(downloadLink);

                                                handleOpenNotification({
                                                    messageText: 'QR code successfully downloaded.',
                                                    type: 'success',
                                                    iconSrc: QRCodeIconWhite,
                                                    showTimeInSeconds: 3
                                                });
                                            }}
                                        >
                                            <QRCodeCanvas
                                                id="qr-code"
                                                className="hidden"
                                                value={snipUrl}
                                                includeMargin
                                                fgColor="#2681DB"
                                                size={1000}
                                            />
                                            <img className="h-4 w-4" src={QRCodeIcon} />
                                        </button>
                                    </>
                                )}
                            </div>
                            <p
                                className="text-xs text-left truncate text-grey-medium cursor-pointer"
                                onClick={() => navigateTo(`/snips/${snipId}`)}
                            >
                                {contentUrl}
                            </p>
                        </div>
                    </div>
                    <SnipsTableRowCampaignsSelect
                        campaignOptions={campaignsOptions}
                        selectedCampaignId={selectedCampaignId}
                        onSelectCampaignId={handleSelectCampaign}
                        onCreateCampaign={handleCreateCampaignAndUpdateSnip}
                        onResetCampaignId={() => handleSelectCampaign(null)}
                        isHoveringRow={isHoveringRow}
                    />
                    <SnipsTableRowCtaSelect
                        ctaOptions={ctasOptions}
                        selectedCtaId={selectedCtaId}
                        onSelectCtaId={handleSelectCta}
                        onResetCtaId={() => handleSelectCta(null)}
                        isHoveringRow={isHoveringRow}
                    />
                    <span className="pl-4 text-sm">{numberOfClicks}</span>
                    <span className="pl-4 text-sm">{numberOfConversions}</span>
                    <span className="pl-4 text-sm">{conversionRate}%</span>
                </div>
                <div className="flex w-24">
                    {isHoveringRow && (
                        <>
                            <ButtonSettings onClick={() => navigateTo(`/snips/${snipId}`)} />
                            <ButtonMain size="extra-small" className="ml-2">
                                <a href={snipUrl} target="_blank" rel="noreferrer">
                                    <img
                                        className="h-4 w-4 rotate-180"
                                        src={ArrowIconWhite}
                                        alt="arrow icon"
                                    />
                                </a>
                            </ButtonMain>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const SnipTableRowSmall: React.FC<Partial<SnipTableRowProps>> = ({
    listIndex,
    snipId,
    snipName,
    faviconUrl,
    contentUrl,
    numberOfClicks,
    numberOfConversions,
    conversionRate
}) => {
    return (
        <div>
            {listIndex > 0 && <hr className="border-t border-grey-lighter" />}
            <button className="w-full px-2 py-2 " onClick={() => navigateTo(`/snips/${snipId}`)}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center overflow-hidden ">
                        <ImageWithFallback
                            src={faviconUrl}
                            alt="favicon"
                            fallbackSrc={SniplyIcon}
                            className="h-4 w-4"
                        />{' '}
                        <div className="ml-2">
                            <p className="overflow-hidden text-left">{snipName}</p>
                            <p className="text-xs text-left truncate text-grey-medium">
                                {contentUrl}
                            </p>
                        </div>
                    </div>
                    <img
                        src={MenuBulletsIcon}
                        alt="menu icon"
                        className="ml-10 mr-2 w-5 h-5 rotate-90"
                    />
                </div>
                <div className="mt-4 grid grid-cols-3 text-xs">
                    <span>{`Clicks: ${numberOfClicks}`}</span>
                    <span>{`Conv.: ${numberOfConversions}`}</span>
                    <span>{`Conv.rate: ${conversionRate}%`}</span>
                </div>
            </button>
        </div>
    );
};

type SnipsSortingState =
    | '-created_date'
    | 'created_date'
    | '-cache_view_count'
    | 'cache_view_count'
    | '-cache_click_count'
    | 'cache_click_count';

export const SnipsOverviewTable: React.FC = () => {
    const [snipsData, setSnipsData] = React.useState<SnipsListItem[]>();
    const [searchResultsData, setSearchResultsData] = React.useState<SnipsListItem[]>();
    const [totalSnipsCount, setTotalSnipsCount] = React.useState<number>();

    const [searchTerm, setSearchTerm] = React.useState('');
    const [viewingActiveOrArchived, setviewingActiveOrArchived] =
        React.useState<ActiveOrArchivedView>('Active');

    const [sortingState, setSortingState] = React.useState<SnipsSortingState>('-created_date');
    const [sortClicksDirection, setSortClicksDirection] = React.useState<SortingDirection>();
    const [sortConversionsDirection, setSortConversionsDirection] =
        React.useState<SortingDirection>();

    const [selectedCampaignId, setSelectedCampaignId] = React.useState<string>('');

    const [campaignsData, setCampaignsData] = React.useState<DropDownOption[]>([]);
    const [ctasData, setCtasData] = React.useState([]);

    const [isLoadingData, setIsLoadingData] = React.useState(true);

    const [hasLoadedDataWithZeroResults, setHasLoadedDataWithZeroResults] = React.useState(false);

    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    const [isLoadingMoreData, setIsLoadingMoreData] = React.useState(false);
    const [numberOfPagesLoaded, setNumberOfPagesLoaded] = React.useState(0);
    const [hasMorePages, setHasMorePages] = React.useState(true);

    const dashboardSnipContainersRef = React.useRef(undefined);
    const dashboardSnipsContainerWidth = useElementWidth(dashboardSnipContainersRef);
    const isSmallScreen = dashboardSnipsContainerWidth < 700;

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const handleLoadMoreSnips = React.useCallback(() => {
        if (!isLoadingMoreData && hasMorePages) {
            setIsLoadingMoreData(true);

            LinksService.linksList({
                archived: viewingActiveOrArchived === 'Archived',
                brand: currentWorkspace?.id,
                label: selectedCampaignId,
                ordering: sortingState,
                search: searchTerm,
                page: numberOfPagesLoaded + 1,
                pageSize: NUMBER_OF_SNIPS_PER_LOAD
            })
                .then((result) => {
                    console.log('loading more Snips via LinksService.linksList()', result);

                    setNumberOfPagesLoaded(numberOfPagesLoaded + 1);

                    searchTerm
                        ? setSearchResultsData([...searchResultsData, ...createSnipsList(result)])
                        : setSnipsData([...snipsData, ...createSnipsList(result)]);

                    setIsLoadingMoreData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LinksService.linksList(): ', error);

                    if (error.body?.detail?.startsWith('Invalid page')) {
                        setHasMorePages(false);
                    }

                    setIsLoadingMoreData(false);
                });
        }
    }, [
        snipsData,
        setSnipsData,
        searchResultsData,
        setSearchResultsData,
        sortingState,
        viewingActiveOrArchived,
        isLoadingMoreData,
        numberOfPagesLoaded,
        setNumberOfPagesLoaded,
        currentWorkspace,
        selectedCampaignId,
        hasMorePages,
        searchTerm
    ]);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setIsLoadingData(true);

            LinksService.linksList({
                archived: viewingActiveOrArchived === 'Archived',
                brand: currentWorkspace?.id,
                label: selectedCampaignId,
                ordering: sortingState,
                search: searchTerm,
                page: 1,
                pageSize: NUMBER_OF_SNIPS_PER_LOAD
            })
                .then((result) => {
                    console.log('LinksService.linksList()', result);

                    setNumberOfPagesLoaded(1);
                    setSnipsData(createSnipsList(result));
                    setHadErrorLoadingData(false);
                    setTotalSnipsCount(result.count);

                    setIsLoadingData(false);
                    result.results.length === 0 && setHasLoadedDataWithZeroResults(true);
                })
                .catch((error) => {
                    console.log('Error fetching from LinksService.linksList(): ', error);
                    setHadErrorLoadingData(true);
                    setIsLoadingData(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isOpenAPITokenSet,
        currentWorkspace,
        sortingState,
        selectedCampaignId,
        viewingActiveOrArchived
    ]);

    const searchRequestIndex = React.useRef(0);

    React.useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            const currentSearchRequestIndex = searchRequestIndex.current + 1;

            searchRequestIndex.current = currentSearchRequestIndex;

            setIsLoadingData(true);

            setTimeout(() => {
                if (currentSearchRequestIndex === searchRequestIndex.current) {
                    LinksService.linksList({
                        brand: currentWorkspace?.id,
                        search: searchTerm,
                        page: 1,
                        pageSize: NUMBER_OF_SNIPS_PER_LOAD
                    })
                        .then((result) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'Loading search-based Snips via LinksService.linksList()',
                                    result
                                );
                                setSearchResultsData(createSnipsList(result));
                                setNumberOfPagesLoaded(1);
                                setIsLoadingData(false);

                                result.results.length === 0 &&
                                    setHasLoadedDataWithZeroResults(true);
                            }
                        })
                        .catch((error) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'Error fetching from LinksService.linksList(): ',
                                    error
                                );
                                setIsLoadingData(false);
                            }
                        });
                }
            }, 1000);
        } else {
            setSearchResultsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            CtasService.ctasList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);
                    setCtasData(createCtasListForDropdown(result));
                })
                .catch((error) => {
                    console.error('Error fetching from FeedsService.feedsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    const hasZeroActiveSnips =
        hasLoadedDataWithZeroResults &&
        !searchTerm &&
        viewingActiveOrArchived === 'Active' &&
        !selectedCampaignId;

    const snipsDataToDisplay =
        searchResultsData && searchTerm.length > 2
            ? filterForActiveOrArchivedSnips(searchResultsData, viewingActiveOrArchived)
            : filterForActiveOrArchivedSnips(snipsData, viewingActiveOrArchived);

    return (
        <div ref={dashboardSnipContainersRef}>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-2 items-center text-sm justify-between">
                <div className="flex items-center">
                    <span className="hidden sm:block">Filter by:</span>
                    <span className="block sm:hidden">Filter:</span>
                    <div className="ml-2">
                        <CampaignsDropdownSelect
                            selectedCampaignId={selectedCampaignId}
                            setSelectedCampaignId={setSelectedCampaignId}
                        />
                    </div>
                    {selectedCampaignId && (
                        <button
                            className="ml-2 text-sm text-grey-medium"
                            onClick={() => setSelectedCampaignId('')}
                        >
                            Reset
                        </button>
                    )}
                </div>
                <div className="flex items-center justify-between md:justify-end">
                    <SearchInput
                        searchTermState={searchTerm}
                        setSearchTermState={setSearchTerm}
                        onClearSearch={() => setSearchTerm('')}
                        placeholderText="Search"
                        isCollapsible={false}
                        isRounded
                    />
                    <div className="ml-2">
                        <SimpleDropdown
                            selectedOption={viewingActiveOrArchived}
                            onSelectOption={(option) =>
                                setviewingActiveOrArchived(option as ActiveOrArchivedView)
                            }
                            options={['Active', 'Archived']}
                        />
                    </div>
                </div>
            </div>
            <VerticalSpacer heightValue={2} />
            <hr className="border-t border-grey-lighter" />
            <VerticalSpacer heightValue={4} />
            {isSmallScreen ? (
                <SnipsTableHeaderSmall
                    handleSortClick={(sortingStateValue: SnipsSortingState) =>
                        setSortingState(sortingStateValue)
                    }
                    sortClicksDirection={sortClicksDirection}
                    setSortClicksDirection={setSortClicksDirection}
                    sortConversionsDirection={sortConversionsDirection}
                    setSortConversionsDirection={setSortConversionsDirection}
                    numberOfDisplayedSnips={snipsDataToDisplay?.length ?? 0}
                    numberOfTotalSnips={totalSnipsCount}
                />
            ) : (
                <SnipsTableHeader
                    handleSortClick={(sortingStateValue: SnipsSortingState) =>
                        setSortingState(sortingStateValue)
                    }
                    sortClicksDirection={sortClicksDirection}
                    setSortClicksDirection={setSortClicksDirection}
                    sortConversionsDirection={sortConversionsDirection}
                    setSortConversionsDirection={setSortConversionsDirection}
                    numberOfDisplayedSnips={snipsDataToDisplay?.length ?? 0}
                    numberOfTotalSnips={totalSnipsCount}
                />
            )}

            <div className="bg-white rounded-sm">
                <div className="h-screen-50 sm:h-screen-70">
                    {isLoadingData ? (
                        <div className="h-full w-full flex items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : hadErrorLoadingData ? (
                        <div className="text-red">
                            Apologies your Snips could not be retrieved. An unexpected network error
                            has occurred. Please try again later.
                        </div>
                    ) : snipsData?.length ? (
                        <div className="w-full">
                            <EndlessScrollContainer
                                showLoadMoreAtAll={
                                    snipsDataToDisplay.length >= NUMBER_OF_SNIPS_PER_LOAD
                                }
                                onReachedScrollBottom={handleLoadMoreSnips}
                                onLoadMoreClick={handleLoadMoreSnips}
                                isLoadingMoreData={isLoadingMoreData}
                                hasMoreResults={hasMorePages}
                                height=""
                            >
                                {snipsDataToDisplay?.map((snip, listIndex) =>
                                    isSmallScreen ? (
                                        <SnipTableRowSmall
                                            key={snip.snipName}
                                            listIndex={listIndex}
                                            snipId={snip.id}
                                            snipName={snip.snipName}
                                            snipUrl={snip.snipUrl}
                                            faviconUrl={snip.faviconUrl}
                                            contentUrl={snip.originalContentUrl}
                                            campaign={snip.campaignId}
                                            numberOfClicks={snip.numberOfClicks}
                                            numberOfConversions={snip.numberOfConversions}
                                            conversionRate={snip.conversionRate}
                                        />
                                    ) : (
                                        <SnipTableRow
                                            key={snip.snipName}
                                            listIndex={listIndex}
                                            snipId={snip.id}
                                            snipSlug={snip.slug}
                                            snipName={snip.snipName}
                                            snipUrl={snip.snipUrl}
                                            faviconUrl={snip.faviconUrl}
                                            contentUrl={snip.originalContentUrl}
                                            campaign={snip.campaignId}
                                            campaignsOptions={campaignsData}
                                            setCampaignsOptions={setCampaignsData}
                                            cta={snip.ctaIds[0]}
                                            ctasOptions={ctasData}
                                            numberOfClicks={snip.numberOfClicks}
                                            numberOfConversions={snip.numberOfConversions}
                                            conversionRate={snip.conversionRate}
                                        />
                                    )
                                )}
                            </EndlessScrollContainer>
                        </div>
                    ) : (
                        hasZeroActiveSnips && (
                            <div className="flex w-full h-full items-center justify-center">
                                <EmptyTableView
                                    headlineText="No Snips"
                                    sublineText="Get started with Sniply by creating your first Snip."
                                    mainActionButton={
                                        <RouteLink href="/snip-create/content-select">
                                            <ButtonMain>+ Create New Snip</ButtonMain>
                                        </RouteLink>
                                    }
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
