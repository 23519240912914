import React from 'react';
import { ContactusService } from './api';
import { ButtonMain } from './components/ButtonMain';
import { FormTextAreaInput } from './components/FormTextAreaInput';
import { FormTextInput } from './components/FormTextInput';
import { LoadingSpinner } from './components/LoadingSpinner';
import { VerticalSpacer } from './components/VerticalSpacer';
import { navigateTo } from './Routing';

interface EnterprisePlanContactFormProps {
    readonly onClose: () => void;
}

export const EnterprisePlanContactForm: React.FC<EnterprisePlanContactFormProps> = ({
    onClose
}) => {
    const [name, setName] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [request, setRequest] = React.useState('');

    const [isSendingMessage, setIsSendingMessage] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    React.useEffect(() => {
        setShowError(false);
    }, [name, companyName, email, request]);

    return showSuccess ? (
        <div>
            <p className="font-semibold">Thank you for your message!</p>
            <p className="mt-2 text-sm text-grey-medium">
                Our team will get in touch with as soon as possible.
            </p>
            <div className="flex mt-4">
                <ButtonMain style="secondary" onClick={onClose}>
                    Back To Plans
                </ButtonMain>
                <ButtonMain
                    className="ml-2"
                    onClick={(event) => {
                        event.preventDefault();
                        navigateTo('/dashboard');
                    }}
                >
                    Back to Dashboard
                </ButtonMain>
            </div>
        </div>
    ) : (
        <form
            onSubmit={(event) => {
                event.preventDefault();

                setIsSendingMessage(true);
                ContactusService.contactusCreate({
                    requestBody: { name, company_name: companyName, email, message: request }
                })
                    .then(() => {
                        setIsSendingMessage(false);
                        setShowSuccess(true);
                    })
                    .catch((error) => {
                        console.error('Error submitting message.', error);
                        setShowError(true);
                        setIsSendingMessage(false);
                    });
            }}
        >
            <h2 className="mt-6 font-semibold text-lg">
                Great! You are interested in a custom Enterprise plan
            </h2>
            <p className="mt-1 text-grey-light text-sm">
                Please provide us with some information about you, your company and your specific
                needs. We will get back to you as soon as we can.
            </p>
            <div className="mt-8 flex flex-col">
                <FormTextInput
                    autoFocus
                    id="name-input"
                    labelText="Name"
                    placeholder="Your Name"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                    disabled={isSendingMessage}
                />
                <VerticalSpacer heightValue={4} />
                <FormTextInput
                    id="company-name-input"
                    labelText="Company"
                    placeholder="Your Company"
                    value={companyName}
                    onChange={(event) => {
                        setCompanyName(event.target.value);
                    }}
                    disabled={isSendingMessage}
                />
                <VerticalSpacer heightValue={4} />
                <FormTextInput
                    type="email"
                    id="email-input"
                    labelText="Email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                />
                <VerticalSpacer heightValue={4} />
                <FormTextAreaInput
                    id="request-input"
                    labelText="Request"
                    rows={6}
                    placeholder="Describe your company's needs briefly..."
                    value={request}
                    onChange={(event) => {
                        setRequest(event.target.value);
                    }}
                    disabled={isSendingMessage}
                />
                <div className="mt-6 h-10">
                    {isSendingMessage ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : showError ? (
                        <p className="text-sm text-red">
                            An unexpected error occured when sending your request. Please try again
                            later.
                        </p>
                    ) : (
                        <div className="flex">
                            <ButtonMain className="w-1/3" type="submit">
                                Save
                            </ButtonMain>
                            <ButtonMain className="ml-2" style="secondary" onClick={onClose}>
                                Cancel
                            </ButtonMain>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};
