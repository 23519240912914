import React from 'react';

interface ProgressBarProps {
    readonly backgroundColor: string;
    readonly progressInPercent: string;
    readonly heightInPx: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    backgroundColor,
    progressInPercent,
    heightInPx
}) => {
    const Parentdiv = {
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
        height: `${heightInPx}px`,
        width: '100%'
    };

    const Childdiv = {
        height: '100%',
        width: progressInPercent,
        backgroundColor: backgroundColor,
        borderRadius: 40
    };

    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
    };

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
                <span style={progresstext}></span>
            </div>
        </div>
    );
};
