import React from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import { UserService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';

export const UserSettingsChangePassword = () => {
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

    const [isUpdatingPassword, setIsUpdatingPassword] = React.useState(false);
    const [backendError, setBackendError] = React.useState(false);

    const [errorUpdatingPassword, setErrorUpdatingPassword] = React.useState<
        'incorrect-old' | 'previously-used' | 'new-too-short'
    >();

    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    const handleChangePassword = React.useCallback(
        (event) => {
            event.preventDefault();

            if (newPassword.length < 8 || confirmNewPassword.length < 8) {
                setErrorUpdatingPassword('new-too-short');
                return;
            }

            setIsUpdatingPassword(true);

            UserService.userChangePasswordUpdate({
                requestBody: {
                    old_password: currentPassword,
                    new_password: newPassword,
                    new_password2: confirmNewPassword
                }
            })
                .then((result) => {
                    console.log('UserService.userChangePasswordUpdate()', result);
                    setIsUpdatingPassword(false);

                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);

                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                })
                .catch((error) => {
                    console.log('Error updating the user password.', error);
                    if (error.non_field_errors.includes('Old password is incorrect')) {
                        setErrorUpdatingPassword('incorrect-old');
                    } else {
                        setBackendError(true);
                    }

                    setIsUpdatingPassword(false);
                });
        },
        [currentPassword, newPassword, confirmNewPassword]
    );

    React.useEffect(() => {
        backendError && setBackendError(false);
        errorUpdatingPassword && setErrorUpdatingPassword(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPassword, newPassword, confirmNewPassword]);

    return (
        <ContentElementContainer>
            <SimpleContentBox headerText="Change Password">
                <form className="mt-1 grid gap-y-4" onSubmit={handleChangePassword}>
                    <div className="flex flex-col xs:w-1/2 xs:pr-1">
                        <FormTextInput
                            id="current-password"
                            type="password"
                            labelText="Current password"
                            placeholder="current password"
                            value={currentPassword}
                            onChange={(event) => {
                                setCurrentPassword(event.target.value);
                            }}
                        />
                    </div>
                    <div className="grid grid-cols-1 xs:grid-cols-2 gap-2">
                        <FormTextInput
                            id="new-password"
                            type="password"
                            labelText="New password"
                            placeholder="new password"
                            value={newPassword}
                            onChange={(event) => {
                                setNewPassword(event.target.value);
                            }}
                        />
                        <FormTextInput
                            id="confirm-password"
                            type="password"
                            labelText="Confirm new password"
                            placeholder="confirm password"
                            value={confirmNewPassword}
                            onChange={(event) => {
                                setConfirmNewPassword(event.target.value);
                            }}
                        />
                    </div>
                    {backendError && (
                        <div className="text-red">
                            Apologies something went wrong. An unexpected network error has
                            occurred. Please try refreshing the browser or try again later.
                        </div>
                    )}
                    {errorUpdatingPassword === 'incorrect-old' ? (
                        <div className="text-red">
                            The current password you gave was incorrect. Please try again with your
                            correct current password.
                            {/* TODO Offer pw reset here? */}
                        </div>
                    ) : errorUpdatingPassword === 'previously-used' ? (
                        <div className="text-red">Please try with a different password.</div>
                    ) : errorUpdatingPassword === 'new-too-short' ? (
                        <div className="text-red">
                            Your password must have at least 8 characters.
                        </div>
                    ) : null}
                    {isUpdatingPassword ? (
                        <div>
                            <LoadingSpinner size={5} />
                        </div>
                    ) : showSuccessMessage ? (
                        <div className="text-green">Password successfully changed.</div>
                    ) : (
                        <ButtonMain className="xs:w-40" size="small" type="submit">
                            Save Password
                        </ButtonMain>
                    )}
                </form>
            </SimpleContentBox>
        </ContentElementContainer>
    );
};
