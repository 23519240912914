/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullUser } from '../models/FullUser';
import type { PatchedFullUserRequest } from '../models/PatchedFullUserRequest';
import type { PatchedUserProfileRequest } from '../models/PatchedUserProfileRequest';
import type { ResendUserConfirmationCode } from '../models/ResendUserConfirmationCode';
import type { ResendUserConfirmationCodeRequest } from '../models/ResendUserConfirmationCodeRequest';
import type { UserConfirmEmailChangeRequest } from '../models/UserConfirmEmailChangeRequest';
import type { UserPasswordChangeRequest } from '../models/UserPasswordChangeRequest';
import type { UserProfile } from '../models/UserProfile';
import type { UserRequestEmailChangeRequest } from '../models/UserRequestEmailChangeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns FullUser
     * @throws ApiError
     */
    public static userRetrieve(): CancelablePromise<FullUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/user/',
        });
    }

    /**
     * @returns FullUser
     * @throws ApiError
     */
    public static userPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedFullUserRequest,
    }): CancelablePromise<FullUser> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/user/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change user password
     * @returns any No response body
     * @throws ApiError
     */
    public static userChangePasswordUpdate({
        requestBody,
    }: {
        requestBody: UserPasswordChangeRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/user/change_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change user password
     * @returns any No response body
     * @throws ApiError
     */
    public static userConfirmChangeEmailUpdate({
        requestBody,
    }: {
        requestBody: UserConfirmEmailChangeRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/user/confirm_change_email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deactivates account thru settings User.is_active=False
     * @returns any No response body
     * @throws ApiError
     */
    public static userDeactivateUpdate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/user/deactivate',
        });
    }

    /**
     * Deactivates account thru settings User.is_active=False
     * @returns any No response body
     * @throws ApiError
     */
    public static userDeactivatePartialUpdate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/user/deactivate',
        });
    }

    /**
     * Change user password
     * @returns UserProfile
     * @throws ApiError
     */
    public static userProfilePartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedUserProfileRequest,
    }): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/user/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change user password
     * @returns any No response body
     * @throws ApiError
     */
    public static userRequestChangeEmailUpdate({
        requestBody,
    }: {
        requestBody: UserRequestEmailChangeRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/user/request_change_email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Resend user confirmation code via email
     * @returns ResendUserConfirmationCode
     * @throws ApiError
     */
    public static userResendConfirmationCodeCreate({
        requestBody,
    }: {
        requestBody: ResendUserConfirmationCodeRequest,
    }): CancelablePromise<ResendUserConfirmationCode> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/user/resend_confirmation_code/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
