import React from 'react';
import MobileIconActive from '../assets/icons/mobile-active.svg';
import DesktopIconActive from '../assets/icons/desktop-active.svg';
import MobileIconInactive from '../assets/icons/mobile-inactive.svg';
import DesktopIconInactive from '../assets/icons/desktop-inactive.svg';
import { SnipUrls } from '../data-transformations/create-snip-urls-data';

export type DemoView = 'mobile' | 'desktop';

interface PreviewViewSelectionTabProps {
    readonly activeTab: DemoView;
    readonly setActiveTab: (activeTab: DemoView) => void;
}

export const PreviewViewSelectionTab: React.FC<PreviewViewSelectionTabProps> = ({
    activeTab,
    setActiveTab
}) => (
    <div className="grid grid-cols-2">
        <button
            className={`py-2 px-4 flex justify-center border-l border-y border-r-[0.5px] border-grey-light rounded-l-md bg-white`}
            onClick={() => setActiveTab('mobile')}
        >
            <img
                src={activeTab === 'mobile' ? MobileIconActive : MobileIconInactive}
                alt="mobile icon"
                className="h-4 w-4"
            />
        </button>
        <button
            className={`py-2 px-4 flex justify-center border-r border-y border-l-[0.5px] border-grey-light rounded-r-md bg-white`}
            onClick={() => setActiveTab('desktop')}
        >
            <img
                src={activeTab === 'desktop' ? DesktopIconActive : DesktopIconInactive}
                alt="desktop icon"
                className="h-4 w-4"
            />
        </button>
    </div>
);

interface DemoContentIntegratorProps {
    readonly contentUrl: string;
    readonly cta: JSX.Element;
    readonly previewViewSelectionTab: JSX.Element;
    readonly activePreviewView;
    readonly snipUrls?: SnipUrls;
}

export const DemoContentIntegrator: React.FC<DemoContentIntegratorProps> = ({
    contentUrl,
    cta,
    previewViewSelectionTab,
    activePreviewView,
    snipUrls
}) => {
    return (
        <div className="relative h-full w-full font-poppins text-grey">
            {previewViewSelectionTab && (
                <div className="absolute top-0 left-0 w-full flex items-center justify-center p-2">
                    {previewViewSelectionTab}
                </div>
            )}
            <div className="pt-14 w-full h-full flex items-center justify-center">
                {activePreviewView === 'mobile' && previewViewSelectionTab ? (
                    <div className="bg-white rounded-lg px-4 py-8 w-[450px] h-9/10 flex items-center justify-center shadow-md overflow-hidden">
                        <div className="relative w-full h-full">
                            {snipUrls ? (
                                <>
                                    <iframe
                                        src={snipUrls.dynamic}
                                        className={`${
                                            contentUrl === snipUrls.dynamic ? 'block' : 'hidden'
                                        } absolute w-full h-full`}
                                    ></iframe>
                                    <iframe
                                        src={snipUrls.static}
                                        className={`${
                                            contentUrl === snipUrls.static ? 'block' : 'hidden'
                                        } absolute w-full h-full`}
                                    ></iframe>
                                    <iframe
                                        src={snipUrls.summary}
                                        className={`${
                                            contentUrl === snipUrls.summary ? 'block' : 'hidden'
                                        } absolute w-full h-full`}
                                    ></iframe>
                                </>
                            ) : (
                                <iframe
                                    src={contentUrl}
                                    className="absolute w-full h-full"
                                ></iframe>
                            )}

                            {cta}
                        </div>
                    </div>
                ) : (
                    <div className="relative h-full w-full overflow-hidden mx-2 mb-2 rounded-sm">
                        <iframe src={contentUrl} className="absolute w-full h-full"></iframe>
                        {cta}
                    </div>
                )}
            </div>
        </div>
    );
};
