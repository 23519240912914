import React from 'react';
import {
    Page
    // createPageData
} from './data-transformations/create-page-data';
import { ButtonMain } from './components/ButtonMain';
import { LoadingSpinner } from './components/LoadingSpinner';
import ArchiveIcon from './assets/icons/archive.svg';
import UnArchiveIcon from './assets/icons/unarchive.svg';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { VerticalSpacer } from './components/VerticalSpacer';
import { OverviewTabFirstLevelGrid } from './components/TabContentLayoutOverview';
import { PageOverviewGeneralInfo } from './PageOverviewGeneralInfo';
import { RouteLink } from './Routing';
import { EmptyTableView } from './components/EmptyTableView';

export const PageOverview: React.FC = () => {
    const [pageData, setPageData] = React.useState<Page>();

    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [hadErrorLoadingData] = React.useState(false);

    const [isUpdatingArchivedState, setIsUpdatingArchivedState] = React.useState(false);

    const isMobile = useIsMobileScreenSize();

    const handleSetArchived = React.useCallback((value: boolean) => {
        setIsUpdatingArchivedState(true);

        console.log('TODO PageService.pagePartialUpdate -> archived:', value);
    }, []);

    React.useEffect(() => {
        // fetchPage

        setTimeout(() => {
            // setPageData(createPageData);
            setPageData(undefined);
            setIsLoadingData(false);
        }, 2000);
    }, [setPageData]);

    return (
        <div className="w-full h-full">
            <div className="flex justify-between">
                <div>
                    <h2 className="font-semibold text-base text-grey">Workspace Page</h2>
                    <p className="mt-1 text-sm text-grey-light">
                        View and configure all relevant aspects of your page here
                    </p>
                </div>
                {isUpdatingArchivedState ? (
                    <div className="h-9 w-9 flex items-center justify-center">
                        <LoadingSpinner size={5} />
                    </div>
                ) : pageData?.archived ? (
                    <ButtonMain
                        style="neutral"
                        size="small"
                        onClick={() => {
                            handleSetArchived(false);
                        }}
                    >
                        <img src={UnArchiveIcon} alt="archive icon" className="w-4 h-4" />
                    </ButtonMain>
                ) : (
                    <ButtonMain
                        style="neutral"
                        size="small"
                        onClick={() => {
                            handleSetArchived(true);
                        }}
                    >
                        <img src={ArchiveIcon} alt="archive icon" className="w-4 h-4" />
                    </ButtonMain>
                )}
            </div>
            {isMobile ? <VerticalSpacer heightValue={5} /> : <VerticalSpacer heightValue={10} />}
            {isLoadingData ? (
                <div className="h-2/3 w-full flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : hadErrorLoadingData ? (
                <div className="text-red">
                    Apologies your Page could not be retrieved. An unexpected network error has
                    occurred. Please try refreshing the browser or try again later.
                </div>
            ) : pageData ? (
                <div>
                    <OverviewTabFirstLevelGrid>
                        <div className="grid grid-cols-1 lg:grid-cols-1-2 gap-5">
                            <PageOverviewGeneralInfo page={pageData} />
                            {/* <PageOverviewPerformace page={pageData} /> */}
                        </div>
                    </OverviewTabFirstLevelGrid>
                </div>
            ) : (
                <div className="mt-10 h-2/3">
                    <EmptyTableView
                        headlineText="Try Out The Page Feature"
                        sublineText="You do not have a page yet. Set one up now."
                        mainActionButton={
                            <RouteLink href="/page-create">
                                <ButtonMain>+ Create Page</ButtonMain>
                            </RouteLink>
                        }
                    />
                </div>
            )}
        </div>
    );
};
