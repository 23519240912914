import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';

Sentry.init({
    environment: process.env.ENVIRONMENT,
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
