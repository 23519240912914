import { TeamInvite, TeamMember } from '../api';

export interface TeamMemberData {
    readonly id: string;
    readonly name: string;
    readonly email: string;
    readonly role: TeamMemberRole;
    readonly active: boolean;
    readonly invitationPending: boolean;
    readonly excludedWorkspaceIds: string[];
}

export type TeamMemberRole = 'admin' | 'client';

export function createTeamMembersData(teamMembersResult: TeamMember[]): TeamMemberData[] {
    return (
        teamMembersResult?.map((teamMember) => ({
            id: teamMember.id,
            name: teamMember.name,
            email: teamMember.email,
            role: teamMember.is_client_role ? 'client' : 'admin',
            active: true,
            invitationPending: false,
            excludedWorkspaceIds: teamMember.exclude_brand_ids
        })) ?? []
    );
}

export function createInviteeData(inviteesResult: TeamInvite[]): TeamMemberData[] {
    return (
        inviteesResult?.map((invitee) => ({
            id: invitee.id,
            name: invitee.email,
            email: invitee.email,
            role: 'client',
            active: false,
            invitationPending: true,
            excludedWorkspaceIds: invitee.exclude_brand_ids
        })) ?? []
    );
}
