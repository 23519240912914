import React from 'react';
import { BrandsService, UploadService, UserService } from './api';
import { ButtonMain } from './components/ButtonMain';
import { FocusViewLayout } from './components/FocusViewLayout';
import { FormTextInput } from './components/FormTextInput';
import { ImageUploadWithCropSimplified } from './components/ImageUploadWithCrop';
import { LoadingSpinner } from './components/LoadingSpinner';
import { createWorkspace } from './data-transformations/create-workspaces-list';
import { navigateTo } from './Routing';
import { historyBackWithFallback } from './utils/history-back-with-fallback';
import { WorkspaceContext } from './WorkspaceContextProvider';

export const CreateNewWorkspace: React.FC = () => {
    const [savingWorkspace, setSavingWorkspace] = React.useState(false);
    const [hadErrorSavingWorkspace, setHadErrorSavingWorkspace] = React.useState(false);

    const [workspaceName, setWorkspaceName] = React.useState('');

    const [workspacePreviewImageUrl, setWorkspacePreviewImageUrl] = React.useState('');
    const [workspaceImageUrl, setWorkspaceImageUrl] = React.useState('');

    const [fileName, setFileName] = React.useState('initialState');
    const [imageBlob, setImageBlob] = React.useState<Blob>();

    const { workspaces, setWorkspaces, setCurrentWorkspace } = React.useContext(WorkspaceContext);

    const handleSubmit = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setSavingWorkspace(true);

            if (workspacePreviewImageUrl) {
                // If a new image has been selected - upload it, then proceed to update workspace.
                UploadService.uploadCreate({
                    requestBody: {
                        filename: fileName
                    }
                }).then((uploadCreateResult) => {
                    console.log(' UploadService.uploadCreate()', uploadCreateResult);
                    const newImageUrl = uploadCreateResult.upload_url.split('?')[0];

                    fetch(uploadCreateResult.upload_url, {
                        method: 'PUT',
                        headers: { 'x-amz-acl': 'public-read' },
                        body: imageBlob
                    })
                        .then(() => {
                            BrandsService.brandsCreate({
                                requestBody: {
                                    name: workspaceName,
                                    photo_url: newImageUrl
                                }
                            })
                                .then((result) => {
                                    setCurrentWorkspace(createWorkspace(result));
                                    setWorkspaces([...workspaces, createWorkspace(result)]);
                                    setSavingWorkspace(false);

                                    setTimeout(() => navigateTo('/'), 100);

                                    UserService.userProfilePartialUpdate({
                                        requestBody: { last_used_brand: result.id }
                                    });
                                })
                                .catch((error) =>
                                    console.log(
                                        'Error saving workspace via BrandsService.brandsCreate()',
                                        error
                                    )
                                );
                        })
                        .catch((error) => {
                            console.error('Error uploading image.', error);
                            setSavingWorkspace(false);
                            setHadErrorSavingWorkspace(true);
                        });
                });
            } else {
                BrandsService.brandsCreate({
                    requestBody: {
                        name: workspaceName,
                        photo_url:
                            'https://ffb2efd5105ff0aedbc9-9cdacdeebf0faa19b665bf427f0c8092.ssl.cf1.rackcdn.com/img/profile-placeholder.png'
                    }
                })
                    .then((result) => {
                        setCurrentWorkspace(createWorkspace(result));
                        setWorkspaces([...workspaces, createWorkspace(result)]);
                        setSavingWorkspace(false);

                        setTimeout(() => navigateTo('/'), 100);

                        UserService.userProfilePartialUpdate({
                            requestBody: { last_used_brand: result.id }
                        });
                    })
                    .catch((error) =>
                        console.log(
                            'Error saving workspace via BrandsService.brandsCreate()',
                            error
                        )
                    );
            }
        },
        [
            workspaceName,
            setCurrentWorkspace,
            workspaces,
            setWorkspaces,
            fileName,
            imageBlob,
            workspacePreviewImageUrl
        ]
    );
    return (
        <FocusViewLayout>
            <h2 className="font-semibold text-lg">Create New Workspace</h2>
            {savingWorkspace ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <div className="mt-10 grid grid-cols-1 gap-3">
                    {workspaceName && (
                        <div className="flex items-center">
                            <div className="h-6 w-6 rounded-full border border-white bg-black text-sm text-white flex items-center justify-center">
                                {!workspacePreviewImageUrl ? (
                                    workspaceName?.slice(0, 1)
                                ) : (
                                    <img src={workspacePreviewImageUrl} className="rounded-full" />
                                )}
                            </div>
                            <span className="ml-3 font-medium text-grey-light">
                                {workspaceName}
                            </span>
                        </div>
                    )}
                    <div className="mt-2">
                        <FormTextInput
                            id="workspace-input"
                            labelText="Name"
                            placeholder="Name your new workspace"
                            value={workspaceName}
                            onChange={(event) => setWorkspaceName(event.target.value)}
                            autoFocus
                        />
                    </div>
                    <div>
                        <p className="mb-1 text-sm">Image</p>
                        <ImageUploadWithCropSimplified
                            setFileName={setFileName}
                            setImageBlob={setImageBlob}
                            setImageUrl={setWorkspaceImageUrl}
                            imageUrl={workspaceImageUrl}
                            setImagePreviewUrl={setWorkspacePreviewImageUrl}
                            aspect={1}
                        />
                    </div>
                    <div className="mt-4 grid grid-cols-1-2 gap-2">
                        <ButtonMain
                            size="small"
                            style="neutral"
                            onClick={() => historyBackWithFallback('/user')}
                        >
                            Cancel
                        </ButtonMain>
                        <ButtonMain size="small" onClick={handleSubmit}>
                            Save
                        </ButtonMain>
                    </div>
                    {hadErrorSavingWorkspace && (
                        <p className="mt-2 text-sm text-red">
                            We could not save your changes due to an unexpected error. Please try
                            again.
                        </p>
                    )}
                </div>
            )}
        </FocusViewLayout>
    );
};
