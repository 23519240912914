import React from 'react';
import { LabelsService } from './api';
import { AuthContext } from './auth';
import { FilterDropdownSelect } from './components/FilterDropdownSelect';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface CampaignsDropdownSelectProps {
    readonly selectedCampaignId: string;
    readonly setSelectedCampaignId: (selectedCampaignId: string) => void;
}

export const CampaignsDropdownSelect: React.FC<CampaignsDropdownSelectProps> = ({
    selectedCampaignId,
    setSelectedCampaignId
}) => {
    const [campaignsData, setCampaignsData] = React.useState([]);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return (
        <FilterDropdownSelect
            label="Campaign"
            options={campaignsData}
            selectedOptionId={selectedCampaignId}
            onSelectOption={(optionId) => setSelectedCampaignId(optionId)}
        />
    );
};
