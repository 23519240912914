import React from 'react';

import { SitesService } from './api';
import VerifiedIconWhite from './assets/icons/award-white.svg';
import PendingVerificationIconWhite from './assets/icons/coffee-white.svg';
import { AuthContext } from './auth';
import { ActiveBadge, WarnInActiveBadge } from './components/Badge';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { EmptyTableView } from './components/EmptyTableView';
import { FormTextInput } from './components/FormTextInput';
import { LoadingSpinner } from './components/LoadingSpinner';
import { LegacyModal } from './components/Modal';
import { SimpleContentBox } from './components/SimpleContentBox';
import { StandardContentGrid } from './components/StandardContentGrid';
import { UrlListInput } from './components/UrlListInput';
import { VerticalSpacer } from './components/VerticalSpacer';
import { CtaDropdownSelect } from './CtaDropdownSelect';
import {
    createWebsite,
    createWebsitesList,
    WebsitesListItem
} from './data-transformations/create-websites-list';
import { IntegrationsEmbedOnWebsiteInfoContent } from './IntegrationsEmbedOnWebsiteInfoContent';
import { IntegrationsEmbedOnWebsiteTable } from './IntegrationsEmbedOnWebsiteTable';
import { NotificationContext } from './NotificationContextProvider';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { WorkspaceContext } from './WorkspaceContextProvider';

export const IntegrationsEmbedOnWebsite: React.FC = () => {
    const [websitesData, setWebsitesData] = React.useState<WebsitesListItem[]>([]);
    const [loadingWebsitesData, setLoadingWebsitesData] = React.useState(false);
    const [errorFetchingWebsitesData, setErrorFetchingWebsitesData] = React.useState(false);

    const [websiteInputValue, setWebsiteInputValue] = React.useState('');
    const [displayErrorInvalidUrl, setDisplayErrorInvalidUrl] = React.useState(false);
    const [displayErrorDuplicateDomain, setDisplayErrorDuplicateDomain] = React.useState(false);

    const [showWebsiteDetails, setShowWebsiteDetails] = React.useState(false);
    const [isPostingWebsite, setIsPostingWebsite] = React.useState(false);
    const [errorPostingWebsite, setErrorPostingWebsite] = React.useState(false);

    const [isVerifyingCodeEmbeddedForDomain, setIsVerifyingCodeEmbeddedForDomain] =
        React.useState(false);

    // Current Website
    const [currentWebsiteUrl, setCurrentWebsiteUrl] = React.useState('');
    const [currentWebsiteId, setCurrentWebsiteId] = React.useState(undefined);
    const [codeToBeEmbedded, setCodeToBeEmbedded] = React.useState('');

    const [selectedCtaId, setSelectedCtaId] = React.useState('');
    const [ctaUrlBlacklist, setCtaUrlBlacklist] = React.useState([]);

    const [enableOutboundSnips, setEnableOutboundSnips] = React.useState(false);
    const [outboundSnipsUrlBlacklist, setOutboundSnipsUrlBlacklist] = React.useState([]);

    const [disableSnipOutboundAffiliates, setDisableSnipOutboundAffiliates] = React.useState(false);

    const [isCurrentWebsiteVerified, setIsCurrentWebsiteVerified] = React.useState<
        'not-verified' | 'verified'
    >('not-verified');

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const embedOnWebsiteContainerRef = React.useRef(null);

    const resetAllFormValues = React.useCallback(() => {
        setWebsiteInputValue('');
        setCurrentWebsiteId('');
        setCurrentWebsiteUrl('');
        setSelectedCtaId('');
        setEnableOutboundSnips(false);
        setDisableSnipOutboundAffiliates(false);
        setOutboundSnipsUrlBlacklist([]);
        setCtaUrlBlacklist([]);
        setCodeToBeEmbedded('');
        setIsCurrentWebsiteVerified('not-verified');
    }, [
        setWebsiteInputValue,
        setCurrentWebsiteId,
        setCurrentWebsiteUrl,
        setSelectedCtaId,
        setEnableOutboundSnips,
        setDisableSnipOutboundAffiliates,
        setOutboundSnipsUrlBlacklist,
        setCtaUrlBlacklist,
        setCodeToBeEmbedded,
        setIsCurrentWebsiteVerified
    ]);

    const handleDomainSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(websiteInputValue))) {
                setDisplayErrorInvalidUrl(true);

                return;
            }

            setIsPostingWebsite(true);

            SitesService.sitesCreate({
                requestBody: {
                    brand: currentWorkspace.id,
                    domain: ensureUrlHasProtocol(websiteInputValue),
                    always_cta_ids: selectedCtaId ? [selectedCtaId] : [],
                    outbound_cta_ids: enableOutboundSnips && selectedCtaId ? [selectedCtaId] : [],
                    snip_outbound: enableOutboundSnips
                }
            })
                .then((result) => {
                    console.log('SitesService.sitesCreate(): ', result);
                    setCurrentWebsiteUrl(result.domain);
                    setCurrentWebsiteId(result.id);
                    setCodeToBeEmbedded(`<script type="text/javascript" src="${result.url}" />`);
                    setDisableSnipOutboundAffiliates(result.skip_affiliates);
                    setCtaUrlBlacklist(result.always_blacklist);
                    setOutboundSnipsUrlBlacklist(result.outbound_blacklist);

                    setIsPostingWebsite(false);
                    setShowWebsiteDetails(true);

                    setWebsitesData([createWebsite(result), ...websitesData]);
                })
                .catch((error) => {
                    console.log(
                        `Error posting to SitesService.sitesCreate() with domain: ${ensureUrlHasProtocol(
                            websiteInputValue
                        )}: `,
                        error
                    );

                    setIsPostingWebsite(false);
                    setErrorPostingWebsite(true);
                });
        },
        [currentWorkspace, websiteInputValue, enableOutboundSnips, selectedCtaId, websitesData]
    );

    const handleDomainUpdate = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setShowWebsiteDetails(false);

            SitesService.sitesPartialUpdate({
                id: currentWebsiteId,
                requestBody: {
                    always_cta_ids: selectedCtaId ? [selectedCtaId] : [],
                    snip_outbound: enableOutboundSnips,
                    outbound_cta_ids: enableOutboundSnips && selectedCtaId ? [selectedCtaId] : [],
                    skip_affiliates: disableSnipOutboundAffiliates,
                    outbound_blacklist: outboundSnipsUrlBlacklist,
                    always_blacklist: ctaUrlBlacklist
                }
            })
                .then((result) => {
                    console.log('SitesService.sitesCreate(): ', result);
                    console.log({
                        websitesData: websitesData.map((website) => {
                            if (website.id === currentWebsiteId) {
                                createWebsite(result);
                            } else {
                                return website;
                            }
                        })
                    });

                    setWebsitesData(
                        websitesData.map((website) => {
                            if (website.id === currentWebsiteId) {
                                createWebsite(result);
                            } else {
                                return website;
                            }
                        })
                    );
                    resetAllFormValues();
                })
                .catch((error) => {
                    console.log(
                        `Error posting to SitesService.sitesUpdate() with domain: ${currentWebsiteUrl}: `,
                        error
                    );
                    resetAllFormValues();
                });
        },
        [
            currentWebsiteUrl,
            currentWebsiteId,
            selectedCtaId,
            enableOutboundSnips,
            disableSnipOutboundAffiliates,
            outboundSnipsUrlBlacklist,
            ctaUrlBlacklist,
            resetAllFormValues,
            websitesData
        ]
    );

    const handleOpenEditViewForDomain = React.useCallback((website: WebsitesListItem) => {
        setCurrentWebsiteUrl(website.domain);
        setCurrentWebsiteId(website.id);
        setCodeToBeEmbedded(`<script type="text/javascript" src="${website.url}" />`);
        setSelectedCtaId(website.ctaId);
        setDisableSnipOutboundAffiliates(website.disableSnipOutboundAffiliates);
        setCtaUrlBlacklist(website.ctaUrlBlacklist);
        setOutboundSnipsUrlBlacklist(website.outboundSnipsUrlBlacklist);

        setShowWebsiteDetails(true);

        setTimeout(() => {
            embedOnWebsiteContainerRef.current &&
                embedOnWebsiteContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
    }, []);

    const handleVerifyEmbeddedOnWebsiteClick = React.useCallback(
        (websiteId: string) => {
            if (!websiteId) {
                return;
            }

            SitesService.sitesVerifyPartialUpdate({
                id: websiteId
            })
                .then((result) => {
                    if (result.installed) {
                        setWebsitesData(
                            websitesData.map((website) => {
                                if (website.id === websiteId) {
                                    return { ...website, verified: true };
                                } else {
                                    return website;
                                }
                            })
                        );
                        handleOpenNotification({
                            iconSrc: VerifiedIconWhite,
                            type: 'success',
                            messageText: 'Your website is embedding Sniply.',
                            showTimeInSeconds: 3
                        });

                        setIsVerifyingCodeEmbeddedForDomain(true);
                    } else {
                        handleOpenNotification({
                            iconSrc: PendingVerificationIconWhite,
                            type: 'warning',
                            messageText: 'We could not yet verify your installation.',
                            showTimeInSeconds: 3
                        });
                        setIsVerifyingCodeEmbeddedForDomain(false);
                    }
                })
                .catch((error) => {
                    console.log('Error verifying if website embeds Sniply.', error);

                    if (
                        error.body.non_field_errors?.[0].startsWith("Your site couldn't be reached")
                    ) {
                        handleOpenNotification({
                            iconSrc: PendingVerificationIconWhite,
                            type: 'warning',
                            messageText: 'We could not find this website.',
                            showTimeInSeconds: 3
                        });
                    }
                });
        },
        [websitesData, setWebsitesData, handleOpenNotification]
    );

    React.useEffect(() => {
        if (isOpenAPITokenSet) {
            setLoadingWebsitesData(true);

            SitesService.sitesList({ pageSize: 100 })
                .then((result) => {
                    console.log(' SitesService.sitesList()', result);
                    setWebsitesData(createWebsitesList(result));
                    setLoadingWebsitesData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from  SitesService.sitesList(): ', error);
                    setErrorFetchingWebsitesData(true);
                    setLoadingWebsitesData(false);
                });
        }
    }, [isOpenAPITokenSet]);

    return (
        <StandardContentGrid>
            <div ref={embedOnWebsiteContainerRef} className="grid grid-cols-1 gap-5">
                {showWebsiteDetails ? (
                    <LegacyModal
                        titleText={`Preferences`}
                        onCloseClick={() => setShowWebsiteDetails(false)}
                    >
                        <div className="grid grid-cols-1 gap-4">
                            <div className="pt-2 pb-4 px-2">
                                <p className="pb-2 border-b border-grey-lighter">
                                    <span className="font-semibold">{currentWebsiteUrl}</span>
                                    {isCurrentWebsiteVerified === 'verified' ? (
                                        <span className="ml-2">
                                            <ActiveBadge displayText="verified" />
                                        </span>
                                    ) : isCurrentWebsiteVerified === 'not-verified' ? (
                                        <span className="ml-2">
                                            <WarnInActiveBadge displayText="not verified" />
                                        </span>
                                    ) : null}
                                </p>
                                <p className="mt-4 text-sm">
                                    Copy and paste the following code into your webpage, just before
                                    your body tag ends.
                                </p>
                                <p className="mt-2 p-2 border border-grey-light rounded-md text-sm">
                                    {codeToBeEmbedded}
                                </p>
                                <p className="mt-6 text-sm">
                                    <span className="font-semibold">Note: </span>
                                    <span>
                                        If you use wordpress, you can install the code using a
                                        plugin. This one seems to work pretty well:
                                    </span>
                                    <a
                                        className="ml-2 text-primary underline"
                                        href="https://wordpress.org/plugins/header-and-footer-scripts/"
                                    >
                                        Check it out
                                    </a>
                                </p>
                                {isCurrentWebsiteVerified ===
                                'verified' ? null : isVerifyingCodeEmbeddedForDomain ? (
                                    <div className="mt-4 h-9 w-24 flex items-center justify-center">
                                        <LoadingSpinner size={5} />
                                    </div>
                                ) : (
                                    <ButtonMain
                                        className="mt-4"
                                        size="small"
                                        onClick={() =>
                                            handleVerifyEmbeddedOnWebsiteClick(currentWebsiteId)
                                        }
                                    >
                                        Verify Installation
                                    </ButtonMain>
                                )}
                            </div>
                            <div className="mt-4 pb-4 border-b border-grey-lighter">
                                <CtaDropdownSelect
                                    titleText="Selected Call-To-Action"
                                    selectedCtaId={selectedCtaId}
                                    setSelectedCtaId={setSelectedCtaId}
                                />
                                {selectedCtaId && (
                                    <div className="pl-4 mt-4 text-xs">
                                        <p>
                                            You can blacklist URLs here which this should not apply
                                            to:
                                        </p>
                                        <VerticalSpacer heightValue={2} />
                                        <UrlListInput
                                            urlList={ctaUrlBlacklist}
                                            setUrlList={setCtaUrlBlacklist}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="mt-4 p-2 border border-grey-lighter rounded-md">
                                <div className="flex items-center text-sm">
                                    <input
                                        id="outbound-snips-checkbox"
                                        type="checkbox"
                                        checked={enableOutboundSnips}
                                        onChange={() =>
                                            setEnableOutboundSnips(
                                                enableOutboundSnips ? false : true
                                            )
                                        }
                                    />
                                    <span className="ml-2">
                                        Convert all outbound links from your website into Snips
                                    </span>
                                </div>
                                {enableOutboundSnips && (
                                    <div>
                                        <div className="mt-2 pl-2">
                                            <div className="mt-6 flex items-center text-sm">
                                                <input
                                                    id="dont-snip-affiliates-checkbox"
                                                    type="checkbox"
                                                    checked={disableSnipOutboundAffiliates}
                                                    onChange={() =>
                                                        setEnableOutboundSnips(
                                                            enableOutboundSnips ? false : true
                                                        )
                                                    }
                                                />
                                                <span className="ml-2">
                                                    Do not snip affiliate links
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pl-4 mt-4 text-xs">
                                            <p>
                                                You can blacklist URLs here which this should not
                                                apply to:
                                            </p>
                                            <VerticalSpacer heightValue={2} />
                                            <UrlListInput
                                                urlList={outboundSnipsUrlBlacklist}
                                                setUrlList={setOutboundSnipsUrlBlacklist}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4 grid grid-cols-2 gap-2">
                                <ButtonMain
                                    size="small"
                                    style="neutral"
                                    onClick={() => {
                                        setCodeToBeEmbedded('');
                                        setCurrentWebsiteUrl('');
                                        setCurrentWebsiteId(undefined);
                                        setShowWebsiteDetails(false);
                                    }}
                                >
                                    Cancel
                                </ButtonMain>
                                <ButtonMain size="small" onClick={handleDomainUpdate}>
                                    Save
                                </ButtonMain>
                            </div>
                        </div>
                    </LegacyModal>
                ) : (
                    <ContentElementContainer>
                        {isPostingWebsite ? (
                            <div className="flex w-full h-full items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : errorPostingWebsite ? (
                            <p className="text-sm text-red">
                                Error saving your website. Please try again.
                            </p>
                        ) : (
                            <SimpleContentBox headerText="Embed Sniply Code On Your Website">
                                <form onSubmit={handleDomainSubmit}>
                                    <FormTextInput
                                        id="website-input"
                                        labelText="Your Website"
                                        value={websiteInputValue}
                                        onChange={(event) => {
                                            setWebsiteInputValue(event.target.value);

                                            if (displayErrorDuplicateDomain) {
                                                setDisplayErrorDuplicateDomain(false);
                                            }

                                            if (displayErrorInvalidUrl) {
                                                setDisplayErrorInvalidUrl(false);
                                            }
                                        }}
                                        placeholder="example.com"
                                    />
                                    <div className="mt-6">
                                        <CtaDropdownSelect
                                            titleText="Add Call-To-Action to your website"
                                            selectedCtaId={selectedCtaId}
                                            setSelectedCtaId={setSelectedCtaId}
                                        />
                                    </div>
                                    <div className="mt-6 pl-1 flex items-center text-sm">
                                        <input
                                            id="outbound-snips-checkbox"
                                            type="checkbox"
                                            checked={enableOutboundSnips}
                                            onChange={() =>
                                                setEnableOutboundSnips(
                                                    enableOutboundSnips ? false : true
                                                )
                                            }
                                        />
                                        <span className="ml-2">
                                            Convert all outbound links from your website into Snips
                                        </span>
                                    </div>
                                    <p className="mt-1 h-2 text-red text-xs">
                                        {displayErrorInvalidUrl
                                            ? 'Please provide a valid domain url.'
                                            : displayErrorDuplicateDomain
                                            ? 'This Url already has been set up previously.'
                                            : ''}
                                    </p>
                                    <div className="flex mt-4">
                                        <ButtonMain size="small" type="submit">
                                            Generate code
                                        </ButtonMain>
                                    </div>
                                </form>
                            </SimpleContentBox>
                        )}
                    </ContentElementContainer>
                )}
                <ContentElementContainer>
                    {loadingWebsitesData ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : errorFetchingWebsitesData ? (
                        <div className="text-red">
                            Apologies your websites data could not be retrieved. An unexpected
                            network error has occurred. Please try again later.
                        </div>
                    ) : websitesData.length ? (
                        <IntegrationsEmbedOnWebsiteTable
                            websites={websitesData}
                            setWebsites={setWebsitesData}
                            handleVerifyEmbeddedOnWebsiteClick={handleVerifyEmbeddedOnWebsiteClick}
                            handleOpenEditWebsite={handleOpenEditViewForDomain}
                        />
                    ) : (
                        <div className="flex h-full w-full items-center justify-center">
                            <EmptyTableView
                                headlineText="None of your websites are embedding Sniply yet"
                                sublineText="Once you do have websites embedding Sniply, you will see the overview of all your websites here."
                            />
                        </div>
                    )}
                </ContentElementContainer>
            </div>
            <ContentElementContainer>
                <IntegrationsEmbedOnWebsiteInfoContent />
            </ContentElementContainer>
        </StandardContentGrid>
    );
};
