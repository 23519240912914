import React from 'react';
import { Customization } from './Customization';
import { Dashboard } from './Dashboard';
import { Integrations } from './Integrations';
import { Snips } from './Snips';
import { Route } from './Routing';
import { Support } from './Support';
import { WithNavigation } from './WithNavigation';
import { Reporting } from './Reporting';
import { SnipCreate } from './journeys/SnipCreate/SnipCreate';
import { UserAccountDashboard } from './UserAccountDashboard';
import { UserSettings } from './UserSettings';
import { UserTeam } from './UserTeam';
import { UserBilling } from './UserBilling';
import { CreateFeed } from './IntegrationsRssFeedsCreateFeed';
import { EditFeed } from './IntegrationsRssFeedsEditFeed.tsx';
import { Clipboard } from './Clipboard';
import { SnipCtaChange } from './journeys/SnipCtaChange';
import { SubscriptionPlans } from './SubscriptionPlans';
import { SnipEdit } from './journeys/SnipEdit/SnipEdit';
import { SnipCtaEdit } from './journeys/SnipCtaEdit';
import { DiscoverContent } from './DiscoverContent';
import { UserContext } from './UserContextProvider';
import { useInitializeBeamerIntegration } from './hooks/use-initialize-beamer-integration';
import { useInitializeChurnkeyIntegration } from './hooks/use-initialize-churnkey-integration';
import { ManageWorkspaces } from './UserSettingsManageWorkspaces';
import { PageCreate } from './journeys/PageCreate/PageCreate';
import { FeatureFlagsContext } from './FeatureFlagsContextProvider';
import { PageOverview } from './PageOverview';
import { SnipCtaAddToRedirect } from './journeys/SnipCtaAddToRedirect/SnipCtaAddToRedirect';
import { CtaCreate } from './journeys/CtaCreate/CtaCreate';

export interface TabViewContext<TabViewType> {
    readonly tabView: TabViewType;
    readonly setTabView: (tabView: TabViewType) => void;
}

export type StandardTabView = 'overview' | 'analytics';

export const Router: React.FC = () => {
    const { userId } = React.useContext(UserContext);
    const { enablePagesFeature } = React.useContext(FeatureFlagsContext);

    useInitializeBeamerIntegration(userId);
    useInitializeChurnkeyIntegration();

    return (
        <>
            <Route path="/snip-create">
                <SnipCreate />
            </Route>
            <Route path="/snip-edit">
                <SnipEdit />
            </Route>
            <Route path="/cta-create">
                <CtaCreate />
            </Route>
            <Route path="/snip-cta-change">
                <SnipCtaChange />
            </Route>
            <Route path="/snip-cta-edit">
                <SnipCtaEdit />
            </Route>
            <Route path="/snip-cta-add">
                <SnipCtaAddToRedirect />
            </Route>
            <Route path="/user">
                <UserAccountDashboard />
            </Route>
            <Route path="/settings">
                <UserSettings />
            </Route>
            <Route path="/team">
                <UserTeam />
            </Route>
            <Route path="/billing">
                <UserBilling />
            </Route>
            <Route path="/manage-workspaces">
                <ManageWorkspaces />
            </Route>
            <Route path="/plans">
                <SubscriptionPlans />
            </Route>
            <Route path="/feed-create">
                <CreateFeed />
            </Route>
            <Route path="/feed-edit">
                <EditFeed />
            </Route>
            <Route path="/">
                <WithNavigation>
                    <Dashboard />
                </WithNavigation>
            </Route>
            <Route path="/snips">
                <WithNavigation>
                    <Snips />
                </WithNavigation>
            </Route>
            <Route path="/discover-content">
                <WithNavigation>
                    <DiscoverContent />
                </WithNavigation>
            </Route>
            <Route path="/clipboard">
                <WithNavigation>
                    <Clipboard />
                </WithNavigation>
            </Route>
            <Route path="/reporting">
                <WithNavigation>
                    <Reporting />
                </WithNavigation>
            </Route>
            <Route path="/integrations">
                <WithNavigation>
                    <Integrations />
                </WithNavigation>
            </Route>
            <Route path="/customization">
                <WithNavigation>
                    <Customization />
                </WithNavigation>
            </Route>
            <Route path="/support">
                <WithNavigation>
                    <Support />
                </WithNavigation>
            </Route>
            {enablePagesFeature && (
                <>
                    <Route path="/page-create">
                        <PageCreate />
                    </Route>
                    <WithNavigation>
                        <Route path="/page">
                            <PageOverview />
                        </Route>
                    </WithNavigation>
                </>
            )}
        </>
    );
};
