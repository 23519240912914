import React from 'react';
import { CampaignsDropdownTagSelect } from './CampaignsDropdownTagSelect';
import { navigateTo } from './Routing';
import ArrowIcon from './assets/icons/arrow-grey-light.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { LabelsService, LinksService } from './api';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { DropDownOption } from './components/DropdownSelect';

interface SnipSnipContentCampaignProps {
    readonly snip: SnipsListItem;
}

export const SnipSnipContentCampaign: React.FC<SnipSnipContentCampaignProps> = ({ snip }) => {
    const [campaignsData, setCampaignsData] = React.useState<DropDownOption[]>([]);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = React.useState(true);

    const [selectedCampaignId, setSelectedCampaignId] = React.useState(snip.campaignId);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const handleSelectCampaign = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignId: string) => {
            event.preventDefault();

            LinksService.linksPartialUpdate({
                id: snip.id,
                requestBody: { label: campaignId }
            }).then(() => setSelectedCampaignId(campaignId));
        },
        [snip.id]
    );

    const handleCreateCampaignAndUpdateSnip = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
            event.preventDefault();

            LabelsService.labelsCreate({
                requestBody: { brand: currentWorkspace.id, name: campaignName }
            }).then((result) => {
                setCampaignsData([
                    ...campaignsData,
                    { displayLabel: result.name, value: result.id }
                ]);

                setSelectedCampaignId(result.id);

                LinksService.linksPartialUpdate({
                    id: snip.id,
                    requestBody: { label: result.id }
                });
            });
        },
        [snip, currentWorkspace, campaignsData]
    );

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingCampaigns(true);

            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                    setIsLoadingCampaigns(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                    setIsLoadingCampaigns(false);
                });
        }
    }, [currentWorkspace]);

    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="font-semibold">Campaign</h3>
                {selectedCampaignId && (
                    <button
                        className="flex items-center"
                        onClick={() => navigateTo(`/reporting?campaignId=${selectedCampaignId}`)}
                    >
                        <span className="text-sm text-grey-light">View Campaign Analytics</span>
                        <img src={ArrowIcon} alt="arrow icon" className="rotate-180 ml-1 h-4 w-4" />
                    </button>
                )}
            </div>
            <div className="mt-4 w-72">
                <CampaignsDropdownTagSelect
                    label="+ Add To Campaign"
                    campaigns={campaignsData}
                    isLoadingCampaigns={isLoadingCampaigns}
                    selectedCampaignId={selectedCampaignId}
                    onResetCampaignId={(event) => handleSelectCampaign(event, '')}
                    onSelectCampaignId={handleSelectCampaign}
                    onCreateCampaign={handleCreateCampaignAndUpdateSnip}
                />
            </div>
        </ContentElementContainer>
    );
};
