import React from 'react';
import { FormTextInput } from './components/FormTextInput';
import CopyIcon from './assets/icons/copy.svg';
import CopyIconWhite from './assets/icons/copy-white.svg';
import DeleteIcon from './assets/icons/delete.svg';
import SuccessIcon from './assets/icons/tick-white.svg';
import { ButtonMain } from './components/ButtonMain';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { VerticalSpacer } from './components/VerticalSpacer';
import { DropDownOption, DropdownSelect } from './components/DropdownSelect';
import { CtasService, FeedsService, LabelsService } from './api';
import { createCtasListForDropdown } from './data-transformations/create-ctas-list';
import { FocusViewLayout } from './components/FocusViewLayout';
import { navigateTo, useCurrentUrlPath } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { LoadingSpinner } from './components/LoadingSpinner';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { UserContext } from './UserContextProvider';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { CampaignsDropdownTagSelect } from './CampaignsDropdownTagSelect';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { NotificationContext } from './NotificationContextProvider';

export const EditFeed: React.FC = () => {
    const [feedUrl, setFeedUrl] = React.useState('');
    const [feedOutputUrl, setFeedOutputUrl] = React.useState('');

    const [feedCtaId, setFeedCtaId] = React.useState('');
    const [secondaryFeedCtaId, setSecondaryFeedCtaId] = React.useState('');

    const [selectedCampaignId, setSelectedCampaignId] = React.useState('');

    const [ctasData, setCtasData] = React.useState<DropDownOption[]>([]);
    const [campaignsData, setCampaignsData] = React.useState<DropDownOption[]>([]);

    const [displayValidationErrors, setDisplayValidationErrors] = React.useState(false);

    const [isLoadingFeed, setIsLoadingFeed] = React.useState(true);
    const [isSavingFeed, setIsSavingFeed] = React.useState(false);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = React.useState(true);

    const [errorLoadingFeed, setErrorLoadingFeed] = React.useState(false);
    const [errorSavingFeed, setErrorSavingFeed] = React.useState(false);
    const [errorDeletingFeed, setErrorDeletingFeed] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { canUseABTesting, minimumABTestingPlan } = React.useContext(UserContext);

    const { handleOpenNotification } = React.useContext(NotificationContext);
    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    const [showBVariantCtaDropdown, setShowBVariantCtaDropdown] = React.useState(false);

    const { level2: feedId } = useCurrentUrlPath();

    const handleSaveFeed = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setErrorSavingFeed(false);

            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(feedUrl))) {
                setDisplayValidationErrors(true);

                return;
            }

            setIsSavingFeed(true);

            const ctaIds =
                !feedCtaId && !secondaryFeedCtaId
                    ? []
                    : feedCtaId && !secondaryFeedCtaId
                    ? [feedCtaId]
                    : [feedCtaId, secondaryFeedCtaId];

            FeedsService.feedsUpdate({
                id: feedId,
                requestBody: {
                    brand: currentWorkspace.id,
                    feed_url: feedUrl,
                    cta_ids: ctaIds,
                    label: selectedCampaignId ? selectedCampaignId : null
                }
            })
                .then((result) => {
                    console.log(' FeedsService.feedsUpdate()', result);
                    setIsSavingFeed(false);
                    navigateTo('/integrations/rss-feeds');

                    handleOpenNotification({
                        messageText: 'Feed successfully saved.',
                        type: 'success',
                        iconSrc: SuccessIcon,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.log('Error calling api for FeedsService.feedsUpdate()', error);
                    setIsSavingFeed(true);
                    setErrorSavingFeed(true);
                });
        },
        [
            currentWorkspace,
            feedId,
            feedUrl,
            feedCtaId,
            secondaryFeedCtaId,
            selectedCampaignId,
            handleOpenNotification
        ]
    );

    const handleDeleteFeed = React.useCallback(
        (event: React.FormEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setErrorDeletingFeed(false);
            setIsLoadingFeed(true);

            FeedsService.feedsPartialUpdate({
                id: feedId,
                requestBody: {
                    deleted: true
                }
            })
                .then((result) => {
                    console.log(' FeedsService.feedsUpdate()', result);
                    setIsLoadingFeed(false);
                    navigateTo('/integrations/rss-feeds');
                })
                .catch((error) => {
                    console.log('Error calling api for FeedsService.feedsUpdate()', error);
                    setErrorDeletingFeed(true);
                    setIsLoadingFeed(false);
                });
        },
        [feedId]
    );

    const handleCreateNewCampaign = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
            event.preventDefault();

            LabelsService.labelsCreate({
                requestBody: { brand: currentWorkspace.id, name: campaignName }
            }).then((result) => {
                console.log('LabelsService.labelsCreate()', result);

                setCampaignsData([
                    ...campaignsData,
                    { displayLabel: result.name, value: result.id }
                ]);

                setSelectedCampaignId(result.id);
            });
        },
        [currentWorkspace, campaignsData]
    );

    const handleCopyOutputFeedUrl = React.useCallback(() => {
        navigator.clipboard.writeText(feedOutputUrl);

        handleOpenNotification({
            messageText: `Successfully copied ${feedOutputUrl}`,
            type: 'success',
            iconSrc: CopyIconWhite,
            showTimeInSeconds: 3
        });
    }, [feedOutputUrl, handleOpenNotification]);

    React.useEffect(() => {
        if (feedId) {
            setIsLoadingFeed(true);

            FeedsService.feedsRetrieve({ id: feedId })
                .then((result) => {
                    console.log(
                        `FeedsService.feedsRetrieve() with id ${feedId} returned: `,
                        result
                    );
                    setFeedUrl(result.feed_url);
                    setFeedOutputUrl(result.link);
                    setFeedCtaId(result.cta_ids[0]);
                    setSecondaryFeedCtaId(result.cta_ids[1] || '');
                    setSelectedCampaignId(result.label);

                    setIsLoadingFeed(false);
                })
                .catch((error) => {
                    console.log('Error fetch feed for editing', error);
                    setIsLoadingFeed(false);
                    setErrorLoadingFeed(true);
                });
        }
    }, [feedId]);

    React.useEffect(() => {
        if (currentWorkspace) {
            CtasService.ctasList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('FeedsService.feedsList()', result);
                    setCtasData(createCtasListForDropdown(result));
                })
                .catch((error) => {
                    console.log('Error fetching from FeedsService.feedsList(): ', error);
                });
        }
    }, [currentWorkspace]);

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingCampaigns(true);

            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                    setIsLoadingCampaigns(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                    setIsLoadingCampaigns(false);
                });
        }
    }, [currentWorkspace]);

    return (
        <FocusViewLayout enableWideView>
            <div className="flex items-start justify-between">
                <h2 className="font-semibold text-lg">Your Feed</h2>
                <button
                    onClick={handleDeleteFeed}
                    className="flex h-9 items-center text-grey border border-grey-light rounded-md text-sm px-2 py-1"
                >
                    <img src={DeleteIcon} alt="delete icon" />
                    <span className="ml-2">Delete</span>
                </button>
            </div>

            <VerticalSpacer heightValue={10} />

            {isLoadingFeed ? (
                <div className="h-44 flex items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : errorLoadingFeed ? (
                <div className="flex items-center justify-center">
                    <p className="text-sm text-red">
                        Apologies the requested feed could not be loaded at this time. Please try
                        again by reloading the browser window.
                    </p>
                </div>
            ) : (
                <form className="mt-1 grid p-4" onSubmit={handleSaveFeed}>
                    <FormTextInput
                        id="feed-url-input"
                        labelText="Feed Url"
                        placeholder="Enter feed url"
                        value={feedUrl}
                        onChange={(event) => {
                            setFeedUrl(event.target.value);
                        }}
                    />
                    <p className="h-3 p-1 text-xs text-red">{`${
                        displayValidationErrors ? 'please provide a valid url' : ''
                    }`}</p>
                    <VerticalSpacer heightValue={6} />
                    <p className="text-sm">Feed Output Url</p>
                    <div className="mt-2 flex items-center">
                        <p className="text-xs break-words w-56 md:w-auto">{feedOutputUrl}</p>
                        <button
                            className="ml-2"
                            onClick={(event) => {
                                event.preventDefault();
                                handleCopyOutputFeedUrl();
                            }}
                        >
                            <img src={CopyIcon} alt="copy icon" className="h-4 w-4" />
                        </button>
                    </div>
                    <VerticalSpacer heightValue={6} />
                    <div>
                        <p className="text-sm">Call-To-Actions</p>
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-y-3 gap-x-10">
                            <DropdownSelect
                                placeholderText="Select Call-To-Action"
                                options={ctasData}
                                selectedOption={feedCtaId}
                                onSelectOption={(option) => setFeedCtaId(option)}
                            />
                            {feedCtaId ? (
                                showBVariantCtaDropdown ? (
                                    <DropdownSelect
                                        placeholderText="Select Call-To-Action"
                                        options={ctasData}
                                        selectedOption={secondaryFeedCtaId}
                                        onSelectOption={(option) => setSecondaryFeedCtaId(option)}
                                    />
                                ) : (
                                    <button
                                        className="text-left px-2 text-grey-light line-clamp-1 text-sm"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (!canUseABTesting) {
                                                handleOpenUpgradePlanMessage({
                                                    feature: 'A/B Testing',
                                                    requiredPlan: minimumABTestingPlan
                                                });
                                            } else {
                                                setShowBVariantCtaDropdown(true);
                                            }
                                        }}
                                    >
                                        + Add B Variation
                                    </button>
                                )
                            ) : null}
                        </div>
                    </div>
                    <VerticalSpacer heightValue={6} />
                    <div>
                        <p className="text-sm mb-2">Campaign</p>
                        {isLoadingCampaigns ? (
                            <div className="flex items-center justify-center">
                                <LoadingSpinner size={5} />
                            </div>
                        ) : (
                            <CampaignsDropdownTagSelect
                                label="+ Add To Campaign"
                                campaigns={campaignsData}
                                isLoadingCampaigns={isLoadingCampaigns}
                                selectedCampaignId={selectedCampaignId}
                                onSelectCampaignId={(event, id) => {
                                    event.preventDefault();
                                    setSelectedCampaignId(id);
                                }}
                                onResetCampaignId={(event) => {
                                    event.preventDefault();
                                    setSelectedCampaignId('');
                                }}
                                onCreateCampaign={handleCreateNewCampaign}
                            />
                        )}
                    </div>
                    {errorSavingFeed ? (
                        <p className="mt-2 text-sm text-red">
                            Apologies the feed could not be saved at this time. Please try again.
                        </p>
                    ) : errorDeletingFeed ? (
                        <p className="mt-2 text-sm text-red">
                            Apologies the feed could not be deleted at this time. Please try again.
                        </p>
                    ) : null}
                    <VerticalSpacer heightValue={12} />
                    {isSavingFeed ? (
                        <div className="flex items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : (
                        <div className="flex">
                            <ButtonMain
                                style="neutral"
                                onClick={() => navigateTo('/integrations/rss-feeds')}
                            >
                                Cancel
                            </ButtonMain>
                            <ButtonMain className="ml-2 w-full sm:w-32" type="submit">
                                Save Feed
                            </ButtonMain>
                        </div>
                    )}
                </form>
            )}
        </FocusViewLayout>
    );
};
