import React from 'react';
import { CustomizationUtmTags } from './CustomizationUtmTags';
import { Tab, Tabs } from './components/shared/Tabs';
import { VerticalSpacer } from './components/VerticalSpacer';
import { DropdownTabs } from './components/DropdownTabs';
import { useElementWidth } from './hooks/use-element-width';
import { RouteLink, useCurrentUrlPath } from './Routing';
import { CustomizationCustomDomains } from './CustomizationCustomDomains';
import { CustomizationConversionPixel } from './CustomizationConversionPixels';
import { CustomizationCustomCode } from './CustomizationCustomCode';

export type CustomizationView =
    | 'custom-domains'
    | 'publisher-tools'
    | 'utm-tags'
    | 'conversion-pixel'
    | 'custom-code';

function renderCustomizationViewContent(customizationView: CustomizationView): JSX.Element {
    switch (customizationView) {
        case 'utm-tags':
            return <CustomizationUtmTags />;
        case 'conversion-pixel':
            return <CustomizationConversionPixel />;
        case 'custom-code':
            return <CustomizationCustomCode />;
        default:
            return <CustomizationCustomDomains />;
    }
}

export const Customization: React.FC = () => {
    const tabView: CustomizationView =
        (useCurrentUrlPath()?.level2 as CustomizationView) ?? 'custom-domains';

    const customizationSectionContainerRef = React.useRef(undefined);
    const customizationSectionContainerWidth = useElementWidth(customizationSectionContainerRef);
    const showDropDownTabs = customizationSectionContainerWidth < 580;

    return (
        <div ref={customizationSectionContainerRef}>
            <div>
                <h2 className="font-semibold text-lg text-grey">Customization</h2>
                <div>
                    <p className="text-sm text-grey-light">Overview of your custom settings</p>
                </div>
            </div>
            <VerticalSpacer heightValue={showDropDownTabs ? 5 : 10} />
            {showDropDownTabs ? (
                <DropdownTabs
                    options={[
                        { value: 'custom-domains', displayLabel: 'Custom Domains' },
                        { value: 'utm-tags', displayLabel: 'UTM Tags' },
                        { value: 'conversion-pixel', displayLabel: 'Conversion Pixel' },
                        { value: 'custom-code', displayLabel: 'Custom Code' }
                    ]}
                    selectedOption={tabView ?? 'team'}
                    renderOptionSelectionButton={(option, onClick) => (
                        <button className="w-full text-left" onClick={() => onClick()}>
                            <RouteLink
                                className="inline-block pr-20"
                                href={`/customization/${option.value}`}
                            >
                                {option.displayLabel}
                            </RouteLink>
                        </button>
                    )}
                />
            ) : (
                <Tabs
                    tabs={[
                        { id: 'custom-domains', displayText: 'Custom Domains' },
                        { id: 'utm-tags', displayText: 'UTM Tags' },
                        { id: 'conversion-pixel', displayText: 'Conversion Pixel' },
                        { id: 'custom-code', displayText: 'Custom Code' }
                    ]}
                    currentTabView={tabView ?? 'team'}
                    renderTabSelectionButton={(tab: Tab) => (
                        <button className="text-base h-6 px-2">
                            <RouteLink href={`/customization/${tab.id}`}>
                                {tab.displayText}
                            </RouteLink>
                        </button>
                    )}
                />
            )}
            <p className="border-b border-grey-lighter" />
            <VerticalSpacer heightValue={showDropDownTabs ? 5 : 10} />
            {renderCustomizationViewContent(tabView)}
        </div>
    );
};
