import React from 'react';
import { InfoCardGrid } from './components/InfoCardGrid';
import { SupportInfoCard, SupportInfoCardProps } from './components/SupportInfoCard';

import DrivingResultsImage from './assets/images/TipsAndStrategies/driving-more-results.jpeg';
import ABTestingImage from './assets/images/TipsAndStrategies/start-ab-testing.jpeg';
import BrandingImage from './assets/images/TipsAndStrategies/branding-your-snips.jpeg';
import FormsImage from './assets/images/TipsAndStrategies/capture-emails-with-forms.jpeg';
import IntegrationsImage from './assets/images/TipsAndStrategies/leverage-sniply-integrations.jpeg';
import AddCodeImage from './assets/images/TipsAndStrategies/adding-code-to-your-snips.jpeg';

const TIPS_AND_STRATEGIES: SupportInfoCardProps[] = [
    {
        titleText: 'Driving More Results',
        imageUrl: DrivingResultsImage,
        additionalText: 'Practice makes perfect, but here are a few ideas',
        infoLinks: [
            {
                displayText: 'How to drive more Clicks to your Snips',
                url: 'https://sniply.io/blog/getting-more-clicks-with-sniply/'
            },
            {
                displayText: 'How to improve conversion rates on Call-To-Actions',
                url: 'https://sniply.io/blog/driving-more-conversions-with-sniply/'
            },
            {
                displayText: 'Learn from this live stream of Snips in real-time',
                url: 'https://twitter.com/search?q=snip.ly&src=typd'
            }
        ]
    },
    {
        titleText: 'Start A/B Testing',
        imageUrl: ABTestingImage,
        additionalText: 'Test and optimize your Call-To-Actions',
        infoLinks: [
            {
                displayText: 'What is A/B Testing?',
                url: 'https://support.snip.ly/hc/en-us/articles/5880061800852-What-is-A-B-Testing-'
            },
            {
                displayText: 'How do I add an A/B Variation?',
                url: 'https://support.snip.ly/hc/en-us/articles/5880109661204-How-do-I-add-an-A-B-Variation-'
            },
            {
                displayText: 'How do I manage A/B Variations?',
                url: 'https://support.snip.ly/hc/en-us/articles/5880166110484-How-do-I-make-changes-to-A-B-Variations-'
            }
        ]
    },
    {
        titleText: 'Branding Your Snips',
        imageUrl: BrandingImage,
        additionalText: 'Turn curated content into a branded experience',
        infoLinks: [
            {
                displayText: 'Customize colors, themes, and positioning',
                url: 'https://support.snip.ly/hc/en-us/articles/5880075816468-How-do-I-customize-the-look-and-feel-of-my-Snips-'
            },
            {
                displayText: 'Remove the Sniply logo from your Snips',
                url: 'https://support.snip.ly/hc/en-us/articles/5880159564308-How-do-I-hide-the-Sniply-Logo-'
            },
            {
                displayText: 'Create Snips using your own URL',
                url: 'https://support.snip.ly/hc/en-us/articles/20000907424532-How-do-I-create-snips-using-my-own-custom-domain-'
            }
        ]
    },
    {
        titleText: 'Capture Emails with Forms',
        imageUrl: FormsImage,
        additionalText: 'Grow your newsletters and get sign ups',
        infoLinks: [
            {
                displayText: 'Create snips with a form submission field',
                url: 'https://support.snip.ly/hc/en-us/articles/5880134829076-How-do-I-create-a-Form-Snip-'
            },
            {
                displayText: 'Exporting submissions from the dashboard',
                url: 'https://support.snip.ly/hc/en-us/articles/5880060658196-How-do-I-export-submissions-from-Form-Snips-'
            },
            {
                displayText: 'Connect Sniply with Mailchimp, Aweber, etc',
                url: 'https://support.snip.ly/hc/en-us/articles/5880121466132-How-do-I-integrate-with-Mailchimp-Aweber-using-Zapier-'
            }
        ]
    },
    {
        titleText: 'Leverage Sniply Integrations',
        imageUrl: IntegrationsImage,
        additionalText: 'Use Sniply in combination with other platforms',
        infoLinks: [
            {
                displayText: 'Snip anywhere using Chrome/Firefox plugins',
                url: 'https://support.snip.ly/hc/en-us/articles/5880120860820-How-do-I-create-snips-using-Chrome-Firefox-plugins-'
            },
            {
                displayText: 'Integrate Sniply with Hootsuite, Buffer, etc',
                url: 'https://support.snip.ly/hc/en-us/articles/5880067122452-How-do-I-snip-within-Buffer-Hootsuite-Facebook-Twitter-etc-'
            },
            {
                displayText: 'Use Zapier to automate your Sniply workflow',
                url: 'https://sniply.io/blog/use-zapier-with-sniply-to-automate-your-workflow/'
            }
        ]
    },
    {
        titleText: 'Adding Code to Your Snips',
        imageUrl: AddCodeImage,
        additionalText: 'Build custom audiences through your curated content',
        infoLinks: [
            {
                displayText: 'Add a Retargeting Pixel to all your Snips',
                url: 'https://support.snip.ly/hc/en-us/articles/5880093019540-How-do-I-add-Remarketing-Pixels-to-my-snips-'
            },
            {
                displayText: 'Use CSS to build your own unique theme',
                url: 'https://support.snip.ly/hc/en-us/articles/5880093575444-How-do-I-customize-my-snips-using-CSS-'
            },
            {
                displayText: 'Build your own custom setup using the Sniply API',
                url: 'https://snip.ly/api/'
            }
        ]
    }
];

export const SupportTipsAndStrategies = () => {
    return (
        <InfoCardGrid>
            {TIPS_AND_STRATEGIES.map((infoCardData) => (
                <SupportInfoCard
                    key={infoCardData.titleText}
                    titleText={infoCardData.titleText}
                    additionalText={infoCardData.additionalText}
                    infoLinks={infoCardData.infoLinks}
                    imageUrl={infoCardData.imageUrl}
                />
            ))}
        </InfoCardGrid>
    );
};
