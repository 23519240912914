import { EmbeddedWebsite, PaginatedEmbeddedWebsiteList } from '../api';

export interface WebsitesListItem
    extends Record<string, string | number | boolean | Array<string>> {
    readonly id: string;
    readonly domain: string;
    readonly url: string;
    readonly ctaId: string;
    readonly verified: boolean;
    readonly disableSnipOutboundAffiliates: boolean;
    readonly ctaUrlBlacklist: Array<string>;
    readonly outboundSnipsUrlBlacklist: Array<string>;
}

export function createWebsite(embeddedWebsite: EmbeddedWebsite) {
    return {
        id: embeddedWebsite.id,
        domain: embeddedWebsite.domain,
        url: embeddedWebsite.url,
        ctaId: embeddedWebsite.always_cta_ids[0],
        verified: embeddedWebsite.installed,
        disableSnipOutboundAffiliates: embeddedWebsite.skip_affiliates,
        ctaUrlBlacklist: embeddedWebsite.always_blacklist,
        outboundSnipsUrlBlacklist: embeddedWebsite.outbound_blacklist
    };
}

export function createWebsitesList(response: PaginatedEmbeddedWebsiteList): WebsitesListItem[] {
    if (!response) {
        return [];
    }

    return response.results.map((websiteResult) => createWebsite(websiteResult));
}
