import { UserPlan } from '../UserContextProvider';

export function getNextHigherPlan(userPlan: UserPlan): UserPlan {
    return userPlan === 'Basic'
        ? 'Pro'
        : userPlan === 'Pro'
        ? 'Business'
        : userPlan === 'Business'
        ? 'Enterprise'
        : 'Basic';
}
