import React from 'react';
import ArrowIcon from '../assets/icons/arrow.svg';

interface ButtonBackProps {
    readonly onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly children: React.ReactNode;
}

const ButtonBack: React.FC<ButtonBackProps> = ({ onClick, children }) => {
    return (
        <button className="flex items-center hover:underline" onClick={(event) => onClick(event)}>
            <img src={ArrowIcon} className="h-5 w-5" />
            <span className="ml-2">{children}</span>
        </button>
    );
};

export default ButtonBack;
