import classNames from 'classnames';
import React from 'react';

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'warning' | 'pages';
export type ButtonSize = 'extra-large' | 'large' | 'normal' | 'small' | 'extra-small';
interface ButtonMainProps {
    readonly className?: string;
    readonly type?: 'button' | 'submit';
    readonly style?: ButtonStyle;
    readonly size?: ButtonSize;
    readonly width?: string;
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly disabled?: boolean;
    readonly children?: React.ReactNode;
}

export const ButtonMain: React.FC<ButtonMainProps> = ({
    className,
    type = 'button',
    style = 'primary',
    size = 'normal',
    width = '',
    onClick,
    onMouseOver,
    onMouseLeave,
    children,
    disabled = false
}) => {
    const combinedClassName = classNames(
        className,
        'text-center border rounded-md px-3',
        width && `w-${width}`,
        style === 'primary'
            ? 'bg-primary text-white hover:bg-primary-dark'
            : style === 'secondary'
            ? 'bg-grey-lighter hover:bg-grey-light hover:text-white'
            : style === 'neutral'
            ? 'border-grey-light text-grey hover:bg-grey-lightest hover:border-light'
            : style === 'warning'
            ? 'bg-warning text-white hover:bg-grey'
            : style === 'pages'
            ? 'bg-green text-white hover:bg-grey'
            : 'border-blue-100 bg-blue-100 text-blue hover:bg-blue hover:border-blue hover:text-white',
        size === 'extra-large'
            ? 'text-lg font-normal h-14'
            : size === 'large'
            ? 'text-lg font-normal h-12'
            : size === 'small'
            ? 'text-sm font-normal h-9'
            : size === 'extra-small'
            ? 'text-sm font-normal h-7'
            : 'text-base font-normal h-10'
    );

    return (
        <button
            type={type}
            className={combinedClassName}
            onClick={onClick && ((event) => onClick(event))}
            onMouseOver={onMouseOver && ((event) => onMouseOver(event))}
            onMouseLeave={onMouseLeave && ((event) => onMouseLeave(event))}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

interface LinkButtonMainProps {
    readonly href: string;
    readonly className?: string;
    readonly style?: ButtonStyle;
    readonly size?: ButtonSize;
    readonly width?: string;
    readonly onMouseOver?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    readonly onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    readonly target?: '_blank';
    readonly rel?: 'noreferrer';
    readonly children?: React.ReactNode;
}

export const LinkButtonMain: React.FC<LinkButtonMainProps> = ({
    className,
    href,
    style = 'primary',
    size = 'normal',
    width = '',
    onMouseOver,
    onMouseLeave,
    target,
    rel,
    children
}) => {
    const combinedClassName = classNames(
        className,
        'text-center border rounded-md px-3 flex items-center justify-center',
        width && `w-${width}`,
        style === 'primary'
            ? 'bg-primary text-white hover:bg-primary-dark'
            : style === 'secondary'
            ? 'bg-grey-lighter hover:bg-grey-light hover:text-white'
            : style === 'neutral'
            ? 'border-grey-light text-grey hover:bg-grey-lightest hover:border-light'
            : style === 'warning'
            ? 'bg-warning text-white hover:bg-grey'
            : 'border-blue-100 bg-blue-100 text-blue hover:bg-blue hover:border-blue hover:text-white',
        size === 'extra-large'
            ? 'text-lg font-normal h-14'
            : size === 'large'
            ? 'text-lg font-normal h-12'
            : size === 'small'
            ? 'text-sm font-normal h-9'
            : size === 'extra-small'
            ? 'text-sm font-normal h-7'
            : 'text-base font-normal h-10'
    );

    return (
        <a
            href={href}
            className={combinedClassName}
            onMouseOver={onMouseOver && ((event) => onMouseOver(event))}
            onMouseLeave={onMouseLeave && ((event) => onMouseLeave(event))}
            target={target}
            rel={rel}
        >
            {children}
        </a>
    );
};
