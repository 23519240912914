import React from 'react';
import ScrollLock from 'react-scrolllock';

import CloseIcon from '../assets/icons/close.svg';
import { useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';
import { SearchInput } from './SearchInput';

interface DropdownContentMobileProps {
    readonly label: string;
    readonly setOpen: (state: boolean) => void;
    readonly isMultiSelect?: boolean;
    readonly searchTerm: string;
    readonly setSearchTerm: (state: string) => void;
    readonly closeOnSelect?: () => void;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptions: string[];
    readonly onSelectOption: (option: string) => void;
}

const DropdownContentMobile: React.FC<DropdownContentMobileProps> = ({
    label,
    setOpen,
    isMultiSelect = false,
    searchTerm,
    setSearchTerm,
    closeOnSelect,
    options,
    selectedOptions,
    onSelectOption
}) => {
    const [startFadeIn, setStartFadeIn] = React.useState(false);

    React.useEffect(() => {
        setStartFadeIn(true);
    }, []);

    return (
        <>
            <div
                className="absolute w-full h-full top-0 left-0 z-40 bg-grey-light/60"
                onClick={() => setOpen(false)}
            />
            <div
                className={`absolute w-full h-76 top-0 left-0 text-sm rounded-md shadow-md px-3 pb-2 bg-white border border-grey-lighter z-50 transition ease-in duration-150 ${
                    startFadeIn ? 'translate-y-0' : '-translate-y-full'
                }`}
            >
                <button
                    className="flex sm:hidden w-full mt-2 pt-3 pr-1 mb-6 justify-between"
                    onClick={() => setOpen(false)}
                >
                    <p className="font-semibold text-grey">{`Select ${label}s To Filter By`}</p>
                    <img src={CloseIcon} alt="close icon" className="h-5 w-5" />
                </button>
                <>
                    <div className="mt-3">
                        <SearchInput
                            searchTermState={searchTerm}
                            setSearchTermState={setSearchTerm}
                            onClearSearch={() => setSearchTerm('')}
                            placeholderText="search..."
                            width="full"
                            isCollapsible={false}
                            isRounded
                        />
                    </div>
                    <ScrollLock>
                        <ul className="mt-4 max-h-44 w-full overflow-y-scroll overflow-x-hidden">
                            {options
                                .filter((campaign) =>
                                    campaign.displayLabel
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                )
                                .map((option) => (
                                    <li className="mr-4" key={option.value}>
                                        <div
                                            className={`p-1 mt-2 mr-3 rounded-sm hover:text-grey border border-white hover:border-grey-light w-full transition duration-100 min-w-0 ${
                                                selectedOptions?.includes(option.value)
                                                    ? 'border border-grey-medium text-grey'
                                                    : 'border white text-grey-medium'
                                            }`}
                                        >
                                            <button
                                                className="flex items-center w-full"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    closeOnSelect && closeOnSelect();
                                                    onSelectOption(option.value);
                                                }}
                                            >
                                                {isMultiSelect && (
                                                    <span
                                                        className={`h-4 w-4 border border-grey-lighter rounded-sm ${
                                                            selectedOptions.includes(option.value)
                                                                ? 'bg-grey'
                                                                : 'bg-white'
                                                        }`}
                                                    />
                                                )}
                                                <div className="text-left ml-3 min-w-0 overflow-x-hidden">
                                                    <p className="truncate">
                                                        {option.displayLabel}
                                                    </p>
                                                    {option.secondaryDisplayLabel && (
                                                        <p className="text-xs truncate">
                                                            {option.secondaryDisplayLabel}
                                                        </p>
                                                    )}
                                                </div>
                                            </button>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </ScrollLock>
                </>
            </div>
        </>
    );
};

interface DropdownContentProps {
    readonly isMultiSelect?: boolean;
    readonly searchTerm: string;
    readonly setSearchTerm: (state: string) => void;
    readonly closeOnSelect?: () => void;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptions: string[];
    readonly onSelectOption: (option: string) => void;
}

const DropdownContent: React.FC<DropdownContentProps> = ({
    isMultiSelect = false,
    searchTerm,
    setSearchTerm,
    closeOnSelect,
    options,
    selectedOptions,
    onSelectOption
}) => {
    return (
        <>
            <div className="mt-3">
                <SearchInput
                    searchTermState={searchTerm}
                    setSearchTermState={setSearchTerm}
                    onClearSearch={() => setSearchTerm('')}
                    placeholderText="search..."
                    width="full"
                    isCollapsible={false}
                    isRounded
                />
            </div>
            <ul className="mt-4 max-h-56 w-full overflow-y-scroll overflow-x-hidden">
                {options
                    .filter((campaign) =>
                        campaign.displayLabel.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((option) => (
                        <li className="mr-4" key={option.value}>
                            <div
                                className={`p-1 mt-2 mr-3 rounded-sm hover:text-grey border border-white hover:border-grey-light w-full transition duration-100 min-w-0 ${
                                    selectedOptions?.includes(option.value)
                                        ? 'border border-grey-medium text-grey'
                                        : 'border white text-grey-medium'
                                }`}
                            >
                                <button
                                    className="flex items-center w-full"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        closeOnSelect && closeOnSelect();
                                        onSelectOption(option.value);
                                    }}
                                >
                                    {isMultiSelect && (
                                        <span
                                            className={`h-4 w-4 border border-grey-lighter rounded-sm ${
                                                selectedOptions.includes(option.value)
                                                    ? 'bg-grey'
                                                    : 'bg-white'
                                            }`}
                                        />
                                    )}
                                    <div className="text-left ml-3 min-w-0 overflow-x-hidden">
                                        <p className="truncate">{option.displayLabel}</p>
                                        {option.secondaryDisplayLabel && (
                                            <p className="text-xs truncate">
                                                {option.secondaryDisplayLabel}
                                            </p>
                                        )}
                                    </div>
                                </button>
                            </div>
                        </li>
                    ))}
            </ul>
        </>
    );
};

export interface FilterDropdownSelectOption {
    readonly displayLabel: string;
    readonly value: string;
    readonly secondaryDisplayLabel?: string;
}

interface FilterDropdownSelectProps {
    readonly label: string;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptionId: string;
    readonly onSelectOption: (optionId: string) => void;
    readonly openReverse?: boolean;
}

export const FilterDropdownSelect: React.FC<FilterDropdownSelectProps> = ({
    label,
    options,
    selectedOptionId,
    onSelectOption,
    openReverse = false
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isMobile = useIsMobileScreenSize();

    return (
        <div
            className={`${
                isMobile ? 'static' : 'relative'
            } w-full h-full py-1 px-2 text-sm border border-grey-lighter rounded-full text-center ${
                selectedOptionId ? 'bg-grey text-white' : 'bg-grey-lightest text-grey'
            } bg-white`}
            ref={dropdownContentRef}
        >
            <button
                className="max-w-xxs overflow-hidden"
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(open ? false : true);
                }}
                disabled={!options || options.length === 0}
            >
                {selectedOptionId ? (
                    <p className="truncate">
                        {options.find((option) => option.value === selectedOptionId)?.displayLabel}
                    </p>
                ) : (
                    <p
                        className={`truncate ${
                            options.length > 0 ? 'text-grey' : 'text-grey-lighter'
                        }`}
                    >
                        {label}
                    </p>
                )}
            </button>
            {open &&
                (isMobile ? (
                    <DropdownContentMobile
                        label={label}
                        setOpen={setOpen}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        closeOnSelect={() => setOpen(false)}
                        options={options}
                        onSelectOption={onSelectOption}
                        selectedOptions={[selectedOptionId]}
                    />
                ) : (
                    <>
                        <div
                            className={`absolute w-72 max-h-76 ${
                                openReverse ? 'top-9 right-0' : 'top-9 left-0'
                            } text-sm rounded-md shadow-md px-3 pb-2 bg-white border border-grey-lighter z-50 `}
                        >
                            <DropdownContent
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                closeOnSelect={() => setOpen(false)}
                                options={options}
                                selectedOptions={[selectedOptionId]}
                                onSelectOption={onSelectOption}
                            />
                        </div>
                    </>
                ))}
        </div>
    );
};

interface FilterDropdownMultiSelectProps {
    readonly label: string;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptions: string[];
    readonly onSelectOption: (option: string) => void;
}

export const FilterDropdownMultiSelect: React.FC<FilterDropdownMultiSelectProps> = ({
    label,
    options,
    selectedOptions,
    onSelectOption
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isMobile = useIsMobileScreenSize();

    return (
        <div
            className={`static sm:relative py-1 px-2 text-sm border border-grey-lighter rounded-full text-center ${
                selectedOptions.length > 0 ? 'bg-grey text-white' : 'bg-white text-grey'
            } bg-white`}
            ref={dropdownContentRef}
        >
            <button
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(open ? false : true);
                }}
                disabled={!options || options.length === 0}
            >
                {selectedOptions.length > 0 ? (
                    <div className="flex">
                        {selectedOptions.length === 1 ? (
                            <span className="line-clamp-1">
                                {
                                    options.find((option) => selectedOptions.includes(option.value))
                                        ?.displayLabel
                                }
                            </span>
                        ) : (
                            <span className="ml-1 line-clamp-1">{`${label}: ${selectedOptions.length} Selected`}</span>
                        )}
                    </div>
                ) : (
                    <span
                        className={`line-clamp-1 ${
                            options.length > 0 ? 'text-grey' : 'text-grey-lighter'
                        }`}
                    >
                        {label}
                    </span>
                )}
            </button>
            {open &&
                (isMobile ? (
                    <DropdownContentMobile
                        label={label}
                        setOpen={setOpen}
                        isMultiSelect
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        options={options}
                        selectedOptions={selectedOptions}
                        onSelectOption={onSelectOption}
                    />
                ) : (
                    <>
                        <div
                            className={`absolute w-72 h-76 top-9 left-0 text-sm rounded-md shadow-md px-3 pb-2 bg-white border border-grey-lighter z-50 `}
                        >
                            <DropdownContent
                                isMultiSelect
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                options={options}
                                selectedOptions={selectedOptions}
                                onSelectOption={onSelectOption}
                            />
                        </div>
                    </>
                ))}
        </div>
    );
};
