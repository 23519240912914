import React from 'react';
import DropdownIcon from '../assets/icons/dropdown-down.svg';

interface WorkspaceTeamMembersDropdownProps {
    readonly numberOfTeamMembersString: string;
    readonly options: { readonly id: string; readonly name: string }[];
    readonly onSelectOption: (option: string) => void;
}

export const WorkspaceTeamMembersDropdown: React.FC<WorkspaceTeamMembersDropdownProps> = ({
    numberOfTeamMembersString,
    options,
    onSelectOption
}) => {
    const [open, setOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className="relative py-2 px-4 border w-full rounded-l-md border-grey-lighter bg-white"
            ref={containerRef}
        >
            <button
                className={`flex w-full items-center justify-between text-sm h-6`}
                onClick={() => setOpen(open ? false : true)}
            >
                <span>{numberOfTeamMembersString}</span>
                <div className="ml-2">
                    {open ? (
                        <div className="rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="h-2 w-2" src={DropdownIcon} />
                    )}
                </div>
            </button>
            {open && (
                <ul className="absolute top-11 left-0 text-sm rounded-md shadow-md px-3 pb-2 w-2/3 bg-white border border-grey-lighter z-50  max-h-40 overflow-y-scroll">
                    {options.map((option) => (
                        <li className=" bg-white rounded-md" key={option.id}>
                            <button
                                className={`w-full p-1 text-left bg-white`}
                                onClick={() => {
                                    onSelectOption(option.id);
                                    setOpen(false);
                                }}
                            >
                                {option.name}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
