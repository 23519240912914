import React from 'react';
import WhiteSquare from '../assets/images/white-square.png';

interface PageLinkButtonProps {
    readonly textColor: string;
    readonly backgroundColor: string;
    readonly href: string;
    readonly children: React.ReactNode;
}

export const PageLinkButton: React.FC<PageLinkButtonProps> = ({
    textColor,
    backgroundColor,
    href,
    children
}) => {
    return (
        <a
            style={{ background: backgroundColor, color: textColor }}
            className="w-auto py-1 px-2 rounded-full hover:bg-grey-lighter hover:text-grey text-sm"
            href={href}
        >
            {children}
        </a>
    );
};

interface PageProps {
    readonly name: string;
    readonly titleText: string;
    readonly imageUrl: string;
    readonly facebookProfileUrl: string;
    readonly instagramProfileUrl: string;
    readonly twitterProfileUrl: string;
    readonly linkedinProfileUrl: string;
    readonly backgroundColor: string;
    readonly textColor: string;
    readonly linksTextColor: string;
    readonly linksButtonColor: string;
    readonly link1Text: string;
    readonly link1Url: string;
    readonly link2Text: string;
    readonly link2Url: string;
    readonly link3Text: string;
    readonly link3Url: string;
}

export const Page: React.FC<PageProps> = ({
    titleText,
    imageUrl,
    facebookProfileUrl,
    instagramProfileUrl,
    twitterProfileUrl,
    linkedinProfileUrl,
    backgroundColor,
    textColor,
    linksTextColor,
    linksButtonColor,
    link1Text,
    link1Url,
    link2Text,
    link2Url,
    link3Text,
    link3Url
}) => {
    return (
        <div style={{ background: backgroundColor }} className="h-full w-full font-poppins p-12">
            <div className="w-full flex item-center justify-center">
                <img
                    src={imageUrl || WhiteSquare}
                    alt="header image"
                    className="h-18 w-18 rounded-full border border-grey-lighter"
                />
            </div>
            <div className="mt-6 flex justify-center">
                <h1 style={{ color: textColor }} className="text-lg font-semibold">
                    {titleText}
                </h1>
            </div>
            <ul className="mt-8 flex justify-center">
                <li>
                    <a href={facebookProfileUrl}>
                        <svg
                            className="h-6 w-6 hover:scale-125 transition-all duration-200"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.0003 2.28418H10.0003C9.11627 2.28418 8.26842 2.63537 7.6433 3.26049C7.01818 3.88561 6.66699 4.73346 6.66699 5.61751V7.61751H4.66699V10.2842H6.66699V15.6175H9.33366V10.2842H11.3337L12.0003 7.61751H9.33366V5.61751C9.33366 5.4407 9.4039 5.27113 9.52892 5.14611C9.65394 5.02108 9.82351 4.95085 10.0003 4.95085H12.0003V2.28418Z"
                                fill={textColor}
                            />
                        </svg>
                    </a>
                </li>
                <li className="ml-3">
                    <a href={instagramProfileUrl}>
                        <svg
                            className="h-6 w-6 hover:scale-125 transition-all duration-200"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.5 1.02148H4.5C2.567 1.02148 1 2.58849 1 4.52148V11.5215C1 13.4545 2.567 15.0215 4.5 15.0215H11.5C13.433 15.0215 15 13.4545 15 11.5215V4.52148C15 2.58849 13.433 1.02148 11.5 1.02148ZM12.7106 3C12.3181 3 12 3.31814 12 3.71058C12 4.10303 12.3181 4.42117 12.7106 4.42117H12.7229C13.1153 4.42117 13.4335 4.10303 13.4335 3.71058C13.4335 3.31814 13.1153 3 12.7229 3H12.7106ZM12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                fill={textColor}
                            />
                        </svg>
                    </a>
                </li>
                <li className="ml-3">
                    <a href={twitterProfileUrl}>
                        <svg
                            className="h-6 w-6 hover:scale-125 transition-all duration-200"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.3337 2.95104C14.6952 3.40136 13.9884 3.74578 13.2403 3.97104C12.8388 3.50938 12.3052 3.18217 11.7117 3.03366C11.1181 2.88515 10.4933 2.9225 9.92171 3.14068C9.3501 3.35885 8.85929 3.74731 8.51565 4.25352C8.17201 4.75973 7.99212 5.35926 8.00032 5.97104V6.63771C6.82875 6.66809 5.66784 6.40825 4.621 5.88134C3.57415 5.35443 2.67387 4.5768 2.00033 3.61771C2.00033 3.61771 -0.666341 9.61771 5.33366 12.2844C3.96068 13.2164 2.3251 13.6837 0.666992 13.6177C6.66699 16.951 14.0003 13.6177 14.0003 5.95104C13.9997 5.76534 13.9819 5.5801 13.947 5.39771C14.6274 4.7267 15.1075 3.87951 15.3337 2.95104Z"
                                fill={textColor}
                            />
                        </svg>
                    </a>
                </li>
                <li className="ml-3">
                    <a href={linkedinProfileUrl}>
                        <svg
                            className="h-6 w-6 hover:scale-125 transition-all duration-200"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.99967 6.95117H1.33301V14.9512H3.99967V6.95117Z"
                                fill={textColor}
                            />
                            <path
                                d="M10.667 6.28418C11.7279 6.28418 12.7453 6.70561 13.4954 7.45575C14.2456 8.2059 14.667 9.22331 14.667 10.2842V14.9508H12.0003V10.2842C12.0003 9.93056 11.8598 9.59142 11.6098 9.34137C11.3598 9.09132 11.0206 8.95085 10.667 8.95085C10.3134 8.95085 9.97423 9.09132 9.72418 9.34137C9.47413 9.59142 9.33366 9.93056 9.33366 10.2842V14.9508H6.66699V10.2842C6.66699 9.22331 7.08842 8.2059 7.83857 7.45575C8.58871 6.70561 9.60613 6.28418 10.667 6.28418Z"
                                fill={textColor}
                            />
                            <path
                                d="M2.66634 4.95085C3.40272 4.95085 3.99967 4.35389 3.99967 3.61751C3.99967 2.88113 3.40272 2.28418 2.66634 2.28418C1.92996 2.28418 1.33301 2.88113 1.33301 3.61751C1.33301 4.35389 1.92996 4.95085 2.66634 4.95085Z"
                                fill={textColor}
                            />
                        </svg>
                    </a>
                </li>
            </ul>
            <div className="mt-10 grid grid-cols-1 gap-2">
                {link1Text && (
                    <div className="flex justify-center">
                        <PageLinkButton
                            textColor={linksTextColor}
                            backgroundColor={linksButtonColor}
                            href={link1Url}
                        >
                            {link1Text}
                        </PageLinkButton>
                    </div>
                )}
                {link2Text && (
                    <div className="flex justify-center">
                        <PageLinkButton
                            textColor={linksTextColor}
                            backgroundColor={linksButtonColor}
                            href={link2Url}
                        >
                            {link2Text}
                        </PageLinkButton>
                    </div>
                )}
                {link3Text && (
                    <div className="flex justify-center">
                        <PageLinkButton
                            textColor={linksTextColor}
                            backgroundColor={linksButtonColor}
                            href={link3Url}
                        >
                            {link3Text}
                        </PageLinkButton>
                    </div>
                )}
            </div>
        </div>
    );
};
