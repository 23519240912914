import { PaginatedCampaignList, PaginatedLabelList } from '../api';
import { DropDownOption } from '../components/DropdownSelect';

export interface CampaignsListItem extends Record<string, string | number | boolean> {
    readonly name: string;
    readonly numberOfSnips: number;
    readonly numberOfViews: number;
    readonly numberOfClicks: number;
    readonly createdDate: string;
    readonly archived: boolean;
}

export function createCampaignsList(response: PaginatedCampaignList): CampaignsListItem[] {
    if (!response) {
        return [];
    }

    return response.results?.map((campaignResult) => ({
        name: campaignResult.name,
        numberOfSnips: campaignResult.cache_link_count,
        numberOfViews: campaignResult.cache_view_count,
        numberOfClicks: campaignResult.cache_click_count,
        createdDate: campaignResult.date_created.slice(0, 10) ?? '',
        archived: campaignResult.archived ?? false
    }));
}

export function createCampaignsListForDropdown(response: PaginatedLabelList): DropDownOption[] {
    if (!response) {
        return [];
    }

    return response.results?.map((result) => ({
        value: result.id,
        displayLabel: result.name
    }));
}
