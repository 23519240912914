import React from 'react';

import LockIcon from '../assets/icons/lock.svg';
import DropdownIcon from '../assets/icons/dropdown-down.svg';
import { UpgradePlanMessageContext } from '../UpgradePlanMessageContextProvider';
import { UserContext } from '../UserContextProvider';
import { CtaIconByType } from './icons/CtaIconByType';

export type CtaType = 'button' | 'text-link' | 'form' | 'image-ad';

interface CtaTypeOption {
    readonly id: CtaType;
    readonly displayText: string;
    readonly additionalDisplayText: string;
}

interface CtaTypeDropdownSelectProps {
    readonly titleText: string;
    readonly selectedCtaType: CtaType;
    readonly setSelectedCtaType: (selectedCtaType: CtaType) => void;
    readonly squeeze?: boolean;
}

const CTA_TYPE_OPTIONS: CtaTypeOption[] = [
    {
        id: 'button',
        displayText: 'Button',
        additionalDisplayText: 'Show a button CTA'
    },
    {
        id: 'text-link',
        displayText: 'Text Link',
        additionalDisplayText: 'Show a link CTA'
    },
    {
        id: 'form',
        displayText: 'Form',
        additionalDisplayText: 'Contact email addresses'
    },
    {
        id: 'image-ad',
        displayText: 'Image Ad',
        additionalDisplayText: 'Upload your own image'
    }
];

const CtaTypeDropdownSelectOption: React.FC<{ readonly option: CtaTypeOption }> = ({ option }) => (
    <div className="flex items-center">
        <CtaIconByType ctaType={option.id} />
        <div className="ml-5 flex flex-col items-start">
            <p>{option.displayText}</p>
            <p className="text-sm text-grey-light">{option.additionalDisplayText}</p>
        </div>
    </div>
);

export const CtaTypeDropdownSelect: React.FC<CtaTypeDropdownSelectProps> = ({
    titleText,
    selectedCtaType,
    setSelectedCtaType,
    squeeze = false
}) => {
    const [open, setOpen] = React.useState(false);
    const dropdownContentRef = React.useRef(null);

    const { canUseImageAdCta, minimumImageAdPlan } = React.useContext(UserContext);
    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedOption = CTA_TYPE_OPTIONS.find((option) => option.id === selectedCtaType);

    return (
        <div>
            <p className="text-sm"> {titleText}</p>
            <div
                className={`z-1 mt-1 relative border border-grey-lighter bg-white rounded-md ${
                    squeeze ? 'mx-2' : 'mx-0'
                }`}
            >
                <button
                    className="flex items-center justify-between py-0 w-full p-3 text-sm"
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                >
                    {selectedCtaType ? (
                        <div className="p-1 rounded-md text-grey w-full">
                            <CtaTypeDropdownSelectOption option={selectedOption} />
                        </div>
                    ) : (
                        <p className="text-grey-light py-2">select call-to-action type</p>
                    )}
                    {open ? (
                        <div className=" rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="h-2 w-2" src={DropdownIcon} />
                    )}
                </button>
                {open && (
                    <div
                        ref={dropdownContentRef}
                        className="absolute text-sm px-3 pb-2 w-full bg-white border border-grey-lighter z-50"
                    >
                        <ul>
                            {CTA_TYPE_OPTIONS.filter((option) => option.id !== selectedCtaType).map(
                                (option) => (
                                    <li className="mt-1 rounded-md w-full" key={option.id}>
                                        {option.id === 'image-ad' && !canUseImageAdCta ? (
                                            <button
                                                className="w-full text-grey-light"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setSelectedCtaType(option.id);
                                                    setOpen(false);

                                                    handleOpenUpgradePlanMessage({
                                                        feature: 'Image Call-To-Action',
                                                        requiredPlan: minimumImageAdPlan
                                                    });
                                                }}
                                            >
                                                <div className="mt-2 flex items-center justify-between">
                                                    <CtaTypeDropdownSelectOption option={option} />
                                                    <img
                                                        src={LockIcon}
                                                        alt="lock icon"
                                                        className="ml-2 w-5 h-5"
                                                    />
                                                </div>
                                            </button>
                                        ) : (
                                            <button
                                                className="w-full text-grey"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setSelectedCtaType(option.id);
                                                    setOpen(false);
                                                }}
                                            >
                                                <div className="mt-2">
                                                    <CtaTypeDropdownSelectOption option={option} />
                                                </div>
                                            </button>
                                        )}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
