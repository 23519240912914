import React from 'react';

import ArrowIcon from './assets/icons/arrow.svg';
import CopyIconWhite from './assets/icons/copy-white.svg';
import CopyIcon from './assets/icons/copy.svg';
import FeedImage from './assets/images/feed.svg';
import BufferLogo from './assets/images/Integrations/buffer.svg';
import DlvritLogo from './assets/images/Integrations/dlvrit.svg';
import FeedlyLogo from './assets/images/Integrations/feedly.svg';
import HotsuiteLogo from './assets/images/Integrations/hotsuite.svg';
import IftttLogo from './assets/images/Integrations/ifttt.svg';
import ZapierLogo from './assets/images/Integrations/zapier.svg';
// import ShareIcon from './assets/icons/share.svg';
import { ButtonMain } from './components/ButtonMain';
import { NotificationContext } from './NotificationContextProvider';
import { RouteLink } from './Routing';

interface FeedIntegrationButtonProps {
    readonly children?: React.ReactNode;
}

const FeedIntegrationButton: React.FC<FeedIntegrationButtonProps> = ({ children }) => (
    <button className="flex items-center justify-center border border-grey-light rounded-md bg-grey-tint p-2 h-8 w-24  xs:h-10 xs:w-30">
        {children}
    </button>
);

interface IntegrationsRssFeedsCompletedProps {
    readonly feedSnipUrl: string;
    readonly onStartCreateNewFeed: () => void;
}

export const IntegrationsRssFeedsCompleted: React.FC<IntegrationsRssFeedsCompletedProps> = ({
    feedSnipUrl,
    onStartCreateNewFeed
}) => {
    const { handleOpenNotification } = React.useContext(NotificationContext);

    return (
        <div className="p-4 flex flex-col items-center">
            <img className="h-30 w-30" src={FeedImage} />
            <p className="mt-4 text-grey-light">
                Any RSS-integrating software can use this feed to share Sniply links.
            </p>
            <div className="mt-6 flex items-center flex-wrap justify-center">
                <p className="px-2 xs:px-4 py-1 xs:py-2 border border-grey-light rounded-md align-middle text-xs break-words">
                    {feedSnipUrl}
                </p>
                <button
                    className="ml-4 p-2"
                    onClick={(event) => {
                        event.preventDefault();

                        navigator.clipboard.writeText(feedSnipUrl);

                        handleOpenNotification({
                            messageText: 'Feed URL successfully copied.',
                            type: 'success',
                            iconSrc: CopyIconWhite,
                            showTimeInSeconds: 3
                        });
                    }}
                >
                    <img className="h-4 w-4" src={CopyIcon} />
                </button>
            </div>
            <p className="mt-8 xs:mt-12 text-grey-light">Use feed with</p>
            <div className="mt-6 grid grid-cols-2 sm:grid-cols-3 gap-1">
                <FeedIntegrationButton>
                    <img src={BufferLogo} className="w-16" />
                </FeedIntegrationButton>
                <FeedIntegrationButton>
                    <img src={HotsuiteLogo} className="w-16" />
                </FeedIntegrationButton>
                <FeedIntegrationButton>
                    <img src={FeedlyLogo} className="w-16" />
                </FeedIntegrationButton>
                <FeedIntegrationButton>
                    <img src={DlvritLogo} className="w-16" />
                </FeedIntegrationButton>
                <FeedIntegrationButton>
                    <img src={ZapierLogo} className="w-16" />
                </FeedIntegrationButton>
                <FeedIntegrationButton>
                    <img src={IftttLogo} className="w-16" />
                </FeedIntegrationButton>
            </div>

            <div className="flex mt-12">
                <RouteLink href="/integrations/rss-feeds">
                    <ButtonMain className="flex items-center text-xs xs:text-base" style="neutral">
                        <img className="h-4 w-4" src={ArrowIcon} />
                        View All Feeds
                    </ButtonMain>
                </RouteLink>

                <ButtonMain
                    onClick={onStartCreateNewFeed}
                    className="ml-4  text-xs xs:text-base"
                    style="neutral"
                >
                    + Create New Feed
                </ButtonMain>
            </div>
        </div>
    );
};
