import React from 'react';

import DeleteIcon from './assets/icons/delete.svg';
import EditIcon from './assets/icons/edit.svg';
import VerifyIcon from './assets/icons/repeat.svg';

import MenuBulletsIcon from './assets/icons/menu-bullets-horizontal.svg';
import { CustomDomainsListItem } from './data-transformations/create-custom-domains-list';

interface EditRowButtonDropdownOptionProps {
    readonly labelText: string;
    readonly iconSrc: string;
    readonly onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly marginTop?: string;
}

export const EditRowButtonDropdownOption: React.FC<EditRowButtonDropdownOptionProps> = ({
    labelText,
    iconSrc,
    onClick,
    marginTop
}) => (
    <li className={`mt-${marginTop}`}>
        <button className="flex items-center" onClick={onClick}>
            <img src={iconSrc} alt="icon" className="w-4 h-4" />
            <span className="ml-2">{labelText}</span>
        </button>
    </li>
);

interface CustomDomainsEditRowButtonDropdownProps {
    readonly customDomain: CustomDomainsListItem;
    readonly onEditCustomDomainClick: (
        event: React.MouseEvent<HTMLButtonElement>,
        customDomain: CustomDomainsListItem
    ) => void;
    readonly onDeleteDomainClick: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
    readonly onVerifyDomainClick: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
}

export const CustomDomainsEditRowButtonDropdown: React.FC<
    CustomDomainsEditRowButtonDropdownProps
> = ({ customDomain, onEditCustomDomainClick, onVerifyDomainClick, onDeleteDomainClick }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end my-2" ref={containerRef}>
            <button onClick={() => setIsMenuOpen(isMenuOpen ? false : true)}>
                <img className="h-4 w-4" src={MenuBulletsIcon} />
            </button>
            {isMenuOpen && (
                <ul className="z-1 absolute top-4 -right-2 p-2 text-sm bg-white border border-grey-lightest rounded-md w-32 shadow-lg">
                    <EditRowButtonDropdownOption
                        labelText="View / Edit"
                        iconSrc={EditIcon}
                        onClick={(event) => {
                            event.preventDefault();
                            setIsMenuOpen(false);
                            onEditCustomDomainClick(event, customDomain);
                        }}
                    />
                    <EditRowButtonDropdownOption
                        labelText="Verify"
                        iconSrc={VerifyIcon}
                        onClick={(event) => {
                            setIsMenuOpen(false);
                            onVerifyDomainClick(event, customDomain.id);
                        }}
                        marginTop="2"
                    />
                    <EditRowButtonDropdownOption
                        labelText="Delete"
                        iconSrc={DeleteIcon}
                        onClick={(event) => {
                            setIsMenuOpen(false);
                            onDeleteDomainClick(event, customDomain.id);
                        }}
                        marginTop="2"
                    />
                </ul>
            )}
        </div>
    );
};
