/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BufferCredentials } from '../models/BufferCredentials';
import type { BufferProfiles } from '../models/BufferProfiles';
import type { BufferSetProfile } from '../models/BufferSetProfile';
import type { BufferSetProfileRequest } from '../models/BufferSetProfileRequest';
import type { PatchedBufferSetProfileRequest } from '../models/PatchedBufferSetProfileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BufferService {

    /**
     * Buffer Credentials List View
     * @returns BufferCredentials
     * @throws ApiError
     */
    public static bufferCredentialsRetrieve(): CancelablePromise<BufferCredentials> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/buffer/credentials/',
        });
    }

    /**
     * Buffer Credentials Destroy View
     * @returns void
     * @throws ApiError
     */
    public static bufferCredentialsDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/buffer/credentials/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns BufferProfiles
     * @throws ApiError
     */
    public static bufferProfilesRetrieve(): CancelablePromise<BufferProfiles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/buffer/profiles/',
        });
    }

    /**
     * @returns BufferSetProfile
     * @throws ApiError
     */
    public static bufferProfilesSetUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: BufferSetProfileRequest,
    }): CancelablePromise<BufferSetProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/buffer/profiles/{id}/set',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BufferSetProfile
     * @throws ApiError
     */
    public static bufferProfilesSetPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedBufferSetProfileRequest,
    }): CancelablePromise<BufferSetProfile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/buffer/profiles/{id}/set',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
