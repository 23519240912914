import { PaginatedCTAList } from '../api';

export function createCtaLinkList(result: PaginatedCTAList): string[] {
    const urlList = [];

    for (const ctaData of result.results) {
        if (ctaData.button_action?.url && !urlList.includes(ctaData.button_action?.url)) {
            urlList.push(ctaData.button_action?.url);
        }

        if (ctaData.image_action?.url && !urlList.includes(ctaData.image_action?.url)) {
            urlList.push(ctaData.image_action?.url);
        }
    }
    return urlList;
}
