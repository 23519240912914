import React from 'react';

import { LinkClipboardService } from './api';
import ChevronIcon from './assets/icons/chevron-right.svg';
import HideIcon from './assets/icons/hide.svg';
import LinkArrowGreyIcon from './assets/icons/link-arrow-grey.svg';
import PlusIcon from './assets/icons/plus-plain.svg';
import SniplyIcon from './assets/icons/sniply-logo.svg';
import XIcon from './assets/icons/x.svg';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { EmptyTableView } from './components/EmptyTableView';
import { FormTextInput } from './components/FormTextInput';
import { ImageWithFallback } from './components/ImageWithFallback';
import { LoadingSpinner } from './components/LoadingSpinner';
import { VerticalSpacer } from './components/VerticalSpacer';
import {
    createClipboardItemData,
    CuratedContentItem
} from './data-transformations/create-clipboard-item-data';
import { navigateTo } from './Routing';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { WorkspaceContext } from './WorkspaceContextProvider';

export interface ClipboardCardProps {
    readonly contentItem: CuratedContentItem;
    readonly onRemoveItem: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
}

export const ClipboardCard: React.FC<ClipboardCardProps> = ({
    contentItem: { id, titleText, linkUrl, imageUrl, additionalText, faviconUrl, source },
    onRemoveItem
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className="flex mt-2 w-full">
            <button className="w-6" onClick={(event) => onRemoveItem(event, id)}>
                <img src={HideIcon} alt="hide icon" className="w-4 h-4" />
            </button>
            <div
                onClick={() => setIsOpen(isOpen ? false : true)}
                className="ml-2 w-full grid p-2 border border-grey-lighter rounded-md cursor-pointer"
            >
                {isOpen ? (
                    <div>
                        <div className="pb-2 w-full min-w-0 border-b border-grey-lighter">
                            <div className="min-w-0">
                                <div className="flex items-center justify-between">
                                    <p className={`text-left font-semibold text-sm line-clamp-1`}>
                                        {titleText ?? '(Title Not Available)'}
                                    </p>
                                    <img
                                        src={ChevronIcon}
                                        alt="chevron icon"
                                        className="ml-2 w-5 h-5 -rotate-90"
                                    />
                                </div>
                                {!titleText && (
                                    <p className="mt-1 text-left text-xs line-clamp-1">{linkUrl}</p>
                                )}
                                {(imageUrl || additionalText) && (
                                    <div className="my-3 flex">
                                        {imageUrl && (
                                            <img src={imageUrl} alt="image" className="mr-2 w-16" />
                                        )}
                                        <p className="text-left text-xs line-clamp-3 text-grey-medium">
                                            {additionalText ?? '...'}
                                        </p>
                                    </div>
                                )}
                                <div className="grid grid-cols-2 text-xs text-grey-medium py-2">
                                    {source && (
                                        <div className="flex items-center">
                                            <ImageWithFallback
                                                src={faviconUrl}
                                                alt="favicon"
                                                fallbackSrc={SniplyIcon}
                                                className="h-4 w-4"
                                            />
                                            <span className="ml-2 line-clamp-1">{source}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 grid grid-cols-2 items-center justify-center">
                            <a
                                className="flex items-center justify-center"
                                href={linkUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={LinkArrowGreyIcon} alt="link icon" className="w-4 h-4" />
                                <span className="ml-2 text-grey-light">View</span>
                            </a>
                            <ButtonMain
                                size="small"
                                onClick={() =>
                                    navigateTo(
                                        `/snip-create/content-select?clipboardItemUrl=${linkUrl}&clipboardItemId=${id}`
                                    )
                                }
                            >
                                Snip
                            </ButtonMain>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-between">
                        <p className={`text-left font-semibold text-sm line-clamp-1`}>
                            {titleText ?? '(Title Not Available)'}
                        </p>
                        <img
                            src={ChevronIcon}
                            alt="chevron icon"
                            className="ml-2 w-5 h-5 rotate-90"
                        />
                    </div>
                )}
            </div>
            {/* {!isOpen && (
                <ButtonMain
                    className="ml-2"
                    onClick={() =>
                        navigateTo(
                            `/snip-create/content-select?clipboardItemUrl=${linkUrl}&clipboardItemId=${id}`
                        )
                    }
                >
                    <img src={LinkArrowIcon} className="h-2 w-2" />
                </ButtonMain>
            )} */}
        </div>
    );
};

export const DashboardClipboard: React.FC = () => {
    const [savedContentItems, setSavedContentItems] = React.useState<CuratedContentItem[]>([]);
    const [isAddNewInputExpanded, setIsAddNewInputExpanded] = React.useState(false);
    const [newContentUrl, setNewContentUrl] = React.useState('');

    const [showValidationError, setShowValidationError] = React.useState(false);

    const [isLoadingContentItems, setIsLoadingContentItems] = React.useState(true);
    const [errorLoadingContentItems, setErrorLoadingContentItems] = React.useState(false);
    const [isSavingContentItem, setIsSavingContentItem] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const handleRemoveContentItem = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
            event.preventDefault();

            LinkClipboardService.linkClipboardDestroy({ id });
            setSavedContentItems(savedContentItems.filter((contentItem) => contentItem.id !== id));
        },
        [savedContentItems]
    );

    const handleSubmitNewContentUrl = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>, linkUrl: string) => {
            event.preventDefault();
            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(linkUrl))) {
                setShowValidationError(true);
            } else {
                setIsSavingContentItem(true);
                LinkClipboardService.linkClipboardCreate({
                    requestBody: { url: ensureUrlHasProtocol(linkUrl), brand: currentWorkspace.id }
                })
                    .then((result) => {
                        setSavedContentItems([
                            createClipboardItemData(result),
                            ...savedContentItems
                        ]);
                        setIsSavingContentItem(false);
                    })
                    .catch((error) => {
                        console.error(
                            'Error saving content item via LinkClipboardService.linkClipboardCreate()',
                            error
                        );
                        setIsSavingContentItem(false);
                    });

                setIsAddNewInputExpanded(false);
            }
        },
        [savedContentItems, currentWorkspace]
    );

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingContentItems(true);
            LinkClipboardService.linkClipboardList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('LinkClipboardService.linkClipboardList(): ', result);

                    setSavedContentItems(
                        result.map((clipboardItem) => createClipboardItemData(clipboardItem))
                    );
                    setIsLoadingContentItems(false);
                })
                .catch((error) => {
                    console.error(error);
                    setErrorLoadingContentItems(true);
                    setIsLoadingContentItems(false);
                });
        }
    }, [currentWorkspace]);

    React.useEffect(() => {
        showValidationError && setShowValidationError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newContentUrl]);

    return (
        <ContentElementContainer>
            <div className="h-full flex flex-col">
                <div className="flex items-center justify-between h-10">
                    <h3 className="font-semibold">Clipboard</h3>
                    <button
                        className="h-10 w-10 flex items-center justify-center"
                        onClick={(event) => {
                            event.preventDefault();
                            setIsAddNewInputExpanded(isAddNewInputExpanded ? false : true);
                        }}
                    >
                        {isSavingContentItem ? (
                            <LoadingSpinner size={5} />
                        ) : isAddNewInputExpanded ? (
                            <img src={XIcon} className="w-6 h-6" />
                        ) : (
                            <img src={PlusIcon} className="w-6 h-6" />
                        )}
                    </button>
                </div>
                <VerticalSpacer heightValue={3} />
                {isLoadingContentItems ? (
                    <div className="flex h-full w-full justify-center items-center">
                        <LoadingSpinner size={10} />
                    </div>
                ) : errorLoadingContentItems ? (
                    <p className="text-sm text-red">
                        We were unable to retrieve your saved content items at this moment due to an
                        unexpected error. Please try again by refreshing your browser.
                    </p>
                ) : savedContentItems.length ? (
                    <div className="flex flex-col">
                        <div>
                            {isAddNewInputExpanded ? (
                                <form
                                    className="mb-3 py-3"
                                    onSubmit={(event) =>
                                        handleSubmitNewContentUrl(event, newContentUrl)
                                    }
                                >
                                    <div className="flex items-center">
                                        <FormTextInput
                                            id="content-url-input"
                                            placeholder="example.com/some-article"
                                            value={newContentUrl}
                                            onChange={(event) =>
                                                setNewContentUrl(event.target.value)
                                            }
                                            righHandComponent={
                                                newContentUrl && (
                                                    <ButtonMain
                                                        style="secondary"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setNewContentUrl('');
                                                        }}
                                                    >
                                                        Clear
                                                    </ButtonMain>
                                                )
                                            }
                                            autoFocus
                                        />
                                    </div>
                                    {showValidationError && (
                                        <p className="p-2 text-red text-sm">
                                            Please provide a valid Url.
                                        </p>
                                    )}
                                    {newContentUrl && (
                                        <ButtonMain
                                            className="mt-3"
                                            width="full"
                                            type="submit"
                                            size="small"
                                        >
                                            Save
                                        </ButtonMain>
                                    )}
                                </form>
                            ) : (
                                <ul className="max-h-72 overflow-y-scroll flex flex-col">
                                    {savedContentItems.map((contentItem) => (
                                        <ClipboardCard
                                            key={contentItem.id}
                                            contentItem={contentItem}
                                            onRemoveItem={handleRemoveContentItem}
                                        />
                                    ))}
                                </ul>
                            )}
                        </div>
                        {!isAddNewInputExpanded && (
                            <button
                                className="mt-6 text-primary w-full text-center hover:underline"
                                onClick={() => navigateTo('/clipboard')}
                            >
                                View All Saved Content
                            </button>
                        )}
                    </div>
                ) : (
                    <EmptyTableView
                        headlineText="You do not have any saved content"
                        sublineText="Once you do, you will see the overview of your latest saved content
                            here."
                    />
                )}
            </div>
        </ContentElementContainer>
    );
};
