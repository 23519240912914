import { GraphStats } from '../api/models/GraphStats';
import { sortTableRowDataBasedOnColumnId } from '../utils/sort-table-row-data-based-on-column-id';

export interface ReportingGraphEntry {
    readonly 'Date': string;
    readonly 'Clicks': number;
    readonly 'Conversions': number;
    readonly 'Rate (%)': number;
}

export type ReportingGraphStats = ReportingGraphEntry[];

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export function createReportingGraphData(result: GraphStats[]): ReportingGraphStats {
    const resultSortedByDate = sortTableRowDataBasedOnColumnId(result, 'date', 'ascending');

    return resultSortedByDate.map((graphEntry) => {
        const date = new Date(graphEntry.date);
        const dateString = `${monthNames[date.getMonth()].slice(0, 3)} ${date
            .getDate()
            .toLocaleString()}`;

        return {
            'Date': dateString,
            'Clicks': graphEntry.clicks,
            'Conversions': graphEntry.conversions,
            'Rate (%)': graphEntry.conversion_rate
        };
    });
}
