import React from 'react';
import { Tab, Tabs } from './components/shared/Tabs';
import { IntegrationsAppDirectory } from './IntegrationsAppDirectory';
import { IntegrationsBuffer } from './IntegrationsBuffer';
import { IntegrationsEmbedOnWebsite } from './IntegrationsEmbedOnWebsite';
import { IntegrationsRssFeeds } from './IntegrationsRssFeeds';
import { VerticalSpacer } from './components/VerticalSpacer';
import { DropdownTabs } from './components/DropdownTabs';
import { RouteLink, useCurrentUrlPath } from './Routing';
import { useElementWidth } from './hooks/use-element-width';

export type IntegrationsView = 'app-directory' | 'rss-feeds' | 'embed-on-website' | 'buffer';

function renderIntegrationsViewContent(integrationsView: IntegrationsView): JSX.Element {
    switch (integrationsView) {
        case 'app-directory':
            return <IntegrationsAppDirectory />;
        case 'rss-feeds':
            return <IntegrationsRssFeeds />;
        case 'embed-on-website':
            return <IntegrationsEmbedOnWebsite />;
        case 'buffer':
            return <IntegrationsBuffer />;
        default:
            return <IntegrationsAppDirectory />;
    }
}

export const Integrations: React.FC = () => {
    const tabView: IntegrationsView =
        (useCurrentUrlPath()?.level2 as IntegrationsView) ?? 'app-directory';

    const integrationsSectionContainerRef = React.useRef(undefined);
    const integrationsSectionContainerWidth = useElementWidth(integrationsSectionContainerRef);
    const showDropDownTabs = integrationsSectionContainerWidth < 580;

    return (
        <div ref={integrationsSectionContainerRef}>
            <div>
                <h2 className="font-semibold text-lg text-grey">Integrations</h2>
                <div>
                    <p className="text-sm text-grey-light">Overview of available integrations</p>
                </div>
            </div>
            <VerticalSpacer heightValue={showDropDownTabs ? 5 : 10} />
            {showDropDownTabs ? (
                <DropdownTabs
                    options={[
                        { value: 'app-directory', displayLabel: 'App Directory' },
                        { value: 'rss-feeds', displayLabel: 'RSS Feeds' },
                        { value: 'embed-on-website', displayLabel: 'Embed on Website' },
                        { value: 'buffer', displayLabel: 'Buffer' }
                    ]}
                    selectedOption={tabView ?? 'app-directory'}
                    renderOptionSelectionButton={(option, onClick) => (
                        <button className="w-full text-left" onClick={() => onClick()}>
                            <RouteLink
                                className="inline-block pr-20"
                                href={`/integrations/${option.value}`}
                            >
                                {option.displayLabel}
                            </RouteLink>
                        </button>
                    )}
                />
            ) : (
                <div className="mt-10">
                    <Tabs
                        tabs={[
                            { id: 'app-directory', displayText: 'App Directory' },
                            { id: 'rss-feeds', displayText: 'RSS Feeds' },
                            { id: 'embed-on-website', displayText: 'Embed on Website' },
                            { id: 'buffer', displayText: 'Buffer' }
                        ]}
                        currentTabView={tabView ?? 'app-directory'}
                        renderTabSelectionButton={(tab: Tab) => (
                            <button className="text-base h-6 px-2">
                                <RouteLink href={`/integrations/${tab.id}`}>
                                    {tab.displayText}
                                </RouteLink>
                            </button>
                        )}
                    />
                    <p className="border-b border-grey-lighter" />
                </div>
            )}
            <VerticalSpacer heightValue={showDropDownTabs ? 5 : 10} />
            {renderIntegrationsViewContent(tabView)}
        </div>
    );
};
