import { getNumberOfDays } from '../utils/get-number-of-days';
import { LinkClipboard } from '../api';

export interface CuratedContentItem {
    readonly id: string;
    readonly linkUrl: string;
    readonly titleText?: string;
    readonly additionalText?: string;
    readonly imageUrl?: string;
    readonly faviconUrl?: string;
    readonly source?: string;
    readonly author?: string;
    readonly campaignId: string;
    readonly daysAgoSaved: number;
}

export function createClipboardItemData(result: LinkClipboard): CuratedContentItem {
    return {
        id: result.id,
        linkUrl: result.url,
        titleText: result.og_data.og_title,
        additionalText: result.og_data.og_description,
        imageUrl: result.og_data.og_image,
        faviconUrl: result.favicon,
        source: result.og_data.og_source,
        author: result.og_data.og_author,
        campaignId: result.label ?? '',
        daysAgoSaved: getNumberOfDays(new Date(result.created_at), new Date(Date.now()))
    };
}
