import React from 'react';

interface ContentIntegratorProps {
    readonly contentUrl: string;
    readonly cta: JSX.Element;
}

export const ContentIntegrator: React.FC<ContentIntegratorProps> = ({ contentUrl, cta }) => {
    return (
        <div className="relative h-full w-full font-poppins text-grey">
            <iframe src={contentUrl} className="absolute h-full w-full"></iframe>
            {cta}
        </div>
    );
};
