import React from 'react';
import { SnipUrls } from '../data-transformations/create-snip-urls-data';

interface ContentViewTabsProps {
    readonly currentContentView: keyof SnipUrls;
    readonly setCurrentContentUrl: (url: string) => void;
    readonly snipUrls: SnipUrls;
}

export const ContentViewTabs: React.FC<ContentViewTabsProps> = ({
    currentContentView,
    setCurrentContentUrl,
    snipUrls
}) => {
    return (
        <div className={`flex items-end text-grey`}>
            <div>
                <button
                    className={`flex justify-center text-sm p-2 w-24 ${
                        !snipUrls.dynamic ? 'text-grey-light' : 'text-grey'
                    } ${
                        currentContentView === 'dynamic'
                            ? 'border border-primary rounded-l-md'
                            : 'border-y border-l border-grey-light rounded-l-md'
                    }`}
                    type="button"
                    disabled={!snipUrls.dynamic}
                    onClick={(event) => {
                        event.preventDefault();
                        setCurrentContentUrl(snipUrls.dynamic);
                    }}
                >
                    <p
                        className={`${
                            currentContentView === 'dynamic' ? 'font-medium' : 'font-normal'
                        }`}
                    >
                        {`Dynamic${!snipUrls.dynamic ? '*' : ''}`}
                    </p>
                </button>
                <div
                    className={`h-0.5 w-full rounded-t-md mt-2 ${
                        currentContentView === 'dynamic' ? 'bg-primary' : 'bg-grey-lightest'
                    }`}
                />
            </div>
            <div>
                <button
                    className={`text-sm p-2 w-24 ${
                        !snipUrls.static ? 'text-grey-light' : 'text-grey'
                    } ${
                        currentContentView === 'static'
                            ? 'border border-primary'
                            : currentContentView === 'dynamic'
                            ? 'border-y border-r border-grey-light'
                            : 'border-y border-l border-grey-light'
                    }`}
                    type="button"
                    disabled={!snipUrls.static}
                    onClick={(event) => {
                        event.preventDefault();
                        setCurrentContentUrl(snipUrls.static);
                    }}
                >
                    <p
                        className={`${
                            currentContentView === 'static' ? 'font-medium' : 'font-normal'
                        }`}
                    >
                        {`Static${!snipUrls.static ? '*' : ''}`}
                    </p>
                </button>
                <div
                    className={`h-0.5 w-full rounded-t-md mt-2 ${
                        currentContentView === 'static' ? 'bg-primary' : 'bg-grey-lightest'
                    }`}
                />
            </div>
            <div>
                <button
                    className={`text-sm p-2 w-24 ${
                        !snipUrls.summary ? 'text-grey-light' : 'text-grey'
                    } ${
                        currentContentView === 'summary'
                            ? 'border border-primary rounded-r-md'
                            : 'border-y border-r border-grey-light rounded-r-md'
                    }`}
                    type="button"
                    disabled={!snipUrls.summary}
                    onClick={(event) => {
                        event.preventDefault();
                        setCurrentContentUrl(snipUrls.summary);
                    }}
                >
                    <p
                        className={`${
                            currentContentView === 'summary' ? 'font-medium' : 'font-normal'
                        }`}
                    >
                        {`Summary${!snipUrls.summary ? '*' : ''}`}
                    </p>
                </button>
                <div
                    className={`h-0.5 w-full rounded-t-md mt-2 ${
                        currentContentView === 'summary' ? 'bg-primary' : 'bg-grey-lightest'
                    }`}
                />
            </div>
        </div>
    );
};
