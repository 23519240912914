import React from 'react';
import DropdownIcon from '../assets/icons/dropdown-down.svg';

export interface DropDownTabOption {
    readonly displayLabel: string;
    readonly value: string;
}

interface DropdownTabsProps {
    readonly options: DropDownTabOption[];
    readonly selectedOption: string;
    readonly renderOptionSelectionButton: (
        option: DropDownTabOption,
        onClick?: () => void
    ) => JSX.Element;
}

export const DropdownTabs: React.FC<DropdownTabsProps> = ({
    options,
    selectedOption,
    renderOptionSelectionButton
}) => {
    const [open, setOpen] = React.useState(false);
    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="relative mt-1 border-b border-grey-lighter bg-white rounded-md w-full">
                <button
                    className="flex items-center justify-between w-full h-10 p-3 text-grey font-semibold"
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                >
                    <div className="flex items-center justify-between w-full pr-4">
                        <span>
                            {
                                options.find((option) => option.value === selectedOption)
                                    ?.displayLabel
                            }
                        </span>
                    </div>
                    {open ? (
                        <div className=" rotate-180">
                            <img className="h-2 w-2" src={DropdownIcon} />
                        </div>
                    ) : (
                        <img className="h-2 w-2" src={DropdownIcon} />
                    )}
                </button>
                {open && (
                    <div
                        ref={dropdownContentRef}
                        className="absolute rounded-md shadow-md px-3 pb-2 w-full bg-white border border-grey-lighter z-50"
                    >
                        <ul>
                            {options
                                .filter((option) => option.value !== selectedOption)
                                .map((option) => (
                                    <li
                                        className="mt-2 rounded-md text-grey w-full"
                                        key={option.value}
                                    >
                                        {renderOptionSelectionButton(option, () => setOpen(false))}
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
