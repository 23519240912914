import React from 'react';
import { SideNavigationLogo } from './Navigation/SideNavigationLogo';
import { RouteLink } from '../Routing';
import ButtonBack from './ButtonBack';
import { historyBackWithFallback } from '../utils/history-back-with-fallback';

interface FocusViewLayoutProps {
    readonly enableWideView?: boolean;
    readonly onBackButtonClick?: () => void;
    readonly children: React.ReactNode;
}

export const FocusViewLayout: React.FC<FocusViewLayoutProps> = ({
    enableWideView = false,
    onBackButtonClick = () => historyBackWithFallback('/'),
    children
}) => {
    return (
        <div className="h-full w-full flex text-grey">
            <div
                className={`h-full w-full ${
                    enableWideView ? 'sm:w-10/12' : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                } bg-white border-r border-grey-lighter`}
            >
                <div
                    className={`absolute h-15 p-3 sm:p-5 bg-white w-full flex items-start justify-between ${
                        enableWideView ? 'sm:w-10/12' : 'sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12'
                    }`}
                >
                    <RouteLink href="/">
                        <SideNavigationLogo />
                    </RouteLink>
                    <ButtonBack onClick={onBackButtonClick}>Back</ButtonBack>
                </div>
                <div className="h-full pt-10 px-3 sm:px-6 md:px-8 lg-px-12 overflow-y-scroll">
                    <div className="h-full mt-10">{children}</div>
                </div>
            </div>
            <div className={`h-full grow bg-primary`}></div>
        </div>
    );
};
