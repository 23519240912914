export function isValidUrl(url: string): boolean {
    const expression = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/;
    const regexp = new RegExp(expression);

    return regexp.test(url);
}

export function isValidUrlWithProtocol(url: string): boolean {
    const expression =
        /^(http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/|mailto:|tel:|sms:)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/i;

    const regexp = new RegExp(expression);

    return regexp.test(url);
}
