import React from 'react';

import { Prompt, PromptContext } from './PromptContextProvider';
import { ButtonMain } from './components/ButtonMain';

interface PromptComponentProps {
    readonly prompt: Prompt;
    readonly handleClosePrompt: () => void;
}

export const PromptComponent: React.FC<PromptComponentProps> = ({ prompt, handleClosePrompt }) => {
    const [startFadeIn, setStartFadeIn] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleClosePrompt();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setStartFadeIn(true);
    }, []);

    const {
        promptText,
        additionalText,
        acceptButtonText,
        declineButtonText,
        onAcceptButtonClick,
        onDeclineButtonClick
    } = prompt;

    return (
        <div
            ref={containerRef}
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 transition ease-in duration-700 bg-white rounded-md border border-grey-lighter ${
                startFadeIn ? 'opacity-1' : 'opacity-0'
            } p-8 shadow-lg`}
        >
            <p className="text-grey font-semibold">{promptText}</p>
            {additionalText && <p className="text-sm text-grey-light mt-2">{additionalText}</p>}
            <div className="mt-4 flex items-center">
                <ButtonMain
                    onClick={(event) => {
                        onAcceptButtonClick(event);
                        handleClosePrompt();
                    }}
                >
                    {acceptButtonText}
                </ButtonMain>
                <ButtonMain
                    className="ml-2"
                    onClick={(event) => {
                        onDeclineButtonClick && onDeclineButtonClick(event);
                        handleClosePrompt();
                    }}
                    style="neutral"
                >
                    {declineButtonText}
                </ButtonMain>
            </div>
        </div>
    );
};

export const PromptIntegration = () => {
    const promptContext = React.useContext(PromptContext);

    return !promptContext?.showPrompt ? null : (
        <PromptComponent
            prompt={promptContext.prompt}
            handleClosePrompt={promptContext.handleClosePrompt}
        />
    );
};
