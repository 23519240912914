import { PixelCrop } from 'react-image-crop';
import { createCanvasPreview } from './create-canvas-preview';

let previewUrl = '';

function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
        canvas.toBlob(resolve);
    });
}

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export async function createImage(
    image: HTMLImageElement,
    crop: PixelCrop
): Promise<{ readonly previewUrl: string; readonly blob: Blob }> {
    const canvas = document.createElement('canvas');
    createCanvasPreview(image, canvas, crop);

    const blob = await toBlob(canvas);

    if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
    }

    previewUrl = URL.createObjectURL(blob);

    return { previewUrl, blob };
}
