/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRSSFeedList } from '../models/PaginatedRSSFeedList';
import type { PatchedRSSFeedRequest } from '../models/PatchedRSSFeedRequest';
import type { RSSFeed } from '../models/RSSFeed';
import type { RSSFeedRequest } from '../models/RSSFeedRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedsService {

    /**
     * @returns PaginatedRSSFeedList
     * @throws ApiError
     */
    public static feedsList({
        brand,
        page,
        pageSize,
    }: {
        brand?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
    }): CancelablePromise<PaginatedRSSFeedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/feeds/',
            query: {
                'brand': brand,
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @returns RSSFeed
     * @throws ApiError
     */
    public static feedsCreate({
        requestBody,
    }: {
        requestBody: RSSFeedRequest,
    }): CancelablePromise<RSSFeed> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/feeds/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RSSFeed
     * @throws ApiError
     */
    public static feedsRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<RSSFeed> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/feeds/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RSSFeed
     * @throws ApiError
     */
    public static feedsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: RSSFeedRequest,
    }): CancelablePromise<RSSFeed> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/feeds/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RSSFeed
     * @throws ApiError
     */
    public static feedsPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedRSSFeedRequest,
    }): CancelablePromise<RSSFeed> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/feeds/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
