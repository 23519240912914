import React from 'react';

import { CustomDomainsService, LinksService } from '../api';
import { BASE_DOMAIN } from '../App';
import CloseIcon from '../assets/icons/close.svg';
import EditIcon from '../assets/icons/edit.svg';
import TickIcon from '../assets/icons/tick-grey.svg';
import SuccessIcon from '../assets/icons/tick-white.svg';
import { createCustomDomainsList } from '../data-transformations/create-custom-domains-list';
import { createSnipData, SnipsListItem } from '../data-transformations/create-snip-data';
import {
    createDomainDropdownOptions,
    CustomDomainsDropdownOption,
    DomainDropdownSelect
} from '../DomainDropdownSelect';
import { NotificationContext } from '../NotificationContextProvider';
import { WorkspaceContext } from '../WorkspaceContextProvider';
import { LoadingSpinner } from './LoadingSpinner';

interface SnipUrlCustomizerProps {
    readonly snip: SnipsListItem;
    readonly setSnip: (snip: SnipsListItem) => void;
    readonly domainId: string;
    readonly setDomainId: (id: string) => void;
    readonly shortlink: string;
    readonly setShortlink: (shortlink: string) => void;
}

export const SnipUrlCustomizer: React.FC<SnipUrlCustomizerProps> = ({
    snip,
    setSnip,
    domainId,
    setDomainId,
    shortlink,
    setShortlink
}) => {
    const [isEditingSnipSlug, setIsEditingSnipSlug] = React.useState(false);
    const [isUpdatingSnip, setIsUpdatingSnip] = React.useState(false);

    const [errorUpdatingSnip, setErrorUpdatingSnip] = React.useState(false);
    const [errorSlugAlreadyInUse, setErrorSlugAlreadyInUse] = React.useState(false);
    const [errorSlugAlreadyInUseOnDomainChange, setErrorSlugAlreadyInUseOnDomainChange] =
        React.useState(false);

    const [customDomainsDropdownOptions, setCustomDomainsDropdownOptions] = React.useState<
        CustomDomainsDropdownOption[]
    >([]);

    const [isLoadingCustomDomainsData, setIsLoadingCustomDomainsData] = React.useState(true);
    const [errorLoadingCustomDomains, setErrorLoadingCustomDomains] = React.useState(false);

    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleSaveShortLink = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setIsUpdatingSnip(true);
            setErrorSlugAlreadyInUse(false);
            setErrorUpdatingSnip(false);

            LinksService.linksPartialUpdate({
                id: snip.id,
                requestBody: {
                    slug: shortlink
                }
            })
                .then((result) => {
                    console.log('Edited snip with following response:', result);
                    const updatedSnip = createSnipData(result);

                    setSnip(updatedSnip);

                    setIsUpdatingSnip(false);
                    setIsEditingSnipSlug(false);

                    handleOpenNotification({
                        messageText: 'Snip successfully saved.',
                        type: 'success',
                        iconSrc: SuccessIcon,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.error(
                        'Error updating the Snip via LinksService.linksPartialUpdate()',
                        error
                    );

                    if (error.body?.slug?.[0].startsWith('That custom shortlink')) {
                        setErrorSlugAlreadyInUse(true);
                    } else {
                        setErrorUpdatingSnip(true);
                    }

                    setIsUpdatingSnip(false);
                });
        },
        [snip, setSnip, shortlink, handleOpenNotification]
    );

    const handleSaveDomain = React.useCallback(
        (id: string) => {
            setIsUpdatingSnip(true);
            setErrorUpdatingSnip(false);

            LinksService.linksPartialUpdate({
                id: snip.id,
                requestBody: {
                    custom_domain: id === 'default' ? null : id
                }
            })
                .then((result) => {
                    console.log('Edited snip with following response:', result);
                    const updatedSnip = createSnipData(result);

                    setSnip(updatedSnip);
                    setDomainId(id);

                    setIsUpdatingSnip(false);

                    handleOpenNotification({
                        messageText: 'Snip successfully saved.',
                        type: 'success',
                        iconSrc: SuccessIcon,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.error(
                        'Error updating the Snip via LinksService.linksPartialUpdate()',
                        error
                    );
                    if (error.body.includes('Slug is already taken')) {
                        setErrorSlugAlreadyInUseOnDomainChange(true);
                        setIsEditingSnipSlug(true);
                    } else {
                        setErrorUpdatingSnip(true);
                    }

                    setIsUpdatingSnip(false);
                });
        },
        [snip, setSnip, setDomainId, handleOpenNotification]
    );

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const handleLoadCustomDomains = React.useCallback(() => {
        setErrorLoadingCustomDomains(false);

        CustomDomainsService.customDomainsList({ brand: currentWorkspace.id })
            .then((result) => {
                setCustomDomainsDropdownOptions(
                    createDomainDropdownOptions(createCustomDomainsList(result))
                );
                setIsLoadingCustomDomainsData(false);
            })
            .catch((error) => {
                console.error('Error loading custom domains', error);
                setIsLoadingCustomDomainsData(false);
                setErrorLoadingCustomDomains(true);
            });
    }, [currentWorkspace]);

    React.useEffect(() => {
        if (currentWorkspace) {
            handleLoadCustomDomains();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWorkspace]);

    React.useEffect(() => {
        setErrorSlugAlreadyInUse(false);
        setErrorUpdatingSnip(false);
    }, [domainId, shortlink]);

    return (
        <div>
            <div className="h-12 flex items-center">
                <div className="flex border border-grey-lighter rounded-md">
                    <div className="w-36">
                        <DomainDropdownSelect
                            options={customDomainsDropdownOptions}
                            isLoadingOptions={isLoadingCustomDomainsData}
                            defaultOption={{ id: 'default', name: BASE_DOMAIN }}
                            selectedOptionId={domainId}
                            onSelectOption={handleSaveDomain}
                        />
                    </div>
                    {isEditingSnipSlug ? (
                        <input
                            className="pl-3 w-32 text-sm"
                            id="shortlink"
                            type="text"
                            value={shortlink}
                            onChange={(event) => {
                                setShortlink(event.target.value);
                            }}
                            autoFocus
                        />
                    ) : (
                        <button
                            onClick={() => setIsEditingSnipSlug(true)}
                            className="flex items-center py-2 px-4 border border-grey-lighter rounded-md text-sm"
                        >
                            {snip.slug}
                        </button>
                    )}
                </div>
                {isUpdatingSnip ? (
                    <div className="ml-2 flex items-center">
                        <LoadingSpinner size={5} />
                    </div>
                ) : isEditingSnipSlug ? (
                    <div className="ml-2 flex items-center">
                        <button
                            className="flex items-center justify-center"
                            type="button"
                            onClick={handleSaveShortLink}
                        >
                            <img src={TickIcon} alt="save icon" className="h-5 w-5" />
                        </button>
                        <button
                            className="ml-4 flex items-center justify-center"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();

                                setShortlink(snip.slug);
                                setIsEditingSnipSlug(false);
                            }}
                        >
                            <img src={CloseIcon} alt="save icon" className="h-5 w-5" />
                        </button>
                    </div>
                ) : (
                    <div className="flex">
                        <button
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                setIsEditingSnipSlug(true);
                            }}
                        >
                            <img src={EditIcon} alt="edit icon" className="ml-4 w-5 h-5" />
                        </button>
                    </div>
                )}
            </div>
            {errorUpdatingSnip && (
                <p className="my-4 p-1 text-sm text-red">
                    {`Something went wrong when updating your Snip. Please try again.`}
                </p>
            )}
            {errorSlugAlreadyInUse && (
                <p className="my-4 p-1 text-sm text-red">
                    {`The custom shortlink you have selected is already being used for this domain. Please try a different one.`}
                </p>
            )}
            {errorSlugAlreadyInUseOnDomainChange && (
                <p className="my-4 p-1 text-sm text-red">
                    {`The custom shortlink you have selected is already being used for this domain. Please change the shortlink before proceeding to change the domain.`}
                </p>
            )}
            {errorLoadingCustomDomains && (
                <div className={`my-4 p-1 w-full text-red text-sm`}>
                    <p>
                        We could not retrieve your Custom Domains at this time due to an unexpected
                        error.
                    </p>
                    <button
                        className="underline font-semibold"
                        onClick={() => handleLoadCustomDomains()}
                    >
                        Retry Now
                    </button>
                </div>
            )}
        </div>
    );
};
