import React from 'react';
import { SimpleContentBox } from './components/SimpleContentBox';
import { RouteLink } from './Routing';

export const IntegrationsEmbedOnWebsiteInfoContent = () => {
    return (
        <SimpleContentBox
            headerText="Embed on website"
            infoText="Add the sniply code to your website to optimize it for Sniply. Once the code is installed on your site, you will be able to:"
        >
            <ul className="pl-4 list-disc text-sm">
                <li>
                    <span className="font-semibold">Use Your Domain </span>
                    <span>
                        instead of Sniply. Visitors to Sniply links of your site will be redirected
                        to your domain rather than staying on the Sniply domain. Your Call-To-Action
                        will still work perfectly!
                    </span>
                </li>
                <li>
                    <span className="font-semibold">Outbound Links: </span>
                    <span>
                        Add your Call-To-Action to every outbound link on your website of blog. All
                        your visitor who click on external links will to a Sniply page with your
                        Call-To-Action visible.
                    </span>
                </li>
                <li>
                    <span className="font-semibold">Always On: </span>
                    <span>
                        Add a Call-To-Action to your site which applies to each and every visit.
                        Collect email addresses, drive traffic to a landing page, or build your
                        social following whenever somebody visits your site.
                    </span>
                </li>
            </ul>
            <div className="mt-4 text-sm">
                <span>You can read the </span>
                <RouteLink
                    href="https://support.snip.ly/hc/en-us/articles/20000910490132-How-can-I-embed-Sniply-into-my-Website-or-Blog-"
                    className="text-primary underline"
                >
                    full details
                </RouteLink>
                <span> or get started by entering your URL in the form.</span>
            </div>
        </SimpleContentBox>
    );
};
