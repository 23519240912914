import { OverallStats } from '../api';

export interface DashboardOverviewStats {
    readonly numberOfSnips: number;
    readonly numberOfSnipsDelta: number;
    readonly numberOfClicks: number;
    readonly numberOfClicksDelta: number;
    readonly numberOfConversions: number;
    readonly numberOfConversionsDelta: number;
    readonly conversionRateInPercent: number;
    readonly conversionRateDelta: number;
}

export function createDashboardOverviewStats(response: OverallStats): DashboardOverviewStats {
    return {
        numberOfSnips: response.overall.snips,
        numberOfSnipsDelta: Math.round(response.time_range_percentage_diff.snips),
        numberOfClicks: response.overall.clicks,
        numberOfClicksDelta: Math.round(response.time_range_percentage_diff.clicks),
        numberOfConversions: response.overall.conversions,
        numberOfConversionsDelta: Math.round(response.time_range_percentage_diff.conversions),
        conversionRateInPercent: response.overall.conversions,
        conversionRateDelta: response.time_range_percentage_diff.conversion_rate
    };
}
