import React from 'react';
import OnboardingImage from './assets/images/onboarding.svg';
import { ButtonMain } from './components/ButtonMain';
import { useElementWidth } from './hooks/use-element-width';
import { navigateTo } from './Routing';

export const DashboardOnboardingBanner: React.FC = () => {
    const containerRef = React.useRef(undefined);
    const containerWidth = useElementWidth(containerRef);
    const isSmallView = containerWidth < 700;

    return (
        <div ref={containerRef}>
            {isSmallView ? (
                <div className="relative">
                    <div>
                        <img
                            src={OnboardingImage}
                            alt="image"
                            className={`h-20 w-20 absolute -top-5 left-0`}
                        />
                        <div className="ml-24 pr-2">
                            <p className="font-semibold">Welcome</p>
                            <p className="mt-2 text-xs">
                                Sniply helps you drive conversions by leveraging the content you
                                share. Overlay your own custom message (Call-to-action) onto any
                                piece of content you share.
                            </p>
                            <ButtonMain
                                className="mt-3"
                                size="extra-small"
                                onClick={() => navigateTo('/snip-create/content-select')}
                            >
                                + Create Your First Snip
                            </ButtonMain>
                        </div>
                    </div>
                    <div className="mt-4 ml-10 grid grid-cols-1-1"></div>
                </div>
            ) : (
                <div className="relative">
                    <img
                        src={OnboardingImage}
                        alt="image"
                        className="h-40 w-40 absolute -top-10 left-6"
                    />
                    <div className={`mt-2 ml-60`}>
                        <p className="font-semibold">Welcome</p>
                        <p className="mt-2 text-sm pr-2">
                            Sniply helps you drive conversions by leveraging the content you share.
                            Overlay your own custom message (Call-to-action) onto any piece of
                            content you share. Get started by creating your first Snip.
                        </p>
                        <ButtonMain
                            className="mt-4"
                            onClick={() => navigateTo('/snip-create/content-select')}
                        >
                            + Create Your First Snip
                        </ButtonMain>
                    </div>
                </div>
            )}
        </div>
    );
};
