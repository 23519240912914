import React from 'react';

import { LinksService } from './api';
import ArrowUpIcon from './assets/icons/arrow-grey-light.svg';
import SniplyIcon from './assets/icons/sniply-logo.svg';
import { AuthContext } from './auth';
import { EmptyTableView } from './components/EmptyTableView';
import { EndlessScrollContainer } from './components/EndlessScrollContainer';
import { ImageWithFallback } from './components/ImageWithFallback';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SearchInput } from './components/SearchInput';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { createSnipsList } from './data-transformations/create-snips-list';
import { WorkspaceContext } from './WorkspaceContextProvider';

const NUMBER_OF_SNIPS_PER_LOAD = 10;

export type SortingDirection = 'ascending' | 'descending';

interface SnipTableRowProps {
    readonly snipId: string;
    readonly snipName: string;
    readonly faviconUrl: string;
    readonly contentUrl: string;
    readonly contentTitle: string;
    readonly selected: boolean;
    readonly onSelectSnip: (snipId: string) => void;
}

const SnipTableRow: React.FC<Partial<SnipTableRowProps>> = ({
    snipId,
    snipName,
    faviconUrl,
    contentUrl,
    selected,
    onSelectSnip
}) => {
    return (
        <button
            className={`w-full grid grid-cols-4-1 items-center my-2 p-2 rounded-sm transition duration-100 ${
                selected
                    ? 'border border-grey-light'
                    : 'border border-white hover:border hover:border-grey-lighter'
            }`}
            onClick={(event) => {
                event.preventDefault;
                onSelectSnip(snipId);
            }}
        >
            <div className="w-full flex items-center overflow-hidden">
                <ImageWithFallback
                    src={faviconUrl}
                    alt="favicon"
                    fallbackSrc={SniplyIcon}
                    className="h-4 w-4"
                />
                <div className="ml-2">
                    <span className="text-left line-clamp-1">{snipName}</span>
                    <span className="text-xs text-left line-clamp-1">{contentUrl}</span>
                </div>
            </div>
            <div className="flex justify-end">
                <img src={ArrowUpIcon} alt="arrow up icon" className="ml-2 w-4 h-4 rotate-180" />
            </div>
        </button>
    );
};

interface ReportingSnipsTableProps {
    readonly selectedSnipId: string;
    readonly onSelectSnip: (snipId: string) => void;
    readonly selectedCampaignId: string;
    readonly selectedCtaId: string;
}

export const ReportingSnipsTable: React.FC<ReportingSnipsTableProps> = ({
    selectedSnipId,
    onSelectSnip,
    selectedCampaignId,
    selectedCtaId
}) => {
    const [snipsData, setSnipsData] = React.useState<SnipsListItem[]>();
    const [searchResultsData, setSearchResultsData] = React.useState<SnipsListItem[]>();

    const [searchTerm, setSearchTerm] = React.useState('');

    const [isLoadingInitialData, setIsLoadingInitialData] = React.useState(true);
    const [hadErrorLoadingData, setHadErrorLoadingData] = React.useState(false);

    const [isLoadingMoreData, setIsLoadingMoreData] = React.useState(false);
    const [numberOfPagesLoaded, setNumberOfPagesLoaded] = React.useState(0);
    const [hasMorePages, setHasMorePages] = React.useState(true);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const handleLoadMoreSnips = React.useCallback(() => {
        if (!isLoadingMoreData && hasMorePages) {
            setIsLoadingMoreData(true);

            LinksService.linksList({
                brand: currentWorkspace?.id,
                label: selectedCampaignId,
                ordering: '-created_date',
                search: searchTerm,
                page: numberOfPagesLoaded + 1,
                pageSize: NUMBER_OF_SNIPS_PER_LOAD
            })
                .then((result) => {
                    console.log('loading more Snips via LinksService.linksList()', result);

                    setNumberOfPagesLoaded(numberOfPagesLoaded + 1);

                    searchTerm
                        ? setSearchResultsData([...searchResultsData, ...createSnipsList(result)])
                        : setSnipsData([...snipsData, ...createSnipsList(result)]);

                    setIsLoadingMoreData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LinksService.linksList(): ', error);

                    if (error.body?.detail?.startsWith('Invalid page')) {
                        setHasMorePages(false);
                    }

                    setIsLoadingMoreData(false);
                });
        }
    }, [
        snipsData,
        setSnipsData,
        searchResultsData,
        setSearchResultsData,
        isLoadingMoreData,
        numberOfPagesLoaded,
        setNumberOfPagesLoaded,
        currentWorkspace,
        searchTerm,
        selectedCampaignId,
        hasMorePages
    ]);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            setIsLoadingInitialData(true);

            LinksService.linksList({
                brand: currentWorkspace?.id,
                label: selectedCampaignId,
                ordering: '-created_date',
                search: searchTerm,
                page: 1,
                pageSize: NUMBER_OF_SNIPS_PER_LOAD
            })
                .then((result) => {
                    console.log('LinksService.linksList()', result);
                    setNumberOfPagesLoaded(1);
                    setSnipsData(createSnipsList(result));
                    setHadErrorLoadingData(false);
                    setIsLoadingInitialData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LinksService.linksList(): ', error);
                    setHadErrorLoadingData(true);
                    setIsLoadingInitialData(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenAPITokenSet, currentWorkspace, selectedCampaignId]);

    const searchRequestIndex = React.useRef(0);

    React.useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            const currentSearchRequestIndex = searchRequestIndex.current + 1;

            searchRequestIndex.current = currentSearchRequestIndex;

            setIsLoadingInitialData(true);

            setTimeout(() => {
                if (currentSearchRequestIndex === searchRequestIndex.current) {
                    LinksService.linksList({
                        brand: currentWorkspace?.id,
                        search: searchTerm,
                        page: 1,
                        pageSize: NUMBER_OF_SNIPS_PER_LOAD
                    })
                        .then((result) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'loading more Snips via LinksService.linksList()',
                                    result
                                );
                                setSearchResultsData(createSnipsList(result));
                                setIsLoadingInitialData(false);
                            }
                        })
                        .catch((error) => {
                            if (currentSearchRequestIndex === searchRequestIndex.current) {
                                console.log(
                                    'Error fetching from LinksService.linksList(): ',
                                    error
                                );
                                setIsLoadingInitialData(false);
                            }
                        });
                }
            }, 1000);
        } else {
            setSearchResultsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const snipsDataToDisplay =
        searchResultsData && searchTerm.length > 2 ? searchResultsData : snipsData;

    const filteredSnipsDataToDisplay = snipsDataToDisplay
        ?.filter((snipEntry) =>
            selectedCampaignId ? snipEntry.campaignId === selectedCampaignId : true
        )
        .filter((snipEntry) => (selectedCtaId ? snipEntry.ctaIds.includes(selectedCtaId) : true));

    return (
        <div className="w-full">
            <div className="flex items-start justify-between h-10">
                <div className="flex items-center">
                    <h3 className="font-semibold">Snips</h3>
                </div>
                {snipsData?.length > 0 && (
                    <div className="flex shrink justify-end ml-4">
                        <SearchInput
                            searchTermState={searchTerm}
                            setSearchTermState={setSearchTerm}
                            placeholderText={`search...`}
                            isCollapsible={false}
                            width="52"
                            onClearSearch={() => setSearchTerm('')}
                            isRounded
                        />
                    </div>
                )}
            </div>
            <div className="h-72 w-full">
                {isLoadingInitialData ? (
                    <div className="h-full w-full flex items-center justify-center">
                        <LoadingSpinner size={10} />
                    </div>
                ) : hadErrorLoadingData ? (
                    <div className="text-red">
                        Apologies your Snips could not be retrieved. An unexpected network error has
                        occurred. Please try again later.
                    </div>
                ) : snipsData?.length ? (
                    <div className="w-full">
                        {searchResultsData?.length === 0 && searchTerm.length > 2 && (
                            <div className="flex justify-center flex-col">
                                <p className="mt-2 text-grey-medium text-sm">
                                    No results for this search.
                                </p>
                                <button className="mt-2" onClick={() => setSearchTerm('')}>
                                    Clear Search
                                </button>
                            </div>
                        )}
                        <EndlessScrollContainer
                            showLoadMoreAtAll={
                                snipsDataToDisplay.length >= NUMBER_OF_SNIPS_PER_LOAD
                            }
                            onReachedScrollBottom={handleLoadMoreSnips}
                            onLoadMoreClick={handleLoadMoreSnips}
                            isLoadingMoreData={isLoadingMoreData}
                            hasMoreResults={hasMorePages}
                            height="h-72"
                        >
                            {filteredSnipsDataToDisplay?.map((snip) => (
                                <SnipTableRow
                                    key={snip.snipName}
                                    snipId={snip.id}
                                    snipName={snip.snipName}
                                    faviconUrl={snip.faviconUrl}
                                    contentUrl={snip.originalContentUrl}
                                    contentTitle={snip.contentTitle}
                                    selected={selectedSnipId === snip.id}
                                    onSelectSnip={onSelectSnip}
                                />
                            ))}
                        </EndlessScrollContainer>
                    </div>
                ) : (
                    <EmptyTableView
                        headlineText="You do not have any snips yet"
                        sublineText="Once you do, you will see the overview of your most recent snips
                        here and will be able to select a snip for snip-specific statistics."
                    />
                )}
            </div>
        </div>
    );
};
