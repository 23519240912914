/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomDomain } from '../models/CustomDomain';
import type { CustomDomainRequest } from '../models/CustomDomainRequest';
import type { CustomDomainUpdate } from '../models/CustomDomainUpdate';
import type { CustomDomainVerification } from '../models/CustomDomainVerification';
import type { DomainResponse } from '../models/DomainResponse';
import type { DomainResponseRequest } from '../models/DomainResponseRequest';
import type { PaginatedCustomDomainList } from '../models/PaginatedCustomDomainList';
import type { PatchedCustomDomainUpdateRequest } from '../models/PatchedCustomDomainUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomDomainsService {

    /**
     * @returns PaginatedCustomDomainList
     * @throws ApiError
     */
    public static customDomainsList({
        brand,
        ordering,
        page,
        pageSize,
        search,
    }: {
        brand?: string,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedCustomDomainList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/custom-domains/',
            query: {
                'brand': brand,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @returns CustomDomain
     * @throws ApiError
     */
    public static customDomainsCreate({
        requestBody,
    }: {
        requestBody: CustomDomainRequest,
    }): CancelablePromise<CustomDomain> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/custom-domains/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static customDomainsDeleteDestroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v3/custom-domains/{id}/delete',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomDomainUpdate
     * @throws ApiError
     */
    public static customDomainsEditPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedCustomDomainUpdateRequest,
    }): CancelablePromise<CustomDomainUpdate> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/custom-domains/{id}/edit',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CustomDomainVerification
     * @throws ApiError
     */
    public static customDomainsVerifyPartialUpdate({
        id,
    }: {
        id: string,
    }): CancelablePromise<CustomDomainVerification> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/custom-domains/{id}/verify',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Make a POST request with the 'domain' field in the body to check if the domain is in use.
     * @returns DomainResponse
     * @throws ApiError
     */
    public static customDomainsCheckDomainIsInUseCreate({
        requestBody,
    }: {
        requestBody: DomainResponseRequest,
    }): CancelablePromise<DomainResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/custom-domains/check_domain_is_in_use',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
