import React from 'react';
import { CtasService } from './api';
import { AuthContext } from './auth';
import {
    FilterDropdownSelect,
    FilterDropdownSelectOption
} from './components/FilterDropdownSelect';
import { createCtasList } from './data-transformations/create-ctas-list';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface CtasFilterDropdownSelectProps {
    readonly selectedCtaId: string;
    readonly onSelectOption: (option: string) => void;
}

export const CtasFilterDropdownSelect: React.FC<CtasFilterDropdownSelectProps> = ({
    selectedCtaId,
    onSelectOption
}) => {
    const [ctasData, setCtasData] = React.useState<FilterDropdownSelectOption[]>([]);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            CtasService.ctasList({
                brand: currentWorkspace.id
            })
                .then((result) => {
                    console.log('CtasService.ctasList()', result);
                    setCtasData(
                        createCtasList(result).map((cta) => ({
                            displayLabel: cta.name ?? cta.messageText,
                            value: cta.id
                        }))
                    );
                })
                .catch((error) => {
                    console.log('Error fetching from CtasService.ctasList(): ', error);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return (
        <FilterDropdownSelect
            label="Call-To-Action"
            options={ctasData}
            selectedOptionId={selectedCtaId}
            onSelectOption={onSelectOption}
        />
    );
};
