import React from 'react';

import CloseIcon from './assets/icons/close.svg';
import FolderIcon from './assets/icons/folder-green.svg';
import { ButtonMain } from './components/ButtonMain';
import { DropDownOption } from './components/DropdownSelect';
import { SearchInput } from './components/SearchInput';
import { useIsMostLikelyTouchDevice } from './hooks/use-is-most-likely-touch-device';

interface CampaignsDropdownTagSelectProps {
    readonly label: string;
    readonly isLoadingCampaigns: boolean;
    readonly campaigns: DropDownOption[];
    readonly selectedCampaignId: string;
    readonly onSelectCampaignId: (
        event: React.MouseEvent<HTMLButtonElement>,
        selectedCampaignId: string
    ) => void;
    readonly onResetCampaignId: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly onCreateCampaign: (
        event: React.MouseEvent<HTMLButtonElement>,
        campaignName: string
    ) => void;
}

export const CampaignsDropdownTagSelect: React.FC<CampaignsDropdownTagSelectProps> = ({
    label,
    campaigns,
    isLoadingCampaigns,
    selectedCampaignId,
    onSelectCampaignId,
    onResetCampaignId,
    onCreateCampaign
}) => {
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const isTouchDevice = useIsMostLikelyTouchDevice();

    const [isHoveringSelectedCampaign, setIsHoveringSelectedCampaign] = React.useState(false);
    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const campaignsWithSearchApplied = campaigns.filter((campaign) =>
        campaign.displayLabel.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return isLoadingCampaigns ? (
        <div className="h-8 w-full flex items-center justify-center" />
    ) : (
        <div
            className={`h-8 w-full relative flex items-center py-1 px-2 text-sm transition duration-200 rounded-full ${
                selectedCampaignId
                    ? isTouchDevice
                        ? 'border border-grey-lighter'
                        : 'hover:border hover:border-grey-lighter'
                    : 'text-grey-medium text-left'
            }`}
            ref={dropdownContentRef}
        >
            {selectedCampaignId ? (
                <div
                    className="flex items-center w-full"
                    onMouseEnter={() => setIsHoveringSelectedCampaign(true)}
                    onMouseLeave={() => setIsHoveringSelectedCampaign(false)}
                >
                    <button
                        className="flex items-center w-full min-w-0"
                        onClick={(event) => {
                            event.preventDefault();
                            setOpen(open ? false : true);
                        }}
                        disabled={!campaigns || campaigns.length === 0}
                    >
                        <img src={FolderIcon} alt="campaign icon" className="w-4 h-4" />
                        <span className="ml-2 truncate break-words text-left">
                            {
                                campaigns.find((option) => option.value === selectedCampaignId)
                                    ?.displayLabel
                            }
                        </span>
                    </button>
                    {(isTouchDevice || isHoveringSelectedCampaign) && (
                        <button
                            onClick={(event) => onResetCampaignId(event)}
                            className={`ml-2 p-1}`}
                        >
                            <img src={CloseIcon} alt="close icon" className="h-4 w-4" />
                        </button>
                    )}
                </div>
            ) : (
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        setOpen(open ? false : true);
                    }}
                    disabled={isLoadingCampaigns}
                    className={`text-left w-full relative ${
                        isLoadingCampaigns ? 'text-grey-lighter' : 'text-grey-medium'
                    }`}
                >
                    {label}
                </button>
            )}

            {open && (
                <div className="absolute top-9 left-0 text-sm rounded-md  shadow-md px-3 pb-2 w-72 bg-white border border-grey-lighter z-50">
                    <div className="mt-2">
                        <SearchInput
                            placeholderText="Search or create new"
                            searchTermState={searchTerm}
                            onClearSearch={() => setSearchTerm('')}
                            setSearchTermState={setSearchTerm}
                            isCollapsible={false}
                            isRounded
                            width="full"
                            autofocus
                        />
                    </div>
                    <div className="mt-4 max-h-40 w-full overflow-y-scroll overflow-x-hidden">
                        {searchTerm && campaignsWithSearchApplied.length < 1 && (
                            <ButtonMain
                                width="full"
                                size="extra-small"
                                style="neutral"
                                onClick={(event) => {
                                    onCreateCampaign(event, searchTerm);
                                    setSearchTerm('');
                                    setOpen(false);
                                }}
                            >
                                {`Create '${searchTerm}'`}
                            </ButtonMain>
                        )}
                        <ul>
                            {campaignsWithSearchApplied
                                .filter((campaign) => campaign.value !== selectedCampaignId)
                                .map((campaign) => (
                                    <li
                                        className="text-left mt-2 rounded-md text-grey hover:text-grey-light w-full min-w-0"
                                        key={campaign.value}
                                    >
                                        <button
                                            className="w-full"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                onSelectCampaignId(event, campaign.value);
                                                setOpen(false);
                                            }}
                                        >
                                            <div className="text-left ml-3 min-w-0 overflow-x-hidden">
                                                <p className="truncate">{campaign.displayLabel}</p>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
