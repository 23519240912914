/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsSnippable } from '../models/IsSnippable';
import type { IsSnippableRequest } from '../models/IsSnippableRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CheckIfUrlSnippableService {

    /**
     * This endpoint should be called to check if URL is snippable.
     * Should be called before trying to create a link.
     *
     * Response:
     * - url: URL to check
     * - is_snippable: True if URL is snippable, False otherwise
     * - og_data: Open Graph data for URL
     * - favicon: URL to favicon
     * @returns IsSnippable
     * @throws ApiError
     */
    public static checkIfUrlSnippableCreate({
        requestBody,
    }: {
        requestBody: IsSnippableRequest,
    }): CancelablePromise<IsSnippable> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/check_if_url_snippable/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
