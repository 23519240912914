import { useIsMobileScreenSize } from './use-is-mobile-screen-size';

interface NavigatorWithMSFeatures extends Navigator {
    readonly msMaxTouchPoints?: number;
}

export function useIsMostLikelyTouchDevice(): boolean {
    const isMobileScreenSize = useIsMobileScreenSize();

    if (isMobileScreenSize) {
        return true;
    }

    if (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as NavigatorWithMSFeatures).msMaxTouchPoints > 0
    ) {
        return true;
    }

    return false;
}
