import React from 'react';
import { ButtonMain } from './components/ButtonMain';
import { DropdownSelect } from './components/DropdownSelect';
import { TeamMemberData, TeamMemberRole } from './data-transformations/create-team-members-data';
import { TeamService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { WorkspaceDropdownMultiSelect } from './WorkspaceDropdownMultiSelect';
import { getWorkspaceIdsToExclude } from './TeamModalInviteTeamMember';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface TeamModalEditTeamMemberProps {
    readonly teamMembersData: TeamMemberData[];
    readonly setTeamMembersData: (teamData: TeamMemberData[]) => void;
    readonly closeModal: () => void;
}

export const TeamModalEditTeamMember: React.FC<TeamModalEditTeamMemberProps> = ({
    teamMembersData,
    setTeamMembersData,
    closeModal
}) => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [teamMemberId, setTeamMemberId] = React.useState('');

    const [role, setRole] = React.useState<TeamMemberRole>();
    const [workspaceIds, setWorkspaceIds] = React.useState([]);

    const [updatingTeammember, setUpdatingTeammember] = React.useState(false);
    const [errorNetworkUpdatingTeammember, setErrorNetworkUpdatingTeammember] =
        React.useState(false);

    const { activeWorkspaces, teamMembersByWorkspace, setTeamMembersByWorkspace } =
        React.useContext(WorkspaceContext);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setUpdatingTeammember(true);

            const excludedWorkspaceIds = getWorkspaceIdsToExclude(
                activeWorkspaces.map((workspace) => ({
                    displayLabel: workspace.name,
                    value: workspace.id
                })),
                workspaceIds
            );

            TeamService.teamMemberPartialUpdate({
                id: teamMemberId,
                requestBody: {
                    exclude_brand_ids: excludedWorkspaceIds,
                    is_client_role: role === 'client' ? true : false
                }
            })
                .then((result) => {
                    console.log('TeamService.teamMemberPartialUpdate()', result);

                    setTeamMembersData(
                        teamMembersData.map((member) => {
                            if (member.id === teamMemberId) {
                                return {
                                    ...member,
                                    role,
                                    excludedWorkspaceIds
                                };
                            } else {
                                return member;
                            }
                        })
                    );

                    setTeamMembersByWorkspace(
                        teamMembersByWorkspace.map((workspaceTeamMembers) => {
                            if (excludedWorkspaceIds.includes(workspaceTeamMembers.workspaceId)) {
                                return {
                                    ...workspaceTeamMembers,
                                    teamMembers: workspaceTeamMembers.teamMembers.filter(
                                        (teamMember) => teamMember.id !== teamMemberId
                                    )
                                };
                            } else {
                                return {
                                    ...workspaceTeamMembers,
                                    teamMembers: [
                                        ...workspaceTeamMembers.teamMembers,
                                        { id: teamMemberId, name: name }
                                    ]
                                };
                            }
                        })
                    );

                    setUpdatingTeammember(false);

                    closeModal();
                })
                .catch((error) => {
                    console.error(
                        'Error posting new invite to TeamService.teamMembersInviteCreate()',
                        error
                    );

                    setErrorNetworkUpdatingTeammember(true);

                    setUpdatingTeammember(false);
                });
        },
        [
            teamMemberId,
            role,
            closeModal,
            teamMembersData,
            setTeamMembersData,
            activeWorkspaces,
            workspaceIds
        ]
    );

    const handleSelectWorkspace = React.useCallback(
        (workspaceId: string) => {
            if (workspaceIds.includes(workspaceId)) {
                setWorkspaceIds(workspaceIds.filter((id) => id !== workspaceId));
            } else {
                setWorkspaceIds([...workspaceIds, workspaceId]);
            }
        },
        [workspaceIds]
    );

    const handleSelectAllWorkspaces = React.useCallback(() => {
        setWorkspaceIds(activeWorkspaces.map((workspace) => workspace.id));
    }, [activeWorkspaces]);

    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;

        const teammemberIdUrlParam = searchParams.get('teammember-id');
        if (teammemberIdUrlParam && teamMembersData && activeWorkspaces) {
            const teammember = teamMembersData.find((member) => member.id === teammemberIdUrlParam);

            setName(teammember.name);
            setEmail(teammember.email);
            setTeamMemberId(teammember.id);

            setRole(teammember.role);

            setWorkspaceIds(
                activeWorkspaces
                    .map((workspace) => workspace.id)
                    .filter((id) => !teammember.excludedWorkspaceIds.includes(id))
            );
        }
    }, [teamMembersData, activeWorkspaces]);

    return (
        <div className="p-2">
            <h3 className="font-semibold">Edit Team Member</h3>
            {!teamMemberId ? (
                <div className="flex h-full w-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : (
                <form className="mt-6 grid gap-y-4 p-4" onSubmit={handleSubmit}>
                    <div>
                        <h4 className="text-sm mb-1">Name</h4>
                        <p className="text-grey-light">{name}</p>
                    </div>
                    <div>
                        <h4 className="text-sm mb-1">Email</h4>
                        <p className="text-grey-light">{email}</p>
                    </div>
                    <DropdownSelect
                        titleText="User Role"
                        options={[
                            { value: 'admin', displayLabel: 'Admin' },
                            { value: 'client', displayLabel: 'Client' }
                        ]}
                        selectedOption={role}
                        onSelectOption={setRole as (userRole: TeamMemberRole) => void}
                    />
                    <WorkspaceDropdownMultiSelect
                        workspacesData={activeWorkspaces.map((workspace) => ({
                            displayLabel: workspace.name,
                            value: workspace.id
                        }))}
                        selectedWorkspaceIds={workspaceIds}
                        onSelectWorkspace={handleSelectWorkspace}
                        onSelectAllWorkspaces={handleSelectAllWorkspaces}
                    />
                    <div className="h-12">
                        {updatingTeammember ? (
                            <LoadingSpinner size={5} />
                        ) : (
                            <div className="flex">
                                <ButtonMain type="submit">Save</ButtonMain>
                                <ButtonMain className="ml-2" style="neutral" onClick={closeModal}>
                                    Cancel
                                </ButtonMain>
                            </div>
                        )}
                    </div>
                    {errorNetworkUpdatingTeammember && (
                        <p className="mt-4 text-sm text-red">
                            Something went wrong. Please try sending the invite again.
                        </p>
                    )}
                </form>
            )}
        </div>
    );
};
