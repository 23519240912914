import React from 'react';
import ScrollLock from 'react-scrolllock';

import CloseIcon from '../assets/icons/close.svg';
import { useIsMobileScreenSize } from '../hooks/use-is-mobile-screen-size';

interface DropdownContentMobileProps {
    readonly label: string;
    readonly setOpen: (state: boolean) => void;
    readonly closeOnSelect?: () => void;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptionId: string;
    readonly onSelectOption: (option: string) => void;
}

const DropdownContentMobile: React.FC<DropdownContentMobileProps> = ({
    label,
    setOpen,
    closeOnSelect,
    options,
    selectedOptionId,
    onSelectOption
}) => {
    const [startFadeIn, setStartFadeIn] = React.useState(false);

    React.useEffect(() => {
        setStartFadeIn(true);
    }, []);

    return (
        <>
            <div
                className="absolute w-full h-full top-0 left-0 z-40 bg-grey-light/60"
                onClick={() => setOpen(false)}
            />
            <div
                className={`absolute w-full max-h-76 top-0 left-0 text-sm rounded-md shadow-md px-3 pb-2 bg-white border border-grey-lighter z-50 transition ease-in duration-150 ${
                    startFadeIn ? 'translate-y-0' : '-translate-y-full'
                }`}
            >
                <button
                    className="flex sm:hidden w-full mt-2 pt-3 pr-1 mb-6 justify-between"
                    onClick={() => setOpen(false)}
                >
                    <p className="font-semibold text-grey">{`Select ${label}s To Filter By`}</p>
                    <img src={CloseIcon} alt="close icon" className="h-5 w-5" />
                </button>
                <ScrollLock>
                    <ul className="mt-4 max-h-44 w-full overflow-y-scroll overflow-x-hidden">
                        {options.map((option) => (
                            <li className="mr-4" key={option.value}>
                                <div
                                    className={`p-1 mt-2 mr-3 rounded-sm hover:text-grey border border-white hover:border-grey-light w-full transition duration-100 min-w-0 ${
                                        selectedOptionId === option.value
                                            ? 'border border-grey-medium text-grey'
                                            : 'border white text-grey-medium'
                                    }`}
                                >
                                    <button
                                        className="flex items-center"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            closeOnSelect && closeOnSelect();
                                            onSelectOption(option.value);
                                        }}
                                    >
                                        <div className="text-left ml-3 min-w-0 overflow-x-hidden">
                                            <p className="truncate">{option.displayLabel}</p>
                                            {option.secondaryDisplayLabel && (
                                                <p className="text-xs truncate">
                                                    {option.secondaryDisplayLabel}
                                                </p>
                                            )}
                                        </div>
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </ScrollLock>
            </div>
        </>
    );
};

interface DropdownContentProps {
    readonly closeOnSelect?: () => void;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptionId: string;
    readonly onSelectOption: (option: string) => void;
}

const DropdownContent: React.FC<DropdownContentProps> = ({
    closeOnSelect,
    options,
    selectedOptionId,
    onSelectOption
}) => {
    return (
        <>
            <ul className="mt-4 max-h-56 w-full overflow-y-scroll overflow-x-hidden">
                {options.map((option) => (
                    <li className="mr-4" key={option.value}>
                        <div
                            className={`p-1 mt-2 mr-3 rounded-sm hover:text-grey border border-white hover:border-grey-light w-full transition duration-100 min-w-0 ${
                                selectedOptionId === option.value
                                    ? 'border border-grey-medium text-grey'
                                    : 'border white text-grey-medium'
                            }`}
                        >
                            <button
                                className="flex items-center"
                                onClick={(event) => {
                                    event.preventDefault();
                                    closeOnSelect && closeOnSelect();
                                    onSelectOption(option.value);
                                }}
                            >
                                <div className="text-left ml-3 min-w-0 overflow-x-hidden">
                                    <p className="truncate">{option.displayLabel}</p>
                                    {option.secondaryDisplayLabel && (
                                        <p className="text-xs truncate">
                                            {option.secondaryDisplayLabel}
                                        </p>
                                    )}
                                </div>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export interface FilterDropdownSelectOption {
    readonly displayLabel: string;
    readonly value: string;
    readonly secondaryDisplayLabel?: string;
}

interface FilterDropdownSelectProps {
    readonly label: string;
    readonly options: FilterDropdownSelectOption[];
    readonly selectedOptionId: string;
    readonly onSelectOption: (optionId: string) => void;
    readonly defaultOptionId?: string;
    readonly iconSrc?: string;
    readonly openReverse?: boolean;
}

export const FilterDropdownSelectSimple: React.FC<FilterDropdownSelectProps> = ({
    label,
    options,
    selectedOptionId,
    onSelectOption,
    defaultOptionId,
    iconSrc,
    openReverse = false
}) => {
    const [open, setOpen] = React.useState(false);
    const dropdownContentRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isMobile = useIsMobileScreenSize();

    const showFilterAsActive =
        (defaultOptionId && selectedOptionId === defaultOptionId) || !selectedOptionId
            ? false
            : true;

    return (
        <div
            className={`static sm:relative py-1 px-2 text-sm border border-grey-lighter rounded-full text-center ${
                showFilterAsActive ? 'bg-grey text-white' : 'bg-grey-lightest text-grey'
            } bg-white`}
            ref={dropdownContentRef}
        >
            <button
                className="flex items-center"
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(open ? false : true);
                }}
                disabled={!options || options.length === 0}
            >
                {iconSrc && <img src={iconSrc} alt="icon" className="h-4 w-4 mr-2" />}
                {selectedOptionId ? (
                    <span className="line-clamp-1">
                        {options.find((option) => option.value === selectedOptionId)?.displayLabel}
                    </span>
                ) : (
                    <span className={`${options.length > 0 ? 'text-grey' : 'text-grey-lighter'}`}>
                        {label}
                    </span>
                )}
            </button>
            {open &&
                (isMobile ? (
                    <DropdownContentMobile
                        label={label}
                        setOpen={setOpen}
                        closeOnSelect={() => setOpen(false)}
                        options={options}
                        onSelectOption={onSelectOption}
                        selectedOptionId={selectedOptionId}
                    />
                ) : (
                    <>
                        <div
                            className={`absolute w-72 max-h-76 ${
                                openReverse ? 'top-9 right-0' : 'top-9 left-0'
                            } text-sm rounded-md shadow-md px-3 pb-2 bg-white border border-grey-lighter z-50 `}
                        >
                            <DropdownContent
                                closeOnSelect={() => setOpen(false)}
                                options={options}
                                onSelectOption={onSelectOption}
                                selectedOptionId={selectedOptionId}
                            />
                        </div>
                    </>
                ))}
        </div>
    );
};
