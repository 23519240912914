import React from 'react';

import { FeedsService } from './api';
import { AuthContext } from './auth';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { EmptyTableView } from './components/EmptyTableView';
import { LoadingSpinner } from './components/LoadingSpinner';
import { SimpleContentBox } from './components/SimpleContentBox';
import { createFeedsList, FeedsListItem } from './data-transformations/create-feeds-list';
import { IntegrationsRssFeedTable } from './IntegrationsRssFeedsTable';
import { navigateTo, RouteLink } from './Routing';
import { WorkspaceContext } from './WorkspaceContextProvider';

export const IntegrationsRssFeeds: React.FC = () => {
    const [feedsData, setFeedsData] = React.useState<FeedsListItem[]>([]);
    const [numberOfTotalFeeds, setNumberOfTotalFeeds] = React.useState<number>();

    const [loadingFeedData, setLoadingFeedData] = React.useState(true);
    const [errorFetchingFeeds, setErroFetchingFeeds] = React.useState(false);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);

    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            FeedsService.feedsList({ brand: currentWorkspace.id, page: 1, pageSize: 200 })
                .then((result) => {
                    console.log('FeedsService.feedsList()', result);

                    setFeedsData(createFeedsList(result));
                    setNumberOfTotalFeeds(result.count);
                    setLoadingFeedData(false);
                })
                .catch((error) => {
                    console.log('Error fetching from FeedsService.feedsList(): ', error);
                    setErroFetchingFeeds(true);
                    setLoadingFeedData(false);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return (
        <div className="grid grid-cols-1 xl:grid-cols-2-1 gap-10 items-start">
            <ContentElementContainer>
                <div className="h-120">
                    {loadingFeedData ? (
                        <div className="flex h-full w-full items-center justify-center">
                            <LoadingSpinner size={10} />
                        </div>
                    ) : errorFetchingFeeds ? (
                        <div className="text-red">
                            Apologies your RSS Feeds could not be retrieved. An unexpected network
                            error has occurred. Please try again later.
                        </div>
                    ) : feedsData.length ? (
                        <IntegrationsRssFeedTable
                            feeds={feedsData}
                            setFeeds={setFeedsData}
                            numberOfTotalFeeds={numberOfTotalFeeds}
                        />
                    ) : (
                        <div className="flex h-full w-full items-center justify-center">
                            <EmptyTableView
                                headlineText="You do not have any feeds yet"
                                sublineText="Once you do, you will see the overview of all your feeds here."
                                mainActionButton={
                                    <ButtonMain
                                        style="neutral"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            navigateTo('/feed-create');
                                        }}
                                    >
                                        + Create First Feed
                                    </ButtonMain>
                                }
                            />
                        </div>
                    )}
                </div>
            </ContentElementContainer>
            <ContentElementContainer>
                <SimpleContentBox
                    headerText="RSS Feed"
                    infoText="Sniply Feeds create entire sets of automatically snipped links. Just provide a stream of RSS content, choose the Call-To-Action that you would like to apply to the stream and make use of the output feed via your favourite feed reading app."
                >
                    <RouteLink href="/" className=" text-primary underline text-sm">
                        Learn more
                    </RouteLink>
                </SimpleContentBox>
            </ContentElementContainer>
        </div>
    );
};
