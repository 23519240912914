import React from 'react';

import LockIcon from '../../assets/icons/lock.svg';
import { CTARequest, CtasService, LinksService, TeamService } from '../../api';
import { ButtonMain } from '../../components/ButtonMain';
import { ConfigPreviewTabOption } from '../../components/ConfigPreviewTab';
import { CtaType } from '../../components/CtaTypeDropdownSelect';
import { FormStepLayout } from '../../components/FormStepLayout';
import { FormTextInput } from '../../components/FormTextInput';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { VerticalSpacer } from '../../components/VerticalSpacer';
import { ContentIntegrator } from '../../cta-components/ContentIntegrator';
import { LegacyCta } from '../../cta-components/LegacyCta';
import { CtaCreator } from '../../CtaCreator';
import { CtaSelection } from '../../CtaSelection';
import { CtaPosition, CtasListItem, CtaTheme } from '../../data-transformations/create-cta-data';
import { createCtaLinkList } from '../../data-transformations/create-cta-link-list';
import { useIsMobileScreenSize } from '../../hooks/use-is-mobile-screen-size';
import { navigateTo, useCurrentUrlPath } from '../../Routing';
import { UpgradePlanMessageContext } from '../../UpgradePlanMessageContextProvider';
import { UserContext } from '../../UserContextProvider';
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_CTA_POSITION,
    DEFAULT_MESSAGE_TEXT_COLOR
} from '../../utils/default-cta-configuration';
import { ensureUrlHasProtocol } from '../../utils/ensure-url-has-protocol';
import { getPositionForApiCall } from '../../utils/get-position-for-api-call';
import { isValidUrlWithProtocol } from '../../utils/is-valid-url';
import { WorkspaceContext } from '../../WorkspaceContextProvider';
import { hasHitPlanLimitForCtas } from '../../utils/has-hit-plan-limit-for-ctas';
import { NotificationContext } from '../../NotificationContextProvider';
import { getNextHigherPlan } from '../../utils/get-next-higher-plan';

export interface SnipCtaAddToRedirectCtaSelectProps {
    readonly snipId: string;
    readonly activeMobileViewTab: ConfigPreviewTabOption;
    readonly onSelectCta: (cta: CtasListItem) => void;
    readonly resetCtaConfiguration: () => void;
    readonly sniplyContentUrl: string;
    readonly callToActionUrl: string;
    readonly setCallToActionUrl: (callToActionUrl: string) => void;
    readonly ctaId: string;
    readonly setCtaId: (ctaName: string) => void;
    readonly ctaName: string;
    readonly setCtaName: (ctaName: string) => void;
    readonly ctaType: CtaType;
    readonly setCtaType: (ctaType: CtaType) => void;
    readonly headlineText: string;
    readonly setHeadlineText: (headlineText: string) => void;
    readonly messageText: string;
    readonly setMessageText: (messageText: string) => void;
    readonly buttonText: string;
    readonly setButtonText: (ctaText: string) => void;
    readonly linkText: string;
    readonly setLinkText: (ctaText: string) => void;
    readonly linkTextColor: string;
    readonly setLinkTextColor: (textColor: string) => void;
    readonly formButtonTextColor: string;
    readonly setFormButtonTextColor: (formButtonTextColor: string) => void;
    readonly formButtonColor: string;
    readonly setFormButtonColor: (formButtonColor: string) => void;
    readonly formPlaceholderText: string;
    readonly setFormPlaceholderText: (formPlaceholderText: string) => void;
    readonly imageAdUrl: string;
    readonly setImageAdUrl: (imageAdUrl: string) => void;
    readonly imageUrl: string | undefined;
    readonly setImageUrl: (imageUrl: string) => void;
    readonly imagePreviewUrl: string | undefined;
    readonly setImagePreviewUrl: (imagePreviewUrl: string) => void;
    readonly imageAdPreviewUrl: string | undefined;
    readonly setImageAdPreviewUrl: (imagePreviewUrl: string) => void;
    readonly imageBlob: Blob;
    readonly setImageBlob: (blob: Blob) => void;
    readonly fileName: string;
    readonly setFileName: (name: string) => void;
    readonly theme: CtaTheme;
    readonly setTheme: (theme: CtaTheme) => void;
    readonly backgroundColor: string;
    readonly setBackgroundColor: (backgroundColor: string) => void;
    readonly messageTextColor: string;
    readonly setMessageTextColor: (textColor: string) => void;
    readonly buttonColor: string;
    readonly setButtonColor: (buttonColor: string) => void;
    readonly buttonTextColor: string;
    readonly setButtonTextColor: (buttonTextColor: string) => void;
    readonly position: CtaPosition;
    readonly setPosition: (position: CtaPosition) => void;
    readonly showSniplyLogo: boolean;
    readonly setShowSniplyLogo: (state: boolean) => void;
    readonly showWhiteSniplyLogo: boolean;
    readonly setShowWhiteSniplyLogo: (state: boolean) => void;
}

export const SnipCtaAddToRedirectCtaSelect: React.FC<SnipCtaAddToRedirectCtaSelectProps> = ({
    snipId,
    activeMobileViewTab,
    onSelectCta,
    resetCtaConfiguration,
    sniplyContentUrl,
    callToActionUrl,
    setCallToActionUrl,
    ctaId,
    setCtaId,
    ctaName,
    setCtaName,
    ctaType,
    setCtaType,
    headlineText,
    setHeadlineText,
    messageText,
    setMessageText,
    buttonText,
    setButtonText,
    linkText,
    setLinkText,
    linkTextColor,
    setLinkTextColor,
    formButtonTextColor,
    setFormButtonTextColor,
    formButtonColor,
    setFormButtonColor,
    formPlaceholderText,
    setFormPlaceholderText,
    imageAdUrl,
    setImageAdUrl,
    imageUrl,
    setImageUrl,
    imagePreviewUrl,
    setImagePreviewUrl,
    imageAdPreviewUrl,
    setImageAdPreviewUrl,
    theme,
    setTheme,
    backgroundColor,
    setBackgroundColor,
    messageTextColor,
    setMessageTextColor,
    buttonColor,
    setButtonColor,
    buttonTextColor,
    setButtonTextColor,
    position,
    setPosition,
    showSniplyLogo,
    setShowSniplyLogo,
    showWhiteSniplyLogo,
    setShowWhiteSniplyLogo
}) => {
    const { level3 } = useCurrentUrlPath();
    const [showNoCtaSelectedError, setShowNoCtaSelectedError] = React.useState(false);

    const [showNoCtaLinkUrlError, setShowNoCtaLinkUrlError] = React.useState(false);
    const noCtaLinkUrlErrorRef = React.useRef(null);

    const errorMessageRef = React.useRef(null);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const [isCreatingSnip, setIsCreatingSnip] = React.useState(false);

    const [reachedPlanLimitForCtas, setReachedPlanLimitForCtas] = React.useState(false);
    const [isCheckingPlanCtaLimit, setIsCheckingPlanCtaLimit] = React.useState(true);

    const { userPlan, hasUsedFirstFreeSnip, setHasUsedFirstFreeSnip } =
        React.useContext(UserContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    const {
        canUseImageAdCta,
        minimumImageAdPlan,
        canUseCtaColors,
        canUseCtaPositioning,
        canHideSniplyLogo
    } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const handleCreateCtaThenUpdateSnip = React.useCallback(() => {
        const requestObject: CTARequest =
            ctaType === 'button'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: buttonText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          background_color: canUseCtaColors ? buttonColor : DEFAULT_BUTTON_COLOR,
                          is_text: false,
                          text_color: canUseCtaColors ? buttonTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'text-link'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      button_action: {
                          text: linkText,
                          url: ensureUrlHasProtocol(callToActionUrl),
                          is_text: true,
                          text_color: canUseCtaColors ? linkTextColor : DEFAULT_BUTTON_TEXT_COLOR
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : ctaType === 'form'
                ? {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      title: headlineText,
                      message: messageText,
                      message_color: canUseCtaColors
                          ? messageTextColor
                          : DEFAULT_MESSAGE_TEXT_COLOR,
                      background_color: canUseCtaColors
                          ? backgroundColor
                          : DEFAULT_BACKGROUND_COLOR,
                      form_action: {
                          placeholder_text: canUseCtaColors
                              ? formPlaceholderText
                              : DEFAULT_MESSAGE_TEXT_COLOR,
                          button_background_color: canUseCtaColors
                              ? buttonColor
                              : DEFAULT_BUTTON_COLOR,
                          button_text_color: canUseCtaColors
                              ? buttonTextColor
                              : DEFAULT_BUTTON_TEXT_COLOR,
                          submissions: [] // Does nothing at creation but required prop
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true,
                      image_url: imageUrl
                  }
                : // ctaType === 'image-ad'
                  {
                      brand: currentWorkspace.id,
                      name: ctaName,
                      theme,
                      image_action: {
                          image_url: imageAdUrl,
                          url: ensureUrlHasProtocol(callToActionUrl)
                      },
                      position: canUseCtaPositioning
                          ? getPositionForApiCall(position)
                          : getPositionForApiCall(DEFAULT_CTA_POSITION),
                      show_sniply_logo: canHideSniplyLogo ? showSniplyLogo : true
                  };

        setIsCreatingSnip(true);

        CtasService.ctasCreate({ requestBody: requestObject })
            .then((newCtaResult) => {
                console.log('CtasService.ctasCreate()', newCtaResult);

                LinksService.linksUpdate({
                    id: snipId,
                    requestBody: {
                        brand: currentWorkspace.id,
                        url: sniplyContentUrl,
                        cta_ids: [newCtaResult.id]
                    }
                })
                    .then((snipResult) => {
                        console.log('LinksService.linksCreate()', snipResult);

                        userPlan === 'Free' &&
                            !hasUsedFirstFreeSnip &&
                            setHasUsedFirstFreeSnip(true);

                        navigateTo(`/snips/${snipResult.id}`);
                        setIsCreatingSnip(false);
                    })
                    .catch((error) => {
                        console.log(
                            'Error creating a new Snip via LinksService.linksCreate()',
                            error
                        );

                        handleOpenNotification({
                            type: 'warning',
                            messageText:
                                'Something unexpected went wrong when trying to save your Snip. Please try again.',
                            hasCloseButton: true
                        });

                        setIsCreatingSnip(false);
                    });
            })
            .catch((error) => {
                console.error('Error posting to CtasService.ctasCreate()', error);

                handleOpenNotification({
                    type: 'warning',
                    messageText:
                        'Something unexpected went wrong when trying to save your Snip. Please try again.',
                    hasCloseButton: true
                });

                setIsCreatingSnip(false);
            });
    }, [
        snipId,
        currentWorkspace,
        callToActionUrl,
        sniplyContentUrl,
        ctaType,
        ctaName,
        imageUrl,
        imageAdUrl,
        headlineText,
        theme,
        messageText,
        messageTextColor,
        backgroundColor,
        buttonText,
        buttonTextColor,
        linkText,
        linkTextColor,
        buttonColor,
        formPlaceholderText,
        position,
        showSniplyLogo,
        canHideSniplyLogo,
        canUseCtaColors,
        canUseCtaPositioning,
        handleOpenNotification,
        hasUsedFirstFreeSnip,
        setHasUsedFirstFreeSnip,
        userPlan
    ]);

    const handleUpdateSnip = React.useCallback(() => {
        if (ctaType === 'image-ad' && !canUseImageAdCta) {
            handleOpenUpgradePlanMessage({
                feature: 'Image Call-To-Action',
                requiredPlan: minimumImageAdPlan
            });

            return;
        }

        if (!ctaId) {
            handleCreateCtaThenUpdateSnip();
        } else {
            // Create Snip straight up with the existing CTA's ID
            LinksService.linksUpdate({
                id: snipId,
                requestBody: {
                    brand: currentWorkspace.id,
                    url: sniplyContentUrl,
                    cta_ids: [ctaId]
                }
            })
                .then((snipResult) => {
                    console.log('LinksService.linksCreate()', snipResult);
                    navigateTo(`/snips/${snipResult.id}`);
                    setIsCreatingSnip(false);

                    userPlan === 'Free' && !hasUsedFirstFreeSnip && setHasUsedFirstFreeSnip(true);
                })
                .catch((error) => {
                    console.log('Error creating a new Snip via LinksService.linksCreate()', error);

                    handleOpenNotification({
                        type: 'warning',
                        messageText:
                            'Something unexpected went wrong when trying to save your Snip. Please try again.',
                        hasCloseButton: true
                    });

                    setIsCreatingSnip(false);
                });
        }
    }, [
        snipId,
        currentWorkspace,
        sniplyContentUrl,
        ctaId,
        ctaType,
        canUseImageAdCta,
        minimumImageAdPlan,
        handleOpenUpgradePlanMessage,
        handleOpenNotification,
        userPlan,
        hasUsedFirstFreeSnip,
        setHasUsedFirstFreeSnip,
        handleCreateCtaThenUpdateSnip
    ]);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (
                (!callToActionUrl && ctaType !== 'form') ||
                (!isValidUrlWithProtocol(ensureUrlHasProtocol(callToActionUrl)) &&
                    ctaType !== 'form')
            ) {
                setShowNoCtaLinkUrlError(true);
                noCtaLinkUrlErrorRef?.current?.scrollIntoView();
            } else if (!messageText && ctaType !== 'image-ad') {
                setErrorMessage('Please provide a message text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'button' && !buttonText) {
                setErrorMessage('Please provide a button text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'text-link' && !linkText) {
                setErrorMessage('Please provide a link text.');
                errorMessageRef?.current?.scrollIntoView();
            } else if (ctaType === 'form' && !formPlaceholderText) {
                setErrorMessage('Please provide a placeholder text.');
                errorMessageRef?.current?.scrollIntoView();
            } else {
                handleUpdateSnip();
            }
        },
        [
            buttonText,
            linkText,
            messageText,
            handleUpdateSnip,
            callToActionUrl,
            ctaType,
            formPlaceholderText
        ]
    );

    const [ctaLinkSuggestions, setCtaLinkSuggestions] = React.useState<string[]>([]);
    const [filteredCtaLinkSuggestions, setFilteredCtaLinkSuggestions] = React.useState<string[]>(
        []
    );

    React.useEffect(() => {
        CtasService.ctasList({ brand: currentWorkspace?.id, page: 1, pageSize: 10 }).then(
            (result) => {
                console.log(result);
                setCtaLinkSuggestions(createCtaLinkList(result));
                setFilteredCtaLinkSuggestions(createCtaLinkList(result));
            }
        );
    }, [currentWorkspace]);

    React.useEffect(() => {
        TeamService.teamLimitsAndBillingRetrieve()
            .then((result) => {
                setReachedPlanLimitForCtas(
                    hasHitPlanLimitForCtas(result.number_of_used_ctas, result.cta_limit)
                );

                setIsCheckingPlanCtaLimit(false);
            })
            .catch((error) => {
                console.error(
                    'Error fetching from TeamService.teamLimitsAndBillingRetrieve()',
                    error
                );

                setIsCheckingPlanCtaLimit(false);
            });
    }, []);

    // Reset error states
    React.useEffect(() => {
        setShowNoCtaSelectedError(false);
    }, [ctaId]);

    React.useEffect(() => {
        setShowNoCtaLinkUrlError(false);
    }, [callToActionUrl]);

    React.useEffect(() => {
        setErrorMessage(undefined);
    }, [messageText, buttonColor, linkText, formPlaceholderText, ctaType]);

    const isMobileScreen = useIsMobileScreenSize();

    return (
        <FormStepLayout
            title="Add Call-To-Action"
            step="2-of-2"
            buttons={
                <div className="flex">
                    <ButtonMain type="submit" width="full">
                        Save Snip
                    </ButtonMain>
                </div>
            }
            onSubmit={handleSubmit}
        >
            {isCreatingSnip ? (
                <div className="flex w-full h-full items-center justify-center">
                    <LoadingSpinner size={10} />
                </div>
            ) : activeMobileViewTab === 'preview' ? (
                <div className="w-full h-full">
                    <ContentIntegrator
                        contentUrl={sniplyContentUrl}
                        cta={
                            <LegacyCta
                                configuration={{
                                    id: ctaId,
                                    name: ctaName,
                                    type: ctaType,
                                    urlToBePromoted: ensureUrlHasProtocol(callToActionUrl),
                                    imageUrl: imagePreviewUrl || imageUrl,
                                    headlineText,
                                    messageText,
                                    messageTextColor,
                                    backgroundColor,
                                    buttonColor,
                                    buttonText,
                                    buttonTextColor,
                                    linkText,
                                    linkTextColor,
                                    formButtonColor,
                                    formButtonTextColor,
                                    formPlaceholderText,
                                    adImageUrl: imageAdPreviewUrl || imageAdUrl,
                                    position,
                                    theme,
                                    showSniplyLogo,
                                    showWhiteSniplyLogo
                                }}
                                disclaimerHref="https://snip.ly/whats-this2"
                                isDemoMobileScreen={true}
                                onConvertClick={() => console.log('handle CTA Conversion')}
                                onSubmitFormInputValue={() => console.log('handle Form Submit')}
                            />
                        }
                    />
                </div>
            ) : level3 === 'new' ? (
                <>
                    <VerticalSpacer heightValue={4} />
                    <FormTextInput
                        labelText="Call-To-Action Name"
                        id="cta-input"
                        placeholder="Enter a name for your call-to-action"
                        value={ctaName}
                        onChange={(event) => setCtaName(event.target.value)}
                        autoFocus
                    />
                    <VerticalSpacer heightValue={10} />
                    {ctaType !== 'form' ? (
                        <>
                            <div className="relative z-1">
                                <FormTextInput
                                    id="cta-link-input"
                                    labelText="Call-To-Action Link"
                                    explanation={
                                        <p className="mt-1 text-xs text-grey-light">
                                            (This is where users will be sent when they click your
                                            Call-To-Action.)
                                        </p>
                                    }
                                    placeholder="mywebsite.com"
                                    value={callToActionUrl}
                                    onChange={(event) => {
                                        setCallToActionUrl(event.target.value);
                                        setFilteredCtaLinkSuggestions(
                                            ctaLinkSuggestions.filter((suggestion) =>
                                                suggestion.includes(callToActionUrl)
                                            )
                                        );
                                    }}
                                    disableAutocomplete
                                />
                            </div>
                            {filteredCtaLinkSuggestions.length > 0 &&
                                !filteredCtaLinkSuggestions.includes(callToActionUrl) && (
                                    <div className="mx-2 p-4 grid gap-3 bg-grey-tint border border-grey-lighter rounded-md -translate-y-1">
                                        <h4 className="text-xs font-semibold">
                                            {!callToActionUrl ? 'LAST USED:' : 'SUGGESTIONS'}
                                        </h4>

                                        <ul className="mt-2 text-xs grid grid-cols-1 gap-1">
                                            {filteredCtaLinkSuggestions
                                                .slice(0, 3)
                                                .map((suggestion) => (
                                                    <li
                                                        className="p-1 rounded-md bg-white hover:underline"
                                                        key={suggestion}
                                                    >
                                                        <button
                                                            className="w-full text-left"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                setCallToActionUrl(suggestion);
                                                            }}
                                                        >
                                                            {suggestion}
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                            <div ref={noCtaLinkUrlErrorRef}>
                                {showNoCtaLinkUrlError && (
                                    <p className="text-xs mt-2 text-red">
                                        Please provide a valid Call-To-Action Link Url before
                                        proceeding.
                                    </p>
                                )}
                            </div>
                        </>
                    ) : null}
                    <VerticalSpacer heightValue={10} />
                    <CtaCreator
                        ctaName={ctaName}
                        setCtaName={setCtaName}
                        ctaType={ctaType}
                        setCtaType={setCtaType}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        imageAdUrl={imageAdUrl}
                        setImageAdUrl={setImageAdUrl}
                        headlineText={headlineText}
                        setHeadlineText={setHeadlineText}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        buttonText={buttonText}
                        setButtonText={setButtonText}
                        linkText={linkText}
                        setLinkText={setLinkText}
                        linkTextColor={linkTextColor}
                        setLinkTextColor={setLinkTextColor}
                        formButtonTextColor={formButtonTextColor}
                        setFormButtonTextColor={setFormButtonTextColor}
                        formButtonColor={formButtonColor}
                        setFormButtonColor={setFormButtonColor}
                        formPlaceholderText={formPlaceholderText}
                        setFormPlaceholderText={setFormPlaceholderText}
                        setImagePreviewUrl={setImagePreviewUrl}
                        setImageAdPreviewUrl={setImageAdPreviewUrl}
                        theme={theme}
                        setTheme={setTheme}
                        backgroundColor={backgroundColor}
                        setBackgroundColor={setBackgroundColor}
                        messageTextColor={messageTextColor}
                        setMessageTextColor={setMessageTextColor}
                        buttonColor={buttonColor}
                        setButtonColor={setButtonColor}
                        buttonTextColor={buttonTextColor}
                        setButtonTextColor={setButtonTextColor}
                        position={position}
                        setPosition={setPosition}
                        showSniplyLogo={showSniplyLogo}
                        setShowSniplyLogo={setShowSniplyLogo}
                        showWhiteSniplyLogo={showWhiteSniplyLogo}
                        setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                    />
                    <div className="h-5" ref={errorMessageRef}>
                        {errorMessage && <p className="text-xs text-red">{errorMessage} </p>}
                    </div>
                </>
            ) : (
                <div>
                    <p className="text-sm">
                        Using a Call-to-Action you can drive traffic from the Content you are
                        sharing to another Destination like your own product or service.
                    </p>
                    <VerticalSpacer heightValue={10} />
                    <CtaSelection
                        selectedCtaId={ctaId}
                        onSelectCta={onSelectCta}
                        createNewButton={
                            <ButtonMain
                                className="flex items-center"
                                style="neutral"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (reachedPlanLimitForCtas) {
                                        handleOpenUpgradePlanMessage({
                                            feature: 'Add Call-To-Action',
                                            requiredPlan: getNextHigherPlan(userPlan),
                                            message:
                                                'You have reached the maximum amount of Call-To-Actions for your current plan. To add another CTA you will have to upgrade your plan.'
                                        });
                                    } else {
                                        navigateTo('/snip-create/cta-select/new');
                                        resetCtaConfiguration();
                                    }
                                }}
                                disabled={isCheckingPlanCtaLimit}
                            >
                                Create New
                                {reachedPlanLimitForCtas ? (
                                    <img src={LockIcon} alt="lock icon" className="ml-2 h-5 w-5" />
                                ) : null}
                            </ButtonMain>
                        }
                        useAsTemplateButton={
                            <ButtonMain
                                className="ml-2 flex items-center"
                                style="neutral"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (reachedPlanLimitForCtas) {
                                        handleOpenUpgradePlanMessage({
                                            feature: 'Add Call-To-Action',
                                            requiredPlan: getNextHigherPlan(userPlan),
                                            message:
                                                'You have reached the maximum amount of Call-To-Actions for your current plan. To add another CTA you will have to upgrade your plan.'
                                        });
                                    } else {
                                        navigateTo('/snip-create/cta-select/new');
                                        setCtaId('');
                                    }
                                }}
                                disabled={isCheckingPlanCtaLimit}
                            >
                                {isMobileScreen ? 'Use As Template' : 'Use Selected As Template'}
                                {reachedPlanLimitForCtas ? (
                                    <img src={LockIcon} alt="lock icon" className="ml-2 h-5 w-5" />
                                ) : null}
                            </ButtonMain>
                        }
                    />
                    {!ctaId && showNoCtaSelectedError && (
                        <p className="mt-4 text-red text-sm">
                            Please select an existing Call-To-Action or create a new one before
                            proceeding.
                        </p>
                    )}
                </div>
            )}
        </FormStepLayout>
    );
};
