import React from 'react';

import { ContentElementContainer } from './components/ContentElementContainer';
import { DashboardSnips } from './DashboardSnips';
import { DashboardClipboard } from './DashboardClipboard';
import {
    createDashboardOverviewStats,
    DashboardOverviewStats
} from './data-transformations/create-dashboard-overview-stats';
import { StatsService } from './api';
import { AuthContext } from './auth';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { DashboardTopBar } from './DashboardTopBar';
import { DashboardOnboardingBanner } from './DashboardOnboardingBanner';
import { LoadingSpinner } from './components/LoadingSpinner';
import { getDateNumberForXDaysAgo } from './utils/getDateNumberForXDaysAgo';
import { StartHeader } from './components/StartHeader';
import { VerticalSpacer } from './components/VerticalSpacer';

export const Dashboard: React.FC = () => {
    const [stats, setStats] = React.useState<DashboardOverviewStats>();
    const [isLoadingStats, setIsLoadingStats] = React.useState(true);
    const [errorLoadingStats, setErrorLoadingStats] = React.useState(false);

    const [hasZeroSnips, setHasZeroSnips] = React.useState(false);

    const { isOpenAPITokenSet } = React.useContext(AuthContext);
    const { currentWorkspace } = React.useContext(WorkspaceContext);

    React.useEffect(() => {
        if (isOpenAPITokenSet && currentWorkspace) {
            const currentDate = Date.now();

            setIsLoadingStats(true);

            StatsService.statsOverallBrandRetrieve({
                brandId: currentWorkspace.id,
                startDate: getDateNumberForXDaysAgo(currentDate, 30),
                endDate: currentDate
            })
                .then((result) => {
                    console.log('StatsService.statsOverallBrandRetrieve()', result);
                    setStats(createDashboardOverviewStats(result));
                    setIsLoadingStats(false);
                })
                .catch((error) => {
                    console.error(
                        'Error fetching Dashboard Stats from StatsService.statsOverallBrandRetrieve(): ',
                        error
                    ),
                        setErrorLoadingStats(true);
                });
        }
    }, [isOpenAPITokenSet, currentWorkspace]);

    return (
        <div className="grid grid-cols-1 gap-y-3 text-grey">
            <StartHeader
                primaryHeaderLabel="Dashboard"
                secondaryHeaderText=" Overview of your activities and performance"
            />
            <VerticalSpacer heightValue={3} />
            <div className="w-full mt-4 text-grey">
                <ContentElementContainer>
                    <div className="h-40">
                        {isLoadingStats ? (
                            <div className="flex h-full w-full items-center justify-center">
                                <LoadingSpinner size={10} />
                            </div>
                        ) : errorLoadingStats ? (
                            <p className="text-sm text-red">
                                Apologies your statistics overview could not be retrieved at this
                                point. Try again by reloading the page.
                            </p>
                        ) : hasZeroSnips ? (
                            <DashboardOnboardingBanner />
                        ) : (
                            <DashboardTopBar stats={stats} />
                        )}
                    </div>
                </ContentElementContainer>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2-1 gap-x-10 gap-y-5 mt-8">
                <DashboardSnips setHasZeroSnips={setHasZeroSnips} />
                <DashboardClipboard />
            </div>
        </div>
    );
};
