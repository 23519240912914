import React from 'react';
import MenuBulletsIcon from './assets/icons/menu-bullets-horizontal.svg';
import { TeamMemberData } from './data-transformations/create-team-members-data';
import DeleteIcon from './assets/icons/delete.svg';
import EditIcon from './assets/icons/edit.svg';
import ResendIcon from './assets/icons/resend.svg';

interface TeamEditRowButtonProps {
    readonly teamMember: TeamMemberData;
    readonly onResendInvitationClick: (teamMemberId: string) => void;
    readonly onDeleteInvitation: (teamMemberId: string) => void;
    readonly onDeleteTeamMemberClick: (teamMemberId: string) => void;
    readonly onEditClick: (teamMemberId: string) => void;
}

export const TeamEditRowButtonDropdown: React.FC<TeamEditRowButtonProps> = ({
    teamMember,
    onResendInvitationClick,
    onDeleteInvitation,
    onDeleteTeamMemberClick,
    onEditClick
}) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex justify-end" ref={containerRef}>
            <button onClick={() => setIsMenuOpen(isMenuOpen ? false : true)}>
                <img className="h-4 w-4" src={MenuBulletsIcon} />
            </button>
            {isMenuOpen && (
                <ul className="z-1 absolute -top-2 right-0 p-2 text-sm bg-white border border-grey-lightest rounded-md w-28 shadow-lg">
                    <li className="text-grey">
                        <button
                            className="flex items-center"
                            onClick={() => {
                                teamMember.invitationPending
                                    ? onDeleteInvitation(teamMember.id)
                                    : onDeleteTeamMemberClick(teamMember.id);

                                setIsMenuOpen(false);
                            }}
                        >
                            <img src={DeleteIcon} alt="delete icon" className="w-4 h-4" />
                            <span className="ml-2">Delete</span>
                        </button>
                    </li>
                    {teamMember.invitationPending && (
                        <li className="mt-2 text-grey">
                            <button
                                className="flex items-center"
                                onClick={() => {
                                    onResendInvitationClick(teamMember.id);
                                    setIsMenuOpen(false);
                                }}
                            >
                                <img src={ResendIcon} alt="resend icon" className="w-4 h-4" />
                                <span className="ml-2">Resend</span>
                            </button>
                        </li>
                    )}
                    {onEditClick && (
                        <li className="mt-2 text-grey">
                            <button
                                className="flex items-center"
                                onClick={() => {
                                    onEditClick(teamMember.id);
                                    setIsMenuOpen(false);
                                }}
                            >
                                <img src={EditIcon} alt="edit icon" className="w-4 h-4" />
                                <span className="ml-2">Edit</span>
                            </button>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};
