import React from 'react';

export function useInitializeBeamerIntegration(userId: string): void {
    const [hasBeamerBeenInitialized, sethasBeamerBeenInitialized] = React.useState(false);

    React.useEffect(() => {
        if (!hasBeamerBeenInitialized) {
            (window as any).beamer_config = {
                product_id: process.env.BEAMER_PRODUCT_ID,
                bottom: 80 /*Optional: Bottom position offset for the notification bubble*/,
                button_position:
                    'bottom-right' /*Optional: Position for the notification button that shows up when the selector parameter is not set*/,
                icon: 'bell' /*Optional: Alternative icon to display in the notification button*/,
                user_id: userId /*Optional : Input your user ID for better statistics*/
            };

            const beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js';
            const head = document.getElementsByTagName('head')[0];

            const beamerScript = document.createElement('script');
            beamerScript.defer = true;
            beamerScript.src = beamerURL;

            head.appendChild(beamerScript);

            sethasBeamerBeenInitialized(true);
        }
    }, [hasBeamerBeenInitialized, userId]);
}
