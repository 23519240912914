import React, { useContext } from 'react';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { ButtonMain } from './components/ButtonMain';
import { SimpleContentBox } from './components/SimpleContentBox';
import { UserContext } from './UserContextProvider';
import { UserService } from './api';
import { LoadingSpinner } from './components/LoadingSpinner';
import { isValidEmail } from './utils/is-valid-email';
import { VerticalSpacer } from './components/VerticalSpacer';

export const UserSettingsChangeEmail = () => {
    const { userEmail } = useContext(UserContext);
    const [currentEmail, setCurrentEmail] = React.useState(userEmail);
    const [newEmail, setNewEmail] = React.useState('');

    const [confirmationCode, setConfirmationCode] = React.useState('');

    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const [backendError, setBackendError] = React.useState(false);

    const [invalidEmailError, setInvalidEmailError] = React.useState(false);
    const [emailAlreadyExistsError, setEmailAlreadyExistsError] = React.useState(false);
    const [incorrectCodeError, setIncorrectCodeError] = React.useState(false);

    const [hasCodeBeenSent, setHasCodeBeenSent] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    const handleRequestChangeEmail = React.useCallback(
        (event) => {
            event.preventDefault();

            if (!isValidEmail(newEmail)) {
                setInvalidEmailError(true);
                return;
            }

            setIsLoadingResponse(true);

            UserService.userRequestChangeEmailUpdate({
                requestBody: { new_email: newEmail }
            })
                .then((result) => {
                    console.log('UserService.userChangePasswordUpdate()', result);
                    setIsLoadingResponse(false);
                    setCurrentEmail(newEmail);

                    setHasCodeBeenSent(true);
                })
                .catch((error) => {
                    console.log('Error requesting confirmation code.', error);
                    if (error?.body?.new_email?.includes('Email already exists')) {
                        setEmailAlreadyExistsError(true);
                    } else {
                        setBackendError(true);
                    }

                    setIsLoadingResponse(false);
                });
        },
        [newEmail]
    );

    const handleChangeEmail = React.useCallback(
        (event) => {
            event.preventDefault();

            if (!isValidEmail(newEmail)) {
                setInvalidEmailError(true);
                return;
            }

            setIsLoadingResponse(true);

            UserService.userConfirmChangeEmailUpdate({
                requestBody: { new_email: newEmail, code: confirmationCode }
            })
                .then((result) => {
                    console.log('UserService.userChangePasswordUpdate()', result);
                    setIsLoadingResponse(false);
                    setCurrentEmail(newEmail);

                    setShowSuccessMessage(true);
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                        setConfirmationCode('');
                        setHasCodeBeenSent(false);
                        setNewEmail('');
                    }, 5000);
                })
                .catch((error) => {
                    console.log('Error confirming the user email via email code.', error);
                    if (error?.body?.non_field_errors?.includes('Code is incorrect')) {
                        setIncorrectCodeError(true);
                    } else {
                        setBackendError(true);
                    }

                    setIsLoadingResponse(false);
                });
        },
        [newEmail, confirmationCode]
    );

    React.useEffect(() => {
        backendError && setBackendError(false);
        invalidEmailError && setInvalidEmailError(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newEmail]);

    React.useEffect(() => {
        incorrectCodeError && setIncorrectCodeError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationCode]);

    return (
        <ContentElementContainer>
            <SimpleContentBox headerText="Change Account Email">
                <form
                    className="mt-1 grid grid-cols-1 gap-y-4"
                    onSubmit={hasCodeBeenSent ? handleChangeEmail : handleRequestChangeEmail}
                >
                    <div className="flex text-sm flex-wrap">
                        <span className="mr-2">Current email: </span>
                        <span className="font-semibold text-xs xs:text-sm">{currentEmail}</span>
                    </div>
                    {hasCodeBeenSent ? (
                        <div>
                            <p className="text-sm text-green">
                                <span>
                                    A confirmation code has been sent to your email address{' '}
                                </span>
                                <span className="font-semibold">{newEmail}</span>
                            </p>
                            <VerticalSpacer heightValue={2} />
                            <FormTextInput
                                id="confirmation-code"
                                placeholder="Put in Confirmation Code"
                                value={confirmationCode}
                                onChange={(event) => {
                                    setConfirmationCode(event.target.value);
                                }}
                            />
                        </div>
                    ) : (
                        <FormTextInput
                            id="new-email"
                            labelText="New email"
                            placeholder="your new account email"
                            value={newEmail}
                            onChange={(event) => {
                                setNewEmail(event.target.value);
                            }}
                        />
                    )}
                    {backendError && (
                        <div className="text-red text-xs">
                            Apologies something went wrong. An unexpected network error has
                            occurred. Please try refreshing the browser or try again later.
                        </div>
                    )}
                    {invalidEmailError && (
                        <div className="text-red text-xs">
                            Please provide a valid email address.
                        </div>
                    )}
                    {emailAlreadyExistsError && (
                        <div className="text-red text-xs">
                            A user with this email address already exists.
                        </div>
                    )}
                    {incorrectCodeError && (
                        <div className="text-red text-xs">
                            Please make sure to use the correct code.
                        </div>
                    )}
                    {isLoadingResponse ? (
                        <div>
                            <LoadingSpinner size={5} />
                        </div>
                    ) : showSuccessMessage ? (
                        <div className="text-green text-xs">Email successfully changed.</div>
                    ) : (
                        <div>
                            <ButtonMain size="small" type="submit">
                                {`${
                                    hasCodeBeenSent
                                        ? 'Confirm New Email'
                                        : 'Request Confirmation Code'
                                }`}
                            </ButtonMain>
                        </div>
                    )}
                </form>
            </SimpleContentBox>
        </ContentElementContainer>
    );
};
