import { SnipsListItem } from '../data-transformations/create-snip-data';
import { ActiveOrArchivedView } from '../SnipsOverviewTable';

export function filterForActiveOrArchivedSnips(
    snipsList: SnipsListItem[],
    filterValue: ActiveOrArchivedView
) {
    if (filterValue === 'Archived') {
        return snipsList?.filter((snip) => snip.archived) ?? [];
    }

    return snipsList?.filter((snip) => !snip.archived) ?? [];
}
