import React from 'react';

import { GetFormSubmissionsService, ReportsService } from './api';
import CopyIconWhite from './assets/icons/copy-white.svg';
import CopyIcon from './assets/icons/copy.svg';
import SendIconWhite from './assets/icons/send-white.svg';
import SendIcon from './assets/icons/send-grey.svg';
import { ContentElementContainer } from './components/ContentElementContainer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { useIsMobileScreenSize } from './hooks/use-is-mobile-screen-size';
import { NotificationContext } from './NotificationContextProvider';
import { createCommaSeparatedListFromArray } from './utils/create-comma-separated-list-from-array';
import { WorkspaceContext } from './WorkspaceContextProvider';

interface SnipsSnipContentFormResultsProps {
    readonly snipId: string;
}

export const SnipsSnipContentFormResults: React.FC<SnipsSnipContentFormResultsProps> = ({
    snipId
}) => {
    const [formResultsData, setFormResultsData] = React.useState<string[]>([]);
    const [formResultsForCopyAction, setFormResultsForCopyAction] = React.useState<string>();

    const [isLoading, setIsLoading] = React.useState(true);
    const [hadErrorLoading, setHadErrorLoading] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { handleOpenNotification } = React.useContext(NotificationContext);

    const isMobileScreen = useIsMobileScreenSize();

    const handleCopyResults = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            navigator.clipboard.writeText(formResultsForCopyAction);

            handleOpenNotification({
                messageText: `Successfully copied a list of your ${
                    formResultsData?.length > 0 ? formResultsData.length : '0'
                } results to clipboard.`,
                type: 'success',
                iconSrc: CopyIconWhite,
                showTimeInSeconds: 3
            });
        },
        [formResultsForCopyAction, handleOpenNotification, formResultsData]
    );

    const handleRequestResultsEmail = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            ReportsService.reportsSnipsFormSubmissionsCreate({ linkId: snipId })
                .then(() => {
                    handleOpenNotification({
                        messageText: `An email with your responses data has been sent to your email address.`,
                        type: 'success',
                        iconSrc: SendIconWhite,
                        showTimeInSeconds: 3
                    });
                })
                .catch((error) => {
                    console.error('Error requesting form cta responses sent to user email.', error);

                    handleOpenNotification({
                        messageText: `Something went wrong. Please try again.`,
                        type: 'warning',
                        iconSrc: SendIconWhite,
                        showTimeInSeconds: 3
                    });
                });
        },
        [handleOpenNotification, snipId]
    );

    React.useEffect(() => {
        if (currentWorkspace) {
            GetFormSubmissionsService.getFormSubmissionsRetrieve({
                link: snipId
            })
                .then((result) => {
                    console.log(' GetFormSubmissionsService.getFormSubmissionsList()', result);
                    setFormResultsData(result.submissions);
                    setFormResultsForCopyAction(
                        createCommaSeparatedListFromArray(result.submissions)
                    );
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(
                        'Error fetching from  GetFormSubmissionsService.getFormSubmissionsList(): ',
                        error
                    );
                    setHadErrorLoading(true);
                    setIsLoading(false);
                });
        }
    }, [currentWorkspace, snipId]);

    return (
        <ContentElementContainer>
            <div className="flex justify-between">
                <h3 className="font-semibold">Form Submissions</h3>
                {formResultsData.length > 0 && (
                    <div className="flex items-center">
                        <button className="flex items-center" onClick={handleRequestResultsEmail}>
                            <img src={SendIcon} alt="send icon" className="h-4 w-4" />
                            <span className="ml-1 text-sm text-grey-light">
                                {isMobileScreen ? 'Export' : 'Export Results'}
                            </span>
                        </button>
                        <button className="flex items-center ml-3" onClick={handleCopyResults}>
                            <img src={CopyIcon} alt="copy icon" className="h-4 w-4" />
                            <span className="ml-1 text-sm text-grey-light">Copy</span>
                        </button>
                    </div>
                )}
            </div>
            <div className="h-10">
                {isLoading ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <LoadingSpinner size={5} />
                    </div>
                ) : hadErrorLoading ? (
                    <div className="w-full h-full flex items-center justify-center">
                        <p className="text-sm text-red">
                            Unexpected error while downloading your form submission results. Please
                            try again by reloading your browser window.
                        </p>
                    </div>
                ) : (
                    <div className="w-full h-full flex items-center">
                        <p className="text-sm">
                            <span>You have collected </span>
                            <span className="font-bold">
                                {formResultsData?.length > 0 ? formResultsData.length : '0'}
                            </span>
                            <span> responses.</span>
                        </p>
                    </div>
                )}
            </div>
        </ContentElementContainer>
    );
};
