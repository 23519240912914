import React from 'react';
import { ButtonMain } from './components/ButtonMain';
import { CtaFormContainer } from './components/CtaFormContainer';
import { CtaType, CtaTypeDropdownSelect } from './components/CtaTypeDropdownSelect';
import { CtaButtonCreate } from './CtaButtonCreate';
import { CtaFormCreate } from './CtaFormCreate';
import { CtaImageAdCreate } from './CtaImageAdCreate';
import { CtaLinkCreate } from './CtaLinkCreate';
import { CtaTheme, CtaPosition } from './data-transformations/create-cta-data';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { UserContext } from './UserContextProvider';
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_BUTTON_COLOR,
    DEFAULT_BUTTON_TEXT_COLOR,
    DEFAULT_LINK_TEXT_COLOR,
    DEFAULT_MESSAGE_TEXT_COLOR
} from './utils/default-cta-configuration';

const CtaCustomizingButton: React.FC<{
    readonly customizingStyle: boolean;
    readonly setCustomizingStyle: (value: boolean) => void;
}> = ({ customizingStyle, setCustomizingStyle }) => (
    <div className="flex justify-center">
        <ButtonMain
            className="w-48"
            size="small"
            style="neutral"
            onClick={(event) => {
                event.preventDefault();
                setCustomizingStyle(customizingStyle ? false : true);
            }}
        >
            {customizingStyle ? 'Back To Configuration' : 'Customize Style'}
        </ButtonMain>
    </div>
);

export interface CtaCreatorProps {
    readonly ctaName: string;
    readonly setCtaName: (ctaName: string) => void;
    readonly ctaType: CtaType;
    readonly setCtaType: (ctaType: CtaType) => void;
    readonly headlineText: string;
    readonly setHeadlineText: (headlineText: string) => void;
    readonly messageText: string;
    readonly setMessageText: (messageText: string) => void;
    readonly messageTextColor: string;
    readonly setMessageTextColor: (messageTextColor: string) => void;
    readonly buttonText: string;
    readonly setButtonText: (ctaText: string) => void;
    readonly linkText: string;
    readonly setLinkText: (ctaText: string) => void;
    readonly linkTextColor: string;
    readonly setLinkTextColor: (textColor: string) => void;
    readonly formButtonTextColor: string;
    readonly setFormButtonTextColor: (formButtonTextColor: string) => void;
    readonly formButtonColor: string;
    readonly setFormButtonColor: (formButtonColor: string) => void;
    readonly formPlaceholderText: string;
    readonly setFormPlaceholderText: (formPlaceholderText: string) => void;
    readonly imageUrl: string | undefined;
    readonly setImageUrl: (imageUrl: string) => void;
    readonly imageAdUrl: string | undefined;
    readonly setImageAdUrl: (imageAdUrl: string) => void;
    readonly setImagePreviewUrl: (imagePreviewUrl: string) => void;
    readonly setImageAdPreviewUrl: (imagePreviewUrl: string) => void;
    readonly theme: CtaTheme;
    readonly setTheme: (theme: CtaTheme) => void;
    readonly backgroundColor: string;
    readonly setBackgroundColor: (backgroundColor: string) => void;
    readonly buttonColor: string;
    readonly setButtonColor: (buttonColor: string) => void;
    readonly buttonTextColor: string;
    readonly setButtonTextColor: (buttonTextColor: string) => void;
    readonly position: CtaPosition;
    readonly setPosition: (position: CtaPosition) => void;
    readonly showSniplyLogo: boolean;
    readonly setShowSniplyLogo: (state: boolean) => void;
    readonly showWhiteSniplyLogo: boolean;
    readonly setShowWhiteSniplyLogo: (state: boolean) => void;
}

export const CtaCreator: React.FC<CtaCreatorProps> = ({
    ctaType,
    setCtaType,
    headlineText,
    setHeadlineText,
    messageText,
    setMessageText,
    buttonText,
    setButtonText,
    linkText,
    setLinkText,
    linkTextColor,
    setLinkTextColor,
    formPlaceholderText,
    setFormPlaceholderText,
    imageUrl,
    setImageUrl,
    imageAdUrl,
    setImageAdUrl,
    setImagePreviewUrl,
    setImageAdPreviewUrl,
    theme,
    setTheme,
    backgroundColor,
    setBackgroundColor,
    messageTextColor,
    setMessageTextColor,
    buttonColor,
    setButtonColor,
    buttonTextColor,
    setButtonTextColor,
    position,
    setPosition,
    showSniplyLogo,
    setShowSniplyLogo,
    showWhiteSniplyLogo,
    setShowWhiteSniplyLogo
}) => {
    const [customizingCtaStyle, setCustomizingCtaStyle] = React.useState(false);

    const [hasShownWarningCannotUsePosition, setHasShownWarningCannotUsePosition] =
        React.useState(false);

    const [hasShownWarningCannotUseColors, setHasShownWarningCannotUseColors] =
        React.useState(false);

    const [hasShownWarningCannotUseHideSniplyLogo, setHasShownWarningCannotUseHideSniplyLogo] =
        React.useState(false);

    const {
        canHideSniplyLogo,
        minimumHideSniplyLogoPlan,
        canUseCtaColors,
        minimumUseCtaColorsPlan,
        canUseCtaPositioning,
        minimumUseCtaPositioningPlan
    } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    const handleCtaTypeSelect = React.useCallback(
        (type: CtaType) => {
            //set default values
            !messageTextColor && setMessageTextColor(DEFAULT_MESSAGE_TEXT_COLOR);
            !buttonText && setButtonText('Click here');
            !buttonTextColor && setButtonTextColor(DEFAULT_BUTTON_TEXT_COLOR);
            !linkTextColor && setLinkTextColor(DEFAULT_LINK_TEXT_COLOR);
            !backgroundColor && setBackgroundColor(DEFAULT_BACKGROUND_COLOR);
            !buttonColor && setButtonColor(DEFAULT_BUTTON_COLOR);

            setCtaType(type);
        },
        [
            setCtaType,
            messageTextColor,
            buttonText,
            buttonTextColor,
            linkTextColor,
            backgroundColor,
            buttonColor,
            setMessageTextColor,
            setButtonTextColor,
            setButtonText,
            setButtonColor,
            setBackgroundColor,
            setLinkTextColor
        ]
    );

    const handleSelectCtaPosition = React.useCallback(
        (ctaPosition: CtaPosition) => {
            if (!canUseCtaPositioning && !hasShownWarningCannotUsePosition) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Position',
                    requiredPlan: minimumUseCtaPositioningPlan
                });

                setHasShownWarningCannotUsePosition(true);
            }

            setPosition(ctaPosition);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaPositioningPlan,
            setPosition,
            hasShownWarningCannotUsePosition,
            canUseCtaPositioning
        ]
    );

    const handleSelectCtaBackgroundColor = React.useCallback(
        (hexColor: string) => {
            if (!canUseCtaColors && !hasShownWarningCannotUseColors) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Colors',
                    requiredPlan: minimumUseCtaColorsPlan
                });

                setHasShownWarningCannotUseColors(true);
            }

            setBackgroundColor(hexColor);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaColorsPlan,
            setBackgroundColor,
            hasShownWarningCannotUseColors,
            canUseCtaColors
        ]
    );

    const handleSelectCtaMessageTextColor = React.useCallback(
        (hexColor: string) => {
            if (!canUseCtaColors && !hasShownWarningCannotUseColors) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Colors',
                    requiredPlan: minimumUseCtaColorsPlan
                });

                setHasShownWarningCannotUseColors(true);
            }

            setMessageTextColor(hexColor);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaColorsPlan,
            setMessageTextColor,
            hasShownWarningCannotUseColors,
            canUseCtaColors
        ]
    );

    const handleSelectCtaButtonColor = React.useCallback(
        (hexColor: string) => {
            if (!canUseCtaColors && !hasShownWarningCannotUseColors) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Colors',
                    requiredPlan: minimumUseCtaColorsPlan
                });

                setHasShownWarningCannotUseColors(true);
            }

            setButtonColor(hexColor);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaColorsPlan,
            setButtonColor,
            hasShownWarningCannotUseColors,
            canUseCtaColors
        ]
    );

    const handleSelectCtaButtonTextColor = React.useCallback(
        (hexColor: string) => {
            if (!canUseCtaColors && !hasShownWarningCannotUseColors) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Colors',
                    requiredPlan: minimumUseCtaColorsPlan
                });

                setHasShownWarningCannotUseColors(true);
            }

            setButtonTextColor(hexColor);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaColorsPlan,
            setButtonTextColor,
            hasShownWarningCannotUseColors,
            canUseCtaColors
        ]
    );

    const handleSelectCtaLinkTextColor = React.useCallback(
        (hexColor: string) => {
            if (!canUseCtaColors && !hasShownWarningCannotUseColors) {
                handleOpenUpgradePlanMessage({
                    feature: 'Call-To-Action Colors',
                    requiredPlan: minimumUseCtaColorsPlan
                });

                setHasShownWarningCannotUseColors(true);
            }

            setLinkTextColor(hexColor);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumUseCtaColorsPlan,
            setLinkTextColor,
            hasShownWarningCannotUseColors,
            canUseCtaColors
        ]
    );

    const handleSelectShowSniplyLogo = React.useCallback(
        (value: boolean) => {
            if (!canHideSniplyLogo && !hasShownWarningCannotUseHideSniplyLogo) {
                handleOpenUpgradePlanMessage({
                    feature: 'Hide Sniply Logo',
                    requiredPlan: minimumHideSniplyLogoPlan
                });

                setHasShownWarningCannotUseHideSniplyLogo(true);
            }

            setShowSniplyLogo(value);
        },
        [
            handleOpenUpgradePlanMessage,
            minimumHideSniplyLogoPlan,
            setShowSniplyLogo,
            hasShownWarningCannotUseHideSniplyLogo,
            canHideSniplyLogo
        ]
    );

    return (
        <div>
            <CtaTypeDropdownSelect
                titleText="Call-To-Action Styling"
                selectedCtaType={ctaType}
                setSelectedCtaType={handleCtaTypeSelect}
                squeeze={Boolean(ctaType)}
            />
            {ctaType === 'button' ? (
                <CtaFormContainer>
                    <CtaButtonCreate
                        customizingStyle={customizingCtaStyle}
                        setImagePreviewUrl={setImagePreviewUrl}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        headlineText={headlineText}
                        setHeadlineText={setHeadlineText}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        buttonText={buttonText}
                        setButtonText={setButtonText}
                        theme={theme}
                        setTheme={setTheme}
                        backgroundColor={backgroundColor}
                        onSetBackgroundColor={handleSelectCtaBackgroundColor}
                        messageTextColor={messageTextColor}
                        onSetMessageTextColor={handleSelectCtaMessageTextColor}
                        buttonColor={buttonColor}
                        onSetButtonColor={handleSelectCtaButtonColor}
                        buttonTextColor={buttonTextColor}
                        onSetButtonTextColor={handleSelectCtaButtonTextColor}
                        position={position}
                        onSetPosition={handleSelectCtaPosition}
                        showSniplyLogo={showSniplyLogo}
                        onSetShowSniplyLogo={handleSelectShowSniplyLogo}
                        showWhiteSniplyLogo={showWhiteSniplyLogo}
                        setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                        canHideSniplyLogo={canHideSniplyLogo}
                        minimumHideSniplyLogoPlan={minimumHideSniplyLogoPlan}
                        canUseCtaColors={canUseCtaColors}
                        minimumUseCtaColorsPlan={minimumUseCtaColorsPlan}
                        canUseCtaPositioning={canUseCtaPositioning}
                        minimumUseCtaPositioningPlan={minimumUseCtaPositioningPlan}
                        handleOpenUpgradePlanMessage={handleOpenUpgradePlanMessage}
                    />
                    <div className="mt-2">
                        <CtaCustomizingButton
                            customizingStyle={customizingCtaStyle}
                            setCustomizingStyle={setCustomizingCtaStyle}
                        />
                    </div>
                </CtaFormContainer>
            ) : ctaType === 'text-link' ? (
                <CtaFormContainer>
                    <CtaLinkCreate
                        customizingStyle={customizingCtaStyle}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        setImagePreviewUrl={setImagePreviewUrl}
                        headlineText={headlineText}
                        setHeadlineText={setHeadlineText}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        linkText={linkText}
                        setLinkText={setLinkText}
                        theme={theme}
                        setTheme={setTheme}
                        backgroundColor={backgroundColor}
                        onSetBackgroundColor={handleSelectCtaBackgroundColor}
                        messageTextColor={messageTextColor}
                        onSetMessageTextColor={handleSelectCtaMessageTextColor}
                        linkTextColor={linkTextColor}
                        onSetLinkTextColor={handleSelectCtaLinkTextColor}
                        position={position}
                        onSetPosition={handleSelectCtaPosition}
                        showSniplyLogo={showSniplyLogo}
                        onSetShowSniplyLogo={handleSelectShowSniplyLogo}
                        showWhiteSniplyLogo={showWhiteSniplyLogo}
                        setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                        canHideSniplyLogo={canHideSniplyLogo}
                        minimumHideSniplyLogoPlan={minimumHideSniplyLogoPlan}
                        canUseCtaColors={canUseCtaColors}
                        minimumUseCtaColorsPlan={minimumUseCtaColorsPlan}
                        canUseCtaPositioning={canUseCtaPositioning}
                        minimumUseCtaPositioningPlan={minimumUseCtaPositioningPlan}
                        handleOpenUpgradePlanMessage={handleOpenUpgradePlanMessage}
                    />
                    <div className="mt-2">
                        <CtaCustomizingButton
                            customizingStyle={customizingCtaStyle}
                            setCustomizingStyle={setCustomizingCtaStyle}
                        />
                    </div>
                </CtaFormContainer>
            ) : ctaType === 'form' ? (
                <CtaFormContainer>
                    <CtaFormCreate
                        customizingStyle={customizingCtaStyle}
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        setImagePreviewUrl={setImagePreviewUrl}
                        headlineText={headlineText}
                        setHeadlineText={setHeadlineText}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        formPlaceholderText={formPlaceholderText}
                        setFormPlaceholderText={setFormPlaceholderText}
                        theme={theme}
                        setTheme={setTheme}
                        backgroundColor={backgroundColor}
                        onSetBackgroundColor={handleSelectCtaBackgroundColor}
                        messageTextColor={messageTextColor}
                        onSetMessageTextColor={handleSelectCtaMessageTextColor}
                        buttonColor={buttonColor}
                        onSetButtonColor={handleSelectCtaButtonColor}
                        buttonTextColor={buttonTextColor}
                        onSetButtonTextColor={handleSelectCtaButtonTextColor}
                        position={position}
                        onSetPosition={handleSelectCtaPosition}
                        showSniplyLogo={showSniplyLogo}
                        onSetShowSniplyLogo={handleSelectShowSniplyLogo}
                        showWhiteSniplyLogo={showWhiteSniplyLogo}
                        setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                        canHideSniplyLogo={canHideSniplyLogo}
                        minimumHideSniplyLogoPlan={minimumHideSniplyLogoPlan}
                        canUseCtaColors={canUseCtaColors}
                        minimumUseCtaColorsPlan={minimumUseCtaColorsPlan}
                        canUseCtaPositioning={canUseCtaPositioning}
                        minimumUseCtaPositioningPlan={minimumUseCtaPositioningPlan}
                        handleOpenUpgradePlanMessage={handleOpenUpgradePlanMessage}
                    />
                    <div className="mt-2">
                        <CtaCustomizingButton
                            customizingStyle={customizingCtaStyle}
                            setCustomizingStyle={setCustomizingCtaStyle}
                        />
                    </div>
                </CtaFormContainer>
            ) : ctaType === 'image-ad' ? (
                <CtaFormContainer>
                    <CtaImageAdCreate
                        customizingStyle={customizingCtaStyle}
                        setImagePreviewUrl={setImageAdPreviewUrl}
                        imageAdUrl={imageAdUrl}
                        setImageAdUrl={setImageAdUrl}
                        position={position}
                        onSetPosition={handleSelectCtaPosition}
                        showSniplyLogo={showSniplyLogo}
                        onSetShowSniplyLogo={handleSelectShowSniplyLogo}
                        showWhiteSniplyLogo={showWhiteSniplyLogo}
                        setShowWhiteSniplyLogo={setShowWhiteSniplyLogo}
                        canHideSniplyLogo={canHideSniplyLogo}
                        minimumHideSniplyLogoPlan={minimumHideSniplyLogoPlan}
                        canUseCtaPositioning={canUseCtaPositioning}
                        minimumUseCtaPositioningPlan={minimumUseCtaPositioningPlan}
                        handleOpenUpgradePlanMessage={handleOpenUpgradePlanMessage}
                    />
                    <div aria-description="image-upload-input">
                        Images should be 728x90 to display properly
                    </div>
                    <div className="mt-2">
                        <CtaCustomizingButton
                            customizingStyle={customizingCtaStyle}
                            setCustomizingStyle={setCustomizingCtaStyle}
                        />
                    </div>
                </CtaFormContainer>
            ) : null}
        </div>
    );
};
