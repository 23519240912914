import React from 'react';
import { ButtonMain } from './components/ButtonMain';
import { StartHeader } from './components/StartHeader';
import { RouteLink } from './Routing';
import { SnipsOverviewTable } from './SnipsOverviewTable';

export const SnipsOverview: React.FC = () => {
    return (
        <div className="grid grid-cols-1 gap-y-5 text-grey">
            <StartHeader
                primaryHeaderLabel="Snips"
                secondaryHeaderText="Overview of snips - with related campaigns and call-to-actions"
                button={
                    <RouteLink href="/snip-create/content-select">
                        <ButtonMain size="small" style="neutral">
                            + New Snip
                        </ButtonMain>
                    </RouteLink>
                }
            />
            <SnipsOverviewTable />
        </div>
    );
};
