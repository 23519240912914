import React from 'react';
import { useWindowSize } from './hooks/use-window-size';
import { AuthProvider, TAuthConfig as AuthConfig } from './auth';
import { UserContextProvider } from './UserContextProvider';
import { Router } from './Router';
import { WorkspaceContextProvider } from './WorkspaceContextProvider';
import { FeatureFlagsContextProvider } from './FeatureFlagsContextProvider';
import { UpgradePlanMessageIntegration } from './UpgradePlanMessageIntegration';
import { UpgradePlanMessageContextProvider } from './UpgradePlanMessageContextProvider';
import { NotificationContextProvider } from './NotificationContextProvider';
import { NotificationIntegration } from './NotificationIntegration';
import TagManager from 'react-gtm-module';
import { PromptIntegration } from './PromptIntegration';
import { PromptContextProvider } from './PromptContextProvider';

const API_HOST = process.env.API_HOST;
export const BASE_DOMAIN = process.env.API_HOST?.split('//')[1];

export const AUTH_CONFIG: AuthConfig = {
    clientId: process.env.CLIENT_ID || '',
    authorizationEndpoint: `${API_HOST}/api/oauth2/authorize/`,
    tokenEndpoint: `${API_HOST}/api/oauth2/token/`,
    redirectUri: process.env.APP_BASE_DOMAIN ?? 'http://localhost:8080/',
    scope: 'read write',
    logoutEndpoint: '',
    logoutRedirect: `${API_HOST}/logout`
};

// Initialize Google Tag Manager
const tagManagerConfig = {
    gtmId: process.env.GTM_ID
};

TagManager.initialize(tagManagerConfig);

export type CurrentContentView =
    | 'dashboard'
    | 'snips'
    | 'discover-content'
    | 'clipboard'
    | 'reporting'
    | 'customization'
    | 'integrations'
    | 'support'
    | 'page';

export type NavigableViews = Record<CurrentContentView, string>;

interface SideNavigationVisibilityContextValue {
    readonly isSideNavigationExpanded: boolean;
    readonly setIsSideNavigationExpanded: (value: boolean) => void;
}

export const SideNavigationVisibilityContext =
    React.createContext<SideNavigationVisibilityContextValue>({
        isSideNavigationExpanded: true,
        setIsSideNavigationExpanded: () => undefined
    });

const App: React.FC = () => {
    const [isSideNavigationExpanded, setIsSideNavigationExpanded] = React.useState(true);
    const { width } = useWindowSize();

    React.useEffect(() => {
        if (width > 960) {
            // The main create views should have side navigation collapsed for better focus.
            if (!window.location.pathname.split('/')[1].startsWith('create-'))
                setIsSideNavigationExpanded(true);
        } else if (width > 420) {
            setIsSideNavigationExpanded(false);
        }
    }, [width]);

    return (
        <AuthProvider authConfig={AUTH_CONFIG}>
            <FeatureFlagsContextProvider>
                <UserContextProvider>
                    <WorkspaceContextProvider>
                        <NotificationContextProvider>
                            <UpgradePlanMessageContextProvider>
                                <PromptContextProvider>
                                    <SideNavigationVisibilityContext.Provider
                                        value={{
                                            isSideNavigationExpanded,
                                            setIsSideNavigationExpanded
                                        }}
                                    >
                                        <div className="font-poppins h-full w-full fixed">
                                            {/* Use "fixed" here so the container element of the app is positioned 
                                         relative to the initial containing block established by the viewport. 
                                         Primarily this helps dealing with flexible view sizes when mobile browsers 
                                         open keyboard and other elements.  */}
                                            <Router />
                                        </div>
                                    </SideNavigationVisibilityContext.Provider>
                                    <NotificationIntegration />
                                    <UpgradePlanMessageIntegration />
                                    <PromptIntegration />
                                </PromptContextProvider>
                            </UpgradePlanMessageContextProvider>
                        </NotificationContextProvider>
                    </WorkspaceContextProvider>
                </UserContextProvider>
            </FeatureFlagsContextProvider>
        </AuthProvider>
    );
};

export default App;
