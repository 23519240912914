import React from 'react';

import { LinksService } from './api';

import { OverviewTabFirstLevelGrid } from './components/TabContentLayoutOverview';
import { SnipsListItem } from './data-transformations/create-snip-data';
import { SnipsSnipCreationSuccessful } from './SnipsSnipCreationSuccessful';
import { SnipsSnipContentGeneralInfo } from './SnipsSnipContentGeneralInfo';
import { SnipsSnipContentPerformance } from './SnipsSnipContentPerformance';
import { SnipSnipContentCampaign } from './SnipSnipContentCampaign';
import { SnipsSnipContentCta } from './SnipsSnipContentCta';
import { SnipsSnipContentFormResults } from './SnipsSnipContentFormResults';
import { SnipsSnipContentShare } from './SnipsSnipContentShare';

interface SnipsSnipContentProps {
    readonly snip: SnipsListItem;
    readonly setSnip: (snip: SnipsListItem) => void;
}

export type CtaVariant = 'A' | 'B';

export const SnipsSnipContent: React.FC<SnipsSnipContentProps> = ({ snip, setSnip }) => {
    const [ctaTabView, setCtaTabView] = React.useState<CtaVariant>('A');

    const [showCreationSuccessInfo, setShowCreationSuccessInfo] = React.useState(false);

    const [isRemovingCta, setIsRemovingCta] = React.useState(false);

    const [shortLink, setShortLink] = React.useState(snip.slug);
    const [domainId, setDomainId] = React.useState<string>(snip.customDomainId ?? 'default');

    const handleRemoveCta = React.useCallback(
        (ctaId: string) => {
            const newCtaIds = snip.ctaIds.filter((id) => id !== ctaId);
            const newCtas = snip.ctas.filter((cta) => cta.id !== ctaId);

            setIsRemovingCta(true);

            // if all ctas are removed from a snip and this snip is not in summary mode,
            // use the raw url for the redirect that this snip now becomes.
            if (newCtaIds.length === 0 && snip.type !== 'summary') {
                LinksService.linksPartialUpdate({
                    id: snip.id,
                    requestBody: { cta_ids: newCtaIds, url: snip.originalContentUrl }
                }).then(() => {
                    setSnip({
                        ...snip,
                        ctaIds: newCtaIds,
                        ctas: newCtas,
                        sniplyContentUrl: snip.originalContentUrl
                    });

                    ctaTabView === 'B' && setCtaTabView('A');
                    setIsRemovingCta(false);
                });
            } else {
                LinksService.linksPartialUpdate({
                    id: snip.id,
                    requestBody: { cta_ids: newCtaIds }
                }).then(() => {
                    setSnip({ ...snip, ctaIds: newCtaIds, ctas: newCtas });
                    ctaTabView === 'B' && setCtaTabView('A');
                    setIsRemovingCta(false);
                });
            }
        },
        [snip, setSnip, ctaTabView]
    );

    // If a Creation Success Message should be shown, the information is passed as URL params.
    React.useEffect(() => {
        const searchParams = new URL(window.location.href).searchParams;

        const isFirstTime = searchParams.get('firstTime');

        if (isFirstTime && isFirstTime === 'true') {
            setShowCreationSuccessInfo(true);
        }
    }, []);

    const selectedCtaId = snip.ctaIds[ctaTabView === 'A' ? 0 : 1];
    const snipHasCta = snip.ctas.length >= 1;

    return (
        <div className="w-full">
            <OverviewTabFirstLevelGrid>
                {showCreationSuccessInfo ? (
                    <SnipsSnipCreationSuccessful
                        snip={snip}
                        setSnip={setSnip}
                        setShowCreationSuccessInfo={setShowCreationSuccessInfo}
                        domainId={domainId}
                        setDomainId={setDomainId}
                        shortLink={shortLink}
                        setShortLink={setShortLink}
                    />
                ) : (
                    <SnipsSnipContentShare snip={snip} />
                )}
                <div className="grid grid-cols-1 lg:grid-cols-1-2 gap-5">
                    <SnipsSnipContentGeneralInfo snip={snip} />
                    <SnipsSnipContentPerformance snip={snip} />
                </div>
                {snip.ctas[0]?.type === 'form' ? (
                    <SnipsSnipContentFormResults snipId={snip.id} />
                ) : null}
                <SnipSnipContentCampaign snip={snip} />
                <SnipsSnipContentCta
                    snip={snip}
                    snipHasCta={snipHasCta}
                    selectedCtaId={selectedCtaId}
                    ctaTabView={ctaTabView}
                    setCtaTabView={setCtaTabView}
                    handleRemoveCta={handleRemoveCta}
                    isRemovingCta={isRemovingCta}
                />
            </OverviewTabFirstLevelGrid>
        </div>
    );
};
