/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CTA } from '../models/CTA';
import type { CTARequest } from '../models/CTARequest';
import type { PaginatedCTAList } from '../models/PaginatedCTAList';
import type { PatchedCTARequest } from '../models/PatchedCTARequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CtasService {

    /**
     * A Call-to-Action specifies the full details of how would like the call-to-action to show up inside a Sniply Link.
     * This includes the message text, color configuration, themes, positioning, etc.
     * A Call-to-Action lives with in a brand and can be attached to any of your links.
     *
     * Note: if there are multiple call-to-actions attached to a link, Sniply randomly chooses one to show each reader,
     * which allows you to A/B test different Call-to-Actions.
     * @returns PaginatedCTAList
     * @throws ApiError
     */
    public static ctasList({
        archived,
        brand,
        label,
        page,
        pageSize,
        search,
    }: {
        archived?: boolean,
        brand?: string,
        label?: string,
        /**
         * A page number within the paginated result set.
         */
        page?: number,
        /**
         * Number of results to return per page.
         */
        pageSize?: number,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedCTAList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/ctas/',
            query: {
                'archived': archived,
                'brand': brand,
                'label': label,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * A Call-to-Action specifies the full details of how would like the call-to-action to show up inside a Sniply Link.
     * This includes the message text, color configuration, themes, positioning, etc.
     * A Call-to-Action lives with in a brand and can be attached to any of your links.
     *
     * Note: if there are multiple call-to-actions attached to a link, Sniply randomly chooses one to show each reader,
     * which allows you to A/B test different Call-to-Actions.
     * @returns CTA
     * @throws ApiError
     */
    public static ctasCreate({
        requestBody,
    }: {
        requestBody: CTARequest,
    }): CancelablePromise<CTA> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/ctas/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CTA
     * @throws ApiError
     */
    public static ctasRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<CTA> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/ctas/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CTA
     * @throws ApiError
     */
    public static ctasUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CTARequest,
    }): CancelablePromise<CTA> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/ctas/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CTA
     * @throws ApiError
     */
    public static ctasPartialUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: PatchedCTARequest,
    }): CancelablePromise<CTA> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v3/ctas/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
