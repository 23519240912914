import React from 'react';
import { SnipsSnip } from './SnipsSnip';
import { SnipsOverview } from './SnipsOverview';
import { useCurrentUrlPath } from './Routing';

export const Snips: React.FC = () => {
    const { level2: snipId } = useCurrentUrlPath();

    return (
        <div className="w-full h-full">
            {!snipId ? <SnipsOverview /> : <SnipsSnip currentSnipId={snipId} />}
        </div>
    );
};
