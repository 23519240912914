import { PaginatedLinkList } from '../api';
import { createSnipData, SnipsListItem } from './create-snip-data';

export function createSnipsList(response: PaginatedLinkList): SnipsListItem[] {
    if (!response) {
        return [];
    }

    return response.results?.map((result) => createSnipData(result));
}
