import React from 'react';
import RejectedIcon from './assets/icons/rejected.svg';
import { ButtonMain } from './components/ButtonMain';
import { ContentElementContainer } from './components/ContentElementContainer';
import { FormTextInput } from './components/FormTextInput';
import { LoadingSpinner } from './components/LoadingSpinner';
import { VerticalSpacer } from './components/VerticalSpacer';
import { CustomDomainsContentGrid } from './CustomizationCustomDomains';

interface CustomDomainsAddDomainProps {
    readonly domainName: string;
    readonly setDomainName: (domainName: string) => void;
    readonly subDomainName: string;
    readonly setSubDomainName: (subDomainName: string) => void;
    readonly onSubmitCustomDomain: (event: React.FormEvent<HTMLFormElement>) => void;
    readonly onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly errorDomainAlreadyInUse: boolean;
    readonly checkingDomain: boolean;
    readonly isDomainAvailable: boolean;
    readonly showDomainNotAvailableWarning: boolean;
    readonly showSubDomainNotAvailableWarning: boolean;
    readonly isCreatingCustomDomain: boolean;
    readonly errorInvalidDomainName: boolean;
    readonly errorCreatingDomain: boolean;
    readonly errorCreatingSubDomain: boolean;
}

export const CustomDomainsAddDomain: React.FC<CustomDomainsAddDomainProps> = ({
    domainName,
    setDomainName,
    subDomainName,
    setSubDomainName,
    onSubmitCustomDomain,
    onCancel,
    errorDomainAlreadyInUse,
    checkingDomain,
    isDomainAvailable,
    showDomainNotAvailableWarning,
    showSubDomainNotAvailableWarning,
    isCreatingCustomDomain,
    errorInvalidDomainName,
    errorCreatingDomain,
    errorCreatingSubDomain
}) => {
    return (
        <div>
            <h3 className="mt-6 mb-3 sm:mb-6 sm:pl-2 font-semibold text-lg text-grey">
                Add a domain
            </h3>
            <CustomDomainsContentGrid>
                <ContentElementContainer>
                    <h3 className="font-semibold">Enter domain</h3>
                    <div className="mt-3">
                        <form onSubmit={onSubmitCustomDomain}>
                            <div className="flex flex-col">
                                <VerticalSpacer heightValue={4} />
                                <FormTextInput
                                    id="domain-input"
                                    labelText="Enter your custom domain"
                                    placeholder="e.g. mysite.co"
                                    value={domainName}
                                    onChange={(event) => {
                                        setDomainName(event.target.value);
                                    }}
                                    righHandComponent={
                                        checkingDomain ? (
                                            <div className="flex items-center justify-center">
                                                <LoadingSpinner size={5} />
                                            </div>
                                        ) : showDomainNotAvailableWarning && !isDomainAvailable ? (
                                            <img src={RejectedIcon} className="h-4 w-4" />
                                        ) : null
                                    }
                                    autoFocus
                                />
                            </div>
                            {errorInvalidDomainName && (
                                <p className="mt-2 text-sm text-red">
                                    The given domain name is invalid.
                                </p>
                            )}
                            {errorDomainAlreadyInUse && !subDomainName && (
                                <p className="mt-2 text-sm text-red">
                                    The given domain is already part of your custom domains.
                                </p>
                            )}
                            {errorCreatingDomain && (
                                <p className="mt-2 text-sm text-red">
                                    There was an unexpected error while creating this domain. Please
                                    try again.
                                </p>
                            )}
                            {showDomainNotAvailableWarning && !showSubDomainNotAvailableWarning && (
                                <div className="mt-6">
                                    <div className="p-4 bg-orange/10">
                                        <p className="text-sm text-orange-dark">
                                            {`We have detected that the domain "${domainName}" contains published content. In order to use this domain in your Sniply short links, the domain will no longer be connected with your current website and any content currently on it will not be accessible.`}
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <p className="text-sm">
                                            {`If you want to connect this domain and not lose your site,
                                        you'll need to use a subdomain other than 'www'. For
                                        example, if you have a site published to www.yourbrand.com,
                                        use a custom subdomain like: go.yourbrand.com for Sniply. If
                                        you enter a subdomain below, we will provide instructions to
                                        configure it in the next step.`}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {showSubDomainNotAvailableWarning && (
                                <div className="mt-6">
                                    <div className="p-4 bg-orange/10">
                                        <p className="text-sm text-orange-dark">
                                            {`We have detected that the subdomain "${subDomainName}.${domainName}" contains published content. In order to use this subdomain in your Sniply short links, the subdomain will no longer be connected with your current website and any content currently on it will not be accessible.`}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {showDomainNotAvailableWarning && (
                                <div className="mt-6">
                                    <FormTextInput
                                        id="sub-domain-input"
                                        labelText="Subdomain (optional)"
                                        value={subDomainName}
                                        onChange={(event) => {
                                            setSubDomainName(event.target.value);
                                        }}
                                        righHandComponent={<span>{`.${domainName}`}</span>}
                                    />
                                    {errorCreatingSubDomain && (
                                        <p className="mt-2 text-sm text-red">
                                            There was an unexpected error while creating this
                                            subdomain. Please try again.
                                        </p>
                                    )}
                                    {errorDomainAlreadyInUse && subDomainName && (
                                        <p className="mt-2 text-sm text-red">
                                            The given subdomain is already part of your custom
                                            domains.
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="mt-6 flex justify-end">
                                {isCreatingCustomDomain ? (
                                    <div className="flex w-44 justify-center">
                                        <LoadingSpinner size={5} />
                                    </div>
                                ) : (
                                    <>
                                        <ButtonMain style="neutral" onClick={onCancel}>
                                            Cancel
                                        </ButtonMain>
                                        <ButtonMain
                                            className="ml-2"
                                            type="submit"
                                            width="48"
                                            disabled={!domainName}
                                        >
                                            {(!subDomainName && showDomainNotAvailableWarning) ||
                                            showSubDomainNotAvailableWarning
                                                ? 'Continue anyways'
                                                : 'Next'}
                                        </ButtonMain>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </ContentElementContainer>
            </CustomDomainsContentGrid>
        </div>
    );
};
