import React from 'react';
import { FormTextInput } from './components/FormTextInput';
// import ShareIcon from './assets/icons/share.svg';
import { ButtonMain } from './components/ButtonMain';
import { isValidUrlWithProtocol } from './utils/is-valid-url';
import { VerticalSpacer } from './components/VerticalSpacer';
import { CtasService, FeedsService, LabelsService } from './api';
import { FocusViewLayout } from './components/FocusViewLayout';
import { WorkspaceContext } from './WorkspaceContextProvider';
import { CampaignsDropdownTagSelect } from './CampaignsDropdownTagSelect';
import { DropDownOption, DropdownSelect } from './components/DropdownSelect';
import { LoadingSpinner } from './components/LoadingSpinner';
import { createCampaignsListForDropdown } from './data-transformations/create-campaigns-list';
import { createCtasListForDropdown } from './data-transformations/create-ctas-list';
import { navigateTo } from './Routing';
import { UpgradePlanMessageContext } from './UpgradePlanMessageContextProvider';
import { UserContext } from './UserContextProvider';
import { ensureUrlHasProtocol } from './utils/ensure-url-has-protocol';
import { IntegrationsRssFeedsCompleted } from './IntegrationsRssFeedsCompleted';

export const CreateFeed: React.FC = () => {
    const [showCompleteCreateView, setShowCompleteCreateView] = React.useState(false);

    const [feedUrl, setFeedUrl] = React.useState('');
    const [feedCtaId, setFeedCtaId] = React.useState('');
    const [secondaryFeedCtaId, setSecondaryFeedCtaId] = React.useState('');
    const [feedSnipUrl, setFeedSnipUrl] = React.useState('');
    const [selectedCampaignId, setSelectedCampaignId] = React.useState('');

    const [ctasData, setCtasData] = React.useState<DropDownOption[]>([]);
    const [campaignsData, setCampaignsData] = React.useState<DropDownOption[]>([]);

    const [displayValidationErrors, setDisplayValidationErrors] = React.useState(false);

    const [isCreatingFeed, setIsCreatingFeed] = React.useState(false);
    const [isLoadingCampaigns, setIsLoadingCampaigns] = React.useState(true);

    const [errorCreatingFeed, setErrorCreatingFeed] = React.useState(false);

    const { currentWorkspace } = React.useContext(WorkspaceContext);
    const { canUseABTesting, minimumABTestingPlan } = React.useContext(UserContext);

    const { handleOpenUpgradePlanMessage } = React.useContext(UpgradePlanMessageContext);

    const [showBVariantCtaDropdown, setShowBVariantCtaDropdown] = React.useState(false);

    const handleCreateFeed = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setErrorCreatingFeed(false);

            if (!isValidUrlWithProtocol(ensureUrlHasProtocol(feedUrl))) {
                setDisplayValidationErrors(true);

                return;
            }

            setIsCreatingFeed(true);

            const ctaIds =
                !feedCtaId && !secondaryFeedCtaId
                    ? []
                    : feedCtaId && !secondaryFeedCtaId
                    ? [feedCtaId]
                    : [feedCtaId, secondaryFeedCtaId];

            FeedsService.feedsCreate({
                requestBody: {
                    brand: currentWorkspace.id,
                    feed_url: feedUrl,
                    cta_ids: ctaIds,
                    label: selectedCampaignId ? selectedCampaignId : null
                }
            })
                .then((result) => {
                    console.log(' FeedsService.feedsUpdate()', result);
                    setIsCreatingFeed(false);
                    setFeedSnipUrl(result.link);
                    setShowCompleteCreateView(true);
                })
                .catch((error) => {
                    console.log('Error calling api for FeedsService.feedsUpdate()', error);
                    setIsCreatingFeed(true);
                    setErrorCreatingFeed(true);
                });
        },
        [currentWorkspace, feedUrl, feedCtaId, secondaryFeedCtaId, selectedCampaignId]
    );

    const handleCreateNewCampaign = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, campaignName: string) => {
            event.preventDefault();

            LabelsService.labelsCreate({
                requestBody: { brand: currentWorkspace.id, name: campaignName }
            }).then((result) => {
                console.log('LabelsService.labelsCreate()', result);

                setCampaignsData([
                    ...campaignsData,
                    { displayLabel: result.name, value: result.id }
                ]);

                setSelectedCampaignId(result.id);
            });
        },
        [currentWorkspace, campaignsData]
    );

    const handleStartCreateNewFeed = React.useCallback(() => {
        //reset form values
        setFeedUrl('');
        setFeedCtaId('');
        setSecondaryFeedCtaId('');
        setFeedSnipUrl('');
        setSelectedCampaignId('');

        setShowCompleteCreateView(false);
    }, []);

    React.useEffect(() => {
        if (currentWorkspace) {
            CtasService.ctasList({ brand: currentWorkspace.id })
                .then((result) => {
                    console.log('FeedsService.feedsList()', result);
                    setCtasData(createCtasListForDropdown(result));
                })
                .catch((error) => {
                    console.log('Error fetching from FeedsService.feedsList(): ', error);
                });
        }
    }, [currentWorkspace]);

    React.useEffect(() => {
        if (currentWorkspace) {
            setIsLoadingCampaigns(true);

            LabelsService.labelsList({
                brand: currentWorkspace.id,
                ordering: '-created_date',
                pageSize: 500
            })
                .then((result) => {
                    console.log('LabelsService.labelsList()', result);
                    setCampaignsData(createCampaignsListForDropdown(result));
                    setIsLoadingCampaigns(false);
                })
                .catch((error) => {
                    console.log('Error fetching from LabelsService.labelsList(): ', error);
                    setIsLoadingCampaigns(false);
                });
        }
    }, [currentWorkspace]);

    return (
        <FocusViewLayout enableWideView>
            <h2 className="font-semibold text-lg">Create Feed</h2>
            <VerticalSpacer heightValue={10} />
            {showCompleteCreateView ? (
                <IntegrationsRssFeedsCompleted
                    feedSnipUrl={feedSnipUrl}
                    onStartCreateNewFeed={handleStartCreateNewFeed}
                />
            ) : (
                <form className="mt-1 grid p-4" onSubmit={handleCreateFeed}>
                    <FormTextInput
                        id="feed-url-input"
                        labelText="Feed Url"
                        placeholder="Enter feed url"
                        value={feedUrl}
                        onChange={(event) => {
                            setFeedUrl(event.target.value);
                        }}
                    />
                    <p className="h-3 p-1 text-xs text-red">{`${
                        displayValidationErrors ? 'please provide a valid url' : ''
                    }`}</p>
                    <VerticalSpacer heightValue={6} />
                    <div>
                        <p className="text-sm">Call-To-Actions</p>
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-y-3 gap-x-10">
                            <DropdownSelect
                                placeholderText="Select Call-To-Action"
                                options={ctasData}
                                selectedOption={feedCtaId}
                                onSelectOption={(option) => setFeedCtaId(option)}
                            />
                            {feedCtaId ? (
                                showBVariantCtaDropdown ? (
                                    <DropdownSelect
                                        placeholderText="Select Call-To-Action"
                                        options={ctasData}
                                        selectedOption={secondaryFeedCtaId}
                                        onSelectOption={(option) => setSecondaryFeedCtaId(option)}
                                    />
                                ) : (
                                    <button
                                        className="text-left px-2 text-grey-light line-clamp-1 text-sm"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (!canUseABTesting) {
                                                handleOpenUpgradePlanMessage({
                                                    feature: 'A/B Testing',
                                                    requiredPlan: minimumABTestingPlan
                                                });
                                            } else {
                                                setShowBVariantCtaDropdown(true);
                                            }
                                        }}
                                    >
                                        + Add B Variation
                                    </button>
                                )
                            ) : null}
                        </div>
                    </div>
                    <VerticalSpacer heightValue={6} />
                    <div>
                        <p className="text-sm mb-2">Campaign</p>
                        {isLoadingCampaigns ? (
                            <div className="flex items-center justify-center">
                                <LoadingSpinner size={5} />
                            </div>
                        ) : (
                            <CampaignsDropdownTagSelect
                                label="+ Add To Campaign"
                                campaigns={campaignsData}
                                isLoadingCampaigns={isLoadingCampaigns}
                                selectedCampaignId={selectedCampaignId}
                                onSelectCampaignId={(event, id) => {
                                    event.preventDefault();
                                    setSelectedCampaignId(id);
                                }}
                                onResetCampaignId={(event) => {
                                    event.preventDefault();
                                    setSelectedCampaignId('');
                                }}
                                onCreateCampaign={handleCreateNewCampaign}
                            />
                        )}
                    </div>
                    {errorCreatingFeed ? (
                        <p className="mt-2 text-sm text-red">
                            Apologies the feed could not be saved at this time. Please try again.
                        </p>
                    ) : null}
                    <VerticalSpacer heightValue={12} />
                    {isCreatingFeed ? (
                        <div className="flex items-center justify-center">
                            <LoadingSpinner size={5} />
                        </div>
                    ) : (
                        <div className="flex">
                            <ButtonMain
                                style="neutral"
                                onClick={() => navigateTo('/integrations/rss-feeds')}
                            >
                                Cancel
                            </ButtonMain>
                            <ButtonMain className="ml-2 w-full sm:w-32" type="submit">
                                Create Feed
                            </ButtonMain>
                        </div>
                    )}
                </form>
            )}
        </FocusViewLayout>
    );
};
