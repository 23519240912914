import React from 'react';
import SearchIcon from '../assets/icons/search.svg';

interface SearchInputCollapsedProps {
    readonly searchTermState: string;
    readonly setSearchTermState: (searchTerm: string) => void;
    readonly placeholderText?: string;
    readonly onExpand?: () => void;
    readonly onCollapse?: () => void;
    readonly onClearSearch?: () => void;
    readonly collapsedPlaceholder?: boolean;
    readonly width?: string;
    readonly isCollapsible?: boolean;
    readonly isInitiallyCollapsed?: boolean;
    readonly isRounded?: boolean;
    readonly autofocus?: boolean;
}

export const SearchInput: React.FC<SearchInputCollapsedProps> = ({
    searchTermState,
    setSearchTermState,
    placeholderText,
    onExpand,
    onCollapse,
    onClearSearch,
    collapsedPlaceholder = false,
    width = '48',
    isCollapsible = true,
    isInitiallyCollapsed = true,
    isRounded = false,
    autofocus = false
}) => {
    const [expanded, setExpanded] = React.useState(isInitiallyCollapsed);

    const searchInputRef = React.useRef(null);

    React.useEffect(() => {
        if (!expanded && onCollapse) {
            onCollapse();
        }
        if (expanded && onExpand) {
            onExpand();
        }
    }, [expanded, onExpand, onCollapse]);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (
                isCollapsible &&
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target) &&
                !searchTermState
            ) {
                setExpanded(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchTermState, isCollapsible]);

    return (
        <div ref={searchInputRef} className="h-10 flex items-center">
            {expanded ? (
                <div className={`flex items-center relative w-${width}`}>
                    <input
                        className={`px-3 ${
                            isRounded ? 'py-1' : 'py-2'
                        }  w-full border border-grey-lighter ${
                            isRounded ? 'rounded-full' : 'rounded-md'
                        } text-grey outline-none`}
                        value={searchTermState}
                        onChange={(event) => {
                            setSearchTermState(event.target.value);
                        }}
                        placeholder={placeholderText ?? ''}
                        autoFocus={autofocus}
                    />
                    {onClearSearch && searchTermState ? (
                        <div className="flex items-center absolute right-1 py-1 pl-1 rounded-r-full bg-white">
                            <button
                                className="text-sm ml-1 text-grey-medium pr-2"
                                onClick={onClearSearch}
                            >
                                Clear
                            </button>
                        </div>
                    ) : (
                        <button
                            className="absolute right-2"
                            onClick={(event) => {
                                event.preventDefault();

                                if (isCollapsible) {
                                    setExpanded(expanded ? false : true);
                                }
                            }}
                        >
                            {searchTermState === '' && <img className="h-5 w-5" src={SearchIcon} />}
                        </button>
                    )}
                </div>
            ) : (
                <button
                    className={`flex items-center px-3 ${isRounded ? 'py-1' : 'py-2'}`}
                    onClick={(event) => {
                        event.preventDefault();
                        setExpanded(expanded ? false : true);
                    }}
                >
                    <img className="h-5 w-5" src={SearchIcon} />
                    {collapsedPlaceholder && (
                        <span className="ml-2 text-grey">{placeholderText}</span>
                    )}
                </button>
            )}
        </div>
    );
};
